import {getConsole, getMockConsole} from '@/utils/getConsole';
import {io} from "socket.io-client";
import { Webrtc } from "./lib/webrtc";
import {ErrorEvent} from "@/plugins/VoiceChatClient/events/ErrorEvent";
import { DisconnectedEvent } from '@/plugins/VoiceChatClient/events/DisconnectedEvent';
import { RoomJoinedEvent } from '@/plugins/VoiceChatClient/events/RoomJoinedEvent';
import { RoomLeftEvent } from '@/plugins/VoiceChatClient/events/RoomLeftEvent';
import { FrequencyChangedEvent } from '@/plugins/VoiceController/events/FrequencyChangedEvent'
import { LOCAL_STREAM_CHANGED, LocalStreamChangedEvent } from '@/plugins/VoiceController/events/LocalStreamChangedEvent'
import {
  VOICE_CHAT_STATE_CONNECTED,
  VOICE_CHAT_STATE_DISCONNECTED,
  VOICE_CHAT_STATE_IN_ROOM
} from '@/plugins/VoiceChatClient/lib/VoiceChatState'

export default class extends EventTarget {
  /**
   * A reference to the voice controller
   *
   * @type {VoiceController}
   */
  $voiceController;

  // The console to add a prefix to console when printing
  console;

  // The socket picked up from socket.io
  socket;

  // The WebRTC class
  webRtc;

  // Our own socket ID
  selfSocketId;

  state = VOICE_CHAT_STATE_DISCONNECTED;

  constructor(options = {}) {
    super();

    let useDebugConsole = false;

    if (options) {
      if (options.useDebugConsole) {
        useDebugConsole = options.useDebugConsole;
      }
    }

    this.setUseDebugConsole(useDebugConsole);
  }

  setVoiceController($voiceController) {
    this.$voiceController = $voiceController;

    this.console.log('setVoiceController', $voiceController);

    this.$voiceController.addEventListener(LOCAL_STREAM_CHANGED, (e) => {
      this.console.log('LocalStreamChangedEvent (caught)', e);

      if (this.webRtc) {
        this.webRtc.setLocalStream(e.stream);
      }
    })
  }

  setUseDebugConsole(useDebugConsole) {
    if (useDebugConsole) {
      this.console = getConsole('VoiceChatClient', 'white', '#005555');
      this.webRtcConsole = getConsole('VoiceChatClient.WebRTC', 'white', '#00aaaa');
    } else {
      this.console = getMockConsole();
      this.webRtcConsole = getMockConsole();
    }
  }

  getPcConfig() {
    return {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
            'stun:stun3.l.google.com:19302',
            'stun:stun4.l.google.com:19302',
          ],
        },
        {
          urls: 'turn:numb.viagenie.ca',
          credential: 'muazkh',
          username: 'webrtc@live.com',
        },
        {
          urls: 'turn:numb.viagenie.ca',
          credential: 'muazkh',
          username: 'webrtc@live.com',
        },
        {
          urls: 'turn:192.158.29.39:3478?transport=udp',
          credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
          username: '28224511:1379330808',
        },
      ],
    };
  }

  kickPlayer(socketId) {
    this.webRtc.kickUser(socketId);
  }

  disconnect() {
    if (this.webRtc) {
      this.webRtc.leaveRoom();

      this.webRtc = null;
    }

    if (this.socket) {
      this.socket.disconnect();
      this.socket.close();
    }

    this.dispatchEvent(new RoomLeftEvent(this.roomId, 'self'));

    this.state = VOICE_CHAT_STATE_DISCONNECTED;

    //this.$voiceController.removeParticipantByUid('self');
  }

  onCreatedOrJoinedWebRtcRoom(e) {
    if (!this.webRtc) {
      throw new Error('Called onCreatedOrJoinedWebRtcRoom but webRtc was not defined');
    }

    this.webRtc.gotStream();

    const roomId = e.detail.roomId;
    const socketId = e.detail.socketId || 'self';

    this.roomId = roomId;
    this.selfSocketId = socketId;

    const participant = this.$voiceController.createParticipant(socketId, false);

    console.log('onCreatedOrJoinedWebRtcRoom', socketId, participant);

    this.dispatchEvent(new RoomJoinedEvent(roomId, participant));

    this.state = VOICE_CHAT_STATE_IN_ROOM;
  }

  reset() {
    this.$voiceController.reset();

    this.webRtc = undefined;
    this.selfSocketId = undefined;
  }

  connectToRoom(serverAddress, roomId) {
    return new Promise(async (resolve, reject) => {
      this.connect(serverAddress)
        .then(() => {
          resolve(this.joinRoom(roomId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  connect(serverAddress) {
    return new Promise(async (resolve, reject) => {
      this.console.log(`Connecting to ${serverAddress}..`);

      this.socket = io(serverAddress, {
        withCredentials: true,
        jsonp: false,
        transports: ['websocket']
      });

      this.socket.on('connect', async () => {
        try {
          const localStream = await this.$voiceController.requestLocalStreamLatest();

          if (localStream) {
            this.webRtc.setLocalStream(localStream);
          }

          this.console.log(`Socket.on.connect`);

          this.state = VOICE_CHAT_STATE_CONNECTED;

          resolve({
            socket: this.socket,
            webRtc: this.webRtc,
          });
        } catch (e) {
          reject(e.error);
        }
      });

      this.socket.on('connect_error', () => {
        this.console.error(`Socket.on.connect_error`);

        this.socket.disconnect();
        this.socket.close();
        this.socket = null;

        reject(`Could not connect to server at ${serverAddress} (connect_error)`);

        this.dispatchEvent(new ErrorEvent(`Could not connect to server at ${serverAddress} (connect_error)`));

        this.state = VOICE_CHAT_STATE_DISCONNECTED;
      });

      this.socket.on('disconnect', (reason) => {
        this.console.log(`Socket.on.disconnect`, reason);

        this.roomId = null;

        this.dispatchEvent(new DisconnectedEvent(reason));

        this.$voiceController.onLeftRoom('self');
        this.state = VOICE_CHAT_STATE_DISCONNECTED;
      });

      this.webRtc = new Webrtc(this.socket, this.getPcConfig(), this.webRtcConsole);

      this.webRtc.addEventListener('createdRoom', (e) => {
        this.console.log(`You have created the room`, e);

        this.onCreatedOrJoinedWebRtcRoom(e);
      });

      this.webRtc.addEventListener('joinedRoom', (e) => {
        this.console.log(`You have joined the room`, e);

        this.onCreatedOrJoinedWebRtcRoom(e);
      });

      this.webRtc.addEventListener('leftRoom', (e) => {
        this.console.log(`You left the voice chat room`, e);

        this.$voiceController.onLeftRoom('self');

        if (this.state === VOICE_CHAT_STATE_IN_ROOM) {
          this.state = VOICE_CHAT_STATE_CONNECTED;
        }

        //this.$voiceController.removeParticipantByUid('self');
      });

      this.webRtc.addEventListener('newUser', (e) => {
        this.$voiceController.createParticipant(e.detail.socketId);

        this.$voiceController.setVideoTagForParticipant(e.detail.socketId, e.detail.stream);
      });

      this.webRtc.addEventListener('userLeave', (e) => {
        this.console.log(`User left room`, e);

        this.$voiceController.onLeftRoom(e.detail.socketId);

        if (e.detail.socketId === 'self') {
          if (this.state === VOICE_CHAT_STATE_IN_ROOM) {
            this.state = VOICE_CHAT_STATE_CONNECTED;
          }
        }
      });

      /**
       * Handle user got removed
       */
      this.webRtc.addEventListener('removeUser', (e) => {
        this.console.log(`removeUser`, e);

        this.$voiceController.onLeftRoom(e.detail.socketId || 'self');
      });

      /**
       * Handle errors
       */
      this.webRtc.addEventListener('error', (e) => {
        this.console.log(`error`, e);

        this.dispatchEvent(new ErrorEvent(e.message));
      });

      /**
       * Handle notifications
       */
      this.webRtc.addEventListener('notification', (e) => {
        this.console.log(`notification`, e);
      });
    });
  }

  joinRoom(roomId) {
    return new Promise((resolve, reject) => {
      if (!roomId) {
        this.console.warn('Could not join room, room ID not provided');

        reject('Could not join room, room ID not provided');

        return;
      }

      let onJoin;

      onJoin = (e) => {
        this.webRtc.removeEventListener('createdRoom', onJoin);
        this.webRtc.removeEventListener('joinedRoom', onJoin);

        const roomId = e.detail.roomId;
        const socketId = this.socket.id;

        resolve({
          roomId,
          socketId,
        });
      };

      this.webRtc.addEventListener('createdRoom', onJoin);
      this.webRtc.addEventListener('joinedRoom', onJoin);

      this.console.log(`Joining room with ID ${roomId}..`);

      this.webRtc.joinRoom(roomId);
    });
  }

  leaveRoom() {
    this.webRtc.leaveRoom();
  }

  getState() {
    return this.state;
  }

  isConnected() {
    return this.state === VOICE_CHAT_STATE_CONNECTED || this.isInRoom();
  }

  isInRoom() {
    return this.state === VOICE_CHAT_STATE_IN_ROOM;
  }
}
