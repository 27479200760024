<template>
  <div class="indicator" :class="open ? 'indicator-open' : 'indicator-closed'" :style="style">
    <div class="indicator-row d-flex" @click="toggleOpen">
      <div class="name">{{ data.name }}.</div>
      <div class="description flex-fill text-start">{{ data.description }}</div>
      <div class="count"></div>
      <div class="triangle"><img src="@/assets/icons/triangle-up.png" /></div>
    </div>
    <accordion :open="open">
      <div class="capacity-level-wrapper">
        <div class="capacity-level-label-container">
          <label>{{ $t('dashboard.capacityLevel') }}:</label>
        </div>
        <div class="capacity-level-container">
          <capacity-level :capacity-level="capacityLevel" />
        </div>
      </div>
    </accordion>
  </div>
</template>

<script>
import { getDashboardIndicatorColor } from '@/components/dashboard/CategoryColorUtils'
import Accordion from '@/components/common/Accordion.vue'
import CapacityLevel from '@/components/dashboard/CapacityLevel.vue'
import { getCapacityLevelFromJeeScore } from '@/components/dashboard/CapacityLevelUtil'

export default {
  name: 'DashboardIndicator',
  components: { CapacityLevel, Accordion },
  props: {
    data: {
      type: Object,
      required: true,
    },
    identifier: {
      type: String,
      required: true
    },
    jeeScore: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      open: true,
    }
  },
  mounted() {
    console.log('TEST MOUNTED');
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
  computed: {
    capacityLevel() {
      return getCapacityLevelFromJeeScore(this.jeeScore);
    },
    style() {
      return {
        'background': `${getDashboardIndicatorColor(this.identifier, .6)}`,
      }
    },
  },
}
</script>
<style lang="scss">
.indicator {
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
  color: #FFFFFF;

  .name, .count, .capacity-level-label-container {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
  }

  .capacity-level-wrapper {
    margin-left: auto;
    margin-right: 100px;

    padding-top: 10px;
    padding-bottom: 30px;

    width: 280px;

    .capacity-level-label-container {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .count {
    width: 60px;
    min-width: 60px;
    text-align: left;
  }

  .indicator-row {
    user-select: none;
    padding: 10px;
    cursor: pointer;

    .name {
      min-width: 80px;
      width: 80px;
      text-align: left;
    }

    .triangle {
      min-width: 26px;
      width: 26px;
      height: 26px;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
    }
  }

  &.indicator-open {
    .indicator-row {
      .triangle {
        transform: rotate(180deg);
      }
    }
  }
}

.indicators {
  padding: 0 20px;

  .indicator {
    margin-top: 7px;
  }
}

</style>
