<template>
  <lightbox :background-opacity=".8" :background="'000000'">
    <notification-assessment @continue="onContinue" @commit="onCommit" />
  </lightbox>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EpiReportPopup from '@/components/EpiReport/EpiReportPopup/index.vue'
import LobbyPopup from '@/components/Lobby/LobbyPopup.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import NotificationAssessment from '@/components/NotificationAssessment/index.vue'
import Lightbox from '@/components/common/Lightbox.vue'

export default {
  name: 'NotificationAssessmentPopup',
  components: {
    Lightbox,
    NotificationAssessment,
  },
  emits: ['continue', 'commit'],
  methods: {
    onContinue() {
      this.$emit('continue');
    },
    onCommit(answersGiven) {
      this.$emit('commit', answersGiven);
    },
  },
}
</script>
