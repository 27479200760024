<template>
  <div class="role-progress-ball" :class="computedClasses" :title="title">
    <div class="role-progress-ball-fill-container" :style="{ height: (progressPercentile * fullHeight) + 'px' }">
      <div class="role-progress-ball-fill" />
    </div>
    <role-icon class="role-progress-icon" :role="role?.icon" />
  </div>
</template>

<script>
import RoleIcon from '@/components/Icon/RoleIcon.vue'

export default {
  name: 'RoleProgressBall',
  components: { RoleIcon },
  props: {
    role: {
      type: Object,
      required: true,
    },
    showPoints: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentPoints: {
      type: Number,
      required: false,
      default: 0,
    },
    totalPoints: {
      type: Number,
      required: false,
      default: 60,
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
    withBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return [
        `role-progress-ball-size-${this.size}`,
        `role-progress-ball-points-${this.showPoints ? 'on' : 'off'}`,
        `role-progress-ball-background-${this.withBackground ? 'on' : 'off'}`,
      ];
    },
    title() {
      return `${this.currentPoints} / ${this.totalPoints}`;
    },
    progressPercentile() {
      return 0; // No such thing as RP points any more, just return 0

      if (this.totalPoints <= 0 || !this.showPoints) {
        return 0;
      }

      const offset = 0.15;

      return offset + (Math.max(0, Math.min(1, (this.currentPoints / this.totalPoints))) * (1 - offset));
    },
    modifier() {
      if (this.size === 'tiny' || this.size === 'xs') {
        return 0.6;
      } else if (this.size === 'small' || this.size === 'sm') {
        return 0.8;
      } else if (this.size === 'medium-small' || this.size === 'ms') {
        return 1.0;
      } else if (this.size === 'medium' || this.size === 'md') {
        return 1.2;
      } else if (this.size === 'large' || this.size === 'lg') {
        return 1.6;
      } else if (this.size === 'extra-large' || this.size === 'xl') {
        return 2;
      }

      return 1;
    },
    fullHeight() {
      const baseModifier = 0.8;

      return 58 * this.modifier * baseModifier;
    }
  }
}
</script>

<style lang="scss" scoped>
$baseModifier: 0.8;

$width: 57px * $baseModifier;
$height: 58px * $baseModifier;

$modifierTiny: 0.6;
$modifierSmall: 0.8;
$modifierMediumSmall: 1.0;
$modifierMedium: 1.2;
$modifierLarge: 1.6;
$modifierExtraLarge: 2;

$offsetTop: 3px;

@mixin role-progress-ball-size($size, $sizeAlias, $modifier) {
  &.role-progress-ball-size-#{$size}, &.role-progress-ball-size-#{$sizeAlias} {
    width: $width * $modifier;
    height: $height * $modifier;

    .role-progress-ball-fill {
      width: $width * $modifier;
      height: $height * $modifier;
    }

    &.role-progress-ball-background-on {
      .role-progress-icon {
        //top: $offsetTop * $modifier;
      }
    }
  }
}

.role-progress-ball {
  border-radius: 50%;

  &.role-progress-ball-background-on {
    background: #38708F;
    box-shadow: 0px 0.572917px 2.86458px 1.14583px rgba(0, 0, 0, 0.5), inset 0px -2.29167px 1.14583px 0.572917px #071C27, inset -2.29167px 1.14583px 1.71875px rgba(255, 255, 255, 0.25), inset 2.29167px 0px 1.71875px rgba(239, 239, 239, 0.25);
    //padding: 10px;
    position: relative;

    .role-icon {
      //top: 50%;
      //left: 50%;
      transform: translateY(-2px);
      //width: 70%;
      //height: 70%;
    }
  }

  //background-size: contain;
  position: relative;
  display: inline-block;

  .role-progress-ball-fill-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    transition: 200ms height ease-in-out;
  }

  .role-progress-ball-fill {
    border-radius: 50%;
    background: #F09000;
    box-shadow: 0px 0.833333px 4.16667px rgba(0, 0, 0, 0.5), inset 0px -3.33333px 1.66667px #071C27;

    background-size: 100%;
    width: 100%;
    height: 100%;

    position: absolute;
    bottom: 0;
    left: 0;
  }

  .role-progress-icon {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @include role-progress-ball-size('tiny', 'xs', $modifierTiny);
  @include role-progress-ball-size('small', 'sm', $modifierSmall);
  @include role-progress-ball-size('medium-small', 'ms', $modifierMediumSmall);
  @include role-progress-ball-size('medium', 'md', $modifierMedium);
  @include role-progress-ball-size('large', 'lg', $modifierLarge);
  @include role-progress-ball-size('extra-large', 'xl', $modifierExtraLarge);
}
</style>
