<template>
  <div class="row mt-2">
    <div class="col" v-for="iconIdentifier in iconIdentifiers">
      <floating-hazard-icon
        v-if="show"
        :identifier="iconIdentifier"
        :flag-count="flagCount"
        :severity="severity > 0 ? severity : null"
        :type="type !== 'auto' ? type : (iconIdentifier === 'news' ? 'news' : 'hazard')"
      />
    </div>
  </div>



  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Show total</label>
      <select v-model.number="enableShow" class="form-select">
        <option value="1">
          Yes
        </option>
        <option value="0">
          No
        </option>
      </select>
    </div>
    <div class="row">
      <label class="form-label">Type</label>
      <select v-model.number="type" class="form-select">
        <option value="auto">
          Auto
        </option>
        <option value="news">
          News
        </option>
        <option value="hazard">
          Hazard
        </option>
      </select>
    </div>
    <div class="row">
      <label class="form-label">Severity</label>
      <input type="range" class="form-control" v-model="severity" min="0" max="5" />
    </div>
    <div class="row">
      <label class="form-label">Flag count</label>
      <input type="range" class="form-control" v-model="flagCount" min="0" max="10" />
    </div>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'

export default {
  name: 'FloatingHazardIconDev',
  components: {
    DevSettingsPanel,
    BootstrapSelect,
    FloatingHazardIcon,
    ButtonContainer
  },
  extends: DevComponentContainer,

  data () {
    return {
      iconIdentifiers: Object.keys(HazardIconData),

      enableShow: 1,
      flagCount: 0,
      severity: 0,
      type: 'auto',
    }
  },
  computed: {
    show() {
      return !!this.enableShow;
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
