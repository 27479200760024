<template>
  <meeting-popup
    v-if="actionCategories"
    :action-categories="actionCategories"
    :meeting-type="meetingType"
    :hazard-icon="hazardIcon"
    :investments="investments"
    :players="players"
    @continue="onContinue"
    @player-investment-change="onPlayerInvestmentChange"
    @action-added="onActionAdded"
    @action-removed="onActionRemoved"
  />

  <dev-settings-panel>
    <div class="row">
      <button class="btn" :class="meetingType === 'hazard' ? 'btn-success' : 'btn-dark'" @click="meetingType = 'hazard'">Hazard</button>
      <button class="btn" :class="meetingType === 'strategic' ? 'btn-success' : 'btn-dark'" @click="meetingType = 'strategic'">Strategic</button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import { generatePlayerData, generatePlayersData } from '@/utils/playerDataGenerator'

export default {
  name: 'MeetingPopupDev',
  components: {
    DevSettingsPanel,
    MeetingPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['continue'],
      actionCategories: [],

      meetingType: 'hazard',
      hazardIcon: 'animal',

      players: {},
      investments: [],
    }
  },
  computed: {
    ...mapState(['background']),
  },
  mounted() {
    this.players = generatePlayersData();

    this.$store.commit('background', 'roundtable');

    this.$apiClient.get('/mission/strategic').then((data) => {
      this.actionCategories = data.categories;

      this.generateRandomInvestments();
    });
  },
  unmounted () {
    if (this.background === 'roundtable') {
      this.$store.commit('background', '');
    }
  },
  methods: {
    generateRandomInvestments() {
      for (const category of this.actionCategories) {
        for (const tab of category.tabs) {
          for (const action of tab.actions) {
            for (const playerCode in this.players) {
              if (Math.random() < .3) {
                this.investments.push({
                  actionIdentifier: action.identifier,
                  playerCode: playerCode,
                  investment: this.meetingType === 'hazard' ? Math.floor((Math.random() * 1) + 1) : action.cost,
                })
              }
            }
            console.log('action', action);
          }
        }
      }
    },
    onPlayerInvestmentChange(e) {
      const { action, newInvestment } = e;

      let existingInvestmentIndex = null;

      for (let i = 0, len = this.investments.length; i < len; i++) {
        const investment = this.investments[i];
        const player = this.players[investment.playerCode];

        if (!investment || !player) {
          continue;
        }

        if (investment.actionIdentifier === action.identifier && player.isSelf) {
          existingInvestmentIndex = i;

          break;
        }
      }

      let existingInvestment = existingInvestmentIndex !== null ? this.investments[existingInvestmentIndex] : null;

      console.log({
        action,
        newInvestment,
        existingInvestmentIndex,
        existingInvestment,
        investments: this.investments,
      })

      if (existingInvestment) {
        if (newInvestment > 0) {
          existingInvestment.investment = newInvestment;
        } else {
          this.investments.splice(existingInvestmentIndex, 1);
        }
      } else {
        if (newInvestment > 0) {
          this.investments.push({
            actionIdentifier: action.identifier,
            playerCode: '111111',
            investment: newInvestment,
          })
        }
      }
    },
    recalculateInvestments() {
      if (this.meetingType === 'strategic') {
        // This is done when the action is added
      }
    },
    onActionAdded(action) {
      this.investments.push({
        actionIdentifier: action.identifier,
        playerCode: '111111',
        investment: Math.floor((Math.random() * 2) + 1),
      });

      console.log('onActionAdded', action);
    },
    onActionRemoved(action) {
      for (let i = this.investments.length - 1; i >= 0; i--) {
        const investment = this.investments[i];

        if (action.identifier === investment.actionIdentifier) {
          this.investments.splice(i, 1);
        }
      }
    },
    onContinue() {
      this.triggerEvent('continue')
    }
  },
}
</script>

<style lang="scss" scoped>
.strategic-meeting-popup {
  margin: 0 auto;
  //transform: scale(70%);
}
</style>
