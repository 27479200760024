<template>
  <div class="meeting-popup-category" :class="computedClasses">
    <div class="meeting-popup-category-header" @click="toggle">
      <span>{{ category.title }}</span>
      <div class="header-chevron-container">
        <chevron :model-value="isOpen" />
      </div>
    </div>

    <div class="meeting-popup-category-tabs">
      <accordion :open="isOpen">
        <meeting-popup-category-tab
          v-for="tab in category.tabs"
          :tab="tab"
          @select-tab="onSelectTab"
          :is-active="activeTabIdentifier === tab.identifier"
          :lookers="tabLookers[tab.identifier] ? tabLookers[tab.identifier] : []"
        />
      </accordion>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion.vue'
import MeetingPopupCategoryTab from '@/components/MeetingPopup/MeetingPopupCategoryTab.vue'
import Chevron from '@/components/common/Chevron.vue'

export default {
  name: 'MeetingPopupCategory',
  components: { Chevron, Accordion, MeetingPopupCategoryTab },
  props: {
    category: {
      type: Object,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeCategoryIdentifier: {
      type: String,
      required: false,
      default: null,
    },
    activeTabIdentifier: {
      type: String,
      required: false,
      default: null,
    },
    tabLookers: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    closeable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['select-tab'],
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    closeable() {
      this.checkIfOpen();
    },
    activeCategoryIdentifier() {
      this.checkIfOpen();
    }
  },
  mounted() {
    if (this.defaultOpen) {
      this.isOpen = true;
    }

    this.checkIfOpen();
  },
  computed: {
    isActive() {
      return this.activeCategoryIdentifier === this.category.identifier;
    },
    computedClasses() {
      return [
        `meeting-popup-category-${this.isActive ? 'active' : 'inactive'}`,
        `meeting-popup-closeable-${this.closeable ? 'true' : 'false'}`,
      ];
    }
  },
  methods: {
    toggle() {
      if (!this.closeable) {
        return;
      }

      this.isOpen = !this.isOpen;
    },
    checkIfOpen() {
      if (!this.closeable) {
        this.isOpen = true;

        return;
      }

      if (this.isOpen) {
        // Already open

        return;
      }

      if (this.isActive) {
        // Disabled 2023-11-15
        //this.isOpen = true;
      }
    },
    onSelectTab(tab) {
      this.$emit('select-tab', {
        category: this.category,
        tab: tab
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-popup-category-header {
  position: relative;

  background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), url("@/assets/textures/texture-20.png"), #1da15c;

  &:hover {
    background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), url("@/assets/textures/texture-20.png"), darken(#1da15c, 10%);
  }
  //background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);

  font-family: 'Josefin Sans';
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px 15px;
  font-size: 16pt;

  &.meeting-popup-closeable-true {
    cursor: pointer;
  }

  user-select: none;
  cursor: pointer;
}
.meeting-popup-category-active {
  .meeting-popup-category-header {
    text-decoration: underline;
  }
}

.meeting-popup-category-tabs {
  padding-top: 10px;
}

.header-chevron-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
