<template>
  <dev-settings-panel>
    <div class="row">
      <div class="col-5 text-start">
        <label class="form-label">Title</label>
      </div>

      <div class="col-7">
        <input type="text" class="form-control" v-model="title"/>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-5 text-start">
        <label class="form-label">Sub text</label>
      </div>

      <div class="col-7">
        <input type="text" class="form-control" v-model="subText"/>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-5 text-start">
        <label class="form-label">Duration ({{ durationMs }}ms)</label>
      </div>

      <div class="col-7">
        <input type="range" min="1000" max="10000" step="500" class="form-control" v-model.number="durationMs"/>
      </div>
    </div>
    <div class="row mt-2">
      <button class="btn btn-success btn-block" @click="pop" :class="{'disabled': !canPop}">Pop!</button>
    </div>
  </dev-settings-panel>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer';
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue';

export default {
  name: 'OverlayPopDev',

  components: {
    DevSettingsPanel

  },
  extends: DevComponentContainer,

  computed: {
    canPop () {
      return !!this.title;
    }
  },

  data () {
    return {
      durationMs: 5000,
      title: 'Overlay pop title',
      subText: 'Overlay pop subtext',
    };
  },
  methods: {
    pop () {
      if (!this.canPop) {
        return;
      }

      this.$overlayPop.pop(this.title, this.subText, this.durationMs);
    }
  }
};
</script>
