<template>
  <div class="chevron" :class="'chevron-' + (modelValue ? 'active' : 'inactive')">
    <no-drag-image src="@/assets/icons/triangle-up-hollow.png" />
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'

export default {
  name: 'chevron',
  components: {
    NoDragImage,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
};
</script>
<style lang="scss" scoped>
.chevron {
  display: inline-block;
  transition: 100ms ease-in-out;
  transform-origin: 50% 50%;

  &.chevron-active {
    transform: rotate(180deg);
  }
}
</style>
