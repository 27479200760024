<template>
  <div class="ui-timer-dev">
    <div>
      <button class="btn btn-success" @click="resetTimer">Reset timer</button>
    </div>

    <ui-timer :seconds-left="secondsLeft">
      <p>This is a timer hehe</p>
    </ui-timer>

    <ui-timer-toggle :seconds-left="secondsLeft" style="margin-top: 630px" button-text="Ready up" v-model="isReady" :player-count="playerCount" :skip-count="skipCount">
      <p>This is a toggle timer ({{ isReady ? 'ready' : 'not ready' }})</p>
    </ui-timer-toggle>
  </div>
</template>

<script>
import UiTimer from '@/components/player-ui/UiTimer/UiTimer.vue'
import LongButton from '@/components/common/LongButton.vue'
import UiTimerToggle from '@/components/player-ui/UiTimer/UiTimerToggle.vue'

export default {
  name: 'UiTimerDev',
  components: {
    UiTimerToggle,
    LongButton,
    UiTimer,
  },
  data() {
    return {
      secondsLeft: 0,
      tickTimer: null,

      isReady: false,

      playerCount: 3,
    }
  },
  computed: {
    skipCount() {
      if (this.isReady) {
        return 2;
      }

      return 1;
    },
  },
  mounted() {
    this.resetTimer();
  },
  methods: {
    resetTimer() {
      this.clearTimeout();

      this.secondsLeft = 300;

      this.startTimeout();
    },
    tick() {
      this.clearTimeout();

      this.secondsLeft -= 1;

      if (this.secondsLeft > 0) {
        this.startTimeout();
      }
    },
    startTimeout() {
      this.tickTimer = setTimeout(this.tick, 1000);
    },
    clearTimeout() {
      if (this.tickTimer) {
        clearTimeout(this.tickTimer);
      }

      this.tickTimer = null;
    },
  },
  unmounted () {
    this.clearTimeout();
  }
}
</script>
