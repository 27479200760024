<template>
  <div
    :class="['animating-number-theme-' + theme, 'animating-number-max-length-' + numberMaxLength]"
    class="animating-number-container"
  >
    <div :class="'animating-number-text-align-' + textAlign" :style="currentStyle"
         class="animating-number-current"
    >
      <div ref="animating-number-inner" :style="numberInnerStyle"
           class="animating-number-inner-container"
      >
        <div v-for="number in allNumbers" :key="number" class="animating-number-inner">
          {{ number }}
        </div>
      </div>
    </div>
    <div v-if="showTotal" class="animating-number-total">
      /{{ total }}
    </div>
  </div>
</template>

<script>
import { numberToRomanNumerals } from './romanNumeralUtil';

export default {
  name: 'AnimatingNumber',
  props: {
    current: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    duration: {
      type: String,
      required: false,
      default: '800ms',
    },
    topEasing: {
      type: Boolean,
      required: false,
      default: true,
    },
    leftEasing: {
      type: Boolean,
      required: false,
      default: true,
    },
    widthEasing: {
      type: Boolean,
      required: false,
      default: true,
    },
    includeZero: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
    transitionTimingFunction: {
      type: String,
      required: false,
      default: 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
    },
    widthLeftMultiplier: {
      type: Number,
      required: false,
      default: 0.6,
    },
    textAlign: {
      type: String,
      required: false,
      default: 'right',
    },
    theme: {
      type: String,
      default: 'grey',
      required: false,
    },
    emOffsetTop: {
      type: Number,
      default: 0,
      required: false,
    },
    emOffsetLeft: {
      type: Number,
      default: 0,
      required: false,
    },
    type: {
      type: String,
      default: 'number',
      required: false,
    },
  },
  emits: ['animationStart', 'animationEnd'],
  data() {
    return {
      numberMaxLength: 0,
      allNumbers: [],
    };
  },
  computed: {
    widthTransitionDuration() {
      if (!this.widthEasing) {
        return '0ms';
      }

      return this.duration;
    },
    leftTransitionDuration() {
      if (!this.leftEasing) {
        return '0ms';
      }

      return this.duration;
    },
    topTransitionDuration() {
      if (!this.topEasing) {
        return '0ms';
      }

      return this.duration;
    },
    totalCalc() {
      return this.total + 1;
    },
    totalOffset() {
      return this.includeZero ? 0 : 1;
    },
    totalNumberDigitCount() {
      return Math.floor(this.total).toString().length;
    },
    currentNumberDigitCount() {
      return Math.floor(this.current).toString().length;
    },
    currentStyle() {
      const numberLength = Math.max(1, this.currentNumberDigitCount);

      const animatingNumberWidth = `${this.widthLeftMultiplier * numberLength}em`;

      return {
        width: this.showTotal ? animatingNumberWidth : '100%',
        transition: `width ${this.widthTransitionDuration} ${this.transitionTimingFunction}`,
      };
    },
    numberInnerStyle() {
      const numberInnerTop = (this.current ? (this.current - 1) * -1 : 1) + this.emOffsetTop;
      const numberInnerLeft = ((this.totalNumberDigitCount - this.currentNumberDigitCount) * (this.widthLeftMultiplier * -1)) + this.emOffsetLeft;

      return {
        width: this.showTotal ? 'auto' : '100%',
        top: `${numberInnerTop}em`,
        left: this.textAlign === 'right' ? `${numberInnerLeft}em` : 'auto',
        'text-align': this.textAlign,
        transition: `top ${this.topTransitionDuration} ${this.transitionTimingFunction}, left ${this.leftTransitionDuration} ${this.transitionTimingFunction}`,
      };
    },
  },
  watch: {
    total() {
      this.recalculateNumbers();
    },
    includeZero() {
      this.recalculateNumbers();
    },
    type() {
      this.recalculateNumbers();
    },
  },
  mounted() {
    const animatingNumberInner = this.$refs['animating-number-inner'];

    animatingNumberInner.addEventListener('transitionstart', this.onAnimationStart);
    animatingNumberInner.addEventListener('transitionend', this.onAnimationEnd);

    this.recalculateNumbers();
  },
  unmounted() {
    const animatingNumberInner = this.$refs['animating-number-inner'];

    if (animatingNumberInner) {
      animatingNumberInner.removeEventListener('transitionstart', this.onAnimationStart);
      animatingNumberInner.removeEventListener('transitionend', this.onAnimationEnd);
    }
  },
  methods: {
    recalculateNumbers() {
      const allNumbers = [];
      let numberMaxLength = 0;

      const start = this.includeZero ? 0 : 1;
      const to = this.includeZero ? this.total - 1 : this.total;

      for (let i = start; i <= to; i += 1) {
        const numberString = this.getNumberString(i);

        numberMaxLength = Math.max(numberMaxLength, numberString.length);

        allNumbers.push(numberString);
      }

      this.allNumbers = allNumbers;
      this.numberMaxLength = numberMaxLength;
    },
    getNumberString(number) {
      if (this.type === 'roman') {
        return numberToRomanNumerals(number);
      }

      return number.toString();
    },
    onAnimationStart() {
      this.$emit('animationStart', this.current);
    },
    onAnimationEnd() {
      this.$emit('animationEnd', this.current);
    },
  },
};
</script>

<style lang="scss" scoped>
.animating-number-container {
  text-align: center;

  &.animating-number-theme-grey {
    .animating-number-inner {
      color: #212529;
    }
  }

  &.animating-number-theme-blue {
    .animating-number-inner {
      color: #EDF1F4;
    }
  }

  &.animating-number-max-length-6 {
    .animating-number-current {
      font-size: 5em;
    }
  }

  &.animating-number-max-length-7 {
    .animating-number-current {
      font-size: 4em;
    }
  }

  &.animating-number-max-length-8 {
    .animating-number-current {
      font-size: 4em;
    }
  }

  &.animating-number-max-length-9 {
    .animating-number-current {
      font-size: 3em;
    }
  }
}

.animating-number-current {
  font-size: 6em;
  font-weight: bold;
  line-height: 100%;
  height: 1em;
  overflow: hidden;
  position: relative;
  display: inline-block;
  top: 12px;

  transition-property: width;

  .animating-number-inner-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.animating-number-inner {
  user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -webkit-user-select: none !important;
}

.animating-number-total {
  font-size: 1.2em;
  font-weight: bold;
  opacity: 0.4;
  display: inline-block;
}
</style>
