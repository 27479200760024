<template>
  <div class="row mt-2">
    <end-game-popup-decision-not-unanimous
      :end-game-decision-attempts="endGameDecisionAttempts"
      :player-votes-all="playerVotesAll"
      :voted-continue="playerSelf.voteBackToDecision || false"
      @click:accept="onClickAccept"
    />
  </div>
</template>
<script>
import EndGamePopupDecisionNotUnanimous from '@/components/EndGamePopup/EndGamePopupDecisionNotUnanimous/index.vue'
import { mapGetters, mapState } from 'vuex'
import { objectToArray } from '@/utils/arrayUtil'

export default {
  name: 'EndGamePopupDecisionNotUnanimousLive',
  components: {
    EndGamePopupDecisionNotUnanimous,
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf']),
    endGameDecisionAttempts () {
      if (this.room && this.room.endGameDecisionAttempts) {
        return this.room.endGameDecisionAttempts
      }

      return 0
    },
    playerVotesAll () {
      const playerVotesAll = []

      if (this.room && this.room.players) {
        const players = objectToArray(this.room.players)

        if (players && players.length) {
          for (const player of players) {
            if (player.voteBackToDecision) {
              playerVotesAll.push(player)
            }
          }
        }
      }

      return playerVotesAll
    },
  },
  methods: {
    onClickAccept () {
      this.accepted = !this.accepted

      console.log('voteBackToDecision', this.accepted)

      this.$gameClient.playerUpdate('voteBackToDecision', this.accepted)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
