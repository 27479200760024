<script>
import EventTicks from "./EventTicks";

export default {
  components: { EventTicks },
  data() {
    return {
      //events: ['animationStart', 'animationEnd'],
      eventsTimeout: {},
      eventsActive: {},
    };
  },
  methods: {
    triggerEvent(event) {
      this.eventsActive[event] = true;

      if (this.eventsTimeout[event]) {
        clearTimeout(this.eventsTimeout[event]);

        this.eventsTimeout[event] = null;
      }

      this.eventsTimeout[event] = setTimeout(() => {
        this.eventsActive[event] = false;

        this.eventsTimeout[event] = null;
      }, 1000);
    },
  },
};
</script>
