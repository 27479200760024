import {HIGHLIGHT_TYPE_SELECTOR, POPUP_ANCHOR_AUTO, SHAPE_SQUARE} from '@/plugins/TutorialHighlightOverlay/enums'

export class TutorialHighlightOverlayPopEvent extends Event {
  text = null;
  highlight = null;
  highlightType = HIGHLIGHT_TYPE_SELECTOR;
  shape = SHAPE_SQUARE;
  offset = 0;
  popupAnchor = POPUP_ANCHOR_AUTO;
  clickThrough = false;
  animationLength = 1000;
  autoBoundDistance = 0.2;
  passOptions = {};

  constructor(
    text,
    highlight,
    highlightType = HIGHLIGHT_TYPE_SELECTOR,
    shape = undefined,
    offset = undefined,
    popupAnchor = undefined,
    clickThrough = undefined,
    animationLength = undefined,
    autoBoundDistance = undefined,
    passOptions = undefined
  )
  {
    super('pop');

    this.text = text;
    this.highlight = highlight;
    this.highlightType = highlightType;

    if (shape !== undefined) {
      this.shape = shape;
    }

    if (offset !== undefined) {
      this.offset = offset;
    }

    if (popupAnchor !== undefined) {
      this.popupAnchor = popupAnchor;
    }

    if (clickThrough !== undefined) {
      this.clickThrough = clickThrough;
    }

    if (animationLength !== undefined) {
      this.animationLength = animationLength;
    }

    if (autoBoundDistance !== undefined) {
      this.autoBoundDistance = autoBoundDistance;
    }

    if (passOptions !== undefined) {
      this.passOptions = passOptions;
    }

  }
}

export class TutorialHighlightOverlayCloseEvent extends Event {
  popOpenEvent = null;

  constructor(popOpenEvent = null) {
    super('close');

    this.popOpenEvent = popOpenEvent || null;
  }
}

export class TutorialHighlightOverlayResetEvent extends Event {
  popOpenEvent = null;
  callRejection = null;

  constructor(callRejection = false, popOpenEvent = null) {
    super('reset');

    this.callRejection = callRejection || false;
    this.popOpenEvent = popOpenEvent || null;
  }
}
