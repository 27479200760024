<template>
  <div class="error" v-if="error">
    <strong>Error:</strong> {{ error }}
  </div>

  <div class="row">
    <div class="col-12">
      <bootstrap-select
        v-model="modelValueInner"
        :options="inputDevices"
        :placeholder-text="inputDevices.length ? 'Select input device' : 'Could not find any input devices (possibly due to a lack of permission)'"
        :disabled="inputDevices.length === 0"
      />
    </div>
  </div>
</template>

<script>
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect'
import BootstrapProgress from '@/components/bootstrap/BootstrapProgress'
import {EVENT_FREQUENCY_CHANGED} from '@/plugins/VoiceController/events/FrequencyChangedEvent';

import IconMicrophone from '../assets/icon-microphone.png';
import IconMicrophoneMuted from '../assets/icon-microphone-muted.png';
import { LOCAL_STREAM_MUTED_CHANGED } from '@/plugins/VoiceController/events/LocalStreamMutedChangedEvent'

export default {
  components: {
    BootstrapProgress,
    BootstrapSelect,
  },
  props: {
    modelValue: {
      type: String,
    },
  },
  name: 'voice-controller-device-selector',
  emits: ['update:modelValue', 'update:muted'],
  data() {
    return {
      inputDevices: [],
      modelValueInner: this.modelValue || null,

      frequency: 0,

      error: null,

      muted: false,
    }
  },
  watch: {
    modelValue() {
      if (this.modelValueInner === this.modelValue) {
        return;
      }

      this.modelValueInner = this.modelValue || null;
    },
    modelValueInner() {
      if (this.modelValueInner === this.modelValue) {
        return;
      }

      this.$emit('update:modelValue', this.modelValueInner);

      this.requestStreamFromModelValue();
    }
  },
  mounted () {
    console.log('VoiceControllerDeviceSelector.mounted');

    this.enumerateInputDevices();

    this.$voiceController.addEventListener(EVENT_FREQUENCY_CHANGED, (e) => {
      if (e.uid !== 'self') {
        return;
      }

      this.frequency = this.muted ? 0 : e.frequency;
    });

    this.$voiceController.addEventListener(LOCAL_STREAM_MUTED_CHANGED, (e) => {
      this.muted = e.muted;
    });

    this.muted = this.$voiceController.localStreamMuted;
  },
  computed: {
    muteButtonIconSource() {
      if (this.muted) {
        return IconMicrophoneMuted;
      }

      return IconMicrophone;
    },
  },
  methods: {
    toggleMute() {
      console.log('localStreamMuted', this.muted);
      const newMuted = !this.muted;

      this.$voiceController.setLocalStreamMuted(newMuted);

      this.$emit('update:muted', newMuted);
    },
    enumerateInputDevices() {
      this.$voiceController.enumerateInputDevices().then((inputDevices) => {
        console.log('VoiceControllerDeviceSelector.mounted', inputDevices);

        const inputDevicesCalculated = [
          {
            value: 'DISABLE',
            label: '(disable)',
          }
        ];

        for (let i = 0, len = inputDevices.length; i < len; i++) {
          const inputDevice = inputDevices[i];

          if (!inputDevice.deviceId) {
            continue;
          }

          inputDevicesCalculated.push({
            value: inputDevice.deviceId,
            label: inputDevice.label,
          });
        }

        console.log('inputDevicesCalculated', inputDevicesCalculated);

        if (inputDevicesCalculated.length) {
          this.inputDevices = inputDevicesCalculated;

          if (!this.modelValueInner) {
            // If model value inner doesn't currently have a value, let's set the first and best one
            this.modelValueInner = this.inputDevices[1].value;
          }
        } else {
          throw new Error('Could not find any input devices (possibly due to a lack of permission)');
        }
      }).catch((e) => {
        this.inputDevices = [];

        this.error = e.message;
        this.modelValueInner = '';
      });

      if (this.modelValueInner) {
        this.requestStreamFromModelValue();
      }
    },
    requestStreamFromModelValue() {
      let promise, method;

      if (this.modelValueInner === 'DISABLE') {
        this.$voiceController.stopLocalStream();

        method = 'stopLocalStream';
      } else if (this.modelValueInner) {
        promise = this.$voiceController.requestLocalStream(this.modelValueInner);
        method = 'requestLocalStream';
      } else {
        promise = this.$voiceController.requestLocalStreamDefault();
        method = 'requestLocalStreamDefault';
      }

      console.log('method', method, this.modelValueInner);

      if (!promise) {
        return;
      }

      promise.then((stream) => {
        this.error = null;

        if (!this.inputDevices.length) {
          this.enumerateInputDevices();
        }

        // Successfully requested stream
        //console.log(`VoiceControllerDeviceSelector successfully requested stream (method: ${method})`, stream);
      }).catch((e) => {
        this.error = e.message;
        console.error('VoiceControllerDeviceSelector could not request stream: ' + e.message);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-mute {
  height: 55px;
  width: 100%;
  display: block;
  position: relative;
  padding: 10px;

  &:focus {
    outline: 0;
    border: 0;
    box-shadow: 0;
  }

  img {
    width: 35px;
    height: 35px;
    margin: 0 auto;
    max-width: 35px;
    max-height: 35px;
  }
}
</style>
