<template>
  <div class="flag-icon" :class="'flag-icon-text-size-' + textSize">
    {{ value !== null ? value : '' }}
  </div>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    textSize: {
      type: String,
      required: false,
      default: 'md',
    },
    value: {
      type: [Number, String],
      required: false,
      default: null,
    }
  }
}
</script>

<style lang="scss" scoped>
.flag-icon {
  background: url("@/assets/ui/flag.png");
  background-size: contain;
  width: 125px;
  height: 143px;

  font-weight: 700;
  color: white;


  &.flag-icon-text-size-md {
    padding: 36px 42px 74px 23px;
    font-size: 22pt;
  }

  &.flag-icon-text-size-sm {
    padding: 36px 42px 74px 20px;
    font-size: 17pt;
  }
}
</style>
