<template>
  <div class="risk-matrix-dev-container">
    <div class="risk-matrix-center-container">
      <star-minigame-risk-matrix
        :hazard-data-old="hazardDataOld"
        :hazard-data-new="hazardDataNew"
        :hazard-data-correct="hazardDataCorrect"
      />
    </div>
  </div>

  <dev-settings-panel>
    <button class="btn btn-xl btn-success" @click="randomize">Randomize</button>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import StarMinigameRiskMatrix from '@/components/StarMinigame/RiskMatrix/index.vue'

import { getStarData, getStarDataCorrect } from '../star-utils.js'
import { RiskMatrixSwitchValue } from '@/components/StarMinigame/constants'
import StarMinigameRiskMatrixSwitch from '@/components/StarMinigame/RiskMatrix/RiskMatrixSwitch.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import StarMinigame from '@/components/StarMinigame/index.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import TableMixins from '@/components/StarMinigame/TableMixins.vue'
import { pickRandom } from '@/utils/randomUtil'

export default {
  name: 'StarMinigameRiskMatrixDev',
  components: {
    BootstrapSelect,
    StarMinigame,
    DevSettingsPanel,
    StarMinigameRiskMatrixSwitch,
    StarMinigameRiskMatrix,
  },
  extends: DevComponentContainer,
  mixins: [TableMixins],

  data () {
    const hazardDataOld = [];
    const hazardDataCorrect = [];

    const StarData = getStarData();

    for (const dataRow of StarData) {
      hazardDataOld.push({
        hazardName: dataRow.hazardName,
        likelihood: dataRow.computedLikelihood,
        impact: dataRow.computedImpact,
      });
    }

    const StarDataCorrect = getStarDataCorrect();

    for (const dataRow of StarDataCorrect) {
      hazardDataCorrect.push({
        hazardName: dataRow.hazardName,
        likelihood: dataRow.computedLikelihood,
        impact: dataRow.computedImpact,
      });
    }

    return {
      hazardDataOld: hazardDataOld,
      hazardDataNew: this.generateRandomHazardData(),
      hazardDataCorrect: hazardDataCorrect,
    }
  },
  methods: {
    generateRandomHazardData() {
      const StarData = getStarData();

      const hazardData = [];

      const impacts = [
        'perennial',
        'partial',
        'moderate',
        'high',
        'veryHigh',
      ];

      const likelihoods = [
        'veryUnlikely',
        'unlikely',
        'likely',
        'veryLikely',
        'almostCertain',
      ];

      for (const dataRow of StarData) {
        const likelihood = pickRandom(likelihoods);
        const impact = pickRandom(impacts);

        // TODO: Algorithm!
        hazardData.push({
          hazardName: dataRow.hazardName,
          likelihood: likelihood,
          impact: impact,
        });
      }

      return hazardData;
    },
    randomize() {
      this.hazardDataNew = this.generateRandomHazardData();
    }
  }
}
</script>

<style lang="scss" scoped>
.risk-matrix-dev-container {
  margin: 0 auto;
  background: #3f3f3f;
  padding: 150px;
  width: 100%;
  height: 100%;
  position: relative;

  .risk-matrix-center-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
