export const CLOSE_NORMAL = 1000;
export const CLOSE_GOING_AWAY = 1001;
export const CLOSE_PROTOCOL_ERROR = 1002;
export const CLOSE_UNSUPPORTED = 1003;
export const CLOSE_NO_STATUS = 1005;
export const CLOSE_ABNORMAL = 1006;
export const UNSUPPORTED_PAYLOAD = 1007;
export const POLICY_VIOLATION = 1008;
export const CLOSE_TOO_LARGE = 1009;
export const MANDATORY_EXTENSION = 1010;
export const SERVER_ERROR = 1011;
export const SERVICE_RESTARTED = 1012;
export const TRY_AGAIN_LATER = 1013;
export const BAD_GATEWAY = 1014;
export const TLS_HANDSHAKE_FAILED = 1015;

/**
 * @see https://github.com/Luka967/websocket-close-codes#websocket-close-codes
 */
const errorCodes = {
  [CLOSE_NORMAL]: 'CLOSE_NORMAL',
  [CLOSE_GOING_AWAY]: 'CLOSE_GOING_AWAY',
  [CLOSE_PROTOCOL_ERROR]: 'CLOSE_PROTOCOL_ERROR',
  [CLOSE_UNSUPPORTED]: 'CLOSE_UNSUPPORTED',
  // 1004 not used
  [CLOSE_NO_STATUS]: 'CLOSE_NO_STATUS',
  [CLOSE_ABNORMAL]: 'CLOSE_ABNORMAL',
  [UNSUPPORTED_PAYLOAD]: 'UNSUPPORTED_PAYLOAD',
  [POLICY_VIOLATION]: 'POLICY_VIOLATION',
  [CLOSE_TOO_LARGE]: 'CLOSE_TOO_LARGE',
  [MANDATORY_EXTENSION]: 'MANDATORY_EXTENSION',
  [SERVER_ERROR]: 'SERVER_ERROR',
  [SERVICE_RESTARTED]: 'SERVICE_RESTARTED',
  [TRY_AGAIN_LATER]: 'TRY_AGAIN_LATER',
  [BAD_GATEWAY]: 'BAD_GATEWAY',
  [TLS_HANDSHAKE_FAILED]: 'TLS_HANDSHAKE_FAILED',
};

class WebsocketCloseCodeUtil {
  static getErrorCodeString(errorCode) {
    if (errorCodes.hasOwnProperty(errorCode)) {
      return errorCodes[errorCode];
    }

    return null;
  }

  static getErrorCode(errorCodeString) {
    for (let errorCode in errorCodes) {
      let _errorCodeString = errorCodes[errorCode];

      if (_errorCodeString === errorCodeString) {
        return errorCode;
      }
    }
  }
}

export {errorCodes, WebsocketCloseCodeUtil};
