<template>
  <strategic-meeting-popup v-if="actionCategories" :action-categories="actionCategories" @continue="onContinue" />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'StrategicMeetingPopupDev',
  components: {
    StrategicMeetingPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['continue'],
      actionCategories: [],
    }
  },
  computed: {
    ...mapState(['background']),
  },
  mounted() {
    this.$store.commit('background', 'roundtable');

    this.$apiClient.get('/mission/strategic').then((data) => {
      this.actionCategories = data.categories;
    });
  },
  unmounted () {
    if (this.background === 'roundtable') {
      this.$store.commit('background', '');
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    }
  },
}
</script>

<style lang="scss" scoped>
.strategic-meeting-popup {
  margin: 0 auto;
  //transform: scale(70%);
}
</style>
