<template>
  <div class="dashboard-popup-live">
    <dashboard-popup
      @close="onClose"
      :used-actions-strategic="usedActionsStrategic"
      :used-actions-response="usedActionsResponse"
      :current-month="currentMonth"
    />
  </div>
</template>
<script>
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import DashboardPopup from '@/components/dashboard/DashboardPopup.vue'
import { mapState } from 'vuex'
import {ensureArray} from '@/utils/arrayUtil';
import {randomBetween} from '@/utils/randomUtil';
import {
  getImplementedActionsFromRoom, implementedActionsToHazardActions,
  implementedActionsToStrategicActions
} from '@/components/dashboard/DashboardUtil';

export default {
  name: 'DashboardPopupLive',
  components: {
    DashboardPopup,
    ButtonContainer
  },
  computed: {
    ...mapState(['room']),

    jeeScores() {
      return this.room.jeeScores || {};
    },

    currentMonth() {
      return this.room.meetingNumber || 1;
    },

    implementedActions() {
      return getImplementedActionsFromRoom(this.room);
    },

    usedActionsStrategic() {
      return implementedActionsToStrategicActions(this.implementedActions);
    },

    usedActionsResponse() {
      return implementedActionsToHazardActions(this.implementedActions);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss">
.dashboard-popup-live {
  width: 100%;
  height: 100%;
}
</style>
