<template>
  <lightbox :background-opacity=".8" :background="'000000'" :push-top-ui="true">
    <div class="content">

      <h1>{{ $t('epiReport.header').replace('[MONTH]', currentMonth) }}</h1>

      <div class="epi-report-container epic-scrollbar-both">
        <table class="table table-striped table-bordered epi-report-table">
          <thead>
            <tr>
              <th class="id-column"></th>
              <th class="descriptor-column"></th>
              <th colspan="2" v-for="(month, n) in months" class="text-center">{{ n + 1 }}</th>
            </tr>
            <tr>
              <th class="id-column"></th>
              <th class="descriptor-column">
                {{ $t('epiReport.virusName') }}
              </th>
              <template v-for="(month, n) in months">
                <th>{{ $t('epiReport.cases') }}</th>
                <th>{{ $t('epiReport.deaths') }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(singleVirusData, n) in filteredViruses">
              <td class="id-column">
                {{ n + 1 }}
              </td>
              <td class="descriptor-column">
                {{ singleVirusData.virus }}
              </td>
              <template v-for="(monthData, n) in singleVirusData.data">
                <td class="td-cases" :class="['td-' + ((currentMonthMinusOne >= n) ? 'available' : 'unavailable')]">{{ monthData[0] }}</td>
                <td class="td-deaths" :class="['td-' + ((currentMonthMinusOne >= n) ? 'available' : 'unavailable')]">{{ monthData[1] }}</td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </lightbox>

  <div v-if="monthButtons" style="position: fixed; left: 0px; top: 0px; background: white; padding: 10px; z-index: 99999999999999999">
    <button class="btn btn-success btn-xs" @click="previousMonth">{{ $t('epiReport.buttonPreviousMonth') }}</button>
    <button class="btn btn-success btn-xs ms-2" @click="nextMonth">{{ $t('epiReport.buttonNextMonth') }}</button>
  </div>
</template>

<script>
import Lightbox from '@/components/common/Lightbox.vue'

import VirusData from '@/lib/epi-report.js';

export default {
  name: 'EpiReport',
  components: { Lightbox },
  props: {
    currentMonth: {
      type: Number,
      default: 1,
    },
    monthButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['previousMonth', 'nextMonth'],
  data() {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const months = [];

    for (let i = 0; i < 15; i++) {
      months.push(monthNames[i % 12]);
    }

    return {
      months: months,
      virusData: VirusData,
    }
  },
  computed: {
    filteredViruses() {
      const viruses = [];

      for (const virusData of this.virusData) {
        if (this.currentMonth < virusData.startingMonth) {
          continue;
        }

        let virusName = virusData.virus;

        if (virusData.nameChanges.length) {
          for (let nameChange of virusData.nameChanges) {
            if (this.currentMonth >= nameChange.month) {
              virusName = nameChange.newName;
            }
          }
        }

        viruses.push({
          virus: virusName,
          data: virusData.data,
        })
      }

      return viruses;
    },
    currentMonthMinusOne() {
      return this.currentMonth - 1;
    }
  },
  methods: {
    previousMonth() {
      this.$emit('previousMonth');
    },
    nextMonth() {
      this.$emit('nextMonth');
    },
  }
}
</script>

<style lang="scss">
.epi-report-container {
  width: 1350px;
  height: 900px;
  overflow: scroll;
}

.epi-report-table {
  font-size: 14pt;

  th, td {
    color: white;
    text-align: left;
  }

  .descriptor-column, .id-column {
    font-weight: bold;
  }

  tr:nth-child(even), th {
    background: linear-gradient(90deg, rgba(#0F3D3D, .8) -31.57%, rgba(#112B53, .8) 101.27%);
  }

  td {
    color: white;

    &.td-unavailable {
      filter: blur(5px);
    }

    &.td-cases, &.td-deaths {
      text-align: right;
      font-weight: bold;
    }

    &.td-deaths {
      //color: #aa0000;
    }
  }
}

.content {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);

  padding: 0px;

  h1 {
    color: white;
    font-size: 20pt;

    background: linear-gradient(90deg, rgba(#0F3D3D, .8) -31.57%, rgba(#112B53, .8) 101.27%);
    padding: 10px;
    text-align: center;
    margin-bottom: 0;
  }
}
</style>
