<template>
  <lobby-popup-template :show-header="true" :show-footer="false" :style="{ width: (1920 * 0.8) + 'px', height: (1080 * 0.7) + 'px' }" :use-overflow="false" :with-background="true" :center-position="true">
    <template v-slot:header>
      <div class="d-flex justify-content-around">
        <div>
          Actions: {{ investedActions.length }}
        </div>
        <div>
          Total cost: {{ totalCost }}
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div v-if="investedActions.length === 0" class="hazard-results-no-investments">
        <label>No actions were fully invested.</label>
      </div>
      <div v-else class="hazard-results-container-outer d-flex flex-nowrap gap-4 align-items-stretch h-100" :class="selectedActionIndex !== null ? 'hazard-results-container-outer-action-chosen' : ''">
        <div class="hazard-results-invested-actions-outer h-100">
          <div class="hazard-results-invested-actions epic-scrollbar h-100">
            <div
              v-for="(investedAction, n) in investedActions"
              class="hazard-result-invested-action"
              :class="'hazard-result-invested-action-' + (n === selectedActionIndex ? 'active' : 'inactive')"
              @click="clickAction(n)"
            >
              <h2>Cost: {{ investedAction.cost }}</h2>
              <p>{{ investedAction.text }}</p>
            </div>
          </div>
        </div>
        <div class="hazard-results-description-outer h-100" v-if="selectedAction">
          <div class="hazard-results-description d-flex flex-column align-items-stretch h-100 w-100">
            <h2>Feedback:</h2>
            <p class="flex-fill epic-scrollbar">{{ selectedAction.text }}</p>
          </div>
        </div>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import LongButton from '@/components/common/LongButton.vue'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue';

export default {
  name: 'HazardPopupResults',
  components: {MeetingResultsPopup, LongButton, FloatingHazardIcon, HazardIcon, LobbyPopupTemplate },
  props: {
    investedActions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selectedActionIndex: null,
    }
  },
  methods: {
    clickAction(index) {
      if (this.selectedActionIndex === index) {
        this.selectedActionIndex = null;
      } else {
        this.selectedActionIndex = index;
      }
    },
  },
  computed: {
    totalCost() {
      let totalCost = 0;

      for (const action of this.investedActions) {
        totalCost += action.cost;
      }

      return totalCost;
    },
    selectedAction() {
      if (this.selectedActionIndex === null) {
        return null;
      }

      return this.investedActions[this.selectedActionIndex];
    },
    description() {
      const selectedAction = this.selectedAction;

      if (!selectedAction) {
        return null;
      }

      return selectedAction.description;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.results-hazard-separator{
  background: rgba(33, 87, 137, 0.49);
  box-shadow: inset 4.39708px 4.39708px 3.51766px rgba(0, 0, 0, 0.4), inset -3.51766px -3.51766px 2.63825px rgba(255, 255, 255, 0.4);
  border-radius: 19.3471px;
  height: 10px;
  width: 100%;
  margin: 20px 0;
}

.results-hazard-container-outer {
  padding: 0 50px;
}

.results-hazard-container-inner {
  height: 100%;
  overflow-y: auto;
  max-height: $appHeight * 0.45; //45vh;
  padding-left: 30px;
  padding-right: 30px;
}

.hazard-results-invested-actions-outer {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  padding: 10px;
  width: 100%;
}

.hazard-result-invested-action {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: 80ms ease-out;

  &:hover {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), lighten(#051224, 5%);
    cursor: pointer;
  }

  &:active, &.hazard-result-invested-action-active {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), darken(#051224, 5%);
    cursor: pointer;
  }

  &.hazard-result-invested-action-active {
    margin-left: 30px;
  }

  p {
    text-align: left;
    color: white;
    padding: 10px;
    background: rgba(#000000, .33);
    border-radius: 4px;
    font-size: 16pt;
  }
}

.hazard-results-description {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -9.84387px -9.84387px 7.8751px rgba(0, 0, 0, 0.4), inset 7.8751px 7.8751px 5.90632px rgba(255, 255, 255, 0.4);
  padding: 10px;

  p {
    padding: 30px 10px;
    background: rgba(#000000, .33);
    border-radius: 4px;
    color: white;
    text-align: left;
    font-size: 16pt;
  }
}

.hazard-result-item {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
}

h2 {
  color: white;
  font-size: 25pt;
  font-weight: bold;
  text-align: left;
}

.hazard-results-invested-actions-outer,
.hazard-results-description {
  padding: 30px;
}

.hazard-results-container-outer-action-chosen {
  .hazard-results-invested-actions-outer {
    width: 50% !important;
  }

  .hazard-results-description-outer {
    width: 50% !important;
  }
}

.hazard-results-no-investments {
  color: #FFFFFF;
  font-size: 25pt;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
</style>
