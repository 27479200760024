<template>
  <hazard-popup-container :title="title" :icon="icon" size="md">
    <template v-slot:header>
      <no-drag-image :src="icon" class="icon" size="sm" />
      <span style="padding-left: 45px">{{  title }}</span>
    </template>
    <template v-slot:content>
      <div class="row inner-content">
        <div class="col-5 summary-text epic-scrollbar">
          <markdown-text>{{ text }}</markdown-text>
        </div>
        <div class="col-7">
          <div class="media-container">
            <media-container :source="mediaSource" width="100%" :height="400"/>
            <p v-text="mediaCaption" v-if="mediaCaption" class="media-caption"/>
          </div>

          <div class="button-container">
            <close-button @click="onClickCancel" />
          </div>
        </div>
      </div>
    </template>
  </hazard-popup-container>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import LongButton from '@/components/common/LongButton.vue'
import HazardActionTab from '@/components/HazardMeeting/HazardPopup/HazardActionTab.vue'
import HazardAction from '@/components/HazardMeeting/HazardPopup/HazardAction.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import MediaContainer from '@/components/common/media/MediaContainer.vue'
import FlagIcon from '@/components/player-ui/FlagIcon.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'HazardPopupSummary',
  components: {
    AppButton,
    CloseButton,
    FlagIcon,
    MarkdownText,
    HazardPopupContainer,
    HazardAction,
    LongButton,
    NoDragImage,
    HazardIcon,
    HazardActionTab,
    MediaContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    mediaSource: {
      type: String,
      required: true,
    },
    mediaCaption: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: false,
    },
    investigationCost: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'click:cancel',
    'click:investigate',
  ],
  methods: {
    onClickCancel () {
      this.$emit('click:cancel')
    },
    onClickInvestigate() {
      this.$emit('click:investigate');
    },
  },
}
</script>

<style lang="scss" scoped>
.inner-content {
  padding-top: 10px;
  padding-left: 50px;
  height: 100%;
}

.summary-text {
  color: white;
  text-align: left;
  font-size: 18pt;
  padding: 40px 50px;

  background: rgba(#000000, .5);
  border-radius: 20px;

  height: 100%;
  overflow-y: auto;

  max-height: 560px;
}

.media-container {
  padding: 20px;
}

.media-caption {
  color: white;
  margin-top: 10px;
  font-size: 16pt;
  text-align: left;
  padding: 0 20px;
}

.icon {
  position: absolute;
  top: -106px;
  left: -150px;
}

.button-close {
  position: absolute;
  top: -70px;
  right: -30px;
}
</style>
