import { getMockConsole } from '@/utils/getConsole';

export class Webrtc extends EventTarget {
  constructor(
    socket,
    pcConfig = null,
    console = null,
  ) {
    super();
    this.room;
    this.socket = socket;
    this.pcConfig = pcConfig;

    this._myId = null;
    this.pcs = {}; // Peer connections
    this.streams = {};
    this.inCall = false;
    this.isReady = false; // At least 2 users are in room
    this.isInitiator = false; // Initiates connections if true
    this._localStream = null;

    // Manage logging

    this.console = console || getMockConsole();

    // Initialize socket.io listeners
    this._onSocketListeners();
  }

  // Custom event emitter
  _emit(eventName, details) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        detail: details,
      })
    );
  }

  get localStream() {
    return this._localStream;
  }

  get myId() {
    return this._myId;
  }

  get isAdmin() {
    return this._isAdmin;
  }

  get roomId() {
    return this.room;
  }

  get participants() {
    return Object.keys(this.pcs);
  }

  gotStream() {
    if (this.room) {
      this._sendMessage({ type: 'gotstream' }, null, this.room);
    } else {
      this.console.warn('Should join room before sending stream');

      this._emit('notification', {
        notification: `Should join room before sending a stream.`,
      });
    }
  }

  joinRoom(room) {
    if (this.room) {
      this.console.warn('Leave current room before joining a new one');

      this._emit('notification', {
        notification: `Leave current room before joining a new one`,
      });
      return;
    }
    if (!room) {
      this.console.warn('Room ID not provided');

      this._emit('notification', {
        notification: `Room ID not provided`,
      });
      return;
    }
    this.socket.emit('create or join', room);
  }

  leaveRoom() {
    if (!this.room) {
      this.console.warn('You are currently not in a room');

      this._emit('notification', {
        notification: `You are currently not in a room`,
      });
      return;
    }
    this.isInitiator = false;
    this.socket.emit('leave room', this.room);
  }

  // Sets the local stream in case you want it from somewhere else
  setLocalStream(stream) {
    this.console.log('Received local stream via setLocalStream');

    const oldAudioTracks = [];
    const newAudioTracks = [];

    const oldAudioTrackIds = [];
    const newAudioTrackIds = [];

    if (this._localStream) {
      console.log('LOCAL STREAM BEFORE', this._localStream.id, this._localStream);

      for (const localStreamTrack of this._localStream.getAudioTracks()) {
        oldAudioTracks.push(localStreamTrack);
        oldAudioTrackIds.push(localStreamTrack.id);
      }
    }

    this._localStream = stream;

    console.log('Adding locale stream', this.pcs, this.streams);

    if (this._localStream) {
      console.log('LOCAL STREAM AFTER', this._localStream.id, this._localStream);

      for (const localStreamTrack of this._localStream.getAudioTracks()) {
        newAudioTracks.push(localStreamTrack);
        newAudioTrackIds.push(localStreamTrack.id);
      }
    }

    console.log('trackDebug', {
      oldAudioTracks: oldAudioTracks,
      newAudioTracks: newAudioTracks,
    })

    for (const socketId in this.pcs) {
      console.log('Adding locale stream for socket id', socketId, this.pcs[socketId]);
      const rtcPeerConnection = this.pcs[socketId];

      const senders = rtcPeerConnection.getSenders();

      for (const sender of senders) {
        if (!oldAudioTrackIds.includes(sender.track.id)) {
          continue;
        }

        if (newAudioTracks.includes(sender.track.id)) {
          continue;
        }

        sender.replaceTrack(newAudioTracks[0]);
      }
    }

    if (this.room) {
      //???
      this.gotStream();
    }
  }

  /**
   * Try connecting to peers
   * if got local stream and is ready for connection
   */
  _connect(socketId) {
    if (typeof this._localStream !== 'undefined' && this.isReady) {
      this.console.log('Create peer connection to ', socketId);

      this._createPeerConnection(socketId);
      this.pcs[socketId].addStream(this._localStream);

      if (this.isInitiator) {
        this.console.log('Creating offer for ', socketId);

        this._makeOffer(socketId);
      }
    } else {
      this.console.warn('NOT connecting');
    }
  }

  /**
   * Initialize listeners for socket.io events
   */
  _onSocketListeners() {
    this.console.log('socket listeners initialized');

    // Room got created
    this.socket.on('created', (room, socketId) => {
      this.room = room;
      this._myId = socketId;
      this.isInitiator = true;
      this._isAdmin = true;

      this._emit('createdRoom', { roomId: room });
    });

    // Joined the room
    this.socket.on('joined', (room, socketId) => {
      this.console.log('joined: ' + room);

      this.room = room;
      this.isReady = true;
      this._myId = socketId;

      this._emit('joinedRoom', { roomId: room });
    });

    // Left the room
    this.socket.on('left room', (room) => {
      if (room === this.room) {
        this.console.warn(`Left the room ${room}`);

        this.room = null;
        this._removeUser();
        this._emit('leftRoom', {
          roomId: room,
        });
      }
    });

    // Someone joins room
    this.socket.on('join', (room) => {
      this.console.log('Incoming request to join room: ' + room);

      this.isReady = true;

      this.dispatchEvent(new Event('newJoin'));
    });

    // Room is ready for connection
    this.socket.on('ready', (user) => {
      this.console.log('User: ', user, ' joined room');

      if (user !== this._myId && this.inCall) this.isInitiator = true;
    });

    // Someone got kicked from call
    this.socket.on('kickout', (socketId) => {
      this.console.log('kickout user: ', socketId);

      if (socketId === this._myId) {
        // You got kicked out
        this.dispatchEvent(new Event('kicked'));
        this._removeUser();
      } else {
        // Someone else got kicked out
        this._removeUser(socketId);
      }
    });

    // Logs from server
    this.socket.on('log', (log) => {
      this.console.log.apply(console, log);
    });

    /**
     * Message from the server
     * Manage stream and sdp exchange between peers
     */
    this.socket.on('message', (message, socketId) => {
      this.console.log('From', socketId, ' received:', message.type);

      // Participant leaves
      if (message.type === 'leave') {
        this.console.log(socketId, 'Left the call.');
        this._removeUser(socketId);
        this.isInitiator = true;

        this._emit('userLeave', { socketId: socketId });
        return;
      }

      // Avoid dublicate connections
      if (
        this.pcs[socketId] &&
        this.pcs[socketId].connectionState === 'connected'
      ) {
        this.console.log(
          'Connection with ',
          socketId,
          'is already established'
        );
        return;
      }

      this.console.log('MESSAGE.TYPE', message.type);

      switch (message.type) {
        case 'gotstream': // user is ready to share their stream
          this._connect(socketId);
          break;
        case 'offer': // got connection offer
          if (!this.pcs[socketId]) {
            this._connect(socketId);
          }
          this.pcs[socketId].setRemoteDescription(
            new RTCSessionDescription(message)
          );
          this._answer(socketId);
          break;
        case 'answer': // got answer for sent offer
          this.pcs[socketId].setRemoteDescription(
            new RTCSessionDescription(message)
          );
          break;
        case 'candidate': // received candidate sdp
          this.inCall = true;
          const candidate = new RTCIceCandidate({
            sdpMLineIndex: message.label,
            candidate: message.candidate,
          });
          this.pcs[socketId].addIceCandidate(candidate);
          break;
      }
    });
  }

  _sendMessage(message, toId = null, roomId = null) {
    this.socket.emit('message', message, toId, roomId);
  }

  _createPeerConnection(socketId) {
    try {
      if (this.pcs[socketId]) {
        // Skip peer if connection is already established
        this.console.warn('Connection with ', socketId, ' already established');
        return;
      }

      this.pcs[socketId] = new RTCPeerConnection(this.pcConfig);
      this.pcs[socketId].onicecandidate = this._handleIceCandidate.bind(
        this,
        socketId
      );
      this.pcs[socketId].ontrack = this._handleOnTrack.bind(
        this,
        socketId
      );
      // this.pcs[socketId].onremovetrack = this._handleOnRemoveTrack.bind(
      //     this,
      //     socketId
      // );

      this.console.log('Created RTCPeerConnnection for ', socketId);
    } catch (error) {
      this.console.error('RTCPeerConnection failed: ' + error.message);

      this._emit('error', {
        error: new Error(`RTCPeerConnection failed: ${error.message}`),
      });
    }
  }

  /**
   * Send ICE candidate through signaling server (socket.io in this case)
   */
  _handleIceCandidate(socketId, event) {
    this.console.log('icecandidate event');

    if (event.candidate) {
      this._sendMessage(
        {
          type: 'candidate',
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
        },
        socketId
      );
    }
  }

  _handleCreateOfferError(event) {
    this.console.error('ERROR creating offer');

    this._emit('error', {
      error: new Error('Error while creating an offer'),
    });
  }

  /**
   * Make an offer
   * Creates session descripton
   */
  _makeOffer(socketId) {
    this.console.log('Sending offer to ', socketId);

    this.pcs[socketId].createOffer(
      this._setSendLocalDescription.bind(this, socketId),
      this._handleCreateOfferError
    );
  }

  /**
   * Create an answer for incoming offer
   */
  _answer(socketId) {
    this.console.log('Sending answer to ', socketId);

    this.pcs[socketId]
      .createAnswer()
      .then(
        this._setSendLocalDescription.bind(this, socketId),
        this._handleSDPError
      );
  }

  /**
   * Set local description and send it to server
   */
  _setSendLocalDescription(socketId, sessionDescription) {
    this.pcs[socketId].setLocalDescription(sessionDescription);
    this._sendMessage(sessionDescription, socketId);
  }

  _handleSDPError(error) {
    this.console.log('Session description error: ' + error.toString());

    this._emit('error', {
      error: new Error(`Session description error: ${error.toString()}`),
    });
  }

  _handleOnTrack(socketId, event) {
    this.console.log('Remote stream added for ', socketId);

    if (this.streams[socketId]?.id !== event.streams[0].id) {
      this.streams[socketId] = event.streams[0];

      this._emit('newUser', {
        socketId,
        stream: event.streams[0],
      });
    }
  }

  _handleUserLeave(socketId) {
    this.console.log(socketId, 'Left the call.');
    this._removeUser(socketId);
    this.isInitiator = false;
  }

  _removeUser(socketId = null) {
    if (!socketId) {
      // close all connections
      for (const [key, value] of Object.entries(this.pcs)) {
        this.console.log('closing', value);
        value.close();
        delete this.pcs[key];
      }
      this.streams = {};
    } else {
      if (!this.pcs[socketId]) return;
      this.pcs[socketId].close();
      delete this.pcs[socketId];

      delete this.streams[socketId];
    }

    this._emit('removeUser', { socketId });
  }
}
