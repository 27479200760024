<template>
  <div class="game-container" v-if="mainState === 'game'">
    <div class="main-game">
      <div class="debug-view" v-if="showDebugView">
        DEBUG
        <ul>
          <li><strong>hazardMeetingOpenId:</strong> {{ hazardMeetingOpenId }}</li>
          <li><strong>endFlowState:</strong> {{ endFlowState }}</li>
          <li><strong>meetingState:</strong> {{ meetingState }}</li>
          <li><strong>showStrategicMeeting:</strong> {{ showStrategicMeeting }}</li>
          <li><strong>showHazardMeetingLive:</strong> {{ showHazardMeetingLive }}</li>
          <li><strong>tutorialsRead:</strong> {{ tutorialsRead }}</li>
          <li><strong>tutorialId:</strong> {{ tutorialId }}</li>
        </ul>
      </div>
      <hazard-meeting-live v-if="showHazardMeetingLive" @close="hazardMeetingPopupClose"
                           @open:flagManagement="hazardMeetingOpenFlagManagement"/>

      <star-minigame-live v-if="showStarMinigame"/>

      <notification-assessment-popup v-if="showNotificationAssessment"/>

      <epi-report v-if="showEpiReport" :current-month="currentMonth"/>

      <strategic-meeting-live v-if="showStrategicMeeting"/>

      <lightbox v-if="errorMessage" :push-top-ui="true" background="#000000" :background-opacity=".8">
        <error-popup :error-message="errorMessage" @close="onCloseErrorPopup"/>
      </lightbox>

      <lightbox v-if="endFlowState" :push-top-ui="true" background="#000000" :background-opacity=".8">
        <end-game-popup-debriefing-live v-if="endFlowState === 'debriefing'"/>
        <end-game-popup-feedback-live v-else-if="endFlowState === 'feedback'" @click:continue="onFeedbackClickContinue"/>
        <end-game-popup-decision-live v-else-if="endFlowState === 'decision'"/>
        <end-game-popup-decision-not-unanimous-live v-else-if="endFlowState === 'decisionNotUnanimous'"/>
        <end-game-popup-intro v-else-if="endFlowState === 'intro'" @click:start="skippedEndFlowIntro = true"/>
      </lightbox>
      <!--<meeting-invite-bar
        class="meeting-invite-bar"
        v-if="canBeInvitedToMeeting"
        :icon="activeHazardMeetingInvite.worldHazard.icon"
        :seconds-left="hazardMeetingInviteSecondsLeft"
        @click:join-now="onHazardMeetingInviteClickJoinNow"
        @click:decline="onHazardMeetingInviteClickDecline"
      />-->

      <bling-layer-bottom/>

      <div class="floating-hazard-icons">
        <div class="floating-hazard-area-container">
          <div class="floating-hazard-area"/>
        </div>

        <floating-hazard-icon
          v-for="worldHazard in visibleWorldHazards"
          class="floating-hazard-icon"
          :identifier="worldHazard.identifier"
          :flag-count="worldHazard.flagCount"
          :style="{ left: worldHazard.left, top: worldHazard.top }"
          :clickable="worldHazardsClickable"
          :severity="worldHazard.severity"
          :type="worldHazard.type"
          :age="worldHazard.age"
          :decay-age="worldHazard.decayAge"
          @click="onClickFloatingHazardIcon(worldHazard)"
        />
      </div>

      <bling-layer-top/>
    </div>

    <div class="top-ui">
      <div class="toolbox-popup-container" v-if="showToolbox">
        <toolbox-popup @close="activePanel = null"/>
      </div>

      <div class="dashboard-popup-container" v-if="showDashboard">
        <dashboard-popup-live @close="activePanel = null"/>
      </div>


      <div class="flag-popup-container" v-if="showFlagManagement">
        <hazard-popup-flag-management-empty
          v-if="showHazardMeetingNoPrioritizations"
          @cancel="hazardMeetingCancelFlagManagement"
          @close="hazardMeetingCloseFlagManagement"
          @go-back="hazardMeetingGoBackFlagManagement"
          :can-go-back="hazardMeetingCanGoBack"
        />
        <hazard-popup-flag-management-live
          v-else
          @cancel="hazardMeetingCancelFlagManagement"
          @close="hazardMeetingCloseFlagManagement"
          @click:inspectFlag="hazardMeetingOnInspectFlag"
          :type="flagManagementShow ? 'swap' : 'manage'"
        />
      </div>

      <div class="dev-popup-container" v-if="showDevPanel">
        <dev-popup-live @close="activePanel = null"/>
      </div>

      <left-action-bar v-if="!hideBaseTopUi">
        <action-nav-button
          color="orange"
          :icon-src="iconMagnifier"
          @click="onClickDashboard"
          label="Dashboard"
          :is-active="showDashboard"
          class="action-bar-dashboard"
        />

        <action-nav-button
          color="blue"
          :icon-src="iconWrench"
          @click="onClickToolbox"
          label="Toolbox"
          :is-active="showToolbox"
          class="action-bar-toolbox"
        />

        <action-nav-button
          color="green"
          :icon-src="iconFlag"
          @click="onClickFlagManagement"
          label="Priorities"
          :is-active="showFlagManagement"
          :hide="!showFlagManagementButton"
          :amount="allFlagCount"
          class="action-bar-flag-management"
        />

        <action-nav-button
          v-if="showDevButton"
          color="red"
          :icon-src="iconDev"
          @click="onClickDev"
          label="Dev"
          :is-active="showDevPanel"
          class="action-bar-dev-panel"
        />
      </left-action-bar>

      <pause-overlay v-if="isPaused" :is-pauser="this.isPauser"/>

      <ui-timers/>

      <top-bar-live v-if="!hideBaseTopUi" @activate-dev-mode="onActivateDevMode" @pause-pressed="onPausePressed"
                    :tutorial-active="justProgressedTutorial"/><!--:z-index="isPaused ? 10000000000 : null"-->
      <player-avatar-circle-live class="player-avatar-circle"/>
      <right-player-list-bar-live class="right-player-list-bar"/>
    </div>

    <star-points v-if="showStarScoreImpact" :score-impacts="starScoreImpact"/>

    <div class="dev-panel" v-if="devMode" :class="'dev-panel-' + (devPanelOpen ? 'open' : 'closed')">
      <div class="dev-panel-popupout-button" @click="devPanelOpen = !devPanelOpen"></div>

      <div class="dev-panel-body" v-if="devPanelOpen">
        <button class="btn btn-success" @click="stateViewerOpen = !stateViewerOpen">State viewer</button>

        <state-viewer v-if="stateViewerOpen" class="dev-state-viewer"/>
      </div>
    </div>
  </div>
  <div class="predebriefing-screen" v-else-if="mainState === 'preDebriefing'">
    <teaser-logo
      :subtitle="$t('intro.logoEmergencyPreparedness')"
      :action="$t('end.preDebriefingSubtitle')"
      :button-text="$t('end.preDebriefingButtonText')"
      @button-click="onGoToDebriefing"
    />
  </div>
</template>

<script>

// Vue
import { mapGetters, mapState } from 'vuex';

// Components
import TopBar from '@/components/player-ui/TopBar';
import TeamMemberCard from '@/components/player-ui/TeamMemberCard';
import LeftActionBar from '@/components/player-ui/LeftActionBar';
import ActionNavButton from '@/components/player-ui/ActionNavButton';
import PlayerAvatarCircleLive from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircleLive';
import RightPlayerListBarLive from '@/components/player-ui/RightPlayerListBar/RightPlayerListBarLive';
import HazardPopupLive from '@/components/HazardMeeting/HazardPopup/HazardPopupLive';

// Avatar sprites
// Icons
import IconWrench from '@/assets/icons/icon-wrench.png';
import IconMagnifier from '@/assets/icons/icon-magnifier.png';
import IconBug from '@/assets/icons/icon-bug.png';
import IconFlag from '@/assets/icons/icon-flag.png';

import CharacterPlate from '@/components/character/CharacterPlate.vue';
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue';
import StateViewerPopup from '@/plugins/GameClient/components/StateViewerPopup.vue';
import StateViewer from '@/plugins/GameClient/components/StateViewer.vue';
import HazardMeetingLive from '@/components/HazardMeeting/HazardMeetingLive.vue';
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue';
import {
  HAZARD_MEETING_STATE_ENDED,
  HAZARD_MEETING_STATE_SHOW_ANNEX_2,
  HAZARD_MEETING_STATE_STARTED,
  HAZARD_MEETING_STATE_STARTING
} from '@/enum/HazardMeetingState';
import MeetingInviteBar from '@/components/MeetingInviteBar/index.vue';
import {
  ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_HOST,
  ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_PARTICIPANT
} from '@/enum/ActiveHazardMeetingPlayerState';
import ToolboxPopup from '@/components/toolbox/ToolboxPopup';
import DashboardPopupLive from '@/components/dashboard/DashboardPopupLive.vue';
import {
  TIMER_IDENTIFIER_EPI_REPORT,
  TIMER_IDENTIFIER_HAZARD_MEETING,
  TIMER_IDENTIFIER_HAZARD_MEETING_ENDING, TIMER_IDENTIFIER_HAZARD_MEETING_NO_PRIORITIZATIONS,
  TIMER_IDENTIFIER_HAZARD_MEETING_PRIORITIZATION,
  TIMER_IDENTIFIER_HAZARD_MEETING_STARTING,
  TIMER_IDENTIFIER_STRATEGIC_MEETING,
  TIMER_IDENTIFIER_STRATEGIC_MEETING_RESULTS
} from '@/enum/TimerIdentifier';
import UiTimerReadyLive from '@/components/player-ui/UiTimer/UiTimerReadyLive.vue';
import DevPopupLive from '@/components/dev/DevPopupLive.vue';
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue';
import HazardPopupFlagManagementLive
  from '@/components/HazardMeeting/HazardPopupFlagManagement/HazardPopupFlagManagementLive.vue';
import TopBarLive from '@/components/player-ui/TopBar/TopBarLive.vue';
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue';
import StrategicMeetingLive from '@/components/StrategicMeeting/StrategicMeetingLive.vue';
import {
  MEETING_STATE_END_DECISION,
  MEETING_STATE_END_DECISION_NOT_UNANIMOUS,
  MEETING_STATE_END_FEEDBACK,
  MEETING_STATE_EPI_REPORT,
  MEETING_STATE_HAZARD_MEETING,
  MEETING_STATE_HAZARD_MEETING_NO_PRIORITIZATIONS,
  MEETING_STATE_HAZARD_MEETING_PRIORITY,
  MEETING_STATE_STAR_MINIGAME,
  MEETING_STATE_STRATEGIC_MEETING,
  MEETING_STATE_STRATEGIC_MEETING_RESULTS
} from '@/enum/MeetingState';
import EpiReport from '@/components/EpiReport/EpiReportPopup/index.vue';
import UiTimers from '@/components/player-ui/UiTimer/UiTimers.vue';
import HazardPrioritizationPersonalLive
  from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPrioritizationPersonalLive.vue';
import { objectToArray } from '@/utils/arrayUtil';
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue';
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue';
import Lightbox from '@/components/common/Lightbox.vue';
import EndGamePopupDecisionLive from '@/components/EndGamePopup/EndGamePopupDecision/EndGamePopupDecisionLive.vue';
import EndGamePopupFeedbackLive from '@/components/EndGamePopup/EndGamePopupFeedback/EndGamePopupFeedbackLive.vue';

import TransitionScreenData from '@/lib/transition-screens';
import NotificationAssessmentPopup from '@/components/NotificationAssessment/NotificationAssessmentPopup.vue';
import ErrorPopup from '@/components/ErrorPopup/index.vue';
import EndGamePopupDebriefingLive
  from '@/components/EndGamePopup/EndGamePopupDebriefing/EndGamePopupDebriefingLive.vue';
import PauseOverlay from '@/components/player-ui/PauseOverlay.vue';
import OverlayPop from '@/plugins/OverlayPop/OverlayPop.vue';
import StarMinigameLive from '@/components/StarMinigame/StarMinigameLive.vue';
import { STAR_MINIGAME_STATE_NOT_BEGUN } from '@/enum/StarMinigameState';
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import MeetingPopupAction from '@/components/MeetingPopup/MeetingPopupAction.vue';
import HazardPopupFlagManagementEmpty
  from '@/components/HazardMeeting/HazardPopupFlagManagement/HazardPopupFlagManagementEmpty.vue';
import BlingLayerBottom from '@/components/bling/BlingLayerBottom.vue';
import BlingLayerTop from '@/components/bling/BlingLayerTop.vue';
import StarPoints from '@/components/StarPoints/index.vue';
import EndGamePopupDecisionNotUnanimousLive
  from '@/components/EndGamePopup/EndGamePopupDecisionNotUnanimous/EndGamePopupDecisionNotUnanimousLive.vue';
import TutorialOverlay from '@/components/player-ui/TutorialOverlay.vue';
import { TutorialGroupIdentifier, TutorialUtil } from '@/utils/tutorialUtil';
import TeaserLogo from '@/components/Teaser/TeaserLogo.vue';
import store from '@/store';
import { getTimerDurationSeconds } from '@/utils/timerData';

export default {
  name: 'GameView',
  components: {
    TeaserLogo,
    TutorialOverlay,
    EndGamePopupDecisionNotUnanimousLive,
    StarPoints,
    BlingLayerTop,
    BlingLayerBottom,
    HazardPopupFlagManagementEmpty,
    MeetingPopupAction,
    LobbyPopupTemplate,
    StarMinigameLive,
    OverlayPop,
    PauseOverlay,
    EndGamePopupDebriefingLive,
    ErrorPopup,
    NotificationAssessmentPopup,
    EndGamePopupFeedbackLive,
    EndGamePopupDecisionLive,
    Lightbox,
    EndGamePopupDecision,
    EndGamePopupIntro,
    HazardPrioritizationPersonalLive,
    UiTimers,
    EpiReport,
    StrategicMeetingLive,
    StrategicMeetingPopup,
    TopBarLive,
    HazardPopupFlagManagementLive,
    HazardPopupFlagManagement,
    DevPopupLive,
    PlayerAvatarCircleLive,
    RightPlayerListBarLive,
    DashboardPopupLive,
    ToolboxPopup,
    MeetingInviteBar,
    FloatingHazardIcon,
    HazardMeetingLive,
    StateViewer,
    StateViewerPopup,
    HazardPopupLive,
    PlayerCharacterPlate,
    CharacterPlate,
    TeamMemberCard,
    ActionNavButton,
    LeftActionBar,
    TopBar,
    UiTimerReadyLive,
  },
  data () {
    return {
      playerCharacterClickTimeout: null,
      playerCharacterPlateClickIterator: 0,

      devPanelOpen: false,
      devMode: false,
      stateViewerOpen: false,

      hazardMeetingPopupOpen: false,
      activeHazardMeetingInviteDeclined: false,

      iconMagnifier: IconMagnifier,
      iconWrench: IconWrench,
      iconFlag: IconFlag,
      iconDev: IconBug,

      flagManagementShow: false,

      activePanel: null,

      skippedEndFlowIntro: false,

      manualDevMode: false,

      showDebriefing: false,

      delayedMeetingState: null,

      justProgressedTutorial: false,

      isPauser: false,

      showDebugView: false,

      mainState: 'game', // 'preDebriefing'
    };
  },

  mounted () {
    if (!this.$gameClient.hasJoinedRoom()) {
      this.$router.push('/');

      return;
    }

    if (this.playerYou) {
      const inMeeting = [
        ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_HOST,
        ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_PARTICIPANT,
      ].includes(this.playerYou.activeHazardMeetingState);

      if (inMeeting) {
        this.$store.commit('hazardMeetingOpenId', 'ACTIVE');
      }
    }

    if (!this.isPaused && this.tutorialEnabled && this.tutorialsRead < this.tutorialId) {
      console.log('pauseModeEnable1');
      this.$gameClient.roomCallMethod('pauseMode:enable');
    } else if (!this.tutorialEnabled) {
      // No tutorial enabled!
      setTimeout(() => {
        TutorialUtil.markTutorialGroupOpened('mainUi');
        TutorialUtil.markTutorialOpened('mainUi.toolbox');

        this.$gameClient.playerCallMethod('closedTutorial', { tutorialId: 'mainUi.toolbox' });
      }, 200);
    }

    console.log('playerYou', this.playerYou);

    this.delayedMeetingState = this.meetingState;

    this.$store.commit('background', 'game');
    this.$store.commit('backgroundBlingTop', false);
    this.$store.commit('backgroundBlingBottom', false);

    if (this.meetingNumber > 0) {
      this.showMeetingOverlayPop();
    }
  },

  watch: {
    isPaused () {
      if (!this.isPaused && this.tutorialActive && this.tutorialEnabled) {
        console.log('pauseModeEnable2');
        this.$gameClient.roomCallMethod('pauseMode:enable');
      }

      if (this.isPauser && !this.isPaused) {
        this.isPauser = false;
      }
    },
    showFlagManagementButton () {
      if (!this.showFlagManagementButton) {
        // We no longer want to show the prioritization button, and we also don't want to show its popup window

        if (this.activePanel === 'priorities') {
          this.activePanel = null;
        }
      }
    },
    activeHazardMeetingInvite () {
      this.activeHazardMeetingInviteDeclined = false;
    },
    meetingNumber () {
      this.flagManagementShow = false;
      this.activePanel = null;

      this.hazardMeetingPopupClose();

      if (this.meetingNumber > 0) {
        this.showMeetingOverlayPop();
      }
    },

    async meetingState () {
      setTimeout(() => {
        this.delayedMeetingState = this.meetingState;
      }, 500);

      if ([
        MEETING_STATE_STRATEGIC_MEETING,
        MEETING_STATE_HAZARD_MEETING_PRIORITY,
        MEETING_STATE_HAZARD_MEETING,
        MEETING_STATE_EPI_REPORT
      ].includes(this.meetingState)) {
        this.$store.commit('topUiExtraTrust', 0);
        this.$store.commit('topUiExtraCollaboration', 0);
        this.$store.commit('topUiExtraHealthSecurity', 0);
        this.$store.commit('topUiExtraMgEventSuccess', 0);
        this.$store.commit('starScoreImpact', null);

        this.activePanel = null;
      }

      if ([
        MEETING_STATE_STRATEGIC_MEETING,
        MEETING_STATE_EPI_REPORT
      ].includes(this.meetingState)) {
        this.$store.commit('hazardMeetingOpenId', null);
      }

      let transitionScreenData;

      if (this.meetingState === MEETING_STATE_HAZARD_MEETING_PRIORITY) {
        transitionScreenData = structuredClone(TransitionScreenData['beforePrioritization']);
      } else if (this.meetingState === MEETING_STATE_EPI_REPORT) {
        transitionScreenData = structuredClone(TransitionScreenData['beforeEpiReport']);
      } else if (this.meetingState === MEETING_STATE_END_DECISION) {
        transitionScreenData = structuredClone(TransitionScreenData['beforeEndDecision']);
      } else if (this.meetingState === MEETING_STATE_END_FEEDBACK) {
        transitionScreenData = structuredClone(TransitionScreenData['beforeEndResult']);
      } else if (this.meetingState === MEETING_STATE_STAR_MINIGAME) {
        transitionScreenData = structuredClone(TransitionScreenData['beforeStarMinigame']);
      }

      if (transitionScreenData) {
        transitionScreenData.text = this.replaceDynamicTransitionScreenText(transitionScreenData.text);
        transitionScreenData.subText = this.replaceDynamicTransitionScreenText(transitionScreenData.subText);

        await this.$overlayPop.pop(transitionScreenData.text, transitionScreenData.subText, transitionScreenData.duration);

        if (this.meetingState === MEETING_STATE_HAZARD_MEETING_PRIORITY) {
          if (this.meetingNumber === 1) {
            await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.HazardMeetingMap);
          }
        } else if (this.meetingState === MEETING_STATE_EPI_REPORT) {
          await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.EpiReport);
        } else if (this.meetingState === MEETING_STATE_END_DECISION) {
          await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.End);
        }
      }

      if (this.meetingState === MEETING_STATE_STRATEGIC_MEETING) {
        if (TutorialUtil.isTutorialGroupFinished(TutorialGroupIdentifier.MainUi) && !TutorialUtil.isTutorialGroupFinished(TutorialGroupIdentifier.StrategicMeetingInvite)) {
          await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.StrategicMeetingInvite);
        }
      }
    }
  },
  computed: {
    ...mapState(['room', 'hazardMeetingOpenId', 'starScoreImpact']),
    ...mapGetters(['playerSelf', 'errorMessage', 'playerIsFacilitator']),
    tutorialActive () {
      return this.tutorialsRead >= 0 && this.tutorialsRead < this.tutorialId;
    },
    showStarScoreImpact () {
      if (!this.starScoreImpact) {
        return false;
      }

      if (typeof (this.starScoreImpact) !== 'object') {
        return false;
      }

      let hasNonZero = false;

      for (const key in this.starScoreImpact) {
        if (this.starScoreImpact[key] && this.starScoreImpact[key] > 0) {
          hasNonZero = true;
        }
      }

      return hasNonZero;
    },
    isPaused () {
      return this.room && this.room.isPaused;
    },
    hideBaseTopUi () {
      if (this.room) {
        if (this.room.activeStarMinigame && this.showStarMinigame) {
          if (this.room.activeStarMinigame.state !== STAR_MINIGAME_STATE_NOT_BEGUN) {
            return true;
          }
        }
      }

      return false;
    },
    endFlowState () {
      if (this.room.meetingState !== MEETING_STATE_END_FEEDBACK && this.room.meetingState !== MEETING_STATE_END_DECISION && this.room.meetingState !== MEETING_STATE_END_DECISION_NOT_UNANIMOUS) {
        return null;
      }

      if (this.showDebriefing) {
        return 'debriefing';
      }

      if (this.room.meetingState === MEETING_STATE_END_FEEDBACK) {
        return 'feedback';
      } else if (this.skippedEndFlowIntro) {
        if (this.room.meetingState === MEETING_STATE_END_DECISION_NOT_UNANIMOUS) {
          return 'decisionNotUnanimous';
        } else if (this.room.meetingState === MEETING_STATE_END_DECISION) {
          return 'decision';
        }
      }

      return 'intro';
    },

    showFlagManagementButton () {
      if (this.playerIsFacilitator) {
        return false;
      }
      return this.meetingState === MEETING_STATE_HAZARD_MEETING_PRIORITY;
    },
    allFlagCount() {
      if (!this.room || !this.room.players) {
        return 0;
      }

      let totalCount = 0;

      const playerIds = Object.keys(this.room.players);

      for (const playerId of playerIds) {
        const player = this.room.players[playerId];

        if (!player.worldHazardFlags) {
          continue;
        }

        const worldHazardFlags = objectToArray(player.worldHazardFlags);

        for (const worldHazardFlag of worldHazardFlags) {
          if (worldHazardFlag && worldHazardFlag.amount && worldHazardFlag.amount > 0) {
            totalCount += worldHazardFlag.amount;
          }
        }
      }

      return totalCount;
    },
    personalFlagCount () {
      if (!this.playerSelf || !this.playerSelf.worldHazardFlags) {
        return 0;
      }

      const worldHazardFlags = objectToArray(this.playerSelf.worldHazardFlags);

      if (!worldHazardFlags.length) {
        return 0;
      }

      let totalCount = 0;

      for (const worldHazardFlag of worldHazardFlags) {
        if (worldHazardFlag && worldHazardFlag.amount && worldHazardFlag.amount > 0) {
          totalCount += worldHazardFlag.amount;
        }
      }

      return totalCount;
    },
    worldHazardsClickable () {
      return [
        MEETING_STATE_HAZARD_MEETING,
        MEETING_STATE_HAZARD_MEETING_PRIORITY,
      ].includes(this.meetingState);
    },
    meetingState () {
      if (!this.room) {
        return null;
      }

      return this.room.meetingState;
    },
    showStrategicMeeting () {
      return this.meetingState === MEETING_STATE_STRATEGIC_MEETING || this.meetingState === MEETING_STATE_STRATEGIC_MEETING_RESULTS;
    },
    showHazardMeetingLive () {
      if (this.hazardMeetingOpenId !== null && this.hazardMeetingOpenId !== '' && this.hazardMeetingOpenId > 0) {
        // This might be 0 as it's the index
        // Hazard meeting open key is used for the initial intro where only one player looks at the hazard

        return true;
      }

      return [
        MEETING_STATE_HAZARD_MEETING_PRIORITY,
        MEETING_STATE_HAZARD_MEETING,
      ].includes(this.meetingState) && [
        HAZARD_MEETING_STATE_STARTING,
        HAZARD_MEETING_STATE_STARTED,
        HAZARD_MEETING_STATE_ENDED
      ].includes(this.room.hazardMeetingState);
    },
    showDevButton () {
      return this.manualDevMode || parseInt(process.env.VUE_APP_SHOW_DEV_BUTTON, 10) > 0;
    },
    epiReportTimerIdentifier () {
      return TIMER_IDENTIFIER_EPI_REPORT;
    },
    strategicMeetingResultsTimerIdentifier () {
      return TIMER_IDENTIFIER_STRATEGIC_MEETING_RESULTS;
    },
    strategicMeetingTimerIdentifier () {
      return TIMER_IDENTIFIER_STRATEGIC_MEETING;
    },
    hazardMeetingEndingTimerIdentifier () {
      return TIMER_IDENTIFIER_HAZARD_MEETING_ENDING;
    },
    hazardMeetingPrioritizationTimerIdentifier () {
      return TIMER_IDENTIFIER_HAZARD_MEETING_PRIORITIZATION;
    },
    hazardMeetingStartingTimerIdentifier () {
      return TIMER_IDENTIFIER_HAZARD_MEETING_STARTING;
    },
    hazardMeetingTimerIdentifier () {
      return TIMER_IDENTIFIER_HAZARD_MEETING;
    },
    canBeInvitedToMeeting () {
      if (this.activeHazardMeetingInviteDeclined) {
        return false;
      }

      if (!this.activeHazardMeetingInvite) {
        return false;
      }

      if (!this.playerYou) {
        return false;
      }

      console.log('this.playerYou', this.playerYou);

      if (this.playerYou.activeHazardMeetingState > 1) {
        return false;
      }

      return true;
    },
    visibleWorldHazards () {
      if (!this.room || !this.room.worldHazards) {
        return [];
      }

      const iconDatas = [];

      //console.log('this.room.worldHazards', this.room.worldHazards)

      const worldHazards = objectToArray(this.room.worldHazards);

      for (let i = 0, len = worldHazards.length; i < len; i++) {
        const worldHazard = worldHazards[i];

        if (!worldHazard.displayOnMap) {
          continue;
        }

        iconDatas.push({
          identifier: worldHazard.icon,
          type: worldHazard.type,
          left: worldHazard.mapLocation.x + '%',
          top: worldHazard.mapLocation.y + '%',
          key: i,
          id: worldHazard.id,
          flagCount: worldHazard.flagCount,
          severity: worldHazard.currentSeverity,
          age: worldHazard.age,
          decayAge: worldHazard.decayAge,
        });
      }

      return iconDatas;
    },
    activeHazardMeeting () {
      if (!this.room) {
        return null;
      }

      return this.room.activeHazardMeeting;
    },
    activeHazardMeetingInvite () {
      const activeHazardMeeting = this.activeHazardMeeting;

      if (!activeHazardMeeting) {
        return null;
      }

      if (activeHazardMeeting.state !== HAZARD_MEETING_STATE_STARTING) {
        return null;
      }

      return activeHazardMeeting;
    },
    hazardMeetingInviteSecondsLeft () {
      return Math.floor(this.room.timers.HazardMeetingInvite.msLeft / 1000);
    },
    otherPlayers () {
      if (!this.room.players) {
        return null;
      }

      const otherPlayers = {};

      for (const playerId in this.room.players) {
        const player = this.room.players[playerId];

        if (!player.isSelf) {
          otherPlayers[playerId] = player;
        }
      }

      return otherPlayers;
    },
    playerYou () {
      if (!this.room.players) {
        return null;
      }

      for (const playerId in this.room.players) {
        const player = this.room.players[playerId];

        if (player.isSelf) {
          return player;
        }
      }

      return null;
    },
    currentMonth () {
      if (!this.room) {
        return 1;
      }

      return this.room.meetingNumber;
    },
    meetingNumber () {
      if (!this.room) {
        return 0;
      }

      return this.room.meetingNumber;
    },
    activePanelCalc () {
      if (this.flagManagementShow) {
        return 'flagManagement';
      }

      return this.activePanel;
    },
    showNotificationAssessment () {
      return this.meetingState === MEETING_STATE_HAZARD_MEETING && this.room.hazardMeetingState === HAZARD_MEETING_STATE_SHOW_ANNEX_2;
    },
    showStarMinigame () {
      return this.delayedMeetingState === MEETING_STATE_STAR_MINIGAME;
    },
    showEpiReport () {
      return this.meetingState === MEETING_STATE_EPI_REPORT;
    },
    showHazardMeetingNoPrioritizations () {
      return this.meetingState === MEETING_STATE_HAZARD_MEETING_NO_PRIORITIZATIONS;
    },
    showDevPanel () {
      return this.activePanelCalc === 'dev';
    },
    showToolbox () {
      return this.activePanelCalc === 'toolbox';
    },
    showDashboard () {
      return this.activePanelCalc === 'dashboard';
    },
    showFlagManagement () {
      return this.showHazardMeetingNoPrioritizations || this.activePanelCalc === 'flagManagement';
    },
    tutorialActive () {
      return this.tutorialsRead < this.tutorialId;
    },
    tutorialEnabled () {
      return TutorialUtil.isTutorialEnabled();
    },
    hazardMeetingCanGoBack() {
      const timer = this.room.timers[TIMER_IDENTIFIER_HAZARD_MEETING_NO_PRIORITIZATIONS];

      let msLeft = 0;

      if (timer) {
        msLeft = timer.msLeft;
      }

      console.log('msLeft', msLeft, 'msTotal', timer.msTotal);
      return msLeft >= timer.msTotal;
    },
  },
  methods: {
    onFeedbackClickContinue() {
      this.mainState = 'preDebriefing';

      this.$nextTick(() => {
        this.showDebriefing = true;
      });
    },
    onGoToDebriefing() {
      this.mainState = 'game';
    },
    onPausePressed () {
      this.isPauser = true;
    },
    progressTutorial (tutorialId) {
      this.tutorialsRead = tutorialId;

      this.justProgressedTutorial = true;
      setInterval(() => {
        this.justProgressedTutorial = false;
      }, 20000);

    },
    showTutorial (tutorialId) {
      if (this.tutorialsRead < tutorialId) {
        if (this.tutorialEnabled) {
          console.log('pauseModeEnable3');
          this.tutorialId = tutorialId;
          this.$gameClient.roomCallMethod('pauseMode:enable');
        }
      }
    },
    onCloseErrorPopup () {
      this.$store.commit('errorMessage', null);
    },
    hazardMeetingOnInspectFlag (inspectHazard) {
      const worldHazards = objectToArray(this.room.worldHazards);

      for (let i = 0, len = worldHazards.length; i < len; i++) {
        const worldHazard = worldHazards[i];

        if (worldHazard.id === inspectHazard.id) {
          this.hazardMeetingCloseFlagManagement();

          this.$store.commit('hazardMeetingOpenId', worldHazard.id);
          this.$store.commit('hazardMeetingOpenState', 'summary');
        }
      }
    },
    replaceDynamicTransitionScreenText (text) {
      if (!text) {
        return text || '';
      }

      return text.replace(/\[MONTH\]/g, this.meetingNumber);
    },
    async showMeetingOverlayPop () {
      if (this.meetingNumber === 100) {
        // Fake meeting meaning end - do nothing here

        return;
      }

      const beforeMonthStart = structuredClone(TransitionScreenData['beforeMonthStart']);

      const text = this.replaceDynamicTransitionScreenText(beforeMonthStart?.text);
      const subText = this.replaceDynamicTransitionScreenText(beforeMonthStart?.subText);

      const duration = beforeMonthStart['duration'];

      console.log('BEFORE MONTH START', TransitionScreenData['beforeMonthStart'], this.meetingNumber);

      await this.$overlayPop.pop(text, subText, duration);

      if (this.meetingNumber === 1) {
        await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.MainUi);

        if (this.meetingState === MEETING_STATE_STRATEGIC_MEETING) {
          // We're already in this state
          await TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.StrategicMeetingInvite);
        }
      }
    },
    togglePanel (togglePanel) {
      if (this.activePanel !== togglePanel) {
        this.activePanel = togglePanel;
      } else {
        this.activePanel = null;
      }
    },
    onClickFlagManagement () {
      this.togglePanel('flagManagement');
    },
    onClickDev () {
      this.togglePanel('dev');
    },
    onClickToolbox () {
      this.togglePanel('toolbox');
    },
    onClickDashboard () {
      this.togglePanel('dashboard');
    },
    hazardMeetingPopupClose () {
      this.$store.commit('hazardMeetingOpenId', '');
    },
    onClickFloatingHazardIcon (worldHazardData) {
      if (this.hazardMeetingOpenId > 0) {
        console.log('hazardMeetingOpenId is more than 0');

        return;
      }

      if (!this.worldHazardsClickable) {
        console.log('world hazards not clickable');

        return;
      }

      /*if (this.activeHazardMeetingInvite) {
        return;
      }*/

      this.$store.commit('hazardMeetingOpenId', worldHazardData.id);
    },
    hazardMeetingCancelFlagManagement () {
      this.hazardMeetingCloseFlagManagement();
    },
    hazardMeetingCloseFlagManagement () {
      if (this.activePanel === 'flagManagement') {
        this.activePanel = null;
      }

      this.flagManagementShow = false;

      this.hazardMeetingPopupClose();
    },
    hazardMeetingGoBackFlagManagement() {
      if (!this.hazardMeetingCanGoBack) {
        console.warn(`hazardMeetingCanGoBack is false`);
        return;
      }

      this.$gameClient.roomCallMethod('flagManagementGoBack');
    },
    hazardMeetingOpenFlagManagement (hazardId) {
      this.flagManagementShow = true;
    },
    onActivateDevMode () {
      this.manualDevMode = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.floating-hazard-icons {
  .floating-hazard-icon {
    position: absolute;
    cursor: pointer;
  }
}

.meeting-invite-bar {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999999999;
}

.toolbox-popup-container,
.dashboard-popup-container,
.dev-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 120px 300px;
  background: rgba(0, 0, 0, .25);
}

.home {
  position: relative;
  z-index: 1;
}

.home, .main-game, .floating-hazard-icons {
  width: 100%;
  height: 100%;
  position: relative;
}

.floating-hazard-area-container {
  width: 100%;
  height: 100%;
  padding: 200px 300px;
  pointer-events: none !important;
  z-index: 999999999999;

  .floating-hazard-area {
    width: 100%;
    height: 100%;
    pointer-events: none !important;
    //background: rgba(#ffffff, 0);
  }
}

.top-ui {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.debug-view {
  z-index: 10000000000000;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  padding: 10px;
  color: white;
  text-align: left;

  ul, ul li {
    text-align: left;
  }

  ul {
    list-style: none;
  }
}

.game-container {
  width: 100%;
  height: 100%;
}
</style>
