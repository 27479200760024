<template>
  <player-avatar-circle
    class="player-avatar-circle-live"
    v-if="playerSelf"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PlayerAvatarCircle from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircle.vue'

export default {
  name: 'PlayerAvatarCircleLive',
  components: {
    PlayerAvatarCircle,
  },
  computed: {
    ...mapGetters(['playerSelf']),
  },
}
</script>

<style lang="scss" scoped>
.player-avatar-circle-live {
  position: fixed;
  left: 30px;
  bottom: 10px;

  z-index: 1000000000000;
}
</style>
