<template>
  <div ref="accordion" class="accordion" :style="style">
    <div ref="accordionInner" class="accordion-inner">
      <slot v-if="showContent" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      realOpen: false,
      fullyOpen: false,

      fullHeight: 0,
      showContent: false,
    };
  },
  computed: {
    style() {
      return {
        height: this.computedHeight
      };
    },
    computedHeight() {
      if (this.realOpen) {
        if (this.fullyOpen) {
          return 'auto';
        } else {
          return `${this.fullHeight}px`;
        }
      }

      return '0px';
    },
  },
  watch: {
    open() {
      this.recalculateOpenHeight();
    }
  },
  mounted() {
    this.$refs.accordion.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
    this.$refs.accordion.addEventListener('transitionend', this.onTransitionEnd);
    this.$refs.accordion.addEventListener('oTransitionEnd', this.onTransitionEnd);
    this.$refs.accordion.addEventListener('otransitionend', this.onTransitionEnd);
    this.$refs.accordion.addEventListener('transitionend', this.onTransitionEnd);

    if (this.open) {
      this.recalculateOpenHeight();
    } else {
      this.recalculateHeight();
    }
  },
  beforeUnmount () {
    this.$refs.accordion.removeEventListener('webkitTransitionEnd', this.onTransitionEnd);
    this.$refs.accordion.removeEventListener('transitionend', this.onTransitionEnd);
    this.$refs.accordion.removeEventListener('oTransitionEnd', this.onTransitionEnd);
    this.$refs.accordion.removeEventListener('otransitionend', this.onTransitionEnd);
    this.$refs.accordion.removeEventListener('transitionend', this.onTransitionEnd);
  },
  methods: {
    onTransitionEnd() {
      if (!this.open) {
        this.showContent = false;
      }

      this.fullyOpen = this.open;
    },
    recalculateHeight() {
      this.fullHeight = this.$refs.accordionInner.offsetHeight;
    },
    recalculateOpenHeight() {
      this.fullyOpen = false;

      if (this.open) {
        this.showContent = true;
      }

      requestAnimationFrame(() => {
        this.recalculateHeight();

        requestAnimationFrame(() => {
          this.realOpen = this.open;
        })
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  transition-duration: 200ms;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  overflow: hidden;
  height: 0;
}

</style>
