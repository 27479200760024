<template>
  <div class="container bling-layer-dev">
    <bling-layer-bottom
      :enable-waves="enableWaves"
      :enable-stream="enableStream"
      :enable-smoke="enableSmoke"
    />
    <bling-layer-top
      :enable-clouds="enableClouds"
      :enable-plane="enablePlane"
      :enable-dvd="enableDvd"
    />
  </div>

  <dev-settings-panel>
    <h3>Bottom layer</h3>
    <div class="row">
      <label class="form-label"><input v-model="enableClouds" type="checkbox" /> Enable clouds</label>
    </div>
    <div class="row">
      <label class="form-label"><input v-model="enablePlane" type="checkbox" /> Enable plane</label>
    </div>
    <div class="row">
      <label class="form-label"><input v-model="enableDvd" type="checkbox" /> Enable dvd</label>
    </div>

    <h3>Top layer</h3>
    <div class="row">
      <label class="form-label"><input v-model="enableWaves" type="checkbox" /> Enable waves</label>
    </div>
    <div class="row">
      <label class="form-label"><input v-model="enableStream" type="checkbox" /> Enable stream</label>
    </div>
    <div class="row">
      <label class="form-label"><input v-model="enableSmoke" type="checkbox" /> Enable smoke</label>
    </div>
  </dev-settings-panel>
</template>
<script>
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'

import BlingLayerTop from '@/components/bling/BlingLayerTop.vue'
import BlingLayerBottom from '@/components/bling/BlingLayerBottom.vue'

export default {
  name: 'BlingLayerDev',
  components: { DevSettingsPanel, BlingLayerTop, BlingLayerBottom },
  data() {
    return {
      // Bottom
      enableWaves: true,
      enableStream: true,
      enableSmoke: true,

      // Top
      enableClouds: true,
      enablePlane: true,
      enableDvd: true,
    }
  },
  mounted() {
    this.$store.commit('background', 'game')
  },
  unmounted () {
    this.$store.commit('background', '')
  },
}
</script>

<style lang="scss" scoped>
</style>
