<template>
  <voice-controller-device-selector v-model="voiceDeviceId" />

  <voice-controller-participants />

  <div>
    <strong>Current VoiceDeviceId</strong>: {{ voiceDeviceId ? voiceDeviceId : '(none)' }}
  </div>

  <voice-chat-client-manager />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4" />
</template>
<script>
import VoiceControllerDeviceSelector from '@/plugins/VoiceController/components/VoiceControllerDeviceSelector'
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'
import VoiceControllerParticipants from '@/plugins/VoiceController/components/VoiceControllerParticipants'
import VoiceChatClientManager from '@/plugins/VoiceChatClient/components/VoiceChatClientManager'
export default {
  name: 'Voice-Dev',
  components: { VoiceChatClientManager, VoiceControllerParticipants, VoiceControllerDeviceSelector, EventTicks, ButtonContainer },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    return {
      events: ['animationStart', 'animationEnd'],

      voiceDeviceId: null,
    }
  }
};
</script>
