<template>
  <ui-timer-ready-live
    v-for="timer in timers"
    class="ui-timer"
    :class="'ui-timer-' + timer.class"
    :button-text="timer.buttonText"
    :timer-identifier="timer.identifier"
    :hidden="timer.hidden"
    attribute="voteSkipTimer"
    :style="computedStyle"
  >
    {{ timer.label }}
  </ui-timer-ready-live>
</template>

<script>
import TimerData from '@/lib/timers';
import UiTimerReadyLive from '@/components/player-ui/UiTimer/UiTimerReadyLive.vue';

export default {
  name: 'UiTimers',
  components: {UiTimerReadyLive},
  props: {
    zIndex: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    computedStyle() {
      return {
        'z-index': this.zIndex || 500,
      };
    },
  },
  data() {
    const timers = [];

    // Set up all timers imported from TimerData
    for (const timerIdentifier in TimerData) {
      const timerDataSingle = TimerData[timerIdentifier];

      timers.push({
        class: timerDataSingle.identifier.replace(/[A-Z]/g, (replacement) => {
          return '-' + replacement.toLowerCase();
        }).replace(/^-+|-+$/, ''),
        buttonText: timerDataSingle.buttonText,
        identifier: timerDataSingle.identifier,
        label: timerDataSingle.label,
        hidden: timerDataSingle.hidden,
      })
    }

    return {
      timers: timers,
    }
  }

}
</script>

<style lang="scss">
.ui-timer {
  position: fixed;
  top: 120px;
  right: 20px;
}
</style>
