export class AlertPopEvent extends Event {
  type = 'pop';
  title = null;
  body = null;
  popData = {};

  constructor (title, body, popData = {}, type = 'pop') {
    super('pop');

    this.type = type || 'pop';
    this.title = title;
    this.body = body;
    this.popData = popData || {};
  }
}
