<template>
  <div class="strategic-meeting-action" :class="computedClass">
    <div class="strategic-meeting-action-outer">
      <div class="strategic-meeting-action-inner d-flex flex-column">
        <div class="flex-grow-1 p-1">
          <div class="content-container epic-scrollbar">
            {{ description }}
          </div>
        </div>
        <div class="footer-container flex-grow-0 d-flex">
          <div class="cost flex-grow-1">
            Cost: {{ cost }}
          </div>
          <div class="action">
            <app-button color="green" @click.prevent.stop="onAdd" height="sm" text-size="xs" width="fit-content" v-if="!isAdded" :disabled="!canInteract">
              <img src="@/assets/icons/icon-add.png" class="me-2" /> <label class="align-self-center">ADD</label>
            </app-button>
            <app-button color="red" @click.prevent.stop="onRemove" height="sm" text-size="xs" width="fit-content" v-else :disabled="!canInteract">
              <img src="@/assets/icons/icon-remove.png" class="me-2" /> <label class="align-self-center">REMOVE</label>
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'StrategicMeetingAction',
  props: {
    description: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    isAdded: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: { AppButton },
  emits: ['add', 'remove'],
  methods: {
    onAdd() {
      if (!this.canInteract) {
        return;
      }

      this.$emit('add');
    },
    onRemove() {
      if (!this.canInteract) {
        return;
      }

      this.$emit('remove');
    }
  },
  computed: {
    computedClass() {
      const classes = [];

      if (this.isAdded) {
        classes.push(`strategic-meeting-action-added`);
      }

      if (this.isSelected) {
        classes.push(`strategic-meeting-action-selected`);
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
$height: 270px;
$width: 200px;
$footerHeight: 50px;

.strategic-meeting-action {
  width: $width;
  min-width: $width;
  max-width: $width;
  height: $height;
  min-height: $height;
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;

  &.strategic-meeting-action-selected {
    box-shadow: 0 0px 15px 5px #A0D530;
  }
}

.strategic-meeting-action-outer,
.strategic-meeting-action-inner {
  width: 100%;
  height: 100%;
}

.strategic-meeting-action-outer {
  border-radius: 10px;
  overflow: hidden;
}

.strategic-meeting-action-inner {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), #000E21;
  box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
  color: white;
}

.content-container {
  width: 100%;
  height: calc($height - $footerHeight - 20px);
  padding: 10px;
  text-align: left;
}

.footer-container {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), #000E21;
  line-height: $footerHeight;
  height: $footerHeight;
  border-radius: 10px 0px 0px 10px;
  color: white;
  text-align: left;
  padding: 5px 10px;

  .action {
    position: relative;
    top: 4px;
  }
}
</style>
