<template>
  <div class="row mt-2">
    <error-popup
      :error-message="errorMessage"
      @close="onClose"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Error message</label>
      <input type="text" class="form-control" v-model="errorMessage" />
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue';
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'

export default {
  name: 'ErrorPopupDev',
  components: {
    EventTicks,
    DevSettingsPanel,
    BootstrapSelect,
    FloatingHazardIcon,
    ButtonContainer,
    ErrorPopup,
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['close'],

      errorMessage: 'Something went hopelessly wrong.',
    }
  },

  methods: {
    onClose() {
      this.triggerEvent('close');
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
