import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  state: {
    showHazardIconInfo: false,
  },
  mutations: {
    showHazardIconInfo: (state, value) => {
      state.showHazardIconInfo = value;
    },
  },
  getters: {
    showHazardIconInfo (state) {
      return state.showHazardIconInfo;
    },
  },
}
