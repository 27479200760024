<template>
  <hazard-popup-container size="sm">
    <template v-slot:header>
      {{ $t('prioritize.prioritizeHeader') }}
    </template>
    <template v-slot:content>
      <div class="hazard-popup-flag-management" :class="'hazard-popup-flag-management-type-' + type">
        <div v-if="type === 'swap'">
          <label class="all-flags-used">{{ $t('prioritize.usedAllFlags') }}</label>
        </div>
        <div v-else-if="type === 'manage'">
          <label class="all-flags-used">{{ $t('prioritize.prioritizedFollowingHazards') }}</label>
        </div>

        <div class="world-hazard-container d-flex gap-4 flex-nowrap epic-scrollbar-x">
          <hazard-flag-management-item
            v-for="worldHazard in worldHazards"
            :hazard-data="worldHazard"
            :can-add-flag="canAddFlags"
            :type="type"
            :has-been-inspected="investigatedWorldHazardIds.includes(worldHazard.id)"
            :is-self-flagged="selfFlaggedWorldHazardIds.includes(worldHazard.id)"
            :player-prioritizations="playerPrioritizationsAssoc[worldHazard.id]"
            @click:add-flag="onClickAddFlag(worldHazard)"
            @click:remove-flag="onClickRemoveFlag(worldHazard)"
            @click:swap-flag="onClickSwapFlag(worldHazard)"
            @click:inspect-flag="onClickInspectFlag(worldHazard)"
          />
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <close-button class="button-close" @click="onClickCancel"/>
    </template>
  </hazard-popup-container>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import LongButton from '@/components/common/LongButton.vue'
import HazardActionTab from '@/components/HazardMeeting/HazardPopup/HazardActionTab.vue'
import HazardAction from '@/components/HazardMeeting/HazardPopup/HazardAction.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import HazardFlagManagementItem from '@/components/HazardMeeting/HazardPopupFlagManagement/HazardFlagManagementItem.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import HazardPrioritizationItem
  from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPrioritizationItem.vue';

export default {
  name: 'HazardPopupFlagManagement',
  components: {
    HazardPrioritizationItem,
    CloseButton,
    HazardFlagManagementItem,
    MarkdownText,
    HazardPopupContainer,
    HazardAction,
    LongButton,
    NoDragImage,
    HazardIcon,
    HazardActionTab,
  },
  props: {
    worldHazards: {
      type: Array,
      required: true,
    },
    canAddFlags: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'swap', // swap or manage
    },
    investigatedWorldHazardIds: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    playerPrioritizations: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    selfFlaggedWorldHazardIds: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: [
    'click:cancel',
    'click:addFlag',
    'click:removeFlag',
    'click:swapFlag',
    'click:inspectFlag',
  ],
  methods: {
    onClickCancel() {
      this.$emit('click:cancel');
    },
    onClickAddFlag(worldHazard) {
      if (!this.canAddFlags) {
        return;
      }

      console.log('onClickAddFlag.worldHazard', worldHazard);

      this.$emit('click:addFlag', worldHazard);
    },
    onClickRemoveFlag(worldHazard) {
      console.log('onClickRemoveFlag.worldHazard', worldHazard);

      this.$emit('click:removeFlag', worldHazard);
    },
    onClickSwapFlag(worldHazard) {
      console.log('onClickSwapFlag.worldHazard', worldHazard);

      this.$emit('click:swapFlag', worldHazard);
    },
    onClickInspectFlag(worldHazard) {
      console.log('onClickInspectFlag.worldHazard', worldHazard);

      this.$emit('click:inspectFlag', worldHazard);
    },
  },
  computed: {
    playerPrioritizationsAssoc() {
      console.log('[playerPrioritizationsAssoc] this.playerPrioritizations', this.playerPrioritizations);

      if (!this.playerPrioritizations) {
        return;
      }

      const playerPrioritizationsAssoc = {};

      /**
       * {
       *   "32": [
       *     {
       *       player: {
       *         playerCode: "111111",
       *         characterIndex: 3
       *       },
       *       flagCount: 2
       *     }
       *   ]
       * }
       */

      console.log('this.playerPrioritizations', this.playerPrioritizations);

      for (const playerPrioritization of this.playerPrioritizations) {
        const hazardIds = Object.keys(playerPrioritization.hazardPrioritizationCount);

        for (const hazardId of hazardIds) {
          const flagCount = playerPrioritization.hazardPrioritizationCount[hazardId];

          const playerPrioritizationSingle = {
            player: playerPrioritization.player,
            flagCount: flagCount,
          };

          if (!playerPrioritizationsAssoc[hazardId]) {
            playerPrioritizationsAssoc[hazardId] = [];
          }

          playerPrioritizationsAssoc[hazardId].push(playerPrioritizationSingle);
        }
      }

      return playerPrioritizationsAssoc;
    },
  }
}
</script>

<style lang="scss" scoped>
.all-flags-used {
  font-size: 16pt;
  color: #ffffff;
  font-weight: bold;
  width: 90%;
  margin-bottom: 5px;

  background: rgba(#194040, .8);
  padding: 5px 50px;
}

.world-hazard-container {
  background: rgba(#194040, .8);
  border-radius: 20px;
  width: 100%;
  margin: 0 auto;
  //height: 450px;
  height: 520px;
  padding: 15px;

  overflow-x: scroll;
}

.button-container {
  margin-top: 30px;
}

.button-close {
  position: absolute;
  top: -0px;
  right: -0px;
}

.hazard-popup-flag-management {
  margin-top: -50px;
}

.risk-matrix-switch {
  position: absolute;
  right: -300px;
  top: -40px;
}
</style>
