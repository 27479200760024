import { tutorials } from '@/lib/dynamic-locale'

export default tutorials;

export function getSortedTutorialGroupIdentifiers() {
  const groupIdentifiers = {};

  for (const tutorial of tutorials) {
    if (groupIdentifiers[tutorial.groupIdentifier] !== undefined) {
      groupIdentifiers[tutorial.groupIdentifier] = Math.min(tutorial.totalIndex, groupIdentifiers[tutorial.groupIdentifier]);
    } else {
      groupIdentifiers[tutorial.groupIdentifier] = tutorial.totalIndex;
    }
  }

// Convert the object to an array of key-value pairs and sort by the smallest index
  const sortedGroupIdentifiers = Object.entries(groupIdentifiers)
    .sort((a, b) => a[1] - b[1])
    .map(tutorialGroup => {
      return tutorialGroup[0];
    });

  return sortedGroupIdentifiers;
}

export function getSortedTutorials() {
  return tutorials
    .sort((a, b) => a.groupIndex - b.groupIndex);
}


export function getSortedTutorialsByGroupIdentifier(groupIdentifier) {
  return tutorials
    .filter(tutorial => tutorial.groupIdentifier === groupIdentifier)
    .sort((a, b) => a.groupIndex - b.groupIndex);
}
