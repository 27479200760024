<template>
  <div class="tutorial-box-content d-flex gap-2">
    <div class="bg-darkish p-2 description flex-shrink">
      <p>{{ $t('starMinigameTutorialCopingCapacity.paragraph1') }}</p>

      <ul>
        <li>{{ $t('starMinigameTutorialCopingCapacity.list1point1') }}</li>
        <li>{{ $t('starMinigameTutorialCopingCapacity.list1point2')}}</li>
        <li>{{ $t('starMinigameTutorialCopingCapacity.list1point3')}}</li>
      </ul>

      <p>{{ $t('starMinigameTutorialCopingCapacity.paragraph2') }}</p>
    </div>
    <div>
      <table class="tutorial-table">
        <thead>
          <tr>
            <th class="text-center font-lg">{{ $t('starMinigameTutorialCopingCapacity.tableHeaderCopingCapacity') }}</th>
            <th class="text-center font-lg">{{ $t('starMinigameTutorialCopingCapacity.tableHeaderDescription') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="bg-level bg-level-5 text-center">{{ $t('starMinigameTutorialCopingCapacity.tableRowVeryHigh') }}</td>
            <td class="bg-darkish p-2">{{ $t('starMinigameTutorialCopingCapacity.tableRowVeryHighDescription') }}</td>
          </tr>
          <tr>
            <td class="bg-level bg-level-4 text-center">{{ $t('starMinigameTutorialCopingCapacity.tableRowHigh') }}</td>
            <td class="bg-darkish p-2">{{ $t('starMinigameTutorialCopingCapacity.tableRowHighDescription') }}</td>
          </tr>
          <tr>
            <td class="bg-level bg-level-3 text-center">{{ $t('starMinigameTutorialCopingCapacity.tableRowPartial') }}</td>
            <td class="bg-darkish p-2">{{ $t('starMinigameTutorialCopingCapacity.tableRowPartialDescription') }}</td>
          </tr>
          <tr>
            <td class="bg-level bg-level-2 text-center">{{ $t('starMinigameTutorialCopingCapacity.tableRowLow') }}</td>
            <td class="bg-darkish p-2">{{ $t('starMinigameTutorialCopingCapacity.tableRowLowDescription') }}</td>
          </tr>
          <tr>
            <td class="bg-level bg-level-1 text-center">{{ $t('starMinigameTutorialCopingCapacity.tableRowVeryLow') }}</td>
            <td class="bg-darkish p-2">{{ $t('starMinigameTutorialCopingCapacity.tableRowVeryLowDescription') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialCopingCapacity',
}
</script>

<style lang="scss" scoped>
.tutorial-box-content {
  width: 1200px !important;
  max-width: 1200px !important;
}

.font-lg {
  font-size: 25pt;
}

.description {
  flex-basis: 1200px;
}
</style>
