<template>
  <strategic-meeting-intro @continue="onContinue" :waiting-on-player-codes="waitingOnPlayersCodes" :players="connectedPlayers" />

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Connected players</label>
      <toggle-app-button
        v-for="(_, playerCode) of connectedPlayerCodesAssoc"
        idle-color="red"
        active-color="green"
        v-model="connectedPlayerCodesAssoc[playerCode]"
      >
        Player {{ playerCode }}
      </toggle-app-button>
    </div>
    <div class="row">
      <label class="form-label">Waiting on players</label>
      <toggle-app-button
        v-for="(_, playerCode) of waitingOnPlayerCodesAssoc"
        idle-color="red"
        active-color="green"
        :disabled="!connectedPlayerCodesAssoc[playerCode]"
        v-model="waitingOnPlayerCodesAssoc[playerCode]"
      >
        Player {{ playerCode }}
      </toggle-app-button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import { generatePlayersData } from '@/utils/playerDataGenerator';
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue';
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue';

export default {
  name: 'StrategicMeetingIntroDev',
  components: {
    ToggleAppButton,
    DevSettingsPanel,
    StrategicMeetingIntro,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const players = generatePlayersData(5, 0);
    const waitingOnPlayerCodesAssoc = {};
    const connectedPlayerCodesAssoc = {};

    const playerCodes = Object.keys(players);

    for (const playerCode of playerCodes) {
      const player = players[playerCode];

      waitingOnPlayerCodesAssoc[player.playerCode] = true;
      connectedPlayerCodesAssoc[player.playerCode] = true;

      player.joined = true;
    }

    return {
      events: ['continue'],
      players: players,
      waitingOnPlayerCodesAssoc: waitingOnPlayerCodesAssoc,
      connectedPlayerCodesAssoc: connectedPlayerCodesAssoc,
    };
  },
  watch: {
    connectedPlayerCodesAssoc: {
      handler() {
        const playerIds = Object.keys(this.connectedPlayerCodesAssoc);

        for (const playerId of playerIds) {
          const player = this.players[playerId];

          player.joined = this.connectedPlayerCodesAssoc[player.playerCode];
        }
      },
      deep: true,
    }
  },
  computed: {
    waitingOnPlayersCodes() {
      const playerCodes = Object.keys(this.waitingOnPlayerCodesAssoc);

      const waitingOnPlayerCodes = [];

      for (const playerCode of playerCodes) {
        if (!this.waitingOnPlayerCodesAssoc[playerCode]) {
          continue;
        }

        const player = this.players[playerCode];

        waitingOnPlayerCodes.push(player.playerCode);
      }

      console.log('waitingOnPlayerCodes', waitingOnPlayerCodes);


      return waitingOnPlayerCodes;
    },
    connectedPlayers() {
      const connectedPlayers = {};
      const playerIds = Object.keys(this.connectedPlayerCodesAssoc);

      for (const playerId of playerIds) {
        const player = this.players[playerId];

        if (!this.connectedPlayerCodesAssoc[player.playerCode]) {
          continue;
        }

        connectedPlayers[playerId] = player;
      }

      return connectedPlayers;
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    }
  },
}
</script>

<style lang="scss" scoped>
.strategic-meeting-popup {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
