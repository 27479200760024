<template>
  <div class="toolbox-popup-dev">
    <toolbox-popup
      @close="onClose"
    />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import ToolboxPopup from './ToolboxPopup'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'

export default {
  name: 'ToolboxPopupDev',
  components: {
    ToolboxPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['close'],
    }
  },
  methods: {
    onClose() {
      this.triggerEvent('close')
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/variables.scss';

  .meeting-invite-bar {
    margin: 0 auto;
  }

  .toolbox-popup-dev {
    border: 1px solid black;
    margin: 0 auto;
    width: 100%;
    height: 600px;
    max-height: $appHeight * 0.60; //60vh;
  }
</style>
