// Must Match MeetingState.ts in server
let i = 1;

export const MEETING_STATE_BEGIN = (i++);                             //  1
export const MEETING_STATE_DAY_BEGIN = (i++);                         //  2
export const MEETING_STATE_STRATEGIC_MEETING = (i++);                 //  3
export const MEETING_STATE_STRATEGIC_MEETING_RESULTS = (i++);         //  4
export const MEETING_STATE_EPI_REPORT = (i++);                        //  5
export const MEETING_STATE_HAZARD_MEETING_PRIORITY = (i++);           //  6
export const MEETING_STATE_HAZARD_MEETING = (i++);                    //  7
export const MEETING_STATE_DAY_END = (i++);                           //  8
export const MEETING_STATE_END_DECISION = (i++);                      //  9
export const MEETING_STATE_END_FEEDBACK = (i++);                      // 10
export const MEETING_STATE_STAR_MINIGAME = (i++);                     // 11
export const MEETING_STATE_HAZARD_MEETING_NO_PRIORITIZATIONS = (i++); // 12
export const MEETING_STATE_END_DECISION_NOT_UNANIMOUS = (i++);        // 13
