<template>
  <div class="tutorial-box-content">
    <p class="text-start">{{ $t('starMinigameTutorialFrequency.introText') }}</p>
    <table class="tutorial-table">
      <tbody>
        <tr>
          <td>{{ $t('starMinigameTutorialFrequency.perennial') }}</td>
          <td>{{ $t('starMinigameTutorialFrequency.perennialDescription') }}</td>
        </tr>
        <tr>
          <td>{{ $t('starMinigameTutorialFrequency.recurrent') }}</td>
          <td>{{ $t('starMinigameTutorialFrequency.recurrentDescription') }}</td>
        </tr>
        <tr>
          <td>{{ $t('starMinigameTutorialFrequency.frequent') }}</td>
          <td>{{ $t('starMinigameTutorialFrequency.frequentDescription') }}</td>
        </tr>
        <tr>
          <td>{{ $t('starMinigameTutorialFrequency.rare') }}</td>
          <td>{{ $t('starMinigameTutorialFrequency.rareDescription') }}</td>
        </tr>
        <tr>
          <td>{{ $t('starMinigameTutorialFrequency.random') }}</td>
          <td>{{ $t('starMinigameTutorialFrequency.randomDescription') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TutorialFrequency',
}
</script>

<style lang="scss" scoped>

</style>
