<template>
  <lobby-popup-room-code
    v-if="state === 'roomCode'"
    @continue="onRoomJoined"
  />

  <lobby-popup-choose-avatar
    v-else-if="state === 'chooseAvatar'"
    @continue="onChosenAvatarAndName"
    :force-choice="forceCharacterChoice"
    :other-selected-character-indexes="otherSelectedCharacterIndexes"
  />

  <lobby-popup-ready
    v-else-if="state === 'ready'"
  />

  <lobby-popup-role-intro
    v-else-if="state === 'roleIntro'"
    @continue="onRoleIntroContinue"
  />

  <lobby-popup-role-selection
    v-else-if="state === 'roleSelection'"
    @continue="onGameStart"
  />
</template>

<script>
import LobbyPopupRoomCode from '@/components/Lobby/LobbyPopupRoomCode';
import LobbyPopupChooseAvatar from '@/components/Lobby/LobbyPopupChooseAvatar';
import LobbyPopupReady from '@/components/Lobby/LobbyPopupReady.vue'
import { mapState } from 'vuex'
import {GAME_STATE_GAME, GAME_STATE_LOBBY, GAME_STATE_ROLE_INTRO, GAME_STATE_ROLE_SELECTION} from '@/enum/GameState'
import LobbyPopupRoleIntro from '@/components/Lobby/LobbyPopupRoleIntro.vue'
import LobbyPopupRoleSelection from '@/components/Lobby/LobbyPopupRoleSelection';
import CharacterData from '@/data/character-data'

export default {
  name: 'LobbyPopup',
  components: { LobbyPopupRoleIntro, LobbyPopupReady, LobbyPopupRoomCode, LobbyPopupChooseAvatar, LobbyPopupRoleSelection },
  props: {
    forceCharacterChoice: {
      type: Boolean,
      default: false,
      required: false,
    },
    otherSelectedCharacterIndexes: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
  },
  data() {
    return {
      state: null,
    }
  },
  methods: {
    onRoomJoined() {
      console.log('onRoomJoined');

      this.state = 'chooseAvatar';
    },
    onChosenAvatarAndName() {
      console.log('onChosenAvatarAndName');

      this.state = 'ready';
    },
    onRoleIntroContinue() {
      console.log('onRoleIntroContinue');

      this.state = 'roleSelection';
    },
    onGameStart() {
      console.log('gameStart');
    },
    updateFromRoomState() {
      console.log('LobbyPopup.updateFromRoomState - this.roomState', this.roomState);

      if (!this.roomState) {
        this.state = 'roomCode';
      } else if (this.roomState === GAME_STATE_LOBBY) {
        this.state = 'chooseAvatar';
      } else if (this.roomState === GAME_STATE_ROLE_SELECTION) {
        this.state = 'roleIntro';
      } else if (this.roomState === GAME_STATE_GAME) {
        if (!this.$gameClient.hasJoinedRoom()) {
          console.warn(`Apparently the game client is no longer connected to the room - something probably went wrong when starting`);

          this.$router.push('/');
        } else {
          console.log('1 MOVING TO /game');
          this.$router.push('/game');
          console.log('2 MOVING TO /game');
        }
      } else {
        console.log(`The room state was unknown (${this.roomState})`);
      }
    },
  },
  mounted() {
    this.updateFromRoomState();

    this.state = 'roomCode';
  },
  watch: {
    state() {
      this.$emit('change-state', this.state);
    },
    roomState() {
      this.updateFromRoomState();
    },
  },
  computed: {
    ...mapState(['room']),
    roomState() {
      return this.room.state || null;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
