<template>
  <div class="row">
    <div class="settings-row">
      <label class="form-label">JEE values</label>

      <bootstrap-select v-model="jeeValueType" :options="jeeValueTypeOptions" />

      <pre class="jee-values">{{ jeeValuesString }}</pre>
    </div>
    <div class="settings-row">
      <label class="form-label">Mission</label>

      <bootstrap-select v-model="missionIdentifier" :options="missionOptions" placeholder-value="0" placeholder-text="Select a mission" />
    </div>
  </div>
  <div v-if="selectedMission">
    <div class="row">
      <div class="settings-row">
        <label class="form-label">Hazard info</label><br />

        <strong>Hazard base likelihood: {{ selectedMission.baseLikelihood }}</strong><br />
        <strong>Hazard base severity: {{ selectedMission.startingSeverity }}</strong><br />
        <strong>Hazard start time: {{ selectedMission.startTime }}</strong><br />
      </div>
    </div>
    <div class="row">
      <div class="settings-row">
        <label class="form-label">Hazard Age ({{ hazardAge }})</label>

        <input class="form-control" type="range" min="0" max="5" v-model.number="hazardAge" />
      </div>
    </div>
    <div class="row">
      <div class="settings-row">
        <label class="form-label">Actions</label>

        <div class="hazards">
          <div class="hazard mb-2" v-for="action in missionActions">
            <label>
              <input type="checkbox" v-model="selectedActionIdentifiers[action.identifier]" />
              <cost-box size="sm">{{ computedHazardActionCosts[action.identifier] }}</cost-box> <strong>{{ action.text }}</strong>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row result-row" v-if="computedHazardScore">
      <div class="settings-row">
        <h2>Computation</h2>

        <strong>Hazard likelihood:</strong> {{ computedHazardScore.hazardLikelihood }} (base: {{ computedHazardScore.hazard.baseLikelihood }})<br />
        <strong>Hazard severity:</strong> {{ computedHazardScore.hazardSeverity }} (base: {{ computedHazardScore.hazard.startingSeverity }})<br />
        <strong>Hazard score:</strong> {{ computedHazardScore.hazardScore }}<br />
        <br />
        <strong>Total response effect:</strong> {{ computedHazardScore.hazardTotalResponseEffect }}<br />
        <strong>Total response cost:</strong> {{ computedHazardScore.hazardTotalResponseCost }}<br />
        <strong>Hazard return in:</strong> {{ computedHazardScore.hazardReturnIn }}<br />
        <br />
        <div class="computed-actions">
          <div class="computed-action mb-4" v-for="computedAction in computedHazardScore.actions">
            <label>{{ computedAction.action.text }}</label><br />
            <br />
            <strong>Base cost:</strong> {{ computedAction.action.baseCost }}<br />
            <strong>Base hazard impact:</strong> {{ computedAction.action.baseHazardImpact }}<br />
            <strong>JEE score:</strong> {{ computedAction.jeeScore }} (<span class="jees">{{ computedAction.jeeCurrentValues.join(', ') }})</span><br />
            <strong>JEE vs Hazard score:</strong> {{ computedAction.jeeVsHazardScore }}<br />
            <strong>Response effect:</strong> {{ computedAction.responseEffect }}<br />
            <strong>Response cost:</strong> {{ computedAction.responseCost }}<br />
            <strong>ScoreImpact.trust:</strong> {{ computedAction.calcScores.trust }}<br />
            <strong>ScoreImpact.healthSecurity:</strong> {{ computedAction.calcScores.healthSecurity }}<br />
            <strong>ScoreImpact.collaboration:</strong> {{ computedAction.calcScores.collaboration }}<br />
            <strong>ScoreImpact.mgEventSuccess:</strong> {{ computedAction.calcScores.mgEventSuccess }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue';
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue';
import {
  computeHazardScores,
  computeHazardActionCosts,
} from '@/utils/algorithmUtil'
import { mapState } from 'vuex'
import CostBox from '@/components/common/CostBox.vue'

export default {
  name: 'AlgorithmDev',
  components: {
    CostBox,
    BootstrapSelect, DevSettingsPanel},
  extends: DevComponentContainer,

  watch: {
    missionActions: {
      handler() {
        const selectedActionIdentifiers = {};

        for (const action of this.missionActions) {
          selectedActionIdentifiers[action.identifier.toString()] = false;
        }

        this.selectedActionIdentifiers = selectedActionIdentifiers;
      },
      deep: true,
    },
    selectedMission() {
      this.computeFromSelectedMission();
    },
    selectedChosenActionIdentifiers() {
      this.computeFromSelectedMission();
    },
    hazardAge() {
      this.computeFromSelectedMission();
    },
    jeeValueType() {
      this.computeJeeValuesFromJeeValueType();
      this.computeFromSelectedMission();
    },
  },
  data() {
    const jeeValueTypeOptions = [
    ];

    for (let i = 0; i < 10; i++) {
      const jeeValue = i * 10;

      jeeValueTypeOptions.push({
        label: `All ${jeeValue}`,
        value: jeeValue.toString(),
      })
    }

    jeeValueTypeOptions.push({
      label: `All random`,
      value: 'random',
    });

    return {
      missions: [],

      missionOptions: [],
      missionIdentifier: null,
      selectedActionIdentifiers: {},

      hazardAge: 0,

      jeeValues: {},
      jeeValuesString: '',
      jeeValueType: jeeValueTypeOptions[0].value,
      jeeValueTypeOptions: jeeValueTypeOptions,
      computedHazardScore: null,
      computedHazardActionCosts: {},
    }
  },
  mounted() {
    this.computeJeeValuesFromJeeValueType();

    this.$apiClient.get('/mission/hazard').then(({missions}) => {
      const missionOptions = [];

      for (const mission of missions) {
        missionOptions.push({
          label: `${mission.identifier} / ${mission.title} (start time: ${mission.startTime})`,
          value: mission.identifier,
        });
      }

      this.missions = missions;
      this.missionOptions = missionOptions;
    });
  },
  methods: {
    computeJeeValuesFromJeeValueType() {
      const jeeValueType = this.jeeValueType === 'random' ? 'random' : parseInt(this.jeeValueType, 10);

      const jeeValues = {};
      const jeeValuesStringParts = [];

      for (const jeeCategory of this.jeeCategories) {
        for (const jeeGroup of jeeCategory.groups) {
          for (const jee of jeeGroup.jees) {
            let jeeValue = 0;

            if (jeeValueType === 'random') {
              jeeValue = Math.max(0, Math.min(100, Math.round(Math.random() * 100)))
            } else {
              jeeValue = jeeValueType;
            }

            jeeValues[jee.identifier] = jeeValue;
            jeeValuesStringParts.push(`${jee.identifier}: ${jeeValue}`);
          }
        }
      }

      console.log('Fake JEE values', jeeValues);

      this.jeeValues = jeeValues;

      this.jeeValuesString = jeeValuesStringParts.join(', ');
    },
    computeFromSelectedMission() {
      this.computedHazardScore = computeHazardScores(
        this.selectedMission,
        this.hazardAge,
        this.selectedChosenActionIdentifiers,
        this.jeeValues,
        true,
      );

      console.log('this.computedHazardScore', this.computedHazardScore);

      this.computedHazardActionCosts = computeHazardActionCosts(
        this.selectedMission,
        this.hazardAge,
        this.jeeValues,
      )
    },
  },
  computed: {
    ...mapState(['jeeCategories']),
    missionActions() {
      const missionActions = [];

      if (this.selectedMission) {
        for (const category of this.selectedMission.categories) {
          for (const tab of category.tabs) {
            for (const action of tab.actions) {
              missionActions.push(action)
            }
          }
        }
      }

      return missionActions;
    },
    selectedChosenActionIdentifiers() {
      const selectedActionsIdentifiers = [];

      for (const actionIdentifier in this.selectedActionIdentifiers) {
        if (this.selectedActionIdentifiers[actionIdentifier]) {
          selectedActionsIdentifiers.push(actionIdentifier);
        }
      }

      return selectedActionsIdentifiers;
    },
    selectedMission() {
      if (this.missionIdentifier === '0') {
        return null;
      }

      for (const mission of this.missions) {
        if (mission.identifier === this.missionIdentifier) {
          return mission;
        }
      }

      return null;
    },
  }
};
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 15px;
}

.settings-row {
  width: 1200px;
  border: 1px solid rgba(0, 0, 0, .5);
  padding: 10px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: left;
}

.hazards {
  padding: 10px;
  border: 1px solid black;
  text-align: left;
}

.computed-actions, .result-row {
  text-align: left;

  .computed-action {
    border: 1px solid rgba(0, 0, 0, .5);
    padding: 10px;
  }
}

pre {
  font-size: 12px;
}

.hazard {
  input[type=checkbox] {
    margin-right: 10px;
  }
  .note {
    color: #888888;
    margin-left: 22px;
    font-size: 12px;
  }
}

.cost-box {
  display: inline-block;
}

.jee-values {
  white-space: pre-wrap;
  background: #333333;
  color: white;
  padding: 10px;
  font-size: 10px;
  text-align: left;
}
</style>
