<template>
  <div class="role-selection-button-container">
    <div class="role-selection-glow" :class="computedGlowClasses" />
    <div class="role-selection-button" :class="computedClasses">
      <div class="role-selection-icon">
        <div v-if="playerChosenData" class="role-selection-icon-player">
          <character-thumbnail
            :character-index="playerChosenData.characterIndex"
          />
        </div>
        <div v-else class="role-selection-icon-role">
          <role-progress-ball :role="roleData" size="xl" :with-background="true" :show-points="false" />
        </div>
      </div>
      <div class="role-selection-name">
        <label>{{ roleData.name }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import RoleIcon from '@/components/Icon/RoleIcon.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import RoleProgressBall from '@/components/character/RoleProgressBall.vue'

export default {
  name: 'RoleSelectionIconButton',
  components: {
    RoleProgressBall,
    CharacterThumbnail,
    CharacterPlate,
    RoleIcon,
  },
  extends: DevComponentContainer,
  props: {
    roleData: {
      type: Object,
      required: true,
    },
    playerChosenData: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
    localSelected: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  computed: {
    computedClasses() {
      const classes = [];

      if (this.selected) {
        classes.push('role-selection-button-selected');
      }

      if (this.localSelected) {
        classes.push('role-selection-button-selected');
        classes.push('role-selection-button-local-selected');
      }

      if (this.playerChosenData) {
        classes.push('role-selection-button-with-player');
      }

      return classes;
    },
    computedGlowClasses() {
      const classes = [];

      if (this.localSelected) {
        classes.push('role-selection-button-local-selected');
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
.role-selection-button-container {
  width: 184px;
  height: 193px;
  position: relative;
}

.role-selection-button,
.role-selection-glow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.role-selection-button {
  cursor: pointer;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #C97800;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  border-radius: 15px;
  z-index: 100;
  transition: 100ms ease-out;

  &:hover {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), darken(#C97800, 10%);
  }

  &:active, &.role-selection-button-selected, &.role-selection-button-with-player {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), darken(#C97800, 15%);
    border: 1px solid #051224;
    box-shadow: inset 0px 0px 2px 1px #071C27, inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  }

  .role-selection-name,
  .role-selection-icon {
    user-select: none;
    pointer-events: none;
  }

  .role-selection-icon {
    width: 100%;
    height: 60%;

    .role-selection-icon-role {
      padding: 15px 20px 0 20px;
      width: 100%;
      height: 100%;
      position: relative;

      .role-icon {
        width: 120px;
        height: 120px;
        position: relative;
      }
    }

    .role-selection-icon-player {
      width: 100%;
      height: 100%;
      padding: 0 5px;
      position: relative;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .role-selection-name {
    width: 100%;
    height: 40%;

    color: white;
    font-weight: 700;
    text-align: center;
  }

  .role-selection-icon-player {
    .character-thumbnail {
      width: 120px;
      position: relative;
      top: -30px;
    }
  }
}

.role-selection-glow {
  z-index: 0;
  opacity: 0;

  // If no background is provided the glow will simply not work.
  // "transparent" won't work either, neither will "rgba(#000000, 0)"
  background: #000000;
  //filter: drop-shadow(0px -5px 31px #A0D530);
  box-shadow: 0 -5px 31px 20px #A0D530;
  transition: 100ms ease-out;

  &.role-selection-button-local-selected {
    opacity: 1;
  }
}
</style>
