<template>
  <div class="hazard-flag-management-item d-flex flex-column align-items-stretch">
    <div class="hazard-flag-management-item-inner">
      <floating-hazard-icon :float="false" :flag-count="flagCount" :identifier="hazardData.icon" size="flag-management" :show-shadow="false" :clickable="false" :severity="-1" />

      <div class="text-container epic-scrollbar">{{ hazardData.introText }}</div>

      <div class="button-container" v-if="isSelfFlagged">
        <div>
          <app-button v-if="type === 'swap'" color="red" size="fit-content" @click="onClickSwapFlag">{{ $t('prioritize.buttonSwap') }}</app-button>
          <app-button v-else-if="type === 'manage'" color="red" width="fit-content" height="fit-content" text-size="md" @click="onClickRemoveFlag">{{ $t('prioritize.buttonRemove') }}</app-button>
        </div>
      </div>
      <div class="prioritization-players d-flex justify-content-end gap-2">
        <div class="prioritization-player-box" v-for="playerPrioritization in playerPrioritizations">
          <character-thumbnail
            :character-index="playerPrioritization.player.characterIndex"
            size="extra-tiny"
          />

          <flag-icon :value="playerPrioritization.flagCount" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'
import { generateFakeHazard1, generateFakeHazard2 } from '@/utils/generator'
import HazardIcon from '@/components/common/HazardIcon.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import LongButton from '@/components/common/LongButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import FlagIcon from '@/components/player-ui/FlagIcon.vue';
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue';

export default {
  name: 'HazardFlagManagementItem',
  components: {CharacterThumbnail, FlagIcon, AppButton, LongButton, FloatingHazardIcon, HazardIcon },
  props: {
    playerPrioritizations: {
      type: Array,
      required: true,
    },
    hazardData: {
      type: Object,
      required: true,
    },
    canAddFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'swap', // swap or manage
    },
    hasBeenInspected: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelfFlagged: {
      // Whether you (the player) has inspected this
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click:addFlag', 'click:removeFlag', 'click:swapFlag', 'click:inspectFlag'],
  computed: {
    flagCount() {
      return this.hazardData.flagCount;
    },
    hasFlag() {
      return this.flagCount > 0;
    },
    inspectCost() {
      /*if (this.hazardData.hasBeenInspected) {
        return 0; // Free since someone already inspected it
      }

      return this.hazardData.introInvestigationCost;*/
      if (!this.hasBeenInspected && this.hazardData.introInvestigationCost) {
        return this.hazardData.introInvestigationCost;
      }

      return 0;
    },
  },
  methods: {
    onClickAddFlag() {
      if (!this.canAddFlag) {
        return;
      }

      this.$emit('click:addFlag');
    },
    onClickRemoveFlag() {
      this.$emit('click:removeFlag');
    },
    onClickSwapFlag() {
      this.$emit('click:swapFlag');
    },
    onClickInspectFlag() {
      this.$gameClient.playerCallMethod('investigateHazard', {
        hazardId: this.hazardData.id,
      }).then(() => {
        this.$emit('click:inspectFlag');
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hazard-flag-management-item {
  overflow: hidden;
  min-width: 25%;
  max-width: 25%;
  flex-basis: 25%;

  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), rgba(#000E21, .6);
  padding: 5px;
  border-radius: 10px 0px 0px 10px;
}

.hazard-flag-management-item-inner {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000E21, .6);
  box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
  border-radius: 10px;
  width: 100%;
  height: 100%;

  //background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  //box-shadow: inset 4.86858px 4.86858px 3.65143px rgba(255, 255, 255, 0.4), inset -6.08572px -6.08572px 4.86858px rgba(0, 0, 0, 0.4);

  padding: 10px 20px;
  overflow: hidden;
  position: relative;

  .button-container {
    position: absolute;
    height: 50px;
    left: 0;
    width: 100%;
    bottom: 10px;
    white-space: nowrap;
  }

  .text-container {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 5px;
    text-align: left;
    font-size: 12pt;
    line-height: 125%;

    //height: calc(100% - 200px);
    //height: calc(100% - 215px);
    height: calc(100% - 215px - 85px);
    overflow-x: hidden;
    white-space: break-spaces;
  }
}

.hazard-popup-intro {
  margin: 0 auto;
  transform: scale(70%);
}

.prioritization-players {
  //padding: 0 10px;
  margin-left: -20px;
  width: calc(100% + 40px);

  .prioritization-player-box {
    width: 50px;
    padding: 0;
    position: relative;

    .flag-icon {
      position: absolute;
      top: -70px;
      left: -40px;
      transform: scale(35%);
      z-index: 10000;
    }
  }
}
</style>
