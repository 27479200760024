export default {
  'HazardMeeting': {
    'identifier': 'HazardMeeting',
    'label': 'timerLabel.HazardMeeting',
    'buttonText': 'timerButtonText.HazardMeeting',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingStarting': {
    'identifier': 'HazardMeetingStarting',
    'label': 'timerLabel.HazardMeetingStarting',
    'buttonText': 'timerButtonText.HazardMeetingStarting',
    'duration': 60000,
    'hidden': false,
  },
  'HazardMeetingPrioritization': {
    'identifier': 'HazardMeetingPrioritization',
    'label': 'timerLabel.HazardMeetingPrioritization',
    'buttonText': 'timerButtonText.HazardMeetingPrioritization',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingEnding': {
    'identifier': 'HazardMeetingEnding',
    'label': 'timerLabel.HazardMeetingEnding',
    'buttonText': 'timerButtonText.HazardMeetingEnding',
    'duration': 60000,
    'hidden': false,
  },
  'StrategicMeeting': {
    'identifier': 'StrategicMeeting',
    'label': 'timerLabel.StrategicMeeting',
    'buttonText': 'timerButtonText.StrategicMeeting',
    'duration': 300000,
    'hidden': false,
  },
  'StrategicMeetingResults': {
    'identifier': 'StrategicMeetingResults',
    'label': 'timerLabel.StrategicMeetingResults',
    'buttonText': 'timerButtonText.StrategicMeetingResults',
    'duration': 60000,
    'hidden': false,
  },
  'EpiReport': {
    'identifier': 'EpiReport',
    'label': 'timerLabel.EpiReport',
    'buttonText': 'timerButtonText.EpiReport',
    'duration': 60000,
    'hidden': false,
  },
  'EndGameDecision': {
    'identifier': 'EndGameDecision',
    'label': 'timerLabel.EndGameDecision',
    'buttonText': 'timerButtonText.EndGameDecision',
    'duration': 300000,
    'hidden': false,
  },
  'EndGameDecisionAgain': {
    'identifier': 'EndGameDecisionAgain',
    'label': 'timerLabel.EndGameDecisionAgain',
    'buttonText': 'timerButtonText.EndGameDecisionAgain',
    'duration': 120000,
    'hidden': false,
  },
  'RoleSelectionStartGame': {
    'identifier': 'RoleSelectionStartGame',
    'label': 'timerLabel.RoleSelectionStartGame',
    'buttonText': 'timerButtonText.RoleSelectionStartGame',
    'duration': 300000,
    'hidden': false,
  },
  'Annex2': {
    'identifier': 'Annex2',
    'label': 'timerLabel.Annex2',
    'buttonText': 'timerButtonText.Annex2',
    'duration': 120000,
    'hidden': false,
  },
  'StarMinigameIntro': {
    'identifier': 'StarMinigameIntro',
    'label': 'timerLabel.StarMinigameIntro',
    'buttonText': 'timerButtonText.StarMinigameIntro',
    'duration': 60000,
    'hidden': true,
  },
  'StarMinigameForcedDocument': {
    'identifier': 'StarMinigameForcedDocument',
    'label': 'timerLabel.StarMinigameForcedDocument',
    'buttonText': 'timerButtonText.StarMinigameForcedDocument',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameOverview': {
    'identifier': 'StarMinigameOverview',
    'label': 'timerLabel.StarMinigameOverview',
    'buttonText': 'timerButtonText.StarMinigameOverview',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameTutorialFrequency': {
    'identifier': 'StarMinigameTutorialFrequency',
    'label': 'timerLabel.StarMinigameTutorialFrequency',
    'buttonText': 'timerButtonText.StarMinigameTutorialFrequency',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialSeasonality': {
    'identifier': 'StarMinigameTutorialSeasonality',
    'label': 'timerLabel.StarMinigameTutorialSeasonality',
    'buttonText': 'timerButtonText.StarMinigameTutorialSeasonality',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialLikelihood': {
    'identifier': 'StarMinigameTutorialLikelihood',
    'label': 'timerLabel.StarMinigameTutorialLikelihood',
    'buttonText': 'timerButtonText.StarMinigameTutorialLikelihood',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialSeverity': {
    'identifier': 'StarMinigameTutorialSeverity',
    'label': 'timerLabel.StarMinigameTutorialSeverity',
    'buttonText': 'timerButtonText.StarMinigameTutorialSeverity',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialVulnerability': {
    'identifier': 'StarMinigameTutorialVulnerability',
    'label': 'timerLabel.StarMinigameTutorialVulnerability',
    'buttonText': 'timerButtonText.StarMinigameTutorialVulnerability',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialCopingCapacity': {
    'identifier': 'StarMinigameTutorialCopingCapacity',
    'label': 'timerLabel.StarMinigameTutorialCopingCapacity',
    'buttonText': 'timerButtonText.StarMinigameTutorialCopingCapacity',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameVoteLikelihood': {
    'identifier': 'StarMinigameVoteLikelihood',
    'label': 'timerLabel.StarMinigameVoteLikelihood',
    'buttonText': 'timerButtonText.StarMinigameVoteLikelihood',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameVoteImpact': {
    'identifier': 'StarMinigameVoteImpact',
    'label': 'timerLabel.StarMinigameVoteImpact',
    'buttonText': 'timerButtonText.StarMinigameVoteImpact',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameRiskMatrix': {
    'identifier': 'StarMinigameRiskMatrix',
    'label': 'timerLabel.StarMinigameRiskMatrix',
    'buttonText': 'timerButtonText.StarMinigameRiskMatrix',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingNoPrioritizations': {
    'identifier': 'HazardMeetingNoPrioritizations',
    'label': 'timerLabel.HazardMeetingNoPrioritizations',
    'buttonText': 'timerButtonText.HazardMeetingNoPrioritizations',
    'duration': 60000,
    'hidden': false,
  },
};