export default {
  state: {
    signedInUser: null,
  },
  mutations: {
    signedInUser: (state, value) => {
      state.signedInUser = value;
    },
  },
  getters: {
    signedInUser (state) {
      return state.signedInUser;
    },
  },
}
