<template>
  <div class="lobby-popup" :class="computedClasses">
    <div v-if="showHeader" class="lobby-popup-header" :class="headerClass">
      <slot name="header" />
    </div>
    <div class="lobby-popup-content" :class="contentClass">
      <slot name="content" />
    </div>
    <div v-if="showFooter" class="lobby-popup-footer" :class="footerClass">
      <div class="lobby-popup-footer-line" v-if="showFooterLine" />
      <div class="footer-content d-flex justify-content-center align-items-center">
        <slot name="footer" />
      </div>
    </div>
    <slot name="root" />
  </div>
</template>

<script>
export default {
  name: 'LobbyPopupTemplate',
  props: {
    headerClass: {
      required: false,
      default: null,
    },
    contentClass: {
      required: false,
      default: null,
    },
    footerClass: {
      required: false,
      default: null,
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    overflowAuto: {
      type: Boolean,
      required: false,
      default: true,
    },
    withBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    centerPosition: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFooterBackground: {
      type: Boolean,
      required: false,
      default: true,
    },
    popupClass: {
      type: [Array, String],
      required: false,
      default: '',
    },
    showFooterLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      const classes = [];

      classes.push('lobby-popup-footer-' + (this.showFooter ? 'on' : 'off'));
      classes.push('lobby-popup-header-' + (this.showHeader ? 'on' : 'off'));
      classes.push('lobby-popup-overflow-' + (this.overflowAuto ? 'on' : 'off'));
      classes.push('lobby-popup-background-' + (this.withBackground ? 'on' : 'off'));
      classes.push('lobby-popup-center-position-' + (this.centerPosition ? 'on' : 'off'));
      classes.push('lobby-popup-show-footer-background-' + (this.showFooterBackground ? 'on' : 'off'));

      if (this.popupClass) {
        classes.push(this.popupClass);
      }

      return classes;
    },
    computedContainerClasses() {
      const classes = [];

      classes.push('lobby-popup-container-background-' + (this.withBackground ? 'on' : 'off'));
      classes.push('lobby-popup-container-center-position-' + (this.centerPosition ? 'on' : 'off'));

      return classes;
    },
  },
}
</script>

<style lang="scss" scoped>
.lobby-popup-footer-on.lobby-popup-header-on {
  .lobby-popup-content {
    height: calc(100% - 230px);
  }

  &.lobby-popup-show-footer-background-off {
    .lobby-popup-content {
      height: calc(100% - 210px) !important;
    }
  }
}

.lobby-popup-footer-on.lobby-popup-header-off {
  .lobby-popup-content {
    height: calc(100% - 130px);
  }
}

.lobby-popup-footer-off.lobby-popup-header-on {
  .lobby-popup-content {
    height: calc(100% - 130px);
  }
}

.lobby-popup-footer-off.lobby-popup-header-off {
  .lobby-popup-content {
    height: 100%;
  }
}

.lobby-popup {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  height: 100%;
  padding: 0;
  width: 100%;
  position: relative;
}

.lobby-popup-header {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  width: 100%;

  text-transform: uppercase;
  font-size: 25pt;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  padding: 15px 30px;
  z-index: 100;
}

.lobby-popup-content {
  position: relative;
  padding: 60px 100px 50px 100px;
  overflow-y: auto;

  &.equal-padding {
    padding: 50px !important;
  }

  &.medium-padding {
    padding: 25px !important;
  }

  &.small-padding {
    padding: 10px !important;
  }
}

.lobby-popup-show-footer-background-off {
  .lobby-popup-footer {
    background: none !important;
    height: 80px;
  }

  .lobby-popup-footer-line {
    background: none !important;
    box-shadow: none !important;
  }
}

.lobby-popup-footer {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #060f0f;
  width: 100%;
  height: 150px;
  position: relative;

  .footer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.lobby-popup-footer-line {
  background: rgba(33, 87, 137, 0.49);
  box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.4), inset -4px -4px 3px rgba(255, 255, 255, 0.4);
  border-radius: 22px;
  height: 10px;
  width: calc(100% - 50px);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.lobby-popup-overflow-off {
  .lobby-popup-content {
    overflow-y: clip;
  }
}

.lobby-popup-container {
  &.lobby-popup-container-position-center-on {
    transform: translate(-50%, -50%);
  }
}

.lobby-popup-tutorial {
  width: 400px;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);

  .lobby-popup-header {
    font-size: 18pt;
  }

  .lobby-popup-content {
    color: white;
    padding: 10px;
  }

  .lobby-popup-footer {
    height: 60px;
  }
}
</style>
