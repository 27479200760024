<template>
  <strategic-meeting-total-cost :cost="10" />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'
import StrategicMeetingTotalCost from '@/components/StrategicMeeting/StrategicMeetingTotalCost/index.vue'

export default {
  name: 'StrategicMeetingTotalCostDev',
  components: {
    StrategicMeetingTotalCost,
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['continue'],
    }
  },
}
</script>
