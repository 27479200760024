<template>
  <lightbox :push-top-ui="true" background="#000000" :background-opacity=".8">
    <hazard-popup-container size="sm" padding="sm" :content-background="true">
      <template v-slot:header>
        <no-drag-image src="@/assets/icons/icon-calendar-large.png" />
        <h1>{{ $t('starMinigame.invitationHeader') }}</h1>
      </template>

      <template v-slot:content>
        <div class="popup-content">
          <h2>{{ $t('starMinigame.invitationBodyTitle') }}</h2>
          <p>{{ $t('starMinigame.invitationBodyContents') }}</p>
        </div>
      </template>

      <template v-slot:buttons>
        <long-button color="light-green" @click="clickContinue" :disabled="!canInteract">{{ $t('starMinigame.buttonInvitationContinue') }}</long-button>
      </template>
    </hazard-popup-container>
  </lightbox>
</template>
<script>
import LongButton from '@/components/common/LongButton.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import Lightbox from '@/components/common/Lightbox.vue'

export default {
  name: 'StarMinigameInvitationPopup',
  components: {
    Lightbox,
    NoDragImage,
    HazardPopupContainer,
    LongButton,
  },
  props: {
    canInteract: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ['continue'],
  methods: {
    clickContinue() {
      if (!this.canInteract) {
        return;
      }

      this.$emit('continue');
    }
  }
}
</script>

<style lang="scss" scoped>
  .popup-content {
    padding: 60px 10px;

    h2 {
      font-size: 30pt;
      font-weight: bold;
    }

    p {
      margin-top: 60px;
      font-size: 24pt;
      font-weight: normal;
    }
  }
</style>
