export function getWorldHazardById(hazardList, hazardId) {
  console.log({
    method: 'getWorldHazardById',
    hazardList,
    hazardId,
  });

  if (typeof(hazardId) !== 'number') {
    hazardId = parseInt(hazardId, 10);
  }

  if (hazardList instanceof Array) {
    for (const hazard of hazardList) {
      if (hazard.id === hazardId) {
        return hazard;
      }
    }
  } else {
    for (const key in hazardList) {
      const hazard = hazardList[key];

      if (hazard.id === hazardId) {
        return hazard;
      }
    }
  }

  console.warn(`Could not find hazard by id ${hazardId} in list`, hazardList);

  return null;
}
