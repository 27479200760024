<template>
  <div class="background">
    <strategic-meeting-invested-action :description="description" :cost="cost" :disabled="disabled" />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import StrategicMeetingInvestedAction from '@/components/StrategicMeeting/StrategicMeetingInvestedAction/index.vue'

export default {
  name: 'StrategicMeetingInvestedActionDev',
  components: {
    StrategicMeetingInvestedAction,
    ButtonContainer
  },
  extends: DevComponentContainer,

  data () {
    return {
      description: 'This is a description of some action that I am not quiet sure what does',
      cost: 10,
      disabled: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.background {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  padding: 50px;
}
</style>
