export const EVENT_ROOM_LEFT = 'roomLeft';

export class RoomLeftEvent extends Event {
  roomId;
  participant;

  constructor(roomId, participant) {
    super(EVENT_ROOM_LEFT);

    this.roomId = roomId;
    this.participant = participant;
  }
}
