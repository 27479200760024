<template>
  <div class="floating-event-icon" :class="computedClasses">
    <div class="floating-event-icon-zoom">
      <div class="floating-event-icon-severity-bar row" v-if="showSeverity">
        <div class="col-3 floating-event-icon-severity-bar-segment floating-event-icon-severity-bar-segment-1" />
        <div class="col-3 floating-event-icon-severity-bar-segment floating-event-icon-severity-bar-segment-2" />
        <div class="col-3 floating-event-icon-severity-bar-segment floating-event-icon-severity-bar-segment-3" />
        <div class="col-3 floating-event-icon-severity-bar-segment floating-event-icon-severity-bar-segment-4" />
      </div>
      <div class="floating-event-icon-floater">
        <div class="floating-event-icon-image">
          <no-drag-image :src="iconImage"/>
        </div>
        <flag-icon :value="flagCount" v-if="flagCount > 0" class="flag-icon"/>
      </div>
      <div class="floating-event-icon-label">{{ label }}</div>
      <div class="floating-event-icon-info" v-if="showHazardIconInfo">
        <strong>Severity</strong>: {{ severity || 0 }}<br />
        <strong>Age</strong>: {{ age || 0 }}<br />
        <strong>Decays in</strong>: {{ decaysIn || 'N/A' }}
      </div>
      <div class="floating-event-icon-shadow" v-if="showShadow && shadowImage">
        <no-drag-image :src="shadowImage"/>
      </div>
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'

import ShadowHexagon from '@/assets/icons/hazards/shadow/shadow-hex.png'
import ShadowTriangle from '@/assets/icons/hazards/shadow/shadow-triangle.png'
import ShadowPin from '@/assets/icons/hazards/shadow/shadow-pin.png'

import {
  FLOATING_HAZARD_ICON_SHAPE_HEXAGON, FLOATING_HAZARD_ICON_SHAPE_PIN,
  FLOATING_HAZARD_ICON_SHAPE_TRIANGLE
} from '@/enum/FloatingHazardIconShadow'

import HazardIconData from '@/data/hazard-icon-data'
import FlagIcon from '@/components/player-ui/FlagIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FloatingHazardIcon',
  components: {
    FlagIcon,
    NoDragImage
  },
  props: {
    identifier: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      required: false,
    },
    showShadow: {
      type: Boolean,
      default: true,
      required: false,
    },
    flagCount: {
      type: Number,
      default: 0,
      required: false,
    },
    float: {
      type: Boolean,
      default: true,
      required: false,
    },
    clickable: {
      type: Boolean,
      default: true,
      required: false,
    },
    severity: {
      type: [Number, String],
      default: null,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    age: {
      type: Number,
      default: null,
      required: false,
    },
    decayAge: {
      type: Number,
      default: null,
      required: false,
    },
    zoom: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data () {
    return {
      popShow: false,
    }
  },
  mounted () {
    setTimeout(() => {
      this.$nextTick(() => {
        this.popShow = true
      })
    }, 100)
  },
  computed: {
    ...mapGetters(['showHazardIconInfo']),
    decaysIn() {
      if (!this.decayAge) {
        return null;
      }

      return this.decayAge - this.age;
    },
    showSeverity() {
      return this.type !== 'news' && this.severityNumber >= 0;
    },
    severityNumber() {
      if (this.severity === null) {
        return null;
      }

      if (typeof(this.severity) === 'string') {
        return parseInt(this.severity, 10);
      }

      return this.severity;
    },
    maxSeverity() {
      return this.severityNumber >= 5;
    },
    computedSeverity() {
      if (this.severityNumber < 1 || this.severityNumber === null) {
        return null;
      }

      return Math.max(1, Math.min(4, Math.round(this.severityNumber)));
    },
    shadowImage () {
      if (this.shape === FLOATING_HAZARD_ICON_SHAPE_HEXAGON) {
        return ShadowHexagon
      } else if (this.shape === FLOATING_HAZARD_ICON_SHAPE_TRIANGLE) {
        return ShadowTriangle
      } else if (this.shape === FLOATING_HAZARD_ICON_SHAPE_PIN) {
        return ShadowPin
      }

      return null
    },
    iconData () {
      if (!HazardIconData[this.identifier]) {
        return HazardIconData.unknown;

        throw new Error(`Could not find hazard icon by identifier ${this.identifier}`)
      }

      return HazardIconData[this.identifier]
    },
    iconImage () {
      if (!this.iconData) return null;

      return this.iconData.iconImage
    },
    label () {
      if (!this.iconData) return null;

      return this.iconData.label
    },
    shape () {
      if (!this.iconData) return null;

      return this.iconData.shape
    },
    computedIdentifier () {
      if (!this.iconData) return null;

      return this.iconData.identifier
    },
    computedClasses () {
      return [
        this.popShow ? `floating-event-icon-pop-show` : '',
        `floating-event-icon-size-${this.size}`,
        `floating-event-icon-identifier-${this.computedIdentifier}`,
        `floating-event-icon-shape-${this.shape}`,
        `floating-event-icon-zoom-${this.zoom ? 'on' : 'off'}`,
        `floating-event-icon-floating-${this.float ? 'on' : 'off'}`,
        `floating-event-icon-clickable-${this.clickable ? 'on' : 'off'}`,
        `floating-event-icon-severity-${this.computedSeverity !== null ? this.computedSeverity : 'off'}`,
        `floating-event-icon-max-severity-${this.maxSeverity ? 'on' : 'off'}`
      ]
    },
  }
}
</script>

<style lang="scss" scoped>
$animationDuration: 3s;
$shadowShrinkage: 0.9;

.floating-event-icon-label {
  text-transform: uppercase;
  background: linear-gradient(180deg, rgba(243, 43, 43, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), #C15554;

  color: #FFFFFF;
  text-shadow: 0px 2.37267px 4.74534px rgba(0, 0, 0, 0.6);
  text-align: center;
}

.floating-event-icon-clickable-on {
  cursor: pointer;

  &.floating-event-icon-clickable-on.floating-event-icon-floating-on {
    &:hover {
      .floating-event-icon-zoom {
        transform: scale(120%) !important;
      }
    }
  }
}

.floating-event-icon-zoom-on {
  .floating-event-icon-zoom {
    transform: scale(110%) !important;
  }
}

.floating-event-icon-zoom {
  transform: scale(85%);
  transition: 200ms cubic-bezier(0.175, 0.985, 0.32, 2.175);
  z-index: 100;
}

.floating-event-icon-identifier-news {
  .floating-event-icon-floater {
    margin-bottom: -80px !important;
  }

  .floating-event-icon-image {
    transform: scale(0.85);
    transform-origin: top;
  }

  .floating-event-icon-label {
    position: relative;
    top: -25px !important;
    text-align: center;
  }

  .floating-event-icon-severity-bar {
    bottom: 35px !important;
  }

  &.floating-event-icon-size-md {
    font-size: 12pt !important;
  }

  &.floating-event-icon-size-flag-management {
    .floating-event-icon-floater {
      margin-bottom: -105px !important;
    }
  }
}

.floating-event-icon {
  opacity: 0;

  &.floating-event-icon-size-md {
    width: 150px;
  }

  &.floating-event-icon-size-flag-management {
    .floating-event-icon-floater {
      transform: scale(70%);
      margin-bottom: -25px;
      margin-top: -20px;
    }

    .floating-event-icon-label {
      font-size: 11pt;
      text-align: center;
    }
  }

  &.floating-event-icon-pop-show {
    transform: scale(100%);
    opacity: 1;
  }
}

.floating-event-icon-shape-triangle {
  .floating-event-icon-shadow {
    width: 90%;
    margin: 0 auto;
  }
}

.floating-event-icon-shape-hexagon {
  .floating-event-icon-shadow {
    width: 70%;
  }
}

.floating-event-icon-shape-pin {
  .floating-event-icon-shadow {
    width: 70%;
  }
}

.floating-event-icon-shadow {
  top: 30px;
}

img {
  width: 100%;
}

.floating-event-icon-floating-off {
  transition-duration: 0ms !important;
}

.floating-event-icon-floating-on {
  .floating-event-icon-shadow {
    position: relative;
    animation: upDown $animationDuration alternate infinite ease-in-out;
    margin: 0 auto;
  }

  .floating-event-icon-floater {
    position: relative;
    animation: downUp $animationDuration alternate infinite ease-in-out;
  }
}

@keyframes downUp {
  to {
    transform: translateY(-10px);
  }
}

@keyframes upDown {
  to {
    transform: scale($shadowShrinkage);
    opacity: $shadowShrinkage;
    filter: blur(5px);
  }
}

.flag-icon {
  position: absolute;
  top: -30px;
  left: -40px;
  transform: scale(80%);
}

.floating-event-icon-severity-circle {
  position: absolute;
  left: -225px;
  top: -100px;
  pointer-events: none !important;
  user-select: none !important;
  border-radius: 50%;
  transform: scaleY(40%);
  width: 600px;
  height: 600px;
}

@mixin severityCircleColor($severity, $color) {
  .floating-event-icon-severity-#{$severity} .floating-event-icon-severity-circle {
    background: radial-gradient(circle at 50% 50%, rgba($color, 80%) 0%, rgba($color, 0%) 70%);
  }
}

@mixin severityBarColor($severity, $activeColor, $inactiveColor) {
  .floating-event-icon-severity-bar-segment-#{$severity} {
    background: $inactiveColor;
    border: 2px solid #666666;
    z-index: 1;
  }

  .floating-event-icon-severity-#{$severity} .floating-event-icon-severity-bar-segment-#{$severity} {
    background: $activeColor;
    border: 2px solid white;
    transform: scale(120%);
    z-index: 100;
  }
}

.floating-event-icon-severity-bar-segment {
  height: 20px;
  filter: drop-shadow(0px 2.2200000286102295px 7.731620788574219px rgba(0, 0, 0, 0.76));
  transform: scale(100%);
  transition: 200ms ease-in-out;
  transform-origin: center center;
}

.floating-event-icon-severity-bar {
  position: absolute;
  bottom: 10px;
  width: 100%;
  margin-left: 0;
}

@include severityCircleColor('1', #5AC061);
@include severityCircleColor('2', #81CEED);
@include severityCircleColor('3', #F9D448);
@include severityCircleColor('4', #F5902A);
@include severityCircleColor('5', #F83E3F);

@include severityBarColor('1', #01a44f, #67c895);
@include severityBarColor('2', #a6ce39, #cae288);
@include severityBarColor('3', #fec212, #feda71);
@include severityBarColor('4', #ee1c23, #f5777b);

@keyframes backgroundFade {
  0% {
    background-color: #ee1c23;
    box-shadow: 0 0 10px #ee1c23;
  }

  50% {
    background-color: #000000;
    box-shadow: 0 0 30px #ee1c23;
  }

  100% {
    background-color: #ee1c23;
    box-shadow: 0 0 10px #ee1c23;
  }
}

.floating-event-icon-max-severity-on .floating-event-icon-severity-bar-segment-4 {
  animation: backgroundFade 500ms infinite linear;
}

.floating-event-icon-info {
  background: rgba(255, 255, 255, .8);
  padding: 5px;
  font-size: 12px;
  border-radius: 10px;
  text-align: left;
  z-index: 999999999;
}
</style>
