<template>
  <lobby-popup-template content-class="small-padding">
    <template v-slot:header>
      DEV Tools
    </template>
    <template v-slot:content>
      <div class="dev-content">
        <div>
          <h2>Meeting</h2>
          <div class="d-flex gap-4">
            <long-button @click="skipToNextMeeting" color="orange" size="fit-content">Skip to next meeting</long-button>
            <long-button @click="skipToLastMeeting" color="orange" size="fit-content">Skip to last meeting</long-button>
            <long-button @click="skipToEnd" color="orange" size="fit-content">Skip to end</long-button>
          </div>
        </div>
        <div>
          <h2>Hazards</h2>
          <div class="d-flex gap-4">
            <long-button @click="addRandomHazard" color="orange" size="fit-content">Add 1 random hazard</long-button>
            <long-button @click="addRandomHazards" color="orange" size="fit-content">Add 5 random hazards</long-button>
            <long-button @click="toggleHazardIconInfo" :state="showHazardIconInfo ? 'active' : ''" color="orange" size="fit-content">Hazard icon info: {{ showHazardIconInfo ? 'on' : 'off' }}</long-button>
          </div>
        </div>
        <div>
          <h2>Flags</h2>
          <div class="d-flex gap-4">
            <long-button @click="addRandomFlagsToHazards" color="orange" size="fit-content">Add random flags to hazards</long-button>
            <long-button @click="decreasePlayerFlags" color="orange" size="fit-content">Flag count -1</long-button>
            <long-button @click="increasePlayerFlags" color="orange" size="fit-content">Flag count +1</long-button>
            <long-button @click="toggleFlagVotingPower" color="orange" size="fit-content">Flag voting power: {{ flagVotingPower }}</long-button>
          </div>
        </div>
        <div>
          <h2>Points</h2>
          <div class="d-flex gap-4">
            <long-button @click="randomizeMainPoints" color="orange" size="fit-content">Randomize main points</long-button>
            <long-button @click="maxOutRolePoints" color="orange" size="fit-content">Max out RP</long-button>
            <long-button @click="maxOutActionPoints" color="orange" size="fit-content">Max out AP</long-button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <long-button @click="close" color="blue">Close</long-button>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import LongButton from '@/components/common/LongButton.vue';
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue';
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DevPopupLive',
  components: {CharacterThumbnail, LongButton, LobbyPopupTemplate},
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf', 'showHazardIconInfo']),
    flagVotingPower() {
      if (this.playerSelf) {
        return this.playerSelf.flagVotingPower;
      }

      return 0;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    skipToNextMeeting() {
      this.$gameClient.roomCallMethod('dev:skipToNextMeeting');
    },
    skipToLastMeeting() {
      this.$gameClient.roomCallMethod('dev:skipToLastMeeting');
    },
    skipToEnd() {
      this.$gameClient.roomCallMethod('dev:skipToEnd');
    },
    addRandomFlagsToHazards() {
      this.$gameClient.roomCallMethod('dev:addRandomFlagsToHazards');
    },
    decreasePlayerFlags() {
      this.$gameClient.roomCallMethod('dev:decreasePlayerFlags');
    },
    increasePlayerFlags() {
      this.$gameClient.roomCallMethod('dev:increasePlayerFlags');
    },
    randomizeMainPoints() {
      this.$gameClient.roomCallMethod('dev:randomizeMainPoints');
    },
    maxOutRolePoints() {
      this.$gameClient.roomCallMethod('dev:maxOutRolePoints');
    },
    maxOutActionPoints() {
      this.$gameClient.roomCallMethod('dev:maxOutActionPoints');
    },
    addRandomHazard() {
      this.$gameClient.roomCallMethod('dev:addHazard');
    },
    addRandomHazards() {
      this.$gameClient.roomCallMethod('dev:addHazards');
    },
    toggleFlagVotingPower() {
      const newFlagVotingPower = this.flagVotingPower === 3 ? 1 : 3;

      this.$gameClient.roomCallMethod('dev:setFlagVotingPower', newFlagVotingPower);
    },
    toggleHazardIconInfo() {
      this.$store.commit('showHazardIconInfo', !this.showHazardIconInfo);
    }
  }
}
</script>

<style lang="scss" scoped>
.dev-content {
  color: white;
  text-align: left;

  h1, h2, h3, h4, h5 {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

</style>
