/**
 * @source https://stackoverflow.com/a/70844631/247893
 */

const descriptors = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1,
};

export function numberToRomanNumerals(number = 0) {
  return Object.keys(descriptors).reduce((acc, key) => {
    while (number >= descriptors[key]) {
      acc += key;
      number -= descriptors[key];
    }

    return acc;
  }, '');
}

export function romanNumeralsToNumber(roman = '') {
  return Object.keys(descriptors).reduce((acc, key) => {
    while (roman.indexOf(key) === 0) {
      acc += descriptors[key];

      roman = roman.substr(key.length);
    }

    return acc;
  }, 0);
}

// console.log(toRoman(1903));  // should return 'MCMIII
// console.log(fromRoman('MCMIII')); // should return 1903
