<template>
  <div class="button-container">
    <button class="btn btn-success" @click="modify(-10)">
      -10
    </button>
    <button class="ms-2 btn btn-success" @click="modify(-1)">
      -1
    </button>
    <span class="ms-2">{{ value }}</span>
    <button class="ms-2 btn btn-success" @click="modify(+1)">
      +1
    </button>
    <button class="ms-2 btn btn-success" @click="modify(+10)">
      +10
    </button>
  </div>
</template>
<script>
export default {
  name: 'ButtonContainer',
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  methods: {
    modify(n) {
      let { value } = this;

      value += n;

      if (this.min !== null) {
        value = Math.max(this.min, value);
      }

      if (this.max !== null) {
        value = Math.min(this.max, value);
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  z-index: 100000000;
  margin-top: 50px;
  text-align: center;
  background: rgba(255, 255, 255, .7);
  padding: 10px;
}
</style>
