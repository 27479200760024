<template>
  <table>
    <thead>
      <tr>
        <th>UID</th>
        <th>Volume</th>
        <th>Frequency ball</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="participantUid in participantUids">
        <td>{{ participants[participantUid].uid }}</td>
        <td>{{ participants[participantUid].volume }}</td>
        <td><frequency-ball :frequency="participants[participantUid].frequency" :show-label="true" /></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import FrequencyBall from './FrequencyBall'
import { EVENT_FREQUENCY_CHANGED } from '@/plugins/VoiceController/events/FrequencyChangedEvent'
import { EVENT_PARTICIPANT_REMOVED } from '@/plugins/VoiceController/events/ParticipantRemovedEvent'
import { EVENT_PARTICIPANT_ADDED } from '@/plugins/VoiceController/events/ParticipantAddedEvent'
export default {
  name: 'VoiceControllerParticipants',
  components: { FrequencyBall },
  data() {
    return {
      participants: {},
      participantUids: [],
    }
  },
  mounted () {
    this.$voiceController.addEventListener(EVENT_PARTICIPANT_ADDED, this.onParticipantAdded);
    this.$voiceController.addEventListener(EVENT_PARTICIPANT_REMOVED, this.onParticipantRemoved);
    this.$voiceController.addEventListener(EVENT_FREQUENCY_CHANGED, this.onFrequencyChanged);

    this.updateParticipantsFromVoiceController();
  },
  methods: {
    updateParticipantsFromVoiceController() {
      this.participants = this.$voiceController.participants;
    },
    onParticipantAdded(e) {
      const participant = e.participant;

      if (this.participantUids.includes(participant.uid)) {
        // Already there, not _readding_ it

        return;
      }

      this.participants[participant.uid] = participant;

      this.participantUids.push(participant.uid);
    },
    onParticipantRemoved(e) {
      const participant = e.participant;

      if (!this.participantUids.includes(participant.uid)) {
        // No idea who you're trying to delete but they do not exist

        return;
      }

      delete this.participants[participant.uid];

      let index = this.participantUids.indexOf(participant.uid);

      if (index !== -1) {
        this.participantUids.splice(index, 1);
      }
    },
    onFrequencyChanged(e) {
      const uid = e.uid;

      this.participants[uid].frequency = e.frequency;
    },
  }
}
</script>
