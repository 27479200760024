import {ensureArray} from '@/utils/arrayUtil';

export function getImplementedActionsFromRoom(room) {
  if (!room) {
    console.log('Cannot get implemented actions as no room');
    return [];
  }

  if (!room.implementedActions) {
    console.log('Cannot get implemented actions as empty');
    return [];
  }

  const implementedActions = ensureArray(room.implementedActions);

  for (const implementedAction of implementedActions) {
    if (implementedAction.actionType === 'strategic') {
      implementedAction.categoryLabel = 'Strategic actions - Month ' + implementedAction.investAtMeeting;
      // TODO
    } else if (implementedAction.actionType === 'hazard') {
      implementedAction.categoryLabel = 'Hazards responses - Month ' + implementedAction.investAtMeeting;
    }
  }

  return implementedActions;
}

export function implementedActionsToHazardActions(implementedActions) {
  const implementedActionsFiltered = [];

  for (const implementedAction of implementedActions) {
    if (implementedAction.actionType !== 'hazard') {
      continue;
    }

    const indicatorsImpacted = {};

    const jeeImpacts = ensureArray(implementedAction.jeeImpacts);

    for (const jeeImpacted of jeeImpacts) {
      if (jeeImpacted.impact > 0) {
        indicatorsImpacted[jeeImpacted.identifier] = jeeImpacted.impact;
      }
    }

    implementedActionsFiltered.push({
      ...implementedAction,

      indicatorsImpacted: indicatorsImpacted,
      month: implementedAction.investAtMeeting,
      description: implementedAction.text,
      inResponseTo: implementedAction.inResponseTo,
    });
  }

  return implementedActionsFiltered;
}

export function implementedActionsToStrategicActions(implementedActions) {
  const implementedActionsFiltered = [];

  for (const implementedAction of implementedActions) {
    if (implementedAction.actionType !== 'strategic') {
      continue;
    }

    const indicatorsImpacted = {};

    const jeeImpacts = ensureArray(implementedAction.jeeImpacts);

    for (const jeeImpacted of jeeImpacts) {
      if (jeeImpacted.impact > 0) {
        indicatorsImpacted[jeeImpacted.identifier] = jeeImpacted.impact;
      }
    }

    implementedActionsFiltered.push({
      ...implementedAction,

      indicatorsImpacted: indicatorsImpacted,
      month: implementedAction.investAtMeeting,
      description: implementedAction.text,
      inResponseTo: implementedAction.inResponseTo,
    });
  }

  return implementedActionsFiltered;
}
