<template>
  <div class="character-plate" :class="computedClasses">
    <div class="character-plate-waiting-for-background">WAITING FOR</div>

    <header>
      <character-thumbnail :character-index="characterIndex" :show-background="true" :size="size" class="character-thumbnail" />
      <cost-box v-if="cost > 0" class="cost">{{ cost }}</cost-box>
      <cost-box v-else class="cost-box-ready" title="The user is ready">✓</cost-box>
      <!--<div class="character-muted" v-if="!isSelf">
        <no-drag-image src="@/assets/icons/microphone/icon-muted.png" />
      </div>-->
      <flag-icon v-if="flagCount >= 0" :value="flagCountWithTotal" text-size="sm" />
    </header>
    <footer>
      <microphone-icon v-if="showVoiceStatus" :muted="localMuted" class="microphone-icon" :class="'microphone-icon-' + size" :with-background="true" @click="toggleLocalMute" />
      <div class="character-nameplate">
        {{ name || '' }}
      </div>
      <role-progress-ball
        v-if="role"
        :role="role"
        :size="size"
        :with-background="true"
        :current-points="rolePointsCalc"
        :total-points="rolePointsTotal"
      />
    </footer>
  </div>
</template>

<script>
import CharacterData from '@/data/character-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import MicrophoneIcon from '@/components/Icon/MicrophoneIcon.vue'
import RoleProgressBall from '@/components/character/RoleProgressBall.vue'
import CostBox from '@/components/common/CostBox.vue'
import { PLAYER_TYPE_HUMAN } from '@/enum/PlayerType'
import FlagIcon from '@/components/player-ui/FlagIcon.vue'

export default {
  name: 'CharacterPlate',
  components: { FlagIcon, CostBox, RoleProgressBall, MicrophoneIcon, NoDragImage, CharacterThumbnail },
  props: {
    characterIndex: {
      type: Number,
      required: false,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    role: {
      type: Object,
      required: false,
      default: null,
    },
    showVoiceStatus: {
      type: Boolean,
      default: false,
      required: false,
    },
    rolePoints: {
      type: Number,
      default: null,
      required: false,
    },
    rolePointsTotalInvestment: {
      type: Number,
      default: 0,
      required: false,
    },
    cost: {
      type: Number,
      default: 0,
      required: false,
    },
    voiceFrequency: {
      type: Number,
      default: 0,
      required: false,
    },
    playerType: {
      type: Number,
      default: PLAYER_TYPE_HUMAN,
      required: false,
    },
    isSelf: {
      type: Boolean,
      default: false,
      required: false,
    },
    muted: {
      type: Boolean,
      default: false,
      required: false,
    },
    localMuted: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasJoined: {
      type: Boolean,
      default: false,
      required: false,
    },
    isReady: {
      type: Boolean,
      default: false,
      required: false,
    },
    flagCount: {
      type: Number,
      default: -1,
      required: false,
    },
    flagCountTotal: {
      type: Number,
      default: -1,
      required: false,
    },
    waitingFor: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  emits: ['updated:localMute'],
  computed: {
    rolePointsTotal() {
      return 60; // TODO: Fetch from some settings file?
    },
    flagCountWithTotal() {
      if (this.flagCountTotal > 0) {
        return `${this.flagCount} / ${this.flagCountTotal}`;
      }

      return this.flagCount;
    },
    computedClasses() {
      const classes = [
        `character-plate-size-${this.size}`,
        `character-plate-player-type-${this.playerType === PLAYER_TYPE_HUMAN ? 'human' : 'robot'}`,
        this.size !== 'tiny' ? `character-plate-${this.hasJoined ? 'joined' : 'not-joined'}` : '',
      ];

      if (this.showVoiceStatus) {
        classes.push(`character-plate-with-voice-status`);

        if (this.voiceFrequency >= 15) {
          classes.push(`character-plate-is-talking`);
        }

        if (this.muted) {
          classes.push(`character-plate-remote-muted`);
        }

        if (this.localMuted) {
          classes.push(`character-plate-local-muted`);
        }
      }

      if (this.isReady) {
        classes.push(`character-plate-is-ready`);
      }

      if (this.waitingFor) {
        classes.push(`character-plate-waiting-for`);
      }

      return classes;
    },
    computedTitle() {
      return this.name || null;
    },
    rolePointsCalc() {
      return Math.max(0, this.rolePoints - this.rolePointsTotalInvestment);
    },
  },
  methods: {
    toggleLocalMute() {
      if (this.isSelf) {
        this.$emit('updated:localMute', !this.muted);
      } else {
        this.$emit('updated:localMute', !this.localMuted);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.character-plate {
  position: relative;
  transition: 200ms all ease-in-out;

  .character-thumbnail {
    transition: 200ms all ease-in-out;
  }

  &.character-plate-joined {
    filter: none;
    opacity: 1;
  }

  &.character-plate-remote-muted {
    .character-muted {
      opacity: 1;
    }
  }

  &.character-plate-not-joined {
    filter: grayscale(1) blur(5px);
    opacity: .6;
    pointer-events: none !important;
    user-select: none !important;
  }

  // Talking
  &.character-plate-is-talking {
    .character-thumbnail {
      box-shadow: 0px 0px 6px 6px rgba(143, 255, 0, 0.7), 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    }
  }

  &.character-plate-is-ready {
    .cost-box-ready {
      opacity: 1;
    }
  }

  &.character-plate-waiting-for {
    .character-plate-waiting-for-background {
      opacity: 1;
    }
  }

  // Size
  &.character-plate-size-tiny {
    width: 50px;

    .character-plate-waiting-for-background {
      width: 64px;
      height: 90px;

      font-size: 6pt;
      line-height: 25px;
    }

    .role-progress-ball {
      position: absolute;
      right: -15px;
      bottom: 28px;
    }

    .character-nameplate {
      padding: 5px 2px 0 2px;
      font-size: 5pt;
      height: 25px;
      top: -12px;
    }

    &.character-plate-with-voice-status .character-nameplate {
      padding-left: 20px;
    }

    .microphone-icon {
      width: 20px;
      height: 25px;
      left: 0px;
      bottom: 12px;
    }

    .cost, .cost-box-ready {
      top: 0px;
      right: -15px;
      transform: scale(60%);
    }

    .character-muted {
      bottom: 12px;
      height: 53%;
      padding: 4px 8px;
    }
  }

  &.character-plate-size-small {
    width: 70px;

    .character-plate-waiting-for-background {
      width: 84px;
      height: 110px;

      font-size: 7pt;
      line-height: 30px;
    }

    .role-progress-ball {
      position: absolute;
      right: -15px;
      bottom: 30px;
    }

    .character-nameplate {
      padding: 8px 2px 0 2px;
      height: 28px;
      font-size: 8pt;
      top: -12px;
    }

    &.character-plate-with-voice-status .character-nameplate {
      padding-left: 25px;
    }

    .microphone-icon {
      width: 25px;
      height: 28px;
      left: 0px;
      bottom: 12px;
    }

    .cost, .cost-box-ready {
      top: 5px;
      right: -13px;
      transform: scale(80%);
    }

    .character-muted {
      bottom: 12px;
      height: 58%;
      padding: 8px 12px;
    }
  }

  &.character-plate-size-medium-small {
    width: 110px;

    .character-plate-waiting-for-background {
      width: 123px;
      height: 140px;

      font-size: 10pt;
      line-height: 30px;
    }

    .role-progress-ball {
      position: absolute;
      right: -25px;
      bottom: 30px;
    }

    .character-nameplate {
      padding: 8px 2px 0 2px;
      height: 28px;
      font-size: 8pt;
      top: -12px;
    }

    &.character-plate-with-voice-status .character-nameplate {
      padding-left: 25px;
    }

    .microphone-icon {
      width: 25px;
      height: 28px;
      left: 0px;
      bottom: 12px;
    }

    .cost, .cost-box-ready {
      top: 5px;
      right: -13px;
      transform: scale(80%);
    }

    .character-muted {
      bottom: 12px;
      height: 60%;
      padding: 8px 12px;
    }
  }

  &.character-plate-size-medium {
    width: 150px;

    .character-plate-waiting-for-background {
      left: -11px;
      top: -20px;
      width: 170px;
      height: 200px;

      font-size: 15pt;
      line-height: 50px;
    }

    .role-progress-ball {
      position: absolute;
      right: -25px;
      top: -50px;
    }

    .character-nameplate {
      padding: 5px 5px 0 5px;
      font-size: 12pt;
      height: 41px;
      top: -13px;
    }

    &.character-plate-with-voice-status .character-nameplate {
      padding-left: 30px;
    }

    .microphone-icon {
      width: 30px;
      height: 41px;
      left: 0px;
      bottom: 13px;
      cursor: pointer;
    }

    .cost, .cost-box-ready {
      top: 25px;
      right: -10px;
    }

    .character-muted {
      bottom: 13px;
      height: 62%;
      padding: 20px 30px;
    }

    .flag-icon {
      position: absolute;
      top: -30px;
      right: -70px;
      transform: scale(.8);
      letter-spacing: -2px;
    }
  }

  &.character-plate-size-large {
    width: 250px;

    .role-progress-ball {
      position: absolute;
      right: -35px;
      top: -65px;
    }

    .character-nameplate {
      padding: 5px 5px 0 5px;
      font-size: 12pt;
      height: 55px;
      top: -13px;
    }

    &.character-plate-with-voice-status .character-nameplate {
      padding-left: 30px;
    }

    .microphone-icon {
      width: 40px;
      height: 55px;
      left: 0px;
      bottom: 13px;
      cursor: pointer;

      img {
        width: 60px;
      }
    }

    .cost, .cost-box-ready {
      top: 25px;
      right: -10px;
    }

    .character-muted {
      bottom: 13px;
      height: 62%;
      padding: 20px 30px;
    }
  }
}

.cost, .cost-box-ready {
  position: absolute;
  user-select: none;
}

.cost-box-ready {
  transition: 200ms all ease-in-out;
  opacity: 0;
}

.character-nameplate {
  background-size: 100% 100%;
  color: white;
  padding: 2px 5px;
  font-size: 12pt;
  position: relative;

  box-shadow: inset -4.44697px -4.44697px 3.55758px rgba(0, 0, 0, 0.4), inset 3.55758px 3.55758px 2.66818px rgba(255, 255, 255, 0.4);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
}

.role-progress-ball {
  position: absolute;
  bottom: 20px;
}

.microphone-icon {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

header, footer {
  position: relative;
}

.character-muted {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  background: rgba(#BA5151, .7);
  transition: 200ms ease-in-out;
}

.character-plate-waiting-for-background {
  opacity: 0;

  background: url("@/assets/ui/timer/character-waiting-for-background.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: -17px;
  left: -7px;
  text-align: center;
  color: white;
  font-weight: 700;

  transition: 200ms ease-in-out;
}
</style>

<style lang="scss">
.character-plate {
  &.character-plate-size-medium .microphone-icon {
    img {
      top: 42%;
      width: 17px * 1.3;
      height: 17px * 1.3;
    }
  }

  &.character-plate-size-large .microphone-icon {
    img {
      top: 45%;
      width: 17px * 1.7;
      height: 17px * 1.7;
    }
  }
}
</style>
