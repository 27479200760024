<template>
  <hazard-popup-summary
    class="hazard-popup-summary"
    :call-meeting-cost="callMeetingCost"
    :title="title"
    :icon="icon"
    :text="text"
    :media-source="mediaSource"
    :media-caption="mediaCaption"

    @click:cancel="onClickCancel"
    @click:call-meeting="onClickCallMeeting"
  />

  <dev-settings-panel>
    <div class="row">
      <button class="btn" :class="textType === 'short' ? 'btn-success' : 'btn-dark'" @click="textType = 'short'">Short</button>
      <button class="btn" :class="textType === 'medium' ? 'btn-success' : 'btn-dark'" @click="textType = 'medium'">Medium</button>
      <button class="btn" :class="textType === 'long' ? 'btn-success' : 'btn-dark'" @click="textType = 'long'">Long</button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupSummary from './index.vue'
import HazardPopupIntro from '@/components/HazardMeeting/HazardPopupIntro/index.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'

export default {
  name: 'HazardPopupSummaryDev',
  components: {
    DevSettingsPanel,
    HazardPopupIntro,
    HazardPopupSummary,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['cancel', 'callMeeting'],

      //text: '**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.',
      mediaSource: 'https://www.youtube.com/watch?v=ZuRLOlB4N8U',
      mediaCaption: 'If you weren\'t quiet sure about what a cute animal is, this might explain it.',

      title: 'Measles',
      icon: 'infectious',

      callMeetingCost: 1,

      textType: 'short',
    }
  },
  computed: {
    text() {
      if (this.textType === 'short') {
        return '**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.';
      } else if (this.textType === 'medium') {
        return '**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.\n\n**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.';
      } else if (this.textType === 'long') {
        return '**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.\n\n**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.\n\n**There are lots of cute animals in the world, but there\'s only so many homes. It is up to you to decide who gets adopted.**\n\nMake sure you make the right choice. Or else.';
      }

      return `Unknown text type ${this.textType}`;
    }
  },
  methods: {
    onClickCancel() {
      this.triggerEvent('cancel')
    },
    onClickCallMeeting() {
      this.triggerEvent('callMeeting')
    }
  },
}
</script>

<style lang="scss" scoped>
.hazard-popup-summary {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
