<template>
  <div>
    <cost-box>1</cost-box>
    <cost-box>10</cost-box>
    <cost-box>100</cost-box>
    <cost-box>1000</cost-box>
    <cost-box>10000</cost-box>
    <cost-box>{{ cost }}</cost-box>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import AppButton from '@/components/common/buttons/AppButton.vue'
import ImageButton from '@/components/common/buttons/ImageButton.vue'
import TinyButton from '@/components/common/TinyButton.vue'
import TableColumnDescriptor from '@/components/common/TableColumnDescriptor.vue'
import ToggleImageButton from '@/components/common/buttons/ToggleImageButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'
import CostBox from '@/components/common/CostBox.vue'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'CostBoxDev',
  components: {
    CostBox,},
  extends: DevComponentContainer,

  data() {
    return {
      cost: 0
    }
  },
  mounted() {
    this.onTimeoutTick();
  },
  unmounted () {
    this.stopTimeout();
  },
  methods: {
    stopTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);

        this.timeout = null;
      }
    },
    startTimeout() {
      this.stopTimeout();

      this.timeout = setTimeout(this.onTimeoutTick, 1000);
    },
    onTimeoutTick() {
      this.cost = Math.floor(randomBetween(0x0000, 0xffff));

      this.startTimeout();
    }
  },
};
</script>

<style lang="scss" scoped>
.app-button-dev-container {
  color: white !important;
  width: 1200px;

  > .row {
    padding-top: 30px;
  }
}

table {
  background: rgba(0, 0, 0, 60%);
  tr {
    th, td {
      color: white !important;
    }
  }
}

.animated-button-container {
  width: 500px;
  height: 500px;
}
</style>
