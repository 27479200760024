<template>
  <div class="waiting-on-players">
    <p>Waiting on players</p>

    <div class="players-container d-flex align-items-stretch flex-nowrap justify-content-center">
      <div
        class="player-container"
        v-for="player in players" :class="{
          'player-waiting': waitingOnPlayerCodes.includes(player.playerCode),
          'player-ready': !waitingOnPlayerCodes.includes(player.playerCode)
        }">
        <character-thumbnail
          :character-index="player.characterIndex"
          size="tiny"
        />

        <img src="@/assets/icons/check-icon-small.png" class="check-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue';
import { objectToArray } from '@/utils/arrayUtil';

export default {
  name: 'WaitingOnPlayers',
  components: { CharacterThumbnail },
  props: {
    players: {
      type: Object,
      required: true,
    },
    waitingOnPlayerCodes: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
}
</script>

<style lang="scss">
.waiting-on-players {
  font-size: 1.5rem;

  width: 300px;
  margin: 0 auto;
}

.player-container {
  position: relative;
  transition: filter 200ms ease-in-out;
  margin: 0 5px;

  .check-icon {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: all 200ms ease-in-out;
  }

  &.player-waiting {
    filter: saturate(0);

    .check-icon {
      top: 20px;
      opacity: 0;
    }
  }

  &.player-ready {
    filter: saturate(1);

    .check-icon {
      top: 2px;
      opacity: 1;
    }
  }
}
</style>
