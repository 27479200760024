<script>

import {
  getConfidenceLevelLabel,
  getCopingCapacityLabel,
  getCopingCapacityLevel,
  getFrequencyLabel, getImpactIdentifier,
  getImpactLabel,
  getImpactLevel, getLikelihoodIdentifier,
  getLikelihoodLabel,
  getLikelihoodLevel, getRiskLevelIdentifier,
  getRiskLevelLabel,
  getRiskLevelLevel,
  getSeverityLabel,
  getSeverityLevel,
  getVulnerabilityLabel,
  getVulnerabilityLevel,
} from '@/components/StarMinigame/star-utils'

export default {
  name: 'TableMixins',
  methods: {
    getLikelihoodLabel (likelihood) {
      return getLikelihoodLabel(likelihood);
    },

    getLikelihoodLevel (likelihood) {
      return getLikelihoodLevel(likelihood);
    },

    getSeverityLabel (severity) {
      return getSeverityLabel(severity);
    },

    getSeverityLevel (severity) {
      return getSeverityLevel(severity);
    },

    getVulnerabilityLabel (vulnerability) {
      return getVulnerabilityLabel(vulnerability);
    },

    getVulnerabilityLevel (vulnerability) {
      return getVulnerabilityLevel(vulnerability);
    },

    getCopingCapacityLabel (copingCapacity) {
      return getCopingCapacityLabel(copingCapacity);
    },

    getCopingCapacityLevel (copingCapacity) {
      return getCopingCapacityLevel(copingCapacity);
    },

    getImpactLabel (impact) {
      return getImpactLabel(impact);
    },

    getImpactLevel (impact) {
      return getImpactLevel(impact);
    },

    getRiskLevelLabel (riskLevel) {
      return getRiskLevelLabel(riskLevel);
    },

    getRiskLevelLevel (riskLevel) {
      return getRiskLevelLevel(riskLevel);
    },

    getFrequencyLabel (frequency) {
      return getFrequencyLabel(frequency);
    },

    getConfidenceLevelLabel (confidenceLevel) {
      return getConfidenceLevelLabel(confidenceLevel);
    },

    getLikelihoodIdentifier(likelihoodLevel) {
      return getLikelihoodIdentifier(likelihoodLevel);
    },

    getImpactIdentifier(impactLevel) {
      return getImpactIdentifier(impactLevel);
    },
    getRiskLevelIdentifier(riskLevelLevel) {
      return getRiskLevelIdentifier(riskLevelLevel);
    },
  },
}
</script>
