import {
  STAR_MINIGAME_TUTORIAL_STATE_COPING_CAPACITY,
  STAR_MINIGAME_TUTORIAL_STATE_FREQUENCY,
  STAR_MINIGAME_TUTORIAL_STATE_LIKELIHOOD,
  STAR_MINIGAME_TUTORIAL_STATE_NONE,
  STAR_MINIGAME_TUTORIAL_STATE_SEASONALITY,
  STAR_MINIGAME_TUTORIAL_STATE_SEVERITY,
  STAR_MINIGAME_TUTORIAL_STATE_VULNERABILITY
} from '@/enum/StarMinigameTutorialState'
import {
  STAR_MINIGAME_VOTE_STATE_LIKELIHOOD,
  STAR_MINIGAME_VOTE_STATE_IMPACT,
  STAR_MINIGAME_VOTE_STATE_NONE
} from '@/enum/StarMinigameVoteState'
import {
  STAR_MINIGAME_STATE_FORCED_DOCUMENT,
  STAR_MINIGAME_STATE_NOT_BEGUN,
  STAR_MINIGAME_STATE_OVERVIEW,
  STAR_MINIGAME_STATE_RISK_MATRIX,
  STAR_MINIGAME_STATE_TUTORIAL,
  STAR_MINIGAME_STATE_VOTE
} from '@/enum/StarMinigameState'
import {
  STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_OLD,
  STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_NEW,
  STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_CORRECT
} from '@/enum/StarMinigameRiskMatrixSwitchValue'

export const RiskMatrixSwitchValue = {
  OLD: STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_OLD,
  NEW: STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_NEW,
  CORRECT: STAR_MINIGAME_RISK_MATRIX_SWITCH_VALUE_CORRECT,
}

export const State = {
  NOT_BEGUN: STAR_MINIGAME_STATE_NOT_BEGUN,
  FORCED_DOCUMENT: STAR_MINIGAME_STATE_FORCED_DOCUMENT,
  OVERVIEW: STAR_MINIGAME_STATE_OVERVIEW,
  TUTORIAL: STAR_MINIGAME_STATE_TUTORIAL,
  VOTE: STAR_MINIGAME_STATE_VOTE,
  RISK_MATRIX: STAR_MINIGAME_STATE_RISK_MATRIX,
}

export const TutorialState = {
  NONE: STAR_MINIGAME_TUTORIAL_STATE_NONE,
  FREQUENCY: STAR_MINIGAME_TUTORIAL_STATE_FREQUENCY,
  SEASONALITY: STAR_MINIGAME_TUTORIAL_STATE_SEASONALITY,
  LIKELIHOOD: STAR_MINIGAME_TUTORIAL_STATE_LIKELIHOOD,
  SEVERITY: STAR_MINIGAME_TUTORIAL_STATE_SEVERITY,
  VULNERABILITY: STAR_MINIGAME_TUTORIAL_STATE_VULNERABILITY,
  COPING_CAPACITY: STAR_MINIGAME_TUTORIAL_STATE_COPING_CAPACITY,
}

export const VoteState = {
  NONE: STAR_MINIGAME_VOTE_STATE_NONE,
  LIKELIHOOD: STAR_MINIGAME_VOTE_STATE_LIKELIHOOD,
  IMPACT: STAR_MINIGAME_VOTE_STATE_IMPACT,
}
