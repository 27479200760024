const playerVoiceFrequencyDecayTimeouts = {};
//const easingSpeed = 0.05;
const decreaseSpeed = 1.5;

const decayIntervalFps = 10;
const decayIntervalMs = 1000 / decayIntervalFps;

const minValue = 0.1; // Frequency goes from 0-100

function removeVoiceFrequencyTimeout(playerId) {
  if (playerVoiceFrequencyDecayTimeouts[playerId]) {
    clearTimeout(playerVoiceFrequencyDecayTimeouts[playerId]);

    delete playerVoiceFrequencyDecayTimeouts[playerId];
  }
}

function easeTowardsZero(number) {
  if (number === null || number === false || number === undefined) {
    return 0;
  }

  return Math.max(0, number - decreaseSpeed);
  //return number - (number * easingSpeed);
}

export function removeAllPlayerVoiceFrequencyTimeouts() {
  for (const playerId in playerVoiceFrequencyDecayTimeouts) {
    removeVoiceFrequencyTimeout(playerId);
  }
}

export function setPlayerVoiceFrequencyByVoiceUid($store, voiceUid, voiceFrequency) {
  let foundPlayerId;
  let foundPlayer;

  for (const playerId in $store.state.room.players) {
    const player = $store.state.room.players[playerId];

    if (player.voiceSocketId === voiceUid) {
      foundPlayerId = playerId;
      foundPlayer = player;

      break;
    }
  }

  if (!foundPlayerId) {
    console.error(`Could not find room player by voice uid ${voiceUid}`);

    return;
  }

  const storePlayerSet = function(data) {
    $store.commit('roomSetPlayerLocalData', {
      playerUid: foundPlayerId,
      data: data,
    });
  };

  // Set the current values
  const setData = {
    voiceFrequency: voiceFrequency,
  }

  //console.log('SETTING VOICE FREQUENCY', voiceFrequency);

  if ((foundPlayer.muted || foundPlayer.mutedLocally) && (foundPlayer.voiceFrequency > 0 || foundPlayer.voiceFrequencyDecay > 0)) {
    removeVoiceFrequencyTimeout(foundPlayerId);

    storePlayerSet({
      voiceFrequency: 0,
      voiceFrequencyDecay: 0,
    });

    return;
  }

  if (voiceFrequency >= foundPlayer.voiceFrequencyDecay) {
    // It's higher, let's set it to the current one
    setData.voiceFrequencyDecay = voiceFrequency;
    //console.log('reset to ', voiceFrequency);
  } else {
    // It's lower, let's slowly ease towards 0;
    setData.voiceFrequencyDecay = easeTowardsZero(foundPlayer.voiceFrequencyDecay);
  }

  //console.log('voiceFrequencyDecay set from main', foundPlayerId, setData);

  storePlayerSet(setData);

  // Set up decay timeout
  removeVoiceFrequencyTimeout(foundPlayerId);

  if (setData.voiceFrequencyDecay >= minValue) {
    playerVoiceFrequencyDecayTimeouts[foundPlayerId] = setInterval(() => {
      // let's slowly ease towards 0
      let newVoiceFrequencyDecay = easeTowardsZero(foundPlayer.voiceFrequencyDecay);

      if (newVoiceFrequencyDecay <= minValue) {
        newVoiceFrequencyDecay = 0;
      }

      //console.log('voiceFrequencyDecay set from timeout', foundPlayer.voiceFrequencyDecay, newVoiceFrequencyDecay, foundPlayerId);

      storePlayerSet({
        voiceFrequencyDecay: newVoiceFrequencyDecay
      });

      if (newVoiceFrequencyDecay === 0) {
        removeVoiceFrequencyTimeout(foundPlayerId);
      }
    }, decayIntervalMs);
  }
}
