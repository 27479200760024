<template>
  <lobby-popup-template :show-footer="false" :style="{ width: '800px' }" :content-class="'small-padding error-popup'">
    <template v-slot:header>
      {{ $t('error.title') }}

      <close-button @click="onClickClose" class="btn-close-popup" />
    </template>
    <template v-slot:content>
      <div class="error-popup-body">
        {{ errorMessage }}
      </div>
      <div class="error-popup-buttons">
        <app-button color="green" width="lg" height="lg" class="btn-okay" @click="onClickOk">{{ $t('error.buttonOk') }}</app-button>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import HazardPrioritizationItem from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPrioritizationItem.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'

export default {
  name: 'ErrorPopupInner',
  components: {
    CloseButton,
    LobbyPopupTemplate,
    HazardPrioritizationItem,
    AppButton,
  },
  props: {
    errorMessage: {
      type: String,
      required: true,
    }
  },
  emits: ['close'],
  methods: {
    onClickOk() {
      this.$emit('close');
    },
    onClickClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-close-popup {
  position: absolute;
  top: 0;
  right: 0;
}
.error-popup-body {
  color: #ffffff;
  padding: 10px;
  font-size: 16pt;
}

.error-popup-buttons {
  margin-bottom: 10px;
}
</style>
