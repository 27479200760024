<template>
  <div class="bar-root">
    <div class="top-row">
      <img class="icon" :src="clockIcon"/>
      <p>Time to Event</p>
      <p class="countdown-text">11 months & 23 days</p>
    </div>
    <div class="bottom-row">
      <div class="progress-bar-outer">
        <div class="progress-bar-inner" :style="{width: calculatedWidthPercentage + '%'}"/>
        <div class="monthly-event-icons-container">
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>
          <div class="monthly-event-icon-root">
            <div class="monthly-event-icon-inner-circle">
              <img class="monthly-event-icon-inner-icon" :src="messageIcon"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Icons from '@/utils/icons';
export default {
  name: 'GameEventTimerCard',
  props: {
    maxGameDuration: Number,
    gameTimeRemaining: Number
  },
  computed: {
    calculatedWidthPercentage () {
      return (this.gameTimeRemaining / this.maxGameDuration) * 100
    }
  },
  data () {
    return {
      clockIcon: Icons.ClockIcon,
      messageIcon: Icons.MessageIcon,
    }
  },
}

</script>

<style scoped lang="scss">

.bar-root {
  background: rgba(0, 0, 0, 0.6);
  width: 698px;
  height: 94px;
  border-radius: 10px;
  margin-right: 1rem;
}

.top-row {
  width: 100%;
  height: 52.5%;
  display: flex;
  align-content: center;
  padding: 0.5rem;
  position: relative;
}
.bottom-row {
  width: 100%;
  height: 47.5%;
  display: flex;
  padding: 0.5rem;
}

.icon {
  height: 3rem;
  width: 3rem;
}

p {
  margin-top: 0.75rem;
  height: 100%;
  font-weight: bold;
  color: white;
  font-size: 20px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 110%;
  padding-left: 0.35rem;
}

.countdown-text{
  position: absolute;
  right:0;
  margin-right: 10px;
}


.progress-bar-outer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  border-radius: 4px;
  background: linear-gradient(180deg, #32CACA 0%, #253D62 60%);

}

.progress-bar-inner {
  height: 100%;
  transition-duration: 0.5s;
  position: absolute;
  top: 0;
  right: 0;

  background: linear-gradient(180deg, #FFE8C6 0%, #F09000 45.31%);
  border-radius: 4px;
}

.monthly-event-icons-container{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}


.monthly-event-icon-root{
  width: 27px;
  height: 27px;
  background:  linear-gradient(180deg, rgba(50, 202, 202, 1) 100%, rgba(37, 61, 98, 1) 100% );
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.monthly-event-icon-inner-circle{
  width: 25px;
  height: 25px;
  background: #1F3E52;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthly-event-icon-inner-icon{
  height: 15px;
  width: 15px;
}
</style>
