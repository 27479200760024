<template>
  <div class="row mt-2">
    <div class="leaderboard-container">
      <leaderboard v-if="ranks" :ranks="ranks" />
    </div>
  </div>



  <dev-settings-panel>
    <div class="row">
      <button class="btn btn-success" @click="randomize">Randomize</button>
    </div>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import {randomBetween} from '@/utils/randomUtil';
import {generateRandomDate, generateTeamName} from '@/utils/generator';
import {generatePlayersData} from '@/utils/playerDataGenerator';
import Leaderboard from '@/components/Leaderboard/index.vue';
import {objectToArray} from '@/utils/arrayUtil';

export default {
  name: 'LeaderboardDev',
  components: {
    Leaderboard,
    DevSettingsPanel,
    BootstrapSelect,
    FloatingHazardIcon,
    ButtonContainer
  },
  extends: DevComponentContainer,

  data () {
    return {
      ranks: null,
    }
  },
  methods: {
    randomize() {
      const ranks = [];

      const minDate = new Date('2023-01-01');
      const maxDate = new Date('2023-08-01');

      for (let i = 0; i < 100; i++) {
        ranks.push({
          teamName: generateTeamName(),
          finalScore: Math.round(randomBetween(50, 1000)),
          date: generateRandomDate(minDate, maxDate),
          teamMembers: objectToArray(generatePlayersData(Math.round(randomBetween(2, 5))))
        })
      }

      ranks.sort((a, b) => {
        if (a.finalScore < b.finalScore) {
          return 1;
        } else if (a.finalScore > b.finalScore) {
          return -1;
        }

        return 0;
      });

      for (let i = 0, len = ranks.length; i < len; i++) {
        ranks[i].position = i + 1;
      }

      console.log('ranks', ranks);
      this.ranks = ranks;
    },
  },
  mounted() {
    this.randomize();
  },
}
</script>

<style lang="scss" scoped>
.leaderboard-container {
  width: 1000px;
  margin: 0 auto;
  background: rgba(0, 0, 0, .8);
}
</style>
