<template>
  <div class="dev-settings-panel" ref="settings-panel">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DevSettingsPanel',
  mounted() {
    const devSettingsPanel = document.getElementById('dev-settings-panel');

    //console.log('devSettingsPanel', devSettingsPanel);

    devSettingsPanel.innerHTML = ''; // Clear

    devSettingsPanel.appendChild(this.$refs['settings-panel']);
  }
}
</script>

<style lang="scss" scoped>
</style>
