export default {
  state: {
    tutorialIdentifiersOpened: [],
    tutorialGroupIdentifiersOpened: [],
  },
  mutations: {
    addTutorialIdentifierOpened: (state, tutorialIdentifier) => {
      if (state.tutorialIdentifiersOpened.includes(tutorialIdentifier)) {
        return;
      }

      state.tutorialIdentifiersOpened.push(tutorialIdentifier);
    },
    addTutorialGroupIdentifierOpened: (state, tutorialGroupIdentifier) => {
      if (state.tutorialGroupIdentifiersOpened.includes(tutorialGroupIdentifier)) {
        return;
      }

      state.tutorialGroupIdentifiersOpened.push(tutorialGroupIdentifier);
    },
  },
  getters: {
    tutorialIdentifiersOpened (state) {
      return state.tutorialIdentifiersOpened;
    },
    tutorialGroupIdentifiersOpened (state) {
      return state.tutorialGroupIdentifiersOpened;
    },
  },
}
