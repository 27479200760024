<template>
  <top-bar
    :trust="computedTrust"
    :collaboration="computedCollaboration"
    :health-security="computedHealthSecurity"
    :mg-event-success="computedMgEventSuccess"
    :game-time="gameTime || 0"
    :action-points="actionPoints || 0"
    :total-action-points-cost="totalActionPointsCost || null"
    :is-paused="isPaused || false"
    :tutorial-active="tutorialActive"
    @activate-dev-mode="onActivateDevMode"
    @pauseMode:disable="onPauseModeDisable"
    @pauseMode:enable="onPauseModeEnable"
    :style="computedStyle"
  />
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import TopBar from '@/components/player-ui/TopBar';
import {mapState} from 'vuex';
import {
  HAZARD_MEETING_STATE_ENDED,
  HAZARD_MEETING_STATE_SHOW_ANNEX_2,
  HAZARD_MEETING_STATE_STARTED
} from '@/enum/HazardMeetingState'

export default {
  name: 'TopBarLive',
  components: { TopBar },
  extends: DevComponentContainer,

  props: {
    zIndex: {
      type: Number,
      required: false,
      default: null,
    },
    tutorialActive: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
    }
  },
  emits: [
    'activate-dev-mode',
    'update:trust',
    'update:collaboration',
    'update:healthSecurity',
    'update:mgEventSuccess',
    'pause-pressed'
  ],
  watch: {
    trust() {
      this.$emit('update:trust', this.trust);
    },
    collaboration() {
      this.$emit('update:collaboration', this.collaboration);
    },
    healthSecurity() {
      this.$emit('update:healthSecurity', this.healthSecurity);
    },
    mgEventSuccess() {
      this.$emit('update:mgEventSuccess', this.mgEventSuccess);
    },
  },
  computed: {
    ...mapState(['room', 'investmentCartCostAp', 'topUiExtraTrust', 'topUiExtraCollaboration', 'topUiExtraHealthSecurity', 'topUiExtraMgEventSuccess']),
    computedStyle() {
      return {
        'z-index': this.zIndex || null,
      };
    },
    actionPoints() {
      if (!this.room) {
        return 0;
      }

      //return this.actionPoints;
      let actionPoints = this.room.actionPoints;

      //if (this.room.hazardMeetingState !== HAZARD_MEETING_STATE_ENDED && this.room.hazardMeetingState !== HAZARD_MEETING_STATE_SHOW_ANNEX_2) {
     //   actionPoints -= this.totalActionPointsCost;
      //}

      return actionPoints;
    },
    totalActionPointsCost() {
      let totalCost = 0;

      if (this.room.activeStrategicMeeting) {
        if (this.room.activeStrategicMeeting.totalInvestment && this.room.activeStrategicMeeting.totalInvestment > 0) {
          totalCost += this.room.activeStrategicMeeting.totalInvestment;
        }
      }

      if (this.room.activeHazardMeeting) {
        if (this.room.activeHazardMeeting.currentHazardIndex >= 0) {
          if (this.room.hazardMeetingState === HAZARD_MEETING_STATE_STARTED) {
            const activeHazardMeeting = this.room.activeHazardMeeting.prioritizedWorldHazards[this.room.activeHazardMeeting.currentHazardIndex]

            if (activeHazardMeeting.totalInvestment && activeHazardMeeting.totalInvestment > 0) {
              totalCost += this.room.activeHazardMeeting.totalInvestment;
            }
          }
        }
      }

      if (!totalCost || totalCost < 0) {
        totalCost = 0;
      }

      return this.investmentCartCostAp + totalCost;
    },
    actionPointsTotal() {
      return 100;
    },
    gameTime() {
      if (!this.room) {
        return 0;
      }

      return this.room.meetingNumber;
    },
    gameTimeTotal() {
      return 12;
    },
    trust() {
      if (!this.room) {
        return 0;
      }

      return this.room.trust;
    },
    collaboration() {
      if (!this.room) {
        return 0;
      }

      return this.room.collaboration;
    },
    healthSecurity() {
      if (!this.room) {
        return 0;
      }

      return this.room.healthSecurity;
    },
    mgEventSuccess() {
      if (!this.room) {
        return 0;
      }

      return this.room.mgEventSuccess;
    },
    isPaused() {
      if (!this.room) {
        return false;
      }

      return this.room.isPaused;
    },
    computedTrust() {
      const base = this.trust || 0;
      const extra = this.topUiExtraTrust || 0;

      return base + extra
    },
    computedCollaboration() {
      const base = this.collaboration || 0;
      const extra = this.topUiExtraCollaboration || 0;

      return base + extra;
    },
    computedHealthSecurity() {
      const base = this.healthSecurity || 0;
      const extra = this.topUiExtraHealthSecurity || 0;

      return base + extra;
    },
    computedMgEventSuccess() {
      const base = this.mgEventSuccess || 0;
      const extra = this.topUiExtraMgEventSuccess || 0;

      return base + extra;
    },
  },
  methods: {
    onPauseModeDisable() {
      this.$gameClient.roomCallMethod('pauseMode:disable');
    },
    onPauseModeEnable() {
      console.info("onPauseModeEnableTopBar")
      this.$emit('pause-pressed');
      this.$gameClient.roomCallMethod('pauseMode:enable');
    },
    onActivateDevMode() {
      this.$emit('activate-dev-mode');
    }
  },
}
</script>
