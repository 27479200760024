<template>
  <div class="microphone-icon" :class="computedClasses">
    <no-drag-image src="@/assets/icons/microphone/mic-off.png" v-if="muted" />
    <no-drag-image src="@/assets/icons/microphone/mic-on.png" />
  </div>
</template>
<script>
import NoDragImage from '@/components/common/NoDragImage.vue'

export default {
  name: 'MicrophoneIcon',
  components: {
    NoDragImage,
  },
  props: {
    withBackground: {
      type: Boolean,
      default: false,
      required: false,
    },
    muted: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  computed: {
    computedClasses() {
      return [
        `microphone-icon-background-${this.withBackground ? 'on' : 'off'}`,
        `microphone-icon-muted-${this.muted ? 'yes' : 'no'}`,
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
  .microphone-icon {
    display: inline-block;
    width: 36px;
    height: 35px;
    object-fit: contain;
    cursor: pointer;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 17px;
    }

    &.microphone-icon-background-on {
      background: url("@/assets/textures/grunge-seamless-20-percent.png"), #415F00;
      box-shadow: inset 1.40747px 2.81495px 4.22242px rgba(255, 255, 255, 0.25), inset 0px -7.03737px 2.81495px #071C27;
      padding: 1px 5px 14px 5px;

      img {
        top: 35%;
        left: 50%;
      }

      &.microphone-icon-muted-yes {
        background: url("@/assets/textures/grunge-seamless-20-percent.png"), #BA5151 !important;
        mix-blend-mode: normal !important;
        box-shadow: inset 0.997938px 1.99588px 2.99381px rgba(255, 255, 255, 0.25), inset 0px -4.98969px 1.99588px #071C27 !important;
      }
    }
  }
</style>
