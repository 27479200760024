export default [
  {
    'pageNumber': 1,
    'header': 'page1.header',
    'body': 'page1.body',
    'media': 'Intro1.jpeg',
  },
  {
    'pageNumber': 2,
    'header': 'page2.header',
    'body': 'page2.body',
    'media': 'Intro2.jpeg',
  },
  {
    'pageNumber': 3,
    'header': 'page3.header',
    'body': 'page3.body',
    'media': 'Intro3.jpeg',
  },
  {
    'pageNumber': 4,
    'header': 'page4.header',
    'body': 'page4.body',
    'media': 'Intro4.jpeg',
  },
  {
    'pageNumber': 5,
    'header': 'page5.header',
    'body': 'page5.body',
    'media': 'Intro5.jpeg',
  },
];