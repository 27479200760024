<template>
  <version-container :attach-corner="true" :display-session-info="false" />
  <div class="lobby-full">
    <div class="lobby-inner">
      <lobby-popup @change-state="onChangeState" :force-character-choice="forceCharacterChoice" :other-selected-character-indexes="otherSelectedCharacterIndexes" />
    </div>

    <player-avatar-circle-live class="player-avatar-circle" v-if="showPlayerUi" />
    <right-player-list-bar-live class="right-player-list-bar" v-if="showPlayerUi" />
    <ui-timer-ready-live class="ui-timer-start-game" :button-text="startGameButtonText" :timer-identifier="timerIdentifier" v-if="lobbyState === 'roleSelection'" />

    <ui-timer
      v-if="chooseCharacterSecondsLeft > 0"
      class="ui-timer-choose-character"
      :seconds-left="chooseCharacterSecondsLeft"
    >{{ $t('lobby.timerPickCharacter') }}</ui-timer>
  </div>
</template>

<script>
import {GameClientEvent} from '@/plugins/GameClient/lib/GameClientEvent'
import CharacterData from '@/data/character-data'
import {setConsoleLogger} from '@/utils/getConsole';
import LobbyPopup from '@/components/Lobby/LobbyPopup.vue'
import PlayerAvatarCircleLive from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircleLive.vue'
import RightPlayerListBarLive from '@/components/player-ui/RightPlayerListBar/RightPlayerListBarLive.vue'
import UiTimerReadyLive from '@/components/player-ui/UiTimer/UiTimerReadyLive.vue'
import { TIMER_IDENTIFIER_ROLE_SELECTION_START_GAME } from '@/enum/TimerIdentifier'
import UiTimer from '@/components/player-ui/UiTimer/UiTimer.vue'
import { mapGetters, mapState } from 'vuex';
import { getTimerDurationSeconds } from '@/utils/timerData'
import VersionContainer from '@/components/common/VersionContainer.vue'

export default {
  name: 'LobbyView',
  components: {
    VersionContainer,
    UiTimerReadyLive,
    RightPlayerListBarLive,
    PlayerAvatarCircleLive,
    LobbyPopup,
    UiTimer,
  },
  data() {
    return {
      lobbyState: null,

      chooseCharacterTimerStartMs: null,
      chooseCharacterTimeSecondsTotal: getTimerDurationSeconds(TIMER_IDENTIFIER_ROLE_SELECTION_START_GAME), // 180,
      chooseCharacterSecondsLeft: null,
      chooseCharacterTimer: null,

      forceCharacterChoice: false,
    }
  },
  computed: {
    ...mapState(['seenIntro', 'room']),
    ...mapGetters(['playerSelf', 'players']),
    showPlayerUi() {
      return this.lobbyState === 'roleSelection';
    },
    timerIdentifier() {
      return TIMER_IDENTIFIER_ROLE_SELECTION_START_GAME;
    },
    startGameButtonText() {
      return this.$t('lobby.buttonStartGame');
    },
    otherSelectedCharacterIndexes() {
      if (!this.players) {
        return [];
      }

      const otherSelectedCharacterIndexes = {};

      const playerCodes = Object.keys(this.players);

      for (const playerCode of playerCodes) {
        const player = this.room.players[playerCode];

        if (player.isSelf) {
          continue;
        }

        otherSelectedCharacterIndexes[playerCode] = player.characterIndex;
      }

      return otherSelectedCharacterIndexes;
    },
  },
  unmounted () {
    this.chooseCharacterTimerStop();
  },
  watch: {
    lobbyState() {
      if (this.lobbyState === 'chooseAvatar') {
        this.chooseCharacterTimerStart();
      } else {
        this.chooseCharacterTimerStop();
      }
    },
  },
  methods: {
    chooseCharacterTimerStop() {
      if (this.chooseCharacterTimer) {
        clearTimeout(this.chooseCharacterTimer);

        this.chooseCharacterTimerStartMs = null;
        this.chooseCharacterSecondsLeft = null;
        this.chooseCharacterTimer = null;
      }
    },
    onChooseCharacterTimeOut() {
      this.chooseCharacterTimerStop();

      this.forceCharacterChoice = true;
    },
    chooseCharacterTimerStart() {
      this.chooseCharacterTimerStop();

      this.chooseCharacterTimerStartMs = (new Date()).getTime();

      this.chooseCharacterTimerTick();
    },
    chooseCharacterTimerTick() {
      if (!this.chooseCharacterTimerStartMs) {
        return null;
      }

      const secondsElapsedSinceStart = ((new Date()).getTime() - this.chooseCharacterTimerStartMs) / 1000;

      const countdownSecondsLeft = Math.max(0, this.chooseCharacterTimeSecondsTotal - secondsElapsedSinceStart);

      this.chooseCharacterSecondsLeft = countdownSecondsLeft;

      if (countdownSecondsLeft === 0) {
        this.onChooseCharacterTimeOut();
      } else {
        this.chooseCharacterTimer = setTimeout(this.chooseCharacterTimerTick, 1000);
      }
    },
    consoleLogger(name, color, backgroundColor, arg) {
      this.consoleText += `\n[${name}] ${arg}`;

      if (this.$refs['consoleLogger']) {
        this.$refs['consoleLogger'].scrollTop = this.$refs['consoleLogger'].scrollHeight;
      }
    },
    onChangeState(e) {
      this.lobbyState = e;

      if (this.lobbyState === 'chooseAvatar') {
        this.chooseCharacterTimerStart();
      } else {
        this.chooseCharacterTimerStartMs = null;
      }
    },
  },
  mounted() {
    // They haven't seen the intro yet - be gone!
    if (!this.seenIntro) {
      this.$router.push('/');
      this.$store.commit('joinCode', this.$route.query.u);
      return;
    }

    this.$store.commit('background', 'game');
    this.$store.commit('backgroundBlingTop', true);
    this.$store.commit('backgroundBlingBottom', true);

    console.log('LOBBY STATE', this.lobbyState);

    setConsoleLogger(this.consoleLogger);

    if (this.lobbyState === 'chooseAvatar') {
      this.chooseCharacterTimerStart();
    }

    //this.$gameClient.eventEmitter.on(GameClientEvent.ROOM_LEAVE, this.onRoomLeave);

    /*function sortObjects(array) {
      return array.sort((a, b) => {
        if (a.flagCount !== b.flagCount) {
          return b.flagCount - a.flagCount;
        }
        if (a.currentSeverity !== b.currentSeverity) {
          return b.currentSeverity - a.currentSeverity;
        }
        if (a.baseLikelihood !== b.baseLikelihood) {
          return b.baseLikelihood - a.baseLikelihood;
        }
        return b.startingSeverity - a.startingSeverity;
      });
    }

    const hazards = [];

    for (let i = 0; i < 1000; i++) {
      hazards.push({
        flagCount: Math.floor(Math.random() * 10),
        currentSeverity: Math.floor(Math.random() * 10),
        baseLikelihood: Math.floor(Math.random() * 10),
        startingSeverity: Math.floor(Math.random() * 10),
      });
    }

    console.log('sorted', sortObjects(hazards));*/
  },
  beforeUnmount() {
    //this.$gameClient.eventEmitter.off(GameClientEvent.ROOM_LEAVE, this.onRoomLeave);
  },
}
</script>


<style lang="scss" scoped>
.lobby-full {
  width: 100%;
  height: 100%;
  background: rgba(#000000, .8);
  padding: 50px 290px 50px 290px;
  position: relative;
}

.lobby-inner {
  width: 100%;
  height: 100%;
}

.ui-timer-start-game, .ui-timer-choose-character {
  position: fixed;
  top: 20px;
  right: 20px;
}
</style>
