<template>
  <div ref="size-wrapper" class="size-wrapper"></div>
  <div ref="wrapper" class="tutorial-highlight-overlay-wrapper" v-if="isOpen && showTutorialLayer">
    <div class="tutorial-overlay-backgrounds">
      <div class="tutorial-overlay-background tutorial-overlay-background-left" :style="boxStyles.left"/>
      <div class="tutorial-overlay-background tutorial-overlay-background-top" :style="boxStyles.top"/>
      <div class="tutorial-overlay-background tutorial-overlay-background-right" :style="boxStyles.right"/>
      <div class="tutorial-overlay-background tutorial-overlay-background-bottom" :style="boxStyles.bottom"/>
    </div>

    <div class="hole-container" :style="holeContainerStyle">
      <div class="hole" :class="holeClass"/>
    </div>

    <div class="tutorial-highlight-popup" :style="popupStyle">
      <div class="tutorial-highlight-anchor-circle" v-if="showAnchorCircle" @click="close"/>
      <div class="tutorial-popup-inner" :style="popupInnerStyle">
        <slot name="popup" :text="text" :style="popupSlotStyle" :passOptions="passOptions"/>
      </div>
    </div>
  </div>
</template>
<script>
import {
  HIGHLIGHT_TYPE_ELEMENT,
  HIGHLIGHT_TYPE_ID,
  HIGHLIGHT_TYPE_SELECTOR,
  POPUP_ANCHOR_AUTO,
  POPUP_ANCHOR_X_LEFT,
  POPUP_ANCHOR_X_MIDDLE,
  POPUP_ANCHOR_X_RIGHT,
  POPUP_ANCHOR_Y_BOTTOM,
  POPUP_ANCHOR_Y_MIDDLE,
  POPUP_ANCHOR_Y_TOP,
  SHAPE_SQUARE
} from '@/plugins/TutorialHighlightOverlay/enums';

export default {
  name: 'TutorialHighlightOverlay',
  props: {
    popupFollow: {
      type: Boolean,
      required: false,
      default: true,
    },
    popupOffsetX: {
      type: Number,
      required: false,
      default: null,
    },
    popupOffsetY: {
      type: Number,
      required: false,
      default: null,
    },
    showAnchorCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTutorialLayer: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  mounted () {
    this.$tutorialHighlightOverlay.addEventListener('pop', (e) => {
      this.pop({
        text: e.text,
        highlight: e.highlight,
        shape: e.shape,
        offset: e.offset,
        highlightType: e.highlightType,
        popupAnchor: e.popupAnchor,
        clickThrough: e.clickThrough,
        autoBoundDistance: e.autoBoundDistance,
        animationLength: e.animationLength,
        passOptions: e.passOptions,
      });
    });

    this.$tutorialHighlightOverlay.addEventListener('reset', (e) => {
      this.reset();
    });

    this.$tutorialHighlightOverlay.addEventListener('close', (e) => {
      this.close();
    });

    this.recalculateStyle();
    this.restartAnimation();

    window.addEventListener('resize', this.onResize);
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize);
  },
  data () {
    return {
      holeStyle: {},
      holeContainerStyle: {},
      popupStyle: {},
      popupInnerStyle: {},
      popupSlotStyle: {},
      boxStyles: {
        left: {},
        right: {},
        top: {},
        bottom: {}
      },
      showAnimation: false,

      text: '',
      shape: SHAPE_SQUARE,
      offset: 0,
      highlight: null,
      highlightType: HIGHLIGHT_TYPE_ID,
      popupAnchor: POPUP_ANCHOR_AUTO,
      clickThrough: false,
      autoBoundDistance: 0.2,
      animationLength: 1000,
      passOptions: {},

      popIterator: 0,
    };
  },
  computed: {
    isOpen () {
      return !!this.highlightElement;
    },
    sizeWrapper () {
      return this.$refs['size-wrapper'] ?? null;
    },
    highlightElement () {
      let highlightElement;

      if (!this.highlight) {
        return null;
      }

      if (this.highlightType === HIGHLIGHT_TYPE_ID) {
        highlightElement = document.getElementById(this.highlight);
      } else if (this.highlightType === HIGHLIGHT_TYPE_SELECTOR) {
        highlightElement = document.querySelector(this.highlight);
      } else if (this.highlightType === HIGHLIGHT_TYPE_ELEMENT) {
        highlightElement = this.highlight;
      }

      if (!highlightElement) {
        console.log('highlight value', this.highlight);

        throw new Error(`Could not find highlight element by type ${this.highlightType}`);
      }

      return highlightElement;
    },
    holeClass () {
      const highlightClasses = [];

      // whether it should be round, square or rounded
      highlightClasses.push(`hole-shape-${this.shape}`);

      if (this.showAnimation) {
        highlightClasses.push('hole-with-animation');
      }

      return highlightClasses;
    },
  },
  methods: {
    onResize () {
      this.recalculateStyle();
    },
    close () {
      this.reset();

      this.$emit('close');
    },
    reset () {
      this.text = null;
      this.highlight = null;
      this.highlightType = null;
      this.shape = null;
      this.offset = null;
      this.popupAnchor = null;
      this.clickThrough = null;
      this.animationLength = null;
      this.autoBoundDistance = null;
      this.passOptions = null;

      this.clearClonedHighlightElement();

      this.popIterator = this.popIterator + 1;
    },
    clearClonedHighlightElement () {
      const container = this.$refs['clickthrough-container'];

      if (!container) {
        return;
      }

      if (!container.firstElementChild) {
        return;
      }

      while (container.firstElementChild) {
        container.firstElementChild.remove();
      }
    },
    pop (options) {
      this.text = options.text;
      this.highlight = options.highlight;
      this.highlightType = options.highlightType || HIGHLIGHT_TYPE_SELECTOR;
      this.shape = options.shape ?? SHAPE_SQUARE;
      this.offset = options.offset ?? 0;
      this.popupAnchor = options.popupAnchor ?? POPUP_ANCHOR_AUTO;
      this.clickThrough = options.clickThrough ?? false;
      this.animationLength = options.animationLength ?? 1000;
      this.autoBoundDistance = options.autoBoundDistance ?? 0.2;
      this.passOptions = options.passOptions || {};

      this.recalculateStyle();
      this.restartAnimation();
    },
    restartAnimation () {
      this.showAnimation = false;

      requestAnimationFrame(() => {
        this.showAnimation = true;
      });
    },
    cloneHighlightElement () {
      const highlightElementBoundingRect = this.highlightElement.getBoundingClientRect();
      const clonedHighlightElement = this.highlightElement.cloneNode(true);  // Deep clone the element

      // Copy all properties from the original to the cloned element
      for (const prop in this.highlightElement) {
        try {
          //clonedHighlightElement[prop] = this.highlightElement[prop];
        } catch (e) {
          // Some properties might be read-only or might throw errors when being set.
          // We simply catch and ignore these errors.
        }
      }

      const clickThroughContainer = this.$refs['clickthrough-container'];

      clickThroughContainer.style.position = 'absolute';
      clickThroughContainer.style.left = highlightElementBoundingRect.left + 'px';
      clickThroughContainer.style.top = highlightElementBoundingRect.top + 'px';
      clickThroughContainer.style.width = highlightElementBoundingRect.width + 'px';
      clickThroughContainer.style.height = highlightElementBoundingRect.height + 'px';

      this.highlightElement.style.visibility = 'hidden';
      clonedHighlightElement.style.visibility = 'visible';
      clickThroughContainer.appendChild(clonedHighlightElement);

      return clonedHighlightElement;
    },
    getRelativeBoundingRectFromElements (element, container) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      return this.getRelativeBoundingRect(elementRect, containerRect);
    },
    getRelativeBoundingRect (elementRect, containerRect) {
      return {
        top: elementRect.top - containerRect.top,
        left: elementRect.left - containerRect.left,
        bottom: elementRect.bottom - containerRect.top,
        right: elementRect.right - containerRect.left,
        width: elementRect.width,
        height: elementRect.height
      };
    },
    getBoundingRectWithScale (rect, scale) {
      return {
        top: rect.top * scale,
        left: rect.left * scale,
        bottom: rect.bottom * scale,
        right: rect.right * scale,
        width: rect.width * scale,
        height: rect.height * scale,
      };
    },
    getRelativeScale (element) {
      let scaleX = 1;
      let scaleY = 1;
      let currentElement = element;

      // Traverse up the DOM tree and accumulate the scale factors
      while (currentElement) {
        const computedStyle = window.getComputedStyle(currentElement);

        // Extract the scale values from the transform property
        const transform = computedStyle.transform;

        if (transform && transform !== 'none') {
          // Even though you use scale(0.85) it converts it into a matrix
          const matrixMatch = transform.match(/matrix\(([^)]+)\)/);
          const scaleMatch = transform.match(/scale\(([^)]+)\)/);

          if (matrixMatch) {
            const values = matrixMatch[1].split(',').map(parseFloat);
            scaleX *= values[0]; // matrix(a, b, c, d, tx, ty), where scaleX = a
            scaleY *= values[3]; // scaleY = d
          } else if (scaleMatch) {
            const scaleValues = scaleMatch[1].split(',').map(parseFloat);

            if (scaleValues.length === 1) {
              // If only one value is provided, it applies uniformly to both X and Y
              scaleX *= scaleValues[0];
              scaleY *= scaleValues[0];
            } else if (scaleValues.length === 2) {
              // If two values are provided, they apply to X and Y respectively
              scaleX *= scaleValues[0];
              scaleY *= scaleValues[1];
            }
          }
        }

        // Move to the parent element
        currentElement = currentElement.parentElement;
      }

      // If you want to return a single scale value, assuming uniform scaling, you can return the average scale
      return (scaleX + scaleY) / 2;
    },
    recalculateStyle () {
      const popupScale = 1; // this.getAspectRatioScale() * 1.25;

      const holeContainerStyle = {
        width: '0px',
        height: '0px',
      };

      const popupStyle = {
        position: 'absolute',
        left: '50%',
        right: '50%',
      };

      const popupSlotStyle = {
        transform: `scale(${popupScale})`,
      };

      const popupInnerStyle = {};

      if (this.highlightElement && this.sizeWrapper) {
        const relativeScale = this.getRelativeScale(this.sizeWrapper);

        const sizeWrapperBoundingRect = this.sizeWrapper.getBoundingClientRect();
        const highlightElementBoundingRect = this.highlightElement.getBoundingClientRect();

        const relativeBoundingRect = this.getRelativeBoundingRect(highlightElementBoundingRect, sizeWrapperBoundingRect);
        const relativeBoundingRectScale = this.getBoundingRectWithScale(relativeBoundingRect, 1 + (1 - relativeScale));

        if (this.clickThrough) {
          //this.cloneHighlightElement();
        } else {
          //this.clearClonedHighlightElement();
        }

        const offset = this.offset || {
          x: 0,
          y: 0
        };

        const offsetNormalized = {
          x: (typeof (offset) ? offset.x : offset),
          y: (typeof (offset) ? offset.y : offset),
        };

        if (offsetNormalized.y === 100) {
          offsetNormalized.y = 200;
        }

        const offsetX = sizeWrapperBoundingRect.x;
        const offsetY = sizeWrapperBoundingRect.y;

        const offsetX2 = offsetX * 2;
        const offsetY2 = offsetY * 2;

        const screenSize = {
          width: Math.round(sizeWrapperBoundingRect.width),
          height: Math.round(sizeWrapperBoundingRect.height),
        };

        const screenSizeRelative = {
          width: screenSize.width / relativeScale,
          height: screenSize.height / relativeScale,
        };

        const highlightElementBoundingRectRound = {
          left: relativeBoundingRect.left, // Math.round(highlightElementBoundingRect.left) - offsetX,
          top: relativeBoundingRect.top, // Math.round(highlightElementBoundingRect.top) - offsetY,
          width: relativeBoundingRect.width, // Math.round(highlightElementBoundingRect.width),
          height: relativeBoundingRect.height, // Math.round(highlightElementBoundingRect.height),
        };

        highlightElementBoundingRectRound.right = highlightElementBoundingRectRound.left + highlightElementBoundingRectRound.width;
        highlightElementBoundingRectRound.bottom = highlightElementBoundingRectRound.top + highlightElementBoundingRectRound.height;

        const highlightElementBoundingRectRoundScaled = {
          left: relativeBoundingRect.left / relativeScale,
          top: relativeBoundingRect.top / relativeScale,
          width: relativeBoundingRect.width / relativeScale,
          height: relativeBoundingRect.height / relativeScale,
        };

        highlightElementBoundingRectRoundScaled.right = highlightElementBoundingRectRoundScaled.left + highlightElementBoundingRectRoundScaled.width;
        highlightElementBoundingRectRoundScaled.bottom = highlightElementBoundingRectRoundScaled.top + highlightElementBoundingRectRoundScaled.height;

        let startTime;
        let runningTimeMs = this.animationLength ?? 1000;

        this.boxStyles = {
          left: {
            top: 0,
            height: '100%',
            left: 0,
            //width: highlightElementBoundingRectRound.left,
          },
          right: {
            top: 0,
            height: '100%',
            right: 0,
            //width: screenWidth - highlightElementBoundingRectRound.right + 'px',
          },
          top: {
            top: 0,
            //left: highlightElementBoundingRectRound.left + 'px',
            //width: highlightElementBoundingRectRound.width + 'px',
            //height: highlightElementBoundingRectRound.top + 'px',
          },
          bottom: {
            bottom: 0,
            //left: highlightElementBoundingRectRound.left + 'px',
            //width: highlightElementBoundingRectRound.width + 'px',
            //height: screenHeight - highlightElementBoundingRectRound.bottom + 'px',
          },
        };

        const baseBoxStyles = {
          left: {
            width: highlightElementBoundingRectRoundScaled.left,
          },
          right: {
            width: screenSizeRelative.width - highlightElementBoundingRectRoundScaled.right,
          },
          top: {
            left: highlightElementBoundingRectRoundScaled.left,
            width: highlightElementBoundingRectRoundScaled.width,
            height: highlightElementBoundingRectRoundScaled.top,
          },
          bottom: {
            left: highlightElementBoundingRectRoundScaled.left,
            width: highlightElementBoundingRectRoundScaled.width,
            height: screenSizeRelative.height - highlightElementBoundingRectRoundScaled.bottom,
          },
        };

        const ease = (t) => {
          return t * t;
        };

        const animateProgress = (progress) => {
          let widthFactor, heightFactor;

          if (progress <= 0.3) {
            widthFactor = 1 + (0.25 * progress / 0.3);
            heightFactor = 1 - (0.25 * progress / 0.3);
          } else if (progress <= 0.4) {
            widthFactor = 1.25 - (0.5 * (progress - 0.3) / 0.1);
            heightFactor = 0.75 + (0.5 * (progress - 0.3) / 0.1);
          } else if (progress <= 0.5) {
            widthFactor = 0.75 + (0.4 * (progress - 0.4) / 0.1);
            heightFactor = 1.25 - (0.4 * (progress - 0.4) / 0.1);
          } else if (progress <= 0.65) {
            widthFactor = 1.15 - (0.2 * (progress - 0.5) / 0.15);
            heightFactor = 0.85 + (0.2 * (progress - 0.5) / 0.15);
          } else if (progress <= 0.75) {
            widthFactor = 0.95 + (0.1 * (progress - 0.65) / 0.1);
            heightFactor = 1.05 - (0.1 * (progress - 0.65) / 0.1);
          } else {
            widthFactor = 1.05 - (0.05 * (progress - 0.75) / 0.25);
            heightFactor = 0.95 + (0.05 * (progress - 0.75) / 0.25);
          }

          const newWidth = highlightElementBoundingRectRoundScaled.width * widthFactor;
          const newHeight = highlightElementBoundingRectRoundScaled.height * heightFactor;

          const diffWidth = newWidth - highlightElementBoundingRectRoundScaled.width;
          const diffHeight = newHeight - highlightElementBoundingRectRoundScaled.height;

          const computedBoxStyles = {
            left: {
              width: baseBoxStyles.left.width - (diffWidth / 2),
            },
            right: {
              width: baseBoxStyles.right.width - (diffWidth / 2),
            },
            top: {
              left: baseBoxStyles.top.left - (diffWidth / 2),
              width: newWidth,
              height: baseBoxStyles.top.height - (diffHeight / 2),
            },
            bottom: {
              left: baseBoxStyles.bottom.left - (diffWidth / 2),
              width: newWidth,
              height: baseBoxStyles.bottom.height - (diffHeight / 2),
            }
          };

          for (const box in computedBoxStyles) {
            for (const cssProperty in computedBoxStyles[box]) {
              this.boxStyles[box][cssProperty] = computedBoxStyles[box][cssProperty] + 'px';
            }
          }

          this.holeContainerStyle.top = (highlightElementBoundingRectRoundScaled.top - (diffHeight / 2)) + 'px';
          this.holeContainerStyle.left = (highlightElementBoundingRectRoundScaled.left - (diffWidth / 2)) + 'px';
          this.holeContainerStyle.height = `${newHeight}px`;
          this.holeContainerStyle.width = `${newWidth}px`;

          if (progress < 1) {
            requestAnimationFrame(animateTime);
          }
        };

        const animateTime = (currentTime) => {
          if (!startTime) {
            startTime = currentTime;
          }

          const elapsed = currentTime - startTime;
          const rawProgress = Math.max(Math.min(elapsed / runningTimeMs, 1), 0); // Normalize to a 0-1 range over 1000ms
          const progress = ease(rawProgress); // Apply easing

          animateProgress(progress);
        };

        if (this.animationLength <= 0) {
          requestAnimationFrame(() => {
            // Instantly go to end
            animateProgress(1);
          });
        } else {
          requestAnimationFrame(animateTime);
        }

        let top = (highlightElementBoundingRectRoundScaled.top);
        let left = (highlightElementBoundingRectRoundScaled.left);
        let width = (highlightElementBoundingRectRoundScaled.width);
        let height = (highlightElementBoundingRectRoundScaled.height);

        const width2 = width / 2;
        const height2 = height / 2;

        if (this.popupFollow) {
          let popupAnchor = this.popupAnchor;

          if (popupAnchor === POPUP_ANCHOR_AUTO) {
            const bodyBoundingRect = document.body.getBoundingClientRect();

            const boundingBox = {
              min: {
                x: left,
                y: top,
              },
              max: {
                x: left + width,
                y: top + height,
              },
              center: {
                x: left + width2,
                y: top + height2,
              }
            };

            const topFraction = boundingBox.center.y / bodyBoundingRect.height;
            const bottomFraction = 1 - topFraction;

            const leftFraction = boundingBox.center.x / bodyBoundingRect.width;
            const rightFraction = 1 - leftFraction;

            const boundDistance = this.autoBoundDistance;

            // Default to MiddleX, TopY anchor
            popupAnchor = {
              x: POPUP_ANCHOR_X_MIDDLE,
              y: POPUP_ANCHOR_Y_MIDDLE
            };

            const topFractionCrossing = topFraction <= boundDistance;
            const bottomFractionCrossing = bottomFraction <= boundDistance;
            const leftFractionCrossing = leftFraction <= boundDistance;
            const rightFractionCrossing = rightFraction <= boundDistance;

            if (topFractionCrossing && bottomFractionCrossing) {
              popupAnchor.y = POPUP_ANCHOR_Y_MIDDLE;
            } else if (topFractionCrossing) {
              popupAnchor.y = POPUP_ANCHOR_Y_TOP;
            } else if (bottomFractionCrossing) {
              popupAnchor.y = POPUP_ANCHOR_Y_BOTTOM;
            }

            if (leftFractionCrossing && rightFractionCrossing) {
              popupAnchor.y = POPUP_ANCHOR_Y_MIDDLE;
            } else if (leftFractionCrossing) {
              popupAnchor.x = POPUP_ANCHOR_X_LEFT;
            } else if (rightFractionCrossing) {
              popupAnchor.x = POPUP_ANCHOR_X_RIGHT;
            }

            if (popupAnchor.x === POPUP_ANCHOR_X_MIDDLE && popupAnchor.y === POPUP_ANCHOR_Y_MIDDLE) {
              popupAnchor.y = POPUP_ANCHOR_Y_TOP; // Can't both be middle as it then overlays the element we're referring to
            }
          }

          const popupAnchorX = typeof (popupAnchor) === 'object' ? popupAnchor.x : popupAnchor;
          const popupAnchorY = typeof (popupAnchor) === 'object' ? popupAnchor.y : popupAnchor;

          let transform = {
            x: '-50%',
            y: '-50%',
          };

          if (popupAnchorX === POPUP_ANCHOR_X_LEFT) {
            transform.x = `calc(${width2}px + ${this.popupOffsetX + offsetNormalized.x}px)`;
          } else if (popupAnchorX === POPUP_ANCHOR_X_MIDDLE) {
            transform.y = `calc(-50% + ${offsetNormalized.x}px)`;
          } else if (popupAnchorX === POPUP_ANCHOR_X_RIGHT) {
            transform.x = `calc(-100% - ${width2}px - ${this.popupOffsetX + offsetNormalized.x}px)`;
          }

          if (popupAnchorY === POPUP_ANCHOR_Y_TOP) {
            transform.y = `calc(${height2}px + ${this.popupOffsetY + offsetNormalized.y}px)`;
          } else if (popupAnchorY === POPUP_ANCHOR_Y_MIDDLE) {
            transform.y = `calc(-50% + ${offsetNormalized.y}px)`;
          } else if (popupAnchorY === POPUP_ANCHOR_Y_BOTTOM) {
            transform.y = `calc(-100% - ${height2}px - ${this.popupOffsetY + offsetNormalized.y}px)`;
          }

          /*if (popupAnchor === POPUP_ANCHOR_BOTTOM) {
            popupInnerStyle.transform = `translate(-50%, calc(${height2}px + ${this.popupOffsetY}px))`
          } else if (popupAnchor === POPUP_ANCHOR_TOP) {
            popupInnerStyle.transform = `translate(-50%, calc(-100% - ${height2}px - ${this.popupOffsetY}px))`
          } else if (popupAnchor === POPUP_ANCHOR_LEFT) {
            popupInnerStyle.transform = `translate(calc(-100% - ${width2}px - ${this.popupOffsetX}px), -50%)`
          } else if (popupAnchor === POPUP_ANCHOR_RIGHT) {
            popupInnerStyle.transform = `translate(calc(${width2}px + ${this.popupOffsetX}px), -50%)`
          }*/

          popupInnerStyle.transform = `translate(${transform.x}, ${transform.y})`;
          //popupStyle.transform = ``;

          const popupStyleCalc = {
            top: top + (height2),
            left: left + (width2),
          };

          popupStyle.top = `${popupStyleCalc.top}px`;
          popupStyle.left = `${popupStyleCalc.left}px`;
        }
      }

      this.popupStyle = popupStyle;
      this.popupInnerStyle = popupInnerStyle;
      this.popupSlotStyle = popupSlotStyle;
    },
  },
  watch: {
    isOpen () {
      if (this.isOpen) {
        // We want to recalculate style now that we know it's open
        this.recalculateStyle();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tutorial-highlight-overlay-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: hidden;
  pointer-events: none;

  // background-color: rgba(0,0,0,0.7);

  .hole-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .hole {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;

    &.hole-with-animation {
      //-webkit-animation: jello-horizontal 0.9s 1 both;
      //animation: jello-horizontal 0.9s 1 both;
    }

    /**
     * SHAPES
     */
    &.hole-shape-square {
      border-radius: 0 !important;
    }

    &.hole-shape-slightly-rounded {
      border-radius: 8px !important;
    }

    &.hole-shape-rounded {
      border-radius: 12px !important;
    }

    &.hole-shape-circle {
      border-radius: 50% !important;
    }
  }
}

.tutorial-overlay-backgrounds {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tutorial-highlight-popup {
  pointer-events: all;
}

.tutorial-overlay-background {
  position: absolute;
  pointer-events: all;
  background: rgba(#000000, .7);

  &.tutorial-overlay-background-left {
    //background: rgba(#ffff00, .7);
    transform-origin: left center;
    //animation: jello-horizontal-edge-left 900ms 1 both;
  }

  &.tutorial-overlay-background-right {
    //background: rgba(#ff0000, .7);
    transform-origin: right center;
    //animation: jello-horizontal-edge-right 900ms 1 both;
  }

  &.tutorial-overlay-background-top {
    //background: rgba(#00ff00, .7);
    transform-origin: center top;
    //animation: jello-vertical-edge-top 900ms 1 both;
  }

  &.tutorial-overlay-background-bottom {
    //background: rgba(#0000ff, .7);
    transform-origin: center bottom;
    //animation: jello-vertical-edge-bottom 900ms 1 both;
  }
}

.tutorial-highlight-anchor-circle {
  z-index: 99999999999999999;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background: yellow;
  border-radius: 100%;
  border: 1px solid black;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 0, 0.9);
  cursor: pointer;
}

.size-wrapper {
  pointer-events: none !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: yellow;
  z-index: -1;
}

.tutorial-popup-inner {
  width: fit-content;
  height: fit-content;
}

@import "animations.scss";
</style>
