<template>
  <button class="app-button" :class="computedClasses">
    <div class="app-button-cost" v-if="cost > 0">
      <div class="app-button-cost-inner">
        {{ cost }}
      </div>
    </div>

    <div class="app-button-outer">
      <div class="app-button-inner">
        <div class="app-button-slot-outer d-flex align-items-center">
          <div class="app-button-slot-inner flex-grow-1 d-flex justify-content-center">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import {SIZE_XS, SIZE_SM, SIZE_MD, SIZE_LG, SIZE_XL, SIZE_FIT_CONTENT, SIZE_FULL} from './enums';
import {TYPE_BOX_ROUNDED, TYPE_BOX, TYPE_CIRCLE} from './enums';

export default {
  name: 'AppButton',
  props: {
    color: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cost: {
      type: Number,
      required: false,
      default: null,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: null,
    },
    textSize: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: TYPE_BOX_ROUNDED,
    },
    multiLine: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    computedClasses() {
      const defaultSize = this.defaultSize;

      const classes = [
        `app-button-color-${this.color}`,
        `app-button-state-${this.state || 'none'}`,
        `app-button-type-${this.type}`,
        `app-button-width-${this.width || defaultSize}`,
        `app-button-height-${this.height || defaultSize}`,
        `app-button-text-size-${this.textSize || defaultSize}`,
        `app-button-line-${this.multiLine ? 'multi' : 'single'}`,
      ];

      if (this.disabled) {
        classes.push('app-button-disabled');
      }

      return classes;
    },
    defaultSize() {
      if (this.width && this.height) {
        const sizes = [
          SIZE_XS,
          SIZE_SM,
          SIZE_MD,
          SIZE_LG,
          SIZE_XL,
          SIZE_FIT_CONTENT,
          SIZE_FULL,
        ];

        const widthPosition = sizes.indexOf(this.width);
        const heightPosition = sizes.indexOf(this.height);

        return sizes[Math.min(widthPosition, heightPosition)];
      }

      // Set default size to whatever width is
      if (this.width) {
        return this.width;
      }

      // Set default size to whatever height is
      if (this.height) {
        return this.height;
      }

      // Set default size to whatever textSize is
      if (this.textSize) {
        return this.textSize;
      }

      // Give up and return MD
      return 'md';
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./variables.scss";

@mixin appButtonColors($name, $borderColor, $backgroundColor) {
  &.app-button-color-#{$name} {
    background: linear-gradient(130deg, #FFFFFF, $borderColor); // BORDER

    .app-button-inner {
      background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), $texture, darken($backgroundColor, 10%);
    }

    &.app-button-state-hover, &:hover {
      background: linear-gradient(130deg, #FFFFFF, $borderColor); // BORDER

      .app-button-inner {
        background: $texture, $backgroundColor;
        //background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), $texture, $backgroundColor;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56) !important;
      }
    }

    &.app-button-state-active, &:active {
      background: linear-gradient(130deg, rgba(#FFFFFF, 50%), rgba($borderColor, 50%)); // BORDER

      .app-button-inner {
        background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), $texture, darken($backgroundColor, 20%);
        box-shadow: inset -10px -10px 10px rgba(0, 0, 0, 0.55), inset 6px 7px 6px 1px rgba(0, 0, 0, 0.72) !important;
      }
    }

    .app-button-cost {
      background: linear-gradient(130deg, #FFFFFF, $borderColor); // BORDER

      .app-button-cost-inner {
        background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), $texture, darken($backgroundColor, 10%);
      }
    }
  }
}

@mixin appButtonWidth($name, $width, $padding) {
  &.app-button-width-#{$name} {
    width: $width;

    .app-button-inner {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
  }
}

@mixin appButtonHeight($name, $height, $padding) {
  &.app-button-height-#{$name} {
    min-height: $height;

    .app-button-outer {
      min-height: $height;
    }

    .app-button-inner {
      min-height: $height;
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@mixin appButtonFontSize($name, $fontSize, $padding) {
  &.app-button-text-size-#{$name} {
    font-size: $fontSize;

    .app-button-inner {
      padding: $padding;
    }
  }
}

.app-button {
  //box-shadow: inset 5px 5px 5px rgba(255, 255, 255, 0.5);
  border: 0;
  outline: 0;
  padding: 2px; /* Border width */

  color: #FFFFFF;
  text-transform: uppercase;

  cursor: pointer;
  position: relative;

  .app-button-outer {
    pointer-events: none;
  }

  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  //overflow: hidden;
  line-height: 100% !important;

  .app-button-slot-outer {
    width: 100%;
  }

  .app-button-slot-inner {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
  }

  .app-button-outer,
  .app-button-inner,
  .app-button-slot-outer {
    width: 100%;
    height: 100%;
  }

  .app-button-outer {
    overflow: hidden;
    height: 100%;
  }

  .app-button-inner {
    font-weight: 700;
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.8), 0px -2px 9px 1px rgba(0, 0, 0, 0.5), inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56);
  }

  // Colors
  &.app-button-state-hover, &:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);

    .app-button-outer {
      overflow-x: visible;
      //box-shadow: inset 5px 5px 5px rgba(255, 255, 255, 0.5);
    }

    .app-button-inner {
      box-shadow: inset -10px -10px 10px rgba(0, 0, 0, 0.55), inset 6px 7px 6px 1px rgba(0, 0, 0, 0.72) !important;
    }
  }

  &.app-button-state-active, &:active {
    box-shadow: none !important;

    .app-button-outer {
      overflow: hidden !important;
    }
  }

  //@include appButtonColors('blue', #184E5A);
  //@include appButtonColors('green', #01A44F);
  //@include appButtonColors('red', #6d9444);
  //@include appButtonColors('orange', #815D00);

  //@include appButtonColors('blue', #184E5A, #466481);
  //@include appButtonColors('green', #01A44F, #3C8D63);
  //@include appButtonColors('red', #6d9444, #7A2524);
  //@include appButtonColors('orange', #815D00, #CC933F);

  @include appButtonColors('blue', #184E5A, #7784C5);
  @include appButtonColors('green', #01A44F, #25CC75);
  @include appButtonColors('red', #983c3c, #d25b5b);
  @include appButtonColors('orange', #815D00, #CF9642);

  /*&.app-button-color-blue {
    background: linear-gradient(130deg, #FFFFFF, #184E5A); // BORDER

    .app-button-inner {
      background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), radial-gradient(352.78% 203.85% at 50% 50%, rgba(24, 78, 90, 0.7) 0%, rgba(15, 48, 56, 0.7) 38.02%, rgba(0, 0, 0, 0.7) 100%), $texture, #001AFF;
    }

    &.app-button-state-hover, &:hover {
      background: linear-gradient(130deg, #FFFFFF, #184E5A); // BORDER

      .app-button-inner {
        background: radial-gradient(352.78% 203.85% at 50% 50%, rgba(24, 78, 90, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), $texture, #5D69AB;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56) !important;
      }
    }

    &.app-button-state-active, &:active {
      background: linear-gradient(130deg, rgba(#FFFFFF, 50%), rgba(#184E5A, 50%)); // BORDER

      .app-button-inner {
        background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), radial-gradient(352.78% 203.85% at 50% 50%, rgba(24, 78, 90, 0.2) 0%, rgba(15, 48, 56, 0.2) 38.02%, rgba(0, 0, 0, 0.2) 100%), $texture, #001AFF;
        box-shadow: inset -10px -10px 10px rgba(0, 0, 0, 0.55), inset 6px 7px 6px 1px rgba(0, 0, 0, 0.72) !important;
      }
    }
  }

  &.app-button-color-green {
    background: linear-gradient(130deg, #FFFFFF, #01A44F); // BORDER

    .app-button-inner {
      background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), radial-gradient(352.78% 203.85% at 50% 50%, rgba(1, 164, 79, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), $texture, #000000;
    }

    &.app-button-state-hover, &:hover {
      background: linear-gradient(130deg, #FFFFFF, #FFFFFF); // BORDER

      .app-button-inner {
        background: radial-gradient(33.99% 48.4% at 50% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 255, 122, 0.7), rgba(0, 255, 122, 0.7)), $texture, #000000;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 102, 17, 0.56) !important;
      }
    }

    &.app-button-state-active, &:active {
      background: linear-gradient(130deg, rgba(#FFFFFF, 50%), rgba(#01A44F, 50%)); // BORDER

      .app-button-inner {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), radial-gradient(352.78% 203.85% at 50% 50%, rgba(0, 67, 32, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), $texture, #000000;
        box-shadow: inset -10px -10px 10px rgba(0, 0, 0, 0.55), inset 6px 7px 6px 1px rgba(0, 0, 0, 0.72) !important;
      }
    }
  }

  &.app-button-color-red {
    background: linear-gradient(130deg, #FFFFFF, #6d9444); // BORDER

    .app-button-inner {
      background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), radial-gradient(352.78% 203.85% at 50% 50%, rgba(164, 1, 1, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), $texture, #000000;
    }

    &.app-button-state-hover, &:hover {
      background: linear-gradient(130deg, #FFFFFF, #e6ebed); // BORDER

      .app-button-inner {
        background: linear-gradient(198.42deg, rgba(255, 255, 255, 0.2) -8.54%, rgba(0, 0, 0, 0.126) 30.9%, rgba(255, 255, 255, 0.163451) 95.81%, rgba(255, 255, 255, 0.2) 132.39%), radial-gradient(352.78% 203.85% at 50% 50%, rgba(164, 1, 1, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), $texture, #FF0000;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56) !important;
      }
    }

    &.app-button-state-active, &:active {
      background: linear-gradient(130deg, #464646, #6D4545);  // BORDER

      .app-button-inner {
        background: radial-gradient(352.78% 203.85% at 50% 50%, rgba(164, 1, 1, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), $texture, #000000;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56) !important;
      }
    }
  }

  &.app-button-color-orange {
    background: linear-gradient(130deg, #FFFFFF, #815D00); // BORDER

    .app-button-inner {
      background: $texture, #C97800;
    }

    &.app-button-state-hover, &:hover {
      background: linear-gradient(130deg, #FFFFFF, #815D00); // BORDER

      .app-button-inner {
        background: $texture, #C97800;
        box-shadow: inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56) !important;
      }
    }

    &.app-button-state-active, &:active {
      background: linear-gradient(130deg, #858483, #30150D); // BORDER

      .app-button-inner {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), radial-gradient(352.78% 203.85% at 50% 50%, rgba(67, 40, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), $texture, #000000;
        box-shadow: inset -10px -10px 10px rgba(0, 0, 0, 0.55), inset 6px 7px 6px 1px rgba(0, 0, 0, 0.72) !important;
      }
    }
  }*/

  &.app-button-state-disabled, &:disabled, &.app-button-disabled {
    background: linear-gradient(130deg, #333333, #9B9B9B) !important; // BORDER
    box-shadow: none !important;
    cursor: not-allowed !important;

    .app-button-outer {
      box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.69) !important;
    }

    .app-button-inner {
      background: $texture, #000000 !important;

      box-shadow: inset 0px -8px 38px 1px #071C27 !important;
    }
  }

  // Types
  &.app-button-type-box-rounded {
    // Default
    border-radius: 5px;

    .app-button-outer,
    .app-button-inner {
      border-radius: 5px;
    }
  }

  &.app-button-type-box {
    border-radius: 1px;

    .app-button-outer,
    .app-button-inner {
      border-radius: 1px;
    }
  }

  &.app-button-type-circle {
    aspect-ratio: 1;
    border-radius: 50%;

    .app-button-outer,
    .app-button-inner {
      position: relative;
      border-radius: 50%;
    }
  }

  // Widths
  @include appButtonWidth('xs', $widthXs, $widthPaddingXs);
  @include appButtonWidth('sm', $widthSm, $widthPaddingSm);
  @include appButtonWidth('md', $widthMd, $widthPaddingMd);
  @include appButtonWidth('lg', $widthLg, $widthPaddingLg);
  @include appButtonWidth('xl', $widthXl, $widthPaddingXl);

  &.app-button-width-fit-content {
    min-width: $widthFitContent;
  }

  &.app-button-width-full {
    min-width: $widthFull;
  }

  &.app-button-width-icon-lg {
    min-width: 60px;

    .app-button-outer {
      width: 60px;
    }

    img {
      width: 100%;
    }
  }

  // Heights
  @include appButtonHeight('xs', $heightXs, $heightPaddingXs);
  @include appButtonHeight('sm', $heightSm, $heightPaddingSm);
  @include appButtonHeight('md', $heightMd, $heightPaddingMd);
  @include appButtonHeight('lg', $heightLg, $heightPaddingLg);
  @include appButtonHeight('xl', $heightXl, $heightPaddingXl);

  &.app-button-height-fit-content {
    min-height: $heightFitContent;
  }

  &.app-button-height-full {
    min-height: $heightFull;
  }

  &.app-button-height-icon-lg {
    min-height: 60px;

    .app-button-outer {
      height: 60px;
    }

    img {
      height: 100%;
    }
  }

  // Text sizes
  @include appButtonFontSize('xs', $fontSizeXs, 3px 6px);
  @include appButtonFontSize('sm', $fontSizeSm, 4px 8px);
  @include appButtonFontSize('md', $fontSizeMd, 6px 12px);
  @include appButtonFontSize('lg', $fontSizeLg, 8px 16px);
  @include appButtonFontSize('xl', $fontSizeXl, 10px 20px);
  @include appButtonFontSize('xxl', $fontSizeXxl, 10px 20px);
  @include appButtonFontSize('xxxl', $fontSizeXxxl, 10px 20px);

  &.app-button-text-size-xxl, &.app-button-text-size-xxxl {
    text-shadow: 0px 6.46664px 6.46664px rgba(0, 0, 0, 0.5);
  }

  .app-button-cost {
    position: absolute;
    top: -15px;
    right: -15px;
    padding: 3px;
    width: 30px;
    height: 30px;

    border-radius: 50%;

    .app-button-cost-inner {
      width: 30px - (3px * 2);
      height: 30px - (3px * 2);

      line-height: 30px - (3px * 2);

      border-radius: 50%;
    }
  }
}

</style>
