import ApiClientCore from './core';

import PlayerMixins from './mixins/player';
import ToolboxMixins from './mixins/toolbox';
import JeeMixins from './mixins/jee';
import RoleMixins from './mixins/role';
import AreaOfExpertiseMixins from './mixins/areaOfExpertise'
import LeaderboardMixins from './mixins/leaderboard'
import FacilitatorMixins from './mixins/facilitator'
import MissionMixins from './mixins/mission'
import BackendMixins from './mixins/backend'

export default class ApiClient extends ApiClientCore {
  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return {
      player: PlayerMixins,
      toolbox: ToolboxMixins,
      jee: JeeMixins,
      role: RoleMixins,
      areaOfExpertise: AreaOfExpertiseMixins,
      leaderboard: LeaderboardMixins,
      facilitator: FacilitatorMixins,
      mission: MissionMixins,
      backend: BackendMixins,
    };
  }
}
