<template>
  <div class="stat-bar" :class="computedClasses">
    <div
      class="stat-bar-progress"
      :style="{ width: calculatedWidthPercentage + '%' }"
    />
    <div class="stat-bar-label">
      <span v-show="showLabel">{{ percentLabel }}%</span>
    </div>
    <div class="stat-bar-separators d-flex align-items-stretch justify-content-around" v-if="separatorCount > 0">
      <div v-for="n in separatorCount" class="stat-bar-separator" />
    </div>
  </div>
  <!--
  <transition name="fade">
    <div class="stat-bar-value-change" :class="'stat-bar-value-change-' + (statBarValueChangeShow ? 'show' : 'hide')">
      {{ statBarValueChange }}
    </div>
  </transition>
  -->
</template>

<script>

export default {
  name: 'StatBar',
  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    current: {
      type: Number,
      required: true,
    },
    reverse: {
      type: Boolean,
      required: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    segmentCount: {
      type: Number,
      required: false,
      default: null,
    },
    watchValueChanges: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statBarValueChange: 0,
      statBarValueChangeShow: false,
      statBarValueChangeTimeout: null,
    };
  },
  watch: {
    current(newValue, oldValue) {
      if (this.watchValueChanges) {
        //this.onValueChange(newValue - oldValue, newValue, oldValue);
      }
    }
  },
  methods: {
    onValueChange(valueChange, newValue, oldValue) {
      let valueChangePercent = valueChange / 10;

      this.statBarValueChange = (valueChangePercent < 0 ? valueChangePercent : ('+' + valueChangePercent)) + '%';
      this.statBarValueChangeShow = true;

      if (this.statBarValueChangeTimeout) {
        clearTimeout(this.statBarValueChangeTimeout);

        this.statBarValueChangeTimeout = null;
      }

      this.statBarValueChangeTimeout = setTimeout(() => {
        this.statBarValueChangeShow = false;

        if (this.statBarValueChangeTimeout) {
          clearTimeout(this.statBarValueChangeTimeout);

          this.statBarValueChangeTimeout = null;
        }
      }, 2500);
      console.log('onValueChange', { valueChange, newValue, oldValue });
    },
  },
  computed: {
    separatorCount() {
      if (!this.segmentCount) {
        return 0;
      }

      if (this.segmentCount <= 1) {
        return 0;
      }

      const separatorCount = this.segmentCount - 1;

      if (separatorCount <= 0) {
        return 0;
      }

      return separatorCount;
    },
    computedClasses() {
      return [
        `stat-bar-classification-${this.classification}`,
        `stat-bar-reverse-${this.reverse ? 'on' : 'off'}`
      ]
    },
    calculatedWidthPercentage () {
      return this.percentage
    },
    percentage() {
      const currentComputed = Math.max(this.min, Math.min(this.max, this.current));

      return ((currentComputed - this.min) / (this.max - this.min)) * 100
    },
    percentLabel() {
      return Math.round(this.percentage);
    },
    classification() {
      const percentage = this.reverse ? 100 - this.percentage : this.percentage;

      if (percentage >= 80) {
        return 'green';
      } else if (percentage >= 40) {
        return 'yellow';
      }

      return 'red'
    }
  }
}

</script>

<style scoped lang="scss">
.stat-bar {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  background: url("@/assets/textures/wall-seamless-20-percent.png"), linear-gradient(180deg, #00FEE9 10.23%, #000000 100%);
  box-shadow: inset 0px 7px 2px rgba(4, 19, 21, 0.25), inset 0px 3px 2px rgba(0, 0, 0, 0.51);
  border-radius: 2px;

  .stat-bar-progress {
    height: 42px;
    background: white;
    transition-duration: 200ms;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px 0px 0px 3px;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.25), inset 0px 3px 2px rgba(0, 0, 0, 0.51);
  }

  &.stat-bar-reverse-on {
    .stat-bar-progress {
      left: auto;
      left: 0;
    }
  }

  &.stat-bar-classification-red {
    .stat-bar-progress {
      background: linear-gradient(185.09deg, rgba(255, 77, 77, 0.7) 40%, rgba(11, 34, 3, 0.7) 127.09%), url("@/assets/textures/wall-seamless.png");
    }
  }

  &.stat-bar-classification-yellow {
    .stat-bar-progress {
      background: linear-gradient(185.09deg, rgba(251, 255, 57, 0.7) 40%, rgba(11, 34, 3, 0.7) 127.09%), url("@/assets/textures/wall-seamless.png");
    }
  }

  &.stat-bar-classification-green {
    .stat-bar-progress {
      background: linear-gradient(185.09deg, rgba(161, 255, 77, 0.7) 40%, rgba(11, 34, 3, 0.7) 127.09%), url("@/assets/textures/wall-seamless.png");
    }
  }

  .stat-bar-label {
    color: #FFFFFF;

    height: 42px;
    line-height: 42px;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    width: 100%;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
    position: relative;

    z-index: 5;
  }

  .stat-bar-separators {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .stat-bar-separator {
      background: rgba(#ffffff, .5);
      width: 5px;
      height: 100%;
    }
  }
}

.stat-bar-value-change {
  background: url("@/assets/ui/points-frame.png");
  width: 130px;
  height: 69px;
  padding: 0 10px;

  color: #FFF;

  text-align: center;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.80);
  font-size: 35px;
  font-weight: 700;

  opacity: 0;

  transition: 300ms ease-in-out;

  position: absolute;
  top: 72px;
  left: 43px;
  line-height: 69px;

  &.stat-bar-value-change-show {
    opacity: 1;
  }
}
</style>
