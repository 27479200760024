<template>
  <div class="debriefing-learning-objective-chart">
    <div class="debriefing-learning-objective-chart-background">
    </div>
    <div class="debriefing-learning-objective-chart-bars">
      <Bar
        v-if="chartData"
        id="my-chart-id"
        :options="chartOptions"
        :data="chartData"
        style="width: 100%; height: 100%"
      />
    </div>

  </div>
</template>
<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'DebriefingLearningObjectiveChart',
  components: {
    Bar,
  },
  props: {
    bars: {
      type: Array,
    }
  },
  watch: {
    bars: {
      deep: true,
      handler() {
        this.recomputeChartData();
      },
    },
  },
  data() {
    return {
      chartData: null,
    }
  },
  mounted() {
    this.recomputeChartData();
  },
  methods: {
    recomputeChartData() {
      const labels = [];

      const hazardActionCounts = [];
      const strategicActionCounts = [];

      console.log(ChartJS.defaults.font);

      for (const bar of this.bars) {
        labels.push(bar.label);
        hazardActionCounts.push(bar.hazardActionCount);
        strategicActionCounts.push(bar.strategicActionCount);
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Hazard actions',
            data: hazardActionCounts,
            backgroundColor: '#C29858',
          },
          {
            label: 'Strategic actions',
            data: strategicActionCounts,
            backgroundColor: '#5CA3CB',
          }
        ],
      }
    }
  },
  computed: {
    chartOptions() {
      const ticks = {
        color: "#ffffff",
        font: {
          size: 16,
          family: '"Josefin Sans", sans-serif',
          style: '',
        },
      };

      const borderColor = 'rgba(84, 96, 107, 0.6)';

      return {
        responsive: false,
        scales: {
          x: {
            stacked: true,
            ticks: ticks,
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: ticks,
            border: {
              display: false,
            },
            grid: {
              color: function(context) {
                // Only colour every second line

                if ((context.index % 2) === 0) {
                  return borderColor;
                }

                return null;
              },
              drawBorder: false,
              drawOnChartArea: true,
              drawTicks: false,
              lineWidth: 5,
              zeroLineWidth: 5,
              zeroLineColor: borderColor,
            },
          }
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.debriefing-learning-objective-chart-bar-hazard-actions {
  background: #C29858;
  border-radius: 2px;
}

.debriefing-learning-objective-chart-bar-strategic-actions {
  background: #5CA3CB;
  border-radius: 2px;
}

.debriefing-learning-objective-chart {
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, .40);
}

.debriefing-learning-objective-chart-bars {
  height: 100%;
  padding: 50px;
}
</style>
