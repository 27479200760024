export class OverlayPopEvent extends Event {
  title = null;
  subText = null;
  timeout = null;
  fadeSpeed = null;

  constructor (title, subText, timeout, fadeSpeed) {
    super('pop');

    this.title = title;
    this.subText = subText;
    this.timeout = timeout;
    this.fadeSpeed = fadeSpeed;
  }
}
