export default {
  'intro': {
    'logoEmergencyPreparedness': 'Emergency preparedness & IHR compliance', // Type: text
    'logoYouDecide': 'You decide the future of Global Land', // Type: text
    'introText': 'You have to build a multi sectoral GHSC committee that is to be strong on different technical areas.\n' + 
      '\n' + 
      'Whole of government approach and whole of society approach should be among your priorities.\n' + 
      '\n' + 
      'As a team you will make strategic decisions wisely after discussing together and building on the collective experience of your team.\n' + 
      '\n' + 
      'Prioritize on resources for a better outcome in preparedness, readiness and response stages. You cannot do everything all the time.\n' + 
      '\n' + 
      'A successful Mass Gathering (MG) event can build a legacy to your country. The preparedness and readiness investments you do will pay off beyond the event success.\n' + 
      '\n' + 
      'You win the game when you collaborate together to make the right decision in the right time so that you are prepared well for the Mass Gathering event and ready to deal with any situation.', // Type: markdown
    'introHeader': 'Global Land Handball Steering Commitee', // Type: text
    'welcome': 'WELCOME TO EPIC 2', // Type: text
    'introSubtitle': 'In this virtual simulated world, you will be able to observe and analyse the potential outcomes and consequences of your teams decisions as well as receive immediate feedback to guide your decision making.', // Type: text
    'header1': 'The Global Land Handball Cup', // Type: text
    'header2': 'MAIN MISSION', // Type: text
    'header3': 'YOUR ACTIONS', // Type: text
    'text1': 'A One Day\n' + 
      'International\n' + 
      'handball\n' + 
      'tournament\n' + 
      'organized\n' + 
      'by the\n' + 
      'Global Handball Council.', // Type: text
    'text2': 'Hosted in Global Land with participants from:', // Type: text
    'text3': 'To handle the upcoming handball tournament as a multi sectoral GLobal Land Handball Steering Committee and stage a successful Mass Gathering event that can build a legacy for your country.', // Type: text
    'text4': 'Prioritize actions and allocate resources to strengthen the capacities of the national health emergency preparedness and response agendas.', // Type: text
    'text5': 'Explore and deal with hazards, Communicate effectively,\n' + 
      'Collaborate as a team and make the right decision at the right time.', // Type: text
    'happens': 'Happens in 12 months.', // Type: text
    'visitors': 'The tournament will attract more than 500.000 visitors.', // Type: text
    'buttonGotIt': 'Got it', // Type: text
    'roleSelectionFooterLeft': 'Warning', // Type: text
    'roleSelectionFooterRight': 'On the impact of the team composition - pre choice and then change if needed', // Type: text
    'disclaimerHeader': 'Disclaimer', // Type: text
    'disclaimerBody': 'The following content is developed as training material for WHO as a serious game called **Emergency Preparedness and IHR Compliance**.\n' + 
      '\n' + 
      'The game is based on real world scenarios presented in a safe learning environment, where the technical contents is simplified as deemed necessary.\n' + 
      'The game was designed to be played by a team of up to 5 players to encourage collaboration, decision-making, Emergency Preparedness and IHR Compliance and is estimated to last for about 3 hours.\n' + 
      '\n' + 
      'We encorage player not to \'fight\' the simulation, but enbrace the it and have fun with the other players.\n' + 
      '\n' + 
      '**It is recommended that you play the game with headset as it will enchance the user expirience.**\n' + 
      '\n' + 
      'In order to play the game optimally, please use an updated modern browser like one of the following:', // Type: markdown
    'buttonIUnderstand': 'I understand', // Type: text
    'buttonPreviousPage': 'Previous page', // Type: text
    'buttonNextPage': 'Next page', // Type: text
    'buttonLogIn': 'Log in', // Type: text
  },
  'end': {
    'finalDecisionTitle': 'Final Decision', // Type: text
    'finalDecisionHeader': 'How will you proceed with the Handball Tournament?', // Type: text
    'finalDecisionBody': 'You have done what you could for Global Land to prepare for the Handball tournament. Now it\'s time to make the final decision together. But choose wisely, for while the true choice will bring you life, the false choice will take it from you.', // Type: text
    'decisionRestrictButton': 'Restrict', // Type: text
    'decisionRestrictBody': 'You will restrict the Tournament to only hold for a limited amount of spectators.', // Type: text
    'decisionHoldButton': 'Hold', // Type: text
    'decisionHoldBody': 'You will hold the Tournament without restriction', // Type: text
    'decisionDelayButton': 'Delay', // Type: text
    'decisionDelayBody': 'You will delay the Tournament to hold it at a later time.', // Type: text
    'decisionCancelButton': 'Cancel', // Type: text
    'decisionCancelBody': 'You will cancel the tournament.', // Type: text
    'decisionFooterHeader': 'How will you proceed with the Handball Tournament?', // Type: text
    'decisionFooterBody': 'You have done what you could for Global Land to prepare for the Handball tournament. Now it\'s time to make the final decision together. But choose wisely, for while the true choice will bring you life, the false choice will take it from you.', // Type: text
    'decisionFeedbackHeaderLeft': 'Decision', // Type: text
    'decisionFeedbackHeaderRight': 'Feedback', // Type: text
    'decisionWarning': 'Warning', // Type: text
    'decisionNotUnanimous': 'You have not reached a unanimous decision, yet.\n' + 
      'Further time spent will result in a deduction of points from your key indicators.', // Type: text
    'decisionNotUnanimousPenalty': 'You have not reached a unanimous decision, yet.\n' + 
      'You will now be deducted 1 point from each of your key indicators.', // Type: text
    'decisionFurtherTime': 'You will get 2 more minutes to come to an agreement.', // Type: text
    'buttonGotIt': 'Got it', // Type: text
    'preDebriefingSubtitle': 'Thank you for playing EPIC 2', // Type: text
    'preDebriefingButtonText': 'Go to debriefing', // Type: text
  },
  'lobby': {
    'buttonStartGame': 'Start game', // Type: text
    'timerPickCharacter': 'Pick a character and a name', // Type: text
    'headerWelcomeTo': 'Welcome to', // Type: text
    'headerButtonLeaderboard': 'Leaderboard', // Type: text
    'enterRoomCode': 'ENTER ROOM CODE', // Type: text
    'buttonEnterRoom': 'Enter', // Type: text
    'headerChooseAvatar': 'Choose avatar', // Type: text
    'playerName': 'Player name', // Type: text
    'buttonJoin': 'Join', // Type: text
    'lobbyHeader': 'Lobby', // Type: text
    'playersReady': 'PLAYERS READY', // Type: text
    'buttonReady': 'Ready', // Type: text
    'buttonUnready': 'Unready', // Type: text
    'buttonStart': 'Start', // Type: text
    'error': 'Error', // Type: text
    'errorNoMicrophone': 'A microphone needs to be selected to continue', // Type: text
    'errorAtLeast2Players': 'At least 2 players are needed to begin a session', // Type: text
    'buttonContinue': 'Continue', // Type: text
    'headerChooseRole': 'Choose Role', // Type: text
    'roleRepresents': 'Represent', // Type: text
    'roleBranch': 'Branch', // Type: text
    'roleAreasOfExpertise': 'Areas of expertise', // Type: text
    'buttonRoleChosen': 'Role chosen', // Type: text
    'buttonChooseRole': 'Choose role', // Type: text
    'buttonShowTeamBalance': 'Show team balance', // Type: text
    'teamBalanceHeader': 'Team balance', // Type: text
    'noRoomCodeTitle': 'Don\'t have a room code?', // Type: text
    'noRoomCodeBody': 'If you don\'t have a room code you need to use the code\/link sent by your facilitator.', // Type: text
  },
  'leaderboard': {
    'header': 'Leaderboard', // Type: text
    'buttonGoBack': 'Go back', // Type: text
    'loading': 'Loading leaderboard', // Type: text
  },
  'topUi': {
    'actionPoints': 'TEAM RESOURCES', // Type: text
    'trust': 'Trust (Public in Gov+ Gov in Public+ Gov sectors at nat. level+ international in Gl)', // Type: text
    'collaboration': 'Collaboration (Gov sectors+ Public with Gov+ Partners & International with Gov)', // Type: text
    'healthSecurity': 'Health Security (consider Capacity building+ Vulnerability level+ Hazard impact)', // Type: text
    'mgEventSuccess': 'MG event success (direct or indirect support+ tourism+ legacy)', // Type: text
    'buttonPauseGame': 'Pause game', // Type: text
    'buttonResumeGame': 'Resume game', // Type: text
    'buttonEndGame': 'Skip to the end of the game', // Type: text
    'totalActionPointCost': 'Total cost', // Type: text
    'timeLeftHover': '[CURRENT_MONTH] out of [TOTAL_MONTHS] months', // Type: text
    'pauseConfirmationTitle': 'Confirmation', // Type: text
    'pauseConfirmationBody': 'The game can only be paused once every 10 minutes.\n' + 
      '\n' + 
      'Are you sure you want to pause the game?', // Type: text
  },
  'pause': {
    'gameCurrentlyPaused': 'The game is currently paused.', // Type: text
  },
  'dashboard': {
    'indicators': 'Indicators', // Type: text
    'usedActions': 'Used actions', // Type: text
    'strategicActions': 'Strategic actions', // Type: text
    'responseActions': 'Response actions', // Type: text
    'indicatorAverageCapacityLevel': 'Average capacity level', // Type: text
    'indicatorName': 'Name', // Type: text
    'capacityLevel': 'Capacity level', // Type: text
    'actionCost': 'Cost', // Type: text
    'actionIndicatorsImpacted': 'Indicators impacted', // Type: text
    'actionMonth': 'Month', // Type: text
    'actionDescription': 'Description', // Type: text
    'actionInResponseTo': 'In response to', // Type: text
  },
  'meetingPopup': {
    'strategicMeetingHeader': 'Strategic meeting', // Type: text
    'hazardMeetingHeader': 'Hazard meeting', // Type: text
    'unknownMeetingHeader': 'Unknown meeting', // Type: text
    'totalCost': 'Total cost', // Type: text
    'buttonJoinMeeting': 'Join meeting', // Type: text
    'invitationMessage': 'You are invited for the monthly strategic meeting', // Type: text
    'noActionsInvested': 'No actions have been invested', // Type: text
    'buttonAdd': 'ADD', // Type: text
    'buttonRemove': 'REMOVE', // Type: text
    'cost': 'Cost', // Type: text
    'allActionsInvested': 'All actions from this category have been invested', // Type: text
    'noActionsInGroup': 'No actions exist in this group', // Type: text
    'everythingInvested': 'Everything is already invested. There is nothing left to invest.', // Type: text
    'meetingResultsHeader': 'Meeting results', // Type: text
    'meetingResultsActionsTaken': 'Actions taken', // Type: text
    'meetingResultsFeedback': 'Feedback', // Type: text
    'feedbackHeader': 'Meeting results', // Type: text
    'feedbackNothingImplemented': 'Nothing was implemented during the meeting', // Type: text
    'feedbackActionsTaken': 'Actions taken', // Type: text
    'feedbackFeedback': 'Feedback', // Type: text
    'hazardPrioritizationUpNext': 'Up next', // Type: text
    'hazardPrioritizationsHeader': 'Hazard prioritizations', // Type: text
    'hazardNoPrioritizationsHeader': 'Not enough flags', // Type: text
    'hazardNoPrioritizationsDescription': 'No hazards had enough flags to be prioritized. A hazard needs at least 2 flags to start the meeting.', // Type: text
    'feedbackClickToShowFeedback': 'Click on an eye button on the left to unveil insights about each investment', // Type: text
  },
  'prioritize': {
    'buttonPrioritize': 'Prioritize', // Type: text
    'buttonUnprioritize': 'Unprioritize', // Type: text
    'buttonKeepPrioritized': 'Keep prioritized', // Type: text
    'buttonKeepUnprioritized': 'Keep unprioritized', // Type: text
    'buttonLearnMore': 'Learn more', // Type: text
    'prioritizeHeader': 'Prioritize', // Type: text
    'usedAllFlags': 'You have used all your flags, remove one to change priority or press cancel.', // Type: text
    'prioritizedFollowingHazards': 'The following hazards currently have 1 or more flags', // Type: text
    'buttonSwap': 'Swap', // Type: text
    'buttonRemove': 'Remove', // Type: text
    'buttonInspect': 'Inspect', // Type: text
  },
  'hazard': {
    'animal': 'Animal', // Type: text
    'chemical': 'Chemical', // Type: text
    'disaster': 'Disaster', // Type: text
    'environmental': 'Environmental', // Type: text
    'infectious': 'Infectious', // Type: text
    'societal': 'Societal', // Type: text
    'zoonosis': 'Zoonosis', // Type: text
    'unknown': 'Unknown', // Type: text
    'breakingNews': 'Breaking News', // Type: text
    'foodSafety': 'Food Safety', // Type: text
    'news': 'News', // Type: text
  },
  'epiReport': {
    'header': 'EPI report - Month [MONTH]', // Type: text
    'virusName': 'Virus name', // Type: text
    'cases': 'Cases', // Type: text
    'deaths': 'Deaths', // Type: text
    'buttonPreviousMonth': 'Previous month', // Type: text
    'buttonNextMonth': 'Next month', // Type: text
  },
  'error': {
    'couldNotInvest': 'Could not invest: [ERROR]', // Type: text
    'buttonOk': 'OK', // Type: text
    'title': 'Sorry', // Type: text
  },
  'notificationAssessment': {
    'header': 'Notification assessment', // Type: text
    'intro': 'Now, please asses whether the current situation warrants notification to WHO under IHR Annex 2.', // Type: text
    'q1': 'Is the public health impact of the event serious?', // Type: text
    'q2': 'Is the event unusual or unexpected?', // Type: text
    'q3': 'Is there a significant risk of international spread?', // Type: text
    'q4': 'Is there a significant risk of international travel or trade restrictions?', // Type: text
    'q5': 'Does this event need to be notified to WHO under Article 6 of the IHR?', // Type: text
    'q6': 'If the event is not notifiable, will you nonetheless decide to consult WHO?', // Type: text
    'answerYes': 'Yes', // Type: text
    'answerNo': 'No', // Type: text
  },
  'various': {
    'confirmationPopupAnswerYes': 'Yes', // Type: text
    'confirmationPopupAnswerNo': 'No', // Type: text
  },
  'starMinigame': {
    'invitationHeader': 'Mini-GAME', // Type: text
    'invitationBodyTitle': 'STAR (Strategic Toolkit for Assessing Risks)', // Type: text
    'invitationBodyContents': 'Dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.', // Type: text
    'buttonInvitationContinue': 'Continue', // Type: text
    'buttonStrategicToolkit': 'Strategic toolkit', // Type: text
    'buttonChoose': 'Choose', // Type: text
    'buttonChosen': 'Chosen', // Type: text
    'buttonStarDocuments': 'Star Documents', // Type: text
    'frequency_perennial': 'Perennial', // Type: text
    'frequency_recurrent': 'Recurrent', // Type: text
    'frequency_frequent': 'Frequent', // Type: text
    'frequency_rare': 'Rare', // Type: text
    'frequency_random': 'Random', // Type: text
    'likelihood_veryUnlikely': 'Very unlikely', // Type: text
    'likelihood_unlikely': 'Unlikely', // Type: text
    'likelihood_almostCertain': 'Almost Certain', // Type: text
    'likelihood_veryLikely': 'Very Likely', // Type: text
    'likelihood_likely': 'Likely', // Type: text
    'severity_veryHigh': 'Very High severity', // Type: text
    'severity_high': 'High severity', // Type: text
    'severity_moderate': 'Moderate severity', // Type: text
    'severity_low': 'Low severity', // Type: text
    'vulnerability_low': 'Low', // Type: text
    'vulnerability_partial': 'Partial', // Type: text
    'vulnerability_high': 'High', // Type: text
    'vulnerability_veryHigh': 'Very High', // Type: text
    'copingCapacity_perennial': 'Perennial', // Type: text
    'copingCapacity_partial': 'Partial', // Type: text
    'copingCapacity_high': 'High', // Type: text
    'copingCapacity_veryHigh': 'Very High', // Type: text
    'impact_perennial': 'Perennial', // Type: text
    'impact_partial': 'Partial', // Type: text
    'impact_moderate': 'Moderate', // Type: text
    'impact_high': 'High', // Type: text
    'impact_veryHigh': 'Very High', // Type: text
    'confidenceLevel_satisfactory': 'Satisfactory', // Type: text
    'confidenceLevel_unsatisfactory': 'Unsatisfactory', // Type: text
    'confidenceLevel_good': 'Good', // Type: text
    'riskLevel_low': 'Low', // Type: text
    'riskLevel_partial': 'Partial', // Type: text
    'riskLevel_high': 'High', // Type: text
    'riskLevel_veryHigh': 'Very High', // Type: text
    'row1riskAssessment': 'Risk assessment', // Type: text
    'row1globalLand': 'Global Land', // Type: text
    'row2hazardsAndExposures': 'Hazards and Exposure', // Type: text
    'row2likelihood': 'Likelihood', // Type: text
    'row2likelihoodSeasonality': 'Seasonality', // Type: text
    'row2severityVulnerabilityCopingCapacity': 'Severity, Vulnerability and Coping Capacity', // Type: text
    'row2impact': 'Impact', // Type: text
    'row2confidenceLevel': 'Confidence level', // Type: text
    'row2riskLevel': 'Risk level', // Type: text
    'row3subgroupOfHazard': 'Subgroup of hazard', // Type: text
    'row3mainTypeOfHazard': 'Main type of hazard', // Type: text
    'row3specificHazard': 'Specific hazard', // Type: text
    'row3healthConsequences': 'Health Consequences', // Type: text
    'row3scale': 'Scale', // Type: text
    'row3exposure': 'Exposure', // Type: text
    'row3frequency': 'Frequency', // Type: text
    'row3frequency_january': 'J', // Type: text
    'row3frequency_february': 'F', // Type: text
    'row3frequency_march': 'M', // Type: text
    'row3frequency_april': 'A', // Type: text
    'row3frequency_may': 'M', // Type: text
    'row3frequency_june': 'J', // Type: text
    'row3frequency_july': 'J', // Type: text
    'row3frequency_august': 'A', // Type: text
    'row3frequency_september': 'S', // Type: text
    'row3frequency_october': 'O', // Type: text
    'row3frequency_november': 'N', // Type: text
    'row3frequency_december': 'D', // Type: text
    'row3likelihood': 'Likelihood', // Type: text
    'row3severity': 'Severity', // Type: text
    'row3vulnerability': 'Vulnerability', // Type: text
    'row3copingCapacity': 'Coping capacity', // Type: text
    'btnAvailable': 'Available', // Type: text
    'btnUnavailable': 'Unavailable', // Type: text
    'btnActive': 'Active', // Type: text
    'btnCompleted': 'Completed', // Type: text
    'headerFrequency': 'Frequency', // Type: text
    'headerSeasonality': 'Seasonality', // Type: text
    'headerLikelihood': 'Likelihood', // Type: text
    'headerSeverity': 'Severity', // Type: text
    'headerVulnerability': 'Vulnerability', // Type: text
    'headerCopingCapacity': 'Coping Capacity', // Type: text
    'titleDocumentStrategicToolkit': 'Document - Strategic toolkit', // Type: text
    'titleDocumentStarDocument': 'Document - Star document', // Type: text
    'titleTutorial': 'Tutorial', // Type: text
    'titleOverview': 'Overview', // Type: text
    'titleLikelihoodVote': 'Likelihood vote', // Type: text
    'titleImpactVote': 'Impact vote', // Type: text
    'titleRiskMatrix': 'Risk matrix', // Type: text
    'titleDefault': 'STAR minigame', // Type: text
    'documentHelpText': 'If you need help, you can read the documents:', // Type: text
  },
  'starMinigameTutorialFrequency': {
    'introText': 'For each hazard define whether the hazard frequency is:', // Type: text
    'perennial': 'Perennial', // Type: text
    'perennialDescription': 'Regular og seasonal events during the year.', // Type: text
    'recurrent': 'Recurrent', // Type: text
    'recurrentDescription': 'Events occurring every 1-2 years.', // Type: text
    'frequent': 'Frequent', // Type: text
    'frequentDescription': 'Events occurring every 2-5 years.', // Type: text
    'rare': 'Rare', // Type: text
    'rareDescription': 'Events occurring every 5-10 years.', // Type: text
    'random': 'Random', // Type: text
    'randomDescription': 'Unpredictable events for which the frequency cannot be determined.', // Type: text
  },
  'starMinigameTutorialLikelihood': {
    'introText': 'Take into account the historical information on the hazard, the recent trends in the country or in the sub-region, the frequency and the seasonality of each hazard to define the likelihood the hazard will occur in the next 12 months at the scale defined in Step 1.', // Type: text
    'headerLikelihood': 'Likelihood', // Type: text
    'headerDescription': 'Description', // Type: text
    'veryUnlikely': 'Very unlikely', // Type: text
    'veryUnlikelyDescription': 'The scenario developed in the Step 1 could occur under exceptional circumstances (e.g. a probability of less than 5%)', // Type: text
    'unlikely': 'Unlikely', // Type: text
    'unlikelyDescription': 'The scenario developed in the Step 1 could occur some of the time (e.g. a probability of between 5% and 29%)', // Type: text
    'likely': 'Likely', // Type: text
    'likelyDescription': 'The scenario developed in the Step 1 will occur some of the time (e.g. a probability of between 30% and 69%)', // Type: text
    'veryLikely': 'Very likely', // Type: text
    'veryLikelyDescription': 'The scenario developed in the Step 1 will probably occur in most circumstances (e.g. a probability of between 70% and 94%)', // Type: text
    'almostCertain': 'Almost certain', // Type: text
    'almostCertainDescription': 'The scenario developed in the Step 1 will probably occur in the next 12 months in most circumstances (e.g. probability of 95% or more)', // Type: text
  },
  'starMinigameTutorialSeasonality': {
    'introText': 'For each hazard, and as appropriate, identify the months of the year during which the is most likely to occur. For instance, for a hazard that may occur every year between March and July with a peak every May, this would be filled in as:', // Type: text
    'monthAbbreviationJanuary': 'J', // Type: text
    'monthAbbreviationFebruary': 'F', // Type: text
    'monthAbbreviationMarch': 'M', // Type: text
    'monthAbbreviationApril': 'A', // Type: text
    'monthAbbreviationMay': 'M', // Type: text
    'monthAbbreviationJune': 'J', // Type: text
    'monthAbbreviationJuly': 'J', // Type: text
    'monthAbbreviationAugust': 'A', // Type: text
    'monthAbbreviationSeptember': 'S', // Type: text
    'monthAbbreviationOctober': 'O', // Type: text
    'monthAbbreviationNovember': 'N', // Type: text
    'monthAbbreviationDecember': 'D', // Type: text
  },
  'starMinigameTutorialSeverity': {
    'introText': 'When conducting the severity assessment for biological hazards of an infectious nature, the following information may be required:', // Type: text
    'list1point1': 'Transmission potential (mode of transmission or basic reproduction number (R0))', // Type: text
    'list1point2': 'Seriousness of consequences (morbidity and mortality)', // Type: text
    'list1point3': 'Disruption of critical health and other services (excess M&M from common illnesses, risk of outbreaks, malnutrition, psychological health)', // Type: text
    'paragraph2': 'When conducting the severity assessment for geological, hydrometeorological, technological and societal hazards, the following information will be required:', // Type: text
    'list2point1': 'Seriousness of consequences (morbidity and mortality)', // Type: text
    'list2point2': 'Disruption of critical health and other services', // Type: text
    'tableHeader': 'Severity', // Type: text
    'tableVeryLowSeverity': 'Very low severity', // Type: text
    'tableLowSeverity': 'Low severity', // Type: text
    'tableModerateSeverity': 'Moderate severity', // Type: text
    'tableHighSeverity': 'High severity', // Type: text
    'tableVeryHighSeverity': 'Very high severity', // Type: text
  },
  'starMinigameTutorialVulnerability': {
    'paragraph1': 'Vulnerability refers to the characteristics and circumstances of a community, system or asset that make it susceptible to the damaging effects of a hazard. In this regard the vulnerability to a given hazard should take into account the following parameters:', // Type: text
    'list1point1': 'Access to health services in potentially affected areas;', // Type: text
    'list1point2': 'The health status of the populations at risk based on health service coverage, population immunity, disease burden etc.;', // Type: text
    'list1point3': 'Social determinants of health such as access to good housing, water, sanitation, education and food security.', // Type: text
    'list1point4': 'Presence of vulnerable groups in affected areas.', // Type: text
    'paragraph2': 'Using information on the parameters above, describe briefly the vulnerabilities in the \'vulnerabilities and capacities\' worksheet and use the following scale for rating existing vulnerabilities to the hazard and consequences:', // Type: text
    'tableHeader': 'Vulnerability', // Type: text
    'tableVeryHigh': 'Very high', // Type: text
    'tableHigh': 'High', // Type: text
    'tableModerate': 'Moderate', // Type: text
    'tableLow': 'Low', // Type: text
    'tableVeryLow': 'Very low', // Type: text
  },
  'starMinigameTutorialCopingCapacity': {
    'paragraph1': 'Coping capacity measures the means by which people or organizations use available resources and abilities to face adverse consequences. The coping capacity associated with a hazard will be determined by:', // Type: text
    'list1point1': 'The capacity of the health sector to detect, identify, and respond to the hazard and its health consequences at the given scale;', // Type: text
    'list1point2': 'The functional capacity of health facilities to manage expected caseloads in potentially affected areas;', // Type: text
    'list1point3': 'The existence of community knowledge about the hazard, and risk minimising attitudes and practice.', // Type: text
    'paragraph2': 'Using information on the parameters above, describe briefly the capacities in the \'vulnerabilities and capacities\' worksheet and use the following scale for rating coping capacity available for the hazard and consequences identified:', // Type: text
    'tableHeaderCopingCapacity': 'Coping capacity', // Type: text
    'tableHeaderDescription': 'Description', // Type: text
    '': '', // Type: text
    'tableRowVeryHigh': 'Very high', // Type: text
    'tableRowVeryHighDescription': 'All coping capacities required for the specific hazard are functional and sustainable, and the country is supporting one or more other countries in their implementation.', // Type: text
    'tableRowHigh': 'High severity', // Type: text
    'tableRowHighDescription': 'All coping capacity required for the hazard are available but have never been stressed under real conditions (response) or tested during simulation exercise.', // Type: text
    'tableRowPartial': 'Partial', // Type: text
    'tableRowPartialDescription': 'Some coping capacity required for the hazard are available, but functionality and sustainability have not been ensured, such as through inclusion in the operational plan of the national health sector plan with a secure funding source.', // Type: text
    'tableRowLow': 'Low', // Type: text
    'tableRowLowDescription': 'Core coping capacity required for the hazard (human, material, strategic and financial) are in development stage. Implementation has started with some attributes achieved and others commenced.', // Type: text
    'tableRowVeryLow': 'Very low', // Type: text
    'tableRowVeryLowDescription': 'Core coping capacity required for the hazard (human, material, strategic and financial) are not available', // Type: text
  },
  'starMinigameRiskMatrix': {
    'critical': 'Critical', // Type: text
    'severe': 'Severe', // Type: text
    'moderate': 'Moderate', // Type: text
    'minor': 'Minor', // Type: text
    'negligible': 'Negligible', // Type: text
    'veryUnlikely': 'Very unlikely', // Type: text
    'unlikely': 'Unlikely', // Type: text
    'likely': 'Likely', // Type: text
    'veryLikely': 'Very likely', // Type: text
    'almostCertain': 'Almost certain', // Type: text
    'impact': 'Impact', // Type: text
    'likelihood': 'Likelihood', // Type: text
    'switchToggle': 'Switch\/Toggle', // Type: text
    'switchOld': 'Old', // Type: text
    'switchNew': 'New', // Type: text
    'switchCorrect': 'Correct', // Type: text
    'titleOld': 'Global Land emergency risk matrix table (2019)', // Type: text
    'titleNew': 'Global Land emergency risk matrix table ([YEAR])', // Type: text
    'titleCorrect': 'Global Land emergency risk matrix table (Correct)', // Type: text
  },
  'debriefing': {
    'finalFeedbackTitle': 'Final feedback', // Type: text
    'finalFeedbackDescription': '**Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim risus, maximus eget est quis, sodales fermentum ex. Vestibulum laoreet libero nec nunc auctor, tempus suscipit dui dignissim. Etiam non ligula malesuada, convallis nisl accumsan, faucibus turpis. Suspendisse ac nisl ac arcu pulvinar commodo et eu purus. Quisque fermentum nisi magna, dictum accumsan risus placerat vitae. Fusce in neque vel magna viverra volutpat. Morbi consequat eros nec ipsum congue, ac sollicitudin risus venenatis.**\r\n' + 
      '\r\n' + 
      'Proin sed maximus lorem. Maecenas sollicitudin tristique elit eget congue. Integer elementum dapibus felis sit amet finibus. Aliquam id libero a elit pretium luctus in vitae ligula. Aliquam at malesuada sapien. Nulla magna ligula, tempus at lorem vitae, interdum egestas sem. Praesent ut velit consectetur, sodales nunc vitae, ultrices urna.', // Type: markdown
    'finalScoreTitle': 'Final score', // Type: text
    'finalScoreDescription': '**Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim risus, maximus eget est quis, sodales fermentum ex. Vestibulum laoreet libero nec nunc auctor, tempus suscipit dui dignissim. Etiam non ligula malesuada, convallis nisl accumsan, faucibus turpis. Suspendisse ac nisl ac arcu pulvinar commodo et eu purus. Quisque fermentum nisi magna, dictum accumsan risus placerat vitae. Fusce in neque vel magna viverra volutpat. Morbi consequat eros nec ipsum congue, ac sollicitudin risus venenatis.**', // Type: markdown
    'indicatorsTitle': 'Indicators', // Type: text
    'indicatorsDescription': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam enim risus, maximus eget est quis, sodales fermentum ex. Vestibulum laoreet libero nec nunc auctor, tempus suscipit dui dignissim. Etiam non ligula malesuada, convallis nisl accumsan, faucibus turpis. Suspendisse ac nisl ac arcu pulvinar commodo et eu purus. Quisque fermentum nisi magna, dictum accumsan risus placerat vitae. Fusce in neque vel magna viverra volutpat. Morbi consequat eros nec ipsum congue, ac sollicitudin risus venenatis.', // Type: markdown
    'learningObjectivesTitle': 'Learning objectives linked to team actions', // Type: text
    'actionsTakenTitle': 'Actions taken', // Type: text
    'trust': 'Trust', // Type: text
    'healthSecurity': 'Health security', // Type: text
    'collaboration': 'Collaboration', // Type: text
    'massGatheringSuccess': 'Mass gathering success', // Type: text
  },
  'tutorial': {
    'title': 'Tutorial', // Type: text
    'understood': 'Got it', // Type: text
    'timeline': 'This is your **Timeline**. It displays the number of month left to the Mass gathering event. Each month will consist of 3 phases.\n' + 
      '\n' + 
      '**Phase 1** is the strategic action phase where you and your team will decide what strategic actions you will take for the month. Strategic actions build capacity and prepare for the mass gathering event.\n' + 
      '\n' + 
      '**Phase 2** is the prioritization phase, where you discuss, prioritize and investigate the hazards in Global Land.\n' + 
      '\n' + 
      '**Phase 3** is the Hazard response phase, where you respond to the hazards that you prioritized in the previous round.', // Type: markdown
    'character': 'This is **you**. This avatar will represent you throughout the game. Remember, you can always mute yourself on the microphone icon.\n' + 
      '\n' + 
      'This is a representation of your role.', // Type: markdown
    'flag': 'This is your **Priority flags**. Each month you will get a fixed amount of flags that you will spend on prioritizing hazards in the game.', // Type: markdown
    'scores': 'This is your **overall scores** of the game. Your have **Trust, Health security, Collaboration** and **Mass gathering event success**. Your actions throughout the game will add more or less to these scores.', // Type: markdown
    'actionPoints': 'This is your *Team Ressources** (TR). **The most important element of the game.** Team ressources represent you as a teams investment of time, finances and skill into different actions throughout the game.\n' + 
      '\n' + 
      'Each month has a fixed amount of TR and you and your teammates will decide how to spend these TR throughout that month.\n' + 
      '\n' + 
      'But spend them wisely because all activities has an TR cost and you and your teammates all have to read documents, investigate hazards, take strategic action and respond to hazards. Collaborate with you teammates on when, how and why you spend TRs.', // Type: markdown
  },
};