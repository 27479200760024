<template>
  <button class="tiny-button" :class="computedClasses">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TinyButton',
  props: {
    state: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      const classes = [
        'tiny-button-state-' + (this.state || 'none'),
      ];

      if (this.disabled) {
        classes.push('tiny-button-disabled');
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
.tiny-button {
  width: 60px;
  height: 57px;
  background: transparent url("@/assets/buttons/btn-tiny-inactive.png") no-repeat;
  background-size: cover;
  outline: 0;
  border: 0;
  color: white;
  font-size: 14pt;
  font-weight: bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:focus {
    outline: 0;
    border: 0;
  }

  &.tiny-button-disabled {
    cursor: not-allowed;
    opacity: .6;
  }

  &:active {
    background-image: url("@/assets/buttons/btn-tiny-active.png");
  }
}
</style>
