export const EVENT_ROOM_JOINED = 'roomJoined';

export class RoomJoinedEvent extends Event {
  roomId;
  participant;

  constructor(roomId, participant) {
    super(EVENT_ROOM_JOINED);

    this.roomId = roomId;
    this.participant = participant;
  }
}
