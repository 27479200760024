export function getScoreClassification (score) {
  if (score >= 70) {
    return 'positive'
  } else if (score >= 30) {
    return 'neutral'
  } else {
    return 'negative'
  }
}

const difficultyHandicap = 2.5;

export function getHazardLikelihood (hazardBaseLikelihood) {
  return (hazardBaseLikelihood / difficultyHandicap)
}

export function getHazardSeverity (hazardBaseSeverity, hazardAge) {
  return Math.min(5, (hazardBaseSeverity / difficultyHandicap) + hazardAge);
}

export function getHazardScore (hazardLikelihood, hazardSeverity) {
  return hazardLikelihood * hazardSeverity
}

export function getJeeScore (actionJeeImpacts, jeeValues) {
  // =CEILING((AVERAGE($L$2;$N$2))/20)
  let jeeCount = 0
  let jeeSum = 0

  for (const jeeImpact of actionJeeImpacts) {
    const jee = jeeImpact.identifier

    jeeCount += 1
    jeeSum += jeeValues[jee]
  }

  return Math.max(1, Math.floor((jeeSum / jeeCount) / 20));
}

export function getJeeVsHazardScore (hazardScore, actionJeeScore) {
  //(1/($F$19/$C$23))+1
  return (1 / (hazardScore / actionJeeScore)) + 1
}

export function getResponseEffect (actionHazardBaseImpact, actionJeeVsHazardScore) {
  // =FLOOR(C30+$D$23)
  return Math.floor((actionHazardBaseImpact + actionJeeVsHazardScore) * 1.5)
}

export function getResponseCost (actionBaseCost, hazardScore, actionJeeScore) {
  // =CEILING(C34*($F$19/$C$23))
  return Math.ceil(actionBaseCost * (hazardScore / actionJeeScore))
}

export function getCalcScore (baseScore, actionJeeVsHazardScore) {
  // =FLOOR(D44+$D$23)
  return Math.floor(baseScore + actionJeeVsHazardScore)
}

export function getHazardReturnsIn (responseEffectTotal, hazardScore) {
  // =FLOOR(C40-F19)
  return Math.floor(responseEffectTotal - hazardScore)
}

export function computeHazardScores (hazard, missionAge, investedActionIdentifiers, jeeValues, includeStaticData = false) {
  if (!hazard || (investedActionIdentifiers !== null && investedActionIdentifiers.length <= 0)) {
    return null
  }

  const investedActions = []

  for (const category of hazard.categories) {
    for (const tab of category.tabs) {
      for (const action of tab.actions) {
        if (investedActionIdentifiers === true || investedActionIdentifiers.includes(action.identifier)) {
          investedActions.push(action)
        }
      }
    }
  }

  const hazardLikelihood = getHazardLikelihood(hazard.baseLikelihood)
  const hazardSeverity = getHazardSeverity(hazard.startingSeverity, missionAge)
  const hazardScore = getHazardScore(hazardLikelihood, hazardSeverity)

  const computedActions = []

  let hazardTotalEffect = 0
  let hazardTotalCost = 0;

  for (const investedAction of investedActions) {
    const actionJeeScore = getJeeScore(investedAction.jeeImpacts, jeeValues)
    const actionJeeVsHazardScore = getJeeVsHazardScore(hazardScore, actionJeeScore)

    const actionResponseEffect = getResponseEffect(investedAction.baseHazardImpact, actionJeeVsHazardScore)
    const actionResponseCost = getResponseCost(investedAction.baseCost, hazardScore, actionJeeScore)

    const jeeCurrentValues = [];

    if (includeStaticData) {
      for (const jeeImpact of investedAction.jeeImpacts) {
        jeeCurrentValues.push(`${jeeImpact.identifier}: ${jeeValues[jeeImpact.identifier]}`)
      }
    }

    const actionComputationStatic = includeStaticData ? {
      // Static info, doesn't actually need to be returned
      action: investedAction,
      jeeCurrentValues: jeeCurrentValues,
    } : {}

    const calcScores = {
      trust: Math.floor(investedAction.scoreImpactTrust + actionJeeVsHazardScore),
      healthSecurity: Math.floor(investedAction.scoreImpactHealthSecurity + actionJeeVsHazardScore),
      collaboration: Math.floor(investedAction.scoreImpactCollaboration + actionJeeVsHazardScore),
      mgEventSuccess: Math.floor(investedAction.scoreImpactMgEventSuccess + actionJeeVsHazardScore),
    };

    computedActions.push({
      ...actionComputationStatic,

      // Computed numbers
      jeeScore: actionJeeScore,
      jeeVsHazardScore: actionJeeVsHazardScore,
      responseEffect: actionResponseEffect,
      responseCost: actionResponseCost,

      calcScores: calcScores,
    })

    hazardTotalEffect += actionResponseEffect
    hazardTotalCost += actionResponseCost;
  }

  const missionComputationStatic = includeStaticData ? {
    // Static info, doesn't actually need to be returned
    hazard: hazard,
    missionAge: missionAge,
    investedActionIdentifiers: investedActionIdentifiers,
    investedActions: investedActions,
  } : {}

  const hazardReturnIn = Math.max(1, Math.floor(hazardTotalEffect - hazardScore));

  return {
    // Static info, doesn't actually need to be returned
    ...missionComputationStatic,

    // Computed numbers
    hazardTotalResponseEffect: hazardTotalEffect,
    hazardTotalResponseCost: hazardTotalCost,
    hazardReturnIn: hazardReturnIn,
    hazardLikelihood: hazardLikelihood,
    hazardSeverity: hazardSeverity,
    hazardScore: hazardScore,
    actions: computedActions,
  }
}



export function computeHazardActionCosts (hazard, missionAge, jeeValues) {
  const computedData = computeHazardScores(hazard, missionAge, true, jeeValues, true);

  const actionCost = {};

  if (computedData && computedData.actions.length > 0) {
    for (const action of computedData.actions) {
      actionCost[action.action.identifier] = action.responseCost;
    }
  }

  return actionCost;
}
