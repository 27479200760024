<template>
  <div class="notification-assessment">
    <h1>{{ $t('notificationAssessment.header') }}</h1>
    <p class="sub-title">{{ $t('notificationAssessment.intro') }}</p>

    <div class="questions">
      <div v-for="question in computedQuestions" class="question-row row" :class="question.disabled ? 'question-row-disabled' : 'question-row-enabled'">
        <div class="question col-8 text-start">{{ question.question }}</div>
        <div class="buttons col-4 d-flex">
          <app-button
            v-for="answer in question.answers"
            color="green"
            width="md"
            height="md"
            :state="answer.label === question.currentAnswer ? 'active' : ''"
            @click="question.currentAnswer = answer.label"
          >
            {{ answer.label }}
          </app-button>
        </div>
      </div>
    </div>

    <!--<div class="main-buttons row mt-4">
      <div class="col-6">
        <app-button color="blue" @click="onCancel" width="full" height="lg">Cancel</app-button>
      </div>
      <div class="col-6">
        <app-button :disabled="!canCommit" color="blue" @click="onCommit" width="full" height="lg">Commit</app-button>
      </div>
    </div>-->
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue'

// :state="answersGiven[question.identifier] === answer.value ? 'active' : 'normal'"
// :disabled="question.disabled"
// @click="giveAnswer(question.identifier, answer.value)"
export default {
  name: 'NotificationAssessment',
  components: { AppButton },
  emits: ['cancel', 'commit'],
  data () {
    const answers = [
      {
        value: 1,
        label: this.$t('notificationAssessment.answerYes'),
      },
      {
        value: 0,
        label: this.$t('notificationAssessment.answerNo'),
      },
    ]

    const questions = [
      {
        identifier: 'q1',
        question: this.$t('notificationAssessment.q1'),
        answers: answers,
        disabled: true,
      },
      {
        identifier: 'q2',
        question: this.$t('notificationAssessment.q2'),
        answers: answers,
        disabled: true,
      },
      {
        identifier: 'q3',
        question: this.$t('notificationAssessment.q3'),
        answers: answers,
        disabled: true,
      },
      {
        identifier: 'q4',
        question: this.$t('notificationAssessment.q4'),
        answers: answers,
        disabled: true,
      },
      {
        identifier: 'q5',
        question: this.$t('notificationAssessment.q5'),
        answers: answers,
        disabled: true,

      },
      {
        identifier: 'q6',
        question: this.$t('notificationAssessment.q6'),
        answers: answers,
        disabled: true,
        /*disabled: () => {
          return this.answersGiven['q5'] === null;
        },*/
      }
    ]

    const answersGiven = [];

    for (const question of questions) {
      answersGiven[question.identifier] = null;
    }

    return {
      answersGiven: answersGiven,
      questions: questions,
      computedQuestions: [],
    }
  },
  mounted() {
    this.recomputeQuestions();
  },
  computed: {
    canCommit() {
      return true;

      for (const answerKey in this.answersGiven) {
        const answerGiven = this.answersGiven[answerKey];

        if (answerGiven === null) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onCommit() {
      if (!this.canCommit) {
        return;
      }

      this.$emit('commit', this.answersGiven);
    },
    recomputeQuestions() {
      const computedQuestions = [];

      for (const question of this.questions) {
        computedQuestions.push({
          identifier: question.identifier,
          question: question.question,
          answers: question.answers,
          disabled: this.isQuestionDisabled(question),
        });
      }

      this.computedQuestions = computedQuestions;
    },
    giveAnswer (questionIdentifier, answerValue) {
      this.answersGiven[questionIdentifier] = answerValue

      this.recomputeQuestions();
    },
    isQuestionDisabled(question) {
      if (!question.disabled) {
        return false;
      }

      const disabled = question.disabled;

      if (typeof(disabled) === 'boolean') {
        return disabled;
      }

      const isDisabled = disabled.bind(this)();

      console.log('isDisabled', isDisabled);

      return disabled.bind(this)();
    },
  }
}
</script>

<style lang="scss" scoped>
.notification-assessment {
  width: 900px;
  margin: 0 auto;

  .question-row {
    line-height: 50px;

    &.question-row-disabled {
    }
  }
}
</style>
