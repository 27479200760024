export function selectifyObject(options) {
  const optionsArray = [];

  const keys = Object.keys(options);

  for (const key of keys) {
    optionsArray.push({
      value: key,
      label: options[key],
    });
  }

  return optionsArray;
}

export function selectifyArray(options) {
  const optionsArray = [];

  for (const option of options) {
    optionsArray.push({
      value: option,
      label: option,
    });
  }

  return optionsArray;
}
