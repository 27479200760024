<template>
  <div class="overlay-pop" :class="computedClasses" :style="computedStyle" @transitionstart="onTransitionStart" @transitionend="onTransitionEnd">
    <div class="overlay-pop-content">
      <h1>{{ title }}</h1>
      <p v-if="subText" class="sub-text">{{ subText }}</p>
    </div>
  </div>
</template>

<script>
import { timeoutPromise } from '@/utils/promiseUtil'

export default {
  name: 'OverlayPop',
  mounted() {
    this.$overlayPop.addEventListener('pop', (e) => {
      this.pop(e.title, e.subText ,e.timeout, e.fadeSpeed);
    });

    this.$overlayPop.addEventListener('cancel', (e) => {
      this.cancel();
    });
  },
  data() {
    return {
      title: '',
      subText: '',
      show: false,
      catchClick: false,
      fadeSpeed: 250,
      timeout: 5000,
    }
  },
  computed: {
    computedClasses() {
      return [
        `overlay-pop-${this.show ? 'show' : 'hide'}`,
        `overlay-pop-mouse-${this.catchClick ? 'catch' : 'ignore'}`
      ];
    },
    computedStyle() {
      return {
        'transition': `${this.fadeSpeed}ms ease-in-out`,
      };
    }
  },
  methods: {
    cancel() {
      this.fadeSpeed = 0;
      this.title = '';
      this.subText = '';
      this.timeout = 0;
      this.show = false;
      this.catchClick = false;
    },
    pop(title, subText = '', timeout = 5000, fadeSpeed = 250) {
      this.fadeSpeed = fadeSpeed;
      this.title = title;
      this.subText = subText || '';
      this.timeout = timeout;

      this.show = true;
      this.catchClick = true;

      return timeoutPromise(this.fadeSpeed).then(() => {
        return timeoutPromise(this.timeout)
      }).then(() => {
        this.show = false;

        return timeoutPromise(this.fadeSpeed)
      }).then(() => {
        this.catchClick = false;

        this.$overlayPop.dispatchEvent(new Event('end'));
      })
    },
    onTransitionStart() {
      if (this.show) {
        this.$overlayPop.dispatchEvent(new Event('beforeFadeIn'));
      } else {
        this.$overlayPop.dispatchEvent(new Event('beforeFadeOut'));
      }
    },
    onTransitionEnd() {
      if (this.show) {
        this.$overlayPop.dispatchEvent(new Event('afterFadeIn'));
      } else {
        this.$overlayPop.dispatchEvent(new Event('afterFadeOut'));
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.overlay-pop {
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  opacity: 0;
  color: white;
  pointer-events: none;

  .overlay-pop-content {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: fit-content;
    height: fit-content;
  }

  h1 {
    font-size: 50pt;
    font-weight: bold;
  }

  p.sub-text {
    font-size: 30pt;
    text-align: center;
  }

  &.overlay-pop-show {
    opacity: 1;
  }

  &.overlay-pop-mouse-catch {
    pointer-events: all;
    cursor: default;
  }
}
</style>
