<template>
  <hazard-popup-container size="sm" padding="sm" :content-background="true">
    <template v-slot:header>
      <hazard-icon :icon="icon" class="icon hazard-icon" :class="'hazard-icon-' + icon" size="sm" />
      <span style="padding-left: 45px">{{  title }}</span>
    </template>
    <template v-slot:content>
      <div class="content-container-outer">
        <div class="content-container epic-scrollbar">
          <div class="content-container-inner" :class="doVerticalAlign ? 'content-container-vertical-align' : ''">
            <markdown-text @render="onMarkdownRender">{{ hazardText }}</markdown-text>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <close-button class="button-close" @click="onClickCancel" />

      <div v-if="isNews">
        <!--<app-button class="btn-prioritize ms-2" @click="onClickLearnMore" color="green" width="xl" height="lg" text-size="lg" :cost="inspectCost" :disabled="!canInteract">
          {{ $t('prioritize.buttonLearnMore') }}
        </app-button>-->
      </div>
      <div v-else-if="isPrioritized">
        <!--<app-button class="btn-prioritize ms-2" @click="onClickCancel" color="green" width="xl" height="lg" text-size="lg" :disabled="!canInteract">
          {{ $t('prioritize.buttonKeepPrioritized') }}
        </app-button>-->

        <app-button class="btn-prioritize ms-2" @click="onClickUnprioritize" color="green" width="xl" height="lg" text-size="lg" :disabled="!canInteract">
          {{ $t('prioritize.buttonUnprioritize') }}
          <flag-icon class="flag-icon" :value="'+' + prioritizationCost"/>
        </app-button>
      </div>
      <div v-else>
        <!--<app-button class="btn-prioritize ms-2" @click="onClickCancel" color="green" width="xl" height="lg" text-size="lg" :disabled="!canInteract">
          {{ $t('prioritize.buttonKeepUnprioritized') }}
        </app-button>-->

        <app-button class="btn-prioritize ms-2" @click="onClickPrioritize" color="green" width="xl" height="lg" text-size="lg" :disabled="!canInteract">
          {{ $t('prioritize.buttonPrioritize') }}
          <flag-icon class="flag-icon" :value="-prioritizationCost"/>
        </app-button>
      </div>
    </template>
  </hazard-popup-container>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import LongButton from '@/components/common/LongButton.vue'
import { padLeft } from '@/utils/stringUtil'
import HazardActionTab from '@/components/HazardMeeting/HazardPopup/HazardActionTab.vue'
import HazardAction from '@/components/HazardMeeting/HazardPopup/HazardAction.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import FlagIcon from '@/components/player-ui/FlagIcon.vue'

export default {
  name: 'HazardPopupIntro',
  components: {
    FlagIcon,
    CloseButton,
    AppButton,
    MarkdownText,
    HazardPopupContainer,
    HazardAction,
    LongButton,
    NoDragImage,
    HazardIcon,
    HazardActionTab,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    hazardText: {
      type: String,
      required: true,
    },
    speaker: {
      type: String,
      default: null,
      required: false,
    },
    prioritizationCost: {
      type: Number,
      required: true,
    },
    canPrioritize: {
      type: Boolean,
      required: false,
      default: true,
    },
    isPrioritized: {
      type: Boolean,
      required: false,
      default: false,
    },
    inspectCost: {
      type: Number,
      required: false,
      default: 0,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'click:cancel',
    'click:prioritize',
    'click:unprioritize',
    'click:learnMore',
    'open:flagManagement',
  ],
  data() {
    return {
      doVerticalAlign: false,
    };
  },
  computed: {
    isNews() {
      return this.type && this.type.toLowerCase() === 'news';
    },
  },
  methods: {
    onClickPrioritize () {
      if (!this.canInteract){
        return;
      }

      if (!this.canPrioritize) {
        this.$emit('open:flagManagement')

        return
      }

      this.$emit('click:prioritize')
    },
    onClickUnprioritize () {
      if (!this.canInteract){
        return;
      }

      this.$emit('click:unprioritize')
    },
    onClickLearnMore() {
      if (!this.canInteract){
        return;
      }

      this.$emit('click:learnMore');
    },
    onClickCancel() {
      this.$emit('click:cancel');
    },
    onMarkdownRender(e) {
      const element = e.element;
      const containerElement = element.parentElement.parentElement;

      this.doVerticalAlign = element.clientHeight < containerElement.clientHeight;
    },
  },
}
</script>

<style lang="scss" scoped>
$tickWidth: 100px;
$tickHeight: 100px;

.intro-left-side {
  .speaker-image {
    position: absolute;
    left: 37px;
    bottom: 40px;
    width: 450px;
  }
}

.intro-right-side {
  position: absolute;
  top: 100px;
  right: 50px;

  .button-container {
    margin-top: 20px;
  }
}

.speech-bubble {
  position: relative;
  background: rgba(0, 0, 0, 60%);

  color: #FFFFFF;
  font-size: 18pt;
  line-height: 150%;
  font-weight: 700;
  width: 600px;
  height: auto;
  border-radius: 15px;
  padding: 50px 30px;
  text-align: center;

  .speech-bubble-tick {
    position: absolute;
    left: -$tickWidth;
    top: 40%;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 $tickHeight $tickWidth;
    border-color: transparent transparent rgba(0, 0, 0, 60%) transparent;
  }
}

.icon {
  position: absolute;
  top: -106px;
  left: -150px;

  &.hazard-icon-news {
    top: -70px;
    left: -125px;
  }
}

.content-container-outer, .content-container {
  height: 100%;
  width: 100%;
}

.content-container {
  padding-right: 20px;
}

.content-container-outer {
  height: 420px;
  padding: 10px;
}

.button-close {
  position: absolute;
  top: 0;
  right: 0;
}

.content-container-inner {
  &.content-container-vertical-align {
    position: absolute;
    top: 50%;
    width: 935px;
    transform: translateY(-50%);
  }
}

.btn-prioritize {
  .flag-icon {
    position: absolute;
    top: -70px;
    right: -50px;
    transform: scale(80%);
  }

  &.long-button-disabled {
    .flag-icon {
      filter: grayscale(100%);
      opacity: .5;
    }
  }
}
</style>



<style lang="scss">
.speech-bubble {
  .markdown-text {
    strong {
      color: #CD5864;
    }
  }
}
</style>
