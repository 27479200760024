<template>
  <version-container :attach-corner="true" :display-session-info="false" />
  <div class="leaderboard-full">
    <lobby-popup-template :show-footer="false">
      <template v-slot:header>
        {{ $t('leaderboard.header') }}
        <app-button @click="onClickGoBack" color="blue" class="header-button" width="lg">{{ $t('leaderboard.buttonGoBack') }}</app-button>
      </template>
      <template v-slot:content>
        <span v-if="loading" class="loading">
          {{ $t('leaderboard.loading') }}
        </span>
        <div v-else class="leaderboard-container epic-scrollbar">
          <leaderboard :ranks="leaderboardRanks" />
        </div>
      </template>
    </lobby-popup-template>
  </div>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton.vue'

import IntroPageData from '@/lib/intro-pages'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import MediaContainer from '@/components/common/media/MediaContainer.vue'

import IntroImg1 from '@/assets/image-library/intro-img-1.png';
import IntroImg2 from '@/assets/image-library/intro-img-2.png';
import IntroImg3 from '@/assets/image-library/intro-img-3.png';
import IntroLogo from '@/assets/image-library/intro-logo.png';
import { mapState } from 'vuex'
import Leaderboard from '@/components/Leaderboard/index.vue';
import {generateRandomDate, generateTeamName} from '@/utils/generator';
import {randomBetween} from '@/utils/randomUtil';
import {objectToArray} from '@/utils/arrayUtil';
import {generatePlayersData} from '@/utils/playerDataGenerator';
import {formatDate} from '@/utils/dateUtil';
import VersionContainer from '@/components/common/VersionContainer.vue'

export default {
  name: 'LeaderboardView',
  components: {
    VersionContainer,
    Leaderboard,
    MarkdownText,
    LongButton,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    MediaContainer,
  },
  mounted() {
    // They haven't seen the intro yet - be gone!
    this.$store.commit('background', 'game');
    this.$store.commit('backgroundBlingTop', true);
    this.$store.commit('backgroundBlingBottom', true);

    this.$apiClient.leaderboard.getLeaderboardRanks().then((leaderboardRanks) => {
      const leaderboardRanksParsed = [];

      for (const rank of leaderboardRanks) {
        leaderboardRanksParsed.push({
          position: rank.position,
          teamName: rank.teamName,
          finalScore: rank.finalScore,
          date: formatDate(new Date(rank.date)),
          teamMembers: rank.teamMembers,
        });
      }

      this.leaderboardRanks = leaderboardRanksParsed;

      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,

      leaderboardRanks: [],
    }
  },
  methods: {
    onClickGoBack() {
      this.$router.push('/lobby');
    }
  }
}
</script>

<style lang="scss" scoped>
.leaderboard-full {
  padding: 50px 290px 50px 290px;
}

.center-page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  p {
    font-size: 16pt;
  }
}

.loading {
  font-size: 16pt;
  color: white;
}

.header-button {
  position: absolute;
  top: 13px;
  left: 13px;
}

.leaderboard-container {
  height: 780px;
}
</style>
