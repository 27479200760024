<template>
  <div class="aspect-ratio-container">
    <div class="aspect-ratio-container-scaler" :style="computedScalerStyle">
      <div class="aspect-ratio-container-app" :style="computedAppStyle">
        <slot />

        <div class="aspect-ratio-container-negate" :style="computedNegateStyle">
          <slot name="negate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AspectRatioContainer',
  props: {
    aspectWidth: {
      type: Number,
    },
    aspectHeight: {
      type: Number,
    },
    useScaler: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      scale: 100,
    }
  },
  watch: {
    scale() {
      this.updateStoreScale();
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);

    this.onResize();

    this.updateStoreScale();
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    updateStoreScale() {
      this.$store.commit('aspectRatioScale', this.scale);
    },
    onResize() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const currentCalculatedAspectRatioWH = windowWidth / windowHeight;

      if (currentCalculatedAspectRatioWH > this.aspectRatioWH) {
        // Black bars on top and bottom
        this.scale = (windowHeight / this.aspectHeight);
      } else {
        // Black bars on side
        this.scale = (windowWidth / this.aspectWidth);
      }
    },
  },
  computed: {
    computedNegateStyle() {
      const negateScale = 1 - this.scale;

      const offsetX = (this.aspectWidth / negateScale) / 2;
      const offsetY = (this.aspectHeight / negateScale) / 2;

      return {
        'transform': `scale(${negateScale}) translate(${offsetX}px, ${offsetY}px)`,
      }
    },
    computedAppStyle() {
      return {
        'width': this.aspectWidth + 'px',
        'height': this.aspectHeight + 'px',
      }
    },
    computedScalerStyle() {
      if (!this.useScaler) {
        return {
          'transform': `scale(1) translate(-50%, -50%)`,
        }
      }

      const offsetX = (this.aspectWidth / this.scale) / 2;
      const offsetY = (this.aspectHeight / this.scale) / 2;

      return {
        'transform': `scale(${this.scale}) translate(-${offsetX}px, -${offsetY}px)`,
      }
    },
    aspectRatioWH() {
      return this.aspectWidth / this.aspectHeight;
    },
  }
}
</script>

<style lang="scss" scoped>
.aspect-ratio-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.aspect-ratio-container-scaler {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}
</style>
