<template>
  <div class="analytics-container">
    <div v-if="loading">Loading..</div>
    <div v-else-if="eventData">
      <meeting-analytics
        type="event"
        :event-data="eventData"
        :date-time="eventDateTime"
        :implemented-actions="allImplementedActions"
        :all-actions="allActions"
      />
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue'
import Sidebar from '@/components/Admin/Sidebar.vue'
import { mapState } from 'vuex'
import Accordion from '@/components/common/Accordion.vue'
import AdminUserRow from '@/components/Admin/AdminUserRow.vue'
import DashboardAction from '@/components/dashboard/DashboardAction.vue'
import { ensureArray } from '@/utils/arrayUtil'
import StatBar from '@/components/player-ui/StatBar.vue'
import MeetingAnalytics from '@/components/MeetingAnalytics/index.vue';
import {formatDateTime} from '@/utils/dateUtil';

export default {
  name: 'EventAnalytics',
  components: {
    MeetingAnalytics,
    StatBar,
    DashboardAction,
    AdminUserRow,
    Accordion,
    Sidebar,
    AppButton,
  },
  data () {
    return {
      // Rooms
      rooms: [],
      eventData: null,
      allActions: null,

      loading: false,

      eventDateTime: '',
    }
  },
  computed: {
    ...mapState(['signedInUser']),
    allImplementedActions () {
      if (!this.eventData) {
        console.warn('Cannot get implemented actions as no event')

        return;
      }

      const rooms = this.eventData.rooms || [];

      if (!rooms.length) {
        return [];
      }

      let allImplementedActions = []

      console.log('eventData', this.eventData);
      console.log('roomsx', rooms);

      if (this.eventData && this.eventData.rooms) {
        for (const eventRoom of this.eventData.rooms) {
          if (!eventRoom) {
            continue;
          }

          const roomData = this.rooms.find((room) => {
            return room.id === eventRoom.id;
          });

          if (roomData) {
            if (!roomData.saveData) {
              continue; // The room has no save data
            }

            if (roomData.saveData?.gameData?.implementedActions === null) {
              continue; // The room save data had no implemented actions
            }

            const implementedActionsEnsure = ensureArray(roomData.saveData.gameData.implementedActions);

            for (const implementedAction of implementedActionsEnsure) {
              allImplementedActions.push(implementedAction);
            }
          }
        }
      }

      return allImplementedActions
    },
    eventId() {
      return this?.$route?.params?.id;
    },
  },
  mounted () {
    if (!this.signedInUser) {
      this.$router.push({ name: 'BackendIndex' })

      return;
    }

    this.$nextTick(async () => {
      this.loading = true;

      await this.loadRooms();
      await this.loadEvent();
      await this.loadAllActions();

      this.loading = false;
    })
  },
  methods: {
    getEventDateTime() {
      let lastSessionAtMax = null;

      if (this.eventData && this.eventData.rooms) {
        for (const eventRoom of this.eventData.rooms) {
          if (!lastSessionAtMax) {
            lastSessionAtMax = new Date(eventRoom.updatedAt);
          }

          const roomData = this.rooms.find((room) => {
            return room.id === eventRoom.id;
          });

          if (roomData) {
            const lastSessionAt = new Date(roomData.lastSessionAt);

            if (lastSessionAtMax < lastSessionAt) {
              lastSessionAtMax = lastSessionAt;
            }
          }
        }
      }

      return lastSessionAtMax;
    },
    async loadAllActions() {
      return this.$apiClient.mission.getAllActions().then((actions) => {
        console.log('MISSIONS', actions);

        this.allActions = actions;
      });
    },
    async loadEvent () {
      if (!this.eventId) {
        throw new Error(`Could not find event id in params`);
      }

      return this.$apiClient.backend.getEvent(this.eventId).then((event) => {
        this.eventData = event

        this.eventDateTime = this.getEventDateTime();
      })
    },
    async loadRooms () {
      return this.$apiClient.facilitator.getRooms(this.liveRoomsOnly, this.signedInUser.role === 'facilitator').then((rooms) => {
        this.rooms = rooms
      });
    },

  },
}
</script>

<style lang="scss" scoped>
.backend-container {
  padding: 0;
}
</style>
