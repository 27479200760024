<template>
  <lightbox background="#000000" :background-opacity=".5">
    <hazard-popup-flag-management
      :world-hazards="worldHazards"
      :player-prioritizations="playerPrioritizations"

      :can-add-flags="canAddFlags"

      :type="type"

      :investigated-world-hazard-ids="investigatedWorldHazardIds"
      :self-flagged-world-hazard-ids="selfFlaggedWorldHazardIds"

      @click:cancel="onClickCancel"
      @click:removeFlag="onClickRemoveFlag"
      @click:addFlag="onClickAddFlag"
      @click:swapFlag="onClickSwapFlag"
      @click:inspectFlag="onClickInspectFlag"
    />
  </lightbox>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'
import { generateFakeHazard1, generateFakeHazard2 } from '@/utils/generator'
import { mapGetters, mapState } from 'vuex'
import Lightbox from '@/components/common/Lightbox.vue'
import { objectToArray } from '@/utils/arrayUtil'
import { getWorldHazardById } from '@/utils/hazardUtil'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue';

export default {
  name: 'HazardPopupFlagManagementLive',
  components: {HazardPopupPrioritization, Lightbox, HazardPopupFlagManagement },

  props: {
    type: {
      type: String,
      required: false,
      default: 'swap', // swap or manage
    },
  },
  emits: ['cancel', 'click:inspectFlag'],
  computed: {
    ...mapState(['room', 'hazardMeetingOpenId', 'hazardMeetingForceState']),
    ...mapGetters(['playerSelf']),
    worldHazardsAll() {
      if (!this.room || !this.room.worldHazards) {
        return [];
      }

      return objectToArray(this.room.worldHazards);
    },
    investigatedWorldHazardIds() {
      if (!this.playerSelf || !this.playerSelf.investigatedHazardIds) {
        return [];
      }

      return objectToArray(this.playerSelf.investigatedHazardIds);
    },
    selfFlaggedWorldHazardIds() {
      if (!this.playerSelf || !this.playerSelf.worldHazardFlags) {
        return [];
      }

      const worldHazardFlags = objectToArray(this.playerSelf.worldHazardFlags);

      const worldHazardFlagIds = [];

      for (const worldHazardFlag of worldHazardFlags) {
        worldHazardFlagIds.push(worldHazardFlag.hazardId);
      }

      return worldHazardFlagIds;
    },
    worldHazards() {
      const worldHazardIdsAll = [];

      for (const playerPrioritization of this.playerPrioritizations) {
        if (!playerPrioritization.hazardPrioritizationCount) {
          continue;
        }

        const worldHazardIdsPlayer = Object.keys(playerPrioritization.hazardPrioritizationCount).map(id => parseInt(id, 10));

        if (!worldHazardIdsPlayer) {
          continue;
        }

        for (const worldHazardId of worldHazardIdsPlayer) {
          if (worldHazardIdsAll.includes(worldHazardId)) {
            continue;
          }

          worldHazardIdsAll.push(worldHazardId);
        }
      }

      console.log('worldHazardIdsAll', worldHazardIdsAll);

      return this.worldHazardsAll
        .filter(worldHazard => {
          return worldHazardIdsAll.includes(worldHazard.id)
        })
    },
    canAddFlags() {
      return this.playerSelf && this.playerSelf.flagCount > 0;
    },

    playerPrioritizations() {
      const playerPrioritizations = [];

      const playerIds = Object.keys(this.room.players);

      for (const playerId of playerIds) {
        const player = this.room.players[playerId];

        const hazardPrioritizationCount = {};

        const worldHazardFlags = objectToArray(player.worldHazardFlags);

        for (const worldHazardFlag of worldHazardFlags) {
          const hazardIdString = worldHazardFlag.hazardId.toString();

          if (!hazardPrioritizationCount[hazardIdString]) {
            hazardPrioritizationCount[hazardIdString] = 0;
          }

          hazardPrioritizationCount[hazardIdString] += worldHazardFlag.amount;
        }

        playerPrioritizations.push({
          player: player,
          hazardPrioritizationCount: hazardPrioritizationCount,
        });
      }

      return playerPrioritizations;
    },
  },
  methods: {
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickClose() {
      this.$emit('close');
    },
    onClickAddFlag(worldHazard) {
      console.log('addWorldHazardFlag', worldHazard);

      this.$gameClient.playerCallMethod('addWorldHazardFlag', { hazardId: worldHazard.id }).then(() => {
        this.onClickCancel();
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickRemoveFlag(worldHazard) {
      console.log('removeWorldHazardFlag', worldHazard);

      this.$gameClient.playerCallMethod('removeWorldHazardFlag', { hazardId: worldHazard.id }).then(() => {
        this.onClickCancel();
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickSwapFlag(worldHazardOld) {
      let worldHazardNew = getWorldHazardById(this.worldHazardsAll, this.hazardMeetingOpenId);

      if (!worldHazardOld) {
        console.log('Could not swap hazards as worldHazardOld is empty', {
          worldHazardOld: worldHazardOld,
          worldHazardNew: worldHazardNew,
          worldHazardOldJson: JSON.stringify(worldHazardOld),
          worldHazardNewJson: JSON.stringify(worldHazardNew),
          hazardMeetingOpenId: this.hazardMeetingOpenId,
        });

        return;
      }

      if (!worldHazardNew) {
        console.log('Could not swap hazards as worldHazardNew is empty', {
          worldHazardOld: worldHazardOld,
          worldHazardNew: worldHazardNew,
          worldHazardOldJson: JSON.stringify(worldHazardOld),
          worldHazardNewJson: JSON.stringify(worldHazardNew),
          hazardMeetingOpenId: this.hazardMeetingOpenId,
        });

        return;
      }

      this.$gameClient.playerCallMethod('swapWorldHazardFlag', { oldHazardId: worldHazardOld.id, newHazardId: worldHazardNew.id }).then(() => {
        this.onClickClose();
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickInspectFlag(worldHazard) {
      this.$emit('click:inspectFlag', worldHazard);
    }
  },
}
</script>
