<template>
  <json-viewer
    :value="room"
    :expand-depth="5"
    sort
    class="json-viewer"/>
</template>

<script>
import { mapState } from 'vuex'
//import JsonViewer from 'vue-json-viewer'

export default {
  name: 'StateViewer',
  components: {
    //JsonViewer,
  },
  computed: {
    ...mapState(['room'])
  }
}
</script>

<style lang="scss" scoped>
.json-viewer {
  height: 100%;
  text-align: left;
  font-size: 11px;
}
</style>
