<template>
  <lightbox background="#000000" :background-opacity=".5" :push-top-ui="true">

    <hazard-popup-intro
      v-if="computedState === 'intro'"

      :type="type"
      :title="title"
      :icon="icon"

      :hazard-text="introText"
      :speaker="introSpeaker"
      :prioritization-cost="summaryPrioritizationCost"
      :can-prioritize="summaryCanPrioritize"
      :is-prioritized="summaryIsPrioritized"
      :inspect-cost="summaryInspectCost"
      :can-interact="canInteract"

      @click:cancel="onClickCancel"
      @click:prioritize="onClickPrioritize"
      @click:unprioritize="onClickUnprioritize"
      @click:learnMore="onClickLearnMore"
      @open:flagManagement="onOpenFlagManagement"
    />

    <hazard-popup-summary
      v-else-if="computedState === 'summary'"

      :title="title"
      :icon="icon"

      :investigation-cost="introInvestigationCost"
      :text="summaryText"
      :media-source="summaryMediaSource"
      :media-caption="summaryMediaCaption"
      :type="type"
      :can-interact="canInteract"

      @click:cancel="onClickCancel"
      @click:investigate="onClickInvestigate"
      @open:flagManagement="onOpenFlagManagement"
    />

    <hazard-popup-prioritization
      :hazard-datas="futurePrioritizedHazardMeetings"
      v-else-if="computedState === 'prioritization'"
      :player-prioritizations="playerPrioritizations"
      :can-interact="canInteract"
    />

    <meeting-popup
      v-else-if="computedState === 'meeting'"
      meeting-type="hazard"
      :action-categories="hazardMeetingActionCategories"
      :investments="investments"
      :tab-lookers="tabLookers"
      :players="players"
      :meeting-number="meetingNumber"
      :hazard-icon="activeHazardMeeting ? activeHazardMeeting.icon : null"
      @action-removed="onActionRemoved"
      @action-added="onActionAdded"
      @looking-at-category-change="onLookingAtCategoryChange"
      @looking-at-tab-change="onLookingAtTabChange"
      :can-interact="canInteract"
    />

    <meeting-results-popup
      v-else-if="computedState === 'results'"
      meeting-type="hazard"
      :meeting-number="meetingNumber"
      :players="players"
      :action-categories="hazardMeetingActionCategories"
      :investments="investments"
      :can-interact="canInteract"
    />
  </lightbox>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import HazardPopupSummary from '@/components/HazardMeeting/HazardPopupSummary/index.vue'
import HazardPopupIntro from '@/components/HazardMeeting/HazardPopupIntro/index.vue'
import HazardPopupLive from '@/components/HazardMeeting/HazardPopup/HazardPopupLive.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import {
  HAZARD_MEETING_STATE_ENDED,
  HAZARD_MEETING_STATE_STARTED,
  HAZARD_MEETING_STATE_STARTING
} from '@/enum/HazardMeetingState'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue';
import { objectToArray } from '@/utils/arrayUtil'
import HazardPopupResults from '@/components/HazardMeeting/HazardPopupResults/index.vue'
import {MEETING_STATE_HAZARD_MEETING, MEETING_STATE_HAZARD_MEETING_PRIORITY} from '@/enum/MeetingState';
import { generatePlayerData } from '@/utils/playerDataGenerator'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue';
import { getWorldHazardById } from '@/utils/hazardUtil'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'HazardMeetingLive',
  components: {
    MeetingPopup,
    MeetingResultsPopup,
    HazardPopupResults,
    HazardPopupPrioritization,
    Lightbox,
    HazardPopupSummary,
    HazardPopupIntro,
    HazardPopupLive,
  },
  data() {
    return {
      state: 'intro',
    };
  },
  watch: {
    activeHazardMeeting() {
      if (!this.activeHazardMeeting) {
        this.$emit('close');
      }
    },
    computedState() {
      this.onComputedStateChanged();
    }
  },
  emits: ['close', 'open:flagManagement'],
  computed: {
    ...mapState(['room', 'hazardMeetingOpenId', 'hazardMeetingOpenState']),
    ...mapGetters(['playerSelf', 'playerType', 'playerIsFacilitator']),

    meetingNumber() {
      if (!this.room || !this.room.meetingNumber) {
        return 0;
      }

      return this.room.meetingNumber;
    },

    summaryInspectCost() {
      return 0; // Not used any more ??
    },

    canInteract() {
      return !this.playerIsFacilitator;
    },

    playerPrioritizations() {
      const playerPrioritizations = [];

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];
        const worldHazardFlags = objectToArray(player.worldHazardFlags);

        const hazardPrioritizationCounts = {};

        let hazardPrioritizationCount = 0;

        const existingWorldHazardIds = [];

        for (const worldHazardFlag of worldHazardFlags) {
          const worldHazardId = worldHazardFlag.hazardId.toString();

          if (!existingWorldHazardIds.includes(worldHazardId)) {
            hazardPrioritizationCount++;
            hazardPrioritizationCounts[worldHazardId] = 0;

            existingWorldHazardIds.push(worldHazardId);
          }

          hazardPrioritizationCounts[worldHazardId] += worldHazardFlag.amount;
        }

        if (hazardPrioritizationCount <= 0) {
          continue;
        }

        playerPrioritizations.push({
          player: player,
          hazardPrioritizationCount: hazardPrioritizationCounts
        });
      }

      return playerPrioritizations;
    },

    players() {
      if (!this.room || !this.room.players) {
        return {};
      }

      return this.room.players;
    },
    tabLookers() {
      if (!this.players) {
        return {};
      }

      const tabLookers = {};
      const tabLookerIds = [];

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.meetingLookingAtTabIdentifier) {
          const tabIdentifier = player.meetingLookingAtTabIdentifier;

          if (!tabLookerIds.includes(tabIdentifier)) {
            tabLookerIds.push(tabIdentifier);
            tabLookers[tabIdentifier] = [];
          }

          tabLookers[tabIdentifier].push(player);
        }
      }

      return tabLookers;
    },
    investments() {
      const activeHazardMeeting = this.activeHazardMeeting;

      if (!activeHazardMeeting) {
        return [];
      }

      if (!activeHazardMeeting.investments) {
        return [];
      }

      const investments = objectToArray(activeHazardMeeting.investments);

      const parsedInvestments = [];

      for (const investment of investments) {
        parsedInvestments.push({
          actionIdentifier: investment.action.identifier,
          playerCode: investment.addedByPlayerCode,
          investment: investment.action.cost, // In strategic meetings you are the sole investor
        });
      }

      console.log('parsedInvestments', parsedInvestments);

      return parsedInvestments;
    },
    hazardMeetingActionCategories() {
      const activeHazardMeeting = this.activeHazardMeeting;

      if (!activeHazardMeeting) {
        return [];
      }

      if (!activeHazardMeeting.categories) {
        return [];
      }

      return objectToArray(activeHazardMeeting.categories);
    },

    summaryIsPrioritized() {
      const hazardId = this.hazardMeetingData ? this.hazardMeetingData.id : null;

      let isPrioritized = false;

      if (hazardId && this.playerSelf && this.playerSelf.worldHazardFlags) {
        const worldHazardFlagKeys = Object.keys(this.playerSelf.worldHazardFlags);
        const hazardFlagsCount = worldHazardFlagKeys.length;

        if (hazardFlagsCount > 0) {
          for (const worldHazardFlagKey of worldHazardFlagKeys) {
            const worldHazardFlag = this.playerSelf.worldHazardFlags[worldHazardFlagKey];

            if (worldHazardFlag.hazardId === hazardId) {
              isPrioritized = true;
            }
          }
        }
      }

      return isPrioritized;
    },

    prioritizedHazardMeetings() {
      if (!this.room.activeHazardMeeting) {
        return [];
      }

      if (!this.room.activeHazardMeeting.prioritizedWorldHazards) {
        return [];
      }

      return objectToArray(this.room.activeHazardMeeting.prioritizedWorldHazards, true);
    },

    futurePrioritizedHazardMeetings() {
      return this.prioritizedHazardMeetings.slice(this.room.activeHazardMeeting.currentHazardIndex);
    },

    investedActions() {
      if (!this.activeHazardMeeting) {
        return [];
      }

      if (!this.activeHazardMeeting.summary) {
        return [];
      }

      if (!this.activeHazardMeeting.summary.actionsInvested) {
        return [];
      }

      return this.activeHazardMeeting.summary.actionsInvested;
    },

    hazardMeetingData() {
      if (this.activeHazardMeeting) {
        console.log(`Already have active hazard meeting`);
        return this.activeHazardMeeting;
      }

      if (this.hazardMeetingOpenId === null || this.hazardMeetingOpenId === '') {
        console.log(`Hazard open id is empty`);

        return null;
      }

      if (!this.room.worldHazards) {
        console.log(`Could not find world hazards`);

        return null;
      }

      const worldHazard = getWorldHazardById(this.room.worldHazards, this.hazardMeetingOpenId);

      if (!worldHazard) {
        console.log(`Could not find world hazard by key ${this.hazardMeetingOpenId}`);

        return null;
      }

      return worldHazard;
    },

    activeHazardMeeting() {
      if (!this.room) {
        return null;
      }

      if (!this.room.activeHazardMeeting) {
        return null;
      }

      const activeHazardIndex = this.room.activeHazardMeeting.currentHazardIndex;

      if (typeof(activeHazardIndex) === 'undefined' || activeHazardIndex === null) {
        return null;
      }

      if (activeHazardIndex < 0) {
        return null;
      }

      const activeHazardIndexString = activeHazardIndex.toString();

      if (!this.room.activeHazardMeeting.prioritizedWorldHazards[activeHazardIndexString]) {
        return null;
      }

      return this.room.activeHazardMeeting.prioritizedWorldHazards[activeHazardIndexString];
    },

    hazardMeetingState() {
      if (!this.room) {
        return null;
      }

      return this.room.hazardMeetingState;
    },

    meetingState() {
      if (!this.room) {
        return null;
      }

      return this.room.meetingState;
    },

    computedState() {
      if (this.hazardMeetingOpenState) {
        return this.hazardMeetingOpenState;
      }

      if (this.meetingState === MEETING_STATE_HAZARD_MEETING) {
        if (this.hazardMeetingState === HAZARD_MEETING_STATE_STARTING) {
          // Show all queued priorities
          return 'prioritization';
        } else if (this.hazardMeetingState === HAZARD_MEETING_STATE_ENDED) {
          return 'results';
        } else {
          return 'meeting';
        }
      }

      return this.state;
    },



    // Generic
    title() {
      if (!this.hazardMeetingData) {
        return '';
      }

      return this.hazardMeetingData.title;
    },
    type() {
      if (!this.hazardMeetingData) {
        return '';
      }

      return this.hazardMeetingData.type;
    },
    icon() {
      if (!this.hazardMeetingData) {
        return '';
      }

      return this.hazardMeetingData.icon;
    },
    type() {
      if (!this.hazardMeetingData) {
        return '';
      }

      return this.hazardMeetingData.type;
    },

    // Intro
    introInvestigationCost() {
      if (!this.hazardMeetingData) {
        return null;
      }

      let investigatedHazardIds = [];

      if (this.playerSelf.investigatedHazardIds && this.playerSelf.investigatedHazardIds) {
        investigatedHazardIds = objectToArray(this.playerSelf.investigatedHazardIds);
      }

      if (investigatedHazardIds.length <= 0 || investigatedHazardIds.includes(this.hazardId)) {
        return 0; // We already investigated this
      }

      return this.hazardMeetingData.introInvestigationCost;

      /*if (this.hazardMeetingData.hasBeenInspected) {
        return 0; // It's already been inspected
      }

      return this.hazardMeetingData.introInvestigationCost || 0;*/

    },
    introText() {
      if (!this.hazardMeetingData) {
        return null;
      }

      return this.hazardMeetingData.introText;
    },
    introSpeaker() {
      if (!this.hazardMeetingData) {
        return null;
      }

      return this.hazardMeetingData.introSpeaker;
    },

    // Summary
    summaryText() {
      if (!this.hazardMeetingData) {
        return null;
      }

      return this.hazardMeetingData.summaryText;
    },
    summaryMediaSource() {
      if (!this.hazardMeetingData) {
        return null;
      }

      return this.hazardMeetingData.summaryMediaSource;
    },
    summaryMediaCaption() {
      if (!this.hazardMeetingData) {
        return null;
      }

      return this.hazardMeetingData.summaryMediaCaption;
    },
    summaryPrioritizationCost() {
      if (!this.hazardMeetingData) {
        return 1;
      }

      return this.hazardMeetingData.summaryPrioritizationCost || 1;
    },
    canStartMeeting() {
      if (this.activeHazardMeeting && this.activeHazardMeeting.state) {
        // IF there's already a meeting then no, we can't
        return false;
      }

      return true;
    },
    summaryCanPrioritize() {
      const playerSelf = this.playerSelf;

      if (!playerSelf) {
        return false;
      }

      return playerSelf.flagCount >= this.summaryPrioritizationCost;
    },
    hazardId() {
      if (!this.hazardMeetingData) {
        return null;
      }

      if (!this.hazardMeetingData.id) {
        return null;
      }

      return this.hazardMeetingData.id;
    }
  },
  methods: {
    onComputedStateChanged() {
      if (this.meetingNumber === 1 && this.computedState === 'prioritization') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.HazardMeetingPreMeeting);
      }
    },
    onOpenFlagManagement() {
      this.$emit('open:flagManagement');
    },
    onClose() {
      this.$emit('close');
    },
    onClickInvestigate() {
      if (!this.canStartMeeting) {
        return;
      }

      if (!this.hazardId) {
        return;
      }

      // DEDUCT 1 AP!!!
      this.$gameClient.playerCallMethod('investigateHazard', { hazardId: this.hazardId }).then(() => {
        if (this.state === 'intro') {
          this.onClickCancel();
        } else {
          this.state = 'intro';
        }
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickPrioritize() {
      if (!this.summaryCanPrioritize) {
        return;
      }

      if (!this.hazardId) {
        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerCallMethod('addWorldHazardFlag', { hazardId: this.hazardId }).then(() => {
        this.$emit('close');
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickUnprioritize() {
      const hazardId = this.hazardMeetingData ? this.hazardMeetingData.id : null;

      if (!hazardId) {
        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerCallMethod('removeWorldHazardFlag', { hazardId: hazardId }).then(() => {
        this.$emit('close');
      }).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onClickLearnMore() {
      console.log('LearnMore');
    },
    onClickCancel() {
      this.$emit('close');
    },
    toggleState() {
      if (this.state === 'intro') {
        this.state = 'summary';
      } else if (this.state === 'summary') {
        this.state = 'meeting';
      } else if (this.state === 'meeting') {
        this.state = 'intro';
      }
    },
    onLookingAtCategoryChange(lookingCategory) {
      if (!lookingCategory) {
        console.warn(`Could not change the looking category since it was NULL`, lookingCategory);

        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('meetingLookingAtCategoryIdentifier', lookingCategory.identifier);
    },
    onLookingAtTabChange(lookingTab) {
      if (!lookingTab) {
        console.warn(`Could not change the looking tab since it was NULL`, lookingTab);

        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('meetingLookingAtTabIdentifier', lookingTab.identifier);
    },


    onActionAdded(action) {
      console.log('onActionAdded', action);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.roomCallMethod('addHazardActionInvestment', action.id).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onActionRemoved(action) {
      console.log('onActionRemoved', action);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.roomCallMethod('removeHazardActionInvestment', action.id).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
  },
  mounted() {
    this.onComputedStateChanged();
  }
}
</script>

<style lang="scss" scoped>
.inner-content {
  padding-top: 90px;
}
.summary-text {
  color: white;
  text-align: left;
  font-size: 18pt;
  padding: 40px 50px;
}

.media-container {
  padding: 20px;
}
.media-caption {
  color: white;
  margin-top: 10px;
  font-size: 16pt;
  text-align: left;
  padding: 0 20px;
}
</style>
