<template>
  <lobby-popup-template :show-footer="false" :style="{ width: '1300px' }" :content-class="'equal-padding'">
    <template v-slot:header>
      {{ $t('meetingPopup.hazardPrioritizationsHeader') }}
    </template>
    <template v-slot:content>
      <div class="prioritization-hazard-container-outer">
        <div class="prioritization-hazard-container-inner epic-scrollbar">
          <hazard-prioritization-item
            v-for="(hazardData, index) of hazardDatas"
            :hazard-data="hazardData"
            :index="index"
            :is-last="index >= hazardDatasLengthMinusOne"
            :player-prioritizations="playerPrioritizationsAssoc[hazardData.id]"
          />
        </div>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardPrioritizationItem from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPrioritizationItem.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HazardPopupPrioritization',
  components: { HazardPrioritizationItem, FloatingHazardIcon, HazardIcon, LobbyPopupTemplate },
  props: {
    hazardDatas: {
      type: Array,
      required: true,
    },
    playerPrioritizations: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  computed: {
    playerPrioritizationsAssoc() {
      console.log('[playerPrioritizationsAssoc] this.playerPrioritizations', this.playerPrioritizations);

      if (!this.playerPrioritizations) {
        return;
      }

      const playerPrioritizationsAssoc = {};

      /**
       * {
       *   "32": [
       *     {
       *       player: {
       *         playerCode: "111111",
       *         characterIndex: 3
       *       },
       *       flagCount: 2
       *     }
       *   ]
       * }
       */

      for (const playerPrioritization of this.playerPrioritizations) {
        const hazardIds = Object.keys(playerPrioritization.hazardPrioritizationCount);

        for (const hazardId of hazardIds) {
          const flagCount = playerPrioritization.hazardPrioritizationCount[hazardId];

          const playerPrioritizationSingle = {
            player: playerPrioritization.player,
            flagCount: flagCount,
          };

          if (!playerPrioritizationsAssoc[hazardId]) {
            playerPrioritizationsAssoc[hazardId] = [];
          }

          playerPrioritizationsAssoc[hazardId].push(playerPrioritizationSingle);
        }
      }

      return playerPrioritizationsAssoc;
    },
    hazardDatasLengthMinusOne() {
      return this.hazardDatas.length - 1;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.prioritization-hazard-container-outer {
  width: 100%;
}

.prioritization-hazard-container-inner {
  height: 100%;
  overflow-y: auto;
  max-height: $appHeight * 0.45; //45vh;
  padding: 0 30px;
}
</style>
