export const EVENT_INPUT_DEVICES_UPDATED = 'inputDevicesUpdated';

export class InputDevicesUpdatedEvent extends Event {
  inputDevices;

  constructor(inputDevices) {
    super(EVENT_INPUT_DEVICES_UPDATED);

    this.inputDevices = inputDevices;
  }
}
