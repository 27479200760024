<template>
  <div class="meeting-action-container" :class="computedContainerClass">
    <div class="meeting-action" :class="computedClass">
      <div class="meeting-action-outer">
        <div class="meeting-action-inner d-flex flex-column">
          <div class="flex-grow-1 p-1">
            <div class="content-container" :class="showFeedbackButton ? 'content-container-with-feedback' : ''">
              {{ text }}
            </div>
            <div class="feedback-button-container" v-if="showFeedbackButton">
              <app-button color="blue" @click.prevent.stop="toggleFeedback" height="icon-lg" width="icon-lg"
                          :state="feedbackIsActive ? 'active' : ''">
                <no-drag-image src="@/assets/icons/eye.png"/>
              </app-button>
            </div>
          </div>
          <div class="footer-container flex-grow-0 d-flex" v-if="meetingType === 'strategic' || meetingType === 'hazard'">
            <div class="cost flex-grow-1">
              Cost: {{ cost }}
            </div>
            <div class="action" v-if="showActions && !showFeedbackButton">
              <app-button color="green" @click.prevent.stop="onAdd" height="md" text-size="md" width="fit-content"
                          v-if="!isAdded" :disabled="!canInteract">
                <img src="@/assets/icons/icon-add.png" class="me-2"/> <label class="align-self-center">{{ $t('meetingPopup.buttonAdd') }}</label>
              </app-button>
              <app-button color="red" @click.prevent.stop="onRemove" height="md" text-size="md" width="fit-content"
                          v-else :disabled="!canInteract">
                <img src="@/assets/icons/icon-remove.png" class="me-2"/> <label class="align-self-center">{{ $t('meetingPopup.buttonRemove') }}</label>
              </app-button>
            </div>
          </div>
          <div class="footer-container flex-grow-0 d-flex footer-container-no-padding gap-4"
               v-else-if="meetingType === 'hazard'">
            <div class="investment" v-if="showActions">
              <meeting-popup-action-investment
                class="popup-action-investment"
                :action-identifier="actionIdentifier"
                :investment-cost="cost"
                :total-investment="totalInvestment"
                :player-investment="playerInvestment"
                :player-points="playerPoints"
                :can-interact="canInteract"
                @update:player-investment="onUpdatePlayerInvestment"
              />
            </div>
            <div class="cost flex-grow-1">
              {{ $t('meetingPopup.cost') }}: {{ cost }}
            </div>
        <!--
            <div class="investors">
              <div
                class="meeting-popup-action-investors d-flex align-items-stretch flex-nowrap justify-content-between">
                <div class="meeting-popup-action-investor" v-for="playerIndex in playerIndexes">
                  <div class="meeting-popup-action-investor-inner" v-if="investments.length > playerIndex">
                    <character-thumbnail
                      :character-index="players[investments[playerIndex].playerCode].characterIndex"
                      size="tiny"
                    />

                    <cost-box size="sm">{{ investments[playerIndex].investment }}</cost-box>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import MeetingPopupActionInvestment from '@/components/MeetingPopup/MeetingPopupActionInvestment.vue'
import CostBox from '@/components/common/CostBox.vue'
import { mapGetters } from 'vuex'
import { objectToArray } from '@/utils/arrayUtil'

export default {
  name: 'MeetingPopupAction',
  props: {
    action: {
      type: Object,
      required: true,
    },
    meetingType: {
      type: String,
      required: true,
    },
    isAdded: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      required: true,
      default: false,
    },
    showFeedbackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    feedbackIsActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    investments: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    players: {
      type: Object,
      required: true,
    },
    highlightPlayerAoe: {
      type: Boolean,
      required: false,
      default: false,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    CostBox,
    CharacterThumbnail,
    NoDragImage,
    AppButton,
    MeetingPopupActionInvestment
  },
  emits: ['add', 'remove', 'feedback-show', 'feedback-hide', 'update:player-investment'],
  methods: {
    onUpdatePlayerInvestment (newInvestment) {
      this.$emit('update:player-investment', newInvestment)
    },
    toggleFeedback () {
      if (this.feedbackIsActive) {
        //this.$emit('feedback-hide')
      } else {
        this.$emit('feedback-show')
      }
    },
    onAdd () {
      this.$emit('add')
    },
    onRemove () {
      this.$emit('remove')
    }
  },
  computed: {
    ...mapGetters(['playerSelf']),
    actionAoeIdentifiers () {
      const aoeIdentifiers = []

      if (this.action.aoeImpacts && this.action.aoeImpacts.length) {
        for (const aoeImpact of this.action.aoeImpacts) {
          aoeIdentifiers.push(aoeImpact.identifier)
        }
      }

      return aoeIdentifiers
    },
    isOwnAoe () {
      if (!this.playerSelf) {
        return false
      }

      const aoeIdentifiers = this.actionAoeIdentifiers

      const roleAreasOfExpertise = objectToArray(this.playerSelf.roleAreasOfExpertise);

      if (roleAreasOfExpertise && roleAreasOfExpertise.length > 0) {
        for (const playerAoeIdentifier of roleAreasOfExpertise) {
          if (aoeIdentifiers.includes(playerAoeIdentifier)) {
            return true
          }
        }
      }

      return false
    },
    playerIndexes () {
      return [4, 3, 2, 1, 0]
    },
    playerPoints () {
      for (const playerCode in this.players) {
        const player = this.players[playerCode]

        if (player.isSelf) {
          return player.rolePoints
        }
      }

      return 0
    },
    actionIdentifier () {
      return this.action.identifier
    },
    text () {
      return this.action.text
    },
    cost () {
      if (this.action && this.action.cost) {
        return this.action.cost
      }

      return this.totalInvestment
    },
    totalInvestment () {
      if (!this.investments) {
        return 0
      }

      let totalInvestment = 0

      for (const investment of this.investments) {
        totalInvestment += investment.investment
      }

      return totalInvestment
    },
    playerInvestment () {
      let playerInvestment = 0

      for (const investment of this.investments) {
        const player = this.players[investment.playerCode]

        if (player.isSelf) {
          playerInvestment += investment.investment
        }
      }

      return playerInvestment
    },
    computedClass () {
      const classes = []

      if (this.isAdded) {
        classes.push(`meeting-action-added`)
      }

      if (this.isSelected) {
        classes.push(`meeting-action-selected`)
      }

      classes.push(`meeting-action-${this.meetingType}`)

      if (this.highlightPlayerAoe && this.isOwnAoe) {
        classes.push(`meeting-action-matches-player-aoe`)
      }

      return classes
    },
    computedContainerClass () {
      const classes = []

      if (this.highlightPlayerAoe && this.isOwnAoe) {
        classes.push(`meeting-action-container-matches-player-aoe`)
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
$footerHeight: 50px;
$footerHeightHazard: 80px;

.meeting-action {
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;
  position: relative;

  &.meeting-action-selected {
    box-shadow: 0 0px 15px 5px #A0D530;
  }
}

.meeting-action-container {
  margin-bottom: 20px;

  &.meeting-action-container-matches-player-aoe {
    border: 1px solid #051224;
    box-shadow: 0 -5px 10px 5px #A0D530;
  }
}

.meeting-action-outer,
.meeting-action-inner {
  width: 100%;
  height: 100%;
}

.meeting-action-outer {
  border-radius: 10px;
  overflow: hidden;
}

.meeting-action-inner {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), #000E21;
  box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
  color: white;
}

.cost, .content-container {
  font-size: 16pt;
}

.content-container {
  width: 100%;
  padding: 10px;
  text-align: left;

  &.content-container-with-feedback {
    padding-right: 100px;
  }
}

.footer-container {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), #000E21;
  line-height: $footerHeight;
  height: $footerHeight;
  border-radius: 10px 0px 0px 10px;
  color: white;
  text-align: left;
  padding: 5px 10px;

  &.footer-container-no-padding {
    padding: 0 !important;
  }

  .action {
    position: relative;
    top: 4px;
  }
}

.feedback-button-container {
  position: absolute;
  right: 10px;
  top: 10px;
}

.meeting-action-hazard {
  .footer-container {
    line-height: $footerHeightHazard;
    height: $footerHeightHazard;

    .meeting-popup-action-investor {
      height: 60px;
      width: 60px;
      line-height: 100%;
    }
  }

  .cost {
    padding-left: 10px;
  }
}

.popup-action-investment {
  width: 350px;
}

.meeting-popup-action-investor {
  padding: 3px;
  border-radius: 3px;

  height: 60px;
  width: 60px;
  margin: 10px;

  background: rgba(0, 0, 0, .5);
}

.meeting-popup-action-investor {
  position: relative;

  .cost-box {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
