<template>
  <div class="row">
    <div style="text-align: left; width: 400px; margin: 0 auto">
      <label class="form-label">Role points</label>
      <input class="form-control" type="range" min="0" :max="100" v-model.number="rolePoints"/>
    </div>
  </div>
  <div class="row">
    <div style="text-align: left; width: 400px; margin: 0 auto">
      <label class="form-label">Flag count</label>
      <input class="form-control" type="range" min="-1" :max="99" v-model.number="flagCount"/>
    </div>
  </div>
  <div class="row">
    <div style="text-align: left; width: 400px; margin: 0 auto">
      <label class="form-label">Role</label>
      <bootstrap-select :options="options.role" v-model="roleIndex" />
    </div>
  </div>
  <div class="row">
    <div style="text-align: left; width: 400px; margin: 0 auto">
      <label class="form-label">Character</label>
      <bootstrap-select :options="options.character" v-model="characterIndex" />
    </div>
  </div>

  <div class="player-avatar-circle-dev-container">
    <player-avatar-circle
      :character-index="characterIndexNumber"
      :role-index="roleIndexNumber"
      :role-points="rolePoints"
      :flag-count="flagCount"
    />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import PlayerAvatarCircle from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircle.vue'
import CharacterData from '@/data/character-data'
import fakePlayerData from '@/data/fake-player-data'
import { mapState } from 'vuex'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'PlayerAvatarCircleDev',
  components: {
    PlayerAvatarCircle,
    BootstrapSelect,
  },
  extends: DevComponentContainer,

  data () {
    return {
      characterIndex: '0',
      roleIndex: '0',
      rolePoints: 50,
      flagCount: 3,

      options: {
        role: [],
        character: [],
      }
    }
  },
  mounted() {
    const roleOptions = [];
    const characterOptions = [];

    for (let i = 0, len = this.roles.length; i < len; i++) {
      const role = this.roles[i];

      this.options.role.push({
        label: `${role.name} (${role.icon})`,
        value: i.toString()
      });
    }

    for (let i = 0, len = CharacterData.length; i < len; i++) {
      const characterDataSingle = CharacterData[i];

      this.options.character.push({
        label: characterDataSingle.identifier,
        value: i.toString()
      });
    }
  },
  computed: {
    ...mapState(['roles']),
    characterIndexNumber() {
      return parseInt(this.characterIndex, 10);
    },
    roleIndexNumber() {
      return parseInt(this.roleIndex, 10);
    },
  },
}
</script>

<style lang="scss" scoped>
.player-avatar-circle-dev-container {
  display: inline-block;
  width: fit-content;
  //background: rgba(255, 0, 0, 25%);
}
</style>
