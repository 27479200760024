<template>
  <div class="ui-timer-ready-check" :class="computedClasses">
    <div class="ui-timer-ready-check-tick" />
  </div>
</template>

<script>
import LongButton from '@/components/common/LongButton.vue'
import UiTimer from '@/components/player-ui/UiTimer/UiTimer.vue'
import AppButton from '@/components/common/buttons/AppButton.vue';
import { encodeHtml } from '@/utils/stringUtil'
import {mapGetters} from 'vuex';

export default {
  name: 'UiTimerReadyCheck',
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    computedClasses() {
      return [
        'ui-timer-ready-check-' + (this.modelValue ? 'on': 'off'),
        'ui-timer-ready-check-' + (this.active ? 'active' : 'inactive'),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-timer-ready-check {
  background: url("@/assets/ui/timer/checkmark-background.png") no-repeat;
  background-size: contain;
  width: 33px;
  height: 33px;

  &.ui-timer-ready-check-on {
    .ui-timer-ready-check-tick {
      opacity: 1;
    }
  }

  &.ui-timer-ready-check-inactive {
    opacity: .2;
  }

  .ui-timer-ready-check-tick {
    background: url("@/assets/ui/timer/checkmark-checked.png") no-repeat;
    background-size: contain;
    width: 33px;
    height: 33px;
    opacity: 0;
    transition: 200ms ease-in-out;
  }
}
</style>
