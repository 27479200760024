<template>
  <div class="risk-matrix-switch">
    <div class="headline">
      <label>Switch/Toggle</label>
    </div>
    <div class="buttons">
      <app-button
        color="red"
        width="full"
        height="xl"
        :state="modelValue === RiskMatrixSwitchValue.OLD ? 'active' : ''"
        @click="clickSwitchValue(RiskMatrixSwitchValue.OLD)">
        {{ $t('starMinigameRiskMatrix.switchOld') }}
      </app-button>

      <app-button
        color="blue"
        width="full"
        height="xl"
        :state="modelValue === RiskMatrixSwitchValue.NEW ? 'active' : ''"
        @click="clickSwitchValue(RiskMatrixSwitchValue.NEW)">
        {{ $t('starMinigameRiskMatrix.switchNew') }}
      </app-button>

      <app-button
        color="green"
        width="full"
        height="xl"
        :state="modelValue === RiskMatrixSwitchValue.CORRECT ? 'active' : ''"
        @click="clickSwitchValue(RiskMatrixSwitchValue.CORRECT)">
        {{ $t('starMinigameRiskMatrix.switchCorrect') }}
      </app-button>
    </div>
  </div>
</template>
<script>
import { RiskMatrixSwitchValue } from '@/components/StarMinigame/constants';
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'StarMinigameRiskMatrixSwitch',
  components: {
    AppButton,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: RiskMatrixSwitchValue.OLD,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      RiskMatrixSwitchValue,
    }
  },
  methods: {
    clickSwitchValue(value) {
      this.$emit('update:modelValue', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.risk-matrix-switch {
  width: 250px;
}

.buttons {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.60), 4px 4px 3px 0px rgba(255, 255, 255, 0.40) inset, -5px -5px 4px 0px rgba(0, 0, 0, 0.40) inset;
  padding: 20px;

  .app-button {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.headline label {
  text-align: center;
  font-size: 16pt;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
