<template>
  <div class="cost-box" :class="'cost-box-size-' + size">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CostBox',
  props: {
    size: {
      type: String,
      required: false,
      default: 'md',
    }
  }
}
</script>

<style lang="scss" scoped>
.cost-box {
  background: url("@/assets/textures/texture-20.png"), linear-gradient(180deg, #38708F 0%, #051224 84.07%);
  box-shadow: 0px 3.30859px 3.30859px rgba(0, 0, 0, 0.52), inset 0px 0.827148px 0.827148px rgba(255, 255, 255, 0.5);
  border-radius: 2px;

  padding: 2px 6px 10px 6px;
  text-align: center;
  width: fit-content;
  color: white;
  font-size: 16pt;
  font-weight: bold;

  &.cost-box-size-xs {
    font-size: 10pt;

    min-width: 22px;
    height: 22px;
    line-height: 22px;
  }

  &.cost-box-size-sm {
    font-size: 12pt;

    min-width: 30px;
    height: 30px;
    line-height: 30px;
  }

  &.cost-box-size-md {
    font-size: 14pt;

    min-width: 37px;
    height: 40px;
    line-height: 40px;
  }

  &.cost-box-size-lg {
    font-size: 16pt;
  }

  &.cost-box-size-xl {
    font-size: 22pt;
  }
}
</style>
