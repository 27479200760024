<template>
  <div class="table-column-descriptor" ref="container">
    <div class="descriptor-row"><label :style="labelStyle">{{ row }}</label></div>
    <div class="descriptor-column"><label :style="labelStyle">{{ column }}</label></div>
    <div class="background-container">
      <div class="background" :style="backgroundStyle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableColumnDescriptor',
  props: {
    row: {
      type: String,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    separatorWidth: {
      type: Number,
      required: false,
      default: 2,
    },
    separatorColor: {
      type: String,
      required: false,
      default: '#d9d9d9',
    }
  },
  data() {
    return {
      backgroundStyle: {},
      labelStyle: {},
    }
  },
  mounted() {
    this.recomputeStyle();

    window.addEventListener('resize', this.onResize);
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.recomputeStyle();
    },
    recomputeStyle() {
      this.backgroundStyle = this.getBackgroundStyle();
      this.labelStyle = this.getLabelStyle();
    },
    getCornerRotation() {
      if (!this.$refs['container']) {
        return {};
      }

      const boxWidth = this.$refs['container'].clientWidth;
      const boxHeight = this.$refs['container'].clientHeight;

      const rotation = Math.atan(boxHeight / boxWidth);

      return rotation * (180 / Math.PI);
    },
    getBackgroundStyle() {
      if (!this.$refs['container']) {
        return {};
      }

      return {
        'background': this.separatorColor,
        'height': `${this.separatorWidth}px`,
        'transform': `translate(-50%, -50%) rotate(${this.getCornerRotation()}deg)`,
      };
    },
    getLabelStyle() {
      if (!this.$refs['container']) {
        return {};
      }

      return {
        'transform': `rotate(${this.getCornerRotation()}deg)`,
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.table-column-descriptor {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 150px;
  min-height: 80px;
  background: transparent;
}

.descriptor-row {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: 100;
  width: 50%;
  text-align: center;
}

.descriptor-column {
  position: absolute;
  left: 0;
  bottom: 20%;
  z-index: 100;
  width: 50%;
  text-align: center;
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background {
  width: 200%;
  transform-origin: center center;
  position: absolute;
  left: 50%;
  top: 50%;
}

label {
  transform-origin: center center;
}
</style>
