<template>
  <end-game-popup-decision v-model="currentDecision" :player-votes="playerVotes" :end-game-decision-attempts="endGameDecisionAttempts" />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'
import { mapState } from 'vuex'

export default {
  name: 'EndGamePopupDecisionLive',
  components: {
    EndGamePopupDecision,
  },
  watch: {
    currentDecision() {
      this.$gameClient.playerUpdate('activeEndGameVote', this.currentDecision);
    }
  },

  computed: {
    ...mapState(['room']),
    endGameDecisionAttempts() {
      return this.room.endGameDecisionAttempts || 0;
    },
    playerVotes() {
      const playerVotes = {
        [DECISION_RESTRICT]: [],
        [DECISION_HOLD]: [],
        [DECISION_DELAY]: [],
        [DECISION_CANCEL]: [],
      };

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (!player.activeEndGameVote) {
          continue;
        }

        playerVotes[player.activeEndGameVote].push(player);
      }

      return playerVotes;
    }
  },
  data () {
    return {
      currentDecision: null,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
