<template>
  <div class="container-fluid">
    <div>
      <div class="row">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">UI Slider value</label>: <strong>{{ sliderValue }}</strong>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ui-slider v-model="sliderValue" min="0" max="100" />
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import {selectifyObject} from '@/utils/selectifyUtils'
import UiSlider from '@/components/player-ui/UiSlider.vue'

export default {
  name: 'UiSliderDev',
  components: {
    UiSlider,
  },
  extends: DevComponentContainer,

  data() {
    return {
      sliderValue: 0,
    }
  },
  computed: {
    showVoiceStatusBool() {
      return !!this.showVoiceStatus;
    }
  }
}
</script>
