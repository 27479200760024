export const EVENT_DISCONNECTED = 'disconnected';

export class DisconnectedEvent extends Event {
  reason;

  constructor(reason) {
    super(EVENT_DISCONNECTED);

    this.reason = reason;
  }
}
