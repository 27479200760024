<template>
  <div class="strategic-meeting-popup">
    <strategic-meeting-total-cost class="strategic-meeting-cost" :cost="totalCost" />

    <div class="strategic-meeting-header-container d-flex">
      <div class="picked-actions-container p-3 flex-fill">
        <div class="picked-actions epic-scrollbar d-flex justify-content-start flex-wrap gap-3">
          <strategic-meeting-action
            v-for="action in actionsAdded"
            :cost="action.cost"
            :description="action.text"
            :is-added="true"
            :is-selected="selectedActionIdentifier === action.identifier"
            :can-interact="canInteract"

            @click="selectedActionIdentifier = action.identifier"
            @remove="onActionRemove(action)"
          />
        </div>
      </div>
      <div class="selected-action-container p-3">
        <div class="selected-action" v-if="selectedAction">
          <div class="selected-action-inner d-flex flex-column">
            <div class="selected-action-description flex-fill">
              {{ selectedAction.text }}
            </div>
            <div class="selected-action-footer">
              Cost: {{ selectedAction.cost }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="strategic-meeting-footer-toggle-container p-3">
      <app-button color="green" :state="showActions ? 'active' : 'idle'" width="full" height="lg" text-size="lg" @click="showActions = !showActions" :class="['btn-show-actions', 'btn-show-actions-' + (showActions ? 'active' : 'idle')]">
        <label>Show actions</label>
        <img src="@/assets/icons/triangle-up-hollow.png" class="chevron ms-3" />
      </app-button>
    </div>
    <div class="strategic-meeting-footer-container" v-if="showActions">
      <div class="strategic-meeting-footer d-flex">
        <div class="categories flex-grow-0 p-3">
          <div class="categories-inner epic-scrollbar p-3">
            <div v-for="category of activeCategories" class="mb-3">
              <app-button
                color="orange"
                width="lg"
                height="lg"
                :state="category.identifier === selectedCategoryIdentifier ? 'active' : ''"
                @click="setActiveCategory(category.identifier)"
              >{{ category.title }}</app-button>
            </div>
          </div>
        </div>
        <div class="right-actions flex-grow-1">
          <div class="actions p-3">
            <div class="epic-scrollbar-x d-flex gap-3 p-3 actions-inner">
              <strategic-meeting-action v-for="action in actionsNotAdded" :cost="action.cost" :description="action.text" :can-interact="canInteract" @add="onActionAdd(action)" />
            </div>
          </div>
          <div class="tabs p-3">
            <div class="tabs-inner epic-scrollbar-x p-3">
              <div class="d-flex align-items-stretched justify-content-between gap-3 flex-nowrap">
                <app-button
                  v-for="tab of activeTabs"
                  color="blue"
                  width="lg"
                  height="md"
                  text-size="lg"
                  :state="tab.identifier === selectedTabIdentifier ? 'active' : ''"
                  @click="setActiveTab(tab.identifier)"
                >{{ tab.title }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LongButton from '@/components/common/LongButton.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import StrategicMeetingAction from '@/components/StrategicMeeting/StrategicMeetingAction/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'
import StrategicMeetingTotalCost from '@/components/StrategicMeeting/StrategicMeetingTotalCost/index.vue'
import { objectToArray } from '@/utils/arrayUtil'
import { mapState } from 'vuex'

export default {
  name: 'StrategicMeetingPopup',
  components: {
    StrategicMeetingTotalCost,
    ToggleAppButton,
    StrategicMeetingAction,
    Lightbox,
    NoDragImage,
    HazardPopupContainer,
    AppButton,
  },
  props: {
    actionCategories: {
      type: Array,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedCategoryIdentifier: null,
      selectedTabIdentifier: null,
      selectedActionIdentifier: null,

      //addedActionIdentifiers: [],

      showActions: false,

      actionIdentifierCategoryTabs: {},

      categories: {},
      tabs: {},
      actions: {},

      categoryIdentifiers: [],
      tabIdentifiers: [],
      actionIdentifiers: [],

      currentCost: 10,
    };
  },
  computed: {
    ...mapState(['room']),
    totalCost() {
      if (!this.room || !this.room.activeStrategicMeeting) {
        return 0;
      }

      const investments = objectToArray(this.room.activeStrategicMeeting.investments);

      if (!investments.length) {
        return 0;
      }

      let totalCost = 0;

      for (const investment of investments) {
        totalCost += investment.action.cost;
      }

      return totalCost;
    },
    activeCategories() {
      const categories = [];

      for (let categoryIdentifier of this.categoryIdentifiers) {
        categories.push(this.categories[categoryIdentifier]);
      }

      return categories;
    },
    activeTabs() {
      const tabs = [];

      const selectedCategory = this.selectedCategory;

      if (selectedCategory) {
        for (let tabIdentifier of selectedCategory.tabIdentifiers) {
          tabs.push(this.tabs[tabIdentifier]);
        }
      }

      return tabs;
    },

    actionsNotAdded() {
      const selectedTab = this.selectedTab;

      if (!selectedTab) {
        return [];
      }

      const actions = [];

      for (const actionIdentifier of selectedTab.actionIdentifiers) {
        if (this.addedActionIdentifiers.indexOf(actionIdentifier) < 0) {
          actions.push(this.actions[actionIdentifier]);
        }
      }

      return actions;
    },

    actionsAdded() {
      const actions = [];

      for (const actionIdentifier of this.addedActionIdentifiers) {
        actions.push(this.actions[actionIdentifier]);
      }

      return actions;
    },

    selectedCategory() {
      if (!this.selectedCategoryIdentifier) {
        return null;
      }

      return this.categories[this.selectedCategoryIdentifier];
    },
    selectedTab() {
      if (!this.selectedTabIdentifier) {
        return null;
      }

      return this.tabs[this.selectedTabIdentifier];
    },
    selectedAction() {
      if (!this.selectedActionIdentifier) {
        return null;
      }

      return this.actions[this.selectedActionIdentifier];
    },
    addedActionIdentifiers() {
      if (!this.room || !this.room.activeStrategicMeeting) {
        return [];
      }

      const investments = objectToArray(this.room.activeStrategicMeeting.investments);

      if (!investments.length) {
        return [];
      }

      const actionIds = [];

      for (const investment of investments) {
        actionIds.push(investment.action.identifier);
      }

      return actionIds;
    }
  },
  emits: ['continue'],
  watch: {
    actionCategories() {
      this.recalculateDataFromCategories();
    },
    selectedCategoryIdentifier() {
      if (!this.selectedCategory) {
        return;
      }

      this.selectedTabIdentifier = this.selectedCategory.tabIdentifiers[0];
    }
  },
  mounted() {
    this.recalculateDataFromCategories();
  },
  methods: {
    recalculateDataFromCategories() {
      if (!this.actionCategories || this.actionCategories.length <= 0) {
        return;
      }

      const actionCategories = structuredClone(this.actionCategories);

      const categories = {};
      const tabs = {};
      const actions = {};

      const actionIdentifiers = [];
      const tabIdentifiers = [];
      const categoryIdentifiers = [];

      for (let i = 0, iLen = actionCategories.length; i < iLen; i++) {
        const actionCategory = actionCategories[i];

        actionCategory.tabIdentifiers = [];

        for (let j = 0, jLen = actionCategory.tabs.length; j < jLen; j++) {
          const actionTab = actionCategory.tabs[j];

          actionCategory.tabIdentifiers.push(actionTab.identifier);

          actionTab.actionIdentifiers = [];

          for (const action of actionTab.actions) {
            actionTab.actionIdentifiers.push(action.identifier);

            actionIdentifiers.push(action.identifier);
            actions[action.identifier] = action;
          }

          delete actionTab.actions;

          tabIdentifiers.push(actionTab.identifier);
          tabs[actionTab.identifier] = actionTab;
        }

        delete actionCategory.tabs;

        categoryIdentifiers.push(actionCategory.identifier);
        categories[actionCategory.identifier] = actionCategory;
      }

      this.categories = categories;
      this.categoryIdentifiers = categoryIdentifiers;

      this.tabs = tabs;
      this.tabIdentifiers = tabIdentifiers;

      this.actions = actions;
      this.actionIdentifiers = actionIdentifiers;

      this.setFirstCategoryIfEmpty();
    },
    onActionAdd(action) {
      if (!this.canInteract) {
        return;
      }

      this.$gameClient.roomCallMethod('addStrategicActionInvestment', action.id).catch((e) => {
        this.$
      });

      //this.addedActionIdentifiers.push(action.identifier);
    },
    onActionRemove(action) {
      if (!this.canInteract) {
        return;
      }

      this.$gameClient.roomCallMethod('removeStrategicActionInvestment', action.id);

      /*
      const index = this.addedActionIdentifiers.indexOf(action.identifier);

      if (index >= 0) {
        this.addedActionIdentifiers.splice(index, 1);
      }

      if (action.identifier === this.selectedActionIdentifier) {
        this.selectedActionIdentifier = null;
      }*/
    },
    setFirstCategoryIfEmpty() {
      if (this.selectedCategoryIdentifier) {
        return; // Already has one
      }

      if (!this.categoryIdentifiers || !this.categoryIdentifiers.length) {
        return; // Can't
      }

      console.log('this.categoryIdentifiers', this.categoryIdentifiers);

      this.selectedCategoryIdentifier = this.categoryIdentifiers[0];
    },
    setActiveCategory(categoryIdentifier) {
      if (this.selectedCategoryIdentifier === categoryIdentifier) {
        return;
      }

      this.selectedCategoryIdentifier = categoryIdentifier;
      this.selectedTabIdentifier = this.selectedCategory.tabIdentifiers[0];
    },
    setActiveTab(tabIdentifier) {
      this.selectedTabIdentifier = tabIdentifier;
    },
  }
}
</script>

<style lang="scss" scoped>
$footerHeight: 700px;

.header-container {
  margin-top: 50px;

  .large-icon-container {
    width: 400px;
  }

  .headline-container {
    h1 {
      text-shadow: 0px 7.4949px 7.4949px rgba(0, 0, 0, 0.25);
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 38px;
    }
  }

  .headline-container, .large-icon-container {
    text-align: center;
  }
}

.main-text {
  background: rgba(#000000, .6);
  border-radius: 26.2321px;
  color: #FFFFFF;
  padding: 30px;
  font-size: 40px;
  margin: 50px 150px;
}

.strategic-meeting-footer {
  height: 100%;

  .categories {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, 60%);
    box-shadow: inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
    height: 100%;

    .categories-inner {
      height: 100%;
    }
  }

  .actions {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, 60%);
    box-shadow: inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
    height: 400px; // 370px;
  }

  .actions-inner, .tabs-inner {
    width: 100%;
    height: 100%;
  }
}

.strategic-meeting-header-container {
  height: calc(1080px - $footerHeight);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, 80%);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
}

.strategic-meeting-footer-container {
  height: $footerHeight;
}

.strategic-meeting-footer-toggle-container {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, 80%);
  //box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
}

.tabs {
  height: 190px;
  background: #194040;
}

.btn-show-actions {
  img {
    max-height: 20px !important;
    transition: ease-in-out 200ms;
  }

  &.btn-show-actions-active {
    img {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
}

.strategic-meeting-popup {
  overflow: hidden;
  height: 1060px;
  width: 1300px;
  padding: 0;
}

.selected-action-container, .picked-actions-container {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, 80%);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
}

.picked-actions-container {
  width: calc(100% - 440px);
}

.picked-actions {
  height: 100%;
  padding: 25px;
}

.selected-action-container {
  width: 450px;
}

.selected-action {
  overflow: hidden;
  padding: 10px;
  height: 100%;

  .selected-action-inner {
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000E21, 60%);
    box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
    border-radius: 10px;
    color: white;
    height: 100%;
  }

  .selected-action-description {
    padding: 40px;
    text-align: left;
  }

  .selected-action-footer {
    text-align: left;
    padding: 10px 30px;
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), rgba(#000E21, 60%);
  }
}

.categories {
  width: 320px;
}

.right-actions {
  width: calc(100% - 320px);
}

.strategic-meeting-cost {
  position: absolute;
  top: 0;
  left: -235px;
}
</style>
