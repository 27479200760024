export const EVENT_ERROR = 'error';

export class ErrorEvent extends Event {
  errorString;

  errorData;

  constructor(errorString, errorData = null) {
    super(EVENT_ERROR);

    this.errorString = errorString;
    this.errorData = errorData || null;
  }
}
