export class ColyseusUtils {
  static isPrimitive(value) {
    return value === null || value === undefined || typeof value !== 'object';
  }

  static getConstructorNameFromValue(value) {
    const constructorName = this.getConstructorNameFromValueInternal(value);

    if (!this.isPrimitive(value)) {
      //console.log(constructorName, value);
    }

    return constructorName;
  }

  static getConstructorNameFromValueInternal(value) {
    if (!value) {
      return null;
    }

    if (typeof(value) !== 'object') {
      return null;
    }

    // This only works if vueConfig.configureWebpack.devtool is set to "eval" - instead look at the properties
    //let constructorName = value.constructor ? value.constructor.name : null;
    let constructorName = null;

    if (value._definition) {
      // Colyseus root and/or state
      constructorName = '_';
    } else if (value.$indexes && value.$items) {
      if (typeof(value.size) !== 'undefined') {
        constructorName = 'MapSchema'; // MapSchema has a "size" property, but it may be 0
      } else if (typeof(value.length) !== 'undefined') {
        constructorName = 'ArraySchema'; // ArraySchema has a "length" property, but it may be 0
      }
    }

    return constructorName;
  }
}
