import * as LanguageEn from './en';
import * as LanguageSystem from './system';
import { i18n } from '@/lib/i18n/i18n'

const languages = {
  system: LanguageSystem,
  en: LanguageEn,
};

const language = i18n.global.locale;
const languageDynamicLocales = languages[language];

const winningConditions = languageDynamicLocales.WinningConditions;
const epiReport = languageDynamicLocales.EpiReport;
const introPages = languageDynamicLocales.IntroPages;
const transitionScreens = languageDynamicLocales.TransitionScreens;
const timers = languageDynamicLocales.Timers;
const starData = languageDynamicLocales.StarData;
const tutorials = languageDynamicLocales.Tutorials;

export {
  winningConditions,
  epiReport,
  introPages,
  transitionScreens,
  timers,
  starData,
  tutorials,
}
