<template>
  <div class="meeting-popup">
    <div class="meeting-popup-inner d-flex">
      <div class="meeting-popup-left">
        <div class="meeting-popup-details">
          <div class="meeting-popup-icon">
            <hazard-icon v-if="meetingType === 'hazard'" :icon="hazardIcon"/>
            <no-drag-image v-else-if="meetingType === 'strategic'" src="@/assets/icons/icon-calendar-large.png"/>
          </div>
          <div class="meeting-popup-title">
            {{ title }}
          </div>
        </div>
        <div class="meeting-popup-navigation epic-scrollbar pt-3">
          <meeting-popup-category
            v-for="category in actionCategoriesWithTabs"
            :category="category"
            @select-tab="onSelectTab"
            :active-category-identifier="activeCategoryIdentifier"
            :active-tab-identifier="activeTabIdentifier"
            :default-open="false"
            :tab-lookers="tabLookers"
            :closeable="meetingType === 'strategic'"
          />
        </div>
      </div>
      <div class="meeting-popup-right flex-grow-1" v-if="activeTab || nothingToInvest">
        <div class="meeting-popup-right-inner" v-if="nothingToInvest">
          <div class="meeting-actions-all epic-scrollbar">
            <div class="no-investment-items">{{ $t('meetingPopup.everythingInvested') }}</div>
          </div>
        </div>
        <div class="meeting-popup-right-inner" :class="investmentsAvailableOpen ? 'meeting-popup-right-inner-investments-open' : 'meeting-popup-right-inner-investments-closed'" v-else-if="meetingType === 'hazard' || meetingType === 'strategic'">
          <div class="meeting-investments-made epic-scrollbar">
            <meeting-popup-action
              v-for="action in actionsInvested"
              :meeting-type="meetingType"
              :action="action"
              :highlight-player-aoe="true"
              :investments="actionInvestments[action.identifier]"
              @remove="onActionRemove(action)"
              :is-added="true"
              :show-actions="true"
              :players="players"
              :can-interact="canInteract"
            />

            <div class="no-investment-items" v-if="actionsInvested.length === 0">{{ $t('meetingPopup.noActionsInvested') }}</div>
          </div>
          <div class="meeting-investments-popout">
            <toggle-app-button idle-color="green" active-color="green" width="full" height="lg" v-model="investmentsAvailableOpen" :show-chevron="true">{{ activeTab.title }}</toggle-app-button>
            <div class="meeting-investments-available epic-scrollbar" v-if="investmentsAvailableOpen">
              <meeting-popup-action
                v-for="action in actionsNotInvested"
                :meeting-type="meetingType"
                :action="action"
                :highlight-player-aoe="true"
                :investments="actionInvestments[action.identifier]"
                @add="onActionAdd(action)"
                :is-added="false"
                :show-actions="true"
                :players="players"
                :can-interact="canInteract"
              />

              <div class="no-investment-items" v-if="actionsNotInvested.length === 0">{{ $t('meetingPopup.allActionsInvested') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HazardIcon from '@/components/common/HazardIcon.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import MeetingPopupCategory from '@/components/MeetingPopup/MeetingPopupCategory.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'
import StrategicMeetingAction from '@/components/StrategicMeeting/StrategicMeetingAction/index.vue'
import MeetingPopupAction from '@/components/MeetingPopup/MeetingPopupAction.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'MeetingPopup',
  components: {
    StrategicMeetingAction,
    ToggleAppButton,
    AppButton,
    NoDragImage,
    HazardIcon,
    MeetingPopupCategory,
    MeetingPopupAction,
  },
  props: {
    actionCategories: {
      type: Array,
      required: true,
    },
    meetingType: {
      type: String,
      required: true,
    },
    meetingNumber: {
      type: Number,
      required: true,
    },
    hazardIcon: {
      type: String,
      required: false,
    },
    investments: {
      type: Array,
      required: false,
      default: [],
    },
    tabLookers: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    players: {
      type: Object,
      required: true,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data () {
    return {
      activeCategory: null,
      activeTab: null,

      investmentsAvailableOpen: true,

      actionInvestments: [],

      nothingToInvest: false,
    }
  },
  mounted() {
    this.setActiveCategoryIfEmpty();

    this.generateActionInvestments();

    if (this.meetingNumber === 1) {
      if (this.meetingType === 'strategic') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.StrategicMeeting);
      } else if (this.meetingType === 'hazard') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.HazardMeeting);
      }
    }
  },
  emits: ['action-removed', 'action-added', 'looking-at-category-change', 'looking-at-tab-change', 'player-investment-change'],
  watch: {
    actionCategories() {
      this.setActiveCategoryIfEmpty();

      this.generateActionInvestments();
    },
    activeCategory() {
      if (!this.activeCategory) {
        return;
      }

      this.$emit('looking-at-category-change', this.activeCategory);
    },
    activeTab() {
      if (!this.activeTab) {
        return;
      }

      this.$emit('looking-at-tab-change', this.activeTab);
    },
    investments: {
      handler() {
        console.log('deepHandler');
        this.generateActionInvestments();
      } ,
      deep: true,
    },
  },
  methods: {
    generateActionInvestments() {
      const actionInvestments = {};

      console.log('this.actionCategories', this.actionCategories);

      for (const category of this.actionCategories) {
        if (!category || !category.tabs) {
          continue;
        }

        for (const tab of category.tabs) {
          if (!tab || !tab.actions) {
            continue;
          }

          for (const action of tab.actions) {
            actionInvestments[action.identifier] = [];
          }
        }
      }

      console.log({
        actionInvestments,
        investments: this.investments,
      });

      for (const investment of this.investments) {
        if (!actionInvestments[investment.actionIdentifier]) {
          throw new Error(`Could not find action investment by action identifier "${investment.actionIdentifier}"`);
        }
        //console.log('investment', investment);

        actionInvestments[investment.actionIdentifier].push(investment);
      }

      console.log('actionInvestments', actionInvestments);

      this.actionInvestments = actionInvestments;
    },
    onActionRemove(action) {
      if (!this.canInteract) {
        return;
      }

      console.log('ON ACTION REMOVE', action, this.canInteract);
      this.$emit('action-removed', action);
    },
    onActionAdd(action) {
      if (!this.canInteract) {
        return;
      }

      console.log('ON ACTION ADD', action, this.canInteract);
      this.$emit('action-added', action);
    },
    onPlayerInvestmentChange(action, newInvestment) {
      this.$emit('player-investment-change', {
        action,
        newInvestment,
      });
    },
    setActiveCategoryIfEmpty() {
      this.nothingToInvest = false;

      if (this.activeCategory) {
        return; // Category already set
      }

      if (!this.actionCategories || this.actionCategories.length <= 0) {
        return; // No categories available
      }

      const investedActionIdentifiers = this.investedActionIdentifiers;

      let doBreak = false;

      for (const category of this.actionCategories) {
        if (!category || !category.tabs) {
          continue;
        }

        for (const tab of category.tabs) {
          if (!tab || !tab.actions) {
            continue;
          }

          for (const action of tab.actions) {
            if (!investedActionIdentifiers.includes(action.identifier)) {
              this.onSelectTab({
                category: category,
                tab: tab,
              }, false);

              doBreak = true;
            }

            if (doBreak) {
              break; // Break out of the actions loop
            }
          }


          if (doBreak) {
            break; // Break out of the tabs loop
          }
        }


        if (doBreak) {
          break; // Break out of the category loop
        }
      }

      if (!doBreak) {
        console.log('Did not find a valid tab to change to - perhaps everything is already invested');
        this.nothingToInvest = true;
      }
    },
    onSelectTab(data, forceOpen = true) {
      this.activeCategory = data.category;
      this.activeTab = data.tab;

      if (forceOpen) {
        this.investmentsAvailableOpen = true;
      }
    },
    parseAction(action) {
      const _action = structuredClone(action);

      if (!_action.cost) {
        _action.cost = _action.baseCost;
      }

      _action.playerInvestment = Math.floor(Math.random() * 4);
      _action.totalInvestment = 2;

      return _action;
    },
  },
  computed: {
    playerPoints() {
      for (const playerCode in this.players) {
        const player = this.players[playerCode];

        if (player.isSelf) {
          return player.rolePoints;
        }
      }

      return 0;
    },
    investedActionIdentifiers() {
      const investedActionIdentifiers = [];

      if (this.investments && this.investments.length > 0) {
        for (const investment of this.investments) {
          investedActionIdentifiers.push(investment.actionIdentifier);
        }
      }

      return investedActionIdentifiers;
    },
    actionCategoriesWithTabs() {
      const actionCategoriesWithTabs = [];

      for (const category of this.actionCategories) {
        if (category.tabs.length <= 0) {
          continue;
        }

        actionCategoriesWithTabs.push(category);
      }

      return actionCategoriesWithTabs;
    },
    actionsInvested() {
      const investedActionIdentifiers = this.investedActionIdentifiers;

      const actionsInvested = [];

      for (const category of this.actionCategories) {
        for (const tab of category.tabs) {
          for (const action of tab.actions) {
            if (!investedActionIdentifiers.includes(action.identifier)) {
              continue;
            }

            actionsInvested.push(this.parseAction(action));
          }
        }
      }

      return actionsInvested;
    },
    actionsNotInvested() {
      const investedActionIdentifiers = this.investedActionIdentifiers;
      const actions = this.activeTab.actions;

      const actionsNotInvested = [];

      for (const action of actions) {
        if (investedActionIdentifiers.includes(action.identifier)) {
          continue;
        }

        actionsNotInvested.push(this.parseAction(action));
      }

      return actionsNotInvested;
    },
    actionsAll() {
      const actions = this.activeTab.actions;

      const allActions = [];

      for (const action of actions) {
        allActions.push(this.parseAction(action));
      }

      return allActions;
    },
    activeCategoryIdentifier() {
      return this.activeCategory ? this.activeCategory.identifier : null;
    },
    activeTabIdentifier() {
      return this.activeTab ? this.activeTab.identifier : null;
    },
    title() {
      if (this.meetingType === 'strategic') {
        return this.$t('meetingPopup.strategicMeetingHeader');
      } else if (this.meetingType === 'hazard') {
        return this.$t('meetingPopup.hazardMeetingHeader');
      } else {
        return this.$t('meetingPopup.unknownMeetingHeader');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-popup {
  width: 100%;
  height: 100%;
}

.meeting-popup-left {
  min-width: 400px !important;
  flex-basis: 400px !important;

  .meeting-popup-details {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
    box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
    padding: 15px;

    .meeting-popup-title {
      padding-top: 15px;
      font-family: 'Josefin Sans';
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      letter-spacing: 0.05em;
      font-size: 20pt;
    }
  }

  .meeting-popup-icon {
    height: 100px;

    .icon {
      max-height: 100%;
    }

    img {
      max-height: 100%;
    }
  }

  .meeting-popup-navigation {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
    height: 600px;
    padding: 16px;
  }
}

.meeting-popup-right-inner {
  .meeting-investments-made, .meeting-actions-all {
    height: calc(960px - 50px);
  }

  .meeting-investments-popout {
    height: 50px;
  }

  &.meeting-popup-right-inner-investments-open {
    .meeting-investments-made {
      height: calc(960px - 570px);
    }

    .meeting-investments-popout {
      height: 570px;
    }
  }
}

.meeting-popup-right-inner {
  height: 100%;
  overflow: hidden;
  position: relative;

  .meeting-investments-made, .meeting-actions-all {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#184E5A, .8);
  }

  .meeting-investments-available {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
    height: calc(100% - 55px);
  }
}

.meeting-investments-made, .meeting-investments-available, .meeting-actions-all {
  padding: 20px;
  overflow-y: auto;

  .meeting-action {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.no-investment-items {
  font-size: 16pt;
  color: white;
}

.meeting-popup-inner {
  height: 960px;
  width: 1350px;
}
</style>
