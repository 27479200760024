import { getConsole, getMockConsole } from '@/utils/getConsole'
import { pushCloned } from '@/utils/arrayUtil'
import { ArraySchema } from '@colyseus/schema'
import {isProxy} from 'vue';
import { colyseusStateToJs } from '@/plugins/GameClient/utils/ColyseusStateNormalizer'
import { ColyseusUtils } from '@/plugins/GameClient/utils/ColyseusUtils'

export const VUEX_MUTATION_SET = 'set'
export const VUEX_MUTATION_ADD = 'add'
export const VUEX_MUTATION_REMOVE = 'remove'
export const VUEX_MUTATION_RESET = 'reset'

export const MUTATION_VALUE_TYPE_MAP = 'map'
export const MUTATION_VALUE_TYPE_ARRAY = 'array'
export const MUTATION_VALUE_TYPE_PRIMITIVE = 'primitive'

export class ColyseusVuexSyncerNew {
  gameClient
  vuex
  vuexGetter
  vuexMutations = {
    [VUEX_MUTATION_SET]: null,
    [VUEX_MUTATION_ADD]: null,
    [VUEX_MUTATION_REMOVE]: null,
    [VUEX_MUTATION_RESET]: null,
  }

  mutationListeners = {
    [VUEX_MUTATION_SET]: [],
    [VUEX_MUTATION_ADD]: [],
    [VUEX_MUTATION_REMOVE]: [],
    [VUEX_MUTATION_RESET]: [],
  }

  console

  constructor (gameClient, vuex, vuexGetter, vuexMutations, useConsole = false) {
    this.gameClient = gameClient
    this.vuex = vuex

    this.console = useConsole ? getConsole('ColyseusVuexSyncer', 'black', '#00ffff') : getMockConsole()

    //console.log('ColyseusVuexSyncer', this.console)

    if (!vuexMutations[VUEX_MUTATION_SET]) {
      throw new Error(`vuexMutations.${VUEX_MUTATION_SET} does not exist`)
    } else if (!vuexMutations[VUEX_MUTATION_ADD]) {
      throw new Error(`vuexMutations.${VUEX_MUTATION_ADD} does not exist`)
    } else if (!vuexMutations[VUEX_MUTATION_REMOVE]) {
      throw new Error(`vuexMutations.${VUEX_MUTATION_REMOVE} does not exist`)
    } else if (!vuexMutations[VUEX_MUTATION_RESET]) {
      throw new Error(`vuexMutations.${VUEX_MUTATION_RESET} does not exist`)
    }

    this.vuexGetter = vuexGetter
    this.vuexMutations = vuexMutations
  }

  dispose() {

  }

  addMutationListener (mutation, listener) {
    this.mutationListeners[mutation].push(listener)
  }

  removeMutationListener (mutation, listener) {
    for (let i = 0, len = this.mutationListeners[mutation].length; i < len; i++) {
      this.mutationListeners[mutation].remove(listener)
    }
  }

  normalizeColyseusState (colyseusState) {
    if (ColyseusUtils.isPrimitive(colyseusState)) {
      return colyseusState
    }

    const json = colyseusState.toJSON()

    // https://github.com/colyseus/schema/issues/94
    // Colyseus can't actually tell you what index was deleted from an array, so it instead keeps a global number
    // Let's convert all arrays to just use objects to avoid this
    const jsonFixed = this.replaceArraysWithObjects(json)

    console.log('jsonFixed', jsonFixed);

    return jsonFixed
  }

  replaceArraysWithObjects (data) {
    const dataType = typeof data

    if (dataType === 'object') {
      if (data instanceof Array) {
        const valueObject = {}

        for (let i = 0, len = data.length; i < len; i++) {
          valueObject[i.toString()] = this.replaceArraysWithObjects(data[i])
        }

        return valueObject
      } else {
        for (const key in data) {
          data[key] = this.replaceArraysWithObjects(data[key])
        }
      }
    }

    return data
  }

  bindColyseusRoot (colyseusState) {
    this.console.log('bindColyseusRoot', colyseusState)

    colyseusState.onStateChange = (changes) => {
      for (const change of changes) {
        //const fieldValue = colyseusState
        this.console.log('colyseusState.onStateChange.change', change)
      }
    }

    const vuexState = this.normalizeColyseusState(colyseusState)

    this.bindAny(colyseusState, [this.vuexGetter])

    this.mutationSet({
      keyParts: [this.vuexGetter],
      value: vuexState,
    })
  }

  bindMapSchema (item, keyParts) {
    item.onAdd = (subItem, subKey) => {
      this.console.log('colyseusState.item.onAdd', subItem, subKey, keyParts.join('.'))

      this.mutationAdd({
        keyParts: keyParts.concat(subKey),
        value: this.normalizeColyseusState(subItem),
        type: MUTATION_VALUE_TYPE_MAP,
      })

      this.bindAny(subItem, keyParts.concat(subKey.toString()))
    }

    item.onRemove = (subItem, subKey) => {
      this.console.log('colyseusState.map.item.onRemove', subItem, subKey, keyParts.join('.'))

      this.mutationRemove({
        keyParts: keyParts.concat(subKey),
        value: this.normalizeColyseusState(subItem),
        type: MUTATION_VALUE_TYPE_MAP,
      })
    }

    item.onChange = (subItem, subKey) => {
      this.console.log('colyseusState.map.item.onChange', subItem, subKey, keyParts.join('.'))

      this.mutationSetAuto(keyParts.concat(subKey), subItem);
    }

    this.console.log(`colyseusState.bindMapSchema`, item, keyParts.join('.'))

    item.forEach((value, key) => {
      this.bindAny(value, keyParts.concat(key))
    })
  }

  bindArraySchema (item, keyParts) {
    this.console.log(`colyseusState.bindArraySchema`, item, keyParts.join('.'))

    item.onAdd = (subItem, subKey) => {
      subKey = subKey.toString()

      this.console.log('colyseusState.array.item.onAdd', subItem, subKey, keyParts.join('.'))

      this.mutationSetAuto(keyParts.concat(subKey), subItem);

      this.bindAny(subItem, keyParts.concat(subKey.toString()))
    }

    item.onRemove = (subItem, subKey) => {
      subKey = subKey.toString()

      this.console.log('colyseusState.array.item.onRemove', subItem, subKey, keyParts.join('.'))

      /*this.mutationSetAuto({
        keyParts: keyParts.concat(subKey),
        value: subItem,
      })*/
      this.mutationRemove({
        keyParts: keyParts.concat(subKey),
        value: this.normalizeColyseusState(subItem),
        type: MUTATION_VALUE_TYPE_ARRAY,
      })
    }

    item.onChange = (subItem, subKey) => {
      subKey = subKey.toString()

      this.console.log('colyseusState.item.onChange', subItem, subKey, keyParts.join('.'))

      this.mutationSetAuto(keyParts.concat(subKey), subItem);
    }

    this.console.log(`colyseusState.bindArraySchema`, {
      item,
      keyParts: keyParts.join('.')
    })

    item.forEach((value, subKey) => {
      this.bindAny(value, keyParts.concat(subKey.toString()))
    })
  }

  bindAny (item, keyParts) {
    const itemType = typeof item

    //this.debugType(item, 'bindAny');

    if (itemType === 'object') {
      this.console.log('item', item)

      this.console.log(`colyseusState.bindAny`, {
        item,
        keyParts: keyParts.join('.')
      })

      const itemConstructorName = ColyseusUtils.getConstructorNameFromValue(item);

      //this.console.log('bindAny', item, keyParts);

      if (itemConstructorName === '_') {
        this.bindUnderscore(item, keyParts)
      } else if (itemConstructorName === 'ArraySchema') {
        this.bindArraySchema(item, keyParts)
      } else if (itemConstructorName === 'MapSchema') {
        this.bindMapSchema(item, keyParts)
      }
    } else {
      //this.console.log('could not bindAny since the item type is not object', item, itemType, keyParts);
    }
  }

  bindUnderscore (item, keyParts) {
    //this.debugType(item, 'bindUnderscore');

    if (!item || !item._definition || !item._definition.schema) {
      this.console.error(`Cannot call bindUnderscore on item since there is no schema`, item)

      return
    }

    this.console.log(`colyseusState.bindUnderscore`, {
      item,
      keyParts: keyParts.join('.')
    })

    const schemaDefinition = item._definition.schema

    for (const field in schemaDefinition) {
      this.bindAny(item[field], keyParts.concat(field))
    }

    this.console.log('itemitemitemitemitemitemitem', item)

    item.onChange = (changes) => {
      this.console.log('colyseusState.onChange', changes)

      changes.forEach((change) => {
        let storeValue = change.value

        const keyPartsField = keyParts.concat(change.field)

        this.console.log('keyPartsField', keyPartsField, change)

        this.mutationSetAuto(keyPartsField, storeValue)
      })
    }
  }

  mutationSetAuto (keyPartsField, value) {
    const storeValueConstructor = ColyseusUtils.getConstructorNameFromValue(value)

    //this.debugType(value, `mutationSetAuto(${storeValueConstructor})`);

    if (typeof(keyPartsField) === 'object' && Object.keys(keyPartsField).includes('value')) {
      value = keyPartsField.value;
      keyPartsField = keyPartsField.keyParts;
    }

    /*this.console.log('mutationSetAuto', {
      keyPartsField,
      value
    }, storeValueConstructor)*/

    if (storeValueConstructor === '_') {
      this.bindUnderscore(value, keyPartsField)

      const fieldsByIndex = value._definition.fieldsByIndex || {}

      /*console.log('CONSTERUCTOR IS UNDERSCORE', {
        'fieldsByIndex': fieldsByIndex,
        'value': value,
        'value._definition': value._definition,
        'JSON.stringify(value)': JSON.stringify(value),
        'keyPartsField': keyPartsField,
      })*/

      this.mutationSet({
        keyParts: keyPartsField,
        value: colyseusStateToJs(value),
        type: MUTATION_VALUE_TYPE_MAP,
        origin: 'A',
      })

      for (const fieldNameKey in fieldsByIndex) {
        const fieldName = fieldsByIndex[fieldNameKey]
        const keyPartsFieldSub = pushCloned(keyPartsField, fieldName)

        /*console.log('SETTING _ FIELD', {
          fieldName,
          fieldNameKey,
          keyPartsFieldSub,
          keyPartsField,
          value,
          'value[fieldName]': value[fieldName],
        });*/

        this.mutationSetAuto(keyPartsFieldSub, value[fieldName])
      }
    } else if (storeValueConstructor === 'MapSchema') {
      /*const storeValue = {}
      const valueKeys = value.keys();

      for (const key of valueKeys) {
        storeValue[key] = value[key]
      }

      console.log(
        'DEBUG MAPPER / MapSchema', {
          JSON: JSON.stringify(value),
          keyPartsField,
          value,
          storeValue,
        })*/
      //const storeValue = value.entries();

      //console.log('MapSchema.storeValue.storeValue', storeValue);

      this.bindAny(value, keyPartsField)

      this.mutationSet({
        keyParts: keyPartsField,
        value: colyseusStateToJs(value),
        type: MUTATION_VALUE_TYPE_MAP,
        origin: 'B',
      })
    } else if (storeValueConstructor === 'ArraySchema') {
      this.bindAny(value, keyPartsField)

      this.mutationSet({
        keyParts: keyPartsField,
        value: colyseusStateToJs(value),
        type: MUTATION_VALUE_TYPE_ARRAY,
        origin: 'C',
      })
    } else {
      this.mutationSet({
        keyParts: keyPartsField,
        value: colyseusStateToJs(value),
        type: MUTATION_VALUE_TYPE_PRIMITIVE,
        origin: 'D',
      })
    }
  }

  debugType(value, label) {
    const constructor = ColyseusUtils.getConstructorNameFromValue(value);

    this.console.log('debugType', {
      label,
      value,
      constructor
    }, value);
  }

  callMutation (mutation, data) {
    if (!this.vuexMutations[mutation]) {
      throw new Error(`Could not call mutation ${mutation} as it does not exist`)
    }

    const vuexMutation = this.vuexMutations[mutation]

    /*console.log('callMutation', {
      mutation,
      data,
      vuexMutation
    })*/

    this.vuex.commit(vuexMutation, data)

    //this.console.log('callMutation', mutation, this.vuexMutations[mutation], data);

    if (this.mutationListeners[mutation].length > 0) {
      for (const mutationListener of this.mutationListeners[mutation]) {
        mutationListener(data)
      }
    }
  }

  reset() {
    this.mutationReset();
  }

  mutationReset() {
    this.callMutation(VUEX_MUTATION_RESET, null);
  }

  mutationAdd (data) {
    this.callMutation(VUEX_MUTATION_ADD, data)
  }

  mutationSet (data) {
    this.callMutation(VUEX_MUTATION_SET, data)
  }

  mutationRemove (data) {
    this.callMutation(VUEX_MUTATION_REMOVE, data)
  }
}
