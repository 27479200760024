<template>
  <app-button :width="width" :height="height" :color="computedColor" :state="computedState" :disabled="disabled" :type="type" @click="toggle">
    <slot />
    <chevron class="chevron" v-if="showChevron" :model-value="modelValue" />
  </app-button>
</template>

<script>
import {SIZE_XS, SIZE_SM, SIZE_MD, SIZE_LG, SIZE_XL, SIZE_FIT_CONTENT, SIZE_FULL} from './enums';
import {TYPE_BOX_ROUNDED, TYPE_BOX, TYPE_CIRCLE} from './enums';
import AppButton from '@/components/common/buttons/AppButton.vue'
import Chevron from '@/components/common/Chevron.vue'

export default {
  name: 'ToggleAppButton',
  components: { Chevron, AppButton },
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    idleColor: {
      type: String,
      required: true,
    },
    activeColor: {
      type: String,
      required: false,
    },
    idleState: {
      type: String,
      required: false,
      default: 'idle',
    },
    activeState: {
      type: String,
      required: false,
      default: 'active',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: SIZE_MD,
    },
    height: {
      type: String,
      required: false,
      default: SIZE_MD,
    },
    type: {
      type: String,
      required: false,
      default: TYPE_BOX_ROUNDED,
    },
    showChevron: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      innerValue: this.modelValue,
    }
  },
  computed: {
    computedColor() {
      if (this.modelValue && this.activeColor) {
        return this.activeColor;
      }

      return this.idleColor;
    },
    computedState() {
      if (this.modelValue && this.activeState) {
        return this.activeState;
      }

      return this.idleState;
    },
  },
  methods: {
    toggle() {
      this.innerValue = !this.innerValue;
    },
  },
  watch: {
    modelValue() {
      if (this.innerValue === this.modelValue) {
        return;
      }

      this.innerValue = this.modelValue;
    },
    innerValue() {
      if (this.innerValue === this.modelValue) {
        return;
      }

      this.$emit('update:modelValue', this.innerValue);
    }
  }
}
</script>

<style lang="scss" scoped>
.chevron {
  position: absolute;
  top: 15px;
  right: 40px;
}
</style>
