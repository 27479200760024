<template>
  <tr class="tr-room-details">
    <td>{{ room.id }}</td>
    <td>{{ room.name }}</td>
    <td class="td-player-list">
      <ul>
        <li v-for="player in room.players">
          <facilitator-player
            :player="player"
            @remove-facilitator-called="onRemoveFacilitatorCalled"
          />
        </li>
      </ul>
    </td>
    <td>
      {{ formattedLastSession }}
    </td>
    <td>
      <div class="d-flex">
        <div class="room-status">
          {{ roomStatus }}
        </div>
        <div v-if="hasLiveSession" class="facilitator-room-status" />
      </div>
    </td>
    <td>
      <button class="btn btn-success" @click="onClickToggleActions">Actions</button>
    </td>
  </tr>
  <tr class="tr-room-actions" v-if="showActions">
    <td colspan="6">
      <div class="room-header">
        <h3>{{ room.name }} </h3>
        <h4 class="room-id">Room: #{{ room.id }} </h4>
      </div>
      <div class="btn-group" role="group">
        <button class="btn btn-success" :disabled="!canDeleteSaveData" @click="onClickDeleteSaveData">Delete save data</button>
        <button class="btn btn-success" :disabled="!canEndCurrentSession" @click="onClickEndSession">End session</button>
        <button class="btn btn-success" :disabled="!canSpectate" @click="onClickSpectate">Spectate</button>
        <button class="btn btn-success" v-if="isPaused === false" @click="onClickPauseRoom">Pause Room</button>
        <button class="btn btn-warning" v-if="isPaused" @click="onClickUnPauseRoom">Unpause Room</button>
      </div>

      <div class="btn-group float-end" role="group" v-if="isAdmin">
        <button class="btn btn-warning" :disabled="!canDeleteRoom" @click="onClickEditRoom">Edit Room</button>
        <button class="btn btn-danger" :disabled="!canDeleteRoom" @click="onClickDeleteRoom">Delete Room</button>
      </div>

    </td>
  </tr>
</template>

<script>
import FacilitatorPlayer from '@/components/Facilitator/FacilitatorPlayer.vue'
import { formatDateTime, formatTimeAgo } from '@/utils/dateUtil'
import {mapState} from "vuex";

export default {
  name: 'FacilitatorRoomRow',
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  components: {
    FacilitatorPlayer,
  },
  emits: ['delete-save-data', 'end-session', 'spectate', 'player-remove-facilitator-called', 'delete-room', 'pause-room'],
  data() {
    return {
      showActions: false,
    }
  },
  computed: {
    ...mapState(['signedInUser']),
    isAdmin(){
      return this.signedInUser?.role === 'admin' || this.signedInUser?.role === 'rootadmin';
    },
    formattedLastSession() {
      const formattedDate = this.formatDate(this.room.lastSessionAt);

      if (this.hasLiveSession) {
        return `Ongoing (started ${formattedDate})`;
      }

      return formattedDate;
    },
    canSpectate() {
      return this.hasLiveSession;
    },
    canDeleteRoom() {
      return !this.hasLiveSession;
    },
    canEndCurrentSession() {
      return this.hasLiveSession;
    },
    canDeleteSaveData() {
      return !!this.room.saveData;
    },
    hasLiveSession() {
      return !!this.liveSession;
    },
    liveSession() {
      if (this.room && this.room.liveSession) {
        return this.room.liveSession;
      }

      return null;
    },
    saveData() {
      if (this.room && this.room.saveData) {
        return this.room.saveData;
      }

      return null;
    },
    meetingNumber() {
      if (this.liveSession && this.liveSession.state && this.liveSession.state.meetingNumber) {
        return this.liveSession.state.meetingNumber;
      }

      if (this.saveData && this.saveData.meetingNumber) {
        return this.saveData.meetingNumber;
      }

      return 0;
    },
    roomStatusSimple() {
      if (this.hasLiveSession) {
        return 'Currently playing';
      } else if (this.room.saveData) {
        return 'Stopped abruptly';
      } else {
        return 'Not started';
      }
    },
    roomStatus() {
      let status = this.roomStatusSimple;

      if (this.meetingNumber) {
        status += ` (M${this.meetingNumber})`;
      }

      return status;
    },
    isPaused() {
      return this.room?.liveSession?.state?.isPaused;
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return `${formatDateTime(date)} - ${formatTimeAgo(date)}`;
      }

      return '(never)';
    },
    onRemoveFacilitatorCalled(player) {
      this.$emit('player-remove-facilitator-called', {  room: this.room, player: player });
    },
    onClickToggleActions() {
      this.showActions = !this.showActions;
    },
    onClickSpectate() {
      if (!this.canSpectate) {
        return;
      }

      this.$emit('spectate', { room: this.room });
    },
    onClickEndSession() {
      if (!this.canEndCurrentSession) {
        return;
      }

      this.$emit('end-session', { room: this.room });
    },
    async onClickPauseRoom() {
      if (!this.hasLiveSession){
        console.log('No live session');

        return;
      }

      await this.$gameClient.pauseRoom(this.room.liveSession.roomId, this.$store.getters.facilitatorCode, true);

      if (this.room?.liveSession?.state) {
        this.room.liveSession.state.isPaused = true;
      }
    },
    async onClickUnPauseRoom() {
      if (!this.hasLiveSession){
        console.log('No live session');

        return;
      }

      console.log('Calling pause room..');

      await this.$gameClient.pauseRoom(this.room.liveSession.roomId, this.$store.getters.facilitatorCode, false);

      if (this.room?.liveSession?.state) {
        this.room.liveSession.state.isPaused = false;
      }
    },
    onClickDeleteSaveData() {
      if (!this.canDeleteSaveData) {
        return;
      }

      this.$emit('delete-save-data', { room: this.room });
    },
    onClickDeleteRoom() {
      if (!this.canDeleteRoom) {
        return;
      }

      this.$emit('delete-room', { room: this.room });
    },
    onClickEditRoom() {
      this.$router.push({ name: 'RoomUpdate', params: { id: this.room.id }});
    },
  }
}
</script>

<style lang="scss">
.facilitator-room-status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: green;
  margin-left: 5px;
  position: relative;
  top: 3px;
  animation: room-glow 500ms infinite alternate;
  cursor: pointer;
}

.tr-room-actions{
  td{
    padding: 2em 2em 3em 2em;
  }
}

.room-header{
  display:flex;
}
.room-id{
  opacity: .5;
  margin-left:auto;
}
@keyframes room-glow {
  from {
    box-shadow: 0 0 5px green;
    opacity: 0.5;
  }

  to {
    box-shadow: 0 0 10px green;
    opacity: 1.0;
  }
}
</style>
