<template>
  <div class="team-balance-aoe">
    <div class="levels">
      <div class="level" v-for="n in 5" :class="['level-' + n, (5 - (n - 1)) <= value ? 'level-active' : 'level-inactive']" />
    </div>
    <div class="label-container">
      <label>{{ label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TeamBalanceAoe',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.team-balance-aoe {
  padding: 5px 15px;
  background: #1B2B40;
  border: 5px solid #0C1827;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

label {
  text-align: center;
  text-transform: uppercase;
  color: white;
  word-break: break-word;
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 12.5px;
}

.level {
  width: 100%;
  height: 25px;
  margin-bottom: 3px;
  transition: 200ms ease-in-out;

  border: 2px solid #000000;
  box-shadow: inset 0px 4px 4px #FFFFFF;
  border-radius: 8.79415px;
  opacity: 0.15;

  &.level-active {
    opacity: 1 !important;
  }

  &.level-5, &.level-4 {
    background: #58C296;

    &.level-active {
      background: linear-gradient(180deg, lighten(#58C296, 20%) 0%, darken(#58C296, 20%) 100%);
    }
  }

  &.level-3 {
    background: #C29858;

    &.level-active {
      background: linear-gradient(180deg, lighten(#C29858, 20%) 0%, darken(#C29858, 20%) 100%);
    }
  }

  &.level-1, &.level-2 {
    background: #C25865;

    &.level-active {
      background: linear-gradient(180deg, lighten(#C25865, 20%) 0%, darken(#C25865, 20%) 100%);
    }
  }
}
</style>
