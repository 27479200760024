<template>
  <div class="row">
    <label class="form-label">Player chosen data</label>
    <bootstrap-select :options="playerChosenDataOptions" v-model="playerChosenDataValue" />
  </div>
  <div class="row">
    <label class="form-label">Selected</label>
    <bootstrap-select :options="yesNoOptions" v-model="selectedValue" />
  </div>
  <div class="row">
    <label class="form-label">Local selected</label>
    <bootstrap-select :options="yesNoOptions" v-model="localSelectedValue" />
  </div>

  <div class="team-balance-dev d-flex flex-wrap justify-content-even gap-3">
    <role-selection-icon-button
      v-if="roles"
      v-for="(roleData, n) in roles"
      :role-data="roleData"
      :player-chosen-data="playerChosenData"
      :selected="roleToggled[n]"
      :local-selected="localSelected"
      @click="roleToggled[n] = !roleToggled[n]"
    />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import TeamBalance from './TeamBalance'
import RoleSelectionIconButton from '@/components/Lobby/RoleSelectionIconButton.vue'
import { mapState } from 'vuex'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import CharacterData from '@/data/character-data'
import FakePlayerData from '@/data/fake-player-data'

export default {
  name: 'RoleSelectionIconButtonDev',
  components: {
    BootstrapSelect,
    RoleSelectionIconButton,
  },
  extends: DevComponentContainer,
  data() {
    const playerChosenDataOptions = [
      {
        label: 'No player chosen',
        value: '-1',
      },
    ];

    const roleToggled = [];

    for (let i = 0, len = CharacterData.length; i < len; i++) {
      const character = CharacterData[i];

      playerChosenDataOptions.push({
        label: `${character.identifier}`,
        value: i.toString(),
      });

      roleToggled.push(false);
    }

    return {
      playerChosenDataValue: '-1',
      playerChosenDataOptions: playerChosenDataOptions,
      yesNoOptions: [
        {
          label: 'No',
          value: '0',
        },
        {
          label: 'Yes',
          value: '1',
        }
      ],
      selectedValue: '0',
      localSelectedValue: '0',
      roleToggled: roleToggled,
    };
  },
  mounted() {
    if (!this.roles || this.roles.length <= 0) {
      this.$apiClient.role.getRoleData().then((roles) => {
        this.$store.commit('roles', roles);
      });
    }
  },
  computed: {
    ...mapState(['room', 'roles']),
    selected() {
      return parseInt(this.selectedValue, 10) > 0;
    },
    localSelected() {
      return parseInt(this.localSelectedValue, 10) > 0;
    },
    playerChosenData() {
      const playerChosenDataValue = parseInt(this.playerChosenDataValue, 10);

      if (playerChosenDataValue < 0) {
        return null;
      }

      const playerData = FakePlayerData;

      playerData.characterIndex = playerChosenDataValue;

      return playerData;
    },
  },
}
</script>

<style lang="scss" scoped>
.lobby-popup-dev {
  border: 1px solid black;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
}
</style>
