<template>
  <div class="hazard-popup-container" :class="computedClasses">
    <div class="hazard-popup-header d-flex justify-content-center gap-3">
      <slot name="header" />
    </div>
    <div class="hazard-popup-body">
      <div class="hazard-popup-body-inner">
        <!--<header class="hazard-descriptor" v-if="title && icon">
          <div class="hazard-descriptor-name" v-text="title" />
          <icon :icon="icon" size="large" />
        </header>-->
        <section class="content-container">
          <slot name="content" />
        </section>
      </div>
    </div>
    <div class="hazard-popup-buttons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import LongButton from '@/components/common/LongButton.vue'
import { padLeft } from '@/utils/stringUtil'
import HazardActionTab from '@/components/HazardMeeting/HazardPopup/HazardActionTab.vue'
import HazardAction from '@/components/HazardMeeting/HazardPopup/HazardAction.vue'

export default {
  name: 'HazardPopupContainer',
  components: {
    HazardAction,
    LongButton,
    NoDragImage,
    HazardIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    padding: {
      type: String,
      required: false,
      default: 'none',
    },
    centerContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return [
        `hazard-popup-container-size-${this.size}`,
        `hazard-popup-container-padding-${this.padding || 'none'}`,
        `hazard-popup-container-center-content-${this.centerContent ? 'true' : 'false'}`,
        `hazard-popup-container-background-${this.contentBackground ? 'on' : 'off'}`,
      ];
    }
  }
}
</script>

<style lang="scss" scoped>

$width: 1390px;
$height: 847px;

@mixin hazardPopupSize($size, $fraction) {
  &.hazard-popup-container-size-#{$size} {
    width: $width * $fraction;
    height: $height * $fraction;
  }
}

@mixin hazardPopupPadding($size, $padding) {
  &.hazard-popup-container-padding-#{$size} {
    .hazard-popup-body-inner {
      padding: 0 $padding;
    }
  }
}

.hazard-popup-container {
  background: url("@/assets/hazard-popup/hazard-popup-background.png") no-repeat transparent;
  background-size: contain;

  width: $width;
  height: $height;

  position: relative;
  margin: 0 auto;
  padding-top: 10px !important;

  @include hazardPopupSize('sm', 0.80);
  @include hazardPopupSize('md', 0.90);
  @include hazardPopupSize('lg', 1.00);

  @include hazardPopupPadding('none', 0px);
  @include hazardPopupPadding('sm', 20px);
  @include hazardPopupPadding('md', 100px);
  @include hazardPopupPadding('lg', 220px);
  @include hazardPopupPadding('xl', 300px);
}

.hazard-descriptor {
  position: absolute;

  top: -135px;
  left: -120px;

  .hazard-descriptor-name {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: inset -6.95499px -6.95499px 5.56399px rgba(0, 0, 0, 0.4), inset 5.56399px 5.56399px 4.17299px rgba(255, 255, 255, 0.4);

    //background: url("@/assets/hazard-popup/hazard-name-bg.png") no-repeat transparent;
    //background-size: contain;

    font-size: 25pt;
    font-weight: 700;
    color: #ffffff;
    width: 386px;
    height: 81px;
    line-height: 61px;
    padding: 10px 10px 10px 15px;

    position: absolute;
    top: 130px;
    left: 200px;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}


.hazard-popup-container-center-content-true {
  section.content-container {
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hazard-popup-header {
  width: fit-content;
  position: absolute;
  top: 0px;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 15px 40px;
}

.hazard-popup-container-background-on {
  .hazard-popup-body-inner {
    background: rgba(#000000, .4);
    padding: 30px;
  }
}

.hazard-popup-body-inner {
  position: relative;
  color: #FFFFFF;
  padding: 0px;
  font-size: 40px;
  margin: 70px 50px 50px 50px;
  height: 420px;
}
</style>

<style lang="scss">
.hazard-popup-header {
  img {
    max-height: 40px;
  }

  *, & {
    text-transform: uppercase;
    color: white;
    font-size: 25pt !important;
    line-height: 40px;
    letter-spacing: 0.05em !important;
    font-weight: 700 !important;
    padding-top: 9px;
  }
}
</style>
