<template>
  <div class="dashboard-popup-dev">
    <dashboard-popup
      @close="onClose"
      :used-actions-strategic="usedActionsStrategic"
      :used-actions-response="usedActionsResponse"
    />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import DashboardPopup from '@/components/dashboard/DashboardPopup.vue'
import {randomBetween} from '@/utils/randomUtil'
import {getCapacityLevelFromJeeScore} from '@/components/dashboard/CapacityLevelUtil'
import {
  getImplementedActionsFromRoom, implementedActionsToHazardActions,
  implementedActionsToStrategicActions
} from '@/components/dashboard/DashboardUtil';

export default {
  name: 'DashboardPopupDev',
  components: {
    DashboardPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    return {
      jeeScores: {},

      implementedActions: [
        {
          "actionType": "strategic",
          "id": 120,
          "identifier": "SAC1-S1-A1",
          "text": "Establish a unit/functional team at the Ministry of Health & Sports (MoH&S) in charge of alignment between all public health domestic legislation, laws, regulations, policy & administrative requirements including those for compliance with the International Health Regulation (IHR).",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 0,
          "impactHealthSecurity": 4,
          "impactCollaboration": 3,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Health should be considered in all policies of the country to enhance the achievements of the Global Developmental Goals at a national level.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 3},
            "1": {"identifier": "p1.2", "impact": 1},
            "2": {"identifier": "p2.1", "impact": 2},
            "3": {"identifier": "p2.2", "impact": 2},
            "4": {"identifier": "p3.2", "impact": 2},
            "5": {"identifier": "p3.3", "impact": 3},
            "6": {"identifier": "p5.2", "impact": 0},
            "7": {"identifier": "p5.3", "impact": 0},
            "8": {"identifier": "p6.2", "impact": 0},
            "9": {"identifier": "p7.1", "impact": 2},
            "10": {"identifier": "p8.1", "impact": 1},
            "11": {"identifier": "p8.2", "impact": 1},
            "12": {"identifier": "p8.3", "impact": 1},
            "13": {"identifier": "d1.1", "impact": 1},
            "14": {"identifier": "d1.4", "impact": 2},
            "15": {"identifier": "d2.1", "impact": 2},
            "16": {"identifier": "d2.3", "impact": 3},
            "17": {"identifier": "d3.1", "impact": 5},
            "18": {"identifier": "d3.2", "impact": 4},
            "19": {"identifier": "d3.4", "impact": 3},
            "20": {"identifier": "r1.1", "impact": 3},
            "21": {"identifier": "r1.2", "impact": 3},
            "22": {"identifier": "r1.3", "impact": 3},
            "23": {"identifier": "r1.4", "impact": 3},
            "24": {"identifier": "r1.5", "impact": 2},
            "25": {"identifier": "r1.6", "impact": 1},
            "26": {"identifier": "r2.1", "impact": 2},
            "27": {"identifier": "r3.2", "impact": 1},
            "28": {"identifier": "r5.1", "impact": 2},
            "29": {"identifier": "poe1", "impact": 2},
            "30": {"identifier": "poe3", "impact": 2},
            "31": {"identifier": "ce1", "impact": 1},
            "32": {"identifier": "ce2", "impact": 1},
            "33": {"identifier": "re1", "impact": 1},
            "34": {"identifier": "re2", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 121,
          "identifier": "SAC1-S1-A2",
          "text": "Review & enhance legal bases to facilitate procurement of novel vaccines & accelerate their market authorization in the country during emergencies.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Having the legal bases established, will always support you in taking rapid mitigation measures during emergency public health events.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 1},
            "2": {"identifier": "p2.1", "impact": 1},
            "3": {"identifier": "p2.2", "impact": 1},
            "4": {"identifier": "p3.2", "impact": 1},
            "5": {"identifier": "p3.3", "impact": 1},
            "6": {"identifier": "p5.2", "impact": 1},
            "7": {"identifier": "p6.2", "impact": 1},
            "8": {"identifier": "p7.1", "impact": 1},
            "9": {"identifier": "p8.1", "impact": 3},
            "10": {"identifier": "p8.2", "impact": 3},
            "11": {"identifier": "p8.3", "impact": 3},
            "12": {"identifier": "d1.4", "impact": 1},
            "13": {"identifier": "d2.1", "impact": 0},
            "14": {"identifier": "d2.2", "impact": 0},
            "15": {"identifier": "d2.3", "impact": 1},
            "16": {"identifier": "d3.1", "impact": 2},
            "17": {"identifier": "d3.2", "impact": 1},
            "18": {"identifier": "d3.4", "impact": 1},
            "19": {"identifier": "r1.3", "impact": 2},
            "20": {"identifier": "r1.4", "impact": 0},
            "21": {"identifier": "r1.5", "impact": 3},
            "22": {"identifier": "r1.6", "impact": 1},
            "23": {"identifier": "r2.1", "impact": 1},
            "24": {"identifier": "r3.2", "impact": 1},
            "25": {"identifier": "poe2", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 130,
          "identifier": "SAC1-S5-A1",
          "text": "Plan a resources mapping workshop in collaboration with WHO to address the needs for the tournament preparation & map doners & contributors on the ground.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 3,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "You are in shortage of:\n1. Paramedic industry & technologies, \n2. Infrastructure & construction materials industry, \n\nOther highly important needs consider:\n1. IDPs who are at high risk in different areas in Global Land (GL), & \n2. climate change, which is exerting a huge drawback on the country.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 2},
            "1": {"identifier": "p2.1", "impact": 4},
            "2": {"identifier": "p2.2", "impact": 2},
            "3": {"identifier": "p3.2", "impact": 1},
            "4": {"identifier": "p3.3", "impact": 0},
            "5": {"identifier": "r1.4", "impact": 0},
            "6": {"identifier": "r1.6", "impact": 0}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 131,
          "identifier": "SAC1-S5-A2",
          "text": "Review of the health workforce development needs & develop an up-to-date multi-sectoral workforce strategy.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 3,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "You have now mapped all the progressing health related projects in Global Land (GL), & will use information accordingly to be ready for the tournament based on priorities.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.2", "impact": 1},
            "1": {"identifier": "p3.2", "impact": 1},
            "2": {"identifier": "p3.3", "impact": 2},
            "3": {"identifier": "d3.3", "impact": 1},
            "4": {"identifier": "d3.4", "impact": 1},
            "5": {"identifier": "r1.1", "impact": 1},
            "6": {"identifier": "r1.4", "impact": 2}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 132,
          "identifier": "SAC1-S5-A3",
          "text": "Map HR capacities of your health system. Your people are your wealth.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 1,
          "impactHealthSecurity": 1,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "A map of your national health system human Resource will help you take better health workforce related decision.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p3.2", "impact": 0},
            "1": {"identifier": "p3.3", "impact": 1},
            "2": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 170,
          "identifier": "SAC4-S4-A1",
          "text": "Establish a One Health Platform in coordination with human, animal & agriculture ministries.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 3,
          "impactHealthSecurity": 5,
          "impactCollaboration": 5,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "You successfully established the One Health Platform bringing together representatives from animal, agriculture, fisheries & human health sectors using a methodology developed by WHO & World Health Organizations for Animals.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p2.2", "impact": 1},
            "2": {"identifier": "p3.2", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1},
            "4": {"identifier": "p4.5", "impact": 1},
            "5": {"identifier": "p7.1", "impact": 1},
            "6": {"identifier": "d3.1", "impact": 1},
            "7": {"identifier": "poe1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 171,
          "identifier": "SAC4-S4-A2",
          "text": "In coordination with one health platform, conduct an IHR-PVS National Bridging Workshop between the relevant sectors.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 3,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "72 stakeholders from the animal health & the human health sectors in collaboration with agriculture & environmental specialists met during this 3 day event. \nThey assessed their collaboration for key technical areas. They established a joint roadmap to improve collaboration & coordination mechanisms at the animal-human interface between the represented sectors.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p2.1", "impact": 1},
            "1": {"identifier": "p2.2", "impact": 1},
            "2": {"identifier": "p3.2", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1},
            "4": {"identifier": "p4.5", "impact": 0},
            "5": {"identifier": "p7.1", "impact": 1},
            "6": {"identifier": "d2.1", "impact": 1},
            "7": {"identifier": "d2.3", "impact": 1},
            "8": {"identifier": "d3.1", "impact": 1},
            "9": {"identifier": "r2.1", "impact": 1},
            "10": {"identifier": "r5.3", "impact": 1},
            "11": {"identifier": "poe1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 122,
          "identifier": "SAC1-S2-A1",
          "text": "Develop action plans for multisectoral/multidisciplinary coordination & communication mechanisms in a variety of settings including during times of high risk, deliberate events & mass gathering events.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 4,
          "impactHealthSecurity": 4,
          "impactCollaboration": 4,
          "impactMgEventSuccess": 5,
          "feedbackNarrative": "Whole of Government approach would always support the results of the actions taken towards public health plans for the best of Global Land (GL) people.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 5,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 0},
            "1": {"identifier": "p1.2", "impact": 0},
            "2": {"identifier": "p2.1", "impact": 3},
            "3": {"identifier": "p2.2", "impact": 2},
            "4": {"identifier": "p3.1", "impact": 1},
            "5": {"identifier": "p3.2", "impact": 3},
            "6": {"identifier": "p3.3", "impact": 4},
            "7": {"identifier": "p7.1", "impact": 1},
            "8": {"identifier": "p8.2", "impact": 1},
            "9": {"identifier": "d2.3", "impact": 1},
            "10": {"identifier": "d3.1", "impact": 3},
            "11": {"identifier": "d3.2", "impact": 1},
            "12": {"identifier": "d3.4", "impact": 1},
            "13": {"identifier": "r1.5", "impact": 1},
            "14": {"identifier": "r1.6", "impact": 1},
            "15": {"identifier": "r5.2", "impact": 1},
            "16": {"identifier": "r5.3", "impact": 1},
            "17": {"identifier": "poe1", "impact": 1},
            "18": {"identifier": "poe3", "impact": 1},
            "19": {"identifier": "ce1", "impact": 1},
            "20": {"identifier": "re1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 123,
          "identifier": "SAC1-S2-A2",
          "text": "Review National Legislation, Policy & Financing on International Health Regulation (IHR) implementation.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "• Sustained financing for the strategic plans will help you reach the best results on the long run.\n\n• Partner support is also an important factor, keep an eye on it.\n\n• Do not forget the great role of the community & the private sectors in Global Land (GL).",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 5,
          "investAtMeeting": 1,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 1},
            "2": {"identifier": "p2.1", "impact": 4},
            "3": {"identifier": "p2.2", "impact": 2},
            "4": {"identifier": "p3.1", "impact": 3},
            "5": {"identifier": "p3.2", "impact": 0},
            "6": {"identifier": "p3.3", "impact": 2},
            "7": {"identifier": "p7.1", "impact": 1},
            "8": {"identifier": "d1.4", "impact": 1},
            "9": {"identifier": "d3.1", "impact": 1},
            "10": {"identifier": "d3.2", "impact": 1},
            "11": {"identifier": "d3.3", "impact": 1},
            "12": {"identifier": "d3.4", "impact": 1},
            "13": {"identifier": "r1.3", "impact": 1},
            "14": {"identifier": "r1.5", "impact": 1},
            "15": {"identifier": "r1.6", "impact": 1},
            "16": {"identifier": "r3.2", "impact": 2},
            "17": {"identifier": "r3.3", "impact": 2},
            "18": {"identifier": "r5.1", "impact": 1},
            "19": {"identifier": "poe1", "impact": 1},
            "20": {"identifier": "ce1", "impact": 1},
            "21": {"identifier": "re1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "hazard",
          "id": 409,
          "identifier": "HRC15-S2-A1",
          "text": "Continue the communication with WHO on the updates of the ship outbreak through IHR NFP & the country & regional offices IHR contact points.",
          "baseCost": 1,
          "baseHazardImpact": 1,
          "cost": 1,
          "impactTrust": 0,
          "impactHealthSecurity": 0,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "• Continued information sharing with WHO under IHR 2005 is essential to provide Global Land (GL) with all the essential technical support in time.\n\n• A mass gathering team of experts in WHO can always support during such huge events like the handball tournament in Global Land.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 4,
          "investAtMeeting": 1,
          "jeeImpacts": {},
          "aoeImpacts": {"0": {"identifier": "IHR", "impact": 0}}
        },
        {
          "actionType": "hazard",
          "id": 410,
          "identifier": "HRC15-S2-A2",
          "text": "Check the EIS website to get informed about various emergencies currently reported that could exert any risk on the tournament.",
          "baseCost": 1,
          "baseHazardImpact": 1,
          "cost": 1,
          "impactTrust": 0,
          "impactHealthSecurity": 0,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Getting ready to the tournament by assessing the possible health risks and updating your risk assessment on regular basis is essential for successful mass gathering events.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 4,
          "investAtMeeting": 1,
          "jeeImpacts": {},
          "aoeImpacts": {"0": {"identifier": "IHR", "impact": 0}}
        },
        {
          "actionType": "strategic",
          "id": 136,
          "identifier": "SAC2-S2-A1",
          "text": "Assess Tjemara water & sanitation systems.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 4,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 4,
          "feedbackNarrative": "It will help you define the required upgrades in light of the tournament holding.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 7,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p3.2", "impact": 1},
            "1": {"identifier": "p3.3", "impact": 2},
            "2": {"identifier": "p4.4", "impact": 0},
            "3": {"identifier": "p4.5", "impact": 0},
            "4": {"identifier": "p5.1", "impact": 1},
            "5": {"identifier": "p5.2", "impact": 1},
            "6": {"identifier": "p5.3", "impact": 1},
            "7": {"identifier": "p6.1", "impact": 1},
            "8": {"identifier": "p6.2", "impact": 1},
            "9": {"identifier": "d1.1", "impact": 1},
            "10": {"identifier": "d1.2", "impact": 0},
            "11": {"identifier": "d1.3", "impact": 0},
            "12": {"identifier": "d1.4", "impact": 0},
            "13": {"identifier": "d2.1", "impact": 2},
            "14": {"identifier": "d2.2", "impact": 2},
            "15": {"identifier": "d2.3", "impact": 1},
            "16": {"identifier": "r1.1", "impact": 1},
            "17": {"identifier": "r2.1", "impact": 1},
            "18": {"identifier": "r3.1", "impact": 1},
            "19": {"identifier": "r5.1", "impact": 0},
            "20": {"identifier": "r5.2", "impact": 0}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 137,
          "identifier": "SAC2-S2-A2",
          "text": "Plan for more food inspection activities across the main touristic cities in Global Land (GL).",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 3,
          "impactHealthSecurity": 3,
          "impactCollaboration": 3,
          "impactMgEventSuccess": 4,
          "feedbackNarrative": "It will be helpful in maintaining good food stalls during the event and avoid foodborne outbreaks.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p3.2", "impact": 1},
            "1": {"identifier": "p3.3", "impact": 2},
            "2": {"identifier": "p4.4", "impact": 0},
            "3": {"identifier": "p4.5", "impact": 0},
            "4": {"identifier": "p5.1", "impact": 2},
            "5": {"identifier": "p5.2", "impact": 1},
            "6": {"identifier": "p5.3", "impact": 2},
            "7": {"identifier": "p6.1", "impact": 3},
            "8": {"identifier": "p6.2", "impact": 3},
            "9": {"identifier": "p8.3", "impact": 1},
            "10": {"identifier": "d1.1", "impact": 2},
            "11": {"identifier": "d1.2", "impact": 0},
            "12": {"identifier": "d1.3", "impact": 0},
            "13": {"identifier": "d1.4", "impact": 0},
            "14": {"identifier": "d2.1", "impact": 2},
            "15": {"identifier": "d2.2", "impact": 2},
            "16": {"identifier": "d2.3", "impact": 2},
            "17": {"identifier": "r1.1", "impact": 2},
            "18": {"identifier": "r2.1", "impact": 1},
            "19": {"identifier": "r3.1", "impact": 1},
            "20": {"identifier": "r5.1", "impact": 0},
            "21": {"identifier": "r5.2", "impact": 0},
            "22": {"identifier": "poe1", "impact": 1},
            "23": {"identifier": "poe3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 138,
          "identifier": "SAC2-S2-A3",
          "text": "Develop standards for food & beverages advertisement in Global Land (GL), including stadium & fan zones.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "This can support healthier behaviors on the long run and stop falsified or untrusted food advertisement.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p3.2", "impact": 1},
            "2": {"identifier": "p3.3", "impact": 1},
            "3": {"identifier": "p4.4", "impact": 1},
            "4": {"identifier": "p4.5", "impact": 1},
            "5": {"identifier": "p5.1", "impact": 1},
            "6": {"identifier": "p5.2", "impact": 1},
            "7": {"identifier": "p5.3", "impact": 1},
            "8": {"identifier": "p6.1", "impact": 1},
            "9": {"identifier": "p6.2", "impact": 1},
            "10": {"identifier": "p8.3", "impact": 1},
            "11": {"identifier": "d1.1", "impact": 1},
            "12": {"identifier": "d2.1", "impact": 1},
            "13": {"identifier": "d2.3", "impact": 1},
            "14": {"identifier": "r1.1", "impact": 0},
            "15": {"identifier": "r2.1", "impact": 1},
            "16": {"identifier": "r3.1", "impact": 1},
            "17": {"identifier": "r5.1", "impact": 1},
            "18": {"identifier": "r5.2", "impact": 1},
            "19": {"identifier": "poe1", "impact": 1},
            "20": {"identifier": "poe3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 257,
          "identifier": "SAC15-S2-A2",
          "text": "Identify key government agencies involved in the response & management of outbreak emergencies and build connections based on a joint framework for work.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 1,
          "impactHealthSecurity": 1,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "This step will increase the awareness of the relevant sectors and stakeholders about their various functions regarding health security in times of peace and will ease their involvement in times of emergency.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 6,
          "investAtMeeting": 2,
          "jeeImpacts": {},
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 258,
          "identifier": "SAC15-S2-A3",
          "text": "Establish the IHR NFP center in the capital as you do not have one yet & increase the number of responsible members to perform all the required functions of NFP to help Global Land (GL) implement IHR & better approach health security. ",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "IHR NFP centre as a structure shall provide more concise and clear view over the functions and responsibilities of its members.\nRather than having the few members working as NFP wearing multiple hats, there will be dedicated numbers of staff with specific functions to support all NFP functions and help in implementing IHR capacities as mandated by IHR 2005 and its amendments.\nThis will also show the gaps and needs in regards to the IHR NFP centre functions and role in Global Land.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 6,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p2.1", "impact": 1},
            "2": {"identifier": "p2.2", "impact": 1},
            "3": {"identifier": "p3.1", "impact": 2},
            "4": {"identifier": "p3.2", "impact": 1},
            "5": {"identifier": "p3.3", "impact": 1},
            "6": {"identifier": "d2.2", "impact": 1},
            "7": {"identifier": "d2.3", "impact": 1},
            "8": {"identifier": "d3.2", "impact": 1},
            "9": {"identifier": "d3.4", "impact": 1},
            "10": {"identifier": "r1.1", "impact": 1},
            "11": {"identifier": "r1.2", "impact": 1},
            "12": {"identifier": "r1.3", "impact": 1},
            "13": {"identifier": "r1.4", "impact": 1},
            "14": {"identifier": "r2.1", "impact": 1},
            "15": {"identifier": "poe1", "impact": 1},
            "16": {"identifier": "poe3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 287,
          "identifier": "SAC15-S2-A1",
          "text": "Implement SOPs for communicating between the NFP members responsible & relevant sectors including those responsible for surveillance & reporting, points of entry, public health services, clinics & hospitals & other government departments/ministries & review performance regularly.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 1,
          "impactHealthSecurity": 1,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "The linkages between the governmental sectors and the various responders on the ground in times of emergencies are of invaluable importance.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 6,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 1},
            "2": {"identifier": "p2.1", "impact": 1},
            "3": {"identifier": "p2.2", "impact": 1},
            "4": {"identifier": "p3.1", "impact": 2},
            "5": {"identifier": "p3.2", "impact": 1},
            "6": {"identifier": "p3.3", "impact": 1},
            "7": {"identifier": "d2.1", "impact": 1},
            "8": {"identifier": "d2.2", "impact": 1},
            "9": {"identifier": "d2.3", "impact": 1},
            "10": {"identifier": "d3.1", "impact": 1},
            "11": {"identifier": "r1.1", "impact": 1},
            "12": {"identifier": "r1.3", "impact": 1},
            "13": {"identifier": "r1.4", "impact": 1},
            "14": {"identifier": "r2.1", "impact": 1},
            "15": {"identifier": "r5.1", "impact": 1},
            "16": {"identifier": "poe1", "impact": 1},
            "17": {"identifier": "poe2", "impact": 1},
            "18": {"identifier": "poe3", "impact": 1},
            "19": {"identifier": "ce1", "impact": 1},
            "20": {"identifier": "re1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 124,
          "identifier": "SAC1-S3-A1",
          "text": "Implement an evaluation of the national routine surveillance systems including data sharing mechanism between the relevant sectors.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 4,
          "impactHealthSecurity": 4,
          "impactCollaboration": 5,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "Integrated Disease Surveillance systems will always facilitate decision making & response in times of emergency.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 6,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 0},
            "1": {"identifier": "p2.1", "impact": 0},
            "2": {"identifier": "p2.2", "impact": 2},
            "3": {"identifier": "p3.1", "impact": 1},
            "4": {"identifier": "p3.2", "impact": 2},
            "5": {"identifier": "p3.3", "impact": 2},
            "6": {"identifier": "p4.1", "impact": 1},
            "7": {"identifier": "p4.2", "impact": 1},
            "8": {"identifier": "p4.3", "impact": 1},
            "9": {"identifier": "p4.4", "impact": 1},
            "10": {"identifier": "p4.5", "impact": 1},
            "11": {"identifier": "p5.1", "impact": 2},
            "12": {"identifier": "p5.2", "impact": 1},
            "13": {"identifier": "p6.1", "impact": 1},
            "14": {"identifier": "p6.2", "impact": 1},
            "15": {"identifier": "p7.1", "impact": 1},
            "16": {"identifier": "p7.2", "impact": 0},
            "17": {"identifier": "p8.1", "impact": 0},
            "18": {"identifier": "p8.2", "impact": 1},
            "19": {"identifier": "p8.3", "impact": 0},
            "20": {"identifier": "d1.1", "impact": 1},
            "21": {"identifier": "d1.2", "impact": 1},
            "22": {"identifier": "d1.4", "impact": 2},
            "23": {"identifier": "d2.1", "impact": 3},
            "24": {"identifier": "d2.2", "impact": 3},
            "25": {"identifier": "d2.3", "impact": 3},
            "26": {"identifier": "d3.1", "impact": 1},
            "27": {"identifier": "d3.4", "impact": 0},
            "28": {"identifier": "r1.1", "impact": 2},
            "29": {"identifier": "r1.2", "impact": 0},
            "30": {"identifier": "r1.3", "impact": 0},
            "31": {"identifier": "r1.4", "impact": 0},
            "32": {"identifier": "r1.5", "impact": 0},
            "33": {"identifier": "r1.6", "impact": 0},
            "34": {"identifier": "r2.1", "impact": 1},
            "35": {"identifier": "r3.2", "impact": 1},
            "36": {"identifier": "r3.3", "impact": 1},
            "37": {"identifier": "r4.1", "impact": 1},
            "38": {"identifier": "r4.2", "impact": 1},
            "39": {"identifier": "r4.3", "impact": 0},
            "40": {"identifier": "r5.1", "impact": 1},
            "41": {"identifier": "r5.3", "impact": 1},
            "42": {"identifier": "poe1", "impact": 1},
            "43": {"identifier": "poe2", "impact": 0},
            "44": {"identifier": "poe3", "impact": 2},
            "45": {"identifier": "ce1", "impact": 1},
            "46": {"identifier": "re1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 125,
          "identifier": "SAC1-S3-A2",
          "text": "Start a national campaign for increasing the awareness & social support to surveillance activities of the various health issues in Global Land amid the handball tournament.",
          "baseCost": 3,
          "baseHazardImpact": 0,
          "cost": 3,
          "impactTrust": 1,
          "impactHealthSecurity": 0,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "This step can support you in case of using active surveillance, event based surveillance as well as in times of deployment of Rapid Response Teams (RRT) and Emergency Medical Teams (EMT).",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 6,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 4},
            "2": {"identifier": "p2.1", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 126,
          "identifier": "SAC1-S3-A3",
          "text": "Assign a team to secretly work on the data analysis of the national surveillance system away from the public eye to avoid public rage during the preparation for the tournament in case of any possible disease related outbreak.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 0,
          "impactHealthSecurity": 1,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Transparency & timely sharing the information to public & with relevant partners & WHO can help protecting your country & other neighbours as well as the global society.\nNo one is safe unless everyone is safe.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 2,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p3.1", "impact": 0},
            "1": {"identifier": "p3.2", "impact": 0},
            "2": {"identifier": "p3.3", "impact": 0},
            "3": {"identifier": "p5.1", "impact": 0},
            "4": {"identifier": "p5.2", "impact": 0},
            "5": {"identifier": "p6.1", "impact": 0},
            "6": {"identifier": "p6.2", "impact": 0},
            "7": {"identifier": "d1.2", "impact": 0},
            "8": {"identifier": "d1.4", "impact": 0},
            "9": {"identifier": "d2.1", "impact": 0},
            "10": {"identifier": "d2.2", "impact": 0},
            "11": {"identifier": "d2.3", "impact": 0},
            "12": {"identifier": "d3.1", "impact": 0},
            "13": {"identifier": "d3.2", "impact": 0},
            "14": {"identifier": "r1.1", "impact": 0},
            "15": {"identifier": "r1.2", "impact": 0},
            "16": {"identifier": "r1.3", "impact": 0},
            "17": {"identifier": "r1.4", "impact": 0},
            "18": {"identifier": "r5.1", "impact": 0},
            "19": {"identifier": "r5.2", "impact": 0},
            "20": {"identifier": "r5.3", "impact": 0}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 214,
          "identifier": "SAC9-S2-A2",
          "text": "Train staff of human, animal (domestic animals & wildlife), & environmental health sectors on the priority diseases emergency operation plan, guidelines, & SOPs at the national, subnational & local levels.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "Now your surveillance staff in the relevant sectors are ready to perform up to standards in reporting public health events during emergencies from any priority disease on the national list.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 5,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p2.2", "impact": 1},
            "1": {"identifier": "p3.1", "impact": 1},
            "2": {"identifier": "p5.1", "impact": 1},
            "3": {"identifier": "p5.2", "impact": 1},
            "4": {"identifier": "d3.3", "impact": 1},
            "5": {"identifier": "r1.1", "impact": 1},
            "6": {"identifier": "r1.3", "impact": 1},
            "7": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 268,
          "identifier": "SAC9-S2-A1",
          "text": "Conduct training for IHR NFPs & all relevant stakeholders involved in implementation of IHR at the national & subnational levels.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 2,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "Training is essential to keep the workforce connected, updated and ready to act in times of any possible emergency.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 3,
          "investAtMeeting": 2,
          "jeeImpacts": {
            "0": {"identifier": "p3.1", "impact": 1},
            "1": {"identifier": "p3.2", "impact": 1},
            "2": {"identifier": "d3.3", "impact": 2},
            "3": {"identifier": "d3.4", "impact": 1},
            "4": {"identifier": "r1.1", "impact": 1},
            "5": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "hazard",
          "id": 409,
          "identifier": "HRC15-S2-A1",
          "text": "Continue the communication with WHO on the updates of the ship outbreak through IHR NFP & the country & regional offices IHR contact points.",
          "baseCost": 1,
          "baseHazardImpact": 1,
          "cost": 3,
          "impactTrust": 0,
          "impactHealthSecurity": 0,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "• Continued information sharing with WHO under IHR 2005 is essential to provide Global Land (GL) with all the essential technical support in time.\n\n• A mass gathering team of experts in WHO can always support during such huge events like the handball tournament in Global Land.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 4,
          "investAtMeeting": 2,
          "jeeImpacts": {},
          "aoeImpacts": {"0": {"identifier": "IHR", "impact": 0}}
        },
        {
          "actionType": "hazard",
          "id": 410,
          "identifier": "HRC15-S2-A2",
          "text": "Check the EIS website to get informed about various emergencies currently reported that could exert any risk on the tournament.",
          "baseCost": 1,
          "baseHazardImpact": 1,
          "cost": 3,
          "impactTrust": 0,
          "impactHealthSecurity": 0,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Getting ready to the tournament by assessing the possible health risks and updating your risk assessment on regular basis is essential for successful mass gathering events.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 4,
          "investAtMeeting": 2,
          "jeeImpacts": {},
          "aoeImpacts": {"0": {"identifier": "IHR", "impact": 0}}
        },
        {
          "actionType": "strategic",
          "id": 147,
          "identifier": "SAC3-S1-A1",
          "text": "Interact with international partners & funders to plan together mitigating highly hazardous health risks facing Global Land (GL) based on the latest Strategic Risk Assessment (STAR).",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 3,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "You have succeeded to secure funds for covering IDP camps health needs.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 7,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p2.1", "impact": 1},
            "1": {"identifier": "p2.2", "impact": 1},
            "2": {"identifier": "p3.1", "impact": 1},
            "3": {"identifier": "p3.2", "impact": 2},
            "4": {"identifier": "p3.3", "impact": 2},
            "5": {"identifier": "p7.1", "impact": 0},
            "6": {"identifier": "p8.1", "impact": 0},
            "7": {"identifier": "p8.3", "impact": 0},
            "8": {"identifier": "d2.3", "impact": 0},
            "9": {"identifier": "r1.1", "impact": 0},
            "10": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 148,
          "identifier": "SAC3-S1-A2",
          "text": "Purchase new city tour shuttle buses to be used during the tournament in advertising while moving the fans across the capital city.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 0,
          "impactHealthSecurity": 0,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "This will ease the commuting for tourists and help in leaving good impression about tourism in Global Land (GL)to bring you more tourists later.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {},
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 149,
          "identifier": "SAC3-S1-A3",
          "text": "Use Infection Prevention & Control (IPC) assessment tools (IPCAT) to assess the core components of IPC programmes at the national & facility levels to identify precise required actions & allocate proper resources.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "This will help in prioritization on funding the essential Infection Prevention Control needs of the health facilities at different levels.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p3.3", "impact": 1},
            "1": {"identifier": "d1.2", "impact": 1},
            "2": {"identifier": "d1.3", "impact": 1},
            "3": {"identifier": "d1.4", "impact": 1},
            "4": {"identifier": "r4.1", "impact": 2}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 295,
          "identifier": "SAC13-S1-A1",
          "text": "Establish SOPs for communication among relevant sectors, including thresholds for reporting & response.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 1,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "Training of the users on these SOPs is needed to ensure proper utilization.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 4,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 1},
            "2": {"identifier": "p2.1", "impact": 1},
            "3": {"identifier": "p3.1", "impact": 1},
            "4": {"identifier": "p3.2", "impact": 1},
            "5": {"identifier": "p3.3", "impact": 1},
            "6": {"identifier": "d2.1", "impact": 1},
            "7": {"identifier": "d2.2", "impact": 1},
            "8": {"identifier": "d2.3", "impact": 1},
            "9": {"identifier": "d3.1", "impact": 1},
            "10": {"identifier": "d3.2", "impact": 1},
            "11": {"identifier": "r1.4", "impact": 1},
            "12": {"identifier": "r5.1", "impact": 1},
            "13": {"identifier": "poe2", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 164,
          "identifier": "SAC4-S2-A1",
          "text": "Review the national surveillance outbreak investigations gaps based on the latest years reports.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 2,
          "impactHealthSecurity": 4,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "This is a good step for readiness activities. You have performed an assessment & list of priority core capacity tasks have been identified for all Global Land points of entry.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p2.1", "impact": 1},
            "1": {"identifier": "p3.1", "impact": 1},
            "2": {"identifier": "p3.2", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1},
            "4": {"identifier": "d2.3", "impact": 1},
            "5": {"identifier": "d3.1", "impact": 1},
            "6": {"identifier": "r2.1", "impact": 1},
            "7": {"identifier": "poe1", "impact": 2},
            "8": {"identifier": "poe2", "impact": 1},
            "9": {"identifier": "poe3", "impact": 1},
            "10": {"identifier": "ce1", "impact": 1},
            "11": {"identifier": "re1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 165,
          "identifier": "SAC4-S2-A2",
          "text": "Establishing an electronic laboratory specimen tracking system.",
          "baseCost": 3,
          "baseHazardImpact": 0,
          "cost": 3,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 3,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "This is essential especially during emergency management. \nIt will help in timely communication around samples collected and sent for testing, its results and interpretations and will improve the quality of your surveillance system.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 5,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p2.1", "impact": 1},
            "1": {"identifier": "p3.2", "impact": 1},
            "2": {"identifier": "p3.3", "impact": 1},
            "3": {"identifier": "p4.2", "impact": 1},
            "4": {"identifier": "p5.1", "impact": 1},
            "5": {"identifier": "p6.1", "impact": 1},
            "6": {"identifier": "d1.1", "impact": 3},
            "7": {"identifier": "d1.2", "impact": 1},
            "8": {"identifier": "d1.4", "impact": 1},
            "9": {"identifier": "d2.1", "impact": 1},
            "10": {"identifier": "d2.2", "impact": 1},
            "11": {"identifier": "d2.3", "impact": 1},
            "12": {"identifier": "r1.1", "impact": 1},
            "13": {"identifier": "r4.1", "impact": 1},
            "14": {"identifier": "poe1", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 166,
          "identifier": "SAC4-S2-A3",
          "text": "Conduct a thorough health inspection of the main tourist facilities: hotels, restaurants, and camping grounds.",
          "baseCost": 3,
          "baseHazardImpact": 0,
          "cost": 3,
          "impactTrust": 3,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "Will be useful during tourist & event management to deal with any gaps detected regarding Water and Sanitation System and any basic life support and health services available for visitors at these places.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p3.2", "impact": 1},
            "1": {"identifier": "p3.3", "impact": 1},
            "2": {"identifier": "d2.1", "impact": 1},
            "3": {"identifier": "d3.1", "impact": 1},
            "4": {"identifier": "r1.1", "impact": 1},
            "5": {"identifier": "r1.3", "impact": 1},
            "6": {"identifier": "r5.3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 127,
          "identifier": "SAC1-S4-A1",
          "text": "Review baseline National laboratory capacities, & assign a national reference laboratory.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 0,
          "impactHealthSecurity": 5,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 3,
          "feedbackNarrative": "Planning based on information and evidence from the ground helps you in best resource utilization and allocation.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 2},
            "1": {"identifier": "p2.1", "impact": 4},
            "2": {"identifier": "p2.2", "impact": 2},
            "3": {"identifier": "p3.2", "impact": 1},
            "4": {"identifier": "p3.3", "impact": 0},
            "5": {"identifier": "d1.1", "impact": 1},
            "6": {"identifier": "d1.2", "impact": 1},
            "7": {"identifier": "d1.3", "impact": 2},
            "8": {"identifier": "d1.4", "impact": 1},
            "9": {"identifier": "d2.1", "impact": 1},
            "10": {"identifier": "r1.3", "impact": 1},
            "11": {"identifier": "r1.4", "impact": 2}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 128,
          "identifier": "SAC1-S4-A2",
          "text": "Upgrade laboratory system capacities across the country including equipment & HR.",
          "baseCost": 4,
          "baseHazardImpact": 0,
          "cost": 4,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "You have now assigned a committee to exp& the assessment across the country & work on decentralization of laboratory capacities & services.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p1.2", "impact": 4},
            "2": {"identifier": "p2.1", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1},
            "4": {"identifier": "d1.1", "impact": 1},
            "5": {"identifier": "d1.2", "impact": 2},
            "6": {"identifier": "d1.3", "impact": 2},
            "7": {"identifier": "d1.4", "impact": 2},
            "8": {"identifier": "r1.3", "impact": 1},
            "9": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 129,
          "identifier": "SAC1-S4-A3",
          "text": "Equip a reference laboratory for routine diagnosis.",
          "baseCost": 3,
          "baseHazardImpact": 0,
          "cost": 3,
          "impactTrust": 1,
          "impactHealthSecurity": 2,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "Great. This is essential for laboratory capacity building in Global Land.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 1,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 2},
            "1": {"identifier": "p2.1", "impact": 4},
            "2": {"identifier": "p2.2", "impact": 2},
            "3": {"identifier": "p3.2", "impact": 1},
            "4": {"identifier": "p3.3", "impact": 0},
            "5": {"identifier": "d1.1", "impact": 0},
            "6": {"identifier": "d1.2", "impact": 1},
            "7": {"identifier": "d1.3", "impact": 1},
            "8": {"identifier": "d1.4", "impact": 1},
            "9": {"identifier": "d2.1", "impact": 1},
            "10": {"identifier": "r1.3", "impact": 1},
            "11": {"identifier": "r1.4", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 152,
          "identifier": "SAC3-S3-A1",
          "text": "Conduct a needs assessment at the national central reference laboratory.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 2,
          "impactHealthSecurity": 3,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "Action helpful in identifying gaps  & useful in resources allocation.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 3,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p2.1", "impact": 1},
            "1": {"identifier": "p2.2", "impact": 1},
            "2": {"identifier": "p3.3", "impact": 2},
            "3": {"identifier": "d1.1", "impact": 1},
            "4": {"identifier": "d1.2", "impact": 2},
            "5": {"identifier": "d1.3", "impact": 4},
            "6": {"identifier": "d1.4", "impact": 3},
            "7": {"identifier": "d2.1", "impact": 3},
            "8": {"identifier": "d2.2", "impact": 2},
            "9": {"identifier": "d2.3", "impact": 1},
            "10": {"identifier": "r3.3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 153,
          "identifier": "SAC3-S3-A2",
          "text": "Update SOPs for specimen referrals, transportation & pre-position packaging at the national central reference laboratory.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 1,
          "impactHealthSecurity": 2,
          "impactCollaboration": 1,
          "impactMgEventSuccess": 2,
          "feedbackNarrative": "SoPs are helpful in efficient coordination.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 3,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.1", "impact": 1},
            "1": {"identifier": "p2.1", "impact": 1},
            "2": {"identifier": "p2.2", "impact": 2},
            "3": {"identifier": "p3.2", "impact": 1},
            "4": {"identifier": "p3.3", "impact": 1},
            "5": {"identifier": "d1.1", "impact": 3},
            "6": {"identifier": "d1.2", "impact": 2},
            "7": {"identifier": "d1.3", "impact": 1},
            "8": {"identifier": "d1.4", "impact": 3},
            "9": {"identifier": "d2.1", "impact": 2},
            "10": {"identifier": "d2.2", "impact": 1},
            "11": {"identifier": "d2.3", "impact": 1},
            "12": {"identifier": "d3.1", "impact": 1},
            "13": {"identifier": "d3.2", "impact": 0},
            "14": {"identifier": "r1.5", "impact": 0}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 154,
          "identifier": "SAC3-S3-A3",
          "text": "Hire new cleaning staff for the national central reference laboratory facility.",
          "baseCost": 1,
          "baseHazardImpact": 0,
          "cost": 1,
          "impactTrust": 0,
          "impactHealthSecurity": 1,
          "impactCollaboration": 0,
          "impactMgEventSuccess": 0,
          "feedbackNarrative": "It will be useful for maintaining good hygienic conditions in the laboratory.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 7,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "d1.2", "impact": 1},
            "1": {"identifier": "d3.2", "impact": 1},
            "2": {"identifier": "r3.3", "impact": 1},
            "3": {"identifier": "r4.1", "impact": 2},
            "4": {"identifier": "r4.3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        },
        {
          "actionType": "strategic",
          "id": 206,
          "identifier": "SAC8-S3-A2",
          "text": "Purchase high priority laboratory kits & protective gear to upgrade readiness level of local national laboratories of the marine port governorate.",
          "baseCost": 2,
          "baseHazardImpact": 0,
          "cost": 2,
          "impactTrust": 1,
          "impactHealthSecurity": 2,
          "impactCollaboration": 2,
          "impactMgEventSuccess": 1,
          "feedbackNarrative": "Occupational health is a very essential element of the work standards you establish.",
          "openAnnex2OnImplementation": false,
          "learningObjectiveId": 3,
          "investAtMeeting": 3,
          "jeeImpacts": {
            "0": {"identifier": "p1.2", "impact": 1},
            "1": {"identifier": "p2.1", "impact": 1},
            "2": {"identifier": "p2.2", "impact": 1},
            "3": {"identifier": "p3.3", "impact": 1},
            "4": {"identifier": "p4.3", "impact": 1},
            "5": {"identifier": "p5.2", "impact": 1},
            "6": {"identifier": "p6.2", "impact": 1},
            "7": {"identifier": "p7.1", "impact": 1},
            "8": {"identifier": "p7.2", "impact": 0},
            "9": {"identifier": "d1.1", "impact": 1},
            "10": {"identifier": "d1.2", "impact": 1},
            "11": {"identifier": "r1.1", "impact": 1},
            "12": {"identifier": "r1.3", "impact": 1},
            "13": {"identifier": "r1.4", "impact": 1},
            "14": {"identifier": "r1.5", "impact": 2},
            "15": {"identifier": "r3.1", "impact": 1},
            "16": {"identifier": "r3.2", "impact": 1},
            "17": {"identifier": "r3.3", "impact": 1},
            "18": {"identifier": "r4.1", "impact": 2},
            "19": {"identifier": "r4.2", "impact": 1},
            "20": {"identifier": "r4.3", "impact": 2},
            "21": {"identifier": "r5.1", "impact": 1},
            "22": {"identifier": "r5.2", "impact": 1},
            "23": {"identifier": "r5.3", "impact": 1}
          },
          "aoeImpacts": {},
          "inResponseTo": "Strategic meeting"
        }
      ],

      usedActionsStrategic: null,
      usedActionsResponse: null,
      events: ['close'],
    }
  },
  computed: {
    implementedActions() {
      return getImplementedActionsFromRoom(this.room);
    },

    usedActionsStrategic() {
      return implementedActionsToStrategicActions(this.implementedActions);
    },

    usedActionsResponse() {
      return implementedActionsToHazardActions(this.implementedActions);
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';

.dashboard-popup-dev {
  border: 1px solid black;
  margin: 0 auto;
  width: 100%;
  height: 600px;
  max-height: $appHeight * 0.6; //60vh;
}
</style>
