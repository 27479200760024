import OverlayPop from './OverlayPop.vue'
import { OverlayPopController } from '@/plugins/OverlayPop/OverlayPopController'

export function getOverlayPop() {
  return {
    install(app) {
      app.component('overlay-pop', OverlayPop);

      app.config.globalProperties.$overlayPop = new OverlayPopController();
    }
  }
}
