<template>
  <div class="player-card">
    <div class="character-container">
      <character-plate
        :name="playerData.name"
        :character-index="playerData.characterIndex"
        :show-voice-status="false"
        :voice-frequency="playerData.voiceFrequencyDecay"
        :role-points-total="rolePointsTotal"
        :role-points="playerData.rolePoints"
        :role-points-total-investment="playerData.rolePointsTotalInvestment"
        :role="computedRole"
        :player-type="playerData.playerType"
        :has-joined="!!playerData.sessionId"
        :muted="playerData.muted"
        :local-muted="playerData.isSelf ? (playerData.localMuted || playerData.muted) : playerData.localMuted"
        :is-self="playerData.isSelf"
        :is-ready="playerData.isReady"
        :flag-count="playerData.isSelf ? playerData.flagCount : -1"
        :flag-count-total="flagCountTotal"
        @updated:local-mute="onLocalMuteChange"
        :size="size"
        :waiting-for="waitingFor"
      />
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage'

import CharacterData from '@/data/character-data'
import BootstrapProgress from '@/components/bootstrap/BootstrapProgress'

import RobotAvatar from '@/assets/ui/avatar/robot-avatar.png';
import { PLAYER_TYPE_ROBOT } from '@/enum/PlayerType'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import { mapState } from 'vuex'
import { objectToArray } from '@/utils/arrayUtil'

export default {
  name: 'PlayerCharacterPlate',
  components: {
    CharacterPlate,
  },
  props: {
    playerData: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
    waitingFor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(['roles', 'rolePointsTotal']),
    computedRole() {
      if (this.roles.length <= 0) {
        return null;
      }

      if (this.playerData.roleIndex < 0) {
        return null;
      }

      return this.roles[this.playerData.roleIndex];
    },
    playerUid() {
      return this.playerData.sessionId;
    },
    flagCountTotal() {
      if (!this.playerData || !this.playerData.worldHazardFlags || !this.playerData.worldHazardFlags.length) {
        return 0;
      }

      const worldHazardFlags = objectToArray(this.playerData.worldHazardFlags);

      let totalFlagCount = this.playerData.flagCount || 0;

      for (const worldFlag of worldHazardFlags) {
        totalFlagCount += worldFlag.amount;
      }

      return totalFlagCount;
    }
  },
  methods: {
    onLocalMuteChange(localMuted) {
      if (this.playerData.isSelf) {
        this.$gameClient.playerUpdate('muted', localMuted);

        this.$voiceController.setLocalStreamMuted(localMuted);
      } else {
        this.$store.commit('roomSetPlayerLocalData', {
          playerUid: this.playerData.playerCode,
          data: {
            localMuted: localMuted
          },
        });

        this.$voiceController.setParticipantVolume(this.playerData.voiceSocketId, localMuted ? 0 : 1);
      }
    }
  }
}
</script>
