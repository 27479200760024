<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'bootstrap-progress',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>
