import { getSortedTutorialsByGroupIdentifier } from '@/lib/tutorials'
import {
  HIGHLIGHT_TYPE_ELEMENT,
  POPUP_ANCHOR_AUTO,
  POPUP_ANCHOR_X_LEFT,
  POPUP_ANCHOR_X_MIDDLE,
  POPUP_ANCHOR_X_RIGHT,
  POPUP_ANCHOR_Y_TOP,
  POPUP_ANCHOR_Y_MIDDLE,
  POPUP_ANCHOR_BOTTOM, POPUP_ANCHOR_Y_BOTTOM,
} from '@/plugins/TutorialHighlightOverlay/enums';
import {nextTick} from 'vue';
import store from '@/store'; // Adjust the import path to your Vuex store

class TutorialUtilClass {
  static _instance = undefined;

  static instance() {
    if (!this._instance) {
      this._instance = new TutorialUtilClass();
    }

    return this._instance;
  }

  $tutorialHighlightOverlay = undefined;
  cancellationToken = { cancelled: false };

  setTutorialHighlightOverlay($tutorialHighlightOverlay) {
    this.$tutorialHighlightOverlay = $tutorialHighlightOverlay;
  }

  isTutorialEnabled() {
    return parseInt(process.env.VUE_APP_SKIP_TUTORIAL, 10) <= 0;
  }

  async waitABit() {
    return new Promise(resolve => {
      setTimeout(() => {
        nextTick(() => {
          requestAnimationFrame(() => {
            resolve();
          });
        });
      }, 1);
    });
  }

  async close() {
    console.log('TutorialUtil.close');

    // Cancel any existing tutorial
    if (this.cancellationToken) {
      this.cancellationToken.cancelled = true;

      this.cancellationToken = undefined;
    }

    this.$tutorialHighlightOverlay.close();
  }

  isTutorialGroupFinished(tutorialGroupIdentifier) {
    if (!store?.state?.tutorialGroupIdentifiersOpened) {
      return;
    }

    return store.state.tutorialGroupIdentifiersOpened.includes(tutorialGroupIdentifier);
  }

  isTutorialFinished(tutorialIdentifier) {
    if (!store?.state?.tutorialIdentifiersOpened) {
      return;
    }

    return store.state.tutorialGroupIdentifiersOpened.includes(tutorialIdentifier);
  }

  async launchTutorialGroupByIdentifier(tutorialGroupIdentifier) {
    if (!this.isTutorialEnabled()) {
      return;
    }

    console.log('TutorialUtil.launchTutorialGroupByIdentifier', tutorialGroupIdentifier);

    await this.close();

    // Create a new cancellation token for this tutorial
    this.cancellationToken = { cancelled: false };
    const currentToken = this.cancellationToken;

    this.$tutorialHighlightOverlay.close();

    await this.waitABit();

    await this.launchTutorialGroupByIdentifierInternal(tutorialGroupIdentifier, currentToken);
  }

  async launchTutorialGroupByIdentifierInternal(tutorialGroupIdentifier, currentToken) {
    if (!this.isTutorialEnabled()) {
      return;
    }

    console.log('TutorialUtil.launchTutorialGroupByIdentifierInternal', tutorialGroupIdentifier, currentToken);

    // Check if the tutorial group has already been opened
    if (store.getters.tutorialGroupIdentifiersOpened.includes(tutorialGroupIdentifier)) {
      console.log(`TutorialUtil.Tutorial group ${tutorialGroupIdentifier} has already been opened.`);

      return;
    }

    if (!this.$tutorialHighlightOverlay) {
      throw new Error(`No $tutorialHighlightOverlay specified`);
    }

    if (!tutorialGroupIdentifier) {
      throw new Error(`No tutorialGroupIdentifier specified`);
    }

    const tutorials = getSortedTutorialsByGroupIdentifier(tutorialGroupIdentifier);

    for (const tutorial of tutorials) {
      if (currentToken.cancelled) {
        return;
      }

      // Check if the individual tutorial has already been opened
      if (store.getters.tutorialIdentifiersOpened.includes(tutorial.identifierFull)) {
        console.log(`TutorialUtil.Tutorial ${tutorial.identifierFull} has already been opened.`);

        continue;
      }

      const element = document.querySelector(tutorial.element);

      if (!element) {
        console.error(`TutorialUtil.Could not find tutorial element by selector ${tutorial.element}`);

        //console.error(`Could not find tutorial element by selector ${tutorial.element}`);

        continue;
      }

      console.log('TutorialUtil.Building and popping', tutorial);

      let popupAnchor = POPUP_ANCHOR_AUTO;
      let offset = {
        x: 0,
        y: 0
      };

      const staticAnchors = {
        //'mainUi.overallScores': { x: POPUP_ANCHOR_X_MIDDLE, y: POPUP_ANCHOR_Y_TOP },
        //'mainUi.actionPoints': { x: POPUP_ANCHOR_X_LEFT, y: POPUP_ANCHOR_Y_TOP },
      }

      if (staticAnchors[tutorial.identifierFull]) {
        popupAnchor = staticAnchors[tutorial.identifierFull];
      }

      if (tutorial.staticAnchor) {
        const staticAnchorMatch = tutorial.staticAnchor.match(/^(Left|Middle|Right)(Top|Middle|Bottom)$/i);

        popupAnchor = {
          x: POPUP_ANCHOR_X_MIDDLE,
          y: POPUP_ANCHOR_Y_MIDDLE,
        }

        switch (staticAnchorMatch[1].toLowerCase()) {
          case 'left':
            popupAnchor.x = POPUP_ANCHOR_X_LEFT;

            break;
          case 'middle':
            popupAnchor.x = POPUP_ANCHOR_X_MIDDLE;

            break;
          case 'right':
            popupAnchor.x = POPUP_ANCHOR_X_RIGHT;

            break;
        }

        switch (staticAnchorMatch[0].toLowerCase()) {
          case 'top':
            popupAnchor.y = POPUP_ANCHOR_Y_TOP;

            break;
          case 'middle':
            popupAnchor.y = POPUP_ANCHOR_Y_MIDDLE;

            break;
          case 'bottom':
            popupAnchor.y = POPUP_ANCHOR_Y_BOTTOM;

            break;
        }
      }

      if (tutorial.identifierFull === 'mainUi.overallScores') {
        offset.y += 100;
      }

      await this.$tutorialHighlightOverlay
        .build()
        .text(tutorial.text)
        .highlight(element, HIGHLIGHT_TYPE_ELEMENT)
        .popupAnchor(popupAnchor)
        .offset(offset)
        .autoBoundDistance(.4)
        .animationLength(0)
        .passOptions({
          tutorialGroupIdentifier: tutorial.identifierFull,
        })
        .pop();

      this.markTutorialOpened(tutorial.identifierFull);
    }

    this.markTutorialGroupOpened(tutorialGroupIdentifier);
  }

  markTutorialGroupOpened(tutorialGroupIdentifier) {
    store.commit('addTutorialGroupIdentifierOpened', tutorialGroupIdentifier);
  }

  markTutorialOpened(tutorialIdentifier) {
    store.commit('addTutorialIdentifierOpened', tutorialIdentifier);
  }
}

export const TutorialGroupIdentifier = {
  CharacterSelection: 'characterSelection',
  Lobby: 'lobby',
  RoleSelection: 'roleSelection',

  MainUi: 'mainUi',

  StrategicMeetingInvite: 'strategicMeetingInvite',

  StrategicMeeting: 'strategicMeeting',
  StrategicMeetingResult: 'strategicMeetingResult',

  Dashboard: 'dashboard',
  DashboardActions: 'dashboardActions',

  HazardMeetingMap: 'hazardMeetingMap',
  HazardMeetingPreMeeting: 'hazardMeetingPreMeeting',
  HazardMeeting: 'hazardMeeting',
  HazardMeetingResult: 'hazardMeetingResult',

  EpiReport: 'epiReport',

  End: 'end',
}

const TutorialUtil = TutorialUtilClass.instance();

export {
  TutorialUtil
};
