<template>
  <div class="tutorial-box-content">
    <p>{{ $t('starMinigameTutorialLikelihood.introText') }}</p>

    <table class="tutorial-table">
      <thead>
        <tr>
          <th>{{ $t('starMinigameTutorialLikelihood.headerLikelihood') }}</th>
          <th>{{ $t('starMinigameTutorialLikelihood.headerDescription') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="bg-level bg-level-1">{{ $t('starMinigameTutorialLikelihood.veryUnlikely') }}</td>
          <td>{{ $t('starMinigameTutorialLikelihood.veryUnlikelyDescription') }}</td>
        </tr>
        <tr>
          <td class="bg-level bg-level-2">{{ $t('starMinigameTutorialLikelihood.unlikely') }}</td>
          <td>{{ $t('starMinigameTutorialLikelihood.unlikelyDescription') }}</td>
        </tr>
        <tr>
          <td class="bg-level bg-level-3">{{ $t('starMinigameTutorialLikelihood.likely') }}</td>
          <td>{{ $t('starMinigameTutorialLikelihood.likelyDescription') }}</td>
        </tr>
        <tr>
          <td class="bg-level bg-level-4">{{ $t('starMinigameTutorialLikelihood.veryLikely') }}</td>
          <td>{{ $t('starMinigameTutorialLikelihood.veryLikelyDescription') }}</td>
        </tr>
        <tr>
          <td class="bg-level bg-level-5">{{ $t('starMinigameTutorialLikelihood.almostCertain') }}</td>
          <td>{{ $t('starMinigameTutorialLikelihood.almostCertainDescription') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TutorialLikelihood',
}
</script>

<style lang="scss" scoped>
.bg-level {
  text-align: center !important;
}
</style>
