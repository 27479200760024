import GameClient from './main';

export default {
  install(app) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$gameClient = new GameClient({
      voiceChatEnabled: true,
    });
  },
};
