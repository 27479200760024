<template>
  <notification-assessment @cancel="onCancel" @commit="onCommit" />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EpiReportPopup from '@/components/EpiReport/EpiReportPopup/index.vue'
import LobbyPopup from '@/components/Lobby/LobbyPopup.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import NotificationAssessment from '@/components/NotificationAssessment/index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'

export default {
  name: 'NotificationAssessmentDev',
  components: {
    StrategicMeetingIntro,
    NotificationAssessment,
    LobbyPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['cancel', 'commit'],
    }
  },
  methods: {
    onCancel() {
      this.triggerEvent('cancel')
    },
    onCommit() {
      this.triggerEvent('commit')
    }
  },
}
</script>
