<template>
  <div class="facilitator-view-container">
    <div class="facilitator-view">
      <h1>Facilitator</h1>

      <div class="room-container">
        <h2>Rooms</h2>

        <toggle-slider v-model="liveRoomsOnly" label="Live rooms only" class="mb-4"/>

        <div v-if="loadingRooms">
          Loading rooms..
        </div>
        <div v-else-if="loadingRoomsError">
          <strong>Error: </strong> {{ loadingRoomsError }}
        </div>
        <div v-if="rooms.length">
          <table class="table table-striped table-bordered room-table">
            <thead>
            <tr>
              <th>Room ID</th>
              <th>Room name</th>
              <th>Players</th>
              <th>Last session</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <facilitator-room-row
              v-for="room of rooms"
              :key="room.id"
              :room="room"
              @delete-save-data="onDeleteSaveData"
              @end-session="onEndSession"
              @spectate="onSpectate"
              @player-remove-facilitator-called="onPlayerRemoveFacilitatorCalled"
            />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacilitatorRoomRow from '@/components/Facilitator/FacilitatorRoomRow.vue'
import FacilitatorPlayer from '@/components/Facilitator/FacilitatorPlayer.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import StrategicMeetingLive from '@/components/StrategicMeeting/StrategicMeetingLive.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleSlider from '@/components/common/ToggleSlider.vue'

export default {
  name: 'FacilitatorView',
  components: {
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    StrategicMeetingLive,
    Lightbox,
    FacilitatorPlayer,
    FacilitatorRoomRow,
  },
  data() {
    return {
      // Rooms
      loadingRooms: true,
      loadingRoomsError: null,
      rooms: [],

      liveRoomsOnly: false,

      loadRoomsTimeout: null,
    }
  },
  mounted() {
    this.$store.commit('background', null);

    this.loadRooms()
  },
  watch: {
    liveRoomsOnly() {
      this.loadRooms();
    },
  },
  methods: {
    loadRooms() {
      if (this.loadRoomsTimeout) {
        clearTimeout(this.loadRoomsTimeout);

        this.loadRoomsTimeout = null;
      }

      this.loadingRooms = true
      this.loadingRoomsError = null

      this.$apiClient.facilitator.getRooms(this.liveRoomsOnly).then((rooms) => {
        this.rooms = rooms

        this.loadingRooms = false
      }).catch((e) => {
        this.loadingRoomsError = e
        this.loadingRooms = false
      }).finally(() => {
        // Ask again in 30 seconds
        this.loadRoomsTimeout = setTimeout(() => {
          this.loadRooms()
        }, 30000)
      })
    },
    onPlayerRemoveFacilitatorCalled({
      room,
      player
    })
    {
      this.$apiClient.facilitator.removeRoomPlayerFacilitatorCalled(room.id, player.code).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue
          }

          for (const _player of _room.players) {
            if (_player.playerId !== player.playerId) {
              continue
            }

            console.log('_player', _player, player)

            _player.callFacilitator = false
          }
        }
      })
    },
    onDeleteSaveData({room}) {
      if (!confirm(
        `Are you sure you want to delete the existing save data for room #${room.id}? The save data will be gone. This cannot be undone.`)) {
        return;
      }

      this.$apiClient.facilitator.deleteRoomSaveData(room.id).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue
          }

          _room.saveData = null
        }
      })
    },
    onEndSession({room}) {
      if (!confirm(`Are you sure you want to end the session for room #${room.id}? All players will be kicked out`)) {
        return;
      }

      this.$apiClient.facilitator.endRoomSession(room.id).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue
          }

          _room.liveSession = null
        }
      })
    },
    onSpectate({room}) {
      console.log('SPECTATE ROOM!', room)

      this.$store.commit('playerType', null);

      this.$apiClient.jee.getJeeData().then((jeeCategories) => {
        console.log('jeeCategories', jeeCategories);

        this.$store.commit('jeeCategories', jeeCategories);
      }).then(() => {
        return this.$apiClient.toolbox.getToolboxData().then((toolboxCategories) => {
          console.log('toolboxCategories', toolboxCategories);

          this.$store.commit('toolboxCategories', toolboxCategories);
        });
      }).then(() => {
        return this.$apiClient.areaOfExpertise.getAreaOfExpertiseData().then((areasOfExpertise) => {
          console.log('areasOfExpertise', areasOfExpertise);

          this.$store.commit('areasOfExpertise', areasOfExpertise);
        });
      }).then(() => {
        return this.$apiClient.role.getRoleData().then((roles) => {
          console.log('roles', roles);

          this.$store.commit('roles', roles);
        });
      }).then(() => {
        return this.$gameClient.joinByCustomId('game', room.uid, true, {
          playerType: 'facilitator',
          playerCode: this.$store.getters.facilitatorCode,
          muted: true,
        });
      }).then(() => {
        this.$store.commit('playerType', 'facilitator');
        this.$router.push('/game');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.facilitator-view-container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.facilitator-view {
  width: 80%;
  margin: 0 auto;
}

.room-table {
  .td-player-list {
    ul {
      text-align: left;
      margin: 0;
    }
  }
}

h1, h2, h3, h4, h5, p, div {
  text-align: left;
}
</style>
