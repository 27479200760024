export default {
  // LOGIN
  attemptLogin (username, password) {
    return this.put(`/backend/login`, {
      username: username,
      password: password
    })
  },
  getUserFromToken () {
    return this.put(`/backend/login/token`)
  },

  // USERS
  getUsers () {
    return this.get(`/backend/user`)
  },
  createUser (username, role, password) {
    return this.post(`/backend/user`, {
      username: username,
      role: role,
      password: password
    })
  },
  getFacilitators () {
    return this.get(`/backend/user/facilitators`)
  },
  getUser (userId) {
    return this.get(`/backend/user/${userId}`)
  },
  updateUser (userId, username, role, password) {
    return this.put(`/backend/user/${userId}`, {
      username: username,
      role: role,
      password: password
    })
  },
  deleteUser (userId) {
    return this.delete(`/backend/user/${userId}`)
  },

  // ROOMS
  createRoom (roomName, narrative, facilitator, event, players, scheduledAccessRange) {
    return this.post(`/backend/room`, {
      roomName: roomName,
      narrative: narrative,
      facilitator: facilitator,
      event: event,
      players: players,
      scheduledAccessRange: scheduledAccessRange
    })
  },
  updateRoom (roomId, roomName, narrative, facilitator, event, players, scheduledAccessRange) {
    return this.put(`/backend/room/${roomId}`, {
      roomName: roomName,
      narrative: narrative,
      facilitator: facilitator,
      event: event,
      players: players,
      scheduledAccessRange: scheduledAccessRange
    })
  },
  deleteRoom (roomId) {
    return this.delete(`/backend/room/${roomId}`)
  },
  getRoom (roomId) {
    return this.get(`/backend/room/${roomId}`)
  },

  // NARRATIVES
  getNarratives () {
    return this.get(`/backend/narrative`)
  },

  getEvent (eventId) {
    return this.get(`/backend/event/${eventId}`)
  },
  getEvents () {
    return this.get(`/backend/event`)
  },
  createEvent (name, narrative, facilitator) {
    return this.post(`/backend/event`, {
      eventName: name,
      facilitator: facilitator,
      narrative: narrative
    })
  },
  updateEvent (eventId, name, narrative, facilitator) {
    return this.put(`/backend/event/${eventId}`, {
      eventName: name,
      facilitator: facilitator,
      narrative: narrative
    })
  },
  deleteEvent (eventId) {
    return this.delete(`/backend/event/${eventId}`)
  },
}
