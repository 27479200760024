<template>
  <epi-report-popup :current-month="currentMonth" :month-buttons="true" @previous-month="previousMonth" @next-month="nextMonth" />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EpiReportPopup from '@/components/EpiReport/EpiReportPopup/index.vue'

export default {
  name: 'EpiReportPopupDev',
  components: {
    EpiReportPopup,
  },
  extends: DevComponentContainer,

  data () {
    return {
      events: ['continue'],
      currentMonth: 1,
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    },
    previousMonth() {
      this.currentMonth = Math.max(1, this.currentMonth - 1);
    },
    nextMonth() {
      this.currentMonth = Math.min(15, this.currentMonth + 1);
    },
  },
}
</script>
