export const DECISION_RESTRICT = 'restrict'
export const DECISION_HOLD = 'hold'
export const DECISION_DELAY = 'delay'
export const DECISION_CANCEL = 'cancel'

import WinningConditions from '../lib/winning-conditions';

export const DecisionData = {
  [DECISION_RESTRICT]: {
    icon: 'restrict',
    translationIdentifier: 'Restrict', // decision[Restrict]Button
    //description: 'You will restrict the Tournament to only hold for a limited amount of spectators.',
    //buttonLabel: 'Restrict',
    winningConditions: WinningConditions[DECISION_RESTRICT],
  },
  [DECISION_HOLD]: {
    icon: 'hold',
    translationIdentifier: 'Hold', // decision[Hold]Button
    //description: 'You will hold the Tournament without restriction',
    //buttonLabel: 'Hold',
    winningConditions: WinningConditions[DECISION_HOLD],
  },
  [DECISION_DELAY]: {
    icon: 'delay',
    translationIdentifier: 'Delay', // decision[Delay]Button
    //description: 'You will delay the Tournament to hold it at a later time.',
    //buttonLabel: 'Delay',
    winningConditions: WinningConditions[DECISION_DELAY],
  },
  [DECISION_CANCEL]: {
    icon: 'cancel',
    translationIdentifier: 'Cancel', // decision[Cancel]Button
    //description: 'You will cancel the tournament.',
    //buttonLabel: 'Cancel',
    winningConditions: WinningConditions[DECISION_CANCEL],
  }
}
