<template>
  <lobby-popup-template :style="{ width: '1300px', margin: '0 auto' }" content-class="equal-padding">
    <template v-slot:header>
      {{ $t('end.finalDecisionTitle') }}
    </template>
    <template v-slot:content>
      <div class="card-container d-flex gap-4">
        <div class="card" v-for="(card, decision) in cards">
          <end-game-popup-decision-card
            :value="decision"
            :selected="innerValue === decision"
            :player-votes="playerVotes[decision]"
            @choose="innerValue = decision"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="end-game-popup-decision-footer">
        <strong>{{ $t('end.decisionFooterHeader') }}</strong>
        <p>{{ $t('end.decisionFooterBody') }}</p>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import EndGamePopupDecisionCard from '@/components/EndGamePopup/EndGamePopupDecision/EndGamePopupDecisionCard.vue'
import { objectToArray } from '@/utils/arrayUtil'
import { DecisionData, DECISION_HOLD, DECISION_RESTRICT, DECISION_CANCEL, DECISION_DELAY } from '@/data/decision-data'

export default {
  name: 'EndGamePopupDecision',
  components: {
    LobbyPopupTemplate,
    AppButton,
    HazardPopupContainer,
    EndGamePopupDecisionCard,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
      required: false,
    },
    playerVotes: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    endGameDecisionAttempts: {
      type: Number,
      default: 0,
      required: false,
    }
  },
  emits: ['update:modelValue'],
  watch: {
    endGameDecisionAttempts() {

    },
    modelValue () {
      if (this.innerValue === this.modelValue) {
        return
      }

      this.innerValue = this.modelValue
    },
    innerValue () {
      if (this.innerValue === this.modelValue) {
        return
      }

      this.$emit('update:modelValue', this.innerValue)
    },
  },
  data () {
    return {
      innerValue: this.modelValue,
      cards: DecisionData
    }
  },
  mounted () {
    this.innerValue = this.modelValue
  },
}
</script>

<style lang="scss" scoped>
.text-content {
  padding: 20px 0;

  h2 {
    font-size: 40pt;
  }

  p {
    font-size: 18pt;
  }
}
</style>

<style lang="scss">
.end-game-popup-intro-popup {
  .hazard-popup-body-inner {
    height: fit-content;
    margin-top: 150px;
    margin-bottom: 70px;
  }
}

.end-game-popup-decision-footer {
  color: white;
  text-align: left;
  padding: 0 100px;

  strong {
    font-size: 16pt;
  }

  p {
    font-size: 14pt;
  }
}
</style>
