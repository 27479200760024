<template>
  <div class="meeting-popup-action-investment-container">
    <meeting-popup-action-investment
      :investment-cost="investmentCost"
      :total-investment="totalInvestment"
      :player-investment="playerInvestment"
      :player-points="playerPoints"
      @update:player-investment="onUpdatePlayerInvestment"
    />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue'
import { generateFakeHazard1, generateFakeHazard2 } from '@/utils/generator'
import { generatePlayerData } from '@/utils/playerDataGenerator'
import HazardActionInvestment from '@/components/HazardMeeting/HazardPopup/HazardActionInvestment.vue'
import MeetingPopupActionInvestment from '@/components/MeetingPopup/MeetingPopupActionInvestment.vue'

export default {
  name: 'MeetingPopupActionInvestmentDev',
  components: {
    MeetingPopupActionInvestment,
    HazardActionInvestment,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['update:playerInvestment'],

      investmentCost: 10,
      playerInvestment: 2,
      totalInvestment: 5,

      playerPoints: 50,
    }
  },
  methods: {
    onUpdatePlayerInvestment(e) {
      this.triggerEvent('update:playerInvestment');

      const otherInvestment = this.totalInvestment - this.playerInvestment;

      this.playerInvestment = e;

      this.totalInvestment = this.playerInvestment + otherInvestment;
    },
  },
}
</script>

<style lang="scss" scoped>
.hazard-action-investment-container {
  width: 300px;
  height: 80px;
}
</style>
