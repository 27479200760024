import IconAnimal from '@/assets/icons/hazards/hazard-animal.png';
import IconChemical from '@/assets/icons/hazards/hazard-chemical.png';
import IconDisaster from '@/assets/icons/hazards/hazard-disaster.png';
import IconEnvironmental from '@/assets/icons/hazards/hazard-environmental.png';
import IconInfectious from '@/assets/icons/hazards/hazard-infectious.png';
import IconSocietal from '@/assets/icons/hazards/hazard-societal.png';
import IconZoonosis from '@/assets/icons/hazards/hazard-zoonosis.png';
import IconUnknown from '@/assets/icons/hazards/hazard-unknown.png';
import IconNews from '@/assets/icons/hazards/hazard-news.png';
import IconFoodSafety from '@/assets/icons/hazards/hazard-food-safety.png';
import {
  FLOATING_HAZARD_ICON_SHAPE_HEXAGON,
  FLOATING_HAZARD_ICON_SHAPE_PIN,
  FLOATING_HAZARD_ICON_SHAPE_TRIANGLE
} from '@/enum/FloatingHazardIconShadow'
import { i18n } from '@/lib/i18n/i18n';

const translate = i18n.global.t;

export default {
  animal: {
    label: translate('hazard.animal'),
    identifier: 'animal',
    iconImage: IconAnimal,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  chemical: {
    label: translate('hazard.chemical'),
    identifier: 'chemical',
    iconImage: IconChemical,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  disaster: {
    label: translate('hazard.disaster'),
    identifier: 'disaster',
    iconImage: IconDisaster,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  environmental: {
    label: translate('hazard.environmental'),
    identifier: 'environmental',
    iconImage: IconEnvironmental,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  infectious: {
    label: translate('hazard.infectious'),
    identifier: 'infectious',
    iconImage: IconInfectious,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  societal: {
    label: translate('hazard.societal'),
    identifier: 'societal',
    iconImage: IconSocietal,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },
  zoonosis: {
    label: translate('hazard.zoonosis'),
    identifier: 'zoonosis',
    iconImage: IconZoonosis,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  },

  unknown: {
    label: translate('hazard.unknown'),
    identifier: 'unknown',
    iconImage: IconUnknown,
    shape: FLOATING_HAZARD_ICON_SHAPE_HEXAGON,
  },

  news: {
    label: translate('hazard.news'),
    identifier: 'news',
    iconImage: IconNews,
    shape: FLOATING_HAZARD_ICON_SHAPE_PIN,
  },

  foodSafety: {
    label: translate('hazard.foodSafety'),
    identifier: 'foodSafety',
    iconImage: IconFoodSafety,
    shape: FLOATING_HAZARD_ICON_SHAPE_TRIANGLE,
  }
};
