import { ColyseusUtils } from './ColyseusUtils'

const enableDebugging = false;

export function colyseusStateToJs(value) {
  const storeValueConstructor = ColyseusUtils.getConstructorNameFromValue(value);
  const valueIsPrimitive = ColyseusUtils.isPrimitive(value);

  if (valueIsPrimitive) {
    //return structuredClone(value);
  } else if (enableDebugging) {
    console.log('ColyseusStateNormalizer.before', {
      'fixMutationData': 'fixMutationData',
      storeValueConstructor,
      type: typeof (value),
      value: value,
      valueJson: JSON.stringify(value),
      isArray: value instanceof Array,
      isMap: value instanceof Map,
    });
  }

  let newValue = null;

  if (storeValueConstructor === '_') {
    // Raw colyseus schema
    const fieldsByIndex = value._definition.fieldsByIndex || {}

    newValue = {};

    for (const fieldNameKey in fieldsByIndex) {
      const fieldName = fieldsByIndex[fieldNameKey]

      newValue[fieldName] = colyseusStateToJs(value[fieldName]);
    }
  } else if (storeValueConstructor === 'MapSchema') {
    newValue = {};

    for (const [subKey, subValue] of value.entries()) {
      newValue[subKey] = colyseusStateToJs(subValue);
    }

    return newValue;
  } else if (storeValueConstructor === 'ArraySchema') {
    newValue = [];

    const values = value.toArray();

    for (let i = 0, len = values.length; i < len; i++) {
      newValue.push(colyseusStateToJs(values[i]));
    }
  } else if (value instanceof Array) {
    newValue = [];

    for (let i = 0, len = value.length; i < len; i++) {
      newValue.push(colyseusStateToJs(value[i]));
    }
  } else if (value instanceof Map) {
    newValue = {};

    let objectKeys = value.keys();

    for (const objectKey of objectKeys) {
      newValue[objectKey] = colyseusStateToJs[value[objectKey]];
    }
  } else if (storeValueConstructor === 'Object') {
    newValue = {};

    const objectKeys = Object.keys(value);

    for (const objectKey of objectKeys) {
      newValue[objectKey] = colyseusStateToJs[value[objectKey]];
    }
  } else {
    newValue = value;
  }

  if (enableDebugging) {
    console.log('ColyseusStateNormalizer.after', {
      'colyseusStateToJs': 'colyseusStateToJs',
      storeValueConstructor,
      value: value,
      valueJson: JSON.stringify(value),
      newValue: newValue,
      newValueJson: JSON.stringify(newValue),
    });
  }

  return newValue;
}
