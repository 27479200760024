import { addGetParamsToUrl } from '@/plugins/ApiClient/utils'

export default {
  getRooms(liveRoomsOnly = false, onlyRequesterRooms = false) {
    let url = `/facilitator/rooms`;

    if (liveRoomsOnly) {
      url = addGetParamsToUrl(url, { liveOnly: true });
    }

    if (onlyRequesterRooms) {
      url = addGetParamsToUrl(url, { onlyRequesterRooms: true });
    }

    console.log('liveRoomsOnly', liveRoomsOnly);

    return this.get(url);
  },
  removeRoomPlayerFacilitatorCalled(roomId, playerCode) {
    return this.delete(`/facilitator/rooms/${roomId}/remove-player-facilitator-called/${playerCode}`);
  },
  deleteRoomSaveData(roomId) {
    return this.delete(`/facilitator/rooms/${roomId}/save-data`);
  },
  endRoomSession(roomId) {
    return this.delete(`/facilitator/rooms/${roomId}/session`);
  },
};
