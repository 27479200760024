<template>
  <hazard-popup-flag-management
    :world-hazards="worldHazards"
    :player-prioritizations="playerPrioritizations"

    @click:cancel="onClickCancel"
    @click:removeFlag="onClickRemoveFlag"
    @click:addFlag="onClickAddFlag"
    @click:swapFlag="onClickSwapFlag"
    @click:inspectFlag="onClickInspectFlag"

    type="manage"
  />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'
import { generateFakeHazard1, generateFakeHazard2 } from '@/utils/generator'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue'
import {generatePlayerData} from '@/utils/playerDataGenerator';

export default {
  name: 'HazardPopupFlagManagementDev',
  components: {
    HazardPopupPrioritization,
    HazardPopupFlagManagement,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const hazard1 = generateFakeHazard1();
    const hazard2 = generateFakeHazard2();

    const playerPrioritizations = [];

    const worldHazards = [
      hazard1,
      hazard2,
      hazard1,
      hazard2,
      hazard1,
      hazard2,
      hazard1,
      hazard2,
    ];

    for (let i = 0; i < 5; i++) {
      const hazardPrioritizationCount = {};

      for (const worldHazard of worldHazards) {
        if (Math.random() > 1) {
          continue;
        }

        hazardPrioritizationCount[worldHazard.id.toString()] = Math.floor(Math.random() * 4) + 1;
      }

      playerPrioritizations.push({
        player: generatePlayerData({ characterIndex: i, playerIndex: i, playerCode: i.toString() }),
        hazardPrioritizationCount: hazardPrioritizationCount
      });
    }



    return {
      events: ['cancel', 'removeFlag', 'addFlag', 'swapFlag', 'inspectFlag'],

      worldHazards: worldHazards,
      playerPrioritizations
    }
  },
  methods: {
    onClickCancel() {
      this.triggerEvent('cancel')
    },
    onClickAddFlag() {
      this.triggerEvent('addFlag')
    },
    onClickRemoveFlag() {
      this.triggerEvent('removeFlag')
    },
    onClickSwapFlag() {
      this.triggerEvent('swapFlag')
    },
    onClickInspectFlag() {
      this.triggerEvent('inspectFlag')
    }
  },
}
</script>

<style lang="scss" scoped>
.hazard-popup-intro {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
