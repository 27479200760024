<template>
  <div class="hazard-action-tab-container" :class="active ? 'hazard-action-tab-container-active' : 'hazard-action-tab-container-inactive'">
    <div class="hazard-action-lookers d-flex gap-2 d-flex justify-content-between">
      <div class="hazard-action-looker" v-for="player in playerLookerSlots">
        <character-thumbnail
          v-if="player"
          :character-index="player.characterIndex"
          size="tiny"
        />
      </div>
    </div>
    <div class="hazard-action-tab">
      <long-button color="yellow" size="full-width" @click.prevent.stop="onClick" :state="active ? 'active' : ''">{{ label }}</long-button>
    </div>
  </div>
</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import CostBox from '@/components/common/CostBox.vue'
import LongButton from '@/components/common/LongButton.vue'

export default {
  name: 'HazardActionTab',
  components: {
    LongButton,
    CostBox,
    CharacterThumbnail,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    totalInvestment: {
      type: Number,
      required: false,
      default: 0,
    },
    playersLookingAtTab: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  },
  computed: {
    playersLookingAtTabThatArentYou() {
      return this.playersLookingAtTab.filter((player) => !player.isSelf);
    },
    playerLookerSlots() {
      const players = [];

      for (let i = 0, len = this.playersLookingAtTab.length; i < len; i++) {
        const player = this.playersLookingAtTab[i];

        if (player.isSelf) {
          //continue;
        }

        players.push(player);
      }

      for (let i = players.length; i < 5; i++) {
        players.push(null);
      }

      //console.log('players', { players, a });

      return players;
    }
  }
}
</script>

<style lang="scss" scoped>
$tabHeight: 85px;
$lookerContainerHeight: 44px;
$tabEveryone: !important;

.hazard-action-tab-container {
  width: 262px;
  //height: $tabHeight + $lookerContainerHeight;
  position: relative;

  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #131D2B;
  border: 1.20961px solid #051224;
  box-shadow: inset 0px 0px 2.41923px 1.20961px #071C27, inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);
  border-radius: 6.04807px;
  height: auto;
}

.hazard-action-lookers {
  //height: $lookerContainerHeight;
  width: 100%;
  text-align: left;
  padding: 8px 8px 0 8px;
  margin-bottom: 5px;

  .hazard-action-looker {
    width: 50px;
    height: 46px;
    background: rgba(#000000, .34);
    //background: #ff0000;
    border-radius: 4px;
  }

  .character-thumbnail {
    margin-left: 15px;
    width: 41px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.hazard-action-tab {
  cursor: pointer;

  width: 100%;
  //height: $tabHeight;
  height: calc(100% - $lookerContainerHeight - 10px);
  padding: 8px 8px 16px 8px;
}

.hazard-action-total-investment {
  position: absolute;
  bottom: 32px;
  right: -10px;
  display: none;
}

.hazard-action-label {
  white-space: break-spaces;
  padding: 5px 15px;

  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //font-size: 14pt;
  font-size: 11pt;
}
</style>
