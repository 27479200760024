<template>
  <div class="action-points-circle">
    <div class="text-container">
      <p class="text-header">{{ $t('topUi.actionPoints') }}</p>
      <p class="text-action-points">{{ current }}</p>
    </div>
    <div class="total-cost-container" v-if="totalCost !== null">
      <div class="total-cost-headline">{{ $t('topUi.totalActionPointCost') }}</div>
      <div class="total-cost-value">{{ totalCostAbs }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ActionPointsCircle',
  props: {
    current: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    totalCost: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    totalCostAbs() {
      return Math.abs(this.totalCost) * -1;
    }
  }
}

</script>

<style scoped lang='scss'>
@import '~@/assets/styles/variables.scss';

.action-points-circle {
  width: $actionPointCircleDiameter;
  height: $actionPointCircleDiameter;
  border-radius: $actionPointCircleDiameter / 2;
  mix-blend-mode: normal;

  background: url("@/assets/textures/grunge-seamless-20-percent.png"), linear-gradient(180deg, #A0D530 0%, #0A443A 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 9px -4px 9px -1px rgba(0, 0, 0, 0.56), inset 16px 0px 9px 13px rgba(0, 0, 0, 0.4);
}

.text-container {
  width: 100%;
  height: auto;
  margin-top: 7rem;
}

.text-header {
  width: 100%;
  height: 50%;
  font-weight: bold;
  color: white;
  font-size: 19px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
}

.text-action-points {
  width: 100%;
  height: 50%;
  font-weight: bold;
  color: white;
  font-size: 60pt;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.total-cost-container {
  background: url("@/assets/ui/total-cost-ball.png") no-repeat;
  width: 118px;
  height: 98px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20px);

  .total-cost-headline, .total-cost-value {
    text-align: center;
    color: white;
    font-weight: 700;
    text-shadow: 0px 3.06368px 4.08491px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    width: 100%;
    position: absolute;
  }

  .total-cost-headline {
    top: 10px;
    font-size: 13pt;
  }

  .total-cost-value {
    top: 28px;
    font-size: 30pt;
  }
}
</style>
