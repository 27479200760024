<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="tiny" :show-background="false"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="tiny" :show-background="true"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="small" :show-background="false"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="small" :show-background="true"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="medium" :show-background="false"/>
      </div>
    </div>
    <div class="row">
      <div class="col text-center" v-for="n in 5" >
        <character-thumbnail :character-index="n - 1" size="medium" :show-background="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'

export default {
  name: 'CharacterThumbnailDev',
  components: { CharacterThumbnail },
  extends: DevComponentContainer,

  data () {
    return {}
  },
}
</script>
