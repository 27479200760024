export const GameClientEvent = {
  ROOM_ERROR: 'roomError', // An error has happened

  ROOM_JOIN: 'roomJoin', // You joined a room
  ROOM_LEAVE: 'roomLeave', // You left a room

  PLAYER_JOIN: 'playerJoin', // Another player joined the room
  PLAYER_LEAVE: 'playerLeave', // Another player left the room

  ACCESSOR_SUCCESS: 'accessorSuccess',
  ACCESSOR_ERROR: 'accessorError',
};
