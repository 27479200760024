<template>
  <div class="tutorial-box-content">
    <p>{{ $t('starMinigameTutorialSeasonality.introText') }}</p>

    <div class="seasonality-container bg-darkish d-flex">
      <div
          v-for="(monthAbbreviation, n) in monthAbbreviations"
          class="seasonality-month flex-fill bg-level"
          :class="['seasonality-month-' + monthAbbreviation.value, 'bg-level-' + monthAbbreviation.value]"
          v-text="monthAbbreviation.abbreviation"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialSeasonality',
  computed: {
    monthAbbreviations() {
      return [
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationJanuary'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationFebruary'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationMarch'), value: 3 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationApril'), value: 4 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationMay'), value: 5 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationJune'), value: 4 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationJuly'), value: 3 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationAugust'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationSeptember'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationOctober'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationNovember'), value: 1 },
        { abbreviation: this.$t('starMinigameTutorialSeasonality.monthAbbreviationDecember'), value: 1 },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.seasonality-month {
  padding: 10px 20px;
  border: 1px solid white;
  font-weight: bold;
  color: black;
  text-align: center;
}
</style>
