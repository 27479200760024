<template>
  <lobby-popup-template style="height: 760px; margin-top: 110px;">
    <template v-slot:header>
      <span @click="clickRoomCode">{{ $t('lobby.headerWelcomeTo') }}</span>
      <app-button @click="onClickLeaderboard" color="blue" width="lg" class="header-button">{{ $t('lobby.headerButtonLeaderboard') }}</app-button>
    </template>
    <template v-slot:content>
      <div class="logo-container" :style="{ 'margin-bottom': showPlayerRooms ? '380px' : '150px' }">
        <no-drag-image src="@/assets/lobby/logo.png" />
      </div>
      <div class="d-flex gap-4 player-rooms-container" v-if="showPlayerRooms">
        <div v-for="playerCodeRoom in playerCodeRooms" class="player-room-container">
          <strong>{{ playerCodeRoom.roomName }}</strong>
          <ul>
            <li v-for="playerCode in playerCodeRoom.playerCodes">
              <app-button @click="enterPlayerCode(playerCode)" width="md" height="md" color="green">{{ playerCode }}</app-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="room-code-container-outer">
        <div class="room-code-container">
          <div>
            <label>{{ $t('lobby.enterRoomCode') }}</label>
          </div>
          <div>
            <input type="text" v-model="playerCode" class="form-control" :maxlength="11" />
          </div>
          <div class="no-room-code">
            <strong>{{ $t('lobby.noRoomCodeTitle') }}</strong>
            <p>{{ $t('lobby.noRoomCodeBody') }}</p>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <div>
          <back-button @click="onClickBack" class="back-button" />
          <app-button color="green" height="lg" width="xl" @click="clickEnter" :disabled="!canEnter">{{ $t('lobby.buttonEnterRoom') }}</app-button>
        </div>
        <div class="error" v-if="error">
          <label>{{ error }}</label>
        </div>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LongButton from '@/components/common/LongButton';
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate';
import NoDragImage from '@/components/common/NoDragImage';
import {EVENT_FREQUENCY_CHANGED} from '@/plugins/VoiceController/events/FrequencyChangedEvent';
import {setPlayerVoiceFrequencyByVoiceUid} from '@/store/categories/room/playerVoiceFrequencyDecayController';
import AppButton from '@/components/common/buttons/AppButton.vue'
import {mapState} from "vuex";
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';
import BackButton from '@/components/common/buttons/BackButton.vue';

export default {
  name: 'LobbyPopupRoomCode',
  components: {
    BackButton,
    AppButton,
    NoDragImage, LongButton, LobbyPopupTemplate},
  emits: ['continue'],
  props: {
    initialValue: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    const showPlayerCodeButtons = parseInt(process.env.VUE_APP_SHOW_PLAYER_CODE_BUTTONS, 10) > 0;

    return {
      showPlayerRooms: showPlayerCodeButtons,

      error: null,
      joining: false,
      joinedRoomCustomId: null,
      joinedRoom: null,

      playerCode: this.initialValue || '',

      playerCodeRooms: [
        {
          roomName: 'Green room',
          playerCodes: [
            '111111-1111',
            '111111-2222',
            '111111-3333',
            '111111-4444',
            '111111-5555',
          ],
        },
        {
          roomName: 'Red room',
          playerCodes: [
            '222222-1111',
            '222222-2222',
            '222222-3333',
            '222222-4444',
            '222222-5555',
          ],
        },
        {
          roomName: 'Blue room',
          playerCodes: [
            '333333-1111',
            '333333-2222',
            '333333-3333',
            '333333-4444',
            '333333-5555',
          ],
        },
        {
          roomName: 'Orange room',
          playerCodes: [
            '444444-1111',
            '444444-2222',
            '444444-3333',
            '444444-4444',
            '444444-5555',
          ],
        },
        {
          roomName: 'Purple room',
          playerCodes: [
            '555555-1111',
            '555555-2222',
            '555555-3333',
            '555555-4444',
            '555555-5555',
          ],
        },
      ]
    }
  },
  watch: {
    playerCode() {

      let playerCode = this.playerCode;

      // Remove anything that isn't numbers
      playerCode = playerCode.replace(/[^0-9]+/g, '');

      // Now re-add the dash
      if (playerCode.length >= 6) {
        playerCode = playerCode.substr(0, 6) + '-' + playerCode.substr(6);
      }

      this.playerCode = playerCode;
    },
  },
  computed: {
    ...mapState(['joinCode', 'seenIntro']),
    devMode() {
      return parseInt(process.env.VUE_APP_DEV_MODE, 10) > 0;
    },
    canEnter() {
      if (this.joining) {
        return false;
      }

      if (!this.playerCode) {
        return false;
      }

      if (this.joinedRoom) {
        return false;
      }

      return true;
    }
  },
  mounted(){
    if(this.joinCode !== null && this.seenIntro){
      this.playerCode = this.joinCode;
      this.enter(false);
    }
  },
  methods: {
    onClickBack() {
      this.$router.push('intro');
    },
    onClickLeaderboard() {
      this.$router.push('leaderboard');
    },
    clickRoomCode() {
      // We're skipping all these screens when dev joining, so let us NOT see them
      TutorialUtil.markTutorialGroupOpened(TutorialGroupIdentifier.CharacterSelection);
      TutorialUtil.markTutorialGroupOpened(TutorialGroupIdentifier.Lobby);
      TutorialUtil.markTutorialGroupOpened(TutorialGroupIdentifier.RoleSelection);

      this.$store.commit('autoJoin', true);

      this.$nextTick(() => {
        this.enterPlayerCode('123456-0000', true);
      });
    },
    enterPlayerCode(playerCode, devMode = false) {
      this.playerCode = playerCode;

      this.enter(devMode);
    },
    clickEnter() {
      this.enter(false);
    },
    enter(devMode = false) {
      if (!this.canEnter) {
        return;
      }

      this.error = null;

      if (this.playerCode.length < 6) {
        this.error = 'Invalid room code';

        return;
      }

      this.joining = true;

      let joinedRoom;

      this.$store.commit('playerType', null);

      this.$apiClient.player.getPlayerByCode(this.playerCode).then((playerData) => {
        if (playerData.error) {
          throw new Error(playerData.error.message);
        }

        console.log('getPlayerByCode', playerData)
        const roomUid = playerData.room.uid

        return this.$gameClient.joinByCustomId('game', roomUid, true, {
          playerCode: this.playerCode,
          //muted: true,
        })
      }).then((room) => {
        this.$store.commit('playerType', 'player');

        joinedRoom = room;

        return this.$apiClient.jee.getJeeData().then((jeeCategories) => {
          this.$store.commit('jeeCategories', jeeCategories);
        });
      }).then(() => {
        return this.$apiClient.toolbox.getToolboxData().then((toolboxCategories) => {
          this.$store.commit('toolboxCategories', toolboxCategories);
        });
      }).then(() => {
        return this.$apiClient.areaOfExpertise.getAreaOfExpertiseData().then((areasOfExpertise) => {
          this.$store.commit('areasOfExpertise', areasOfExpertise);
        });
      }).then(() => {
        return this.$apiClient.role.getRoleData().then((roles) => {
          this.$store.commit('roles', roles);
        });
      }).then(() => {
        this.joined = true;

        console.log('joinedRoom', joinedRoom);

        if (devMode) {
          return this.$gameClient.roomCallMethod('devStart');
        } else {
          return this.$emit('continue');
        }
      }).catch((e) => {
        this.error = e.message; // `Could not join room by player code ${this.playerCode}`

        throw e;
      }).finally(() => {
        this.joining = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.lobby-popup-footer {
  position: relative;

  .footer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 145px;
  }

  .error {
    margin-top: 14px;
    font-size: 16pt;

    label {
      color: #ff0000;
      filter: drop-shadow(5px 5px 10px #000000);
      background: rgba(0, 0, 0, .2);
      border-radius: 5px;
      padding: 2px 8px;
    }
  }
}

.header-button {
  position: absolute;
  top: 13px;
  left: 13px;
}

.logo-container{
  width: 100%;
  //margin-bottom: 380px;

  img {
    margin: 0 auto;
    max-width: 60%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.room-code-container-outer {
  position: relative;
  width: 100%;
}

.room-code-container {
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #060f0f;
  padding: 20px 50px;
  border-radius: 14px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  label {
    text-transform: uppercase;
    font-size: 16pt;
    color: white;
    margin-bottom: 10px;
  }

  input {
    text-align: center;

    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: inset 0px 2px 2px 1px rgba(7, 28, 39, 0.56), inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    border-radius: 5px;
  }
}

.player-rooms-container {
  position: absolute;
  top: 255px;
  width: 800px;
  left: 230px;
}
.player-room-container {
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, .5);
  padding: 5px 15px;
  color: white;

  ul {
    list-style: none;
    padding-left: 0;
  }
}

.no-room-code {
  text-align: left;
  color: white;
  margin-top: 10px;
}

.back-button {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
</style>
