export const EVENT_PARTICIPANT_ADDED = 'participantAdded';

export class ParticipantAddedEvent extends Event {
  participant;

  constructor(participant) {
    super(EVENT_PARTICIPANT_ADDED);

    this.participant = participant;
  }
}
