<template>
  <div class="alert-dev-container">
    <div class="row">
      <div class="col">
        <button class="btn btn-dark" @click="onClickPop">POP</button>
        <button class="btn btn-info" @click="onClickInfo">INFO</button>
        <button class="btn btn-warning" @click="onClickWarning">WARNING</button>
        <button class="btn btn-error" @click="onClickError">ERROR</button>
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import AppButton from '@/components/common/buttons/AppButton.vue'
import ImageButton from '@/components/common/buttons/ImageButton.vue'
import TinyButton from '@/components/common/TinyButton.vue'
import TableColumnDescriptor from '@/components/common/TableColumnDescriptor.vue'
import ToggleImageButton from '@/components/common/buttons/ToggleImageButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'

export default {
  name: 'AlertPopDev',
  components: {

  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    return {
    }
  },

  methods: {
    onClickPop() {
      this.$alertPop.pop('MyTitle', 'MyBody - POP');
    },
    onClickInfo() {
      this.$alertPop.info('MyTitle', 'MyBody - INFO');
    },
    onClickWarning() {
      this.$alertPop.warning('MyTitle', 'MyBody - WARNING');
    },
    onClickError() {
      this.$alertPop.error('MyTitle', 'MyBody - ERROR');
    },
  }
};
</script>
