export default {
  'intro': {
    'logoEmergencyPreparedness': 'intro.logoEmergencyPreparedness', // Type: text
    'logoYouDecide': 'intro.logoYouDecide', // Type: text
    'introText': 'intro.introText', // Type: text
    'introHeader': 'intro.introHeader', // Type: text
    'welcome': 'intro.welcome', // Type: text
    'introSubtitle': 'intro.introSubtitle', // Type: text
    'header1': 'intro.header1', // Type: text
    'header2': 'intro.header2', // Type: text
    'header3': 'intro.header3', // Type: text
    'text1': 'intro.text1', // Type: text
    'text2': 'intro.text2', // Type: text
    'text3': 'intro.text3', // Type: text
    'text4': 'intro.text4', // Type: text
    'text5': 'intro.text5', // Type: text
    'happens': 'intro.happens', // Type: text
    'visitors': 'intro.visitors', // Type: text
    'buttonGotIt': 'intro.buttonGotIt', // Type: text
    'roleSelectionFooterLeft': 'intro.roleSelectionFooterLeft', // Type: text
    'roleSelectionFooterRight': 'intro.roleSelectionFooterRight', // Type: text
    'disclaimerHeader': 'intro.disclaimerHeader', // Type: text
    'disclaimerBody': 'intro.disclaimerBody', // Type: text
    'buttonIUnderstand': 'intro.buttonIUnderstand', // Type: text
    'buttonPreviousPage': 'intro.buttonPreviousPage', // Type: text
    'buttonNextPage': 'intro.buttonNextPage', // Type: text
    'buttonLogIn': 'intro.buttonLogIn', // Type: text
  },
  'end': {
    'finalDecisionTitle': 'end.finalDecisionTitle', // Type: text
    'finalDecisionHeader': 'end.finalDecisionHeader', // Type: text
    'finalDecisionBody': 'end.finalDecisionBody', // Type: text
    'decisionRestrictButton': 'end.decisionRestrictButton', // Type: text
    'decisionRestrictBody': 'end.decisionRestrictBody', // Type: text
    'decisionHoldButton': 'end.decisionHoldButton', // Type: text
    'decisionHoldBody': 'end.decisionHoldBody', // Type: text
    'decisionDelayButton': 'end.decisionDelayButton', // Type: text
    'decisionDelayBody': 'end.decisionDelayBody', // Type: text
    'decisionCancelButton': 'end.decisionCancelButton', // Type: text
    'decisionCancelBody': 'end.decisionCancelBody', // Type: text
    'decisionFooterHeader': 'end.decisionFooterHeader', // Type: text
    'decisionFooterBody': 'end.decisionFooterBody', // Type: text
    'decisionFeedbackHeaderLeft': 'end.decisionFeedbackHeaderLeft', // Type: text
    'decisionFeedbackHeaderRight': 'end.decisionFeedbackHeaderRight', // Type: text
    'decisionWarning': 'end.decisionWarning', // Type: text
    'decisionNotUnanimous': 'end.decisionNotUnanimous', // Type: text
    'decisionNotUnanimousPenalty': 'end.decisionNotUnanimousPenalty', // Type: text
    'decisionFurtherTime': 'end.decisionFurtherTime', // Type: text
    'buttonGotIt': 'end.buttonGotIt', // Type: text
    'preDebriefingSubtitle': 'end.preDebriefingSubtitle', // Type: text
    'preDebriefingButtonText': 'end.preDebriefingButtonText', // Type: text
  },
  'lobby': {
    'buttonStartGame': 'lobby.buttonStartGame', // Type: text
    'timerPickCharacter': 'lobby.timerPickCharacter', // Type: text
    'headerWelcomeTo': 'lobby.headerWelcomeTo', // Type: text
    'headerButtonLeaderboard': 'lobby.headerButtonLeaderboard', // Type: text
    'enterRoomCode': 'lobby.enterRoomCode', // Type: text
    'buttonEnterRoom': 'lobby.buttonEnterRoom', // Type: text
    'headerChooseAvatar': 'lobby.headerChooseAvatar', // Type: text
    'playerName': 'lobby.playerName', // Type: text
    'buttonJoin': 'lobby.buttonJoin', // Type: text
    'lobbyHeader': 'lobby.lobbyHeader', // Type: text
    'playersReady': 'lobby.playersReady', // Type: text
    'buttonReady': 'lobby.buttonReady', // Type: text
    'buttonUnready': 'lobby.buttonUnready', // Type: text
    'buttonStart': 'lobby.buttonStart', // Type: text
    'error': 'lobby.error', // Type: text
    'errorNoMicrophone': 'lobby.errorNoMicrophone', // Type: text
    'errorAtLeast2Players': 'lobby.errorAtLeast2Players', // Type: text
    'buttonContinue': 'lobby.buttonContinue', // Type: text
    'headerChooseRole': 'lobby.headerChooseRole', // Type: text
    'roleRepresents': 'lobby.roleRepresents', // Type: text
    'roleBranch': 'lobby.roleBranch', // Type: text
    'roleAreasOfExpertise': 'lobby.roleAreasOfExpertise', // Type: text
    'buttonRoleChosen': 'lobby.buttonRoleChosen', // Type: text
    'buttonChooseRole': 'lobby.buttonChooseRole', // Type: text
    'buttonShowTeamBalance': 'lobby.buttonShowTeamBalance', // Type: text
    'teamBalanceHeader': 'lobby.teamBalanceHeader', // Type: text
    'noRoomCodeTitle': 'lobby.noRoomCodeTitle', // Type: text
    'noRoomCodeBody': 'lobby.noRoomCodeBody', // Type: text
  },
  'leaderboard': {
    'header': 'leaderboard.header', // Type: text
    'buttonGoBack': 'leaderboard.buttonGoBack', // Type: text
    'loading': 'leaderboard.loading', // Type: text
  },
  'topUi': {
    'actionPoints': 'topUi.actionPoints', // Type: text
    'trust': 'topUi.trust', // Type: text
    'collaboration': 'topUi.collaboration', // Type: text
    'healthSecurity': 'topUi.healthSecurity', // Type: text
    'mgEventSuccess': 'topUi.mgEventSuccess', // Type: text
    'buttonPauseGame': 'topUi.buttonPauseGame', // Type: text
    'buttonResumeGame': 'topUi.buttonResumeGame', // Type: text
    'buttonEndGame': 'topUi.buttonEndGame', // Type: text
    'totalActionPointCost': 'topUi.totalActionPointCost', // Type: text
    'timeLeftHover': 'topUi.timeLeftHover', // Type: text
    'pauseConfirmationTitle': 'topUi.pauseConfirmationTitle', // Type: text
    'pauseConfirmationBody': 'topUi.pauseConfirmationBody', // Type: text
  },
  'pause': {
    'gameCurrentlyPaused': 'pause.gameCurrentlyPaused', // Type: text
  },
  'dashboard': {
    'indicators': 'dashboard.indicators', // Type: text
    'usedActions': 'dashboard.usedActions', // Type: text
    'strategicActions': 'dashboard.strategicActions', // Type: text
    'responseActions': 'dashboard.responseActions', // Type: text
    'indicatorAverageCapacityLevel': 'dashboard.indicatorAverageCapacityLevel', // Type: text
    'indicatorName': 'dashboard.indicatorName', // Type: text
    'capacityLevel': 'dashboard.capacityLevel', // Type: text
    'actionCost': 'dashboard.actionCost', // Type: text
    'actionIndicatorsImpacted': 'dashboard.actionIndicatorsImpacted', // Type: text
    'actionMonth': 'dashboard.actionMonth', // Type: text
    'actionDescription': 'dashboard.actionDescription', // Type: text
    'actionInResponseTo': 'dashboard.actionInResponseTo', // Type: text
  },
  'meetingPopup': {
    'strategicMeetingHeader': 'meetingPopup.strategicMeetingHeader', // Type: text
    'hazardMeetingHeader': 'meetingPopup.hazardMeetingHeader', // Type: text
    'unknownMeetingHeader': 'meetingPopup.unknownMeetingHeader', // Type: text
    'totalCost': 'meetingPopup.totalCost', // Type: text
    'buttonJoinMeeting': 'meetingPopup.buttonJoinMeeting', // Type: text
    'invitationMessage': 'meetingPopup.invitationMessage', // Type: text
    'noActionsInvested': 'meetingPopup.noActionsInvested', // Type: text
    'buttonAdd': 'meetingPopup.buttonAdd', // Type: text
    'buttonRemove': 'meetingPopup.buttonRemove', // Type: text
    'cost': 'meetingPopup.cost', // Type: text
    'allActionsInvested': 'meetingPopup.allActionsInvested', // Type: text
    'noActionsInGroup': 'meetingPopup.noActionsInGroup', // Type: text
    'everythingInvested': 'meetingPopup.everythingInvested', // Type: text
    'meetingResultsHeader': 'meetingPopup.meetingResultsHeader', // Type: text
    'meetingResultsActionsTaken': 'meetingPopup.meetingResultsActionsTaken', // Type: text
    'meetingResultsFeedback': 'meetingPopup.meetingResultsFeedback', // Type: text
    'feedbackHeader': 'meetingPopup.feedbackHeader', // Type: text
    'feedbackNothingImplemented': 'meetingPopup.feedbackNothingImplemented', // Type: text
    'feedbackActionsTaken': 'meetingPopup.feedbackActionsTaken', // Type: text
    'feedbackFeedback': 'meetingPopup.feedbackFeedback', // Type: text
    'hazardPrioritizationUpNext': 'meetingPopup.hazardPrioritizationUpNext', // Type: text
    'hazardPrioritizationsHeader': 'meetingPopup.hazardPrioritizationsHeader', // Type: text
    'hazardNoPrioritizationsHeader': 'meetingPopup.hazardNoPrioritizationsHeader', // Type: text
    'hazardNoPrioritizationsDescription': 'meetingPopup.hazardNoPrioritizationsDescription', // Type: text
    'feedbackClickToShowFeedback': 'meetingPopup.feedbackClickToShowFeedback', // Type: text
  },
  'prioritize': {
    'buttonPrioritize': 'prioritize.buttonPrioritize', // Type: text
    'buttonUnprioritize': 'prioritize.buttonUnprioritize', // Type: text
    'buttonKeepPrioritized': 'prioritize.buttonKeepPrioritized', // Type: text
    'buttonKeepUnprioritized': 'prioritize.buttonKeepUnprioritized', // Type: text
    'buttonLearnMore': 'prioritize.buttonLearnMore', // Type: text
    'prioritizeHeader': 'prioritize.prioritizeHeader', // Type: text
    'usedAllFlags': 'prioritize.usedAllFlags', // Type: text
    'prioritizedFollowingHazards': 'prioritize.prioritizedFollowingHazards', // Type: text
    'buttonSwap': 'prioritize.buttonSwap', // Type: text
    'buttonRemove': 'prioritize.buttonRemove', // Type: text
    'buttonInspect': 'prioritize.buttonInspect', // Type: text
  },
  'hazard': {
    'animal': 'hazard.animal', // Type: text
    'chemical': 'hazard.chemical', // Type: text
    'disaster': 'hazard.disaster', // Type: text
    'environmental': 'hazard.environmental', // Type: text
    'infectious': 'hazard.infectious', // Type: text
    'societal': 'hazard.societal', // Type: text
    'zoonosis': 'hazard.zoonosis', // Type: text
    'unknown': 'hazard.unknown', // Type: text
    'breakingNews': 'hazard.breakingNews', // Type: text
    'foodSafety': 'hazard.foodSafety', // Type: text
    'news': 'hazard.news', // Type: text
  },
  'epiReport': {
    'header': 'epiReport.header', // Type: text
    'virusName': 'epiReport.virusName', // Type: text
    'cases': 'epiReport.cases', // Type: text
    'deaths': 'epiReport.deaths', // Type: text
    'buttonPreviousMonth': 'epiReport.buttonPreviousMonth', // Type: text
    'buttonNextMonth': 'epiReport.buttonNextMonth', // Type: text
  },
  'error': {
    'couldNotInvest': 'error.couldNotInvest', // Type: text
    'buttonOk': 'error.buttonOk', // Type: text
    'title': 'error.title', // Type: text
  },
  'notificationAssessment': {
    'header': 'notificationAssessment.header', // Type: text
    'intro': 'notificationAssessment.intro', // Type: text
    'q1': 'notificationAssessment.q1', // Type: text
    'q2': 'notificationAssessment.q2', // Type: text
    'q3': 'notificationAssessment.q3', // Type: text
    'q4': 'notificationAssessment.q4', // Type: text
    'q5': 'notificationAssessment.q5', // Type: text
    'q6': 'notificationAssessment.q6', // Type: text
    'answerYes': 'notificationAssessment.answerYes', // Type: text
    'answerNo': 'notificationAssessment.answerNo', // Type: text
  },
  'various': {
    'confirmationPopupAnswerYes': 'various.confirmationPopupAnswerYes', // Type: text
    'confirmationPopupAnswerNo': 'various.confirmationPopupAnswerNo', // Type: text
  },
  'starMinigame': {
    'invitationHeader': 'starMinigame.invitationHeader', // Type: text
    'invitationBodyTitle': 'starMinigame.invitationBodyTitle', // Type: text
    'invitationBodyContents': 'starMinigame.invitationBodyContents', // Type: text
    'buttonInvitationContinue': 'starMinigame.buttonInvitationContinue', // Type: text
    'buttonStrategicToolkit': 'starMinigame.buttonStrategicToolkit', // Type: text
    'buttonChoose': 'starMinigame.buttonChoose', // Type: text
    'buttonChosen': 'starMinigame.buttonChosen', // Type: text
    'buttonStarDocuments': 'starMinigame.buttonStarDocuments', // Type: text
    'frequency_perennial': 'starMinigame.frequency_perennial', // Type: text
    'frequency_recurrent': 'starMinigame.frequency_recurrent', // Type: text
    'frequency_frequent': 'starMinigame.frequency_frequent', // Type: text
    'frequency_rare': 'starMinigame.frequency_rare', // Type: text
    'frequency_random': 'starMinigame.frequency_random', // Type: text
    'likelihood_veryUnlikely': 'starMinigame.likelihood_veryUnlikely', // Type: text
    'likelihood_unlikely': 'starMinigame.likelihood_unlikely', // Type: text
    'likelihood_almostCertain': 'starMinigame.likelihood_almostCertain', // Type: text
    'likelihood_veryLikely': 'starMinigame.likelihood_veryLikely', // Type: text
    'likelihood_likely': 'starMinigame.likelihood_likely', // Type: text
    'severity_veryHigh': 'starMinigame.severity_veryHigh', // Type: text
    'severity_high': 'starMinigame.severity_high', // Type: text
    'severity_moderate': 'starMinigame.severity_moderate', // Type: text
    'severity_low': 'starMinigame.severity_low', // Type: text
    'vulnerability_low': 'starMinigame.vulnerability_low', // Type: text
    'vulnerability_partial': 'starMinigame.vulnerability_partial', // Type: text
    'vulnerability_high': 'starMinigame.vulnerability_high', // Type: text
    'vulnerability_veryHigh': 'starMinigame.vulnerability_veryHigh', // Type: text
    'copingCapacity_perennial': 'starMinigame.copingCapacity_perennial', // Type: text
    'copingCapacity_partial': 'starMinigame.copingCapacity_partial', // Type: text
    'copingCapacity_high': 'starMinigame.copingCapacity_high', // Type: text
    'copingCapacity_veryHigh': 'starMinigame.copingCapacity_veryHigh', // Type: text
    'impact_perennial': 'starMinigame.impact_perennial', // Type: text
    'impact_partial': 'starMinigame.impact_partial', // Type: text
    'impact_moderate': 'starMinigame.impact_moderate', // Type: text
    'impact_high': 'starMinigame.impact_high', // Type: text
    'impact_veryHigh': 'starMinigame.impact_veryHigh', // Type: text
    'confidenceLevel_satisfactory': 'starMinigame.confidenceLevel_satisfactory', // Type: text
    'confidenceLevel_unsatisfactory': 'starMinigame.confidenceLevel_unsatisfactory', // Type: text
    'confidenceLevel_good': 'starMinigame.confidenceLevel_good', // Type: text
    'riskLevel_low': 'starMinigame.riskLevel_low', // Type: text
    'riskLevel_partial': 'starMinigame.riskLevel_partial', // Type: text
    'riskLevel_high': 'starMinigame.riskLevel_high', // Type: text
    'riskLevel_veryHigh': 'starMinigame.riskLevel_veryHigh', // Type: text
    'row1riskAssessment': 'starMinigame.row1riskAssessment', // Type: text
    'row1globalLand': 'starMinigame.row1globalLand', // Type: text
    'row2hazardsAndExposures': 'starMinigame.row2hazardsAndExposures', // Type: text
    'row2likelihood': 'starMinigame.row2likelihood', // Type: text
    'row2likelihoodSeasonality': 'starMinigame.row2likelihoodSeasonality', // Type: text
    'row2severityVulnerabilityCopingCapacity': 'starMinigame.row2severityVulnerabilityCopingCapacity', // Type: text
    'row2impact': 'starMinigame.row2impact', // Type: text
    'row2confidenceLevel': 'starMinigame.row2confidenceLevel', // Type: text
    'row2riskLevel': 'starMinigame.row2riskLevel', // Type: text
    'row3subgroupOfHazard': 'starMinigame.row3subgroupOfHazard', // Type: text
    'row3mainTypeOfHazard': 'starMinigame.row3mainTypeOfHazard', // Type: text
    'row3specificHazard': 'starMinigame.row3specificHazard', // Type: text
    'row3healthConsequences': 'starMinigame.row3healthConsequences', // Type: text
    'row3scale': 'starMinigame.row3scale', // Type: text
    'row3exposure': 'starMinigame.row3exposure', // Type: text
    'row3frequency': 'starMinigame.row3frequency', // Type: text
    'row3frequency_january': 'starMinigame.row3frequency_january', // Type: text
    'row3frequency_february': 'starMinigame.row3frequency_february', // Type: text
    'row3frequency_march': 'starMinigame.row3frequency_march', // Type: text
    'row3frequency_april': 'starMinigame.row3frequency_april', // Type: text
    'row3frequency_may': 'starMinigame.row3frequency_may', // Type: text
    'row3frequency_june': 'starMinigame.row3frequency_june', // Type: text
    'row3frequency_july': 'starMinigame.row3frequency_july', // Type: text
    'row3frequency_august': 'starMinigame.row3frequency_august', // Type: text
    'row3frequency_september': 'starMinigame.row3frequency_september', // Type: text
    'row3frequency_october': 'starMinigame.row3frequency_october', // Type: text
    'row3frequency_november': 'starMinigame.row3frequency_november', // Type: text
    'row3frequency_december': 'starMinigame.row3frequency_december', // Type: text
    'row3likelihood': 'starMinigame.row3likelihood', // Type: text
    'row3severity': 'starMinigame.row3severity', // Type: text
    'row3vulnerability': 'starMinigame.row3vulnerability', // Type: text
    'row3copingCapacity': 'starMinigame.row3copingCapacity', // Type: text
    'btnAvailable': 'starMinigame.btnAvailable', // Type: text
    'btnUnavailable': 'starMinigame.btnUnavailable', // Type: text
    'btnActive': 'starMinigame.btnActive', // Type: text
    'btnCompleted': 'starMinigame.btnCompleted', // Type: text
    'headerFrequency': 'starMinigame.headerFrequency', // Type: text
    'headerSeasonality': 'starMinigame.headerSeasonality', // Type: text
    'headerLikelihood': 'starMinigame.headerLikelihood', // Type: text
    'headerSeverity': 'starMinigame.headerSeverity', // Type: text
    'headerVulnerability': 'starMinigame.headerVulnerability', // Type: text
    'headerCopingCapacity': 'starMinigame.headerCopingCapacity', // Type: text
    'titleDocumentStrategicToolkit': 'starMinigame.titleDocumentStrategicToolkit', // Type: text
    'titleDocumentStarDocument': 'starMinigame.titleDocumentStarDocument', // Type: text
    'titleTutorial': 'starMinigame.titleTutorial', // Type: text
    'titleOverview': 'starMinigame.titleOverview', // Type: text
    'titleLikelihoodVote': 'starMinigame.titleLikelihoodVote', // Type: text
    'titleImpactVote': 'starMinigame.titleImpactVote', // Type: text
    'titleRiskMatrix': 'starMinigame.titleRiskMatrix', // Type: text
    'titleDefault': 'starMinigame.titleDefault', // Type: text
    'documentHelpText': 'starMinigame.documentHelpText', // Type: text
  },
  'starMinigameTutorialFrequency': {
    'introText': 'starMinigameTutorialFrequency.introText', // Type: text
    'perennial': 'starMinigameTutorialFrequency.perennial', // Type: text
    'perennialDescription': 'starMinigameTutorialFrequency.perennialDescription', // Type: text
    'recurrent': 'starMinigameTutorialFrequency.recurrent', // Type: text
    'recurrentDescription': 'starMinigameTutorialFrequency.recurrentDescription', // Type: text
    'frequent': 'starMinigameTutorialFrequency.frequent', // Type: text
    'frequentDescription': 'starMinigameTutorialFrequency.frequentDescription', // Type: text
    'rare': 'starMinigameTutorialFrequency.rare', // Type: text
    'rareDescription': 'starMinigameTutorialFrequency.rareDescription', // Type: text
    'random': 'starMinigameTutorialFrequency.random', // Type: text
    'randomDescription': 'starMinigameTutorialFrequency.randomDescription', // Type: text
  },
  'starMinigameTutorialLikelihood': {
    'introText': 'starMinigameTutorialLikelihood.introText', // Type: text
    'headerLikelihood': 'starMinigameTutorialLikelihood.headerLikelihood', // Type: text
    'headerDescription': 'starMinigameTutorialLikelihood.headerDescription', // Type: text
    'veryUnlikely': 'starMinigameTutorialLikelihood.veryUnlikely', // Type: text
    'veryUnlikelyDescription': 'starMinigameTutorialLikelihood.veryUnlikelyDescription', // Type: text
    'unlikely': 'starMinigameTutorialLikelihood.unlikely', // Type: text
    'unlikelyDescription': 'starMinigameTutorialLikelihood.unlikelyDescription', // Type: text
    'likely': 'starMinigameTutorialLikelihood.likely', // Type: text
    'likelyDescription': 'starMinigameTutorialLikelihood.likelyDescription', // Type: text
    'veryLikely': 'starMinigameTutorialLikelihood.veryLikely', // Type: text
    'veryLikelyDescription': 'starMinigameTutorialLikelihood.veryLikelyDescription', // Type: text
    'almostCertain': 'starMinigameTutorialLikelihood.almostCertain', // Type: text
    'almostCertainDescription': 'starMinigameTutorialLikelihood.almostCertainDescription', // Type: text
  },
  'starMinigameTutorialSeasonality': {
    'introText': 'starMinigameTutorialSeasonality.introText', // Type: text
    'monthAbbreviationJanuary': 'starMinigameTutorialSeasonality.monthAbbreviationJanuary', // Type: text
    'monthAbbreviationFebruary': 'starMinigameTutorialSeasonality.monthAbbreviationFebruary', // Type: text
    'monthAbbreviationMarch': 'starMinigameTutorialSeasonality.monthAbbreviationMarch', // Type: text
    'monthAbbreviationApril': 'starMinigameTutorialSeasonality.monthAbbreviationApril', // Type: text
    'monthAbbreviationMay': 'starMinigameTutorialSeasonality.monthAbbreviationMay', // Type: text
    'monthAbbreviationJune': 'starMinigameTutorialSeasonality.monthAbbreviationJune', // Type: text
    'monthAbbreviationJuly': 'starMinigameTutorialSeasonality.monthAbbreviationJuly', // Type: text
    'monthAbbreviationAugust': 'starMinigameTutorialSeasonality.monthAbbreviationAugust', // Type: text
    'monthAbbreviationSeptember': 'starMinigameTutorialSeasonality.monthAbbreviationSeptember', // Type: text
    'monthAbbreviationOctober': 'starMinigameTutorialSeasonality.monthAbbreviationOctober', // Type: text
    'monthAbbreviationNovember': 'starMinigameTutorialSeasonality.monthAbbreviationNovember', // Type: text
    'monthAbbreviationDecember': 'starMinigameTutorialSeasonality.monthAbbreviationDecember', // Type: text
  },
  'starMinigameTutorialSeverity': {
    'introText': 'starMinigameTutorialSeverity.introText', // Type: text
    'list1point1': 'starMinigameTutorialSeverity.list1point1', // Type: text
    'list1point2': 'starMinigameTutorialSeverity.list1point2', // Type: text
    'list1point3': 'starMinigameTutorialSeverity.list1point3', // Type: text
    'paragraph2': 'starMinigameTutorialSeverity.paragraph2', // Type: text
    'list2point1': 'starMinigameTutorialSeverity.list2point1', // Type: text
    'list2point2': 'starMinigameTutorialSeverity.list2point2', // Type: text
    'tableHeader': 'starMinigameTutorialSeverity.tableHeader', // Type: text
    'tableVeryLowSeverity': 'starMinigameTutorialSeverity.tableVeryLowSeverity', // Type: text
    'tableLowSeverity': 'starMinigameTutorialSeverity.tableLowSeverity', // Type: text
    'tableModerateSeverity': 'starMinigameTutorialSeverity.tableModerateSeverity', // Type: text
    'tableHighSeverity': 'starMinigameTutorialSeverity.tableHighSeverity', // Type: text
    'tableVeryHighSeverity': 'starMinigameTutorialSeverity.tableVeryHighSeverity', // Type: text
  },
  'starMinigameTutorialVulnerability': {
    'paragraph1': 'starMinigameTutorialVulnerability.paragraph1', // Type: text
    'list1point1': 'starMinigameTutorialVulnerability.list1point1', // Type: text
    'list1point2': 'starMinigameTutorialVulnerability.list1point2', // Type: text
    'list1point3': 'starMinigameTutorialVulnerability.list1point3', // Type: text
    'list1point4': 'starMinigameTutorialVulnerability.list1point4', // Type: text
    'paragraph2': 'starMinigameTutorialVulnerability.paragraph2', // Type: text
    'tableHeader': 'starMinigameTutorialVulnerability.tableHeader', // Type: text
    'tableVeryHigh': 'starMinigameTutorialVulnerability.tableVeryHigh', // Type: text
    'tableHigh': 'starMinigameTutorialVulnerability.tableHigh', // Type: text
    'tableModerate': 'starMinigameTutorialVulnerability.tableModerate', // Type: text
    'tableLow': 'starMinigameTutorialVulnerability.tableLow', // Type: text
    'tableVeryLow': 'starMinigameTutorialVulnerability.tableVeryLow', // Type: text
  },
  'starMinigameTutorialCopingCapacity': {
    'paragraph1': 'starMinigameTutorialCopingCapacity.paragraph1', // Type: text
    'list1point1': 'starMinigameTutorialCopingCapacity.list1point1', // Type: text
    'list1point2': 'starMinigameTutorialCopingCapacity.list1point2', // Type: text
    'list1point3': 'starMinigameTutorialCopingCapacity.list1point3', // Type: text
    'paragraph2': 'starMinigameTutorialCopingCapacity.paragraph2', // Type: text
    'tableHeaderCopingCapacity': 'starMinigameTutorialCopingCapacity.tableHeaderCopingCapacity', // Type: text
    'tableHeaderDescription': 'starMinigameTutorialCopingCapacity.tableHeaderDescription', // Type: text
    '': 'starMinigameTutorialCopingCapacity.', // Type: text
    'tableRowVeryHigh': 'starMinigameTutorialCopingCapacity.tableRowVeryHigh', // Type: text
    'tableRowVeryHighDescription': 'starMinigameTutorialCopingCapacity.tableRowVeryHighDescription', // Type: text
    'tableRowHigh': 'starMinigameTutorialCopingCapacity.tableRowHigh', // Type: text
    'tableRowHighDescription': 'starMinigameTutorialCopingCapacity.tableRowHighDescription', // Type: text
    'tableRowPartial': 'starMinigameTutorialCopingCapacity.tableRowPartial', // Type: text
    'tableRowPartialDescription': 'starMinigameTutorialCopingCapacity.tableRowPartialDescription', // Type: text
    'tableRowLow': 'starMinigameTutorialCopingCapacity.tableRowLow', // Type: text
    'tableRowLowDescription': 'starMinigameTutorialCopingCapacity.tableRowLowDescription', // Type: text
    'tableRowVeryLow': 'starMinigameTutorialCopingCapacity.tableRowVeryLow', // Type: text
    'tableRowVeryLowDescription': 'starMinigameTutorialCopingCapacity.tableRowVeryLowDescription', // Type: text
  },
  'starMinigameRiskMatrix': {
    'critical': 'starMinigameRiskMatrix.critical', // Type: text
    'severe': 'starMinigameRiskMatrix.severe', // Type: text
    'moderate': 'starMinigameRiskMatrix.moderate', // Type: text
    'minor': 'starMinigameRiskMatrix.minor', // Type: text
    'negligible': 'starMinigameRiskMatrix.negligible', // Type: text
    'veryUnlikely': 'starMinigameRiskMatrix.veryUnlikely', // Type: text
    'unlikely': 'starMinigameRiskMatrix.unlikely', // Type: text
    'likely': 'starMinigameRiskMatrix.likely', // Type: text
    'veryLikely': 'starMinigameRiskMatrix.veryLikely', // Type: text
    'almostCertain': 'starMinigameRiskMatrix.almostCertain', // Type: text
    'impact': 'starMinigameRiskMatrix.impact', // Type: text
    'likelihood': 'starMinigameRiskMatrix.likelihood', // Type: text
    'switchToggle': 'starMinigameRiskMatrix.switchToggle', // Type: text
    'switchOld': 'starMinigameRiskMatrix.switchOld', // Type: text
    'switchNew': 'starMinigameRiskMatrix.switchNew', // Type: text
    'switchCorrect': 'starMinigameRiskMatrix.switchCorrect', // Type: text
    'titleOld': 'starMinigameRiskMatrix.titleOld', // Type: text
    'titleNew': 'starMinigameRiskMatrix.titleNew', // Type: text
    'titleCorrect': 'starMinigameRiskMatrix.titleCorrect', // Type: text
  },
  'debriefing': {
    'finalFeedbackTitle': 'debriefing.finalFeedbackTitle', // Type: text
    'finalFeedbackDescription': 'debriefing.finalFeedbackDescription', // Type: text
    'finalScoreTitle': 'debriefing.finalScoreTitle', // Type: text
    'finalScoreDescription': 'debriefing.finalScoreDescription', // Type: text
    'indicatorsTitle': 'debriefing.indicatorsTitle', // Type: text
    'indicatorsDescription': 'debriefing.indicatorsDescription', // Type: text
    'learningObjectivesTitle': 'debriefing.learningObjectivesTitle', // Type: text
    'actionsTakenTitle': 'debriefing.actionsTakenTitle', // Type: text
    'trust': 'debriefing.trust', // Type: text
    'healthSecurity': 'debriefing.healthSecurity', // Type: text
    'collaboration': 'debriefing.collaboration', // Type: text
    'massGatheringSuccess': 'debriefing.massGatheringSuccess', // Type: text
  },
  'tutorial': {
    'title': 'tutorial.title', // Type: text
    'understood': 'tutorial.understood', // Type: text
    'timeline': 'tutorial.timeline', // Type: text
    'character': 'tutorial.character', // Type: text
    'flag': 'tutorial.flag', // Type: text
    'scores': 'tutorial.scores', // Type: text
    'actionPoints': 'tutorial.actionPoints', // Type: text
  },
};