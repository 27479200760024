<template>
  <lobby-popup-template>
    <template v-slot:header>
      {{ $t('lobby.headerChooseAvatar') }}
    </template>
    <template v-slot:content>
      <div class="character-container-wrapper">
        <div class="character-container d-flex flex-wrap justify-content-evenly epic-scrollbar">
          <div class="single-character-container" v-for="n in characters.length">
            <character-thumbnail
              class="character-thumbnail"
              :class="{ ' character-thumbnail-selected': (n - 1) === selectedCharacterIndex, 'character-thumbnail-taken': otherSelectedCharacterIndexesValues.includes(n - 1) }"
              :character-index="n - 1"
              :show-background="false"
              @click="selectCharacterIndex(n - 1)"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="selectedCharacterIndex !== null">
        <character-thumbnail :character-index="selectedCharacterIndex"/>
      </div>
      <div v-if="selectedCharacterIndex !== null" class="ms-3 me-3 player-name-container">
        <div>
          <label>{{ $t('lobby.playerName') }}</label>
        </div>
        <div>
          <input type="text" v-model="playerName" class="form-control" maxlength="10" :placeholder="$t('lobby.playerName')" />
        </div>
      </div>
      <div>
        <app-button @click="join" color="green" width="lg" :disabled="!canJoin">{{ $t('lobby.buttonJoin') }}</app-button>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LongButton from '@/components/common/LongButton'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate'
import NoDragImage from '@/components/common/NoDragImage'
import CharacterThumbnail from '@/components/character/CharacterThumbnail'
import CharacterData from '@/data/character-data'
import { mapGetters } from 'vuex'
import AppButton from '@/components/common/buttons/AppButton.vue';
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'LobbyPopupChooseAvatar',
  components: {
    CharacterThumbnail,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate
  },
  emits: ['continue'],
  props: {
    forceChoice: {
      type: Boolean,
      required: false,
      default: false,
    },
    otherSelectedCharacterIndexes: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
  },
  data () {
    return {
      characters: CharacterData,
      selectedCharacterIndex: null,
      playerName: '',
    }
  },
  computed: {
    ...mapGetters(['playerSelf']),
    canJoin () {
      if (!this.$gameClient.hasJoinedRoom()) {
        return false
      }

      if (this.selectedCharacterIndex === null) {
        return false
      }

      const playerName = this.playerName.replace(/\s+/, ' ').replace(/(^\s+|\s+$)/, '') || ''

      if (!playerName) {
        return false
      }

      return true
    },
    otherSelectedCharacterIndexesValues() {
      return Object.values(this.otherSelectedCharacterIndexes);
    },
  },
  mounted () {
    if (this.playerSelf) {
      this.selectedCharacterIndex = this.playerSelf.characterIndex
      //this.playerName = this.playerSelf.name
    }

    if (this.forceChoice) {
      this.join(null, true)
    } else {
      TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.CharacterSelection);
    }
  },
  watch: {
    forceCharacterChoice() {
      if (!this.forceCharacterChoice) {
        return;
      }

      this.join(null,  true)
    },
    forceChoice () {
      if (!this.forceChoice) {
        return
      }

      this.join(null,  true)
    },
    playerName () {
      this.playerName = this.playerName.replace(/\s+/, ' ')
    },
    initValue () {
      if (!this.initialValue) {
        return
      }

      if (this.initialValue.characterIndex >= 0 && this.selectedCharacterIndex === null) {
        this.selectedCharacterIndex = this.initialValue.characterIndex
      }

      if (this.initialValue.name && this.playerName === null) {
        this.playerName = this.initialValue.name
      }
    },
  },
  methods: {
    selectCharacterIndex(characterIndex) {
      if (this.otherSelectedCharacterIndexesValues.includes(characterIndex)) {
        return; // Ignore
      }

      this.selectedCharacterIndex = characterIndex;

      this.$gameClient.playerSet({
        characterIndex: this.selectedCharacterIndex,
      })
    },
    join (e, force = false) {
      if (!force && !this.canJoin) {
        return
      }

      const playerName = this.playerName.replace(/\s+/, ' ').replace(/(^\s+|\s+$)/, '') || ''

      const playerSet = {
        characterIndex: this.selectedCharacterIndex,
      }

      if (playerName) {
        playerSet.name = playerName;
      }

      console.log('PLAYER SET', playerSet);

      this.$gameClient.playerSet(playerSet)
      this.$emit('continue', playerSet)
    },
  },
}
</script>

<style lang="scss" scoped>
.character-container {
  height: 100%;
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.single-character-container {
  padding: 10px;
}

.character-container-wrapper {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 10px;
}

.character-thumbnail {
  cursor: pointer;
  position: relative;
  transition: 150ms ease-out;
  top: 0;

  &.character-thumbnail-selected {
    top: -20px;
    box-shadow: 0 -5px 31px 20px #A0D530;
    //filter: drop-shadow(0px -5px 31px #A0D530);
  }

  &.character-thumbnail-taken {
    filter: saturate(0);
    transform: scale(0.6);
    opacity: .5;
    transform-origin: bottom center;
  }
}

.player-name-container {
  color: white;
  font-size: 18pt;
  text-transform: uppercase;
  font-weight: 700;
}
</style>
