import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createI18n } from 'vue-i18n';
import localMessages from './lib/i18n/index.js';

import Vue3Toastify, {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import GameClient from './plugins/GameClient';
import ApiClient from "@/plugins/ApiClient";
import VoiceController from '@/plugins/VoiceController';
import VoiceChatClient from "@/plugins/VoiceChatClient";
import { getOverlayPop } from '@/plugins/OverlayPop'
import { getAlertPop } from '@/plugins/AlertPop'
import { getTutorialHighlightOverlay } from '@/plugins/TutorialHighlightOverlay';
import { i18n } from '@/lib/i18n/i18n'

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(VoiceController)
  .use(GameClient)
  .use(VoiceChatClient)
  .use(ApiClient)
  .use(
    Vue3Toastify,
    {
      autoClose: 3000,
      closeOnClick: true,
      position: toast.POSITION.BOTTOM_RIGHT,
    }
  )
  .use(getAlertPop(store))
  .use(getOverlayPop(store))
  .use(getTutorialHighlightOverlay(store));

app.mount('#app')
