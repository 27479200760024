<template>
  <app-button :width="size" :height="size" :color="color" :state="state" :disabled="disabled" :type="type" class="img-button">
    <slot />
  </app-button>
</template>

<script>
import {SIZE_XS, SIZE_SM, SIZE_MD, SIZE_LG, SIZE_XL, SIZE_FIT_CONTENT, SIZE_FULL} from './enums';
import {TYPE_BOX_ROUNDED, TYPE_BOX, TYPE_CIRCLE} from './enums';
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'ImageButton',
  components: { AppButton },
  props: {
    color: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: SIZE_MD,
    },
    type: {
      type: String,
      required: false,
      default: TYPE_BOX_ROUNDED,
    }
  },
}
</script>

<style lang="scss">
@import "./variables.scss";

.img-button {
  img {
    /*max-height: 100%;
    aspect-ratio: auto;
    height: 100%;*/
    aspect-ratio: 1;
    max-height: 100%;
    max-width: 100%;
  }

  &.img-button-height-xs {
    img {
      max-height: $heightXs;
    }
  }

  &.img-button-height-sm {
    img {
      max-height: $heightSm;
    }
  }

  &.img-button-height-md {
    img {
      max-height: $heightMd;
    }
  }

  &.img-button-height-lg {
    img {
      max-height: $heightLg;
    }
  }

  &.img-button-height-xl {
    img {
      max-height: $heightXl;
    }
  }

  &.img-button-height-fit-content {
    img {
      max-height: $heightFitContent;
    }
  }

  &.img-button-height-full {
    img {
      max-height: $heightFull;
    }
  }
}
</style>
