import { timeoutPromise } from '@/utils/promiseUtil'

export default {
  state: {
    aspectRatioScale: 1,

    jeeCategories: [],
    toolboxCategories: [],
    roles: [],
    areasOfExpertise: [],
    joinedRoomCustomUid: null,

    backgroundBlingTop: false,
    backgroundBlingBottom: false,
    background: null,

    hazardMeetingOpenId: null,
    hazardMeetingOpenState: null,

    errorMessage: null,

    seenTeaser: false,
    seenIntro: false,
    showTutorial: true,
    joinCode: null,

    starScoreImpact: null,

    topUiExtraTrust: 0,
    topUiExtraCollaboration: 0,
    topUiExtraHealthSecurity: 0,
    topUiExtraMgEventSuccess: 0,
  },
  mutations: {
    aspectRatioScale: (state, aspectRatioScale) => {
      state.aspectRatioScale = aspectRatioScale;
    },
    seenIntro: (state, value) => {
      state.seenIntro = value;
    },
    seenTeaser: (state, value) => {
      state.seenTeaser = value;
    },
    showTutorial: (state, value) => {
      state.showTutorial = value;
    },
    joinCode: (state, value) => {
      state.joinCode = value;
    },
    errorMessage: (state, value) => {
      state.errorMessage = value;
    },
    background: (state, value) => {
      state.background = value;
    },
    backgroundBlingTop: (state, value) => {
      state.backgroundBlingTop = value;
    },
    backgroundBlingBottom: (state, value) => {
      state.backgroundBlingBottom = value;
    },
    hazardMeetingOpenId: (state, value) => {
      if (typeof(value) === 'number') {
        value = value.toString();
      }

      console.log('Set HazardMeetingOpenId to', value);

      state.hazardMeetingOpenId = value
      state.hazardMeetingOpenState = null;
    },
    hazardMeetingOpenState: (state, value) => {
      state.hazardMeetingOpenState = value.toString()
    },

    jeeCategories: (state, value) => {
      state.jeeCategories = value
    },

    toolboxCategories: (state, value) => {
      state.toolboxCategories = value
    },

    roles: (state, value) => {
      state.roles = value
    },

    areasOfExpertise: (state, value) => {
      state.areasOfExpertise = value
    },

    joinedRoomCustomUid: (state, value) => {
      state.joinedRoomCustomUid = value
    },

    starScoreImpact: (state, value) => {
      state.starScoreImpact = value
    },

    topUiExtraTrust: (state, value) => {
      state.topUiExtraTrust = value;
    },
    topUiExtraCollaboration: (state, value) => {
      state.topUiExtraCollaboration = value;
    },
    topUiExtraHealthSecurity: (state, value) => {
      state.topUiExtraHealthSecurity = value;
    },
    topUiExtraMgEventSuccess: (state, value) => {
      state.topUiExtraMgEventSuccess = value;
    },
  },
  getters: {
    aspectRatioScale(state) {
      return state.aspectRatioScale;
    },
    seenIntro(state) {
      return state.seenIntro;
    },
    seenTeaser(state) {
      return state.seenTeaser;
    },
    showTutorial (state) {
      return state.showTutorial;
    },
    joinCode(state) {
      return state.joinCode;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    backgroundBlingTop(state) {
      return state.backgroundBlingTop;
    },
    backgroundBlingBottom(state) {
      return state.backgroundBlingBottom;
    },
    background(state) {
      return state.background;
    },
    hazardMeetingOpenId (state) {
      return state.hazardMeetingOpenId
    },
    hazardMeetingOpenState (state) {
      return state.hazardMeetingOpenState
    },
    jeeCategories (state) {
      return state.jeeCategories
    },

    toolboxCategories (state) {
      return state.toolboxCategories
    },

    roles (state) {
      return state.roles
    },

    areasOfExpertise (state) {
      return state.areasOfExpertise
    },

    starScoreImpact (state) {
      return state.starScoreImpact
    },

    topUiExtraTrust(state) {
      return state.topUiExtraTrust;
    },
    topUiExtraCollaboration(state) {
      return state.topUiExtraCollaboration;
    },
    topUiExtraHealthSecurity(state) {
      return state.topUiExtraHealthSecurity;
    },
    topUiExtraMgEventSuccess(state) {
      return state.topUiExtraMgEventSuccess;
    },
  },
}
