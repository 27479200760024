<template>
  <strategic-meeting-results :invested-actions="investedActions" @continue="onContinue" />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'
import StrategicMeetingResults from '@/components/StrategicMeeting/StrategicMeetingResults/index.vue';
import {shuffle} from '@/utils/arrayUtil';
import {randomBetween} from '@/utils/randomUtil';

export default {
  name: 'StrategicMeetingResultsDev',
  components: {
    StrategicMeetingResults,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['continue'],
      investedActions: [],
    }
  },
  computed: {
    ...mapState(['background']),
  },
  mounted() {
    this.$store.commit('background', 'roundtable');

    this.$apiClient.get('/mission/strategic').then((data) => {
      this.actionCategories = data.categories;

      const investedActions = [];

      for (const category of data.categories) {
        for (const tab of category.tabs) {
          for (const action of tab.actions) {
            action.cost = action.baseCost; // Do more advanced calculation here, but it's just for the dev panel

            investedActions.push(action);
          }
        }
      }

      this.investedActions = shuffle(investedActions).slice(0, randomBetween(3, 10));


      console.log('this.investedActions', this.investedActions);
    });
  },
  unmounted () {
    if (this.background === 'roundtable') {
      this.$store.commit('background', '');
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    }
  },
}
</script>

<style lang="scss" scoped>
.strategic-meeting-popup {
  margin: 0 auto;
  //transform: scale(70%);
}
</style>
