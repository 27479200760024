<template>
  <div class="debriefing-final-score-card">
    <div class="d-flex justify-content-evenly">
      <debriefing-score-card
        v-for="scoreCard in scoreCards"
        :icon-source="scoreCard.iconSource"
        :label="scoreCard.label"
        :score="scoreCard.score"
      />
    </div>
  </div>
</template>
<script>
import DebriefingScoreCard from '@/components/Debriefing/DebriefingFinalScoreCard/DebriefingScoreCard.vue'

import IconTrust from '@/assets/debriefing/icon-lg-trust.png';
import IconHealthSecurity from '@/assets/debriefing/icon-lg-health-security.png';
import IconCollaboration from '@/assets/debriefing/icon-lg-collaboration.png';
import IconMGSuccess from '@/assets/debriefing/icon-lg-mg-success.png';

export default {
  name: 'DebriefingFinalScoreCard',
  components: {
    DebriefingScoreCard,
  },
  props: {
    trust: {
      type: Number,
      required: true,
    },
    healthSecurity: {
      type: Number,
      required: true,
    },
    collaboration: {
      type: Number,
      required: true,
    },
    mgSuccess: {
      type: Number,
      required: true,
    },
  },
  computed: {
    scoreCards() {
      return [
        {
          iconSource: IconTrust,
          label: 'Trust',
          score: this.trust,
        },
        {
          iconSource: IconHealthSecurity,
          label: 'Health Security',
          score: this.healthSecurity,
        },
        {
          iconSource: IconCollaboration,
          label: 'Collaboration',
          score: this.collaboration,
        },
        {
          iconSource: IconMGSuccess,
          label: 'Mass Gathering Success',
          score: this.mgSuccess,
        }
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.debriefing-final-score-card {
  padding: 30px;

  border-radius: 5px;
  //background: url("@/assets/textures/white-grunge-70-percent.png"), lightgray 0% 0% 42px 42px repeat, linear-gradient(180deg, #051224 0%, #38708F 100%);
  background: url("@/assets/textures/grunge-blue-50.png"), linear-gradient(0deg, #051224 0%, #38708F 100%);

  box-shadow: 4px 0px 3px 0px rgba(239, 239, 239, 0.25) inset, -4px 2px 3px 0px rgba(255, 255, 255, 0.25) inset, 0px -4px 2px 1px #071C27 inset, 0px 1px 5px 2px rgba(0, 0, 0, 0.50);
}
</style>
