<template>
  <div class="long-button-dev-container">
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th v-for="state in states">
                {{ state }}
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="color in colors">
            <th>
              {{ color }}
            </th>
            <td v-for="state in states">
              <long-button :color="color" :state="state" :disabled="state === 'disabled'">Btn text</long-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <long-button color="blue" size="super-wide" @click="onClick('blue')">Super wide</long-button>
      </div>
      <div class="col">
        <long-button color="blue" size="super-tall" @click="onClick('blue')">Super tall</long-button>
      </div>
      <div class="col">
        <long-button color="blue" size="large-block" @click="onClick('blue')">Large block</long-button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="animated-button-container">
          <long-button color="blue" size="animated" @click="onClick('blue')">Btn text (animated)</long-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <tiny-button @click="onClick('tinyMinus')">-</tiny-button>
      </div>
      <div class="col">
        <tiny-button @click="onClick('tinyPlus')">+</tiny-button>
      </div>
      <div class="col">
        <tiny-button @click="onClick('tinyMinus')" :disabled="true">-</tiny-button>
      </div>
      <div class="col">
        <tiny-button @click="onClick('tinyPlus')" :disabled="true">+</tiny-button>
      </div>
    </div>
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import LongButton from '@/components/common/LongButton.vue'
import TinyButton from '@/components/common/TinyButton.vue'

export default {
  name: 'Voice-Dev',
  components: {LongButton, EventTicks, ButtonContainer, TinyButton},
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    return {
      events: ['clicked'],
      states: [
        'normal',
        'hover',
        'active',
        'disabled',
      ],
      colors: [
        'blue',
        'light-green',
        'orange',
      ]
    }
  },

  methods: {
    onClick(buttonName) {
      console.log('click', buttonName);

      this.triggerEvent('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.long-button-dev-container {
  width: 1200px;

  > .row {
    padding-top: 30px;
  }
}

.animated-button-container {
  width: 500px;
  height: 500px;
}
</style>
