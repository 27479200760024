<template>
  <div class="row mt-2">
    <debriefing-final-score-card
      :trust="trust"
      :health-security="healthSecurity"
      :collaboration="collaboration"
      :mg-success="mgSuccess"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">{{ $t('debriefing.trust') }}</label>
      <input type="range" min="0" max="100" v-model.number="trust" />
    </div>
    <div class="row">
      <label class="form-label">{{ $t('debriefing.healthSecurity') }}</label>
      <input type="range" min="0" max="100" v-model.number="healthSecurity" />
    </div>
    <div class="row">
      <label class="form-label">{{ $t('debriefing.collaboration') }}</label>
      <input type="range" min="0" max="100" v-model.number="collaboration" />
    </div>
    <div class="row">
      <label class="form-label">{{ $t('debriefing.massGatheringSuccess') }}</label>
      <input type="range" min="0" max="100" v-model.number="mgSuccess" />
    </div>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import DebriefingFinalScoreCard from '@/components/Debriefing/DebriefingFinalScoreCard/index.vue'

export default {
  name: 'DebriefingFinalScoreCardDev',
  components: {
    NoDragImage,
    HazardPopupFlagManagement,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DevSettingsPanel,
    DebriefingFinalScoreCard,
  },
  extends: DevComponentContainer,

  data () {
    return {
      trust: 16,
      healthSecurity: 30,
      collaboration: 80,
      mgSuccess: 90,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  transform: scale(0.5);
  box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
}
</style>
