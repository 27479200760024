<template>
  <div class="version-container" :class="computedClass" :style="computedStyle">
    <div class="session-info" v-if="sessionInfo && displaySessionInfo"><strong>Session info:</strong> {{ sessionInfo }}</div>
    <div class="session-info" v-if="playerInfo && displaySessionInfo"><strong>Player info:</strong> {{ playerInfo }}</div>
    <div class="version" v-if="version">{{ version }}</div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Version',
  props: {
    attachCorner: {
      type: Boolean,
      required: false,
      default: false,
    },
    displaySessionInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    yOffset: {
      type: Number,
      required: false,
      default: 5,
    },
    xOffset: {
      type: Number,
      required: false,
      default: 5,
    },
    attachmentPoint: {
      type: String,
      required: false,
      default: 'topRight',
    },
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf']),
    computedClass() {
      const classes = [];

      if (this.attachCorner) {
        classes.push('version-container-attach-corner');
        classes.push('version-container-attach-corner-' + this.attachmentPoint);
      }

      return classes;
    },
    computedStyle() {
      const style = {};

      if (this.attachCorner) {
        if (this.attachmentPoint === 'topLeft') {
          style.top = this.yOffset + 'px';
          style.left = this.xOffset + 'px';
        } else if (this.attachmentPoint === 'topRight') {
          style.top = this.yOffset + 'px';
          style.right = this.xOffset + 'px';
        } else if (this.attachmentPoint === 'bottomLeft') {
          style.bottom = this.yOffset + 'px';
          style.left = this.xOffset + 'px';
        } else if (this.attachmentPoint === 'bottomRight') {
          style.bottom = this.yOffset + 'px';
          style.right = this.xOffset + 'px';
        }
      }

      return style;
    },
    version() {
      const projectName = process.env.VUE_APP_PROJECT_NAME || process.env.PROJECT_NAME || null;
      const buildEnvironment = process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV || null;
      const buildVersion = process.env.VUE_APP_BUILD_VERSION || process.env.BUILD_VERSION || null;
      const buildTime = process.env.VUE_APP_BUILD_TIME || process.env.BUILD_TIME || null;

      const projectString = [projectName,  buildEnvironment, buildVersion, buildTime];

      return projectString.filter(v => !!v).join(' / ');
    },
    playerInfo() {
      if (!this.playerSelf) {
        return;
      }

      return this.playerSelf.name + ' / ' + this.playerSelf.playerCode;
    },
    sessionInfo() {
      if (!this.room) {
        return;
      }

      return this.room.customId + ' / ' + this.room.roomName;
    },
  }
}
</script>

<style lang="scss" scoped>
.version-container {
  font-size: 10px;
  font-family: Consolas, monaco, monospace;
  opacity: .6;
  color: white;
  z-index: 9999999999999999;
  text-align: right;
  width: fit-content;

  &-attach-corner {
    position: fixed;
  }
}
</style>
