export const SHAPE_SQUARE = 'square';
export const SHAPE_SLIGHTLY_ROUNDED = 'slightly-rounded';
export const SHAPE_ROUNDED = 'rounded';
export const SHAPE_CIRCLE = 'circle';

export const HIGHLIGHT_TYPE_ID = 'id';
export const HIGHLIGHT_TYPE_SELECTOR = 'selector';
export const HIGHLIGHT_TYPE_ELEMENT = 'element';

export const POPUP_ANCHOR_AUTO = 'auto';

export const POPUP_ANCHOR_X_LEFT = 'x.left';
export const POPUP_ANCHOR_X_MIDDLE = 'x.middle';
export const POPUP_ANCHOR_X_RIGHT = 'x.right';

export const POPUP_ANCHOR_Y_TOP = 'y.top';
export const POPUP_ANCHOR_Y_MIDDLE = 'y.middle';
export const POPUP_ANCHOR_Y_BOTTOM = 'y.bottom';

export const POPUP_ANCHOR_TOP =  { x: POPUP_ANCHOR_X_MIDDLE, y: POPUP_ANCHOR_Y_TOP };
export const POPUP_ANCHOR_LEFT = { x: POPUP_ANCHOR_X_RIGHT, y: POPUP_ANCHOR_Y_MIDDLE };
export const POPUP_ANCHOR_RIGHT = { x: POPUP_ANCHOR_X_RIGHT, y: POPUP_ANCHOR_Y_MIDDLE };
export const POPUP_ANCHOR_BOTTOM =  { x: POPUP_ANCHOR_X_MIDDLE, y: POPUP_ANCHOR_Y_BOTTOM };
