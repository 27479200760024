<template>
  <transition name="slide-fade-bottom">
    <div v-if="open" class="lightbox" :class="pushTopUi ? 'lightbox-push-top-ui' : ''">
      <div class="lightbox-background" :style="backgroundStyle" @click="clickBackground" />
      <div class="lightbox-content">
        <div class="lightbox-content-inner">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Lightbox',
  props: {
    background: {
      type: String,
      default: '#ffffff',
      required: false,
    },
    backgroundOpacity: {
      type: Number,
      default: 0.8,
      required: false,
    },
    bodyOpenClass: {
      type: String,
      default: 'lightbox-open',
      required: false,
    },
    open: {
      type: Boolean,
      default: true,
      required: false,
    },
    pushTopUi: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
emits: ['close'],
  computed: {
    backgroundStyle() {
      return {
        background: this.hex2rgba(this.background, this.backgroundOpacity),
      };
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.addBodyOpenClass();
      } else {
        this.removeBodyOpenClass();
      }
    },
  },
  mounted() {
    if (this.open) {
      this.addBodyOpenClass();
    }
  },
  unmounted() {
    this.removeBodyOpenClass();
  },
  methods: {
    hex2rgba(hex, alpha = 1) {
      // https://thewebdev.info/2022/05/07/how-to-convert-hex-to-rgba-with-javascript/
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));

      return `rgba(${r},${g},${b},${alpha})`;
    },
    clickBackground() {
      this.$emit('close');
    },
    withBody(callback) {
      const bodyElements = document.getElementsByTagName('body');

      for (const bodyElement of bodyElements) {
        callback(bodyElement);
      }
    },
    addBodyOpenClass() {
      this.withBody((body) => {
        body.classList.add('lightbox-open');
      });
    },
    removeBodyOpenClass() {
      this.withBody((body) => {
        body.classList.remove('lightbox-open');
      });
    },
  },
};
</script>

<style lang="scss">

.lightbox {
  padding: 10px;
  z-index: 500;
  overflow-y: auto;
}

.lightbox-background {
  z-index: 0;
}

.lightbox-content {
  z-index: 1;
  margin: 0;
  height: 100%;
}

.lightbox,
.lightbox-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lightbox {
  &.lightbox-push-top-ui {
    .lightbox-content-inner {
      top: calc(50% + 60px) !important;
    }
  }

  .lightbox-content-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16pt;
    text-align: center;
    color: white;
    width: auto;
    height: auto;

    h1, h2, h3, h4, h5, h6, p {
      color: white;
      text-align: center;
    }

    input, textarea {
      width: auto;
      display: inline-block;
    }
  }
}

@media (max-width: 575.98px) {
  .lightbox {
    .lightbox-content-inner {
      font-size: 12pt !important;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .lightbox {
    .lightbox-content-inner {
      font-size: 13pt !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .lightbox {
    .lightbox-content-inner {
      font-size: 14pt !important;
    }
  }
}

</style>
