<template>
  <div class="container-fluid">
    <div class="row">
      <top-bar
        :total-action-points-cost="totalActionPointsCost"
        :trust="trust"
        :collaboration="collaboration"
        :health-security="healthSecurity"
        :mg-event-success="mgEventSuccess"
        :game-time="gameTime"
        :action-points="actionPoints"
      />
    </div>
  </div>
  <dev-settings-panel>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Trust ({{ trust }})</label>
        <input class="form-control" type="range" min="0" :max="1000" v-model.number="trust"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Collaboration ({{ collaboration }})</label>
        <input class="form-control" type="range" min="0" :max="1000" v-model.number="collaboration"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Health Security ({{ healthSecurity }})</label>
        <input class="form-control" type="range" min="0" :max="1000" v-model.number="healthSecurity"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">MG Event Success ({{ mgEventSuccess }})</label>
        <input class="form-control" type="range" min="0" :max="1000" v-model.number="mgEventSuccess"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Game Time ({{ gameTime }})</label>
        <input class="form-control" type="range" min="0" :max="15" v-model.number="gameTime"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">AP current</label>
        <input class="form-control" type="range" min="0" :max="100" v-model.number="actionPoints"/>
      </div>
    </div>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">AP total cost</label>
        <input class="form-control" type="range" min="0" :max="100" v-model.number="totalActionPointsCost"/>
      </div>
    </div>
  </dev-settings-panel>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import TopBar from '@/components/player-ui/TopBar';
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue';
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue';

export default {
  name: 'TopBarDev',
  components: {DevSettingsPanel, BootstrapSelect, TopBar },
  extends: DevComponentContainer,

  data () {
    return {
      trust: 10,
      collaboration: 30,
      healthSecurity: 60,
      mgEventSuccess: 90,
      gameTime: 1,
      actionPoints: 10,

      totalActionPointsCost: 50,
    };
  },
}
</script>
