<template>
  <div class="prioritization-hazard-container" :class="computedClass">
    <div class="prioritization-hazard">
      <div class="prioritization-up-next" v-if="isFirst">
        <h1>{{ $t('meetingPopup.hazardPrioritizationUpNext') }}:</h1>
      </div>
      <div class="d-flex gap-4">
        <div class="icon-container">
          <floating-hazard-icon :identifier="hazardData.icon" :show-shadow="false" :do-float="false" :flag-count="hazardData.flagCount" :float="false" :severity="-1" />
        </div>
        <div class="hazard-info-container flex-grow-1">
          <h3>{{ index + 1 }}. {{ hazardData.title }}</h3>
          <p>{{ hazardData.summaryText }}</p>
        </div>
      </div>
    </div>
    <div class="prioritization-hazard-footer">
      <div class="prioritization-players d-flex justify-content-end gap-2">
        <div class="prioritization-player-box" v-for="playerPrioritization in playerPrioritizations">
          <character-thumbnail
            :character-index="playerPrioritization.player.characterIndex"
            size="tiny"
          />

          <flag-icon :value="playerPrioritization.flagCount" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import FlagIcon from '@/components/player-ui/FlagIcon.vue'

export default {
  name: 'HazardPrioritizationItem',
  props: {
    hazardData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false,
    },
    playerPrioritizations: {
      type: Array,
      required: false,
      default: [],
    },
  },
  components: {
    FlagIcon,
    CharacterThumbnail,
    FloatingHazardIcon,
  },
  computed: {
    computedClass() {
       return [
         this.isFirst ? `prioritization-hazard-container-first` : '',
         this.isLast ? `prioritization-hazard-container-last` : '',
       ];
    },
    isFirst() {
      return this.index === 0 || this.index === '0';
    },
  },
}
</script>

<style lang="scss" scoped>

.prioritization-hazard-container {
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;
  position: relative;

  &.prioritization-hazard-container-first {
    border: 10px solid #ffffff;

    .prioritization-hazard {
      min-height: 290px !important;
    }
  }

  &.prioritization-hazard-container-last {
    margin-bottom: 0;
  }
}

.prioritization-hazard {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000E21, .6);
  mix-blend-mode: normal;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
}

.hazard-info-container {
  color: white;
  padding: 20px;

  h3 {
    font-weight: 700;
    font-size: 20pt;
    text-align: left;
  }

  p {
    text-align: left;
    font-size: 14pt;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
}

.icon-container {
  position: relative;
  top: 25px;
  left: 30px;
}

.prioritization-hazard-footer {
  background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), rgba(#000E21, .6);
  border-top: 3px solid white;
  height: 70px;
}

.prioritization-hazard {
  min-height: 230px;
}

.prioritization-players {
  padding: 0 10px;

  .prioritization-player-box {
    width: 70px;
    padding: 0;
    position: relative;

    .flag-icon {
      position: absolute;
      top: -50px;
      left: 3px;
      transform: scale(50%);
    }
  }
}

.prioritization-up-next {
  h1 {
    font-size: 35pt;
    font-weight: bold;
  }
}
</style>
