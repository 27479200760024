import TutorialHighlightOverlay from './TutorialHighlightOverlay.vue'
import { TutorialHighlightOverlayController } from '@/plugins/TutorialHighlightOverlay/TutorialHighlightOverlayController'

export function getTutorialHighlightOverlay() {
  return {
    install(app) {
      console.log('INSTALLED');

      app.component('tutorial-highlight-overlay', TutorialHighlightOverlay);

      app.config.globalProperties.$tutorialHighlightOverlay = new TutorialHighlightOverlayController();
    }
  }
}
