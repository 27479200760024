<template>
  <div class="intro-full">
    <version-container :attach-corner="true" :display-session-info="false"/>
    <div class="lobby-popup-template-container"
         :class="'lobby-popup-template-container-' + (showLobbyPopupTemplate ? 'visible' : 'hidden')">

      <div v-if="introPageNumber === 0">
        <h1 class="title">
          {{ $t('intro.welcome') }}
        </h1>

        <h2 class="subtitle">
          {{ $t('intro.introSubtitle') }}
        </h2>

        <div style="display:flex; flex-direction: column; gap: 15px; width: 68%; margin: auto">
          <div class="intro-screen-header">
            {{ $t('intro.header1') }}
          </div>

          <div style="display:flex; gap: 10px">

            <div class="intro-screen-block">
              <span>{{ $t('intro.text1') }}</span>
              <img src="@/assets/teaser/handball.png" style="top: 50px; right: 50px">
            </div>
            <div class="intro-screen-block">
              {{ $t('intro.text2') }}
              <ul>
                <li>Golantic</li>
                <li>Nextdooria</li>
                <li>Vuslando</li>
                <li>Treministan</li>
                <li>Baru</li>
                <li>Burland</li>
              </ul>
              <img src="@/assets/teaser/participants.png" style="top: 100px; right: 80px">

            </div>
          </div>

          <div class="intro-screen-footer">

            <img src="@/assets/teaser/time.png">
            <div>
              <div class="intro-visitors">
                {{ $t('intro.visitors') }}
              </div>
              <div class="intro-happens">
                {{ $t('intro.happens') }}
              </div>
            </div>

          </div>
        </div>

      </div>

      <div v-if="introPageNumber === 1">

        <div
          style="display:flex; flex-direction: column; gap: 15px; width: 68%; margin-left: auto; margin-right: auto; margin-top: 170px">

          <div style="display:flex; gap: 10px">
            <div class="intro-screen-block-title">
              {{ $t('intro.header2') }}
            </div>
            <div class="intro-screen-block-title">
              {{ $t('intro.header3') }}
            </div>
          </div>

          <div style="display:flex; gap: 10px">

            <div class="intro-screen-block-2">
              {{ $t('intro.text3') }}

              <div class="image-row">
                <img src="@/assets/teaser/mission-1.png">
                <img src="@/assets/teaser/mission-2.png">
              </div>

            </div>
            <div class="intro-screen-block-2">
              {{ $t('intro.text4') }}

              <div class="image-row">
                <img src="@/assets/debriefing/icon-lg-trust.png">
                <img src="@/assets/debriefing/icon-lg-health-security.png">
                <img src="@/assets/debriefing/icon-lg-collaboration.png">
                <img src="@/assets/debriefing/icon-lg-mg-success.png">
              </div>

            </div>
          </div>

        </div>

        <h2 class="subtitle-2" style="margin-top: 40px; margin-bottom: 40px">
          {{ $t('intro.text5') }}
        </h2>

      </div>

      <div>
        <back-button @click="onClickPreviousPage" v-if="introPageNumber > 0"/>

        <app-button class="ms-4" @click="onClickNextPage" :color="nextPageButtonColor" width="lg" height="lg">
          {{ $t('intro.buttonGotIt') }}
        </app-button>
      </div>

    </div>
  </div>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import AppButton from '@/components/common/buttons/AppButton.vue';
import NoDragImage from '@/components/common/NoDragImage.vue';
import LongButton from '@/components/common/LongButton.vue';

import IntroPageData from '@/lib/intro-pages';
import MarkdownText from '@/components/common/MarkdownText/index.vue';
import MediaContainer from '@/components/common/media/MediaContainer.vue';
import ImageLibrary from '@/lib/image-library';
import { mapState } from 'vuex';
import VersionContainer from '@/components/common/VersionContainer.vue';
import BackButton from '@/components/common/buttons/BackButton.vue';

export default {
  name: 'IntroView',
  components: {
    BackButton,
    MarkdownText,
    LongButton,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    MediaContainer,
    VersionContainer,
  },
  data () {
    return {
      introPageNumber: 0,
      introPages: IntroPageData,

      introImages: ImageLibrary,

      showLobbyPopupTemplate: false,
    };
  },
  mounted () {
    if (!this.seenTeaser) {
      console.log('no Seen teaser going back');

      this.$router.push('/teaser');

      return;
    }

    this.$store.commit('background', 'game');
    this.$store.commit('backgroundBlingTop', true);
    this.$store.commit('backgroundBlingBottom', true);

    if (parseInt(process.env.VUE_APP_SKIP_INTRO, 10) > 0) {
      console.log('Skipping intro due to env SKIP_INTRO');

      this.jumpNext();

      return;
    }

    if (this.seenIntro) {
      // We've already seen it before - we probably clicked back in our browser or back on the menu
      this.introPageNumber = this.introPageNumberLast; // Last intro page
      console.log('GOING TO LAST PAGE BY DEFAULT');
    }

    setTimeout(() => {
      requestAnimationFrame(() => {
        this.showLobbyPopupTemplate = true;
      });
    }, 10);
  },
  computed: {
    ...mapState(['seenTeaser', 'seenIntro']),
    introPageNumberLast () {
      //return this.introPages.length
      return 1;
    },
    nextPageButtonColor () {
      if (this.introPageNumber === this.introPageNumberLast) {
        return 'green';
      } else {
        return 'green';
      }
    },
    nextPageButtonText () {
      if (this.introPageNumber === this.introPageNumberLast) {
        return this.$t('intro.buttonLogIn');
      } else {
        return this.$t('intro.buttonNextPage');
      }
    },
    currentIntroPage () {
      if (this.introPageNumber >= 1) {
        return this.introPages[this.introPageNumber - 1];
      } else {
        return null;
      }
    },
    header () {
      if (this.introPageNumber === 0) {
        return this.$t('intro.disclaimerHeader');
      }

      return this.introPages[this.introPageNumber - 1].header;
    },
  },
  methods: {
    onClickHeader () {
      this.jumpNext();
    },
    getMediaSource (source) {
      if (this.introImages.hasOwnProperty(source)) {
        return this.introImages[source];
      }

      return source;
    },
    onClickAcceptTerms () {
      this.onClickNextPage();
    },
    onClickPreviousPage () {
      if (this.introPageNumber <= 0) {
        return;
      }

      this.introPageNumber -= 1;
    },
    onClickNextPage () {
      if (this.introPageNumber === this.introPageNumberLast) {
        this.jumpNext();

        return;
      }

      if (this.introPageNumber >= this.introPageNumberLast) {
        return;
      }

      this.introPageNumber += 1;
    },
    jumpNext () {
      this.$store.commit('seenIntro', true);
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
.intro-full {
  width: 100%;
  height: 100%;
  background: rgba(#000000, .8);
  padding: 50px 290px 50px 290px;
  color: white;
  font-size: 16pt;
  text-align: left;
  position: relative;
}

.lobby-popup-template-container {
  opacity: 0;
  transition: 200ms ease-in-out;
  text-align: center;

  &.lobby-popup-template-container-visible {
    opacity: 1;
  }
}

.browser-images {
  a {
    transition: 200ms cubic-bezier(0.175, 0.985, 0.32, 2.175);

    &:hover {
      transform: scale(150%);
    }
  }
}

.title {
  text-align: center;
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 5px 10px 15px rgba(0, 0, 0, 1);
}

.subtitle {
  text-align: center;
  font-size: 1.55em;
  text-shadow: 5px 10px 15px rgba(0, 0, 0, 1);
}

.subtitle-2 {
  text-align: center;
  font-size: 2em;
  text-shadow: 5px 10px 15px rgba(0, 0, 0, 1);
}

.intro-screen-header {
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8em;
  white-space: nowrap;
  text-shadow: 5px 10px 15px rgba(0, 0, 0, 1);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 15px 40px;
}

.intro-screen-block-title {
  width: 50%;
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 5px 10px 15px rgba(0, 0, 0, 1);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 20px 20px;
  text-align: center;
  position: relative;
}

.intro-screen-block {
  white-space: pre-wrap;
  width: 50%;
  font-size: 1.1em;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 25px 20px;
  height: 350px;
  position: relative;
  text-align: left;

  & img {
    position: absolute;
  }
}

.intro-screen-block-2 {
  width: 50%;
  font-size: 1.2em;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 25px 20px;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  & .image-row {
    display: flex;
    justify-content: center;
    gap: 20px;

    & img {
      height: 60px;
    }
  }
}

.intro-screen-footer {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #134960;
  box-shadow: inset -5.00894px -5.00894px 4.00715px rgba(0, 0, 0, 0.4), inset 4.00715px 4.00715px 3.00537px rgba(255, 255, 255, 0.4);
  padding: 15px 40px;
  display: flex;
  text-align: left;
  margin-bottom: 10px;

  & .intro-visitors {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 1.2em;
  }

  & .intro-happens {
    font-weight: bolder;
    margin-left: 20px;
    font-size: 1.8em;
  }
}

</style>
