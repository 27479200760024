export const LOCAL_STREAM_CHANGED = 'localStreamChanged';

export class LocalStreamChangedEvent extends Event {
  stream;

  deviceId;

  constructor (stream, deviceId) {
    super(LOCAL_STREAM_CHANGED);

    this.stream = stream;
    this.deviceId = deviceId;
  }
}
