export function randomBetween(min, max) {
  const diff = max - min;

  const random = Math.random() * diff;

  return min + random;
}

export function pickRandom(array) {
  const randomIndex = Math.floor(Math.random() * array.length);

  return array[randomIndex];
}
