<template>
  <div class="state-viewer-popup" v-if="isOpen">
    <div class="header">
      <h1>StateViewer</h1>
      <button class="btn btn-danger" @click="onClickClose">&times;</button>
    </div>
    <div class="body">
      <state-viewer />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StateViewer from '@/plugins/GameClient/components/StateViewer.vue'

export default {
  name: 'StateViewerPopup',
  components: { StateViewer },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  methods: {
    onClickClose() {
      this.$emit('close');
    }
  },
}
</script>

<style lang="scss" scoped>
.state-viewer-popup {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 800px;
  height: 500px;
  background: #ffffff;
  opacity: 1;
  transform: translateX(-50%);

  .body {
    max-height: 440px;
    overflow-y: scroll;
    padding: 15px;
  }

  .btn {
    font-size: 24pt;
    padding: 0 10px;

    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
