<template>
  <div class="ui-timer">
    <header>
      <div class="timer">
        {{ formattedTimeLeft }}
      </div>
    </header>
    <footer>
      <slot />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'UiTimer',
  props: {
    secondsLeft: {
      type: Number,
      required: true,
    }
  },
  computed: {
    formattedTimeLeft() {
      const totalSecondsLeft = Math.round(Math.max(0, this.secondsLeft));

      let minutesLeft = Math.floor(totalSecondsLeft / 60);
      let secondsLeft = totalSecondsLeft - (60 * minutesLeft);

      if (minutesLeft < 10) {
        minutesLeft = '0' + minutesLeft;
      }

      if (secondsLeft < 10) {
        secondsLeft = '0' + secondsLeft;
      }

      return `${minutesLeft}:${secondsLeft}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-timer {
  background: url("@/assets/ui/timer/timer-background.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 241px;
  height: 193px;

  header {
    width: 100%;
    height: 60px;
    /*background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
    box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);*/
    padding: 0;

    .timer {
      padding-left: 70px;
      background: url("@/assets/icons/clock-icon.png") no-repeat center left;
      //background-size: contain;
      background-size: 50px 50px;
      width: 180px;
      margin: 0 auto;

      line-height: 70px;
      font-weight: 700;
      font-size: 30pt;
      color: #ffffff;
      text-shadow: 0px 3.84724px 3.84724px rgba(0, 0, 0, 0.25);
    }
  }

  footer {
    width: 100%;
    height: 193px  - 60px;
    //background: linear-gradient(90deg, rgba(#0F3D3D, 80%) -31.57%, rgba(#112B53, 80%) 101.27%);
    //box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.8);

    color: white;
    font-size: 15pt;

    p {
      margin-bottom: 0;
      display: none;
    }
  }

}
</style>
