<template>
  <div class="tutorial-box-content d-flex gap-2">
    <div class="tutorial-box-info flex-fill bg-darkish p-3">
      <p>{{ $t('starMinigameTutorialVulnerability.paragraph1') }}</p>

      <ul>
        <li>{{ $t('starMinigameTutorialVulnerability.list1point1') }}</li>
        <li>{{ $t('starMinigameTutorialVulnerability.list1point2') }}</li>
        <li>{{ $t('starMinigameTutorialVulnerability.list1point3') }}</li>
        <li>{{ $t('starMinigameTutorialVulnerability.list1point4') }}</li>
      </ul>

      <p>{{ $t('starMinigameTutorialVulnerability.paragraph2') }}</p>
    </div>
    <div class="tutorial-box-vulnerabilities bg-darkish p-3">
      <strong>{{ $t('starMinigameTutorialVulnerability.tableHeader') }}</strong>
      <div class="tutorial-box-vulnerability bg-level bg-level-5"><span>{{ $t('starMinigameTutorialVulnerability.tableVeryHigh') }}</span></div>
      <div class="tutorial-box-vulnerability bg-level bg-level-4"><span>{{ $t('starMinigameTutorialVulnerability.tableHigh') }}</span></div>
      <div class="tutorial-box-vulnerability bg-level bg-level-3"><span>{{ $t('starMinigameTutorialVulnerability.tableModerate') }}</span></div>
      <div class="tutorial-box-vulnerability bg-level bg-level-2"><span>{{ $t('starMinigameTutorialVulnerability.tableLow') }}</span></div>
      <div class="tutorial-box-vulnerability bg-level bg-level-1"><span>{{ $t('starMinigameTutorialVulnerability.tableVeryLow') }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialVulnerability',
}
</script>

<style lang="scss" scoped>
.tutorial-box-vulnerabilities {
  width: 800px;
  flex-basis: 800px;
  padding: 10px;
  height: fit-content;

  .tutorial-box-vulnerability {
    height: 75px;
    position: relative;
    margin-top: 10px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  strong {
    font-size: 25pt;
    text-align: center;
    display: block;
  }
}
</style>
