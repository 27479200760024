<template>
  <lobby-popup-template content-class="equal-padding">
    <template v-slot:header>
      <div>
        {{ $t('lobby.headerChooseRole') }}
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex align-items-stretch content-container-inner">
        <div class="role-selection-container-outer flex-grow-1">
          <div class="role-selection-container-inner d-flex justify-content-center flex-wrap gap-3">
            <role-selection-icon-button
              v-for="roleData in roles"
              :role-data="roleData"
              :local-selected="playerSelectedRole && (playerSelectedRole.identifier === roleData.identifier)"
              :selected="!!playerSelectedRoles[roleData.identifier] || localSelectedRoleIdentifier === roleData.identifier"
              :player-chosen-data="playerSelectedRoles[roleData.identifier]"
              @click="selectRole(roleData.identifier)"
            />
          </div>
        </div>
        <div class="role-info-container-outer" v-if="localSelectedRole">
          <div class="role-info-container-inner d-flex flex-column">
            <div class="role-info-container-inner-header d-flex justify-content-between gap-3">
              <div class="role-info-container-inner-header-icon">
                <role-icon :role="localSelectedRole.icon" />
              </div>
              <label>{{ localSelectedRole.name }}</label>
            </div>
            <div class="role-info-container-inner-details flex-fill epic-scrollbar">
              <h3>{{ $t('lobby.roleRepresents') }}</h3>
              <p>{{ localSelectedRole.represents }}</p>

              <h3>{{ $t('lobby.roleBranch') }}</h3>
              <p>{{ localSelectedRole.branch }}</p>

              <h3>{{ $t('lobby.roleAreasOfExpertise') }}</h3>
              <p>{{ formatAreasOfExpertise(localSelectedRole) }}</p>
            </div>
            <div class="role-info-container-actions">
              <app-button v-if="playerSelectedRole && playerSelectedRole.identifier === localSelectedRole.identifier" width="lg" height="md" color="green" state="active">{{ $t('lobby.buttonRoleChosen') }}</app-button>
              <app-button v-else  width="lg" height="md" color="red" @click="selectPlayerRole(localSelectedRole.identifier)">{{ $t('lobby.buttonChooseRole') }}</app-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          <div class="footer-hint align-self-start"><strong>{{ $t('intro.roleSelectionFooterLeft') }}:</strong></div>
          <div class="footer-hint-text"><p>{{ $t('intro.roleSelectionFooterRight') }}</p></div>
        </div>
        <div>
          <app-button
            :class="['show-team-balance-button', showTeamBalance ? 'show-team-balance-button-active' : 'show-team-balance-button-inactive']"
            color="green"
            height="md"
            width="full"
            @click="toggleShowTeamBalance"
          >
            {{ $t('lobby.buttonShowTeamBalance') }} <no-drag-image src="@/assets/icons/triangle-up-hollow.png" />
          </app-button>
        </div>
      </div>
    </template>
    <template v-slot:root>
      <div class="team-balance-container" v-if="showTeamBalance">
        <team-balance :aoe-values="aoeValues" />
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LongButton from '@/components/common/LongButton'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate'
import NoDragImage from '@/components/common/NoDragImage'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import VoiceControllerDeviceSelector from '@/plugins/VoiceController/components/VoiceControllerDeviceSelector.vue'
import { mapGetters, mapState } from 'vuex'
import UiSlider from '@/components/player-ui/UiSlider.vue'
import VolumeSlider from '@/components/player-ui/VolumeSlider.vue'
import VolumeSliderInput from '@/components/player-ui/VolumeSliderInput.vue'
import VolumeSliderOutput from '@/components/player-ui/VolumeSliderOutput.vue'
import TeamBalance from '@/components/Lobby/TeamBalance.vue'
import RoleIcon from '@/components/Icon/RoleIcon.vue'
import RoleSelectionIconButton from '@/components/Lobby/RoleSelectionIconButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'LobbyPopupRoleSelection',
  components: {
    AppButton,
    RoleSelectionIconButton,
    RoleIcon,
    TeamBalance,
    VolumeSliderOutput,
    VolumeSliderInput,
    VolumeSlider,
    UiSlider,
    VoiceControllerDeviceSelector,
    CharacterPlate,
    NoDragImage,
    LongButton,
    LobbyPopupTemplate,
    PlayerCharacterPlate
  },
  emits: ['joined'],
  data () {
    return {
      localSelectedRoleIndex: null,
      playerSelectedRoles: [],

      showTeamBalance: false,

      aoeValues: {},
    }
  },
  mounted() {
    this.onRoles();

    TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.RoleSelection);
  },
  watch: {
    playerRoles: {
      handler () {
        this.onRoles();
      },
    },
  },
  computed: {
    ...mapState(['roles']),
    ...mapGetters(['playerSelf', 'room', 'players', 'playerCodeOthers', 'playerCodesAll']),

    playerRoles() {
      const playerRoles = {};

      this.playerCodesAll.forEach((playerCode) => {
        const player = this.players[playerCode];

        if (player.roleIndex >= 0) {
          playerRoles[playerCode] = player.roleIndex;
        }
      });

      return playerRoles;
    },
    playerSelectedRole() {
      const playerSelf = this.playerSelf;

      if (!playerSelf) {
        return null;
      }

      if (playerSelf.roleIndex <= -1) {
        return null;
      }

      return this.roles[playerSelf.roleIndex];
    },

    localSelectedRole() {
      if (this.localSelectedRoleIndex < 0) {
        return null;
      }

      return this.roles[this.localSelectedRoleIndex];
    },

    localSelectedRoleIdentifier() {
      return this.localSelectedRole ? this.localSelectedRole.identifier : null;
    }
  },
  methods: {
    selectPlayerRole(roleIdentifier) {
      const roleIndex = this.getRoleIndexFromIdentifier(roleIdentifier);

      if (roleIndex <= -1) {
        return;
      }

      console.log('roleIndex', roleIndex);

      this.$gameClient.playerSet({
        roleIndex: roleIndex,
        roleIdentifier: roleIdentifier
      });
    },
    getRoleIndexFromIdentifier(roleIdentifier) {
      for (let i = 0, len = this.roles.length; i < len; i++) {
        const role = this.roles[i];

        if (role.identifier === roleIdentifier) {
          return i;
        }
      }

      console.error(`Could not find role by identifier ${roleIdentifier}`);

      return -1;
    },
    formatAreasOfExpertise(role) {
      return role.areasOfExpertise.map(v => v.name).join('\n');
    },
    selectRole(roleIdentifier) {
      let roleIndex = this.getRoleIndexFromIdentifier(roleIdentifier);

      if (roleIndex === -1) {
        return;
      }

      this.localSelectedRoleIndex = roleIndex;
    },
    onRoles() {
      for (const roleData of this.roles) {
        this.playerSelectedRoles[roleData.identifier] = null;
      }

      const aoeValues = {};

      this.playerCodesAll.forEach((playerCode) => {
        const player = this.players[playerCode];

        const role = player.roleIndex >= 0 ? this.roles[player.roleIndex] : null;

        if (!role) {
          return;
        }

        const roleIdentifier = role ? role.identifier : null;

        if (!roleIdentifier) {
          return;
        }

        const aoeValueKeys = Object.keys(aoeValues);

        for (const aoe of role.areasOfExpertise) {
          if (aoeValueKeys.includes(aoe.identifier)) {
            aoeValues[aoe.identifier] = aoeValues[aoe.identifier] + 1;
          } else {
            aoeValues[aoe.identifier] = 1;
          }
        }

        this.playerSelectedRoles[roleIdentifier] = player;

        if (player.isSelf) {
          this.localSelectedRoleIndex = this.getRoleIndexFromIdentifier(roleIdentifier);
        }
      });

      this.aoeValues = aoeValues;
    },
    toggleShowTeamBalance() {
      this.showTeamBalance = !this.showTeamBalance;
    },
  },
}
</script>

<style lang="scss" scoped>
.mic-container {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 13px;
  width: fit-content;
  margin: 0 auto;

  > div {
    height: 100px;
    padding: 5px;
  }
}

.dark-bg {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000000, .2);
  padding: 5px;

  img {
  }
}

.settings-panel {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;

  .settings-panel-single {
    height: 50%;
    margin: 0;
    width: 200px;

    .dark-bg {
      height: 100%;
    }

    &:first-child {
      padding-bottom: 5px;
    }

    &:last-child {
      padding-top: 5px;
    }
  }
  img {
    max-width: 30px;
  }
}

select {
  text-align: center;

  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: inset 0px 2px 2px 1px rgba(7, 28, 39, 0.56), inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  border-radius: 5px;
}

.settings-panel-output-volume {
  img {
    max-height: 25px;
    margin-top: 4px;
  }
}

.large-mic-icon {
  max-width: 70px;
}

.voice-device-selector {
  margin-top: 27px;
}

.players-ready-label {
  font-size: 30pt;
  color: white;
  margin-right: 35px;
  font-weight: 700;
}

.character-container {
  padding-top: 50px;
}

.single-character-container-self {
  .player-card {
    transform: scale(120%);
    transform-origin: bottom center;
  }
}

.all-letters-activated {
  font-weight: bold;
  color: #AA0000;
}

.content-inner,
.footer-hint,
.footer-hint-text {
  color: #ffffff;
}

.footer-hint {
  font-size: 120%;
  font-weight: 700;
}

.footer-hint-text {
  max-width: 70%;
  font-weight: 0;
  text-align: justify;
  padding: 0 50px 0 20px;
}

.long-button {
  img {
    width: 35px;
    height: 25px;
  }
}

.team-balance-container {
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 100%;
  height: calc(100% - 150px);
  z-index: 1000;

  .team-balance {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-width: 1200px;
    transform: translateX(-50%);
  }
}

.show-team-balance-button {
  img {
    transition: 200ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transform: rotate(0deg);
  }

  &.show-team-balance-button-active {
    img {
      transform: rotate(180deg);
    }
  }
}

.role-selection-container-inner,
.role-info-container-inner {
  padding: 20px;
}

.role-selection-container-outer {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
}

.role-selection-container-inner,
.role-info-container-inner {
  overflow-y: hidden;
  height: 100%;
}

.role-info-container-outer {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
}

.role-selection-container-inner {
  //padding-right: 20px;
}
.role-info-container-inner {
  position: relative;
  width: 300px;
  padding-bottom: 0;

  color: white;
  text-align: left;
  overflow: visible;

  h2 {
    font-size: 25pt;
    margin-top: 20px;
  }

  .role-info-container-inner-header {
    label {
      font-size: 18pt;
    }

    .role-info-container-inner-header-icon {
      width: 70px;
      height: 70px;
      min-width: 70px;
      min-height: 70px;

      .role-icon {
        width: 100%;
        height: 100%;
      }
    }
  }

  .role-info-container-inner-details {
    overflow-y: auto;

    h3 {
      background: #194040;
      padding: 5px 10px;
      font-size: 16pt;
      margin-bottom: 0;
      font-weight: bold;
    }

    p {
      padding: 5px 10px;
      text-transform: uppercase;
      white-space: pre-wrap;
      font-size: 14pt;
      margin-bottom: 0;
    }
  }

  .role-info-container-actions {
    flex-basis: 60px;
    text-align: center;
    //padding: 15px 50px;
  }
}

.content-container-inner {
  gap: 60px;
  height: 100%;
}
</style>
