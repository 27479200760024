<template>
  <div class="row mt-2">
    <end-game-popup-feedback
      :player-votes="playerVotes"
      :player-decision="playerDecision"
      :game-decision="gameDecision"
      @click:continue="onContinue"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Player decision</label>
      <select v-model="playerDecision" class="form-select">
        <option value="restrict">
          Restrict
        </option>
        <option value="hold">
          Hold
        </option>
        <option value="delay">
          Delay
        </option>
        <option value="cancel">
          Cancel
        </option>
      </select>
    </div>
    <div class="row">
      <label class="form-label">Game decision</label>
      <select v-model="gameDecision" class="form-select">
        <option value="restrict">
          Restrict
        </option>
        <option value="hold">
          Hold
        </option>
        <option value="delay">
          Delay
        </option>
        <option value="cancel">
          Cancel
        </option>
      </select>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import EndGamePopupFeedback from '@/components/EndGamePopup/EndGamePopupFeedback/index.vue'
import { DECISION_CANCEL } from '@/data/decision-data'

export default {
  name: 'EndGamePopupFeedbackDev',
  components: {
    EndGamePopupFeedback,
    HazardPopupFlagManagement,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DevSettingsPanel,
    BootstrapSelect,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const players = generatePlayersData();

    return {
      events: ['click:continue'],

      playerDecision: DECISION_CANCEL,
      gameDecision: DECISION_CANCEL,

      playerVotes: [players['111111'], players['555555']],
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('click:continue');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
