<template>
  <div class="role-icon" v-if="roleIconSource">
    <no-drag-image :src="roleIconSource" />
  </div>
</template>
<script>
import NoDragImage from '@/components/common/NoDragImage.vue'

import RoleIconAgreement from '@/assets/icons/roles/agreement.png';
import RoleIconFinancial from '@/assets/icons/roles/financial.png';
import RoleIconHealthcare from '@/assets/icons/roles/healthcare.png';
import RoleIconJournal from '@/assets/icons/roles/journal.png';
import RoleIconMedical from '@/assets/icons/roles/medical.png';
import RoleIconMilitary from '@/assets/icons/roles/military.png';
import RoleIconPinpoint from '@/assets/icons/roles/pinpoint.png';
import RoleIconResearch from '@/assets/icons/roles/research.png';
import RoleIconSports from '@/assets/icons/roles/sports.png';
import RoleIconSurveillance from '@/assets/icons/roles/surveillance.png';
import RoleIconTransit from '@/assets/icons/roles/transit.png';
import RoleIconWildlife from '@/assets/icons/roles/wildlife.png';

const roleIconMapper = {
  agreement: RoleIconAgreement,
  financial: RoleIconFinancial,
  healthcare: RoleIconHealthcare,
  journal: RoleIconJournal,
  medical: RoleIconMedical,
  military: RoleIconMilitary,
  pinpoint: RoleIconPinpoint,
  research: RoleIconResearch,
  sports: RoleIconSports,
  surveillance: RoleIconSurveillance,
  transit: RoleIconTransit,
  wildlife: RoleIconWildlife,
};

const roleIcons = Object.keys(roleIconMapper);

export default {
  name: 'RoleIcon',
  components: {
    NoDragImage,
  },
  props: {
    role: {
      type: String,
      required: true,
    },
  },
  computed: {
    roleIconSource() {
      if (!roleIcons.includes(this.role)) {
        console.log(`Role icon ${this.role} not found`);

        return null;
      }

      return roleIconMapper[this.role];
    }
  }
}
</script>

<style lang="scss" scoped>
  .role-icon {
    display: inline-block;
    width: 36px;
    height: 35px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
