export const EVENT_PARTICIPANT_REMOVED = 'participantRemoved';

export class ParticipantRemovedEvent extends Event {
  participant;

  constructor(participant) {
    super(EVENT_PARTICIPANT_REMOVED);

    this.participant = participant;
  }
}
