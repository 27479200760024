<template>
  <div class="card-root">
    <div class="content-fitter">
      <div class="name-label-root">
        <p>{{ playerName }}</p>
      </div>
      <div class="avatar-circle-root">
        <img class="avatar-image" :src="avatarImage"/>
        <div v-if="muted" class="muted-overlay"></div>
      </div>
      <div class="power-meter-root">
        <div class="power-meter-outer">
          <div class="power-meter-inner" :style="{height: calculatedWidthPercentage + '%'}">
          </div>
          <img class="icon" :src="powerMeterIconSrc"/>
        </div>
      </div>

      <div v-if="!muted" class="microphone-widget-root">
        <img class="microphone-widget-icon" src="~@/assets/icons/microphone-icon.png"/>
      </div>


    </div>
  </div>
</template>

<script>
import * as Icons from '@/utils/icons';

const ultimateMeterIconMapper = {
  healthCare: Icons.HealthIcon,
  economics: Icons.CommerceIcon,
  sports: Icons.SportsIcon,
  borderSecurity: Icons.ShieldEyeIcon,
};

export default {
  name: 'TeamMemberCard',
  props: {
    minPowerLevel: Number,
    maxPowerLevel: Number,
    currentPowerLevel: Number,
    avatarImage: String,
    playerName: String,
    powerMeterIdentifier: String,
    muted: Boolean,
  },
  computed: {
    calculatedWidthPercentage () {
      return ((this.currentPowerLevel - this.minPowerLevel) / (this.maxPowerLevel - this.minPowerLevel)) * 100
    },
    powerMeterIconSrc() {
      return ultimateMeterIconMapper[this.powerMeterIdentifier]
    }
  },
  data () {
    return {
      focusPoint: Icons.FocusPointIcon,
    }
  },
}
</script>

<style scoped>
.card-root {
  width: 202px;
  height: 116px;
}

.content-fitter {
  width: 100%;
  height: 100%;
  position: relative;
}

.name-label-root {
  background-image: url('~@/assets/ui/team-member-card-name-label-background.png');
  width: 92px;
  height: 23px;
  position: absolute;
  bottom: 50px;
  align-items: center;
  justify-content: center;
}

.muted-overlay{
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url('~@/assets/ui/muted-microphone-overlay.png');
}

p {
  margin-top: 2px;
  height: 100%;
  width: 100%;
  font-weight: bold;
  color: white;
  font-size: 15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.avatar-circle-root {
  width: 84px;
  height: 84px;
  background: linear-gradient(180deg, rgba(50, 202, 202, 0.6) 0%, rgba(37, 61, 98, 0.6) 100%);
  position: absolute;
  top: 15px;
  border-radius: 50%;
  left: 89px;
  border: solid 0.2em rgba(50, 202, 202, 1);

}

.avatar-image {
  height: 95px;
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.power-meter-root {
  height: 42px;
  width: 42px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.power-meter-outer {
  height: 42px;
  width: 42px;
  background: linear-gradient(180deg, #32CACA 0%, #253D62 100%);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: solid 0.1em #F09000;
  z-index: 2;

}

.power-meter-inner {
  height: 100%;
  width: 100%;
  background: #F09000;
  transition-duration: 0.5s;
  bottom: 0;
  position: absolute;
  border-radius: 0 0 50% 50%
}

.icon {
  left: 5px;
  top: 5px;
  position: absolute;
  height: auto;
  width: auto;
  display: block;
  max-width: 30px;
}

.microphone-widget-root {
  width: 23px;
  height: 23px;
  background: linear-gradient(180deg, #A0D530 0%, #0A443A 100%);
  border-radius: 50%;
  position: absolute;
  top:70px;
  right: 90px;
}

.microphone-widget-icon {
  width: 15px;
  height: 22px;
  position: absolute;
  top: 3px;
  right: 4px;
}
</style>
