<template>
  <right-player-list-bar class="right-player-list-bar-live" v-if="otherPlayers && otherPlayers.length" :players="otherPlayers" :waiting-for-player-code="waitingForPlayerCode" />
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import RightPlayerListBar from '@/components/player-ui/RightPlayerListBar/RightPlayerListBar.vue'
import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  name: 'RightPlayerListBarLive',
  components: {
    RightPlayerListBar,
  },
  watch: {
    playerVoteSkips() {
      this.recomputeWaitingForPlayerIndex();
    },
    playerJoinedCount() {
      this.recomputeWaitingForPlayerIndex();
    },
  },
  data() {
    return {
      waitingForPlayerCode: null,
    };
  },
  mounted() {
    this.recomputeWaitingForPlayerIndex();
  },
  methods: {
    recomputeWaitingForPlayerIndex() {
      let notSkipCount = 0;
      let playerCount = 0;

      let notSkipOtherPlayerCode;

      if (this.playerSelf) {
        if (this.playerSelf.sessionId) {
          playerCount++; // Surely?!

          if (!this.playerSelf.voteSkipTimer && !this.playerSelf.isReady && !this.playerSelf.voteSkipMeeting) {
            notSkipCount++;
            notSkipOtherPlayerCode = this.playerSelf.playerCode;
          }
        }
      }

      for (const otherPlayer of this.otherPlayers) {
        if (!otherPlayer.sessionId) {
          continue;
        }

        playerCount++;

        if (!otherPlayer.voteSkipTimer && !otherPlayer.isReady && !otherPlayer.voteSkipMeeting) {
          notSkipCount++;
          notSkipOtherPlayerCode = otherPlayer.playerCode;
        }
      }

      // One player needs to answer
      if (notSkipCount === 1 && notSkipOtherPlayerCode) {
        this.waitingForPlayerCode = notSkipOtherPlayerCode;
      } else {
        this.waitingForPlayerCode = null;
      }
    },
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf']),

    playerJoinedCount() {
      let playerJoinedCount = 0;

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (player.sessionId && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          playerJoinedCount++;
        }
      }

      return playerJoinedCount;
    },

    playerVoteSkips() {
      const playerVoteSkips = [];

      if (this.playerSelf && this.playerSelf.sessionId) {
        playerVoteSkips.push(this.playerSelf.voteSkipTimer || this.playerSelf.isReady || this.playerSelf.voteSkipMeeting);
      }

      for (const otherPlayer of this.otherPlayers) {
        playerVoteSkips.push(otherPlayer.voteSkipTimer || otherPlayer.isReady || otherPlayer.voteSkipMeeting);
      }

      return playerVoteSkips;
    },

    otherPlayers() {
      const otherPlayers = [];

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (!player.isSelf && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          otherPlayers.push(player);
        }
      }

      return otherPlayers;
    },
  },
}
</script>

<style lang="scss" scoped>
.right-player-list-bar-live {
  position: fixed;
  right: 40px;
  bottom: 20px;
}
</style>
