import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  state: {
    investmentCartCostRp: 0,
    investmentCartCostAp: 0,
    microphoneMuted: false,
    playerType: null,
  },
  mutations: {
    playerType: (state, value) => {
      state.playerType = value;
    },
    microphoneMuted: (state, value) => {
      state.microphoneMuted = value;
    },
    investmentCartCostRp: (state, value) => {
      state.investmentCartCostRp = value;
    },
    investmentCartCostAp: (state, value) => {
      state.investmentCartCostAp = value;
    },
  },
  getters: {
    playerType (state) {
      return state.playerType;
    },
    facilitatorCode(state) {
      if (!state.facilitatorCode) {
        const characters = ['0123456789ABCDEF'];

        const charactersLength = characters.length;

        let facilitatorCode = '';

        for (let i = 0; i < 10; i++) {
          facilitatorCode += characters[Math.random() * charactersLength];
        }

        state.facilitatorCode = facilitatorCode;
      }

      return state.facilitatorCode;
    },
    investmentCartCostRp (state) {
      return state.investmentCartCostRp;
    },
    investmentCartCostAp (state) {
      return state.investmentCartCostAp;
    },
    microphoneMuted (state) {
      return state.microphoneMuted;
    },
  },
}
