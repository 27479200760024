<template>
  <table class="table table-striped table-bordered leaderboard-table">
    <thead>
    <tr>
      <th>Position</th>
      <th>Team name</th>
      <th>Final score</th>
      <th>Team members</th>
      <th>Date</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="rank of ranks">
      <td class="text-end">{{ rank.position }}</td>
      <td>{{ rank.teamName }}</td>
      <td class="text-end">{{ rank.finalScore }}</td>
      <td>
        <div class="leaderboard-players d-flex gap-2">
          <div class="leaderboard-player" v-for="playerIndex in 5">
            <character-thumbnail
              v-if="rank.teamMembers.length >= playerIndex"
              :character-index="rank.teamMembers[playerIndex - 1].characterIndex"
              size="tiny"
              :name="rank.teamMembers[playerIndex - 1].name"
              @title="rank.teamMembers[playerIndex - 1].name"
            />
          </div>
        </div>
      </td>
      <td>{{ rank.date }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue';

export default {
  name: 'Leaderboard',
  components: {CharacterThumbnail},
  props: {
    ranks: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">


.leaderboard-table {
  font-size: 14pt;

  th, td {
    color: white;
    text-align: left;
  }

  .descriptor-column, .id-column {
    font-weight: bold;
  }

  tr:nth-child(even), th {
    background: linear-gradient(90deg, rgba(#0F3D3D, .8) -31.57%, rgba(#112B53, .8) 101.27%);
  }

  td {
    color: white;

    line-height: 50px;
  }
}

.leaderboard-player {
  width: 47px;
  height: 50px;
  background: rgba(#000000, .34);
  border-radius: 4px;

  .character-thumbnail {
    transform: scale(90%);
    transform-origin: 3px bottom;
  }
}

.leaderboard-players {
}
</style>
