<template>
  <div class="event-form">
    <div class="title-line">
      <h3 v-if="this.event === null">Create Event</h3>
      <h3 v-else>Edit Event</h3>
    </div>

    <div class="input-row">
      <label for="name">Event</label>
      <input type="text" id="name" v-model="eventName" required>
    </div>

    <div class="input-row">
      <label for="narrative">Default Room Narrative</label>
      <bootstrap-select :options="narrativeOptions" v-model.number="narrative"/>
    </div>

    <div class="input-row">
      <label for="narrative">Default Room Facilitator</label>
      <bootstrap-select :options="facilitatorOptions" v-model.number="facilitator"/>
    </div>

    <div>
      <button class="btn btn-success" @click="onClickSave" :disabled="!canSave">Save</button>
      <button class="btn btn-danger" @click="onClickCancel">Cancel</button>
    </div>

  </div>
</template>

<script>
import Lightbox from '@/components/common/Lightbox.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleSlider from '@/components/common/ToggleSlider.vue'
import { mapState } from 'vuex'
import Sidebar from '@/components/Admin/Sidebar.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'

export default {
  name: 'EventForm',
  components: {
    BootstrapSelect,
    Sidebar,
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    Lightbox,
  },
  data () {
    return {
      facilitatorOptions: [],
      narrativeOptions: [],
      eventName: '',
      narrative: 0,
      facilitator: 0,
      event: null,
    }
  },
  computed: {
    ...mapState(['signedInUser']),
    canSave () {
      return this.eventName !== '' && this.narrative !== ''
    },
  },
  mounted () {
    if (!this.signedInUser) {
      this.$router.push({ name: 'BackendIndex' });

      return
    }

    if (this.signedInUser.role === 'facilitator') {
      this.$router.push({ name: 'EventIndex' });

      return
    }

    this.$apiClient.backend.getNarratives().then((narratives) => {
      for (const narrative of narratives) {
        this.narrativeOptions.push({
          value: narrative.id,
          label: narrative.name
        })
      }
      this.narrative = this.narrativeOptions[0].value
    })

    this.$apiClient.backend.getUsers().then((facilitators) => {
      for (const facilitator of facilitators) {
        this.facilitatorOptions.push({
          value: facilitator.id,
          label: `${facilitator.role}: ${facilitator.username}`
        })
      }
      this.facilitator = this.facilitatorOptions[0].value
    })

    if (this.$route.params.id) {
      this.$apiClient.backend.getEvent(this.$route.params.id).then((data) => {
        this.event = data

        this.eventName = data.label
        this.narrative = data.defaultNarrative
        this.facilitator = data.defaultFacilitator
      })
    }
  },
  methods: {
    onClickCancel () {
      this.$router.push({ name: 'EventIndex' });
    },
    onClickSave () {
      if (this.event === null) {
        this.$apiClient.backend.createEvent(this.eventName, this.narrative, this.facilitator).then((data) => {
          this.$router.push({ name: 'EventIndex' });
        })
      } else {
        this.$apiClient.backend.updateEvent(this.event.id, this.eventName, this.narrative, this.facilitator).then((data) => {
          this.$router.push({ name: 'EventIndex' });
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.backend-container {
  margin: 0 auto;

  .btn {
    margin-left: .5em;
    height: 2.2rem;
    padding: .4em 1em;
  }

  select, input {
    width: 100%;
  }

  .input-row {
    margin-bottom: 2em;
  }

  .event-form {
    width: 50%;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, p, div {
    text-align: center;
  }

  .player-box {
    padding: 2em;
    margin: 1em;
    border: 1px solid lightgrey;
    border-radius: 10px;
  }

  .lobby-link {
    font-size: .8rem;
  }

}

h1, h2, h3, h4, h5, p, div {
  text-align: left;
}
</style>
