<template>
  <div class="container-fluid">
    <div class="row mt-4 bg-info" v-for="size in sizes" v-if="computedRole">
      <h2>{{ size }}</h2>
      <div class="col text-center" v-for="point in points">
        <role-progress-ball :with-background="withBackground > 0" :size="size" :role="computedRole" :current-points="point" :total-points="totalPoints" @click="currentPoints = point" /><br />
        <strong>{{ point }}</strong>
      </div>
      <div class="col text-center">
        <role-progress-ball :with-background="withBackground > 0" :size="size" :role="computedRole" :current-points="currentPoints" :total-points="totalPoints" /><br />
        <strong>{{ currentPoints }}</strong>
      </div>
    </div>
  </div>

  <dev-settings-panel>
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Current points</label>
        <input class="form-control" type="range" min="0" :max="totalPoints" v-model.number="currentPoints"/>
      </div>
    </div>
    <div class="row mt-2">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Total points</label>
        <input class="form-control" type="number" v-model.number="totalPoints"/>
      </div>
    </div>
    <div class="row mt-2">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Show background</label>
        <bootstrap-select :options="yesNoOptions" v-model.number="withBackground" placeholder-value="0" />
      </div>
    </div>
    <div class="row mt-2">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Role</label>
        <bootstrap-select :options="roleOptions" v-model="roleIndex" placeholder-value="-1" />
      </div>
    </div>
    <div class="row mt-2">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <button class="btn btn-success" @click="randomize">Randomize</button>
      </div>
    </div>
  </dev-settings-panel>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import RoleProgressBall from '@/components/character/RoleProgressBall.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import selectifyUtils, { selectifyArray } from '@/utils/selectifyUtils'
import { mapState } from 'vuex'
import { randomBetween } from '@/utils/randomUtil'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import MeetingPopup from '@/components/MeetingPopup/index.vue'

export default {
  name: 'RoleProgressBallDev',
  components: {
    MeetingPopup,
    DevSettingsPanel,
    BootstrapSelect,
    RoleProgressBall,
  },
  extends: DevComponentContainer,

  mounted() {
    this.roleIndex = 0;
    this.roleOptions = [];

    for (let i = 0, len = this.roles.length; i < len; i++) {
      const role = this.roles[i];

      this.roleOptions.push({
        label: `${role.name} (${role.icon})`,
        value: i.toString(),
      });
    }
  },

  data () {
    return {
      roleIndex: null,


      yesNoOptions: [
        {
          label: 'No',
          value: '0',
        },
        {
          label: 'Yes',
          value: '1',
        }
      ],
      roleOptions: [],
      sizes: [
        'tiny',
        'small',
        'medium-small',
        'medium',
        'large',
        'extra-large'
      ],
      withBackground: 0,
      currentPoints: 30,
      totalPoints: 60,
    }
  },
  computed: {
    ...mapState(['roles']),
    computedRole() {
      if (this.roleIndex === '-1' || this.roleIndex === null) {
        return null;
      }

      return this.roles[parseInt(this.roleIndex, 10)];
    },
    points() {
      const points100 = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

      const points = [];

      for (const point of points100) {
        points.push(Math.floor((point / 100) * this.totalPoints));
      }

      return points;
    },
  },
  methods: {
    randomize() {
      this.currentPoints = Math.round(randomBetween(0, this.totalPoints));
    }
  }
}
</script>
