<template>
  <div class="dashboard-action-dev">
    <dashboard-action
      :action-name="actionName"
      :action-description="actionDescription"
      :cost="cost"
      :indicators-impacted="indicatorsImpacted"
      :month="month"
      :in-response-to="inResponseTo"
    />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import DashboardPopup from '@/components/dashboard/DashboardPopup.vue'
import DashboardAction from '@/components/dashboard/DashboardAction.vue'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'DashboardActionDev',
  components: {
    DashboardAction,
    DashboardPopup,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      actionName: 'Action name 2',
      cost: 3,
      indicatorsImpacted: [],
      month: 11,
      actionDescription: 'Establish a unit/functional team at the MoH&S in charge of alignment between all public health domestic legislation, laws, regulations, policy and administrative requirements including those for compliance with the IHR (BM C1.1)',
      inResponseTo: 'MEASLES',
    }
  },

  mounted() {
    return this.$apiClient.jee.getJeeData().then((jeeCategories) => {
      const indicatorsImpacted = [];

      for (const jeeCategory of jeeCategories) {
        for (const jeeGroup of jeeCategory.groups) {
          if (Math.random() > 0.3) {
            continue;
          }

          for (const jee of jeeGroup.jees) {
            if (Math.random() > 0.3) {
              continue;
            }

            indicatorsImpacted.push({
              category: {
                identifier: jeeCategory.identifier,
                name: jeeCategory.name,
              },
              group: {
                identifier: jeeGroup.identifier,
                name: jeeGroup.name,
                description: jeeGroup.description,
              },
              jee: {
                identifier: jee.identifier,
                name: jee.name,
                description: jee.description,
                impact: Math.round(randomBetween(-10, 10)),
                capacityLevel: Math.round(randomBetween(1, 5)),
              },
            })
          }
        }
      }

      this.indicatorsImpacted = indicatorsImpacted;
    });
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/variables.scss';

  .dashboard-action-dev {
    border: 1px solid black;
    margin: 0 auto;
    width: 100%;
    height: 600px;
    max-height: $appHeight * 0.60; //60vh;
  }
</style>
