export default [
  {
    'pageNumber': 1,
    'header': 'Introduction',
    'body': 'The EPIC 2 game will provide a virtual simulated world in which your knowledge, skills and collaborations will be tested and demonstrated. In this virtual simulated world, you will be able to observe and analyse the potential outcomes and consequences of your teams decisions as well as receive immediate feedback to guide your decision making.\n' + 
      '\n' + 
      '**The main objectives of the game are as follows:**\n' + 
      '- Prioritise actions and allocate resources to strengthen the capacities of the national health emergency preparedness and response agenda.\n' + 
      '- Ensure coordination with relevant stakeholders, at local, national and international levels using formal and informal coordination mechanisms.\n' + 
      '- Foster cooperation among all key sectors despite diverging interests.\n' + 
      '- Communicate early on and effectively on the public health events to relevant stakeholders.\n' + 
      '- Collaborate on giving the best response to the public health events throughout the game.',
    'media': 'Intro1.jpeg',
  },
  {
    'pageNumber': 2,
    'header': 'Global land',
    'body': '**Country Profile:**\n' + 
      '- **Population**: 14 mil.\n' + 
      '- **Area**: 236.800 km\u00b2\n' + 
      '- **Capital**: Tjemara\n' + 
      '- **Languages**: Global Landish (official), English, French & various ethnic languages\n' + 
      '- **Border Countries**: Golantic, Vuslando & Nextdooria\n' + 
      '- **Natural Hazards**: Floods, Cyclones, droughts & seismic activity\n' + 
      '\n' + 
      '**Health Profile:**\n' + 
      '- **Food or waterborne diseases**: Bacterial and protozoal diarrhea, hepatitis A & typhoid fever\n' + 
      '- **Vector-borne diseases**: Dengue fever & malaria',
    'media': 'Intro2.jpeg',
  },
  {
    'pageNumber': 3,
    'header': 'International event',
    'body': 'The **Global Land Handball Cup** will be the 8th Globalland Cup, a quadrennial One Day International (ODI) handball tournament contested by men and women\'s national teams and organized by the Global Handball Council (GHC). It will be hosted in Global Land in 12 months where teams from Golantic, Nextdooria, Vuslando, Treministan, Baru and Burland will participate.',
    'media': 'Intro3.jpeg',
  },
  {
    'pageNumber': 4,
    'header': 'Political Landscape',
    'body': 'Global Land is a democratic country with two main political parties: the right wing National Patriotic Front of Global Land (NPFG) and the left wing Global Party of People (GPP). An election in Global Land will happen in 20 months and at the last election in Y019, GPP party won by 60% to NPFG party at 40% of the votes.',
    'media': 'Intro4.jpeg',
  },
  {
    'pageNumber': 5,
    'header': 'Global Handball Steering Committee (GHSC)',
    'body': 'Global Land is going to hold a big international sporting event that will attract more than 500.000 visitors - therefore the country is preparing itself to host the event. The government is setting up a steering committee to address the health related issues in Global Land and to ensure that the Global Land Handball Cup will be helt without issues.',
    'media': 'Intro5.jpeg',
  },
];