<template>
  <lobby-popup-template :show-footer-background="false">
    <template v-slot:header>
      <div>
        {{ $t('intro.introHeader') }}
      </div>
    </template>
    <template v-slot:content>
      <div class="content-outer">
        <div class="content-inner epic-scrollbar">
          <markdown-text>{{ $t('intro.introText') }}</markdown-text>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer-button">
        <app-button @click="clickContinue" color="green" width="lg">{{ $t('lobby.buttonContinue') }}</app-button>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LongButton from '@/components/common/LongButton'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate'
import AppButton from '@/components/common/buttons/AppButton.vue';
import MarkdownText from '@/components/common/MarkdownText/index.vue'

export default {
  name: 'LobbyPopupRoleIntro',
  components: {
    MarkdownText,
    AppButton,
    LongButton,
    LobbyPopupTemplate,
  },
  emits: ['continue'],
  methods: {
    clickContinue() {
      this.$emit('continue');
    }
  }
}
</script>

<style lang="scss" scoped>
.mic-container {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 13px;
  width: fit-content;
  margin: 0 auto;

  > div {
    height: 100px;
    padding: 5px;
  }
}

.dark-bg {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000000, .2);
  padding: 5px;

  img {
  }
}

.settings-panel {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;

  .settings-panel-single {
    height: 50%;
    margin: 0;
    width: 200px;

    .dark-bg {
      height: 100%;
    }

    &:first-child {
      padding-bottom: 5px;
    }

    &:last-child {
      padding-top: 5px;
    }
  }

  img {
    max-width: 30px;
  }
}

select {
  text-align: center;

  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: inset 0px 2px 2px 1px rgba(7, 28, 39, 0.56), inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  border-radius: 5px;
}

.settings-panel-output-volume {
  img {
    max-height: 25px;
    margin-top: 4px;
  }
}

.large-mic-icon {
  max-width: 70px;
}

.voice-device-selector {
  margin-top: 27px;
}

.players-ready-label {
  font-size: 30pt;
  color: white;
  margin-right: 35px;
  font-weight: 700;
}

.character-container {
  padding-top: 50px;
}

.single-character-container-self {
  .player-card {
    transform: scale(120%);
    transform-origin: bottom center;
  }
}

.all-letters-activated {
  font-weight: bold;
  color: #AA0000;
}

.content-inner {
  text-align: justify;
  font-size: 160%;
  line-height: 150%;
  overflow-y: auto;

  p {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.content-outer {
  margin: 0 auto;
  max-height: 100%;
  height: 100%;

  background: rgba(#194040, .8);
  padding: 50px;
}

.content-inner,
.footer-hint,
.footer-hint-text {
  color: #ffffff;
}

.footer-hint {
  font-size: 120%;
  font-weight: 700;
}

.footer-hint-text {
  max-width: 40%;
  font-weight: 0;
  text-align: justify;
  padding: 0 50px 0 20px;
}
</style>
