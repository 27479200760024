<template>
  <div class="backend-login-full">
    <div class="center-page">
      <img class="logo" src="@/assets/lobby/logo.png">

      <div v-if='error' :class="'notice ' + shakeClass">
        Incorrect username or password
      </div>

      <div class="frame">

        <h1>Backend Login</h1>

        <form class="login-form">
          <div>
            <label for="username">Username</label>
            <input id="username" v-model="username" required>
          </div>
          <div>
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" required>
          </div>
          <button class="btn btn-warning" @click="attemptLogin">Login</button>
        </form>

      </div>

    </div>
  </div>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import MediaContainer from '@/components/common/media/MediaContainer.vue'

export default {
  name: 'BackendLoginView',
  components: {
    MarkdownText,
    LongButton,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    MediaContainer,
  },
  data () {
    return {
      username: '',
      password: '',
      error: false,
      shakeClass: 'shake',
    }
  },
  emits: ['logged-in'],
  methods: {
    attemptLogin () {
      this.$apiClient.backend.attemptLogin(this.username, this.password).then((data) => {
        this.$apiClient.setDefaultHeader('authorization', data.token)

        // Save JWT to sessionStorage
        sessionStorage.setItem('jwtToken', data.token)

        this.onSuccessfulLogin(data.user)
      }).catch((e) => {
        this.shakeClass = ''
        this.error = true
        console.log('ERROR', e)

        setTimeout(() => {
          this.shakeClass = 'shake'
        }, 10);
      })
    },
    onSuccessfulLogin (user) {
      this.$emit('logged-in', user);
    }
  }
}
</script>

<style lang="scss" scoped>

.center-page {
  position: absolute;
  left: 50%;
  top: 47%;
  transform: translate(-50%, -50%);

  .logo {
    height: 150px;
    margin-bottom: 2em;
    filter: drop-shadow(0 0 0.75rem black);
  }

  .frame {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #e3e3e3;
    padding: 3em;
    border-radius: 20px;
    box-shadow: .5em .5em 1em rgba(0, 0, 0, 0.2);
    margin: 0 5em;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1em;

    div {
      input {
        float: right;
        width: 100%;
      }
    }

  }

  .notice {
    position: absolute;
    margin: 1em auto;
    left: 0;
    right: 0;
    color: red;
  }

  .shake {
    animation: shake 0.5s ease-in-out;
  }

  p {
    font-size: 16pt;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

}
</style>
