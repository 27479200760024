<template>
  <div class="debriefing">
    <div class="debriefing-header">
      <h1>{{ $t('debriefing.finalFeedbackTitle') }}</h1>
    </div>
    <div class="debriefing-section">
      <h2>
        {{ debriefingTitle }}
      </h2>
      <p>
        {{ debriefingText }}
      </p>
    </div>

    <div class="debriefing-header">
      <h1>{{ $t('debriefing.finalScoreTitle') }}: {{ finalScoreComputed }}</h1>
    </div>
    <div class="debriefing-section debriefing-section-dark">
      <!--<markdown-text>{{ $t('debriefing.finalScoreDescription') }}</markdown-text>-->

      <debriefing-final-score-card :mg-success="mgSuccess" :collaboration="collaboration" :health-security="healthSecurity" :trust="trust" />
    </div>

    <div class="debriefing-header">
      <h1>{{ $t('debriefing.indicatorsTitle') }}</h1>
    </div>
    <div class="debriefing-section debriefing-section-no-padding">
     <!-- <div class="debriefing-re-padding">
        <p>
          <markdown-text>{{ $t('debriefing.indicatorsDescription') }}</markdown-text>
        </p>
      </div>-->

      <debriefing-indicator-category
        v-for="jeeCategory of jeeCategories"
        :identifier="jeeCategory.identifier"
        :name="jeeCategory.name"
        :groups="jeeCategory.groups"
        :jee-scores="jeeScores"

      />
    </div>

    <!--<div class="debriefing-header">
      <h1>{{ $t('debriefing.learningObjectivesTitle') }}</h1>
    </div>
    <div class="debriefing-section debriefing-section-no-padding">
      <debriefing-learning-objective-chart :bars="learningObjectiveChartBars" />
    </div>-->

    <div class="debriefing-header">
      <h1>{{ $t('debriefing.actionsTakenTitle') }}</h1>
    </div>
    <div class="debriefing-section">
      <meeting-analytics
        type="room"
        :all-actions="implementedActions"
        :implemented-actions="implementedActions"
        :months-available="12"
      />
    </div>
  </div>
</template>
<script>
import DebriefingFinalScoreCard from '@/components/Debriefing/DebriefingFinalScoreCard/index.vue'
import DebriefingIndicatorCategory from '@/components/Debriefing/DebriefingIndicator/DebriefingIndicatorCategory.vue';
import {mapState} from 'vuex';
import DashboardAction from '@/components/dashboard/DashboardAction.vue';
import DebriefingLearningObjectiveChart from '@/components/Debriefing/DebriefingLearningObjectiveChart/index.vue';
import { ensureArray, objectToArray } from '@/utils/arrayUtil';
import MarkdownText from '@/components/common/MarkdownText/index.vue';
import MeetingAnalytics from '@/components/MeetingAnalytics/index.vue';

export default {
  name: 'Debriefing',
  components: {
    MeetingAnalytics,
    MarkdownText,
    DebriefingLearningObjectiveChart, DashboardAction, DebriefingIndicatorCategory, DebriefingFinalScoreCard },
  props: {
    finalScore: {
      type: Number,
      required: false,
    },
    trust: {
      type: Number,
      required: true,
    },
    healthSecurity: {
      type: Number,
      required: true,
    },
    collaboration: {
      type: Number,
      required: true,
    },
    mgSuccess: {
      type: Number,
      required: true,
    },
    jeeScores: {
      type: Object,
      required: true,
    },
    responseActionsTaken: {
      type: Array,
      required: true,
    },
    strategicActionsTaken: {
      type: Array,
      required: true,
    },
    learningObjectiveChartBars: {
      type: Array,
      required: true,
    },
    debriefingTitle: {
      type: String,
      required: true,
    },
    debriefingText: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['jeeCategories']),
    implementedActions() {
      const implementedActions = ensureArray([
        ...this.strategicActionsTaken,
        ...this.responseActionsTaken,
      ]);

      for (const implementedAction of implementedActions) {
        if (implementedAction.actionType === 'strategic') {
          implementedAction.categoryLabel = 'Strategic actions - Month ' + implementedAction.investAtMeeting;
          // TODO
        } else if (implementedAction.actionType === 'hazard') {
          implementedAction.categoryLabel = 'Hazards responses - Month ' + implementedAction.investAtMeeting;
        }
      }

      return implementedActions;
    },
    finalScoreComputed() {
      const score = this.finalScore ? this.finalScore : (this.trust + this.healthSecurity + this.collaboration + this.mgSuccess);

      return Math.round(score / 10);
    },
  },
}
</script>

<style lang="scss" scoped>
.debriefing {
  width: 1300px;
  margin: 0 auto;
  background: url("@/assets/textures/grunge-blue-50.png"), #000000;
  box-shadow: 4px 4px 3px 0px rgba(255, 255, 255, 0.40) inset, -5px -5px 4px 0px rgba(0, 0, 0, 0.40) inset;
  color: white;
  padding: 30px 0;

  .debriefing-header {
    background: rgba(0, 0, 0, .6);
    padding: 10px;

    h1 {
      margin-bottom: 0;
      font-size: 30pt;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .debriefing-section {
    padding: 30px 100px;

    &.debriefing-section-no-padding {
      padding: 0 !important;
    }

    &.debriefing-section-dark {
      background: rgba(0, 0, 0, .3);
    }
  }
}

.debriefing-re-padding {
  padding: 30px 100px;
}

p {
  margin-bottom: 0;
  font-size: 16pt;
  text-align: left;
}
</style>
