let promiseTimeout

export function clearTimeoutPromise () {
  if (promiseTimeout) {
    clearTimeout(promiseTimeout)

    promiseTimeout = null
  }
}

export function timeoutPromise (timeout, clearTimeout = true) {
  return new Promise((resolve) => {
    if (clearTimeout) {
      clearTimeoutPromise()
    }

    if (timeout <= 0) {
      // Instantly resolve

      resolve();

      return;
    }

    promiseTimeout = setTimeout(() => {
      if (clearTimeout) {
        clearTimeoutPromise()
      }

      resolve()
    }, timeout)
  })
}

export function runSequentially(promiseFunctions, delayTimeBetween = 0) {
  return promiseFunctions.reduce(
    (previousPromise, nextPromiseFn) => {
      return previousPromise
        .then(() => timeoutPromise(delayTimeBetween)) // Wait for the specified delay time
        .then(nextPromiseFn); // Then execute the next promise function
    },
    Promise.resolve()
  );
}
