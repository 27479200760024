<template>
  <lobby-popup-template :show-footer="false" :style="{ width: '1300px' }" :content-class="'equal-padding'">
    <template v-slot:header>
      {{ $t('meetingPopup.feedbackHeader') }}
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column flex-grow-0" v-if="actionInvestments.length <= 0">
        <div class="meeting-results-content-outer flex-grow-1">
          <div class="meeting-results-content text-center">
            {{ $t('meetingPopup.feedbackNothingImplemented') }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-grow-0" v-else>
        <div class="meeting-results-header row">
          <div class="col-6 text-start actions-taken-container">
            {{ $t('meetingPopup.feedbackActionsTaken') }}: {{ actionInvestments.length }}
          </div>
          <div class="col-6 text-end feedback-container">
            {{ $t('meetingPopup.feedbackFeedback') }}
          </div>
        </div>
        <div class="meeting-results-content-outer flex-grow-1">
          <div class="meeting-results-content d-flex align-items-stretch flex-no-wrap gap-2">
            <div class="meeting-results-actions flex-grow-1 epic-scrollbar">
              <meeting-popup-action
                class="meeting-results-action"
                v-for="actionInvestment in actionInvestments"
                :action="actionInvestment.action"
                :show-actions="false"
                :show-feedback-button="!!actionInvestment.action.feedbackNarrative || false"
                :feedback-is-active="feedbackActionIdentifier === actionInvestment.actionIdentifier"
                :investments="actionInvestment.playerInvestments"
                :players="players"
                :highlight-player-aoe="false"
                :meeting-type="meetingType"
                @feedback-show="feedbackActionIdentifier = actionInvestment.actionIdentifier"
              />
            </div>
            <div class="meeting-results-feedback">
              <div class="meeting-results-feedback-outer">
                <div class="meeting-results-feedback-inner epic-scrollbar" v-if="feedbackAction">
                  {{ feedbackAction.feedbackNarrative }}
                </div>
                <div class="meeting-results-feedback-inner" v-else>
                  <div class="meeting-results-feedback-inner-inactive">
                    <no-drag-image src="@/assets/icons/eye-lg.png"/>
                    <p>{{ $t('meetingPopup.feedbackClickToShowFeedback') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import HazardIcon from '@/components/common/HazardIcon.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import MeetingPopupCategory from '@/components/MeetingPopup/MeetingPopupCategory.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'
import StrategicMeetingAction from '@/components/StrategicMeeting/StrategicMeetingAction/index.vue'
import MeetingPopupAction from '@/components/MeetingPopup/MeetingPopupAction.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import { runSequentially } from '@/utils/promiseUtil'
import StarPoints from '@/components/StarPoints/index.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'MeetingResultsPopup',
  components: {
    StarPoints,
    FloatingHazardIcon,
    LobbyPopupTemplate,
    StrategicMeetingAction,
    ToggleAppButton,
    AppButton,
    NoDragImage,
    HazardIcon,
    MeetingPopupCategory,
    MeetingPopupAction,
  },
  props: {
    meetingType: {
      type: String,
      required: true,
    },
    meetingNumber: {
      type: Number,
      required: true,
    },
    investments: {
      type: Array,
      required: true,
    },
    actionCategories: {
      type: Object,
      required: true,
    },
    players: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionInvestments: [],
      actionsAssoc: {},
      feedbackActionIdentifier: null,

      actionCount: 0,
    }
  },
  watch: {
    investments: {
      handler() {
        this.recalculateInvestedActions();
      },
      deep: true,
    }
  },
  mounted() {
    this.recalculateInvestedActions();

    if (!this.feedbackActionIdentifier) {
      //this.feedbackActionIdentifier = this.getFirstFeedbackActionIdentifier();

      //console.log('this.feedbackActionIdentifier, this.feedbackActionIdentifier', this.feedbackActionIdentifier);
    }

    if (this.meetingNumber === 1) {
      if (this.meetingType === 'strategic') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.StrategicMeetingResult);
      } else if (this.meetingType === 'hazard') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.HazardMeetingResult);
      }
    }
  },
  methods: {
    getFirstFeedbackActionIdentifier() {
      for (const investment of this.actionInvestments) {
        if (investment.action.feedbackNarrative) {
          return investment.action.identifier;
        }
      }

      return null;
    },
    parseAction (action) {
      const _action = structuredClone(action)

      _action.cost = _action.baseCost

      return _action
    },
    recalculateInvestedActions() {
      console.log('recalculateInvestedActions');

      const actionsAssoc = {};

      for (const category of this.actionCategories) {
        for (const tab of category.tabs) {
          for (const action of tab.actions) {
            actionsAssoc[action.identifier] = this.parseAction(action);
          }
        }
      }

      console.log('recalculateInvestedActions', actionsAssoc);

      const actionIdentifiers = [];
      const playerInvestments = {};

      for (const investment of this.investments) {
        const actionIdentifier = investment.actionIdentifier;

        if (!actionIdentifiers.includes(actionIdentifier)) {
          playerInvestments[actionIdentifier] = [];

          actionIdentifiers.push(actionIdentifier);
        }

        playerInvestments[investment.actionIdentifier].push({
          playerCode: investment.playerCode,
          investment: investment.investment,
        });
      }

      const actionInvestments = [];

      for (const actionIdentifier of actionIdentifiers) {
        if (!playerInvestments[actionIdentifier]) {
          continue;
        }

        let totalInvestment = 0;

        for (const playerInvestment of playerInvestments[actionIdentifier]) {
          if (playerInvestment.investment) {
            totalInvestment += playerInvestment.investment;
          }
        }

        actionInvestments.push({
          actionIdentifier: actionIdentifier,
          action: actionsAssoc[actionIdentifier],
          playerInvestments: playerInvestments[actionIdentifier],
          totalInvestment: totalInvestment,
        })
      }

      console.log('investments', {
        investments: this.investments,
        actionsAssoc,
        actionInvestments,
        playerInvestments,
        actionIdentifiers,
      }.investments);
      console.log('actionsAssoc', actionsAssoc);
      console.log('actionInvestments', actionInvestments)
      console.log('playerInvestments', playerInvestments);

      this.actionsAssoc = actionsAssoc;
      this.actionInvestments = actionInvestments;
    }
  },
  computed: {
    feedbackAction () {
      if (!this.feedbackActionIdentifier) {
        return null;
      }

      const action = this.actionsAssoc[this.feedbackActionIdentifier];

      return this.parseAction(action);
    },
  }
}
</script>

<style lang="scss" scoped>
.meeting-results-header {
  color: white;
  text-transform: uppercase;
  font-size: 20pt;
  font-weight: 700;
}

.meeting-results-feedback {
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;
  flex-basis: 380px;
  min-width: 380px;
  height: 100%;
  text-align: left;
  font-size: 18pt;
  white-space: pre-line;

  .meeting-results-feedback-outer,
  .meeting-results-feedback-inner {
    width: 100%;
    height: 100%;
  }

  .meeting-results-feedback-outer {
    border-radius: 10px;
    overflow: hidden;
  }

  .meeting-results-feedback-inner {
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), #000E21;
    box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
    color: white;
    padding: 10px;
    white-space: pre-line;
    position: relative;

    .meeting-results-feedback-inner-inactive {
      text-align: center;
      position: absolute;

      top: 50%;
      left: 0;

      width: 100%;
      height: fit-content;

      transform: translateY(-50%);
      padding: 0 15px;


      img {
        margin: 0 auto;
      }

      p {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

.meeting-results-actions {
  .meeting-results-action {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.meeting-results-content {
  height: 740px;
  color: white;
  font-size: 16pt;
}

.meeting-results-actions {
  height: 100%;
}
</style>
