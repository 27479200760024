<template>
  <div class="icon" :class="'icon-size-' + size" v-if="iconSource">
    <no-drag-image :src="iconSource" />
  </div>
</template>
<script>
import NoDragImage from '@/components/common/NoDragImage.vue'

const iconSourceMapper = {
  'animal': require('@/assets/icons/hazards/hazard-animal.png'),
  'chemical': require('@/assets/icons/hazards/hazard-chemical.png'),
  'disaster': require('@/assets/icons/hazards/hazard-disaster.png'),
  'environmental': require('@/assets/icons/hazards/hazard-environmental.png'),
  'infectious': require('@/assets/icons/hazards/hazard-infectious.png'),
  'societal': require('@/assets/icons/hazards/hazard-societal.png'),
  'unknown': require('@/assets/icons/hazards/hazard-unknown.png'),
  'zoonosis': require('@/assets/icons/hazards/hazard-zoonosis.png'),
  'news': require('@/assets/icons/hazards/hazard-news.png'),
  'foodSafety': require('@/assets/icons/hazards/hazard-food-safety.png'),
};

const icons = Object.keys(iconSourceMapper);

export default {
  name: 'HazardIcon',
  components: {
    NoDragImage,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
  },
  computed: {
    iconSource() {
      if (!icons.includes(this.icon)) {
        console.error(`Could not find hazard icon ${this.icon}`);

        return iconSourceMapper.unknown;
      }

      return iconSourceMapper[this.icon];
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  width: 265px;
  height: 238px;
  display: inline-block;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &.icon-size-small,
  &.icon-size-sm {
    transform: scale(80%);
  }

  &.icon-size-medium,
  &.icon-size-md {
    transform: scale(100%);
  }

  &.icon-size-large,
  &.icon-size-lg {
    transform: scale(120%);
  }

  &.icon-size-fit {
    transform: scale(100%);
    width: auto;
    height: auto;
  }
}
</style>
