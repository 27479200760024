export default [
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Airborne diseases',
    'hazardName': 'COVID-19',
    'healthConsequences': [
      'fever',
      'sore throat',
      'shortness of breath',
      'multi-organ failure',
      'death',
      'socio-economic disruptions',
      '',
    ],
    'scale': 'Common cause of hospitalization and death, affects urban and rural areas. Low vaccine coverage, elderly people and those with comobidity at higher risk of severe complications',
    'exposure': 'Immunity level is moderate in general population',
    'frequency': 'frequent',
    'seasonality': [
      4,
      3,
      3,
      3,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      3,
    ],
    'likelihood': 'almostCertain',
    'severity': 'moderate',
    'vulnerability': 'high',
    'copingCapacity': 'veryHigh',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'HYDRO_METEOROLOGICAL',
    'mainType': 'Tropical cyclone\n' + 
      '[cyclonic wind, cyclonic rain, cyclone (storm) surge)]',
    'hazardName': 'Cyclone',
    'healthConsequences': [
      'drowning',
      'injuries',
      'damage to critical infrastructure',
      'disruption of socio-economic activities',
    ],
    'scale': 'Common cause of hospitalization and deaths, commonly affects coastal areas of Global Land',
    'exposure': 'General population is susceptible',
    'frequency': 'perennial',
    'seasonality': [
      4,
      1,
      4,
      4,
      4,
      2,
      3,
      4,
      4,
      3,
      2,
      1,
    ],
    'likelihood': 'veryLikely',
    'severity': 'high',
    'vulnerability': 'low',
    'copingCapacity': 'perennial',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [
      {
        'index': 0,
        'frequency': 'perennial',
        'seasonality': [
          1,
          1,
          2,
          2,
          2,
          2,
          3,
          4,
          4,
          3,
          2,
          1,
        ],
        'likelihood': 'veryUnlikely',
      },
      {
        'index': 1,
        'frequency': 'recurrent',
        'seasonality': [
          3,
          4,
          2,
          3,
          4,
          1,
          0,
          2,
          0,
          0,
          4,
          2,
        ],
        'likelihood': 'unlikely',
      },
      {
        'index': 2,
        'frequency': 'frequent',
        'seasonality': [
          0,
          4,
          2,
          3,
          4,
          2,
          4,
          4,
          4,
          0,
          4,
          4,
        ],
        'likelihood': 'veryLikely',
      },
      {
        'index': 3,
        'frequency': 'rare',
        'seasonality': [
          0,
          0,
          0,
          3,
          4,
          2,
          4,
          4,
          0,
          4,
          0,
          4,
        ],
        'likelihood': 'almostCertain',
      },
    ],
    'impactData': [
      {
        'index': 0,
        'severity': 'low',
        'vulnerability': 'partial',
        'copingCapacity': 'veryHigh',
      },
      {
        'index': 1,
        'severity': 'moderate',
        'vulnerability': 'high',
        'copingCapacity': 'high',
      },
      {
        'index': 2,
        'severity': 'high',
        'vulnerability': 'veryHigh',
        'copingCapacity': 'perennial',
      },
      {
        'index': 3,
        'severity': 'veryHigh',
        'vulnerability': 'low',
        'copingCapacity': 'partial',
      },
    ],
    'editable': true,
    'correctData': {
      'frequency': 'perennial',
      'seasonality': [
        1,
        1,
        2,
        2,
        2,
        2,
        3,
        4,
        4,
        3,
        2,
        1,
      ],
      'likelihood': 'veryLikely',
      'severity': 'high',
      'vulnerability': 'high',
      'copingCapacity': 'perennial',
      'confidenceLevel': 'satisfactory',
    },
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Airborne diseases',
    'hazardName': 'Disease X',
    'healthConsequences': [
      'fever',
      'sore throat',
      'shortness of breath',
      'multi-organ failure',
      'death',
      'socio-economic disruptions',
      '',
    ],
    'scale': 'May cause hospitalizations and death',
    'exposure': 'Immunity level may be low in general population',
    'frequency': 'random',
    'seasonality': [
      3,
      3,
      3,
      4,
      4,
      4,
      4,
      3,
      3,
      2,
      2,
      1,
    ],
    'likelihood': 'likely',
    'severity': 'veryHigh',
    'vulnerability': 'veryHigh',
    'copingCapacity': 'perennial',
    'confidenceLevel': 'unsatisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'SOCIETAL',
    'mainType': 'non-International armed\n' + 
      'conflict',
    'hazardName': 'Acts of violence',
    'healthConsequences': [
      'injuries',
      'deaths',
      'disruption of essential health and basic services',
      'collapse of infrastructure',
    ],
    'scale': 'Very common in border communities and cities',
    'exposure': 'Refugees and people living in border communities are more susceptible',
    'frequency': 'perennial',
    'seasonality': [
      3,
      3,
      2,
      2,
      2,
      1,
      2,
      3,
      3,
      3,
      4,
      4,
    ],
    'likelihood': 'veryLikely',
    'severity': 'moderate',
    'vulnerability': 'veryHigh',
    'copingCapacity': 'perennial',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Airborne diseases',
    'hazardName': 'Measles',
    'healthConsequences': [
      'fever',
      'cough',
      'skin rash',
      'pneumonia',
      'encephalitis',
    ],
    'scale': 'common cause of under-five deaths, affects mostly rural areas',
    'exposure': 'Immunity is moderate in general population, low in children',
    'frequency': 'perennial',
    'seasonality': [
      2,
      2,
      2,
      3,
      3,
      3,
      3,
      4,
      4,
      4,
      3,
      2,
    ],
    'likelihood': 'veryLikely',
    'severity': 'high',
    'vulnerability': 'high',
    'copingCapacity': 'partial',
    'confidenceLevel': 'good',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'TECHNOLOGICAL',
    'mainType': 'Chemical spill',
    'hazardName': 'Chemical exposure',
    'healthConsequences': [
      'shortness of breath',
      'chemical burns',
      'death',
      'malignancy',
      'environmental degradation',
      'contamination of water sources',
    ],
    'scale': 'Increased hospitalizations and death, affects those living close to River \u2026.......',
    'exposure': 'People living near rivers are more susceptible',
    'frequency': 'frequent',
    'seasonality': [
      1,
      1,
      1,
      2,
      2,
      3,
      3,
      3,
      4,
      4,
      4,
      3,
    ],
    'likelihood': 'likely',
    'severity': 'veryHigh',
    'vulnerability': 'partial',
    'copingCapacity': 'perennial',
    'confidenceLevel': 'unsatisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Vector-borne diseases',
    'hazardName': 'Dengue',
    'healthConsequences': [
      'fever',
      'cough',
      'generalized body pain',
      'bleeding from orifices',
      'seizure',
      'brain damage',
    ],
    'scale': 'Common cause of hospitalization, mainly found in urban areas',
    'exposure': 'Immunity level is moderate in general population',
    'frequency': 'perennial',
    'seasonality': [
      1,
      2,
      2,
      3,
      3,
      4,
      4,
      4,
      4,
      3,
      2,
      1,
    ],
    'likelihood': 'veryLikely',
    'severity': 'moderate',
    'vulnerability': 'high',
    'copingCapacity': 'partial',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'GEOPHYSICAL',
    'mainType': 'Earthquake',
    'hazardName': 'Earthquake',
    'healthConsequences': [
      'injuries',
      'deaths',
      'damage to infrastructure',
      'socio-economic disruption',
      'waterborne disease outbreaks',
    ],
    'scale': 'Mostly affects 3 states in Global Land historically',
    'exposure': 'General population is susceptible',
    'frequency': 'random',
    'seasonality': [
      1,
      1,
      2,
      2,
      3,
      3,
      3,
      3,
      2,
      1,
      1,
      1,
    ],
    'likelihood': 'likely',
    'severity': 'veryHigh',
    'vulnerability': 'partial',
    'copingCapacity': 'partial',
    'confidenceLevel': 'good',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'HYDRO_METEOROLOGICAL',
    'mainType': 'Riverine flood',
    'hazardName': 'Flooding',
    'healthConsequences': [
      'drowning',
      'injuries',
      'death',
      'damage to infrastructure',
      'animal bites',
      'snake bites',
      'trauma',
      'malnutrition',
      'disease outbreaks',
      'disruption of essential servic',
    ],
    'scale': 'Common cause of hospitalization and deaths, commonly affects coastal areas of Global Land',
    'exposure': 'Coastal communities are more susceptible',
    'frequency': 'frequent',
    'seasonality': [
      1,
      1,
      1,
      2,
      2,
      2,
      3,
      4,
      4,
      4,
      3,
      2,
    ],
    'likelihood': 'veryLikely',
    'severity': 'veryHigh',
    'vulnerability': 'high',
    'copingCapacity': 'partial',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [
      {
        'index': 0,
        'frequency': 'perennial',
        'seasonality': [
          1,
          1,
          1,
          2,
          2,
          2,
          3,
          4,
          4,
          4,
          3,
          2,
        ],
        'likelihood': 'veryUnlikely',
      },
      {
        'index': 1,
        'frequency': 'recurrent',
        'seasonality': [
          1,
          1,
          1,
          2,
          2,
          3,
          3,
          3,
          4,
          3,
          3,
          2,
        ],
        'likelihood': 'unlikely',
      },
      {
        'index': 2,
        'frequency': 'frequent',
        'seasonality': [
          3,
          2,
          2,
          1,
          1,
          1,
          2,
          2,
          2,
          3,
          3,
          3,
        ],
        'likelihood': 'veryLikely',
      },
      {
        'index': 3,
        'frequency': 'rare',
        'seasonality': [
          1,
          1,
          2,
          3,
          4,
          4,
          4,
          3,
          3,
          3,
          2,
          1,
        ],
        'likelihood': 'almostCertain',
      },
    ],
    'impactData': [
      {
        'index': 0,
        'severity': 'low',
        'vulnerability': 'partial',
        'copingCapacity': 'veryHigh',
      },
      {
        'index': 1,
        'severity': 'moderate',
        'vulnerability': 'high',
        'copingCapacity': 'high',
      },
      {
        'index': 2,
        'severity': 'high',
        'vulnerability': 'veryHigh',
        'copingCapacity': 'perennial',
      },
      {
        'index': 3,
        'severity': 'veryHigh',
        'vulnerability': 'low',
        'copingCapacity': 'partial',
      },
    ],
    'editable': true,
    'correctData': {
      'frequency': 'frequent',
      'seasonality': [
        1,
        1,
        1,
        2,
        2,
        2,
        3,
        4,
        4,
        4,
        3,
        2,
      ],
      'likelihood': 'veryLikely',
      'severity': 'veryHigh',
      'vulnerability': 'high',
      'copingCapacity': 'partial',
      'confidenceLevel': 'satisfactory',
    },
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Vector-borne diseases',
    'hazardName': 'Malaria',
    'healthConsequences': [
      'fever',
      'cough',
      'generalized body pain',
      'loss of appetite',
      'convulsions in children',
    ],
    'scale': 'Common cause of under-five deaths, affects mostly rural areas',
    'exposure': 'Immunity level is low in children aged 5 years and below',
    'frequency': 'perennial',
    'seasonality': [
      1,
      2,
      2,
      2,
      2,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
    ],
    'likelihood': 'veryLikely',
    'severity': 'moderate',
    'vulnerability': 'low',
    'copingCapacity': 'high',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Airborne diseases',
    'hazardName': 'Influenza',
    'healthConsequences': [
      'fever',
      'cough',
      'body weakness',
      'sorethroat',
      'myocarditis',
      'encephalitis',
      'multi-organ failure',
    ],
    'scale': 'wide spread, especially during winter and fall',
    'exposure': 'Immunity level in general population is moderate, immunity level in pregnant women and those aged 65 years and above is low',
    'frequency': 'perennial',
    'seasonality': [
      1,
      2,
      2,
      2,
      2,
      3,
      3,
      4,
      4,
      4,
      2,
      2,
    ],
    'likelihood': 'almostCertain',
    'severity': 'low',
    'vulnerability': 'partial',
    'copingCapacity': 'high',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Waterborne diseases',
    'hazardName': 'Cholera',
    'healthConsequences': [
      'diarrhea',
      'vomiting',
      'dehydration',
      'shock',
      'death',
    ],
    'scale': 'Outbreaks are common in refugee camps',
    'exposure': 'Immunity level in general population is low',
    'frequency': 'recurrent',
    'seasonality': [
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      3,
      3,
      3,
      3,
      2,
    ],
    'likelihood': 'likely',
    'severity': 'high',
    'vulnerability': 'partial',
    'copingCapacity': 'partial',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
  {
    'subGroup': 'BIOLOGICAL',
    'mainType': 'Foodborne outbreaks',
    'hazardName': 'Food poisoning',
    'healthConsequences': [
      'diarrhea',
      'vomiting',
      'dehydration',
      'shock',
      'death',
    ],
    'scale': 'Outbreaks are common in rural areas and refugee settings',
    'exposure': 'Immunity level in general population is moderate',
    'frequency': 'perennial',
    'seasonality': [
      2,
      2,
      2,
      2,
      2,
      2,
      3,
      3,
      3,
      3,
      2,
      2,
    ],
    'likelihood': 'veryLikely',
    'severity': 'moderate',
    'vulnerability': 'low',
    'copingCapacity': 'high',
    'confidenceLevel': 'satisfactory',
    'likelihoodData': [

    ],
    'impactData': [

    ],
    'editable': false,
    'correctData': [

    ],
  },
];