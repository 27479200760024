<template>
  <div class="event-container">

    <div class="title-line">
      <h2>Events</h2>
      <button v-if="isAdmin" class="btn btn-success" @click="onClickAddEvent">Create New Event +</button>
    </div>

    <div class="accordion event" v-for="(event, index) in events" :key="index">
      <div class="accordion-item">
        <div class="flex">
          <h2 class="accordion-header" :id="'heading_' + index" style="display:flex">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse_' + index" aria-expanded="false"
                    :aria-controls="'collapse_' + index">
              {{ event && event.label ? event.label : 'N/A' }}
            </button>
          </h2>
        </div>

        <div :id="'collapse_' + index" class="accordion-collapse collapse" :aria-labelledby="'heading_' + index">
          <div>

            <div class="d-flex justify-content-between" style="margin:.5em">

              <div class="btn-group" role="group">
                <button class="btn btn-sm btn-outline-dark" @click="onPauseEvent(event)">Pause Event</button>
                <button class="btn btn-sm btn-outline-dark" @click="onUnPauseEvent(event)">Unpause Event</button>
              </div>

              <div>
                <button class="btn btn-sm btn-success mx-1" @click="onAddRoom(event)">Add Room</button>
                <button class="btn btn-sm btn-primary mx-1" @click="onOpenAnalytics(event)">Analytics</button>
                <button class="btn btn-sm btn-warning mx-1" @click="onEditEvent(event)">Edit</button>
                <button class="btn btn-sm btn-danger mx-1" @click="onDeleteEvent(event)">Delete</button>
              </div>

            </div>


            <table class="table table-striped table-bordered room-table">
              <thead>
              <tr>
                <th>Room ID</th>
                <th>Room name</th>
                <th>Players</th>
                <th>Last session</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <facilitator-room-row
                v-for="room of event.rooms"
                :key="room.id"
                :room="room"
                :ref="'facilitatorRows_'+event.id"
                @delete-save-data="onDeleteSaveData"
                @end-session="onEndSession"
                @spectate="onSpectate"
                @player-remove-facilitator-called="onPlayerRemoveFacilitatorCalled"
                @delete-room="onDeleteEvent(event)"
              />
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacilitatorRoomRow from '@/components/Facilitator/FacilitatorRoomRow.vue';
import FacilitatorPlayer from '@/components/Facilitator/FacilitatorPlayer.vue';
import Lightbox from '@/components/common/Lightbox.vue';
import StrategicMeetingLive from '@/components/StrategicMeeting/StrategicMeetingLive.vue';
import ErrorPopup from '@/components/ErrorPopup/index.vue';
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import CloseButton from '@/components/common/buttons/CloseButton.vue';
import AppButton from '@/components/common/buttons/AppButton.vue';
import ToggleSlider from '@/components/common/ToggleSlider.vue';
import Sidebar from '@/components/Admin/Sidebar.vue';
import { mapState } from 'vuex';
import Accordion from '@/components/common/Accordion.vue';

export default {
  name: 'EventIndex',
  components: {
    Accordion,
    Sidebar,
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    StrategicMeetingLive,
    Lightbox,
    FacilitatorPlayer,
    FacilitatorRoomRow,
  },
  computed: {
    ...mapState(['signedInUser']),
    isAdmin () {
      return this.signedInUser?.role === 'admin' || this.signedInUser?.role === 'rootadmin';
    }
  },
  data () {
    return {
      events: [],
      rooms: [],
      facilitatorRooms: [],

      loadingRooms: false,
      loadingRoomsError: null,

      loadRoomsTimeout: null,
    };
  },
  mounted () {
    if (!this.signedInUser) {
      this.$router.push({ name: 'BackendIndex' });

      return;
    }

    this.loadEvents();
  },
  unmounted () {
    //clearTimeout(this.loadRoomsTimeout);
    //this.loadRoomsTimeout = null;
  },
  methods: {
    loadEvents () {
      this.$apiClient.backend.getEvents().then((events) => {
        this.events = events;

        for (const event of events) {
          event.rooms = event.rooms.map(room => {
            const liveRooms = this.rooms.find(r => r.id === room.id);

            if (liveRooms) {
              return liveRooms;
            }

            const liveRoomsFacilitator = this.facilitatorRooms.find(r => r.id === room.id);

            if (liveRoomsFacilitator) {
              return liveRoomsFacilitator;
            }

            return room;
          });
        }
      });
    },
    async loadRooms () {
      if (this.loadRoomsTimeout) {
        clearTimeout(this.loadRoomsTimeout);

        this.loadRoomsTimeout = null;
      }

      this.facilitatorRooms = [];
      this.rooms = [];

      this.loadingRooms = true;
      this.loadingRoomsError = null;

      await this.$apiClient.facilitator.getRooms(this.liveRoomsOnly, this.signedInUser.role === 'facilitator').then((rooms) => {

        if (this.signedInUser.role !== 'facilitator') {
          this.facilitatorRooms = rooms.filter(x => x.facilitatorId === this.signedInUser.id);
          this.rooms = rooms.filter(x => x.facilitatorId !== this.signedInUser.id);
        } else {
          this.rooms = rooms;
        }

        this.loadingRooms = false;
      }).catch((e) => {
        this.loadingRoomsError = e;
        this.loadingRooms = false;
      });

      if (!this.loadingRoomsError) {
        await this.loadEvents();

        // Ask again in 30 seconds
        this.loadRoomsTimeout = setTimeout(() => {
          this.loadRooms();
        }, 30000);
      }
    },
    onPlayerRemoveFacilitatorCalled ({
      room,
      player
    }) {
      this.$apiClient.facilitator.removeRoomPlayerFacilitatorCalled(room.id, player.code).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue;
          }

          for (const _player of _room.players) {
            if (_player.playerId !== player.playerId) {
              continue;
            }

            console.log('_player', _player, player);

            _player.callFacilitator = false;
          }
        }
      });
    },
    onDeleteSaveData ({ room }) {
      if (!confirm(
        `Are you sure you want to delete the existing save data for room #${room.id}? The save data will be gone. This cannot be undone.`)) {
        return;
      }

      this.$apiClient.facilitator.deleteRoomSaveData(room.id).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue;
          }

          _room.saveData = null;
        }
      });
    },
    onDeleteEvent (event) {
      if (!confirm(
        `Are you sure you want to delete event "${event.label}"? This cannot be undone.`)) {
        return;
      }

      this.$apiClient.backend.deleteEvent(event.id).then(() => {

        for (let i = 0; i < this.events.length; i++) {
          const _event = this.events[i];
          if (_event.id !== event.id) {
            continue;
          }

          this.events.splice(i, 1);

        }
      });
    },
    onEditEvent (event) {
      this.$router.push({
        name: 'EventUpdate',
        params: { id: event.id }
      });
    },
    onAddRoom (event) {
      this.$router.push({
        name: 'RoomCreate',
        params: { id: event.id }
      });
    },
    onOpenAnalytics (event) {
      this.$router.push({
        name: 'EventAnalytics',
        params: { id: event.id }
      });
    },
    onPauseEvent (event) {
      const refName = 'facilitatorRows_' + event.id;
      const facilitatorRows = this.$refs[refName];

      if (Array.isArray(facilitatorRows)) {
        facilitatorRows.forEach(row => {
          if (row.onClickPauseRoom) {
            row.onClickPauseRoom();
          }
        });
      } else {
        if (facilitatorRows && facilitatorRows.onClickPauseRoom) {
          facilitatorRows.onClickPauseRoom();
        }
      }
    },
    onUnPauseEvent (event) {
      const refName = 'facilitatorRows_' + event.id;
      const facilitatorRows = this.$refs[refName];

      if (Array.isArray(facilitatorRows)) {
        facilitatorRows.forEach(row => {
          if (row.onClickUnPauseRoom) {
            row.onClickUnPauseRoom();
          }
        });
      } else {
        if (facilitatorRows && facilitatorRows.onClickUnPauseRoom) {
          facilitatorRows.onClickUnPauseRoom();
        }
      }
    },
    onEndSession ({ room }) {
      console.log(room);
      if (!confirm(`Are you sure you want to end the session for room #${room.id}? All players will be kicked out`)) {
        return;
      }

      this.$apiClient.facilitator.endRoomSession(room.id).then(() => {
        for (const _room of this.rooms) {
          if (_room.id !== room.id) {
            continue;
          }

          _room.liveSession = null;
        }
      });
    },
    onSpectate ({ room }) {
      console.log('SPECTATE ROOM!', room);

      this.$store.commit('playerType', null);

      this.$apiClient.jee.getJeeData().then((jeeCategories) => {
        console.log('jeeCategories', jeeCategories);

        this.$store.commit('jeeCategories', jeeCategories);
      }).then(() => {
        return this.$apiClient.toolbox.getToolboxData().then((toolboxCategories) => {
          console.log('toolboxCategories', toolboxCategories);

          this.$store.commit('toolboxCategories', toolboxCategories);
        });
      }).then(() => {
        return this.$apiClient.areaOfExpertise.getAreaOfExpertiseData().then((areasOfExpertise) => {
          console.log('areasOfExpertise', areasOfExpertise);

          this.$store.commit('areasOfExpertise', areasOfExpertise);
        });
      }).then(() => {
        return this.$apiClient.role.getRoleData().then((roles) => {
          console.log('roles', roles);

          this.$store.commit('roles', roles);
        });
      }).then(() => {
        return this.$gameClient.joinByCustomId('game', room.uid, true, {
          playerType: 'facilitator',
          playerCode: this.$store.getters.facilitatorCode,
          muted: true,
        });
      }).then(() => {
        this.$store.commit('playerType', 'facilitator');
        this.$router.push('/game');
      });
    },
    onClickAddEvent () {
      this.$router.push({ name: 'EventCreate' });
    }
  },
};
</script>

<style lang="scss" scoped>

.title-line {
  display: flex;

  .btn {
    margin-left: 1em;
    height: 2.2rem;
    padding: .4em 1em;
  }

}

.event {
  margin-bottom: 1em;
}

.live-slider {
  margin-left: auto;
}

.room-table {
  .td-player-list {
    ul {
      text-align: left;
      margin: 0;
    }
  }
}

h1, h2, h3, h4, h5, p, div {
  text-align: left;
}
</style>
