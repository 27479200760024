<template>
  <div class="container-fluid">
    <div class="row">
      <div style="text-align: left; width: 400px; margin: 0 auto">
        <label class="form-label">Score ({{ score }})</label>
        <input class="form-control" type="range" min="0" max="100" v-model.number="score"/>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <capacity-level :capacity-level="capacityLevel" />
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import RoleProgressBall from '@/components/character/RoleProgressBall.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import selectifyUtils, { selectifyArray } from '@/utils/selectifyUtils'
import CapacityLevel from '@/components/dashboard/CapacityLevel.vue'
import capacityLevel from '@/components/dashboard/CapacityLevel.vue'

export default {
  name: 'CapacityLevelDev',
  components: {
    CapacityLevel,
  },
  extends: DevComponentContainer,

  data () {
    return {
      score: 0,
    }
  },
  computed: {
    capacityLevel() {
      return Math.max(1, Math.min(5, Math.floor(this.score / 20) + 1));
    }
  }
}
</script>
