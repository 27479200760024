<template>
  <hazard-popup-container size="sm" padding="sm" :content-background="true" class="end-game-popup-intro-popup">
    <template v-slot:header>
      {{ $t('end.finalDecisionTitle') }}
    </template>
    <template v-slot:content>
      <div class="text-content end-game-popup-intro-content">
        <h2>{{ $t('end.finalDecisionHeader') }}</h2>

        <p>{{ $t('end.finalDecisionBody') }}</p>
      </div>
    </template>
    <template v-slot:buttons>
      <app-button width="lg" @click="onClickStart" color="green">Start</app-button>
    </template>
  </hazard-popup-container>
</template>

<script>
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'EndGamePopupIntro',
  components: {
    AppButton,
    HazardPopupContainer,
  },
  emits: ['click:start'],
  methods: {
    onClickStart() {
      this.$emit('click:start');
    },
  },
}
</script>

<style lang="scss" scoped>
.text-content {
  padding: 20px 0;

  h2 {
    font-size: 40pt;
  }

  p {
    font-size: 18pt;
  }
}
</style>

<style lang="scss">
.end-game-popup-intro-popup {
  .hazard-popup-body-inner {
    height: fit-content;
    margin-top: 150px;
    margin-bottom: 70px;
  }
}
</style>
