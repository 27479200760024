export default {
  'HazardMeeting': {
    'identifier': 'HazardMeeting',
    'label': 'Discuss which investments need to be made',
    'buttonText': 'End',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingStarting': {
    'identifier': 'HazardMeetingStarting',
    'label': 'Starting hazard meeting',
    'buttonText': 'Start',
    'duration': 60000,
    'hidden': false,
  },
  'HazardMeetingPrioritization': {
    'identifier': 'HazardMeetingPrioritization',
    'label': 'Preparing for hazard meeting',
    'buttonText': 'Start',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingEnding': {
    'identifier': 'HazardMeetingEnding',
    'label': 'Hazard meeting results',
    'buttonText': 'Next',
    'duration': 60000,
    'hidden': false,
  },
  'StrategicMeeting': {
    'identifier': 'StrategicMeeting',
    'label': 'Strategic meeting',
    'buttonText': 'End',
    'duration': 300000,
    'hidden': false,
  },
  'StrategicMeetingResults': {
    'identifier': 'StrategicMeetingResults',
    'label': 'Results',
    'buttonText': 'End',
    'duration': 60000,
    'hidden': false,
  },
  'EpiReport': {
    'identifier': 'EpiReport',
    'label': 'EPI Report',
    'buttonText': 'End',
    'duration': 60000,
    'hidden': false,
  },
  'EndGameDecision': {
    'identifier': 'EndGameDecision',
    'label': 'Time to make a decision',
    'buttonText': 'Make decision',
    'duration': 300000,
    'hidden': false,
  },
  'EndGameDecisionAgain': {
    'identifier': 'EndGameDecisionAgain',
    'label': 'Time to make a decision',
    'buttonText': 'Make decision',
    'duration': 120000,
    'hidden': false,
  },
  'RoleSelectionStartGame': {
    'identifier': 'RoleSelectionStartGame',
    'label': 'Pick a character and a name',
    'buttonText': 'Start',
    'duration': 300000,
    'hidden': false,
  },
  'Annex2': {
    'identifier': 'Annex2',
    'label': 'Fill out annex 2',
    'buttonText': 'Continue',
    'duration': 120000,
    'hidden': false,
  },
  'StarMinigameIntro': {
    'identifier': 'StarMinigameIntro',
    'label': 'The STAR minigame is about to start',
    'buttonText': 'Continue',
    'duration': 60000,
    'hidden': true,
  },
  'StarMinigameForcedDocument': {
    'identifier': 'StarMinigameForcedDocument',
    'label': 'Read the document',
    'buttonText': 'Continue',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameOverview': {
    'identifier': 'StarMinigameOverview',
    'label': 'Vote for a hazard',
    'buttonText': 'Continue',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameTutorialFrequency': {
    'identifier': 'StarMinigameTutorialFrequency',
    'label': 'Learn about frequency',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialSeasonality': {
    'identifier': 'StarMinigameTutorialSeasonality',
    'label': 'Learn about seasonality',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialLikelihood': {
    'identifier': 'StarMinigameTutorialLikelihood',
    'label': 'Learn about likelihood',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialSeverity': {
    'identifier': 'StarMinigameTutorialSeverity',
    'label': 'Learn about severity',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialVulnerability': {
    'identifier': 'StarMinigameTutorialVulnerability',
    'label': 'Learn about vulnerability',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameTutorialCopingCapacity': {
    'identifier': 'StarMinigameTutorialCopingCapacity',
    'label': 'Learn about coping capacity',
    'buttonText': 'Continue',
    'duration': 30000,
    'hidden': false,
  },
  'StarMinigameVoteLikelihood': {
    'identifier': 'StarMinigameVoteLikelihood',
    'label': 'Pick the likelihood',
    'buttonText': 'Continue',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameVoteImpact': {
    'identifier': 'StarMinigameVoteImpact',
    'label': 'Pick the impact',
    'buttonText': 'Continue',
    'duration': 300000,
    'hidden': false,
  },
  'StarMinigameRiskMatrix': {
    'identifier': 'StarMinigameRiskMatrix',
    'label': 'Look at the risk matrix',
    'buttonText': 'Continue',
    'duration': 300000,
    'hidden': false,
  },
  'HazardMeetingNoPrioritizations': {
    'identifier': 'HazardMeetingNoPrioritizations',
    'label': 'Hazard meeting results',
    'buttonText': 'Continue',
    'duration': 60000,
    'hidden': false,
  },
};