<template>
  <lobby-popup-template :style="{ width: '1300px', margin: '0 auto' }" content-class="equal-padding">
    <template v-slot:header>
      {{ $t('end.decisionWarning') }}
    </template>
    <template v-slot:content>
      <div class="decision-feedback">
        {{ $t(endGameDecisionAttempts === 1 ? 'end.decisionNotUnanimous' : 'end.decisionNotUnanimousPenalty') }}
      </div>
      <div class="decision-cards-container">
        <div class="decision-cards d-flex justify-content-around">
          <no-drag-image src="@/assets/debriefing/icon-lg-trust.png"/>
          <no-drag-image src="@/assets/debriefing/icon-lg-health-security.png"/>
          <no-drag-image src="@/assets/debriefing/icon-lg-collaboration.png"/>
          <no-drag-image src="@/assets/debriefing/icon-lg-mg-success.png"/>
        </div>
      </div>
      <div class="decision-footer">
        {{ $t('end.decisionFurtherTime') }};
      </div>
    </template>
    <template v-slot:footer>
      <div class="card-voters d-flex gap-2">
        <div class="card-voter" v-for="n in 5">
          <character-thumbnail
            v-if="playerVotesAll[n - 1]"
            :character-index="playerVotesAll[n - 1].characterIndex"
            size="tiny"
          />
        </div>
      </div>

      <div class="footer-button-container">
        <app-button color="green" @click="onClickAccept" width="xl" height="xl" :state="votedContinue ? 'active' : ''">{{ $t('end.buttonGotIt') }}</app-button>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>

import AppButton from '@/components/common/buttons/AppButton.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import UiTimerReadyCheck from '@/components/player-ui/UiTimer/UiTimerReadyCheck.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'

export default {
  name: 'EndGamePopupDecisionNotUnanimous',
  components: {
    CharacterThumbnail,
    UiTimerReadyCheck,
    NoDragImage,
    LobbyPopupTemplate,
    AppButton,
  },
  props: {
    endGameDecisionAttempts: {
      type: Number,
      default: 0,
      required: false,
    },
    playerVotesAll: {
      type: Array,
      default: [],
      required: false,
    },
    votedContinue: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['click:accept'],
  methods: {
    onClickAccept () {
      console.log('Accept!')
      this.$emit('click:accept')
    }
  }
}
</script>

<style lang="scss" scoped>
.decision-feedback, .decision-footer {
  font-size: 18pt;
  color: white;
  white-space: pre;
}

.decision-cards {
  width: 900px;
  margin: 0 auto;
}

.decision-feedback {
  margin-top: -25px;
  margin-bottom: 25px;
}

.decision-footer {
  margin-top: 25px;
  margin-bottom: -25px;
}

.card-voter {
  width: 47px;
  height: 50px;
  background: rgba(#000000, .34);
  border-radius: 4px;

  .character-thumbnail {
    transform: scale(90%);
    transform-origin: 3px bottom;
  }
}

.footer-button-container {
  margin-bottom: 50px;
}

.card-voters {
  position: absolute;
  z-index: 100;
  bottom: 0;
}
</style>
