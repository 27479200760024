<template>
  <lightbox :background-opacity=".9" background="#000000" :open="true" :push-top-ui="true" class="pause-overlay-lightbox">

    <div>
      <h1>{{ $t('pause.gameCurrentlyPaused') }}</h1>
      <div>
        <app-button color="green" width="xl" height="xl" @click="resumeGame" v-if="isPauser">{{ $t('topUi.buttonResumeGame' )}}</app-button>
      </div>
    </div>
    <!--<div class="mt-4">
      <app-button color="red" width="xl" height="lg" @click="endGame">{{ $t('topUi.buttonEndGame' )}}</app-button>
    </div>-->
  </lightbox>

  <version-container :attach-corner="true" attachment-point="topRight" :display-session-info="true" :x-offset="292" :y-offset="125" />
</template>
<script>
import Lightbox from '@/components/common/Lightbox.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import VersionContainer from '@/components/common/VersionContainer.vue'
import MarkdownText from "@/components/common/MarkdownText/index.vue";
import TutorialOverlay from "@/components/player-ui/TutorialOverlay.vue";

export default {
  name: 'PauseOverlay',
  components: {
    MarkdownText,
    VersionContainer,
    Lightbox,
    AppButton,
  },
  props: {
    isPauser: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    resumeGame() {
      console.info("resumeGame")
      this.$gameClient.roomCallMethod('pauseMode:disable');
    },
    endGame() {
      this.$gameClient.roomCallMethod('facilitator:skipToEnd');
    },
    progressTutorial(tutorialId) {
      this.$emit("progressTutorial", tutorialId)
    }
  },
}
</script>

<style lang="scss">
.pause-overlay-lightbox {
}

</style>
