<template>
  <div>
    <div class="dev-panel">
      <div class="container">
        <div class="row">
          <button class="btn btn-success btn-sm" @click="showDevPanel = !showDevPanel">{{ showDevPanel ? 'Close panel' : 'Open panel' }}</button>
        </div>
        <div class="row mt-4 mb-4" v-if="showDevPanel">
          <label class="form-label">Component</label>
          <select v-model="selectedDevComponent" class="form-select">
            <option value="">
              Select a dev component
            </option>
            <option v-for="devComponentName in devComponentNames" :key="devComponentName"
                    :value="devComponentName"
            >
              {{ devComponentName }}
            </option>
          </select>
        </div>
        <div class="row" :class="'show-dev-panel-' + (showDevPanel ? 'on' : 'off')">
          <div class="container mb-4" ref="settings-panel-container" id="dev-settings-panel">

          </div>
        </div>
      </div>
    </div>
    <div class="pt-2" v-if="loading">
      Loading..
    </div>
    <div :class="'component-theme-' + computedTheme" class="row component-container" v-else>
      <div class="dev-component-container">
        <component :is="selectedDevComponent" v-if="selectedDevComponent" :theme="computedTheme"/>
        <div v-else>
          Select a Dev component in the dropdown above
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatingNumberDev from '@/components/AnimatingNumber/AnimatingNumberDev.vue'
import VoiceDev from '@/components/dev/VoiceDev'
import HazardPopupDev from '@/components/HazardMeeting/HazardPopup/HazardPopupDev.vue'
import LongButtonDev from '@/components/dev/LongButtonDev.vue'
import CharacterThumbnailDev from '@/components/dev/CharacterThumbnailDev.vue'
import CharacterPlateDev from '@/components/dev/CharacterPlateDev.vue'
import RoleProgressBallDev from '@/components/dev/RoleProgressBallDev.vue'
import HazardPopupIntroDev from '@/components/HazardMeeting/HazardPopupIntro/HazardPopupIntroDev.vue'
import HazardPopupSummaryDev from '@/components/HazardMeeting/HazardPopupSummary/HazardPopupSummaryDev.vue'
import FloatingHazardIconDev from '@/components/FloatingHazardIcon/FloatingHazardIconDev.vue'
import MeetingInviteBarDev from '@/components/MeetingInviteBar/MeetingInviteBarDev.vue'
import ToolboxPopupDev from '@/components/toolbox/ToolboxPopupDev';
import DashboardPopupDev from '@/components/dashboard/DashboardPopupDev.vue'
import CapacityLevelDev from '@/components/dashboard/CapacityLevelDev.vue'
import DashboardActionDev from '@/components/dashboard/DashboardActionDev.vue'
import LobbyPopupDev from '@/components/Lobby/LobbyPopupDev';
import UiSliderDev from '@/components/dev/UiSliderDev.vue'
import TeamBalanceDev from '@/components/Lobby/TeamBalanceDev';
import RoleSelectionIconButtonDev from '@/components/Lobby/RoleSelectionIconButtonDev.vue'
import RightPlayerListBarDev from '@/components/player-ui/RightPlayerListBar/RightPlayerListBarDev.vue'
import PlayerAvatarCircleDev from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircleDev.vue'
import UiTimerDev from '@/components/player-ui/UiTimer/UiTimerDev.vue'
import HazardPopupPrioritizationDev
  from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPopupPrioritizationDev.vue'
import HazardPopupResultsDev from '@/components/HazardMeeting/HazardPopupResults/HazardPopupResultsDev.vue'
import HazardPopupFlagManagementDev
  from '@/components/HazardMeeting/HazardPopupFlagManagement/HazardPopupFlagManagementDev.vue'
import TopBarDev from '@/components/player-ui/TopBar/TopBarDev.vue';
import StrategicMeetingIntroDev from '@/components/StrategicMeeting/StrategicMeetingIntro/StrategicMeetingIntroDev.vue'
import StrategicMeetingPopupDev from '@/components/StrategicMeeting/StrategicMeetingPopup/StrategicMeetingPopupDev.vue'
import AppButtonDev from '@/components/dev/AppButtonDev.vue'
import StrategicMeetingActionDev
  from '@/components/StrategicMeeting/StrategicMeetingAction/StrategicMeetingActionDev.vue'
import StrategicMeetingTotalCostDev
  from '@/components/StrategicMeeting/StrategicMeetingTotalCost/StrategicMeetingTotalCostDev.vue'
import AlertPopDev from '@/components/dev/AlertPopDev.vue'
import StrategicMeetingResultsDev
  from '@/components/StrategicMeeting/StrategicMeetingResults/StrategicMeetingResultsDev.vue';
import StrategicMeetingInvestedActionDev
  from '@/components/StrategicMeeting/StrategicMeetingInvestedAction/StrategicMeetingInvestedActionDev.vue';
import EpiReportPopupDev from '@/components/EpiReport/EpiReportPopup/EpiReportPopupDev.vue'
import NotificationAssessmentDev from '@/components/NotificationAssessment/NotificationAssessmentDev.vue'
import MeetingPopupDev from '@/components/MeetingPopup/MeetingPopupDev.vue'
import MeetingResultsPopupDev from '@/components/MeetingResultsPopup/MeetingResultsPopupDev.vue'
import MeetingPopupActionInvestmentDev from '@/components/MeetingPopup/MeetingPopupActionInvestmentDev.vue'
import CostBoxDev from '@/components/dev/CostBoxDev.vue'
import EndGamePopupIntroDev from '@/components/EndGamePopup/EndGamePopupIntro/EndGamePopupIntroDev.vue'
import EndGamePopupDecisionDev from '@/components/EndGamePopup/EndGamePopupDecision/EndGamePopupDecisionDev.vue'
import EndGamePopupFeedbackDev from '@/components/EndGamePopup/EndGamePopupFeedback/EndGamePopupFeedbackDev.vue'
import AlgorithmDev from '@/components/dev/AlgorithmDev.vue';
import DebriefingFinalScoreCardDev
  from '@/components/Debriefing/DebriefingFinalScoreCard/DebriefingFinalScoreCardDev.vue'
import WheelOfEvaluationDev from '@/components/Debriefing/WheelOfEvaluation/WheelOfEvaluationDev.vue'
import ErrorPopupDev from '@/components/ErrorPopup/ErrorPopupDev.vue'
import DebriefingDev from '@/components/Debriefing/DebriefingDev.vue'
import TutorialHighlightOverlayDev from '@/plugins/TutorialHighlightOverlay/TutorialHighlightOverlayDev.vue'
import LeaderboardDev from '@/components/Leaderboard/LeaderboardDev.vue';
import StarMinigameDev from '@/components/StarMinigame/StarMinigameDev.vue'
import TutorialBoxDev from '@/components/TutorialBox/TutorialBoxDev.vue'
import StarMinigameRiskMatrixDev from '@/components/StarMinigame/RiskMatrix/RiskMatrixDev.vue';
import BlingLayerDev from '@/components/bling/BlingLayerDev.vue'
import StarPointsDev from '@/components/StarPoints/StarPointsDev.vue'
import EndGamePopupDecisionNotUnanimousDev
  from '@/components/EndGamePopup/EndGamePopupDecisionNotUnanimous/EndGamePopupDecisionNotUnanimousDev.vue'
import OverlayPopDev from '@/components/dev/OverlayPopDev.vue';

const devComponents = {
  AnimatingNumberDev,
  VoiceDev,
  LongButtonDev,
  AppButtonDev,
  HazardPopupDev,
  CharacterThumbnailDev,
  CharacterPlateDev,
  RoleProgressBallDev,
  HazardPopupIntroDev,
  HazardPopupSummaryDev,
  FloatingHazardIconDev,
  MeetingInviteBarDev,
  ToolboxPopupDev,
  DashboardPopupDev,
  CapacityLevelDev,
  DashboardActionDev,
  LobbyPopupDev,
  UiSliderDev,
  TeamBalanceDev,
  RoleSelectionIconButtonDev,
  RightPlayerListBarDev,
  PlayerAvatarCircleDev,
  UiTimerDev,
  HazardPopupPrioritizationDev,
  HazardPopupResultsDev,
  HazardPopupFlagManagementDev,
  TopBarDev,
  StrategicMeetingIntroDev,
  StrategicMeetingPopupDev,
  StrategicMeetingActionDev,
  StrategicMeetingTotalCostDev,
  StrategicMeetingResultsDev,
  StrategicMeetingInvestedActionDev,
  AlertPopDev,
  EpiReportPopupDev,
  NotificationAssessmentDev,
  MeetingPopupDev,
  MeetingResultsPopupDev,
  MeetingPopupActionInvestmentDev,
  CostBoxDev,
  EndGamePopupIntroDev,
  EndGamePopupDecisionDev,
  EndGamePopupFeedbackDev,
  EndGamePopupDecisionNotUnanimousDev,
  AlgorithmDev,
  DebriefingFinalScoreCardDev,
  WheelOfEvaluationDev,
  ErrorPopupDev,
  DebriefingDev,
  TutorialHighlightOverlayDev,
  TutorialBoxDev,
  LeaderboardDev,
  StarMinigameDev,
  StarMinigameRiskMatrixDev,
  BlingLayerDev,
  StarPointsDev,
  OverlayPopDev,
}

export default {
  name: 'DevView',
  components: devComponents,
  data () {
    return {
      devComponentNames: Object.keys(devComponents),
      selectedDevComponent: '',
      selectedTheme: 'grey',
      loading: true,

      showDevPanel: false,
    }
  },
  computed: {
    currentDevComponent () {
      return this.$router.currentRoute?.value?.params?.component
    },
    computedTheme() {
      if (this.selectedDevComponent === 'StarMinigameDev') {
        return 'full';
      }

      return this.selectedTheme;
    }
  },
  watch: {
    currentDevComponent () {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return
      }

      this.selectedDevComponent = this.currentDevComponent || ''
    },
    selectedDevComponent () {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return
      }

      console.log('Push router', `/dev/${this.selectedDevComponent}`)
      this.$router.push(`/dev/${this.selectedDevComponent}`)
    },
  },
  mounted () {
    this.$store.commit('background', null);

    this.selectedDevComponent = this.currentDevComponent || ''

    this.loading = true

    this.$apiClient.jee.getJeeData().then((jeeCategories) => {
      console.log('jeeCategories', jeeCategories);

      this.$store.commit('jeeCategories', jeeCategories);
    }).then(() => {
      return this.$apiClient.toolbox.getToolboxData().then((toolboxCategories) => {
        console.log('toolboxCategories', toolboxCategories);

        this.$store.commit('toolboxCategories', toolboxCategories);
      });
    }).then(() => {
      return this.$apiClient.areaOfExpertise.getAreaOfExpertiseData().then((areasOfExpertise) => {
        console.log('areasOfExpertise', areasOfExpertise);

        this.$store.commit('areasOfExpertise', areasOfExpertise);
      });
    }).then(() => {
      return this.$apiClient.role.getRoleData().then((roles) => {
        console.log('roles', roles);

        this.$store.commit('roles', roles);
      })
    }).then(() => {
      this.$nextTick(() => {
        this.loading = false
      });
    });
  },
}
</script>

<style lang="scss" scoped>
.component-container {
  border: 1px solid #333333;
  padding: 10px;

  &.component-theme-grey {
    //background: #EDF1F4;
  }

  &.component-theme-blue {
    //background: #45A4CD;
  }

  &.component-theme-white {
    //background: #F8F8F8;
  }

  &.component-theme-full {
    padding: 0 !important;
    border: none;
  }
}

.dev-panel {
  position: absolute;
  z-index: 99999999999;

  bottom: 0;
  left: 0;
  background: white;
  padding: 10px;
  border-right: 5px solid black;
  border-top: 5px solid black;
  border-top-right-radius: 15px;
}
</style>

<style lang="scss">
.dev-panel {

}

.show-dev-panel-off {
  height: 0;
  overflow-y: hidden;
}

.show-dev-panel-on {
  height: fit-content;
}

.dev-component-container {
  overflow: scroll;
  height: 1080px;
}
</style>
