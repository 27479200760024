<template>
  <div class="hazard-popup-container">
    <meeting-popup
      v-if="!meetingEnded"
      meeting-type="hazard"
      :action-categories="actionCategories"
      :hazard-icon="icon"
      :players="players"
      :investments="investments"
      :tab-lookers="tabLookers"
      :can-interact="canInteract"
      @player-investment-change="onPlayerInvestmentChange"
      @looking-at-category-change="onLookingAtCategoryChange"
      @looking-at-tab-change="onLookingAtTabChange"
    />

    <meeting-results-popup
      v-if="meetingEnded"
      :players="players"
      :action-categories="actionCategories"
      :investments="investments"
      meeting-type="hazard"
    />
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import LongButton from '@/components/common/LongButton.vue'
import HazardActionTab from '@/components/HazardMeeting/HazardPopup/HazardActionTab.vue'
import HazardAction from '@/components/HazardMeeting/HazardPopup/HazardAction.vue'
import { mapState } from 'vuex'
import {
  HAZARD_MEETING_STATE_ENDED,
  HAZARD_MEETING_STATE_STARTED,
  HAZARD_MEETING_STATE_STARTING
} from '@/enum/HazardMeetingState'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue'
import { objectToArray } from '@/utils/arrayUtil'

export default {
  name: 'HazardPopup',
  components: {
    MeetingResultsPopup,
    MeetingPopup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    investments: {
      type: Array,
      required: true,
    },
    actionCategories: {
      type: Object,
      required: true,
    },
    players: {
      type: Object,
      required: true,
    },
    tabLookers: {
      type: Object,
      required: true,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    'currentTabIndex:updated',
    'playerInvestment:updated',

    'player-investment-change',
    'looking-at-category-change',
    'looking-at-tab-change',
  ],
  data () {
    return {
    }
  },
  computed: {
    meetingEnded() {
      return false;
    },
  },
  methods: {
     onPlayerInvestmentChange(e) {
      this.$emit('player-investment-change', e);
    },
    onLookingAtCategoryChange(e) {
      this.$emit('looking-at-category-change', e);
    },
    onLookingAtTabChange(e) {
      this.$emit('looking-at-tab-change', e);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
