<template>
  <div class="wheel-of-evaluation">
    <div class="wheel-of-evaluation-arrow" :style="arrowStyle" />
    <div class="wheel-of-evaluation-arrow-anchor" />

    <div class="wheel-of-evaluation-numbers">
      <div class="wheel-of-evaluation-number" v-for="n in 5" :class="'wheel-of-evaluation-number-' + n" >{{ n }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WheelOfEvaluation',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fakeRotation: 0,
    }
  },
  mounted() {
    setInterval(() =>{
      this.fakeRotation += 23;
    }, 50)
  },
  computed: {
    rotation() {
      if (this.computedValue === 1) {
        return 37;
      } else if (this.computedValue === 2) {
        return 108;
      } else if (this.computedValue === 3) {
        return 178;
      } else if (this.computedValue === 4) {
        return 252;
      } else if (this.computedValue === 5) {
        return 316;
      }

      return 0;
    },
    computedValue() {
      return Math.min(5, Math.max(1, Math.round(this.value)));
    },
    arrowStyle() {
      return {
        'transform': `rotate(${this.rotation}deg)`,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wheel-of-evaluation {
  background: url("@/assets/debriefing/wheel-of-evaluation-background.png");

  width: 268px;
  height: 253px;

  position: relative;
}

.wheel-of-evaluation-arrow {
  background: url("@/assets/debriefing/wheel-of-evaluation-arrow.png");

  width: 32px;
  height: 109px;
  transform-origin: 50% 82.5%;
  //transition: ease-in-out 200ms;

  position: absolute;
  top: 38px;
  left: 117px;
}

.wheel-of-evaluation-arrow-anchor {
  background: #486488;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .8;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, .8);
}

.wheel-of-evaluation-numbers {
  .wheel-of-evaluation-number {
    color: white;
    font-weight: bold;
    font-size: 16pt;
    position: absolute;
    text-shadow: 0px 2.37267px 4.74534px rgba(0, 0, 0, 0.6);

    &.wheel-of-evaluation-number-1 {
      top: 24px;
      left: 196px;
    }

    &.wheel-of-evaluation-number-2 {
      top: 147px;
      left: 236px;
    }

    &.wheel-of-evaluation-number-3 {
      top: 222px;
      left: 133px;
    }

    &.wheel-of-evaluation-number-4 {
      top: 147px;
      left: 20px;
    }

    &.wheel-of-evaluation-number-5 {
      top: 33px;
      left: 50px;
    }


  }
}
</style>
