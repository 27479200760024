<template>
  <lightbox :background-opacity=".6" background="#000000" :push-top-ui="true">
    <strategic-meeting-intro-live
      @continue="onIntroContinue"
      v-if="computedState === 'intro'"
      :can-interact="canInteract"
    />

    <meeting-popup
      v-else-if="computedState === 'strategicMeeting'"
      meeting-type="strategic"
      :meeting-number="meetingNumber"
      :action-categories="strategicMeetingActionCategories"
      :investments="investments"
      :tab-lookers="tabLookers"
      :players="players"
      @action-removed="onActionRemoved"
      @action-added="onActionAdded"
      @looking-at-category-change="onLookingAtCategoryChange"
      @looking-at-tab-change="onLookingAtTabChange"
      :can-interact="canInteract"
    />

    <meeting-results-popup
      v-else-if="computedState === 'results'"
      meeting-type="strategic"
      :meeting-number="meetingNumber"
      :players="players"
      :action-categories="strategicMeetingActionCategories"
      :investments="investments"
      :can-interact="canInteract"
    />
  </lightbox>
</template>
<script>
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import Lightbox from '@/components/common/Lightbox.vue';
import {mapGetters, mapState} from 'vuex';
import {ensureArray, objectToArray} from '@/utils/arrayUtil'
import {MEETING_STATE_STRATEGIC_MEETING, MEETING_STATE_STRATEGIC_MEETING_RESULTS} from '@/enum/MeetingState';
import StrategicMeetingResults from '@/components/StrategicMeeting/StrategicMeetingResults/index.vue';
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue'
import StarPoints from '@/components/StarPoints/index.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';
import StrategicMeetingIntroLive
  from '@/components/StrategicMeeting/StrategicMeetingIntro/StrategicMeetingIntroLive.vue';

export default {
  name: 'StrategicMeetingLive',
  components: {
    StrategicMeetingIntroLive,
    StarPoints,
    MeetingResultsPopup,
    MeetingPopup,
    StrategicMeetingResults,
    Lightbox,
    StrategicMeetingPopup,
    StrategicMeetingIntro,
  },
  mounted() {
    this.state = 'intro';

    this.checkStateResults();
  },
  watch: {
    computedState() {
      this.checkStateResults();
    }
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerType', 'playerIsFacilitator']),
    canInteract() {
      return !this.playerIsFacilitator;
    },
    meetingNumber() {
      if (!this.room || !this.room.meetingNumber) {
        return 0;
      }

      return this.room.meetingNumber;
    },
    players() {
      if (!this.room || !this.room.players) {
        return {};
      }

      return this.room.players;
    },
    tabLookers() {
      if (!this.players) {
        return {};
      }

      const tabLookers = {};
      const tabLookerIds = [];

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.meetingLookingAtTabIdentifier) {
          const tabIdentifier = player.meetingLookingAtTabIdentifier;

          if (!tabLookerIds.includes(tabIdentifier)) {
            tabLookerIds.push(tabIdentifier);
            tabLookers[tabIdentifier] = [];
          }

          tabLookers[tabIdentifier].push(player);
        }
      }

      return tabLookers;
    },
    activeStrategicMeeting() {
      if (!this.room) {
        return null;
      }

      const activeStrategicMeeting = this.room.activeStrategicMeeting;

      if (!activeStrategicMeeting) {
        return null;
      }

      return activeStrategicMeeting;
    },
    investments() {
      const activeStrategicMeeting = this.activeStrategicMeeting;

      if (!activeStrategicMeeting) {
        return [];
      }

      if (!activeStrategicMeeting.investments) {
        return [];
      }

      const investments = objectToArray(activeStrategicMeeting.investments);

      const parsedInvestments = [];

      for (const investment of investments) {
        console.log('investment', investment);

        parsedInvestments.push({
          actionIdentifier: investment.action.identifier,
          playerCode: investment.addedByPlayerCode,
          investment: investment.action.cost, // In strategic meetings you are the sole investor

          scoreImpact: {
            mgEventSuccess: investment.action.impactMgEventSuccess || 0,
            healthSecurity: investment.action.impactHealthSecurity || 0,
            collaboration: investment.action.impactCollaboration || 0,
            trust: investment.action.impactTrust || 0,
          }
        });
      }

      console.log('parsedInvestments', parsedInvestments);

      return parsedInvestments;
    },
    strategicMeetingActionCategories() {
      const activeStrategicMeeting = this.activeStrategicMeeting;

      if (!activeStrategicMeeting) {
        return [];
      }

      if (!activeStrategicMeeting.categories) {
        return [];
      }

      const categories = ensureArray(activeStrategicMeeting.categories);

      for (const category of categories) {
        category.tabs = ensureArray(category.tabs);

        for (const tab of category.tabs) {
          tab.actions = ensureArray(tab.actions);
        }
      }

      return categories;
    },
    computedState() {
      if (this.room) {
        if (this.room.meetingState === MEETING_STATE_STRATEGIC_MEETING_RESULTS) {
          return 'results';
        } else if (this.room.meetingState === MEETING_STATE_STRATEGIC_MEETING) {
          //return 'strategicMeeting'
        }
      }

      if (this.playerIsFacilitator) {
        if (this.state === 'intro') {
          return 'strategicMeeting'; // Don't show intro for facilitator
        }
      }

      return this.state;
    },
  },
  methods: {
    checkStateResults() {
      if (this.computedState === 'results') {
        const scoreImpacts = {
          trust: 0,
          healthSecurity: 0,
          collaboration: 0,
          mgEventSuccess: 0,
        };

        for (const investment of this.investments) {
          if (!investment.scoreImpact || investment.scoreImpact.length <= 0) {
            continue;
          }

          for (const impactKey in investment.scoreImpact) {
            scoreImpacts[impactKey] += investment.scoreImpact[impactKey] / 10;
          }
        }

        this.$store.commit('starScoreImpact', scoreImpacts);
      }
    },
    onLookingAtCategoryChange(lookingCategory) {
      if (!lookingCategory) {
        console.warn(`Could not change the looking category since it was NULL`, lookingCategory);

        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('meetingLookingAtCategoryIdentifier', lookingCategory.identifier);
    },
    onLookingAtTabChange(lookingTab) {
      if (!lookingTab) {
        console.warn(`Could not change the looking tab since it was NULL`, lookingTab);

        return;
      }

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('meetingLookingAtTabIdentifier', lookingTab.identifier);
    },
    onIntroContinue() {
      this.state = 'strategicMeeting';
    },
    onStrategicMeetingContinue() {
      this.$emit('close');
    },

    onActionAdded(action) {
      console.log('StrategicMeetingLive.onActionAdded', action);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.roomCallMethod('addStrategicActionInvestment', action.id).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
    onActionRemoved(action) {
      console.log('StrategicMeetingLive.onActionRemoved', action);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.roomCallMethod('removeStrategicActionInvestment', action.id).catch((e) => {
        this.$store.commit('errorMessage', e);
      });
    },
  },
  data() {
    return {
      state: 'intro',
    };
  }
}
</script>

<style lang="scss" scoped>
.meeting-popup {
  width: 1350px;
  height: 960px;
  padding-top: 0px;
}
</style>
