<template>
  <hazard-popup-prioritization :hazard-datas="hazardDatas"
                               :player-prioritizations="playerPrioritizations"
  />
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import HazardIcon from '@/components/common/HazardIcon.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardPrioritizationItem from '@/components/HazardMeeting/HazardPopupPrioritization/HazardPrioritizationItem.vue'
import { mapGetters, mapState } from 'vuex'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue'
import { generatePlayerData } from '@/utils/playerDataGenerator'
import { objectToArray } from '@/utils/arrayUtil'

export default {
  name: 'HazardPrioritizationPersonalLive',
  components: { HazardPopupPrioritization },
  computed: {
    ...mapGetters(['playerSelf', 'room']),

    hazardDatas() {
      return [];
    },

    playerPrioritizations() {
      //for (const )
      const hazardPrioritizationCount = {};

      const worldHazardFlags = objectToArray(this.playerSelf.worldHazardFlags);

      for (const worldHazardFlag of worldHazardFlags) {
        const hazardIdString = worldHazardFlag.hazardId.toString();

        if (!hazardPrioritizationCount[hazardIdString]) {
          hazardPrioritizationCount[hazardIdString] = 0;
        }

        hazardPrioritizationCount[hazardIdString] += worldHazardFlag.amount;
      }

      return {
        player: this.playerSelf,
        hazardPrioritizationCount: hazardPrioritizationCount,
      };
    },
  },
}
</script>
