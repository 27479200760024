export function ensureDate(date) {
  if (date instanceof Date) {
    return date;
  }

  return new Date(date);
}

export function formatDate(date) {
  date = ensureDate(date);

  let month = '' + (date.getMonth() + 1); // Month starts from 0
  let day = '' + date.getDate();

  const year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;

  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function formatTime(date) {
  date = ensureDate(date);

  let hour = '' + date.getHours();
  let minutes = '' + date.getMinutes();
  let seconds = '' + date.getSeconds();

  if (hour.length < 2) {
    hour = '0' + hour;
  }

  if (minutes.length < 2) {
    minutes = '0' + minutes;
  }

  if (seconds.length < 2) {
    seconds = '0' + seconds;
  }

  return [hour, minutes, seconds].join(':');
}

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
const SECONDS_PER_WEEK = SECONDS_PER_DAY * 7;
const SECONDS_PER_MONTH = SECONDS_PER_DAY * 30;
const SECONDS_PER_YEAR = SECONDS_PER_DAY * 365.25;

export function formatTimeAgo(date) {
  date = ensureDate(date);

  const now = new Date();
  const secondsPast = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (secondsPast < SECONDS_PER_MINUTE) {
    return 'very recently';
  }

  if (secondsPast < SECONDS_PER_HOUR) {
    return `${Math.floor(secondsPast / SECONDS_PER_MINUTE)} minutes ago`;
  }

  if (secondsPast <= SECONDS_PER_DAY) {
    return `${Math.floor(secondsPast / SECONDS_PER_HOUR)} hours ago`;
  }

  if (secondsPast <= SECONDS_PER_WEEK) {
    return `${Math.floor(secondsPast / SECONDS_PER_DAY)} days ago`;
  }

  if (secondsPast <= SECONDS_PER_MONTH) {
    return `${Math.floor(secondsPast / SECONDS_PER_WEEK)} weeks ago`;
  }

  if (secondsPast <= SECONDS_PER_YEAR) {
    return `${Math.floor(secondsPast / SECONDS_PER_MONTH)} months ago`;
  }

  return `${Math.floor(secondsPast / SECONDS_PER_YEAR)} years ago`;
}

export function formatDateTime(date) {
  date = ensureDate(date);

  return formatDate(date) + ' ' + formatTime(date);
}
