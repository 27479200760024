<template>
  <hazard-popup-container size="sm" padding="lg" :center-content="true" :content-background="true">
    <template v-slot:header>
      <no-drag-image src="@/assets/icons/icon-calendar-large.png" />
      <h1>{{ $t('meetingPopup.strategicMeetingHeader') }}</h1>
    </template>

    <template v-slot:content>
      <p class="main-text">{{ $t('meetingPopup.invitationMessage') }}</p>

      <div class="waiting-on-players-container" v-if="showWaitingOnPlayers">
        <waiting-on-players :players="players" :waiting-on-player-codes="waitingOnPlayerCodes" />
      </div>
    </template>

    <template v-slot:buttons>
      <app-button color="green" @click="joinMeeting" :disabled="!canStartStrategicMeeting" width="fit-content" height="lg">{{ $t('meetingPopup.buttonJoinMeeting') }}</app-button>
    </template>
  </hazard-popup-container>
</template>
<script>
import LongButton from '@/components/common/LongButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import WaitingOnPlayers from '@/components/WaitingOnPlayers/index.vue';

export default {
  name: 'StrategicMeetingIntro',
  components: {
    WaitingOnPlayers,
    NoDragImage,
    HazardPopupContainer,
    LongButton,
    AppButton,
  },
  props: {
    showWaitingOnPlayers: {
      type: Boolean,
      required: false,
      default: false,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
    waitingOnPlayerCodes: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    players: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  computed: {
    canStartStrategicMeeting() {
      return !this.showWaitingOnPlayers || this.waitingOnPlayerCodes.length === 0;
    }
  },
  emits: ['continue'],
  methods: {
    joinMeeting() {
      if (!this.canInteract) {
        return;
      }

      this.$emit('continue');
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  margin-top: 50px;

  .large-icon-container {
    width: 400px;
  }

  .headline-container {
    h1 {
      text-shadow: 0px 7.4949px 7.4949px rgba(0, 0, 0, 0.25);
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 38px;
    }
  }

  .headline-container, .large-icon-container {
    text-align: center;
  }
}

.waiting-on-players-container {
  position: absolute;
  bottom: -140px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
