<template>
  <div class="row mt-2 woe-container">
    <wheel-of-evaluation :value="value" />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Value</label>
      <input type="range" min="1" max="5" v-model="value" />
    </div>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import WheelOfEvaluation from '@/components/Debriefing/WheelOfEvaluation/index.vue'

export default {
  name: 'WheelOfEvaluationDev',
  components: {
    WheelOfEvaluation,
    DevSettingsPanel,
    ButtonContainer
  },
  extends: DevComponentContainer,

  data() {
    return {
      value: 1,
    }
  }
}
</script>

<style lang="scss" scoped>
.wheel-of-evaluation {
}

.woe-container {
  background: url("@/assets/debriefing/test-background.png") no-repeat;
  width: 100%;
  height: 100%;
  padding: 50px;
}
</style>
