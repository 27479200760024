<template>
  <div class="star-minigame-assessment">
    <div class="star-minigame-table-container d-flex flex-column" ref="table-container">
      <table class="table table-striped table-bordered star-minigame-info-table flex-grow-0">
        <thead ref="table-head">
        <tr class="bg-dark">
          <th colspan="3" class="text-center">{{ $t('starMinigame.row1riskAssessment') }}</th>
          <th colspan="23" class="text-start">{{ $t('starMinigame.row1globalLand') }}</th>
        </tr>
        <tr class="bg-dark">
          <th class="th-subgroupOfHazard"><!-- Subgroup of hazard --></th>
          <th class="th-mainTypeOfHazard"><!-- Main type of hazard --></th>
          <th class="th-specificHazard"><!-- Specific hazard --></th>
          <th colspan="3" class="th-healthConsequences th-scale th-exposure text-center">{{ $t('starMinigame.row2hazardsAndExposures') }}</th>
        </tr>
        <tr class="bg-dark">
          <th class="th-subgroupOfHazard">{{ $t('starMinigame.row3subgroupOfHazard') }}</th>
          <th class="th-mainTypeOfHazard">{{ $t('starMinigame.row3mainTypeOfHazard') }}</th>
          <th class="th-specificHazard">{{ $t('starMinigame.row3specificHazard') }}</th>
          <th class="th-healthConsequences">{{ $t('starMinigame.row3healthConsequences') }}</th>
          <th class="th-scale">{{ $t('starMinigame.row3scale') }}</th>
          <th class="th-exposure">{{ $t('starMinigame.row3exposure') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr ref="origin-table-row" v-if="hazardRow">
          <td class="td-subgroupOfHazard">{{ hazardRow.subGroup }}</td>
          <td class="td-mainTypeOfHazard">{{ hazardRow.mainType }}</td>
          <td class="td-specificHazard">{{ hazardRow.hazardName }}</td>
          <td class="td-healthConsequences">{{ hazardRow.healthConsequences.join(', ') }}</td>
          <td class="td-scale">{{ hazardRow.scale }}</td>
          <td class="td-exposure">{{ hazardRow.exposure }}</td>
        </tr>
        </tbody>
      </table>
      <table class="table table-striped table-bordered star-minigame-frequency-table flex-fill">
        <thead>
        <tr>
          <th class="bg-dark th-narrow">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.FREQUENCY) }}</th>
          <th class="bg-dark" colspan="12">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.SEASONALITY) }}</th>
          <th class="bg-dark th-narrow">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.LIKELIHOOD) }}</th>
        </tr>
        <tr class="row-small">
          <th class="bg-dark th-narrow"><!-- Frequency --></th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_january') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_february') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_march') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_april') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_may') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_june') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_july') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_august') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_september') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_october') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_november') }}</th>
          <th class="bg-dark">{{ $t('starMinigame.row3frequency_december') }}</th>
          <th class="bg-dark th-narrow"><!-- Likelihood --></th>
        </tr>
        </thead>
        <tbody>
        <tr class="tr-likelihood" v-if="likelihoodRow">
          <td class="bg-dark td-narrow td-frequency"><span>{{ getFrequencyLabel(likelihoodRow.frequency) }}</span></td>
          <td class="td-seasonality bg-level-default" v-for="seasonality in likelihoodRow.seasonality" :class="'bg-level-' + seasonality"></td>
          <td class="bg-dark td-narrow td-likelihood"><span>{{ getLikelihoodLabel(likelihoodRow.likelihood) }}</span></td>
        </tr>
        </tbody>
      </table>
      <table class="table table-striped table-bordered star-minigame-severity-table flex-fill">
        <thead>
        <tr>
          <th class="bg-dark">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.SEVERITY) }}</th>
          <th class="bg-dark">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.VULNERABILITY) }}</th>
          <th class="bg-dark">{{ getTutorialHeaderWithOrdinal(TutorialIdentifiers.COPING_CAPACITY) }}</th>
          <th class="th-button-container"><!-- Buttons --></th>
        </tr>
        </thead>
        <tbody>
        <tr class="tr-data-row" v-for="dataRow in impactRows" :class="playerImpactIndex === dataRow.index ? 'tr-data-row-chosen' : ''">
          <td class="td-severity center-text bg-dark" :class="'bg-level-' + getSeverityLevel(dataRow.severity)"><span>{{ getSeverityLabel(dataRow.severity) }}</span></td>
          <td class="td-vulnerability center-text bg-dark" :class="'bg-level-' + getVulnerabilityLevel(dataRow.vulnerability)"><span>{{ getVulnerabilityLabel(dataRow.vulnerability) }}</span></td>
          <td class="td-copingCapacity center-text bg-dark" :class="'bg-level-' + getCopingCapacityLevel(dataRow.copingCapacity)"><span>{{ getCopingCapacityLabel(dataRow.copingCapacity) }}</span></td>
          <td class="td-button-container">
            <span>
              <app-button
                  color="green"
                  :state="playerImpactIndex === dataRow.index ? 'active' : ''"
                  @click="choosePlayerChoice(dataRow.index)"
                  :disabled="isButtonsDisabled"
              >
              {{ playerImpactIndex === dataRow.index ? $t('starMinigame.buttonChosen') : $t('starMinigame.buttonChoose') }}
            </app-button>
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue'
import TutorialBox from '@/components/TutorialBox/index.vue'
import TableMixins from '@/components/StarMinigame/TableMixins.vue'

import { getTutorialHeaderWithOrdinal, TutorialIdentifiers } from '@/components/StarMinigame/tutorial/tutorial-parts'
import { getStarData } from '@/components/StarMinigame/star-utils'
export default {
  name: 'StarMinigameAssessmentImpact',
  mixins: [TableMixins],
  components: {
    TutorialBox,
    AppButton
  },
  props: {
    hazardIndex: {
      type: Number,
      required: true,
    },
    likelihoodIndex: {
      type: Number,
      required: true,
    },
    playerLikelihoodIndex: {
      type: Number,
      required: true,
    },
    playerImpactIndex: {
      type: Number,
      required: false,
      default: -1,
    },
    canInteract: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ['player-choice'],
  data() {
    const StarData = getStarData();

    return {
      StarData,
    }
  },
  methods: {
    getTutorialHeaderWithOrdinal(key) {
      return getTutorialHeaderWithOrdinal(key);
    },
    choosePlayerChoice(playerChoice) {
      if (this.isButtonsDisabled) {
        return;
      }

      this.$emit('player-choice', playerChoice);
    }
  },
  computed: {
    isButtonsDisabled() {
      return !this.canInteract;
    },
    likelihoodRow() {
      if (this.playerLikelihoodIndex < 0) {
        return null;
      }

      return this.hazardRow.likelihoodData[this.playerLikelihoodIndex];
    },
    TutorialIdentifiers () {
      return TutorialIdentifiers
    },
    hazardRow() {
      if (this.hazardIndex < 0) {
        return;
      }

      return this.StarData[this.hazardIndex];
    },
    impactRows() {
      return this.hazardRow.impactData;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/background-colors.scss";

$buttonContainerWidth: 200px;

.star-minigame-assessment,
.star-minigame-table-container {
  height: 100%;
}

.row-small {
  th, td {
    font-size: 10pt;
    padding: 3px 5px;
    text-align: center !important;
  }
}

.tr-data-row {
  border-width: 0 !important;
  margin-bottom: 10px;

  &.tr-data-row-chosen {
    .td-severity,
    .td-vulnerability,
    .td-copingCapacity {
      border-top: 3px solid white;
      border-bottom: 3px solid white;
    }

    .td-severity {
      border-left: 3px solid white;
    }

    .td-copingCapacity {
      border-right: 3px solid white;
    }
  }
}

.star-minigame-info-table,
.star-minigame-frequency-table {
  width: calc(100% - $buttonContainerWidth);
}

.td-button-container, .th-button-container {
  width: $buttonContainerWidth;
  text-align: center;
  border: 0 !important;
  outline: 0 !important;
  background: transparent !important;
}

.star-minigame-severity-table {
  width: 100%;
}

.star-minigame-table-container,
.star-minigame-info-table,
.star-minigame-frequency-table,
.star-minigame-severity-table {
  margin: 0;
}

.star-minigame-info-table {
}

.star-minigame-frequency-table,
.star-minigame-severity-table {
  thead {
    th {
      border: 3px solid white;
    }
  }
}

.th-narrow, .td-narrow {
  white-space: nowrap;
  width: 200px;
}

.td-narrow, .td-button-container, .center-text {
  position: relative;

  span {
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16pt !important;
    font-weight: bold;
    text-align: center !important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.star-minigame-info-table,
.star-minigame-frequency-table,
.star-minigame-severity-table {
  font-size: 14pt;

  th, td {
    color: white;
    text-align: left;
  }

  .descriptor-column, .id-column {
    font-weight: bold;
  }

  tr.bg-dark {
    td, th {
      background: $bgDark !important;
    }
  }

  tr.bg-light {
    td, th {
      background: $bgLight !important;
    }
  }

  tbody {
    tr:nth-child(odd) {
      th, td {
        background: $bgDark;
      }
    }

    tr:nth-child(even) {
      th, td {
        background: $bgLight;
      }
    }
  }

  td {
    color: white;
    font-size: 10pt;
  }
}
</style>
