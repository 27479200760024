<template>
  <div class="meeting-popup-category-tab" @click="onSelectTab" :class="isActive ? 'meeting-popup-category-tab-active' : 'meeting-popup-category-tab-inactive'">
    <strong>{{ tab.title }}</strong>

    <div class="meeting-popup-category-tab-lookers d-flex align-items-stretch flex-nowrap justify-content-between">
      <div class="meeting-popup-category-tab-looker" v-for="playerNumber in 5">
        <character-thumbnail
          v-if="lookers.length >= playerNumber"
          :character-index="lookers[playerNumber - 1].characterIndex"
          size="tiny"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'

export default {
  name: 'MeetingPopupCategory',
  components: { CharacterThumbnail, Accordion },
  props: {
    tab: {
      type: Object,
      required: true,
    },
    lookers: {
      type: Array,
      required: false,
      default: [],
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['select-tab'],
  data() {
    return {
      isOpen: !!this.defaultOpen,
    };
  },
  methods: {
    onSelectTab() {
      this.$emit('select-tab', this.tab);
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-popup-category-tab-lookers {
  background: url("@/assets/textures/texture-20.png"), #C97800;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.8), 0px -2px 9px 1px rgba(0, 0, 0, 0.5), inset -10px -10px 10px 1px rgba(0, 0, 0, 0.56);
  border-radius: 14px;
  height: 70px;
  padding: 6px 12px;
  transition: 100ms ease-out;

  .meeting-popup-category-tab-looker {
    background: rgba(#000000, .34);

    width: 50px;
    height: 50px;
    margin-top: 5px;
    //width: 60px;
    //height: 100%;
  }
}

.meeting-popup-category-tab {
  padding: 0 16px 16px 16px;
  color: white;
  font-size: 16pt;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;

  user-select: none;

  strong {
    z-index: 1000;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  }

  &:hover, &.meeting-popup-category-tab-active {
    text-decoration: underline;

    .meeting-popup-category-tab-lookers {
      filter: drop-shadow(0px 0px 12px #FFFFFF);
    }
  }
}

</style>
