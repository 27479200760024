export function getCapacityLevelFromJeeScore(jeeScore) {
  if (jeeScore >= 80) {
    return 5;
  } else if (jeeScore >= 60) {
    return 4;
  } else if (jeeScore >= 40) {
    return 3;
  } else if (jeeScore >= 20) {
    return 2;
  }

  return 1;
}
