export const LOCAL_STREAM_MUTED_CHANGED = 'localStreamMutedChanged';

export class LocalStreamMutedChangedEvent extends Event {
  stream;

  muted;

  constructor (stream, muted) {
    super(LOCAL_STREAM_MUTED_CHANGED);

    this.stream = stream;
    this.muted = muted;
  }
}
