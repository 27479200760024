<template>
  <div class="debriefing-indicator">
    <h3>{{ name }}</h3>
    <p>{{ description }}</p>

    <capacity-level :capacity-level="capacityLevel" />
  </div>
</template>
<script>
import WheelOfEvaluation from '@/components/Debriefing/WheelOfEvaluation/index.vue'
import CapacityLevel from '@/components/dashboard/CapacityLevel.vue';
import {getCapacityLevelFromJeeScore} from '@/components/dashboard/CapacityLevelUtil';

export default {
  name: 'DebriefingIndicator',
  components: {CapacityLevel, WheelOfEvaluation },
  props: {
    identifier: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    jeeScores: {
      type: Object,
      required: true,
    },
  },
  computed: {
    capacityLevel() {
      return getCapacityLevelFromJeeScore(this.jeeScores[this.identifier]);
    }
  }
}
</script>

<style lang="scss" scoped>
.debriefing-indicator {
  background: rgba(#00255A, .5);
  padding: 10px;
  width: 350px;
}

h3, p {
  font-size: 1.2em;
  text-align: left;
}

h3 {
  text-transform: uppercase;
  font-weight: bold;
}
p {
  min-height: 150px;
}
</style>
