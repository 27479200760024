<template>

  <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark sidebar">

  <img class="logo" src="@/assets/lobby/logo.png">

  <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
    <span class="fs-4"></span>
  </a>
  <hr>
  <ul v-if="signedInUser" class="nav nav-pills flex-column mb-auto">
    <li v-if="isAdmin" class="nav-item">
      <router-link class="rounded text-white nav-link" :to="{ name: 'UserIndex' }">
        User List
      </router-link>
    </li>
    <li v-if="isAdmin" class="nav-item">
      <router-link class="rounded text-white nav-link" :to="{ name: 'UserCreate' }">
        Create User
      </router-link>
    </li>
    <hr v-if="isAdmin" />
    <li class="nav-item">
      <router-link class="rounded text-white nav-link"  :to="{ name: 'EventIndex' }">
        Events
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="rounded text-white nav-link" :to="{ name: 'RoomIndex' }">
        Room List
      </router-link>
    </li>
    <li v-if="isAdmin" class="nav-item">
      <router-link class="rounded text-white nav-link" :to="{ name: 'RoomCreate' }">
        Create Room
      </router-link>
    </li>
    <hr />
  </ul>
  <hr v-if="signedInUser" />
  <div v-if="signedInUser">
    <a class="btn d-flex align-items-center text-white text-decoration-none">
      <strong>{{ signedInUser.username }}</strong>
    </a>
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link class="rounded text-white nav-link" :to="{ name: 'UserUpdate', params: { id: signedInUser.id } }">
          Edit User
        </router-link>
      </li>
      <li class="nav-item">
        <button class="rounded text-white nav-link" @click="signOut">Sign Out</button>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Sidebar',
  computed: {
    ...mapState(['signedInUser']),
    username(){
      return this.signedInUser?.username;
    },
    userId(){
      return this.signedInUser?.id;
    },
    isAdmin(){
      return this.signedInUser.role === 'admin' || this.signedInUser.role === 'rootadmin';
    }
  },
  mounted(){
    this.$store.commit('background', 'backend');
  },
  methods:{
    signOut(){
      console.log("Sign Out");

      sessionStorage.removeItem('jwtToken')
      this.$store.commit('signedInUser', null);
      this.$apiClient.setDefaultHeader('authorization', null);

      this.$router.push({ name: 'BackendIndex' });
    }
  }
}
</script>

<style lang="scss">
.sidebar{
  .logo{
    width:100%;
    margin: 0 auto;
  }

  text-align: left;
  box-shadow: .5em .5em 1em rgba(0, 0, 0, 0.5);
  width: 280px;
  float:left;
  height: 100%;
}
</style>
