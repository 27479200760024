export default [
  {
    'virus': 'virus.0',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        17,
        0,
      ],
      [
        20,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        20,
        0,
      ],
      [
        0,
        0,
      ],
      [
        30,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.1',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        2,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        33,
        3,
      ],
      [
        19,
        1,
      ],
      [
        12,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.2',
    'data': [
      [
        1,
        0,
      ],
      [
        13,
        0,
      ],
      [
        4,
        0,
      ],
      [
        23,
        0,
      ],
      [
        40,
        0,
      ],
      [
        170,
        0,
      ],
      [
        179,
        2,
      ],
      [
        211,
        2,
      ],
      [
        200,
        0,
      ],
      [
        181,
        3,
      ],
      [
        178,
        1,
      ],
      [
        51,
        0,
      ],
      [
        35,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.3',
    'data': [
      [
        95,
        2,
      ],
      [
        41,
        11,
      ],
      [
        29,
        2,
      ],
      [
        33,
        2,
      ],
      [
        41,
        1,
      ],
      [
        349,
        4,
      ],
      [
        471,
        4,
      ],
      [
        1288,
        17,
      ],
      [
        895,
        12,
      ],
      [
        533,
        15,
      ],
      [
        200,
        9,
      ],
      [
        165,
        8,
      ],
      [
        152,
        3,
      ],
      [
        34,
        1,
      ],
      [
        27,
        1,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.4',
    'data': [
      [
        7,
        0,
      ],
      [
        10,
        1,
      ],
      [
        13,
        0,
      ],
      [
        17,
        1,
      ],
      [
        4,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        4,
        0,
      ],
      [
        17,
        1,
      ],
      [
        23,
        1,
      ],
      [
        13,
        0,
      ],
      [
        0,
        0,
      ],
      [
        5,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.5',
    'data': [
      [
        14,
        0,
      ],
      [
        52,
        0,
      ],
      [
        71,
        3,
      ],
      [
        20,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.6',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.7',
    'data': [
      [
        0,
        0,
      ],
      [
        3,
        0,
      ],
      [
        5,
        0,
      ],
      [
        5,
        0,
      ],
      [
        19,
        0,
      ],
      [
        71,
        3,
      ],
      [
        39,
        1,
      ],
      [
        21,
        0,
      ],
      [
        9,
        1,
      ],
      [
        10,
        0,
      ],
      [
        6,
        0,
      ],
      [
        7,
        0,
      ],
      [
        5,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.8',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.9',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.10',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        3,
        0,
      ],
      [
        1,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        3,
        1,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.11',
    'data': [
      [
        4,
        0,
      ],
      [
        3,
        0,
      ],
      [
        5,
        0,
      ],
      [
        12,
        0,
      ],
      [
        9,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        2,
        0,
      ],
      [
        1,
        0,
      ],
      [
        6,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.12',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        10,
        0,
      ],
      [
        10,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        10,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
    ],
    'startingMonth': 1,
    'nameChanges': [

    ],
  },
  {
    'virus': 'virus.13',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        1,
        0,
      ],
      [
        4,
        1,
      ],
      [
        29,
        9,
      ],
      [
        31,
        3,
      ],
      [
        57,
        1,
      ],
      [
        119,
        9,
      ],
      [
        311,
        2,
      ],
      [
        1560,
        11,
      ],
    ],
    'startingMonth': 10,
    'nameChanges': [

    ],
  },
  {
    'virus': 'shipOutbreak',
    'data': [
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        0,
        0,
      ],
      [
        17,
        0,
      ],
      [
        89,
        0,
      ],
      [
        340,
        3,
      ],
    ],
    'startingMonth': 13,
    'nameChanges': [
      {
        'month': 14,
        'newName': 'novovirus',
      },
    ],
  },
];