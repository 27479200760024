<template>
  <div class="stat-card" :title="labelText">
    <div class="stat-card-content d-flex align-content-stretch justify-content-between">
      <div class="icon-container" v-if="icon">
        <img class="icon" :src="icon" v-if="icon" />
      </div>
      <div class="slot-container flex-fill">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    icon: {
      type: String,
      required: false,
    },
    labelText: {
      type: String,
      required: true,
    },
  }
}
</script>
<style scoped lang="scss">
.stat-card {
  position: relative;
  //background: rgba(0, 0, 0, 0.6);

  min-width: 214px;
  height: 70px;
  margin-top: 13px;
  border-radius: 10px;
  margin-right: 1rem;
  z-index: 1;
  padding: 8px 5px;

  &:before {
    background: url("@/assets/textures/wall-seamless-20-percent.png"), linear-gradient(180deg, #38708F 0%, #051224 84.07%);
    opacity: 0.7;
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    border-radius: 5px;
    transform: rotate(-180deg);
    z-index: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.stat-card-content {
  background: url("@/assets/textures/wall-seamless-20-percent.png"), linear-gradient(180deg, #38708F 0%, #051224 84.07%);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.52), inset 0px 1px 1px rgba(255, 255, 255, 0.5), inset 0px -3px 2px rgba(0, 0, 0, 0.51);
  border-radius: 2px;
  padding: 6px;
}

.icon-container {
  background: rgba(#0C2E3F, .6);
  z-index: 1;
  padding: 2px;
}
</style>
