<template>
  <div class="bling-layer bling-layer-bottom">
    <div class="bling-layer-component bling-layer-component-waves" v-if="enableWaves" />
    <div class="bling-layer-component bling-layer-component-stream" v-if="enableStream" />
    <div class="bling-layer-component bling-layer-component-smoke" v-if="enableSmoke" />
  </div>
</template>

<script>
import { randomBetween } from '@/utils/randomUtil'
import BlingLayer from '@/components/bling/BlingLayer.vue'

export default {
  name: 'BlingLayerBottom',
  extends: BlingLayer,
  props: {
    enableWaves: {
      type: Boolean,
      default: true,
      required: false,
    },
    enableStream: {
      type: Boolean,
      default: true,
      required: false,
    },
    enableSmoke: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./style.scss";

.bling-layer-component {
  &-stream {
    position: absolute;
    top: 44.9%;
    left: 44.5%;
    width: 794px;
    height: 571px;

    background: url("@/assets/bling/riverTest1.gif");
  }

  &-waves {
    position: absolute;
    top: 78.2%;
    left: 38.1%;
    width: 1366px;
    height: 273px;

    background: url("@/assets/bling/wavesTest1.gif");
  }

  &-smoke {
    position: absolute;
    top: 71.9%;
    left: 72.3%;
    width: 60px;
    height: 40px;

    background: url("@/assets/bling/smokeTest1.gif");
  }
}
</style>
