<template>
  <meeting-invite-bar
    :icon="icon"
    :seconds-left="16"
    @click:join-now="onClickJoinNow"
    @click:decline="onClickDecline"
  />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import MeetingInviteBar from '@/components/MeetingInviteBar/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'

export default {
  name: 'MeetingInviteBarDev',
  components: {
    MeetingInviteBar,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['joinNow', 'decline'],

      icon: 'environmental',
    }
  },
  methods: {
    onClickJoinNow() {
      this.triggerEvent('joinNow')
    },
    onClickDecline() {
      this.triggerEvent('decline');
    },
  }
}
</script>

<style lang="scss" scoped>
  .meeting-invite-bar {
    margin: 0 auto;
  }
</style>
