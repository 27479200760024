import { timeoutPromise } from '@/utils/promiseUtil'
import { OverlayPopEvent } from '@/plugins/OverlayPop/OverlayPopEvent'
import { OverlayCancelEvent } from '@/plugins/OverlayPop/OverlayCancelEvent'

export class OverlayPopController extends EventTarget {
  pop(title, subText, timeout, callbacks = {}, fadeSpeed = 250) {
    if (callbacks) {
      for (const callbackKey in callbacks) {
        this.addEventListener(callbackKey, callbacks[callbackKey], {
          once: true,
        });
      }
    }

    return new Promise((resolve, reject) => {
      this.addEventListener('end', () => {
        resolve();
      }, {
        once: true,
      });

      this.dispatchEvent(new OverlayPopEvent(title, subText, timeout, fadeSpeed));
    });
  }

  cancel() {
    this.dispatchEvent(new OverlayCancelEvent());
  }
}
