<template>
  <div class="volume-slider">
    <!--<ui-slider
      :min="0"
      :max="100"
      v-model.number="modelValueInner"
    />-->
    <div class="volume-slider-current-volume">
      <div class="volume-slider-current-volume-bar" :style="{ width: currentVolume + '%' }" />
    </div>
  </div>
</template>

<script>
import slider from "vue3-slider"
import UiSlider from '@/components/player-ui/UiSlider.vue'

export default {
  name: 'VolumeSlider',
  props: {
    modelValue: {
      type: Number,
      default: 0,
      required: false,
    },
    min: {
      type: Number,
      default: null,
      required: false,
    },
    max: {
      type: Number,
      default: null,
      required: false,
    },
    currentVolume: {
      type: Number,
      default: 0,
      required: false,
    }
  },
  components: {
    UiSlider,
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      this.modelValueInner = this.modelValue;
    },
    modelValueInner() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      this.$emit('update:modelValue', this.modelValueInner);
    },
  },
  computed: {
    minNumber() {
      return this.min !== null ? parseFloat(this.min) : null;
    },
    maxNumber() {
      return this.max !== null ? parseFloat(this.max) : null;
    },
  },
  data() {
    return {
      modelValueInner: this.modelValue,
    }
  },
  emits: ['update:modelValue'],

}
</script>
<style lang="scss">
.volume-slider {
  width: 100%;
}

.volume-slider-current-volume {
  margin-top: 13px;
  background: #D9D9D9;
  border-radius: 2px;
  height: 5px;
  width: 100%;
  overflow: hidden;

  .volume-slider-current-volume-bar {
    background: linear-gradient(180deg, rgba(#38708F, .4) 0%, rgba(#051224, .4) 84.07%), #D9D9D9 !important;
    transition: 10ms ease;
    height: 100%;
  }
}
</style>
