<template>
  <lobby-popup-template>
    <template v-slot:header>
      <div :class="allLettersActivated ? 'all-letters-activated' : ''">
        {{ $t('lobby.lobbyHeader') }}
      </div>
    </template>
    <template v-slot:content>
      <div class="mic-container mic-container-error" v-if="voiceEnabled && micError">
        <strong>Could not connect to voice server:</strong> {{ micError }}
      </div>
      <div class="mic-container d-flex" v-else-if="voiceEnabled && showMicInfo">
        <div class="dark-bg">
          <div class="d-flex large-mic-icon-flex">
            <div class="large-mic-icon">
              <no-drag-image src="@/assets/icons/icon-microphone-normal.png"/>
            </div>
            <div class="voice-device-selector">
              <voice-controller-device-selector />
            </div>
          </div>
        </div>
        <div class="settings-panel">
          <div class="settings-panel-single settings-panel-output-volume">
            <div class="dark-bg d-flex">
              <no-drag-image src="@/assets/icons/icon-speaker-small.png" class="me-2"/>
              <volume-slider-output class="ms-2" />
            </div>
          </div>
          <div class="settings-panel-single settings-panel-input-volume">
            <div class="dark-bg d-flex">
              <no-drag-image src="@/assets/icons/icon-microphone-normal.png" class="me-2"/>
              <volume-slider-input class="ms-2" />
            </div>
          </div>
        </div>
      </div>
      <div class="team-info">
        {{ teamName }}
      </div>
      <div class="character-container d-flex flex-wrap justify-content-evenly align-items-end" v-if="playerSelf && Object.keys(playerOthers).length">
        <div class="single-character-container single-character-container-self">
          <player-character-plate
            :player-data="playerSelf"
          />
        </div>
        <div class="single-character-container" v-for="playerOther in playerOthers">
          <player-character-plate
            :player-data="playerOther"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <div class="d-flex">
          <div class="flex-grow">
            <label class="players-ready-label">{{ playerCountReadyTotal }} / {{ playerCountJoinedTotal }} {{ $t('lobby.playersReady') }}</label>
          </div>
          <div>
            <div class="row d-flex gap-4">
              <app-button @click="toggleReady" color="green" width="lg" :state="isReady ? 'active' : ''" :disabled="!canReady">{{  isReady ? $t('lobby.buttonUnready') : $t('lobby.buttonReady') }}</app-button>
              <app-button v-if="canForceStart" width="lg" @click="forceStart" color="green">{{ $t('lobby.buttonStart') }}</app-button>
            </div>
          </div>
        </div>
        <div v-if="errorMessage" class="error-container">
          {{ $t('lobby.error') }}: {{ errorMessage }}
        </div>
      </div>
    </template>
  </lobby-popup-template>
</template>

<script>
import LongButton from '@/components/common/LongButton'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate'
import NoDragImage from '@/components/common/NoDragImage'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import VoiceControllerDeviceSelector from '@/plugins/VoiceController/components/VoiceControllerDeviceSelector.vue'
import { mapGetters, mapState } from 'vuex'
import UiSlider from '@/components/player-ui/UiSlider.vue'
import VolumeSlider from '@/components/player-ui/VolumeSlider.vue'
import VolumeSliderInput from '@/components/player-ui/VolumeSliderInput.vue'
import VolumeSliderOutput from '@/components/player-ui/VolumeSliderOutput.vue'
import { EVENT_FREQUENCY_CHANGED } from '@/plugins/VoiceController/events/FrequencyChangedEvent'
import { setPlayerVoiceFrequencyByVoiceUid } from '@/store/categories/room/playerVoiceFrequencyDecayController'
import AppButton from '@/components/common/buttons/AppButton.vue';
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';

export default {
  name: 'LobbyPopupReady',
  components: {
    VolumeSliderOutput,
    VolumeSliderInput,
    VolumeSlider,
    UiSlider,
    VoiceControllerDeviceSelector,
    CharacterPlate,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    PlayerCharacterPlate
  },
  emits: ['joined'],
  data () {
    return {
      isReady: false,
      microphoneSelected: true,

      showMicInfo: false,

      activatedLetters: [
        false,
        false,
        false,
        false,
        false,
      ],

      allLettersActivated: false,

      micError: '',
    }
  },
  mounted() {
    this.connectVoiceServer();

    TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.Lobby);
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['players', 'playerSelf', 'playerOthers']),
    teamName() {
      if (this.room && this.room.roomName) {
        return this.room.roomName;
      }

      return 'N/A';
    },
    errorMessage() {
      if (!this.microphoneSelected) {
        return this.$t('lobby.errorNoMicrophone');
      }

      if (parseInt(process.env.VUE_APP_CAN_START_WITHOUT_ALL_PLAYERS, 10) > 0 && this.playerCountJoinedTotal < 2) {
        return this.$t('lobby.errorAtLeast2Players');
      }

      return null
    },
    canReady () {
      if (!this.microphoneSelected) {
        return false
      }

      if (this.playerCountJoinedTotal < 2) {
        //return false;
      }

      return true
    },
    playerCountReadyTotal () {
      let totalReadyCount = 0

      for (const playerId in this.players) {
        const player = this.players[playerId]

        if (player.sessionId && player.isReady) {
          // Do not count users that have left
          totalReadyCount++
        }
      }

      return totalReadyCount
    },
    playerCountJoinedTotal () {
      let totalJoinCount = 0

      for (const playerId in this.players) {
        const player = this.players[playerId]

        if (player.sessionId) {
          totalJoinCount++
        }
      }

      return totalJoinCount
    },
    canForceStart() {
      return !this.errorMessage && this.playerCountReadyTotal === this.playerCountJoinedTotal;

      /*let allLettersActivated = true;

      for (const activatedLetter of this.activatedLetters) {
        if (!activatedLetter) {
          allLettersActivated = false;
        }
      }

      if (!allLettersActivated) {
        return;
      }*/
    },
    voiceEnabled() {
      return parseInt(process.env.VUE_APP_VOICE_ENABLED, 10) > 0;
    },
  },
  methods: {
    connectVoiceServer() {
      if (!this.voiceEnabled) {
        return;
      }

      this.micError = '';

      console.log('room', this.room, this.room.customId);

      console.log('Connecting to voice room');
      this.$voiceChatClient.connectToRoom(process.env.VUE_APP_VOICE_WEBSOCKET_ADDRESS, this.room.customId)
        .then((voiceRoom) => {
          this.$gameClient.playerSet({
            'voiceSocketId': voiceRoom.socketId,
            'muted': this.$voiceController.isLocalStreamMuted(),
          });

          this.$voiceController.addEventListener(EVENT_FREQUENCY_CHANGED, (e) => {
            let voiceUid = e.uid;

            if (voiceUid === 'self') {
              if (!this.$voiceChatClient.socket) {
                return;
              }

              voiceUid = this.$voiceChatClient.socket.id;

              if (!voiceUid) {
                console.error(`Could not find our own socket ID`, this.$voiceChatClient.socket);

                return;
              }
            }

            setPlayerVoiceFrequencyByVoiceUid(this.$store, voiceUid, e.frequency);
          });

          this.showMicInfo = true;
        })
        .catch((e) => {
          this.micError = e;
        });
    },
    forceStart() {
      if (!this.canForceStart) {
        return;
      }

      this.$gameClient.roomCallMethod('forceEveryoneReady');
    },
    onClickLetter(index) {
      this.activatedLetters[index] = !this.activatedLetters[index];

      let allLettersActivated = true;

      for (const activatedLetter of this.activatedLetters) {
        if (!activatedLetter) {
          allLettersActivated = false;
        }
      }

      this.allLettersActivated = allLettersActivated;
    },
    onMuteChange (playerMuted) {
      if (!this.$gameClient.hasJoinedRoom()) {
        return
      }

      this.$gameClient.playerUpdate('muted', playerMuted)
    },
    toggleReady () {
      if (!this.canReady) {
        return;
      }

      this.isReady = !this.isReady

      this.$gameClient.playerUpdate('isReady', this.isReady)
    },
  },
}
</script>

<style lang="scss" scoped>
.mic-container {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 13px;
  width: fit-content;
  margin: 0 auto;

  &.mic-container-error {
    color: #aa0000;
    font-size: 16pt;
  }

  > div {
    height: 100px;
    padding: 5px;
  }
}

.dark-bg {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000000, .2);
  padding: 5px;

  img {
  }
}

.settings-panel {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;

  .settings-panel-single {
    height: 50%;
    margin: 0;
    width: 200px;

    .dark-bg {
      height: 100%;
    }

    &:first-child {
      padding-bottom: 5px;
    }

    &:last-child {
      padding-top: 5px;
    }
  }
  img {
    max-width: 30px;
  }
}

select {
  text-align: center;

  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: inset 0px 2px 2px 1px rgba(7, 28, 39, 0.56), inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  border-radius: 5px;
}

.settings-panel-output-volume {
  img {
    max-height: 25px;
    margin-top: 4px;
  }
}

.large-mic-icon {
  max-width: 70px;
}

.voice-device-selector {
  margin-top: 27px;
}

.players-ready-label {
  font-size: 30pt;
  color: white;
  margin-right: 35px;
  font-weight: 700;
}

.character-container {
  padding-top: 50px;
}

.single-character-container-self {
  .player-card {
    transform: scale(120%);
    transform-origin: bottom center;
  }
}

.all-letters-activated {
  font-weight: bold;
  color: #AA0000;
}

.large-mic-icon-flex {
  height: 100%;

  .large-mic-icon {
    img {
      height: 100%;
    }
  }
}

.error-container {
  color: #ff0000;
  font-weight: bold;
  font-size: 20pt;
}

.team-info {
  font-size: 25pt;
  font-weight: bold;
  color: #ffffff;
  margin-top: 30px;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}
</style>
