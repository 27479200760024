<template>
  <h1>AnimatingNumber</h1>

  <div class="row">
    <label class="form-label">Show total</label>
    <select v-model="showTotal" class="form-select">
      <option value="1">
        Yes
      </option>
      <option value="0">
        No
      </option>
    </select>
  </div>
  <div class="row mt-2">
    <label class="form-label">Type</label>
    <select v-model="type" class="form-select">
      <option value="number">
        Number
      </option>
      <option value="roman">
        Roman
      </option>
    </select>
  </div>
  <div class="row mt-2">
    <label class="form-label">Text align</label>
    <select v-model="textAlign" class="form-select">
      <option value="left">
        Left
      </option>
      <option value="center">
        Center
      </option>
    </select>
  </div>
  <div class="row mt-2">
    <animating-number
      :current="current"
      :show-total="parseInt(showTotal, 10) > 0"
      :text-align="textAlign"
      :theme="theme"
      :total="total"
      :type="type"
      @animationEnd="triggerEvent('animationEnd')"
      @animationStart="triggerEvent('animationStart')"
    />
  </div>

  <button-container :max="total" :min="0" :value="current" @input="current = $event" />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4" />
</template>

<script>
import AnimatingNumber from './AnimatingNumber.vue';
import ButtonContainer from './ButtonContainer';
import EventTicks from '../DevComponent/EventTicks';
import DevComponentContainer from '../DevComponent/DevComponentContainer';
import EventTicksComponentMixin from "../DevComponent/EventTicksComponentMixin";

export default {
  name: 'AnimatingNumberDev',
  components: { EventTicks, ButtonContainer, AnimatingNumber },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],
  data() {
    return {
      total: 150,
      current: 10,
      events: ['animationStart', 'animationEnd'],

      showTotal: 0,
      type: 'roman',
      textAlign: 'center',
    };
  },
};
</script>
