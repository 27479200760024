<template>
  <div class="total-cost-container">
    <div class="header">
      <div class="image-container">
        <img src="@/assets/icons/icon-calendar-large.png"/>
      </div>
      <div class="label-container">
        {{ $t('meetingPopup.strategicMeetingHeader') }}
      </div>
    </div>
    <div class="content">
      <label>{{ $t('meetingPopup.totalCost') }}:</label>

      <cost-ball class="cost-ball">{{ cost }}</cost-ball>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue'
import CostBall from '@/components/common/CostBall.vue'

export default {
  name: 'StrategicMeetingTotalCost',
  components: {
    AppButton,
    CostBall,
  },
  props: {
    cost: {
      type: Number,
    }
  }
}
</script>

<style lang="scss" scoped>
.total-cost-container {
  color: white;
  width: 210px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 160%;

  .header {
    background: url("@/assets/textures/grunge-seamless-20-percent.png") #051224;
    box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
    padding: 20px 10px 10px 10px;

    .image-container {
      height: 80px;

      img {
        max-height: 100%;
      }
    }

    .label-container {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 16pt;
      text-transform: uppercase;
    }
  }

  .content {
    font-size: 14pt;
    padding: 10px;
    text-transform: uppercase;
    text-align: left;
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
    border-radius: 0px 29px 29px 0px;
    position: relative;
  }

  .cost-ball {
    position: absolute;
    top: -3px;
    right: -10px;
  }
}
</style>
