import {
  HIGHLIGHT_TYPE_ELEMENT,
  HIGHLIGHT_TYPE_ID,
  HIGHLIGHT_TYPE_SELECTOR,
  SHAPE_SQUARE,
  SHAPE_CIRCLE,
  SHAPE_ROUNDED,
  SHAPE_SLIGHTLY_ROUNDED, POPUP_ANCHOR_AUTO, POPUP_ANCHOR_LEFT
} from '@/plugins/TutorialHighlightOverlay/enums'

export class PopBuilder {
  _text = '';
  _highlight = null;
  _highlightType = HIGHLIGHT_TYPE_SELECTOR;
  _shape = SHAPE_SQUARE;
  _offset = 0;
  _popupAnchor = POPUP_ANCHOR_AUTO;
  _clickThrough = false;
  _animationLength = 1000;
  _autoBoundDistance = 0.2;
  _passOptions = {};

  constructor (controller, options = {}) {
    this._controller = controller;

    const _options = options || {};

    if (_options.text) {
      this._text = _options.text;
    }

    if (_options.highlight) {
      this._highlight = _options.highlight;
    }

    if (_options.highlightType) {
      this._highlightType = _options.highlightType;
    }

    if (_options.shape) {
      this._shape = _options.shape;
    }

    if (_options.offset) {
      this._offset = _options.offset;
    }

    if (_options.popupAnchor) {
      this._popupAnchor = _options.popupAnchor;
    }

    if (_options.clickThrough) {
      this._clickThrough = _options.clickThrough;
    }

    if (_options.animationLength) {
      this._animationLength = _options.animationLength;
    }

    if (_options.autoBoundDistance) {
      this._autoBoundDistance = _options.autoBoundDistance;
    }

    if (_options.passOptions) {
      this._passOptions = _options.passOptions;
    }
  }

  fromSelector(elementSelector) {
    return this.highlight(elementSelector, HIGHLIGHT_TYPE_SELECTOR);
  }

  fromId(elementId) {
    return this.highlight(elementId, HIGHLIGHT_TYPE_ID);
  }

  fromElement(element) {
    return this.highlight(element, HIGHLIGHT_TYPE_ELEMENT);
  }

  highlight(highlight, highlightType) {
    this._highlight = highlight;
    this._highlightType = highlightType;

    return this;
  }

  animationLength(animationLength) {
    this._animationLength = animationLength ?? 1000;

    return this;
  }

  autoBoundDistance(autoBoundDistance) {
    this._autoBoundDistance = autoBoundDistance ?? 0.2;

    return this;
  }

  clickThrough(clickThrough = true) {
    this._clickThrough = !!clickThrough;

    return this;
  }

  shape(shape) {
    this._shape = shape;

    return this;
  }

  offset(offset = 0) {
    this._offset = offset;

    return this;
  }

  text(text) {
    this._text = text;

    return this;
  }

  popupAnchor(popupAnchor) {
    this._popupAnchor = popupAnchor;

    return this;
  }

  passOptions(passOptions) {
    this._passOptions = passOptions ?? {};

    return this;
  }

  pop() {
    return this._controller.pop(
      this._text,
      this._highlight,
      this._highlightType,
      this._shape,
      this._offset,
      this._popupAnchor,
      this._clickThrough,
      this._animationLength,
      this._autoBoundDistance,
      this._passOptions,
    );
  }

  clone() {
    return new PopBuilder(this._controller, {
      text: this._text,
      highlight: this._highlight,
      highlightType: this._highlightType,
      shape: this._shape,
      offset: this._offset,
      popupAnchor: this._popupAnchor,
      clickThrough: this._clickThrough,
      animationLength: this._animationLength,
      autoBoundDistance: this._autoBoundDistance,
      passOptions: this._passOptions,
    });
  }
}
