<template>
  <volume-slider
    :min="0"
    :max="100"
    v-model.number="setVolume"
    :current-volume="currentVolume"
  />
</template>

<script>
import slider from "vue3-slider"
import UiSlider from '@/components/player-ui/UiSlider.vue'
import VolumeSlider from '@/components/player-ui/VolumeSlider.vue'
import { EVENT_FREQUENCY_CHANGED } from '@/plugins/VoiceController/events/FrequencyChangedEvent'
import { LOCAL_STREAM_MUTED_CHANGED } from '@/plugins/VoiceController/events/LocalStreamMutedChangedEvent'

export default {
  name: 'VolumeSliderInput',
  components: {
    VolumeSlider,
  },
  data() {
    return {
      setVolume: 100,
      currentVolume: 0,
    }
  },
  mounted() {
    this.$voiceController.addEventListener(EVENT_FREQUENCY_CHANGED, this.onFrequencyChanged);
    this.$voiceController.addEventListener(LOCAL_STREAM_MUTED_CHANGED, this.onLocalMutedChanged);

    this.muted = this.$voiceController.localStreamMuted;

    this.updateVolumeFromSetVolume();
  },
  beforeUnmount () {
    this.$voiceController.removeEventListener(EVENT_FREQUENCY_CHANGED, this.onFrequencyChanged);
    this.$voiceController.removeEventListener(LOCAL_STREAM_MUTED_CHANGED, this.onLocalMutedChanged);
  },
  watch: {
    setVolume() {
      this.updateVolumeFromSetVolume();
    }
  },
  methods: {
    updateVolumeFromSetVolume() {
      const gain = this.setVolume / 100;

      this.$voiceController.setParticipantMicrophoneGain('self', gain);
    },
    onLocalMutedChanged(e) {
      this.muted = e.muted;
    },
    onFrequencyChanged(e) {
      if (e.uid !== 'self') {
        return;
      }

      //console.log('frequency changed', e);
      this.currentVolume = this.muted ? 0 : e.frequency;
    }
  },
}
</script>
<style lang="scss">
.epic-ui-slider {
  &, .track, .track-filled {
    border-radius: 7px !important;
    height: 19px !important;
  }

  .track {
    // Default fill
    background: #D9D9D9 !important;
  }

  .track-filled {
    // Active fill
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%), #D9D9D9 !important;
  }

  .handle {
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%) !important;
    mix-blend-mode: normal !important;
    box-shadow: 0px 4.2459px 4.2459px rgba(0, 0, 0, 0.52), inset 0px 1.06148px 1.06148px rgba(255, 255, 255, 0.5), inset 0px -3.18443px 2.12295px rgba(0, 0, 0, 0.51) !important;
    border-radius: 2.12295px !important;
    height: 33px !important;
    width: 12px !important;
    top: -7.5px !important;
    transform: translateX(6.5px) !important;
  }
}
</style>
