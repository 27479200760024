import {randomBetween} from '@/utils/randomUtil';
import {formatDate} from '@/utils/dateUtil';
import { getTotalMonths } from '@/utils/meetingUtil'

export function generateFakeHazard1() {
  return {
    "id": 119,
    "displayOnMap": true,
    "flagCount": 5,
    "title": "Measles",
    "icon": "infectious",
    "introSpeaker": "NPC1",
    "introInvestigationCost": 1,
    "introText": "Retunya lower mainland in the east of GL has one more case of measles.",
    "summaryText": "A local medical officer of Retunya Dr. Jon Chari, confirms level of vaccination of Measles is 37% in the area and the last case is imported. The RAV Public school of Retunya informs that the sick student comes from the neighboring country Tremeristan and is currently on exchange program with Global Land where 30 students were invited for cultural activities.",
    "summaryMediaSource": "Default",
    "summaryMediaCaption": "Report from the site",
    "summaryCallMeetingCost": 1,
    "summaryPrioritizationCost": 1,
    "mapLocation": {
      "x": 76.1910841344751,
      "y": 33.47701318083211
    },
    "age": 1,
    "baseLikelihood": 1,
    "startingSeverity": 1,
    "investmentTabs": [],
    "summary": {
      "actionsInvested": [],
      "playerInvestments": {}
    },
    "summaryJson": ""
  };
}

export function generateFakeHazard2() {
  return {
    "id": 120,
    "displayOnMap": true,
    "flagCount": 3,
    "title": "COVID-19",
    "icon": "infectious",
    "introSpeaker": "NPC2",
    "introInvestigationCost": 1,
    "introText": "After COVID-19 has become part of the reportable disease list and appears in the periodic epi reports, confirmed cases across the country are put under close follow up, while regular control activities are implemented.  \nEvery now and then there are some resurgence events in overcrowded slum areas or areas of IDPs and hard to reach communities.",
    "summaryText": "•  Currently a resurgence has been expanding in Montuk industrial area around Tjemara. \n•  Epi report of the 7th month in the game is here for you to check. \n•  In collaboration with WHO, GL with a multi sectoral representation, conducted an Intra Action Review (IAR) activity to evaluate the response of the country to COVID-19 pandemic using a qualitative methodology that identify the best practices, gaps, and lessons learned to adjust the response plans to the public health event under review. \n•  As the final review report highlighted, three major recommendations have been of highest importance to act upon: \n1. Lack of timely and comprehensive multi sectoral collaboration. \n2. Lack of equipment and trainings at the governorate and provincial levels in the various sectors relevant to public health event response.  \n3. Lack of community engagement strategies in GL.\"",
    "summaryMediaSource": "Default",
    "summaryMediaCaption": "Report from the site",
    "summaryCallMeetingCost": 1,
    "summaryPrioritizationCost": 1,
    "mapLocation": {
      "x": 52.5968870986727,
      "y": 29.193932288885364
    },
    "age": 1,
    "baseLikelihood": 2,
    "startingSeverity": 2,
    "investmentTabs": [],
    "summary": {
      "actionsInvested": [],
      "playerInvestments": {}
    },
    "summaryJson": ""
  };
}

export function generateUsedActions(addInResponseTo, jeeCategories, jeeScores, actionCount = null) {
  const usedActions = [];

  if (actionCount === null) {
    actionCount = Math.round(randomBetween(1, 20));
  }

  for (let i = 0; i < actionCount; i++) {
    usedActions.push(generateUsedAction(addInResponseTo, jeeCategories, jeeScores));
  }

  return usedActions;
}

export function generateUsedAction(addInResponseTo, jeeCategories, jeeScores) {
  const usedAction = {
    name: 'Action name ' + Math.round(randomBetween(1, 50)),
    cost: Math.round(randomBetween(1, 16)),
    indicatorsImpacted: generateImpactedIndicators(jeeCategories, jeeScores),
    month: Math.round(randomBetween(1, getTotalMonths())),
    description: 'Establish a unit/functional team at the MoH&S in charge of alignment between all public health domestic legislation, laws, regulations, policy and administrative requirements including those for compliance with the IHR (BM C1.1)',
  }

  if (addInResponseTo) {
    usedAction.inResponseTo = 'MEASLES';
  }

  return usedAction;
}

export function generateJeeScores(jeeCategories) {
  const jeeScores = {};

  for (const jeeCategory of jeeCategories) {
    for (const jeeGroup of jeeCategory.groups) {
      for (const jee of jeeGroup.jees) {
        if (Math.random() > 0.3) {
          continue;
        }

        jeeScores[jee.identifier] = Math.round(randomBetween(0, 30));
      }
    }
  }

  return jeeScores;
}

export function generateImpactedIndicators(jeeCategories, jeeScores) {
  const indicatorsImpacted = {};

  for (const jeeCategory of jeeCategories) {
    for (const jeeGroup of jeeCategory.groups) {
      if (Math.random() > 0.3) {
        continue;
      }

      for (const jee of jeeGroup.jees) {
        if (Math.random() > 0.3) {
          continue;
        }

        const jeeScore = jeeScores[jee.identifier] || 0;

        indicatorsImpacted[jee.identifier] = jeeScore;
      }
    }
  }

  return indicatorsImpacted;
}

const teamColors = [
  'Red', 'Blue', 'Green', 'Yellow', 'Purple', 'Orange', 'Cyan', 'Maroon', 'Magenta',
  'Indigo', 'Bronze', 'Silver', 'Gold', 'Diamond', 'Ruby', 'Emerald', 'Sapphire', 'Quartz',
  'Amber', 'Jet', 'Opal', 'Topaz', 'Pearl', 'Onyx', 'Obsidian'
];

const teamNames = [
  'Guardians', 'Yielders', 'Outliers', 'Saviors', 'Medics', 'Mutations', 'Inhibitors',
  'Biospheres', 'Shield', 'Receptors', 'Eradicators', 'Sanitizers', 'Quell', 'Antidote',
  'Juggernauts', 'Outbreaks', 'Protectors', 'Overcomers', 'Seekers', 'Revivers', 'Healers',
  'Clinicians', 'Mediators', 'Navigators', 'Purifiers', 'Avengers', 'Liberators'
];

export function generateTeamName() {
  const colorIndex = Math.floor(Math.random() * teamColors.length);
  const nameIndex = Math.floor(Math.random() * teamNames.length);

  return teamColors[colorIndex] + ' ' + teamNames[nameIndex];
}

export function generateRandomDate(start, end) {
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  return formatDate(date);
}
