<template>
  <vue3-slider
    class="epic-ui-slider"
    :min="minNumber"
    :max="maxNumber"
    v-model.number="modelValueInner"
    :height="20"
    :alwaysShowHandle="true"
    color="#FF0000"
    trackColor="#0000FF"
  />
</template>

<script>
import slider from "vue3-slider"

export default {
  name: 'UiSlider',
  props: {
    modelValue: {
      type: Number,
      default: 0,
      required: false,
    },
    min: {
      type: Number,
      default: null,
      required: false,
    },
    max: {
      type: Number,
      default: null,
      required: false,
    }
  },
  components: {
    "vue3-slider": slider
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      this.modelValueInner = this.modelValue;
    },
    modelValueInner() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      this.$emit('update:modelValue', this.modelValueInner);
    },
  },
  computed: {
    minNumber() {
      return this.min !== null ? parseFloat(this.min) : null;
    },
    maxNumber() {
      return this.max !== null ? parseFloat(this.max) : null;
    },
  },
  data() {
    return {
      modelValueInner: this.modelValue,
    }
  },
  emits: ['update:modelValue'],

}
</script>
<style lang="scss">
.epic-ui-slider {
  &, .track, .track-filled {
    border-radius: 7px !important;
    height: 19px !important;
  }

  .track {
    // Default fill
    background: #D9D9D9 !important;
  }

  .track-filled {
    // Active fill
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%), #D9D9D9 !important;
  }

  .handle {
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%) !important;
    mix-blend-mode: normal !important;
    box-shadow: 0px 4.2459px 4.2459px rgba(0, 0, 0, 0.52), inset 0px 1.06148px 1.06148px rgba(255, 255, 255, 0.5), inset 0px -3.18443px 2.12295px rgba(0, 0, 0, 0.51) !important;
    border-radius: 2.12295px !important;
    height: 33px !important;
    width: 12px !important;
    top: -7.5px !important;
    transform: translateX(6.5px) !important;
  }
}
</style>
