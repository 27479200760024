export default {
  'hold': {
    'identifier': 'hold',
    'veryPositive': {
      'title': 'Spot on!',
      'text': 'Congratulations, you are the winning team and have voted to hold the mass gathering event in Global Land! Your outstanding performance in coordination and strategic thinking have led well maganed event. Your ability to navigate complex challenges and work together effectively worked well. Your dedication, coordination, and decision-making skills were instrumental in overcoming the global crisis. Your triumph in this game reflects your resilience, adaptability, and commitment to problem-solving. Well done on your remarkable achievement!',
    },
    'positive': {
      'title': 'Almost there!',
      'text': 'Well done, your team made some great decisions and managed to plan a relatively successful event at Global Land! Your efforts in coordination and strategic thinking were largely effective. Although there were some minor difficulties along the way, your team\'s ability to address these challenges and work in harmony was commendable. Your dedication, decision-making, and teamwork played a key role in mitigating potential crises. Your achievements in this game highlight your capability to work under pressure and commitment to problem-solving. Keep it up, and strive for an even better outcome next time!',
    },
    'medium': {
      'title': 'Decent work!',
      'text': 'Good job, your team managed to put together a decent event in Global Land. While there were some missteps in coordination and strategic planning, your efforts were not in vain. The challenges you faced were complex, and while not all were navigated successfully, you demonstrated a willingness to adapt and improve. Remember, every decision matters, and coordination and teamwork are key to overcoming crises. This game has showcased your team\'s potential and readiness to solve problems. You\'ve made it this far, now aim higher and work towards excellence!',
    },
    'negative': {
      'title': 'Bad choice!',
      'text': 'Unfortunately, this time your team\'s decision to hold the mass gathering in Global Land didn\'t go as planned. There were notable issues with coordination and strategy, and the event faced various challenges. However, remember that it\'s okay to make mistakes as long as you learn from them. This game has offered you the chance to identify areas for improvement in decision-making, teamwork, and crisis management. Use this experience as a stepping stone to improve your resilience, adaptability, and problem-solving skills. Don\'t get discouraged; every failure is just another opportunity to grow stronger and do better next time!',
    },
    'gameDecisionResults': {
      'hold': 'veryPositive',
      'delay': 'medium',
      'restrict': 'positive',
      'cancel': 'negative',
    },
  },
  'delay': {
    'identifier': 'delay',
    'veryPositive': {
      'title': 'Spot on!',
      'text': 'Well done on your decision to delay the mass gathering event in this game scenario. Your cautious and responsible approach demonstrates your understanding of the potential risks and the importance of prioritizing public health and safety. Your decision reflects your ability to make difficult choice, Analyze the session\u2019s outcomes to identify areas for improvement, development of strategies to overcome more effectively and complexities of the game scenario!',
    },
    'positive': {
      'title': 'Almost there!',
      'text': 'Nice job on choosing to delay the mass gathering event in this game scenario. Your prudent approach signifies an appreciation of potential risks and a respect for public safety. While not every outcome was optimal, your team made difficult decisions and demonstrated a capacity for strategic thinking. Review this session\'s results and look for opportunities to fine-tune your strategies. You are learning the complexities of this game and making solid progress.',
    },
    'medium': {
      'title': 'Decent work!',
      'text': 'You\'ve made the decision to delay the mass gathering event in this game scenario. While this indicates some understanding of risk and safety considerations, there were instances where your approach could have been more effective. The session\'s outcomes reveal areas where your decision-making and strategies could improve. Use this as a learning opportunity to better grasp the intricacies of the game. Remember, improvement comes with practice and experience.',
    },
    'negative': {
      'title': 'Bad choice!',
      'text': 'Regrettably, your decision to delay the mass gathering event didn\'t result in the most positive outcome in this game scenario. Your intentions to consider risk and prioritize public safety are commendable, but the execution of your strategy needs work. Review this session\'s outcomes carefully to understand where things went wrong and how your decision-making can improve. Take this as a valuable learning experience, and remember that each setback is an opportunity for growth and improvement in this complex game scenario.',
    },
    'gameDecisionResults': {
      'hold': 'medium',
      'delay': 'veryPositive',
      'restrict': 'medium',
      'cancel': 'positive',
    },
  },
  'restrict': {
    'identifier': 'restrict',
    'veryPositive': {
      'title': 'Spot on!',
      'text': 'You have voted to restrict the participation in the mass gathering event to the planned teams and the supportive essential staff, in the game scenario. By prioritizing the well-being of the participants and the community, you have taken a proactive approach to mitigating the risks associated with the pandemic. Your decision reflects your ability to make difficult choice. It is recommended to you to analyze the session\u2019s outcomes & your work to identify areas for improvement, development of strategies to overcome more effectively and complexities of the game scenario!',
    },
    'positive': {
      'title': 'Almost there!',
      'text': 'Well-executed, your decision to restrict participation in the mass gathering event to the essential teams and staff shows careful consideration for the well-being of all involved. You\'ve taken some commendable steps to lessen the risks associated with the pandemic in this game scenario. While not perfect, your choices demonstrate strategic thinking and a knack for making tough calls. Review the session\'s results to identify areas where your strategies could be more effective. Keep on learning and refining your approach to navigate this complex scenario better!',
    },
    'medium': {
      'title': 'Decent work!',
      'text': 'You\'ve decided to restrict the participation in the mass gathering event in this game scenario. This choice illustrates some awareness of health risks and the need to protect the community. However, there were aspects of your strategy that could have been better executed. Carefully consider the session\'s outcomes and identify areas for improvement. Each decision you make is a chance to learn and develop more effective strategies for navigating the game\'s complexities.',
    },
    'negative': {
      'title': 'Bad choice!',
      'text': 'Unfortunately, your decision to restrict participation in the mass gathering event did not yield the desired outcomes in this game scenario. Your initial intent to prioritize participant and community well-being was the right direction, but the implementation fell short. Reflect on the session\'s outcomes, identify where your strategy faltered, and consider how to improve for future decisions. Remember, every challenge is an opportunity to learn and develop stronger strategies in this complex game scenario.',
    },
    'gameDecisionResults': {
      'hold': 'positive',
      'delay': 'medium',
      'restrict': 'veryPositive',
      'cancel': 'medium',
    },
  },
  'cancel': {
    'identifier': 'cancel',
    'veryPositive': {
      'title': 'Spot on!',
      'text': 'You have voted to cancel the mass gathering event. Your participation and effort in tackling the global crisis were still noteworthy. The challenges you faced required strategic thinking and good collaboration. Analyze the session\u2019s outcomes to identify areas for improvement and develop strategies to overcome more effectively in the future.   ',
    },
    'positive': {
      'title': 'Almost there!',
      'text': 'Good job on voting to cancel the mass gathering event. Your team\'s participation and effort in this game scenario have shown a clear recognition of the global crisis. The hurdles you encountered demanded strategic foresight and solid teamwork. While not everything went perfectly, your team\'s performance was commendable. Take some time to analyze the session\u2019s outcomes and learn from them. Use these insights to develop stronger strategies for future challenges.',
    },
    'medium': {
      'title': 'Decent work!',
      'text': 'Your decision to cancel the mass gathering event indicates an understanding of the gravity of the global crisis. In this game scenario, your team faced numerous challenges, and your responses required strategic thought and collaboration. There were areas where your strategies could have been more effective, however. Analyze the session\'s outcomes, learn from your mistakes, and use these lessons to bolster your strategies for future scenarios. Remember, every step taken is a step towards improvement.',
    },
    'negative': {
      'title': 'Bad choice!',
      'text': 'Regrettably, your decision to cancel the mass gathering event did not lead to a successful outcome in this game scenario. While your recognition of the global crisis is commendable, your strategic planning and collaboration need enhancement. Use the outcomes of this session as a learning opportunity. Identify where you could have improved, and apply these insights to create stronger strategies in future sessions. Failure is often the best teacher; don\'t be disheartened, but take this as a chance to grow.',
    },
    'gameDecisionResults': {
      'hold': 'negative',
      'delay': 'positive',
      'restrict': 'medium',
      'cancel': 'veryPositive',
    },
  },
};