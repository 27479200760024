export default [
  {
    'totalIndex': 0,
    'groupIndex': 0,
    'identifierFull': 'characterSelection.avatar',
    'groupIdentifier': 'characterSelection',
    'tutorialIdentifier': 'avatar',
    'text': 'This is **your Avatar** for the game. Please choose what you will look like in this game. ',
    'element': '.footer-content .character-thumbnail',
    'staticAnchor': null,
  },
  {
    'totalIndex': 1,
    'groupIndex': 1,
    'identifierFull': 'characterSelection.name',
    'groupIdentifier': 'characterSelection',
    'tutorialIdentifier': 'name',
    'text': 'Fill out your name and join the game.',
    'element': '.footer-content .form-control',
    'staticAnchor': null,
  },
  {
    'totalIndex': 2,
    'groupIndex': 0,
    'identifierFull': 'lobby.mainLobby',
    'groupIdentifier': 'lobby',
    'tutorialIdentifier': 'mainLobby',
    'text': 'This is your lobby - Here you can see **your team**. Click ready when your are ready to play.',
    'element': '.character-container',
    'staticAnchor': 'MiddleBottom',
  },
  {
    'totalIndex': 3,
    'groupIndex': 0,
    'identifierFull': 'roleSelection.role',
    'groupIdentifier': 'roleSelection',
    'tutorialIdentifier': 'role',
    'text': 'This is **your role** for the game. Each role represent a vital function within Globalland. Each role has **Areas of Expertise** that represent their abilities and strengths. Clicking a role to see a description. Choose or unchoose that role underneath their description. ',
    'element': '.lobby-popup-content .role-selection-button-container',
    'staticAnchor': null,
  },
  {
    'totalIndex': 4,
    'groupIndex': 1,
    'identifierFull': 'roleSelection.teamBalance',
    'groupIdentifier': 'roleSelection',
    'tutorialIdentifier': 'teamBalance',
    'text': 'This is your **team balance**. This represents the combined abilities and strengths from the Area of Expertise of your team. Choose your role **in collaboration with your teammates** to make the best team.',
    'element': '.footer-content .app-button',
    'staticAnchor': null,
  },
  {
    'totalIndex': 5,
    'groupIndex': 0,
    'identifierFull': 'mainUi.you',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'you',
    'text': 'This is you. This avatar will represent you throughout the game.',
    'element': '.player-card',
    'staticAnchor': null,
  },
  {
    'totalIndex': 6,
    'groupIndex': 1,
    'identifierFull': 'mainUi.role',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'role',
    'text': 'This is **your role** you chose. ',
    'element': '.player-card .role-icon',
    'staticAnchor': null,
  },
  {
    'totalIndex': 7,
    'groupIndex': 2,
    'identifierFull': 'mainUi.priorityFlags',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'priorityFlags',
    'text': 'This is your **priority flags**. Each month you will get new flags that you will use to priozitizing hazards in the game.',
    'element': '.player-card .flag-icon',
    'staticAnchor': null,
  },
  {
    'totalIndex': 8,
    'groupIndex': 3,
    'identifierFull': 'mainUi.team',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'team',
    'text': 'This is **your team**.',
    'element': '.right-player-list-bar',
    'staticAnchor': null,
  },
  {
    'totalIndex': 9,
    'groupIndex': 4,
    'identifierFull': 'mainUi.overallScores',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'overallScores',
    'text': 'This is your overall scores of the game. Your have **Trust, Health security, Collaboration and Mass gathering event success**. Your actions throughout the game will affect these scores.',
    'element': '.main-content-container',
    'staticAnchor': 'MiddleTop',
  },
  {
    'totalIndex': 10,
    'groupIndex': 5,
    'identifierFull': 'mainUi.actionPoints',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'actionPoints',
    'text': 'This is your **Team Ressources (TR)**. Team Ressources represent your teams investment of time, finances and skill into different actions throughout the game. Each month your will get an amount of TRs. You and your teammates will decide how to spend these TRs throughout that month. But spend them wisely because all activities has an TR cost and you and your teammates all have to read documents, investigate hazards, take strategic action and respond to hazards. Collaborate with you teammates on when, how and why you spend TRs.',
    'element': '.main-content-container .action-points-circle',
    'staticAnchor': null,
  },
  {
    'totalIndex': 11,
    'groupIndex': 6,
    'identifierFull': 'mainUi.timeline',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'timeline',
    'text': 'This is **the Timeline** - It displayes the number of month left to the Mass gathering event. Each month will consist of 3 phases. **Phase 1** is the strategic action phase, where you and your team will decide what strategic actions you will take for the month. Strategic actions build capacity and prepare for the mass gathering event. **Phase 2** is the prioritization phase, where you discuss, prioritize and investigate the hazards of Globalland. **Phase 3** is the Hazard response phase, where you respond to the hazards that you prioritized in the privious round.',
    'element': '.main-content-container .stat-card-time',
    'staticAnchor': null,
  },
  {
    'totalIndex': 12,
    'groupIndex': 7,
    'identifierFull': 'mainUi.timer',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'timer',
    'text': 'This is **your timer**. This represent the time you and your teammate have for each phase and for each discussion. When this timer runs out your team will move forward. If you all deside you want to move the game forward ahead of time, then simply click the ready button. When all of the team are ready to move on the game continues.',
    'element': '.ui-timer',
    'staticAnchor': null,
  },
  {
    'totalIndex': 13,
    'groupIndex': 8,
    'identifierFull': 'mainUi.dashboard',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'dashboard',
    'text': 'This is **your dashboard**. Here you will find information on your previous taken actions and their impact and your current capacity for GlobalLand.',
    'element': '.action-bar-dashboard',
    'staticAnchor': null,
  },
  {
    'totalIndex': 14,
    'groupIndex': 9,
    'identifierFull': 'mainUi.toolbox',
    'groupIdentifier': 'mainUi',
    'tutorialIdentifier': 'toolbox',
    'text': 'This is **your toolbox**. Here you will find useful information on Globalland, your STAR matrix, SPAR and other useful documents. You will not Spend TRs reading documents, unless you take to long.',
    'element': '.action-bar-toolbox',
    'staticAnchor': null,
  },
  {
    'totalIndex': 15,
    'groupIndex': 0,
    'identifierFull': 'strategicMeetingInvite.meetingInvite',
    'groupIdentifier': 'strategicMeetingInvite',
    'tutorialIdentifier': 'meetingInvite',
    'text': 'At the start of each month you and your teammates gathers in a stratetic meeting to plan what actions to take to build capacity in Globalland in preperation for the Mass Gathering event. Remeber to have TRs left for any hazards that might show up.',
    'element': '.hazard-popup-container .hazard-popup-buttons .app-button',
    'staticAnchor': null,
  },
  {
    'totalIndex': 16,
    'groupIndex': 0,
    'identifierFull': 'strategicMeeting.categories',
    'groupIdentifier': 'strategicMeeting',
    'tutorialIdentifier': 'categories',
    'text': 'This the main categories of the strategic meeting - All actions is sorted into these categories and sub categories. Underneath each category you can see what your teammate are looking at at all times.',
    'element': '.meeting-popup-navigation',
    'staticAnchor': 'LeftMiddle',
  },
  {
    'totalIndex': 17,
    'groupIndex': 1,
    'identifierFull': 'strategicMeeting.actionBoard',
    'groupIdentifier': 'strategicMeeting',
    'tutorialIdentifier': 'actionBoard',
    'text': 'Here you can see all the actions for the category you are currently watching. Here you can also add them to the main table for your teammates to see if you think that action should be implemented when the strategic meeting ends. If an action has a green glow around it it means that this action falls within your area of expertise and will be beneficiary for you to implement.',
    'element': '.meeting-investments-available',
    'staticAnchor': 'MiddleBottom',
  },
  {
    'totalIndex': 18,
    'groupIndex': 2,
    'identifierFull': 'strategicMeeting.implementedActions',
    'groupIdentifier': 'strategicMeeting',
    'tutorialIdentifier': 'implementedActions',
    'text': 'This is the collaborative \'table\' of the meeting. Here you can see the actions that you and your teammates have decided to implement. When the meeting runs out or you decide to end it these action will be implmented. Discuss, choose and decide the actions in collaboration. ',
    'element': '.meeting-investments-made',
    'staticAnchor': 'MiddleBottom',
  },
  {
    'totalIndex': 19,
    'groupIndex': 0,
    'identifierFull': 'strategicMeetingResult.meetingResult',
    'groupIdentifier': 'strategicMeetingResult',
    'tutorialIdentifier': 'meetingResult',
    'text': 'This is the result of your meeting. Here you can see the action implemented and the feedback of each action. Click the \'eye\' to view the feedback.',
    'element': '.meeting-results-content-outer',
    'staticAnchor': 'MiddleMiddle',
  },
  {
    'totalIndex': 20,
    'groupIndex': 0,
    'identifierFull': 'dashboard.indicators',
    'groupIdentifier': 'dashboard',
    'tutorialIdentifier': 'indicators',
    'text': 'This is your **Capacity indicators**. These indicators tells you about the current state of Globalland in different areas based on the JEE assessment toolkit.',
    'element': '.dashboard-popup #btn-dashboard',
    'staticAnchor': null,
  },
  {
    'totalIndex': 21,
    'groupIndex': 1,
    'identifierFull': 'dashboard.usedActions',
    'groupIdentifier': 'dashboard',
    'tutorialIdentifier': 'usedActions',
    'text': 'Here you can see the used actions you made so far in the game.',
    'element': '.dashboard-popup #btn-used-actions',
    'staticAnchor': null,
  },
  {
    'totalIndex': 22,
    'groupIndex': 0,
    'identifierFull': 'dashboardActions.elementsOfUsedActions',
    'groupIdentifier': 'dashboardActions',
    'tutorialIdentifier': 'elementsOfUsedActions',
    'text': 'On each action you can see what and how they impacted the indicators.',
    'element': '.dashboard-popup #btn-strategic-actions',
    'staticAnchor': null,
  },
  {
    'totalIndex': 23,
    'groupIndex': 0,
    'identifierFull': 'hazardMeetingMap.main',
    'groupIdentifier': 'hazardMeetingMap',
    'tutorialIdentifier': 'main',
    'text': 'Here you get a more indepth information about the hazard to be used in the Hazard response phase. Prioritize it if you want to bring it to the meeting.',
    'element': '.floating-hazard-area',
    'staticAnchor': 'MiddleMiddle',
  },
  {
    'totalIndex': 24,
    'groupIndex': 1,
    'identifierFull': 'hazardMeetingMap.hazards',
    'groupIdentifier': 'hazardMeetingMap',
    'tutorialIdentifier': 'hazards',
    'text': 'This is a representation of potential hazards in Globalland. This Icon of the hazard shows what kind of category the hazard is. Hazards can be investigated and then prioritized in the Priotization phase if that is what your team wants. In the Hazard response phase you will see what you can do about your prioritized hazard. But remember that a hazard is only prioritized IF it has 2 flags on it, so you better get your teammates to prioritize them too.',
    'element': '.floating-event-icon-identifier-infectious',
    'staticAnchor': null,
  },
  {
    'totalIndex': 25,
    'groupIndex': 2,
    'identifierFull': 'hazardMeetingMap.news',
    'groupIdentifier': 'hazardMeetingMap',
    'tutorialIdentifier': 'news',
    'text': 'This is a representation of news in Globalland. These can be news about upcomming events or forshowing of hazards to come, so pay close attention to the news.',
    'element': '.floating-event-icon-identifier-news',
    'staticAnchor': null,
  },
  {
    'totalIndex': 26,
    'groupIndex': 3,
    'identifierFull': 'hazardMeetingMap.colours',
    'groupIdentifier': 'hazardMeetingMap',
    'tutorialIdentifier': 'colours',
    'text': 'The colour beneath the Hazard represent the hazard severity level. The more severe the hazard is the more costly a response will have to be to overcome the Hazard.',
    'element': '.floating-event-icon-identifier-infectious .floating-event-icon-severity-bar',
    'staticAnchor': null,
  },
  {
    'totalIndex': 27,
    'groupIndex': 4,
    'identifierFull': 'hazardMeetingMap.prioritization',
    'groupIdentifier': 'hazardMeetingMap',
    'tutorialIdentifier': 'prioritization',
    'text': 'Here you can see all the hazard you have prioritized this month.',
    'element': '.action-bar-flag-management',
    'staticAnchor': null,
  },
  {
    'totalIndex': 28,
    'groupIndex': 0,
    'identifierFull': 'hazardMeetingPreMeeting.prioritizationList',
    'groupIdentifier': 'hazardMeetingPreMeeting',
    'tutorialIdentifier': 'prioritizationList',
    'text': 'This is the priotization list for the Hazard response meeting. This list show the hazards that the team has priotized and in which order they will be addressed in the meeting. Remember to have enough TRs for responses to all of your priotizations.',
    'element': '.prioritization-hazard-container-outer',
    'staticAnchor': null,
  },
  {
    'totalIndex': 29,
    'groupIndex': 0,
    'identifierFull': 'hazardMeeting.categories',
    'groupIdentifier': 'hazardMeeting',
    'tutorialIdentifier': 'categories',
    'text': 'This the categories of the actions you can take as a response to that paticular hazard. - All actions is sorted into these categories and sub categories. Underneath each category you can see what your teammate are looking at at all times.',
    'element': '.meeting-popup-navigation',
    'staticAnchor': 'LeftMiddle',
  },
  {
    'totalIndex': 30,
    'groupIndex': 1,
    'identifierFull': 'hazardMeeting.actionBoard',
    'groupIdentifier': 'hazardMeeting',
    'tutorialIdentifier': 'actionBoard',
    'text': 'Here you can see all the actions for the category you are currently watching. Here you can also add them to the main table for your teammates to see if you think that action should be implemented when the strategic meeting ends. If an action has a green glow around it it means that this action falls within your area of expertise and will be beneficiary for you to implement.',
    'element': '.meeting-investments-available',
    'staticAnchor': 'MiddleBottom',
  },
  {
    'totalIndex': 31,
    'groupIndex': 2,
    'identifierFull': 'hazardMeeting.implementedActions',
    'groupIdentifier': 'hazardMeeting',
    'tutorialIdentifier': 'implementedActions',
    'text': 'This is the collaborative \'table\' of the meeting. Here you can see the actions that you and your teammates have decided to implement. When the meeting runs out or you decide to end it these action will be implmented. Discuss, choose and decide the actions in collaboration. ',
    'element': '.meeting-investments-made',
    'staticAnchor': 'MiddleBottom',
  },
  {
    'totalIndex': 32,
    'groupIndex': 0,
    'identifierFull': 'hazardMeetingResult.Meeting result',
    'groupIdentifier': 'hazardMeetingResult',
    'tutorialIdentifier': 'Meeting result',
    'text': 'This is the result of your meeting. Here you can see the action implemented and the feedback of each action. Click the \'eye\' to view the feedback.',
    'element': '.meeting-results-content-outer',
    'staticAnchor': 'MiddleMiddle',
  },
  {
    'totalIndex': 33,
    'groupIndex': 0,
    'identifierFull': 'epiReport.main',
    'groupIdentifier': 'epiReport',
    'tutorialIdentifier': 'main',
    'text': 'This is the EPI report - it will display the development of hazards in Globalland. ',
    'element': '.epi-report-container',
    'staticAnchor': 'MiddleMiddle',
  },
];