export function hexStringToInt(hex) {
  if (hex.match(/^0x[xbo]/)) {
    return Number(hex);
  } else {
    return Number('0x' + hex.replace(/^#/, ''));
  }
}

export function hexToRgbObject(hex) {
  let color;

  if (typeof(hex) === 'string') {
    color = hexStringToInt(hex);
  } else {
    color = hex;
  }

  const red = (color >> 16) & 0xFF;
  const green = (color >> 8) & 0xFF;
  const blue = (color >> 0) & 0xFF;

  return {
    red,
    green,
    blue,
  };
}

export function hexToRgb(hex) {
  const rgbObject = hexToRgbObject(hex);

  return `rgb(${rgbObject.red}, ${rgbObject.green}, ${rgbObject.blue})`;
}

export function hexToRgba(hex, alpha = 1) {
  const rgbObject = hexToRgbObject(hex);

  return `rgba(${rgbObject.red}, ${rgbObject.green}, ${rgbObject.blue}, ${alpha})`;
}
