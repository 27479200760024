<template>
  <div class="container-fluid">
    <div>
      <div class="row">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Role</label>
          <bootstrap-select :options="options.roles" v-model="roleIndex"/>
        </div>
      </div>
      <div class="row mt-2" v-if="role">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Role points (out of {{ rolePointsTotal }})</label>
          <input class="form-control" type="range" min="1" :max="rolePointsTotal" v-model="rolePoints"/>
        </div>
      </div>
      <div class="row mt-2">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Show voice status</label>
          <bootstrap-select :options="options.yesNo" v-model="showVoiceStatus"/>
        </div>
      </div>
      <div class="row mt-2" v-if="showVoiceStatus">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Voice frequency</label>
          <input class="form-control" type="range" min="0" max="100" v-model.number="voiceFrequency"/>
        </div>
      </div>
      <div class="row mt-2" v-if="showVoiceStatus">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Is remote muted</label>
          <bootstrap-select :options="options.yesNo" v-model="remoteMuted"/>
        </div>
      </div>
      <div class="row mt-2" v-if="showVoiceStatus">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Is local muted</label>
          <bootstrap-select :options="options.yesNo" v-model="localMuted"/>
        </div>
      </div>
      <div class="row mt-2">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Is waiting for</label>
          <bootstrap-select :options="options.yesNo" v-model="waitingFor"/>
        </div>
      </div>
      <div class="row mt-2">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Cost (0 to disable)</label>
          <input class="form-control" type="range" min="0" max="10" v-model.number="cost"/>
        </div>
      </div>
      <div class="row mt-2">
        <div style="text-align: left; width: 400px; margin: 0 auto">
          <label class="form-label">Has joined</label>
          <bootstrap-select :options="options.yesNo" v-model="hasJoined"/>
        </div>
      </div>
    </div>

    <div v-for="size in sizes">
      <div class="d-flex justify-content-evenly">
        <div class="text-center" v-for="n in 5">
          <character-plate
            :name="'Player' + n"
            :character-index="n - 1"
            :size="size"
            :show-voice-status="showVoiceStatusBool"
            :role="role"
            :role-points="rolePoints"
            :role-points-total="rolePointsTotal"
            :cost="cost"
            :voice-frequency="voiceFrequency"

            :is-self="!!isSelf"
            :muted="!!remoteMuted"
            :local-muted="!!localMuted"
            :has-joined="!!hasJoined"
            :waiting-for="!!waitingFor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import {selectifyObject} from '@/utils/selectifyUtils'
import { mapState } from 'vuex'

export default {
  name: 'CharacterPlateDev',
  components: {BootstrapSelect, CharacterPlate},
  extends: DevComponentContainer,

  data() {
    return {
      sizes: [
        'tiny',
        'small',
        'medium',
        //'mediumSmall',
        //'large',
        //'extraLarge',
      ],

      showVoiceStatus: '0',
      isSelf: '0',
      remoteMuted: '0',
      localMuted: '0',
      hasJoined: '1',
      waitingFor: '0',

      roleIndex: '-1',

      options: {
        yesNo: selectifyObject({
          '': 'No',
          '1': 'Yes',
        }),
        roles: []
      },

      cost: 0,
      rolePoints: 25,
      rolePointsTotal: 60,
      voiceFrequency: 0,
    }
  },
  mounted() {
    this.roleIndex = -1;
    this.options.roles = [
      {
        label: 'None',
        value: '-1',
      }
    ];

    for (let i = 0, len = this.roles.length; i < len; i++) {
      const role = this.roles[i];

      this.options.roles.push({
        label: `${role.name} (${role.icon})`,
        value: i.toString(),
      });
    }
  },
  computed: {
    ...mapState(['roles']),
    role() {
      if (this.roleIndex === '-1' || this.roleIndex === null) {
        return null;
      }

      const role = this.roles[parseInt(this.roleIndex, 10)];

      return role;
    },
    showVoiceStatusBool() {
      return !!this.showVoiceStatus;
    }
  }
}
</script>
