<template>
  <div class="row mt-2">
    <end-game-popup-decision v-model="currentDecision" :player-votes="playerVotes" />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'

export default {
  name: 'EndGamePopupDecisionDev',
  components: {
    HazardPopupFlagManagement,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DevSettingsPanel,
    BootstrapSelect,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  watch: {
    currentDecision() {
      this.triggerEvent('click:decision');

      const decision = this.currentDecision;
      const playerCode = '111111';

      Object.keys(this.playerVotes).forEach((key) => {
        this.playerVotes[key] = this.playerVotes[key].filter((player) => {
          return player && player.playerCode !== playerCode;
        });
      })

      this.playerVotes[decision].push(this.players[playerCode]);
    }
  },
  data () {
    const players = generatePlayersData();

    return {
      players: players,
      currentDecision: null,
      events: ['click:decision'],
      playerVotes: {
        [DECISION_RESTRICT]: [players['111111'], players['222222']],
        [DECISION_HOLD]: [],
        [DECISION_DELAY]: [players['333333']],
        [DECISION_CANCEL]: [],
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
