<template>
  <div class="side-star" v-if="animate">
  </div>
</template>

<script>
export default {
  name: 'StarPointSmall',
  data() {
    return {
      animate: false,
    }
  },
  mounted() {
    requestAnimationFrame(() => {
      this.animate = true;
    });
  },
}
</script>

<style lang="scss" scoped>
.side-star {
  width: 176px;
  height: 177px;
  background: url("@/assets/star-points/small-star.png") no-repeat;
  background-size: cover;
  animation: 1500ms jackInTheBox 1;
  opacity: 1;
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(60deg);
    transform-origin: center center;
  }

  35% { /* 50% of 70% is 35% */
    transform: rotate(-40deg);
  }

  49% { /* 70% of 70% is 49% */
    transform: rotate(5deg);
  }

  70% {
    opacity: 1;
    transform: scale(1);
  }

  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1; /* Opacity fades to 0 in the last 30% */
    transform: scale(1); /* Keep the scale as in the last keyframe */
  }
}
</style>
