<template>
  <div :class="'media-container-' + type + (subType ? ' media-container-sub-' + subType : '')" :style="containerStyle"
       class="media-container" v-if="source">
    <span v-if="type === 'text'" :class="(xl ? 'media-text-xl' : '')" class="media-text">{{ textSource }}</span>
    <span v-if="type === 'mixed'" :class="(xl ? 'media-mixed-xl' : '')" class="media-mixed"
          v-html="mixedSource"></span>
    <img v-if="type === 'image'" :src="imageSource" class="media-image"/>
    <img v-if="type === 'gif'" ref="gifContainer" :src="imageSource" class="media-image media-gif"
         @click="reloadGif"/>
    <iframe
      v-if="type === 'frame'"
      :src="frameSource"
      allowfullscreen
      class="media-frame"
      frameborder="0"
      mozallowfullscreen
      webkitallowfullscreen
    ></iframe>
  </div>
</template>
<script>
// npm i --save youtube-regex vimeo-regex
import * as RegexYouTube from 'youtube-regex'
import * as RegexVimeo from 'vimeo-regex'
import ImageLibrary from '@/lib/image-library'

export default {
  name: 'MediaContainer',
  props: {
    source: {
      type: String,
      required: true
    },
    width: {
      default: 640
    },
    height: {
      default: 360
    },
    xl: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    // The RegExp call makes sure that we remove the global param from the regex meaning
    // we'll be able continuously call "exec" without getting NULL half the time

    const regexYoutube = RegExp(RegexYouTube())
    const regexVimeo = RegexVimeo()

    const imageLibraryKeys = Object.keys(ImageLibrary)

    return {
      regexYoutube: regexYoutube,
      regexVimeo: regexVimeo,
      imageLibraryKeys: imageLibraryKeys,
    }
  },
  methods: {
    matchRegex (regex, source) {
      if (!source) {
        return null;
      }

      regex.lastIndex = 0

      return regex.exec(source)
    },
    reloadGif () {
      this.$refs.gifContainer.src = this.imageSource
    }
  },
  computed: {
    containerStyle () {
      let width = this.width
      let height = this.height

      if (width === 640 && height === 360 && (this.isYoutube || this.isVimeo)) {
        width = '100%;'
        height = 500
      }

      if (typeof (width) === 'number') {
        width = width + 'px'
      }

      if (typeof (height) === 'number') {
        height = height + 'px'
      }

      return {
        width: width,
        height: height
      }
    },
    subType () {
      if (this.isYoutube) {
        // It's a youtube URL
        return 'video video-youtube'
      } else if (this.isVimeo) {
        // It's a vimeo URL
        return 'video video-vimeo'
      }

      return null
    },
    type () {
      if (!this.source) {
        return 'none';
      }

      if (this.source.match(/^https?\:\/\//)) {
        // It's an external url
        return 'frame'
      } else if (this.source.match(/\.(png|jpg|jpeg)$/)) {
        return 'image'
      } else if (this.source.match(/\.(gif)$/)) {
        return 'gif'
      } else {
        return 'text'
      }
    },
    isVimeo () {
      if (!this.source) {
        return false;
      }

      return this.regexVimeo.test(this.source)
    },
    isYoutube () {
      if (!this.source) {
        return false;
      }

      return this.regexYoutube.test(this.source)
    },
    textSource () {
      if (!this.source) {
        return false;
      }

      return this.source
    },
    mixedSource () {
      if (!this.source) {
        return false;
      }

      return this.source
    },
    imageSource () {
      if (!this.source) {
        return false;
      }

      if (this.imageLibraryKeys.includes(this.source)) {
        return ImageLibrary[this.source];
      }

      return this.source
    },
    frameSource () {
      if (!this.source) {
        return false;
      }

      if (this.isVimeo) {
        let executionResult = this.matchRegex(this.regexVimeo, this.source)

        if (executionResult) {
          let vimeoId = executionResult[5] || executionResult[4]

          return `https://player.vimeo.com/video/${vimeoId}`;
        }
      }

      if (this.isYoutube) {
        let executionResult = this.matchRegex(this.regexYoutube, this.source)

        if (executionResult) {
          let youtubeId = executionResult[1]

          return `https://www.youtube.com/embed/${youtubeId}`;
        }
      }

      // Just output the raw source
      return this.source
    }
  }
}
</script>

<style lang="scss" scoped>
.media-container {
  text-align: center;
  margin: 0 auto 15px auto;
  max-width: 100%;
}

.media-frame {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.media-image {
  max-width: 100%;
  max-height: 100%;
}

.media-gif:hover {
  cursor: pointer;
}
</style>
