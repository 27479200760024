<template>
  <div class="user-form">
    <div class="title-line">
      <h3>{{ this.user ? `Edit user ${username}` : 'Create new user' }}</h3>
    </div>

    <div class="input-row">
      <label for="username">Username</label>
      <input type="text" id="username" v-model="username" required>
    </div>

    <div class="input-row">
      <label for="password">Password</label>
      <input type="password" id="password" v-model="password"
             :placeholder="this.user === null ? '' : '**************'" required>
    </div>

    <div class="input-row" v-if="user?.id !== this.signedInUser?.id">
      <label for="role">Role</label>
      <bootstrap-select :options="roleOptions" v-model="role"/>
    </div>

    <div>
      <button class="btn btn-success" @click="onClickSave" :disabled="isSaveDisabled">Save</button>
      <button class="btn btn-danger" @click="onClickCancel">Cancel</button>
    </div>

  </div>
</template>

<script>
import AdminUserRow from '@/components/Admin/AdminUserRow.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleSlider from '@/components/common/ToggleSlider.vue'
import { mapState } from 'vuex'
import Sidebar from '@/components/Admin/Sidebar.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'

export default {
  name: 'UserForm',
  components: {
    BootstrapSelect,
    Sidebar,
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    Lightbox,
    AdminUserRow,
  },
  data () {
    return {
      role: 'facilitator',
      roleOptions: [
        {
          value: 'facilitator',
          label: 'Facilitator'
        },
        {
          value: 'admin',
          label: 'Admin'
        },
      ],
      username: '',
      password: '',
      user: null,
    }
  },
  computed: {
    ...mapState(['signedInUser']),
    isSaveDisabled () {
      if (this.username === '' || (this.password === '' && this.user === null)) {
        return true
      }

      if (this.user !== null) {
        if (this.username === this.user.username && this.role === this.user.role && this.password === '') {
          return true
        }
      }

      return false
    },
  },
  mounted () {
    if (this.$route.params.id) {
      this.$apiClient.backend.getUser(this.$route.params.id).then((data) => {
        this.user = data

        this.username = this.user.username
        this.role = this.user.role
      })
    }

  },
  methods: {
    onClickCancel () {
      this.$router.push({ name: 'UserIndex' })
    },
    onClickSave () {
      if (this.user === null) {
        this.$apiClient.backend.createUser(this.username, this.role, this.password).then((data) => {
          this.$router.push({ name: 'UserIndex' })
        })
      } else {
        this.$apiClient.backend.updateUser(this.user.id, this.username, this.role, this.password).then((data) => {
          if (this.user.id === this.signedInUser.id) {
            // We have changed the current user, so let's make sure to update them in the store
            this.$store.commit('signedInUser', data)
          }

          this.$router.push({ name: 'UserIndex' })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.backend-container {
  margin: 0 auto;

  .btn {
    margin-left: .5em;
    height: 2.2rem;
    padding: .4em 1em;
  }

  select, input {
    width: 100%;
  }

  .input-row {
    margin-bottom: 2em;
  }

  .user-form {
    width: 50%;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, p, div {
    text-align: center;
  }

}

h1, h2, h3, h4, h5, p, div {
  text-align: left;
}
</style>
