export default {
  'beforePrioritization': {
    'identifier': 'beforePrioritization',
    'text': 'Now it\'s time to prioritize hazards on the map',
    'subText': null,
    'duration': 4000,
  },
  'beforeEpiReport': {
    'identifier': 'beforeEpiReport',
    'text': 'Here is the EPI report for month [MONTH]',
    'subText': null,
    'duration': 4000,
  },
  'beforeEndDecision': {
    'identifier': 'beforeEndDecision',
    'text': 'Now it\'s time to make the decision',
    'subText': 'This is your final desicion about the event. What do you do? Your decision has to be unanimous. ',
    'duration': 8000,
  },
  'beforeEndResult': {
    'identifier': 'beforeEndResult',
    'text': 'Time to see how you did',
    'subText': 'Thank you for playing the game. ',
    'duration': 4000,
  },
  'beforeMonthStart': {
    'identifier': 'beforeMonthStart',
    'text': 'Month [MONTH] is starting...',
    'subText': null,
    'duration': 4000,
  },
  'beforeStarMinigame': {
    'identifier': 'beforeStarMinigame',
    'text': 'Time to show that you\'re a STAR!',
    'subText': null,
    'duration': 4000,
  },
};