function replaceCurrentHost(value) {
  return value.replace(/\[CURRENTHOST\]/g, window.location.host);
}

export function getApiBaseUrl() {
  return replaceCurrentHost(process.env.VUE_APP_BASE_API_URL);
}

export function getWebsocketAddress() {
  return replaceCurrentHost(process.env.VUE_APP_WEBSOCKET_ADDRESS);
}
