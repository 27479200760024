<template>
  <div class="backend-view" v-if="isLoggedIn">
    <sidebar/>

    <div class="backend-container">
      <router-view/>
    </div>
  </div>
  <div class="backend-login" v-else>
    <backend-login-view @logged-in="onLoggedIn" />
  </div>
</template>
<script>
import Sidebar from '@/components/Admin/Sidebar.vue'
import BackendLoginView from '@/views/backend/login/form.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { mapGetters } from 'vuex'

export default {
  name: 'BackendView',
  components: {
    BackendLoginView,
    Sidebar,
  },
  computed: {
    ...mapGetters(['signedInUser']),
    isLoggedIn () {
      return this.signedInUser && this.signedInUser.id;
    },
  },
  mounted() {
    this.$apiClient.setErrorHandler(this.onError);

    this.$store.commit('backgroundBlingTop', false)
    this.$store.commit('backgroundBlingBottom', false)
    this.$store.commit('background', 'backend');

    // Save JWT to sessionStorage
    const authorizationHeader = this.$apiClient.http.defaults.headers.common['authorization'];

    if (authorizationHeader) {
      try {
        this.$apiClient.backend.getUserFromToken().then((user) => {
          this.onLoggedIn(user);
        });
      } catch (e) {
        // could not load initial JWT info
      }
    }

  },
  methods: {
    onLoggedIn(user) {
      if (!user) {
        console.log('No user specified');

        return;
      }

      this.$store.commit('signedInUser', user)

      console.log('onLoggedIn', user);

      let mainPageName = 'RoomIndex'

      if (user.role === 'admin' || user.role === 'rootadmin') {
        mainPageName = 'UserIndex'
      }


      console.log('router pushing', mainPageName);
      this.$router.push({ name: mainPageName })
    },
    onError(e) {
      console.log('ON ERROR', e.message)

      toast(e.message, {
        type: toast.TYPE.ERROR,
      });
    }
  },
  unmounted () {
    this.$apiClient.setErrorHandler(undefined);
  }
}
</script>

<style lang="scss" scoped>
.backend-view {
  width: 100%;
  height: 100%;
}
</style>
