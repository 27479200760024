<template>
  <div class="star-minigame-screen" :class="'star-minigame-screen-size-' + screenSize">
    <div class="star-minigame-contents">
      <slot />
    </div>
    <div class="star-minigame-footer" v-if="screenSize !== 'full'">
      <div class="star-minigame-buttons d-flex gap-3 justify-content-center">
        <p>
          <span class="v-align">{{ $t('starMinigame.documentHelpText') }}</span>
        </p>
        <app-button
          class="star-minigame-document-button"
          color="green"
          width="fit-content"
          height="fit-content"
          :state="currentDocumentIdentifier === 'strategicToolkit' ? 'active' : null"
          @click="changeDocumentIdentifier('strategicToolkit')"
        >
          <div class="star-minigame-button-contents">
            <div><img src="@/assets/icons/icon-document.png" /></div>
            <label>{{ $t('starMinigame.buttonStrategicToolkit')}}</label>
          </div>
        </app-button>
        <app-button
          class="star-minigame-document-button"
          color="green"
          width="fit-content"
          height="fit-content"
          :state="currentDocumentIdentifier === 'starDocuments' ? 'active' : null"
          @click="changeDocumentIdentifier('starDocuments')"
          :disabled="currentDocumentForced"
        >
          <div class="star-minigame-button-contents">
            <div><img src="@/assets/icons/icon-document.png" /></div>
            <label>{{ $t('starMinigame.buttonStarDocuments')}}</label>
          </div>
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import LongButton from '@/components/common/LongButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'StarMinigameScreen',
  components: {
    AppButton,
    NoDragImage,
    LongButton,
  },
  props: {
    currentDocumentIdentifier: {
      type: String,
    },
    currentDocumentForced: {
      type: Boolean,
      required: false,
      default: false,
    },
    screenSize: {
      type: String,
      required: false,
      default: 'normal',
    }
  },
  emits: ['continue', 'document-identifier-change'],
  methods: {
    changeDocumentIdentifier(documentIdentifier) {
      this.$emit('document-identifier-change', documentIdentifier);
    },
  }
}
</script>

<style lang="scss" scoped>
$screenWidth: 1580px;

.star-minigame-screen {
  &-size-normal {
    .star-minigame-contents {
      width: $screenWidth;
      height: 780px;
    }
  }

  &-size-full {
    .star-minigame-contents {
      width: $screenWidth;
      height: 1000px;
    }
  }
}

.star-minigame-contents {
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 60px;

  background: url("@/assets/textures/grunge-seamless.png") repeat #184E5A;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.60), 4px 4px 3px 0px rgba(255, 255, 255, 0.40) inset, -5px -5px 4px 0px rgba(0, 0, 0, 0.40) inset;
}

.star-minigame-button-contents {
  padding: 10px;

  img {
    margin-bottom: 10px;
  }

  label {
    padding: 10px;
    max-width: 120px;
    text-align: center;
  }
}

.star-minigame-footer {
  padding: 30px;
}

.star-minigame-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background: url("@/assets/textures/grunge-seamless.png") repeat;
}

.star-minigame-buttons {
  p {
    position: relative;
    width: 400px;
    color: white;
    text-align: right;
    font-size: 14pt;

    .v-align {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
