<template>
  <div class="tutorial-container" v-if="currentTutorial">
    <div class="tutorial-box" :style="{
      top: currentTutorial.top ?? 'unset',
      left: currentTutorial.left ?? 'unset',
      bottom: currentTutorial.bottom ?? 'unset',
      right: currentTutorial.right ?? 'unset',
    }">

      <div class="tutorial-content">
        <div>
          <markdown-text style="text-align:left">{{ currentTutorial.text }}</markdown-text>
        </div>
      </div>
      <div class="footer-button tutorial-footer">
        <app-button color="green" width="xl" height="xl" @click="progressTutorial">{{ $t('tutorial.understood') }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import Lightbox from '@/components/common/Lightbox.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import VersionContainer from '@/components/common/VersionContainer.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'TutorialOverlay',
  components: {
    MarkdownText,
    VersionContainer,
    Lightbox,
    AppButton,
  },
  emits: ['progressTutorial'],
  methods: {
    progressTutorial () {
      this.$emit('progressTutorial', this.tutorialId)

      this.clearElementHighlights()
      this.tutorialsRead = this.tutorialId

      if (this.tutorialId === this.tutorials.length - 1) {
        this.$gameClient.roomCallMethod('pauseMode:disable')
      } else {
        this.tutorialId += 1
        this.addElementHighlights()
      }

    },
    clearElementHighlights () {
      for (const element of this.tutorialElements) {
        element.classList.remove('tutorial-highlight')
      }
    },
    addElementHighlights () {
      for (const element of this.tutorialElements) {
        element.classList.add('tutorial-highlight')
      }
    },
  },
  computed: {
    ...mapState(['showTutorial']),
    tutorialElements () {
      const currentTutorial = this.currentTutorial;

      if (!currentTutorial || !currentTutorial.element) {
        console.log('tutorialElements.noTutorialIdElement', this.currentTutorial, this.tutorialId);

        return [];
      }

      const tutorialElements = document.querySelectorAll('.' + this.tutorials[this.tutorialId].element);

      console.log('tutorialElements', tutorialElements);

      return tutorialElements;
    },
    currentTutorial() {
      if (!this.showTutorial) {
        console.log('currentTutorial.showTutorial = false');

        return;
      }

      if (!this.tutorials) {
        console.log('currentTutorial.noTutorials', this.tutorials, this.tutorialId);

        return;
      }

      if (!this.tutorialId) {
        console.log('currentTutorial.noTutorialId', this.tutorials, this.tutorialId);

        return;
      }

      return this.tutorials[this.tutorialId];
    }
  },
  mounted () {
    this.addElementHighlights()
  },
  data () {
    return {
      tutorialsRead: 0,
      tutorialId: 0,
      tutorials: tutorials
    }
  },
}
</script>

<style lang="scss" scoped>
.tutorial-container {
  width: 1900px;
  height: 1000px;
}

.tutorial-box {
  position: absolute;
  z-index: 6001;
  max-width: 900px;
}

.tutorial-highlight {
  position: inherit;
  z-index: 5001 !important;
  filter: drop-shadow(0px 0px 20px #A0D530);
}

.tutorial-content {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #b7b7b7;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  padding: 50px;

  .markdown-text p {
    text-align: left !important;
    color: black !important;
    font-size: 16pt;
  }

}

.tutorial-footer {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #04253a;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  padding: 15px;
  text-align: center !important;
}

</style>
