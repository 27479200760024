/**
 * Appends query parameters to a given URL.
 *
 * @param {string} url - The original URL to which query parameters will be appended.
 * @param {Object} params - An object containing key-value pairs that represent the query parameters.
 * @param {boolean} allowEmptyValues - Whether to allow empty values
 * @returns {string} - The new URL with query parameters appended.
 *
 * @example
 * const originalUrl = "https://example.com/api/resource";
 * const params = { foo: "bar", baz: 42, foobaz: null, validOnly: true, isValid: false };
 * const newUrl = addGetParamsToUrl(originalUrl, params);
 * // newUrl will be "https://example.com/api/resource?foo=bar&baz=42&foobaz&validOnly=1&isValid=0"
 */
export function addGetParamsToUrl(url, params = {}, allowEmptyValues = true) {
  const urlParts = [];
  const paramNames = Object.keys(params);

  for (const param of paramNames) {
    let paramValue = params[param];

    if (paramValue === true) {
      paramValue = 1;
    } else if (paramValue === false) {
      paramValue = 0;
    }

    const queryParamEncoded = encodeURIComponent(param);

    if (paramValue === null || paramValue === '') {
      if (!allowEmptyValues) {
        continue;
      }

      urlParts.push(`${queryParamEncoded}`);
    } else {
      const queryValueEncoded = encodeURIComponent(paramValue);

      urlParts.push(`${queryParamEncoded}=${queryValueEncoded}`);
    }
  }

  // If there's no question mark in the URL, then add a questionmark, otherwise use an ampersand
  const urlGlue = url.indexOf('?') < 0 ? '?' : '&';

  return `${url}${urlGlue}${urlParts.join('&')}`;
}
