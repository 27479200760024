<template>
  <!--<div class="star-minigame-dev">
    {{ currentState }} / {{ currentDocumentIdentifier || 'none' }} // {{ currentTutorialState }} / {{ currentVoteState }} / {{  currentHazardIndex }}
  </div>-->
  <star-minigame-invitation-popup v-if="currentState === State.NOT_BEGUN" @continue="onInvitationContinue" :can-interact="canInteract"/>
  <star-minigame-screen v-else
                        :current-document-identifier="currentDocumentIdentifier"
                        @document-identifier-change="onDocumentIdentifierChange"
                        :current-document-forced="currentState === State.FORCED_DOCUMENT"
                        :screen-size="currentState === State.RISK_MATRIX ? 'full' : 'normal'"
  >
    <h1 class="screen-title">{{ screenTitle }}</h1>

    <iframe v-if="currentDocumentIdentifierUrl"
            :key="selectedDocumentIdentifier"
            :src="currentDocumentIdentifierUrl"
            class="document-iframe"
    />

    <div v-else class="content-container">
      <star-minigame-overview
          v-if="computedState === State.OVERVIEW"
          :current-hazard-index="currentHazardIndex"
          :current-vote-index="playerVoteHazardIndex"
          @current-vote-index-changed="onPlayerChoiceHazard"
          :current-tutorial-state="currentTutorialState"
          :hazard-results="hazardResults"
          :players="players"
          :can-interact="canInteract"
      />
      <star-minigame-assessment-likelihood
          v-else-if="currentAssessment === 'likelihood'"
          :hazard-index="currentHazardIndex"
          :player-likelihood-index="playerVoteLikelihoodIndex"
          @player-choice="onPlayerChoiceLikelihood"
          :can-interact="canInteract"
      />
      <star-minigame-assessment-impact
          v-else-if="currentAssessment === 'impact'"
          :hazard-index="currentHazardIndex"
          :likelihood-index="currentHazardLikelihoodIndex"
          :player-likelihood-index="playerVoteLikelihoodIndex"
          :player-impact-index="playerVoteImpactIndex"
          @player-choice="onPlayerChoiceImpact"
          :can-interact="canInteract"
      />
      <div class="risk-matrix-container" v-else-if="computedState === State.RISK_MATRIX && hazardDataOld && hazardDataCorrect && hazardDataNew">
        <div class="risk-matrix-center-container">
          <star-minigame-risk-matrix
            :hazard-data-old="hazardDataOld"
            :hazard-data-correct="hazardDataCorrect"
            :hazard-data-new="hazardDataNew"
          />
        </div>
      </div>
    </div>
  </star-minigame-screen>
</template>
<script>
import StarMinigameScreen from './StarMinigameScreen.vue'
import StarMinigameInvitationPopup from './StarMinigameInvitationPopup.vue'
import StarMinigameOverview from '@/components/StarMinigame/StarMinigameOverview.vue'
import StarMinigameAssessmentLikelihood from '@/components/StarMinigame/StarMinigameAssessmentLikelihood.vue'
import StarMinigameAssessmentImpact from '@/components/StarMinigame/StarMinigameAssessmentImpact.vue'
import { State, TutorialState, VoteState } from './constants';
import StarMinigameRiskMatrix from '@/components/StarMinigame/RiskMatrix/index.vue'
import {
  getImpactIdentifier,
  getLikelihoodIdentifier,
  getStarData,
  getStarDataCorrect
} from '@/components/StarMinigame/star-utils'

export default {
  name: 'StarMinigame',
  components: {
    StarMinigameRiskMatrix,
    StarMinigameAssessmentLikelihood,
    StarMinigameAssessmentImpact,
    StarMinigameOverview,
    StarMinigameInvitationPopup,
    StarMinigameScreen,
  },
  emits: [
    'continue',
    'player-vote-hazard-index-changed',
    'player-vote-likelihood-index-changed',
    'player-vote-impact-index-changed',
  ],
  props: {
    playerVoteHazardIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    playerVoteLikelihoodIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    playerVoteImpactIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    players: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    currentState: {
      type: Number,
      default: State.NOT_BEGUN,
      required: false,
    },
    currentTutorialState: {
      type: Number,
      default: TutorialState.NONE,
      required: false,
    },
    currentVoteState: {
      type: Number,
      default: VoteState.NONE,
      required: false,
    },
    currentHazardIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    hazardResults: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    canInteract: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data () {
    const hazardDataOld = [];
    const hazardDataCorrect = [];

    const StarData = getStarData();

    for (const dataRow of StarData) {
      hazardDataOld.push({
        hazardName: dataRow.hazardName,
        likelihood: dataRow.computedLikelihood,
        impact: dataRow.computedImpact,
      });
    }

    const StarDataCorrect = getStarDataCorrect();

    for (const dataRow of StarDataCorrect) {
      hazardDataCorrect.push({
        hazardName: dataRow.hazardName,
        likelihood: dataRow.computedLikelihood,
        impact: dataRow.computedImpact,
      });
    }

    return {
      State,
      TutorialState,
      VoteState,

      selectedDocumentIdentifier: null,
      documentsFiles: {
        strategicToolkit: 'star_minigame.pdf',
        starDocuments: 'star_minigame.pdf',
      },

      hazardDataOld: hazardDataOld,
      hazardDataCorrect: hazardDataCorrect,
      hazardDataNew: hazardDataCorrect,
    };
  },
  watch: {
    hazardResults() {
      this.recalculateHazardDataNew();
    },
    currentState() {
      if (this.currentState === State.RISK_MATRIX) {
        this.recalculateHazardDataNew();
      }
    },
  },
  computed: {
    screenTitle() {
      if (this.currentDocumentIdentifier === 'strategicToolkit') {
        return this.$t('starMinigame.titleDocumentStrategicToolkit');
      } else if (this.currentDocumentIdentifier === 'starDocuments') {
        return this.$t('starMinigame.titleDocumentStarDocument');
      }

      if (this.currentState === State.OVERVIEW) {
        if (this.currentTutorialState !== TutorialState.NONE) {
          return this.$t('starMinigame.titleTutorial');
        } else {
          return this.$t('starMinigame.titleOverview');
        }
      }

      if (this.currentState === State.VOTE) {
        if (this.currentVoteState === VoteState.LIKELIHOOD) {
          return this.$t('starMinigame.titleLikelihoodVote');
        } else if (this.currentVoteState === VoteState.IMPACT) {
          return this.$t('starMinigame.titleImpactVote');
        }
      }

      if (this.currentState === State.RISK_MATRIX) {
        return this.$t('starMinigame.titleRiskMatrix');
      }

      return this.$t('starMinigame.titleDefault');
    },
    currentHazardResult() {
      for (const hazardResult of this.hazardResults) {
        if (hazardResult.index === this.currentHazardIndex) {
          return hazardResult;
        }
      }

      return null;
    },
    currentHazardLikelihoodIndex() {
      const hazardResult = this.currentHazardResult;

      if (!hazardResult) {
        return -1;
      }

      return hazardResult.voteLikelihood;
    },
    currentHazardImpactIndex() {
      const hazardResult = this.currentHazardResult;

      if (!hazardResult) {
        return -1;
      }

      return hazardResult.voteImpact;
    },
    isTutorial() {
      if (this.currentState !== State.TUTORIAL) {
        return false;
      }

      return this.currentTutorialState !== TutorialState.NONE;
    },
    computedState() {
      if (this.isTutorial) {
        return State.OVERVIEW;
      }

      return this.currentState;
    },
    currentAssessment() {
      const isAssessmentPage = this.currentState === State.VOTE || this.currentState === State.TUTORIAL;

      if (!isAssessmentPage) {
        return;
      }

      if (this.currentHazardIndex < 0) {
        return; // No hazard index
      }

      const isLikelihood = this.currentVoteState === VoteState.LIKELIHOOD
          || [
            TutorialState.FREQUENCY,
            TutorialState.SEASONALITY,
            TutorialState.LIKELIHOOD
          ].includes(this.currentTutorialState);

      const isImpact = this.currentVoteState === VoteState.IMPACT
          || [
            TutorialState.SEVERITY,
            TutorialState.VULNERABILITY,
            TutorialState.COPING_CAPACITY
          ].includes(this.currentTutorialState);

      console.log('ASSESSMENT PAGE', isLikelihood, isImpact);

      if (isLikelihood) {
        return 'likelihood';
      } else if (isImpact) {
        return 'impact';
      }

      return null;
    },
    currentDocumentIdentifierUrl () {
      if (!this.currentDocumentIdentifier) {
        return
      }

      if (!this.documentsFiles[this.currentDocumentIdentifier]) {
        throw new Error(`Could not find document with identifier ${this.currentDocumentIdentifier}`)
      }

      const file = this.documentsFiles[this.currentDocumentIdentifier]

      if (!process.env.VUE_APP_FILE_BASE_URL) {
        throw new Error(`Could not find VUE_APP_FILE_BASE_URL`)
      }

      const baseFileUrl = process.env.VUE_APP_FILE_BASE_URL.replace(/\/+$/, '')

      return `${baseFileUrl}/${file}`
    },
    currentDocumentIdentifier () {
      if (this.currentState === State.FORCED_DOCUMENT) {
        return 'strategicToolkit'
      }

      return this.selectedDocumentIdentifier
    },
  },
  methods: {
    recalculateHazardDataNew() {
      if (!this.hazardResults) {
        return;
      }

      const StarData = structuredClone(getStarData());

      const hazardDataNew = [];

      for (const result of this.hazardResults) {
        const dataRow = StarData[result.hazardIndex];

        dataRow.computedLikelihoodRaw = result.voteLikelihood;
        dataRow.computedImpactRaw = result.voteImpact;
        dataRow.computedLikelihood = getLikelihoodIdentifier(dataRow.computedLikelihoodRaw);
        dataRow.computedImpact = getImpactIdentifier(dataRow.computedImpactRaw);
      }

      for (let i = 0, len = StarData.length; i < len; i++) {
        const dataRow = StarData[i];

        hazardDataNew.push({
          hazardName: dataRow.hazardName,
          likelihood: dataRow.computedLikelihood,
          impact: dataRow.computedImpact,
        });
      }

      console.log('hazardDataNew', hazardDataNew);

      this.hazardDataNew = hazardDataNew;
    },
    onPlayerChoiceHazard(hazardIndex) {
      this.$emit('player-vote-hazard-index-changed', hazardIndex)
    },
    onPlayerChoiceLikelihood(likelihoodIndex) {
      this.$emit('player-vote-likelihood-index-changed', likelihoodIndex)
    },
    onPlayerChoiceImpact(impactIndex) {
      this.$emit('player-vote-impact-index-changed', impactIndex)
    },
    onInvitationContinue () {
      this.$emit('continue', 'invitation')
    },
    onDocumentIdentifierChange (documentIdentifier) {
      if (this.currentState === State.FORCED_DOCUMENT) {
        // Ignore any click if the state is intro

        return
      }

      if (this.selectedDocumentIdentifier === documentIdentifier) {
        this.selectedDocumentIdentifier = null
      } else {
        this.selectedDocumentIdentifier = documentIdentifier
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.document-iframe, .content-container {
  width: 100%;
  height: calc(100% - 45px);
}

.star-minigame-dev {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  font-family: Consolas, monaco, monospace;
  font-size: 12px;
  color: white;
  z-index: 999999999999999999;
  text-align: right;
  padding: 10px;
  pointer-events: none;
}

.risk-matrix-container {
  margin: 0 auto;
  background: #3f3f3f;
  padding: 150px;
  width: 100%;
  height: 100%;
  position: relative;

  .risk-matrix-center-container {
    position: absolute;
    top: 50%;
    left: 195px;
    transform: translateY(-50%);
  }
}

.screen-title {
  font-weight: bold;
  font-size: 20pt;
  color: white;
  text-align: left;
  padding: 15px 30px 0px 30px;
  height: 45px;
  overflow: hidden;
}
</style>
