import TimerData from '@/lib/timers'

console.log('TimerData', TimerData);

const timerIdentifiers = Object.keys(TimerData);

export function getTimerDefinition(timerIdentifier) {
  if (!timerIdentifiers.includes(timerIdentifier)) {
    throw new Error(`Could not find timer by identifier ${timerIdentifier}`);
  }

  return TimerData[timerIdentifier];
}

export function getTimerDuration(timerIdentifier, defaultDuration = 120000) {
  try {
    const timerDefinition = getTimerDefinition(timerIdentifier);

    return timerDefinition.duration;
  } catch (e) {
    console.warn(`Could not find timer definition by identifier ${timerIdentifier} - returning the default ${defaultDuration}`)
    return defaultDuration;
  }
}

export function getTimerDurationSeconds(timerIdentifier, defaultDuration = 120) {
  return getTimerDuration(timerIdentifier, defaultDuration * 1000) / 1000;
}
