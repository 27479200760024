<template>
  <button class="long-button" :class="computedClasses">
    <div class="long-button-cost" v-text="cost" v-if="cost > 0" />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'LongButton',
  props: {
    color: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    cost: {
      type: Number,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'normal',
    }
  },
  computed: {
    computedClasses() {
      const classes = [
        'long-button-color-' + this.color,
        'long-button-state-' + (this.state || 'none'),
        'long-button-size-' + this.size,
      ];

      if (this.disabled) {
        classes.push('long-button-disabled');
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
$grungeTexture: url("@/assets/textures/button-grunge-30-percent.png");

$animatedModifierPercent: 30%;

@keyframes animated-button-size {
  0%, 100% {
    width: 100%;
    height: 100%;
  }

  25% {
    width: $animatedModifierPercent;
    height: 100%;
  }

  50% {
    width: 100%;
    height: $animatedModifierPercent;
  }

  75% {
    width: $animatedModifierPercent;
    height: $animatedModifierPercent;
  }
}


.long-button {
  width: 238px;
  height: 71px;
  position: relative;
  /*background-repeat: no-repeat;
  background-size: cover;
  outline: 0;
  border: 0;
  color: white;
  font-size: 14pt;
  font-weight: bold;
  background-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;*/

  //background: $grungeTexture;
  font-weight: 700;
  font-size: 23px;
  color: white;
  outline: 1px;
  border: none;
  border-radius: 5px;
  /* btn */

  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  background-size: cover;
  background-image: $grungeTexture;
  padding: 1px;
  white-space: nowrap;

  &.long-button-size-full-width {
    width: 100%;
    padding: 5px 10px;
    //height: auto;
    white-space: normal !important;
    height: 100%;
  }

  &.long-button-size-fit-content {
    width: fit-content;
    padding: 5px 10px;
  }

  &.long-button-size-super-wide {
    width: 500px;
  }

  &.long-button-size-super-tall {
    height: 300px;
  }

  &.long-button-size-large-block {
    height: 150px;
    width: 150px;
  }

  &.long-button-size-narrow {
    height: 35px;
    line-height: 30px;
    width: 60px;
    padding: 0 !important;
  }

  &.long-button-size-narrow-fit-content {
    height: 35px;
    line-height: 30px;
    width: fit-content;
    padding: 3px 10px !important;
  }

  &.long-button-size-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    overflow: hidden;
    font-size: 28px;
    padding: 0 0 6px 0;
  }

  &.long-button-size-icon-sm {
    height: 30px;
    width: 30px;
    overflow: hidden;
    font-size: 28px;
    line-height: 30px;
    padding: 0 0 6px 0;
  }

  &.long-button-size-stretch {
    width: 100%;
    height: 100%;
  }

  &.long-button-size-animated {
    animation: animated-button-size 5s linear infinite both;
  }

  .long-button-cost {
    position: absolute;
    top: -10px;
    right: -10px;

    background: rgba(160, 212, 48, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.73);
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    line-height: 45px;
    text-align: center;
    font-size: 20pt;
  }

  &:focus {
    outline: 0 !important;
  }

  &.long-button-disabled, &:disabled {
    //background-image: url("@/assets/buttons/btn-disabled.png") !important;

    cursor: not-allowed;

    &,
    &:hover, &.long-button-state-hover,
    &:active, &.long-button-state-active,
    &.long-button-state-inactive {
      box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25) !important;
      background-color: rgba(70, 70, 70, 0.5) !important;
    }

    &.long-button-color-blue, &.long-button-color-orange {
      &,
      &:hover, &.long-button-state-hover,
      &:active, &.long-button-state-active,
      &.long-button-state-inactive {
        box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25) !important;
        background-color: rgba(70, 70, 70, 0.5) !important;
      }
    }
  }

  &.long-button-color-blue {
    background-color: #104169 !important;

    &:hover, &.long-button-state-hover {
      background-color: #548898 !important;
    }

    &:active, &.long-button-state-active {
      background-color: #253D62 !important;

      border: 1px solid #051224 !important;
      //padding: 0 !important;
      padding: 5px 10px;
      box-shadow: inset 0px 0px 2px 1px #071C27, inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-blue-idle.png") !important;
    }
  }

  &.long-button-color-dark-blue {
    background-color: #1C2B3F !important;

    &:hover, &.long-button-state-hover {
      background-color: lighten(#1C2B3F, 5%) !important;
    }

    &:active, &.long-button-state-active {
      background-color: darken(#1C2B3F, 5%) !important;

      border: 1px solid lighten(#1C2B3F, 5%) !important;
      //padding: 0 !important;
      padding: 5px 10px;
      box-shadow: inset 0px 0px 2px 1px darken(#1C2B3F, 5%), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-blue-idle.png") !important;
    }
  }

  &.long-button-color-orange {
    background-color: #C97800 !important;
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);

    text-shadow: 0px 4.83846px 4.83846px rgba(0, 0, 0, 0.55);

    &:hover, &.long-button-state-hover {
      background-color: #FFB03A !important;
      box-shadow: 0px 1px 5px 2px rgba(255, 255, 255, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
    }

    &:active, &.long-button-state-active {
      background-color: #AC6700 !important;

      border: 1px solid #051224 !important;
      //padding: 0 !important;
      padding: 5px 10px;
      box-shadow: inset 0px 0px 2px 1px #071C27, inset 4px 0px 3px rgba(239, 239, 239, 0.25);

      text-shadow: 0 !important;
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-orange-idle.png") !important;
    }
  }

  &.long-button-color-yellow {
    background-color: #E69317 !important;
    box-shadow: 0px 1.20961px 6.04807px 2.41923px rgba(0, 0, 0, 0.5), inset 0px -4.83846px 2.41923px 1.20961px #071C27, inset -4.83846px 2.41923px 3.62884px rgba(255, 255, 255, 0.25), inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);
    text-shadow: 0px 4.83846px 4.83846px rgba(0, 0, 0, 0.55);

    &:hover, &.long-button-state-hover {
      background-color: lighten(#E69317, 5%) !important;
      box-shadow: 0px 1.20961px 6.04807px 2.41923px rgba(0, 0, 0, 0.5), inset 0px -4.83846px 2.41923px 1.20961px #071C27, inset -4.83846px 2.41923px 3.62884px rgba(255, 255, 255, 0.25), inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);
    }

    &:active, &.long-button-state-active {
      box-shadow: inset 0px 0px 2.41923px 1.20961px #071C27, inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);

      background-color: #3E2500 !important;

      //border-color: #051224;

      //padding: 0 !important;
      padding: 5px 10px;
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-orange-idle.png") !important;
    }
  }

  &.long-button-color-light-red {
    background-color: #CD5966 !important;
    box-shadow: 0px 1.2087px 6.04348px 2.41739px rgba(0, 0, 0, 0.5), inset 0px -4.83478px 2.41739px 1.2087px #071C27, inset -4.83478px 2.41739px 3.62609px rgba(255, 255, 255, 0.25), inset 4.83478px 0px 3.62609px rgba(239, 239, 239, 0.25);

    &:hover, &.long-button-state-hover {
      background-color: lighten(#CD5966, 5%) !important;
    }

    &:active, &.long-button-state-active {
      box-shadow: inset 0px 0px 2.41923px 1.20961px #071C27, inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);

      background-color: darken(#CD5966, 5%) !important;

      padding: 5px 10px;
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-orange-idle.png") !important;
    }
  }

  &.long-button-color-light-green {
    background-color: #96CC31 !important;
    box-shadow: 0px 1.00657px 5.03286px 2.01315px rgba(0, 0, 0, 0.5), inset 0px -4.02629px 2.01315px 1.00657px #071C27, inset -4.02629px 2.01315px 3.01972px rgba(255, 255, 255, 0.25), inset 4.02629px 0px 3.01972px rgba(239, 239, 239, 0.25);

    &:hover, &.long-button-state-hover {
      background-color: lighten(#96CC31, 5%) !important;
    }

    &:active, &.long-button-state-active {
      background-color: darken(#96CC31, 5%) !important;

      padding: 5px 10px;
    }

    &.long-button-state-inactive {
      //background-image: url("@/assets/buttons/btn-orange-idle.png") !important;
    }
  }
}
</style>
<style lang="scss">
.long-button {
  img {
    max-height: 100%;
    aspect-ratio: auto;
    height: 100%;
  }
}
</style>
