<template>
  <div class="debriefing-indicator-category" :class="'debriefing-indicator-category-' + identifier">
    <div class="debriefing-indicator-category-header d-flex" @click="toggleOpen">
      <h1 class="flex-grow-1">{{ name }}</h1>
      <div class="category-capacity-level-container">
        <capacity-level :capacity-level="capacityLevel" />
      </div>
      <div class="category-chevron-container">
        <chevron :model-value="isOpen" />
      </div>
    </div>

    <accordion :open="isOpen">
      <div class="debriefing-indicator-category-groups">
        <debriefing-indicator-group
          class="mb-4"
          v-for="group of groups"
          :name="group.name"
          :description="group.description"
          :indicators="group.jees"
          :jee-scores="jeeScores"
        />
      </div>
    </accordion>
  </div>
</template>
<script>
import WheelOfEvaluation from '@/components/Debriefing/WheelOfEvaluation/index.vue'
import DebriefingIndicatorGroup from '@/components/Debriefing/DebriefingIndicator/DebriefingIndicatorGroup.vue';
import CapacityLevel from '@/components/dashboard/CapacityLevel.vue';
import Chevron from '@/components/common/Chevron.vue';
import Accordion from '@/components/common/Accordion.vue';
import {getCapacityLevelFromJeeScore} from '@/components/dashboard/CapacityLevelUtil';

export default {
  name: 'DebriefingIndicatorCategory',
  components: {Accordion, Chevron, CapacityLevel, WheelOfEvaluation, DebriefingIndicatorGroup },
  props: {
    identifier: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    jeeScores: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    capacityLevel() {
      let totalJeeScore = 0;
      let totalJeeCount = 0;

      for (const group of this.groups) {
        for (const indicator of group.jees) {
          const jeeScore = this.jeeScores[indicator.identifier];

          totalJeeScore += jeeScore;
          totalJeeCount += 1;
        }
      }

      return getCapacityLevelFromJeeScore(totalJeeScore / totalJeeCount);
    },
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>

<style lang="scss" scoped>

@mixin indicator-background($indicatorCategoryIdentifier, $primaryColor, $secondaryColor) {
  .debriefing-indicator-category-#{$indicatorCategoryIdentifier} {
    .debriefing-indicator-category-header {
      background: linear-gradient(180deg, rgba($primaryColor, .6) 0%, rgba($secondaryColor, .6) 100%), rgba(#000, .6);
    }
  }
}

.debriefing-indicator-category-header {
  cursor: pointer;
  padding: 5px 20px;
  height: 80px;

  h1 {
    text-align: left;
    line-height: 70px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 18pt;
    font-weight: bold;
  }
}

.category-capacity-level-container {
  padding: 10px 0;
  transform: scale(.6);
}

.category-chevron-container {
  margin-left: 50px;
  padding: 20px 0;
}

.debriefing-indicator-category-groups {
  background: rgba(0, 0, 0, .20);

  padding: 20px 100px;
}

@include indicator-background('prevent', #0066FF, #004267);
@include indicator-background('detect', #753552, #670000);
@include indicator-background('respond', #299F55, #00671D);
@include indicator-background('poe', #BA9023, #BA9023);
@include indicator-background('chemical', #000000, #000000);
@include indicator-background('radiation', #004655, #004655);
</style>
