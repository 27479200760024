<template>
  <div class="character-thumbnail" :class="computedClasses" :title="computedTitle">
    <no-drag-image v-if="characterImage" :src="characterImage" />
  </div>
</template>

<script>
import CharacterData from '@/data/character-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import CharacterAnon from '@/assets/character/character-images/Character0.png'

export default {
  name: 'CharacterThumbnail',
  components: { NoDragImage },
  props: {
    characterIndex: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
    showBackground: {
      type: Boolean,
      default: true,
      required: false,
    },
    name: {
      type: String,
      default: null,
      required: false,
    }
  },
  computed: {
    computedClasses() {
      return [
        `character-thumbnail-size-${this.size}`,
        `character-thumbnail-background-${this.showBackground ? 'on' : 'off'}`,
      ];
    },
    characterImage() {
      return CharacterData[this.characterIndex] ? CharacterData[this.characterIndex].image : CharacterAnon;
    },
    computedTitle() {
      return this.name || null;
    },
  },
}
</script>

<style lang="scss" scoped>
.character-thumbnail {
  display: inline-block;


  img {
    max-width: 100%;
  }

  background-image: url("@/assets/character/character-thumbnail-background.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: bottom center;

  &.character-thumbnail-background-on {
    overflow-x: hidden;
  }

  // Size
  &.character-thumbnail-size-extra-tiny {
    width: 35px;

    &.character-thumbnail-background-on {
      //background-image: url("@/assets/character/character-thumbnail-bg-tiny.png");
      //padding: 0 1px;
    }
  }

  &.character-thumbnail-size-tiny {
    width: 50px;

    &.character-thumbnail-background-on {
      //background-image: url("@/assets/character/character-thumbnail-bg-tiny.png");
      //padding: 0 1px;
    }
  }

  &.character-thumbnail-size-small {
    width: 70px;

    &.character-thumbnail-background-on {
      //background-image: url("@/assets/character/character-thumbnail-bg-small.png");
      //padding: 0 3px;
    }
  }

  &.character-thumbnail-size-medium {
    width: 150px;

    &.character-thumbnail-background-on {
      //background-image: url("@/assets/character/character-thumbnail-bg-medium.png");
      //padding: 0 4px;
    }
  }

  &.character-thumbnail-size-large {
    width: 250px;

    img {
      width: 100%;
    }

    &.character-thumbnail-background-on {
      //background-image: url("@/assets/character/character-thumbnail-bg-medium.png");
      //padding: 0 8px;
    }
  }
}
</style>
