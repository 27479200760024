import game from './game'
import room from './room'
import player from './player'
import debug from './debug';
import admin from './admin';
import tutorial from './tutorial';

export default [
  game,
  room,
  player,
  debug,
  admin,
  tutorial,
]
