<template>
  <div class="debriefing-score-card">
    <div class="label-container">
      <label>{{ label }}</label>
    </div>
    <div class="icon-container">
      <no-drag-image :src="iconSource" class="icon" />
    </div>
    <div class="score-container" :class="'score-container-' + this.scoreClassification">
      <label>{{ roundedScore }}</label>
    </div>
  </div>
</template>
<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import { getScoreClassification } from '@/utils/algorithmUtil'

export default {
  name: 'DebriefingScoreCard',
  components: { NoDragImage },
  props: {
    iconSource: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: false,
    },
  },
  computed: {
    roundedScore() {
      return Math.round(this.score / 10);
    },
    scoreClassification() {
      return getScoreClassification(this.score);
    },
  }
}
</script>

<style lang="scss" scoped>
$width: 200px;

.debriefing-score-card {
  width: $width;
}

.icon-container {
  //background: url("@/assets/textures/texture-60.png"<path-to-image>), lightgray 50% / cover no-repeat, linear-gradient(180deg, #38708F 0%, #051224 100%);
  //box-shadow: 0px -2.32504940032959px 1.5500329732894897px 0px rgba(0, 0, 0, 0.51) inset, 0px 0.7750164866447449px 0.7750164866447449px 0px rgba(255, 255, 255, 0.50) inset, 0px 3.1000659465789795px 3.1000659465789795px 0px rgba(0, 0, 0, 0.52);

  border-radius: 1.55px;
  background: url("@/assets/textures/texture-20.png"), linear-gradient(180deg, #38708F 0%, #051224 100%);
  background-color: lightgray;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px -2.32504940032959px 1.5500329732894897px 0px rgba(0, 0, 0, 0.51) inset,
  0px 0.7750164866447449px 0.7750164866447449px 0px rgba(255, 255, 255, 0.50) inset,
  0px 3.1000659465789795px 3.1000659465789795px 0px rgba(0, 0, 0, 0.52);


  //background: url("@/assets/debriefing/icon-bg.png") no-repeat;
  //background-size: 100% 100%;
  width: $width;
  height: $width;
  padding: 50px;
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.label-container {
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: white;

  height: 60px;
  max-width: 100%;
}

.score-container {
  border-radius: 2.325px 0px 0px 2.325px;

  &.score-container-positive {
    background: linear-gradient(180deg, rgba(161, 255, 77, 0.70) 29.20%, rgba(11, 34, 3, 0.70) 100%), url("@/assets/textures/texture-60.png");
  }

  &.score-container-neutral {
    background: linear-gradient(180deg, rgba(251, 255, 57, 0.70) 29.20%, rgba(11, 34, 3, 0.70) 100%), url("@/assets/textures/texture-60.png");
  }

  &.score-container-negative {
    background: linear-gradient(180deg, rgba(255, 77, 77, 0.70) 29.20%, rgba(11, 34, 3, 0.70) 100%), url("@/assets/textures/texture-60.png");
  }

  background-color: lightgray;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 20px 0;

  label {
    text-align: center;
    text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    font-size: 70pt;
    color: white;
  }
}
</style>
