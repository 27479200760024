<template>
  <div class="meeting-invite-bar d-flex">
    <icon :icon="icon" class="icon" size="small" />
    <label>Emergency meeting</label>
    <div class="clock-image">
      <no-drag-image src="@/assets/icons/clock-icon.png" />
    </div>
    <label class="timer">{{ formattedTimeLeft }}</label>
    <div class="btn-container">
      <button class="btn-join-now" @click="onClickJoinNow">Join now</button>
      <button class="btn-decline" @click="onClickDecline">Decline</button>
    </div>
  </div>
</template>

<script>
import HazardIcon from '@/components/common/HazardIcon.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import { padLeft } from '@/utils/stringUtil'

export default {
  name: 'MeetingInviteBar',
  components: { NoDragImage, HazardIcon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    secondsLeft: {
      type: Number,
      required: true,
    }
  },
  emits: ['click:join-now', 'click:decline'],
  methods: {
    onClickJoinNow() {
      this.$emit('click:join-now');
    },
    onClickDecline() {
      this.$emit('click:decline');
    }
  },
  computed: {
    formattedTimeLeft() {
      let minutesLeft = Math.floor(this.secondsLeft / 60);
      let secondsLeft = this.secondsLeft - (minutesLeft * 60);

      return `${minutesLeft.toString()}:${padLeft(secondsLeft.toString(), 2, '0')}`
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-invite-bar {
  background: transparent url("@/assets/hazard-popup/meeting-invite-bar.png") no-repeat;
  background-size: contain;

  height: 50px;
  width: 671px;
  position: relative;

  color: white;
  line-height: 50px;
  padding-left: 50px;
  font-size: 16pt;
}

.icon {
  width: 100px;
  position: absolute;
  top: -40px;
  left: -40px;
}

.btn-container {
  position: absolute;
  top: -10px;
  right: 60px;
}

button {
  background-size: contain;
  border: 0;
  outline: 0;
  color: white;
  padding: 0 10px;
}

.btn-join-now {
  background: transparent url("@/assets/hazard-popup/btn-bg-join-meeting.png") no-repeat;
  height: 70px;
  width: 145px;
}

.btn-decline {
  background: transparent url("@/assets/hazard-popup/btn-bg-decline-meeting.png") no-repeat;
  height: 70px;
  width: 105px;
}

.clock-image {
  margin-left: 10px;
}

label.timer {
  color: #ED7979;
  margin-left: 5px;

  text-shadow: 0px 3.88235px 3.88235px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 22pt;
}
</style>
