<template>
</template>

<script>
export default {
  name: 'MainView',
  mounted() {
    console.log('MAIN VIEW MOUNTED');

    if (this.$gameClient.hasJoinedRoom()) {
      this.$gameClient.leaveRoom().then(() => {
        console.log('REDIRECT.1');
        this.$router.push('/lobby');
      })
    } else {
      console.log('REDIRECT.2');
      this.$router.push('/teaser');
    }
  },
}
</script>
