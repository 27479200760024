<template>
  <div class="risk-matrix">
    <table class="risk-matrix-table">
      <tbody>
      <tr>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.critical') }}</td>
        <td v-for="column in tableRows[0]" :class="'bg-level-' + column.level">{{ column.hazardString }}</td>
      </tr>
      <tr>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.severe') }}</td>
        <td v-for="column in tableRows[1]" :class="'bg-level-' + column.level">{{ column.hazardString }}</td>
      </tr>
      <tr>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.moderate') }}</td>
        <td v-for="column in tableRows[2]" :class="'bg-level-' + column.level">{{ column.hazardString }}</td>
      </tr>
      <tr>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.minor') }}</td>
        <td v-for="column in tableRows[3]" :class="'bg-level-' + column.level">{{ column.hazardString }}</td>
      </tr>
      <tr>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.negligible') }}</td>
        <td v-for="column in tableRows[4]" :class="'bg-level-' + column.level">{{ column.hazardString }}</td>
      </tr>
      <tr>
        <td class="bg-blank"><!-- empty --></td>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.veryUnlikely') }}</td>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.unlikely') }}</td>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.likely') }}</td>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.veryLikely') }}</td>
        <td class="bg-black">{{ $t('starMinigameRiskMatrix.almostCertain') }}</td>
      </tr>
      </tbody>
    </table>
    <div class="risk-matrix-sides">
      <div class="risk-matrix-top">
        <div class="risk-matrix-striped-curved-lines"></div>
        <div class="risk-matrix-striped-label-line"></div>
        <div class="risk-matrix-striped-label-triangle risk-matrix-striped-label-triangle-point-down"></div>
        <label>{{ title }}</label>
      </div>
      <div class="risk-matrix-left">
        <div class="risk-matrix-striped-curved-lines"></div>
        <div class="risk-matrix-striped-label-line"></div>
        <div class="risk-matrix-striped-label-triangle risk-matrix-striped-label-triangle-point-right"></div>
        <label>{{ $t('starMinigameRiskMatrix.impact') }}</label>
      </div>
      <div class="risk-matrix-bottom">
        <div class="risk-matrix-striped-curved-lines"></div>
        <div class="risk-matrix-striped-label-line"></div>
        <div class="risk-matrix-striped-label-triangle risk-matrix-striped-label-triangle-point-up"></div>
        <label>{{ $t('starMinigameRiskMatrix.likelihood') }}</label>
      </div>
    </div>

    <star-minigame-risk-matrix-switch v-model="currentSwitchValue" />
  </div>
</template>

<script>
import TableMixins from '@/components/StarMinigame/TableMixins.vue'
import StarMinigameRiskMatrixSwitch from '@/components/StarMinigame/RiskMatrix/RiskMatrixSwitch.vue'
import { RiskMatrixSwitchValue } from '@/components/StarMinigame/constants';

export default {
  name: 'StarMinigameRiskMatrix',
  components: { StarMinigameRiskMatrixSwitch },
  mixins: [TableMixins],
  props: {
    hazardDataOld: {
      type: Array,
      required: true,
    },
    hazardDataNew: {
      type: Array,
      required: true,
    },
    hazardDataCorrect: {
      type: Array,
      required: true,
    }
  },
  data () {
    return {
      RiskMatrixSwitchValue,

      tableRows: [],
      currentSwitchValue: RiskMatrixSwitchValue.OLD,
    }
  },
  mounted () {
    this.computeTableRowsFromHazardData()
  },
  watch: {
    currentSwitchValue() {
      this.computeTableRowsFromHazardData();
    },
    hazardDataNew() {
      this.computeTableRowsFromHazardData();
    },
  },
  computed: {
    title() {
      return this.innerTitle.replace('[YEAR]', (new Date()).getFullYear().toString());
    },
    innerTitle() {
      if (this.currentSwitchValue === RiskMatrixSwitchValue.OLD) {
        return this.$t('starMinigameRiskMatrix.titleOld');
      } else if (this.currentSwitchValue === RiskMatrixSwitchValue.NEW) {
        return this.$t('starMinigameRiskMatrix.titleNew');
      } else if (this.currentSwitchValue === RiskMatrixSwitchValue.CORRECT) {
        return this.$t('starMinigameRiskMatrix.titleCorrect');
      }

      return '';
    },
    hazardData() {
      if (this.currentSwitchValue === RiskMatrixSwitchValue.OLD) {
        return this.hazardDataOld;
      } else if (this.currentSwitchValue === RiskMatrixSwitchValue.NEW) {
        return this.hazardDataNew;
      } else if (this.currentSwitchValue === RiskMatrixSwitchValue.CORRECT) {
        return this.hazardDataCorrect;
      }

      return [];
    }
  },
  methods: {
    computeTableRowsFromHazardData () {
      const tableLevels = [
        [2, 3, 4, 5, 5], // 0
        [2, 3, 4, 4, 5], // 1
        [1, 2, 4, 4, 4], // 2
        [1, 2, 2, 3, 3], // 3
        [1, 1, 1, 2, 2], // 4
      ]

      const tableRows = []

      for (let i = 0; i < 5; i++) {
        const tableColumns = []

        for (let j = 0; j < 5; j++) {
          tableColumns.push({
            level: tableLevels[i][j],
            hazards: [],
            hazardString: '',
          })
        }

        tableRows.push(tableColumns)
      }

      console.log({
        hazardData: this.hazardData,
        currentSwitchValue: this.currentSwitchValue,
      });

      for (const hazardDatum of this.hazardData) {
        if (hazardDatum.impact === null || hazardDatum.impact === false || typeof(hazardDatum.impact) === 'undefined') {
          console.error(`HazardDatum is missing impact`, hazardDatum);
        }

        if (hazardDatum.likelihood === null || hazardDatum.likelihood === false || typeof(hazardDatum.likelihood) === 'undefined') {
          console.error(`HazardDatum is missing likelihood`, hazardDatum);
        }

        const impactLevel = this.getImpactLevel(hazardDatum.impact)
        const likelihoodLevel = this.getLikelihoodLevel(hazardDatum.likelihood)

        /*console.log({
          impactLevel,
          likelihoodLevel,
          impact: hazardDatum.impact,
          likelihood: hazardDatum.likelihood,
        })*/

        tableRows[likelihoodLevel - 1][impactLevel - 1].hazards.push(hazardDatum.hazardName)
      }

      for (const tableRow of tableRows) {
        for (const tableColumn of tableRow) {
          tableColumn.hazardString = tableColumn.hazards.join(', ')
        }
      }

      console.log('tableRows', tableRows)
      console.log('hazardData', this.hazardData)

      this.tableRows = tableRows
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/background-colors.scss";

$columnWidth: 180px;
$columnHeight: 100px;

$sideWidth: 160px;
$sideHeight: 110px;

$stripeSize: 50px;
$labelLineSize: 25px;

$triangleSize: 25px;
$triangleColor: white;

$dashedBorderSize: 5px;
$trianglePush: -12px;

.risk-matrix-table {
  width: $columnWidth * 6;
  height: $columnHeight * 6;
  z-index: 1000;
}

td {
  box-shadow: 4px 4px 3px 0px rgba(255, 255, 255, 0.40) inset, -4px -4px 4px 0px rgba(0, 0, 0, 0.40) inset;
  border: 0.874px solid #000;
  width: $columnWidth;
  height: $columnHeight;
  max-width: $columnWidth;
  font-weight: bold;
  font-size: 14pt;
  text-align: center;
  padding: 10px;
  white-space: pre-line;

  &.bg-blank {
    box-shadow: none !important;
    border: 0 !important;
    background: transparent !important;
    outline: 0;
  }

  &.bg-black {
    background: #082B31;
    color: white;
  }
}

.risk-matrix-top,
.risk-matrix-bottom {
  .risk-matrix-striped-curved-lines {
    width: 100%;
    height: $stripeSize;
  }
}

.risk-matrix-left {
  .risk-matrix-striped-curved-lines {
    height: 100%;
    width: $stripeSize;
  }
}

.risk-matrix-top {
  top: $sideHeight * -1;
  left: 0;
  width: 100%;
  height: $sideHeight;

  .risk-matrix-striped-curved-lines {
    bottom: $stripeSize * -0.5;
  }

  .risk-matrix-striped-label-line {
    left: calc(50% - ($dashedBorderSize / 2));
    bottom: ($stripeSize / 2);

    height: $labelLineSize;
    border-right: $dashedBorderSize dashed white;
  }

  label {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.risk-matrix-left {
  left: $sideWidth * -1;
  top: 0;
  height: calc(100% - $columnHeight);
  width: $sideWidth;

  .risk-matrix-striped-curved-lines {
    right: $stripeSize * -0.5;
  }

  .risk-matrix-striped-label-line {
    top: calc(50% - ($dashedBorderSize / 2));
    right: ($stripeSize / 2);

    width: calc(100% - ($stripeSize / 2));
    border-bottom: $dashedBorderSize dashed white;
  }

  label {
    top: 50%;
    left: 0;
    transform: translateY(-50px);
  }
}

.risk-matrix-bottom {
  bottom: $sideHeight * -1;
  left: $columnWidth;
  width: calc(100% - $columnWidth);
  height: $sideHeight;

  .risk-matrix-striped-curved-lines {
    top: $stripeSize * -0.5;
  }

  .risk-matrix-striped-label-line {
    left: calc(50% - ($dashedBorderSize / 2));
    top: ($stripeSize / 2);

    height: $labelLineSize;
    border-right: $dashedBorderSize dashed white;
  }

  label {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.risk-matrix-top,
.risk-matrix-left,
.risk-matrix-bottom {
  position: absolute;
  font-weight: bold;
  font-size: 18pt;
  color: white;
  //background: red;
}

.risk-matrix-sides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  label {
    position: absolute;
    width: fit-content;
    white-space: nowrap;
  }

  .risk-matrix-striped-label-triangle {
    width: 0;
    height: 0;
    border-style: solid;

    // Right-pointing triangle
    &-point-right {
      border-width: ($triangleSize / 2) 0 ($triangleSize / 2) $triangleSize;
      border-color: transparent transparent transparent $triangleColor;
      top: 50%;
      transform: translateY($trianglePush);
      left: 0;
    }

    // Down-pointing triangle
    &-point-down {
      border-width: $triangleSize ($triangleSize / 2) 0 ($triangleSize / 2);
      border-color: $triangleColor transparent transparent transparent;
      left: 50%;
      transform: translateX($trianglePush);
      bottom: $stripeSize;
    }

    // Up-pointing triangle
    &-point-up {
      border-width: 0 ($triangleSize / 2) $triangleSize ($triangleSize / 2);
      border-color: transparent transparent $triangleColor transparent;
      left: 50%;
      transform: translate($trianglePush, $triangleSize / 2);
      bottom: $stripeSize;
    }
  }

  .risk-matrix-striped-label-triangle,
  .risk-matrix-striped-curved-lines,
  .risk-matrix-striped-label-line {
    position: absolute;
  }

  .risk-matrix-striped-curved-lines {
    border-radius: $stripeSize / 2;
    border: $dashedBorderSize dashed white;
  }
}

.risk-matrix-switch {
  position: absolute;
  right: -270px;
  top: -40px;
}

</style>
