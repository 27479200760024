<template>
  <div class="vcc-frequency-ball" :style="calculatedStyle">
    <div class="vu-pulse" :style="calculatedPulseStyle" />
    <div class="frequency-ball-inner">
      <span v-if="showLabel" class="frequency-label">
        {{ roundFrequency }}
      </span>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrequencyBall',
  props: {
    frequency: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 50,
    },
    multiplier: {
      type: Number,
      default: 1,
    },
    showLabel: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    calculatedStyle() {
      const size = this.size;

      return {
        'width': this.getPx(size),
        'height': this.getPx(size),
        'border-radius': this.getPx(size / 2),
      };
    },
    calculatedPulseStyle() {
      const pulseSizePercent = 100 + (Math.min(this.frequency, 100) * this.multiplier);

      return {
        'transform': `scale(${pulseSizePercent}%, ${pulseSizePercent}%)`,
      };
    },
    roundFrequency() {
      return this.frequency.toFixed(2);
    }
  },
  methods: {
    getPx(size) {
      return size + 'px';
    },
  },
};
</script>

<style lang="scss">
.vcc-frequency-ball {
  display: inline-block;
  position: relative;

  background: #000000;

  img {
    width: 100%;
    height: 100%;
    pointer-events: none !important;
  }

  .vu-pulse {
    width: 100%;
    height: 100%;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,0.5) 35%, rgba(0,212,255,0) 100%);
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }

  .frequency-ball-inner {
    border: 3px solid white;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
  }

  .frequency-label {
    font-family: Consolas, monaco, monospace;
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
