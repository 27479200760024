<template>
  <div class="hazard-action-container-outer">
    <div class="hazard-action-container d-flex flex-column align-items-stretch">
      <div class="hazard-action-header d-flex justify-content-between">
        <span class="hazard-action-cost">Cost: {{ cost }}</span>
      </div>
      <div class="hazard-action-body flex-fill">
        <div class="hazard-action-info epic-scrollbar" ref="scroller">
          <div class="hazard-action-info-inner" v-if="media">
            <no-drag-image :src="media"/>
          </div>
          <div class="hazard-action-info-inner" v-else-if="text">
            {{ text }}
          </div>
        </div>
      </div>
      <div class="hazard-action-investment">
        <hazard-action-investment
          :player-investment="currentPlayerInvestment"
          :total-investment="totalInvestment"
          @update:playerInvestment="setPlayerInvestment"
          :investment-cost="cost"
        />
      </div>
    </div>
    <accordion :open="investmentsOpen">
      <div class="hazard-action-investments d-flex align-items-stretch flex-nowrap justify-content-between">
        <div v-for="investment in investments">
          <character-thumbnail
            v-if="investment.player"
            :character-index="investment.player.characterIndex"
            size="tiny"
          />
        </div>
      </div>
    </accordion>
    <div class="hazard-action-investments-button" @click="investmentsOpen = !investmentsOpen" :class="'hazard-action-investments-button-' + (investmentsOpen ? 'open' : 'closed')">
      See votes {{ investments.length ? '(' + investments.length + ')' : '' }} <span class="expand-triangle"><no-drag-image src="@/assets/icons/triangle-up-hollow.png" /></span>
    </div>
  </div>
</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import HazardActionInvestment from '@/components/HazardMeeting/HazardPopup/HazardActionInvestment.vue'
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import Accordion from '@/components/common/Accordion.vue'

export default {
  name: 'HazardAction',
  components: {
    Accordion,
    PlayerCharacterPlate,
    NoDragImage,
    CharacterPlate,
    HazardActionInvestment,
    CharacterThumbnail,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    investments: {
      type: Array,
      required: false,
      default: [],
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    media: {
      type: String,
      required: false,
      default: '',
    },
    currentPlayerInvestment: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:playerInvestment'],
  computed: {
    totalInvestment () {
      let totalInvestment = 0

      for (const investment of this.investments) {
        totalInvestment += investment.investment
      }

      return totalInvestment
    }
  },
  watch: {
    text() {
      this.$refs['scroller'].scrollTop = 0;
    },
  },
  data () {
    return {
      investmentsOpen: false,
    }
  },
  methods: {
    setPlayerInvestment (playerInvestment) {
      this.$emit('update:playerInvestment', playerInvestment)
    }
  }
}
</script>

<style lang="scss" scoped>
.hazard-action-container-outer {
  //height: 380px;
  position: relative;
  z-index: 10;
  height: 100%;
}

.hazard-action-container {
  margin: 0 auto;
  width: 298px;
  height: calc(100% - 44px);
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  //background: url("@/assets/hazard-popup/hazard-action-bg.png") no-repeat transparent;
}

.hazard-action-body {
  padding: 0 20px;
  margin-bottom: 20px;
  //padding: 25px 10px 10px 10px;
  overflow-y: hidden;
}

.hazard-action-info {
  //width: 100%;
  //height: 180px;
  height: 100%;

  background: rgba(#000000, .33);
  border-radius: 4px;
  font-size: 15pt;
  color: #ffffff;
  padding: 10px;
  text-align: left;
}

.hazard-action-header {
  //background: url("@/assets/hazard-popup/hazard-name-bg.png") no-repeat transparent;
  //background-size: 100% 100%;

  width: 100%;
  height: 79px;

  font-size: 16pt;
  font-weight: 700;
  color: #ffffff;
  line-height: 79px;

  .hazard-action-name {
    padding: 0 20px;
  }

  .hazard-action-cost {
    padding: 0 20px;
    text-align: right;
    white-space: nowrap;


    font-weight: 700;
    font-size: 22pt;
    margin-top: 0;
  }
}

.hazard-action-investments {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  padding: 10px;

  & > div {
    margin-bottom: -15px;
  }
}

.hazard-action-investments-button {
  background: #1C2B3F;
  /* btn */

  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.5), inset 0px -4px 2px 1px #071C27, inset -4px 2px 3px rgba(255, 255, 255, 0.25), inset 4px 0px 3px rgba(239, 239, 239, 0.25);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;

  height: 44px;
  line-height: 44px;

  .expand-triangle {
    display: inline-block;
    margin-left: 10px;
    transition: 200ms ease-in-out;
    transform: rotate(180deg);

    img {
      transform: scale(80%);
    }
  }

  &.hazard-action-investments-button-open {
    .expand-triangle {
      transform: rotate(0deg);
      //transform: scaleY(-100%);
    }
  }
}

.hazard-action-investments {
  padding: 5px 10px;
  height: 70px;
}

.hazard-action-investment {
  min-height: 59px;
  overflow: hidden;
}

.hazard-action-investment,
.hazard-action-investments,
.hazard-action-investments-button {
  position: relative;
  z-index: 1;
}
</style>
