import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

import DevView from '@/views/000-DevView';
import MainView from '@/views/000-MainView';
import TeaserView from '@/views/001-TeaserView.vue';
import IntroView from '@/views/005-IntroView.vue';
import LoginView from '@/views/008-LoginView.vue';
import LobbyView from '@/views/010-LobbyView';
import LeaderboardView from '@/views/015-LeaderboardView.vue';
import GameView from '@/views/020-GameView.vue'
import FacilitatorView from '@/views/050-FacilitatorView.vue';

import UserIndex from '@/views/backend/user/index.vue';
import UserForm from '@/views/backend/user/form.vue';

import RoomIndex from '@/views/backend/room/index.vue';
import RoomForm from '@/views/backend/room/form.vue';

import EventIndex from '@/views/backend/event/index.vue';
import EventForm from '@/views/backend/event/form.vue';
import EventAnalytics from '@/views/backend/event/analytics.vue';

import BackendView from '@/views/backend/BackendView.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView
  },
  {
    path: '/teaser',
    name: 'Teaser',
    component: TeaserView
  },
  {
    path: '/intro',
    name: 'Intro',
    component: IntroView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/lobby',
    name: 'Lobby',
    component: LobbyView
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: LeaderboardView
  },
  {
    path: '/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/facilitator',
    name: 'facilitator',
    component: FacilitatorView
  },
  {
    path: '/backend',
    name: 'BackendIndex',
    component: BackendView,
    children: [
      {
        path: 'user',
        children: [
          {
            path: '',
            name: 'UserIndex',
            component: UserIndex,
          },
          {
            path: 'new',
            name: 'UserCreate',
            component: UserForm,
          },
          {
            path: ':id',
            name: 'UserUpdate',
            component: UserForm,
          }
        ],
      },
      {
        path: 'room',
        children: [
          {
            path: '',
            name: 'RoomIndex',
            component: RoomIndex,
          },
          {
            path: 'new',
            name: 'RoomCreate',
            component: RoomForm,
          },
          {
            path: ':id',
            name: 'RoomUpdate',
            component: RoomForm,
          }
        ],
      },
      {
        path: 'event',
        children: [
          {
            path: '',
            name: 'EventIndex',
            component: EventIndex,
          },
          {
            path: 'new',
            name: 'EventCreate',
            component: EventForm,
          },
          {
            path: ':id/analytics',
            name: 'EventAnalytics',
            component: EventAnalytics,
          },
          {
            path: ':id',
            name: 'EventUpdate',
            component: EventForm,
          },
        ],
      },
    ],
  },
]

const isDev = true;

if (isDev) {
  routes.push({
    path: '/dev',
    name: 'DevMain',
    component: DevView,
    children: [
      {
        path: ':component',
        name: 'DevSub',
        component: DevView,
      },
    ],
  });
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
