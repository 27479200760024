<script>
export default {
  name: 'DevComponentContainer',
  props: {
    theme: {
      type: String,
      default: 'grey',
      required: false,
    },
  },
};
</script>
