import {getConsole, getMockConsole} from '@/utils/getConsole';
import {FrequencyChangedEvent} from "@/plugins/VoiceController/events/FrequencyChangedEvent";
import {
  InputDevicesUpdatedEvent
} from '@/plugins/VoiceController/events/InputDevicesUpdatedEvent';
import { LocalStreamChangedEvent } from '@/plugins/VoiceController/events/LocalStreamChangedEvent'
import { VoiceParticipant } from '@/plugins/VoiceController/lib/VoiceParticipant'
import { ParticipantAddedEvent } from '@/plugins/VoiceController/events/ParticipantAddedEvent'
import { ParticipantRemovedEvent } from '@/plugins/VoiceController/events/ParticipantRemovedEvent'
import {UserMediaError} from '@/plugins/VoiceController/events/UserMediaError';
import { LocalStreamMutedChangedEvent } from '@/plugins/VoiceController/events/LocalStreamMutedChangedEvent'

export class VoiceController extends EventTarget {
  // The console to add a prefix to console when printing
  console;

  // The main DOM element
  element;

  // An array of input devices
  inputDevices;

  // The currently running local stream
  localStream;

  // The current local stream
  localStreamDeviceId;

  // Whether the local stream is muted
  localStreamMuted = false;

  // The video tag attached to the main element
  localVideo;

  // Each key is a room ID
  participants = {};

  constructor(options = {}) {
    super();

    let element = null, useDebugConsole = true;

    if (options) {
      if (options.element) {
        element = options.element;
      }

      if (options.useDebugConsole) {
        useDebugConsole = options.useDebugConsole;
      }
    }

    this.setUseDebugConsole(useDebugConsole);

    if (element) {
      this.element = element;
    } else {
      this.element = document.createElement('div');
      this.element.id = 'voice-controller-element';

      /*this.element.style.border = '1px solid black';
      this.element.style.width = '100%';
      this.element.style.height = '400px';*/
      this.element.style.position = 'fixed';
      this.element.style.display = 'inline-block';
      this.element.style.top = '-1500px';
      this.element.style.left = '-1500px';
      this.element.style.width = '10px';
      this.element.style.height = '10px';
    }

    document.body.appendChild(this.element);
  }

  setUseDebugConsole(useDebugConsole) {
    if (useDebugConsole) {
      this.console = getConsole('VoiceController', 'white', '#005555');
    } else {
      this.console = getMockConsole();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return [];
  }

  closeAudioContextForParticipant(participant) {
    if (participant.audioContext && participant.audioContext.state === 'running') {
      this.console.log('Closing audio context for participant', participant);

      // Clean up previous audio context
      participant.audioContext.close();

      participant.audioContext = null;
      participant.gainNode = null;

      // Reset it!
      this.dispatchEvent(new FrequencyChangedEvent(participant.uid, 0));
    }
  }

  initializeAudioContextForParticipant(uid, stream) {
    const participant = this.getParticipantByUid(uid, true);

    try {
      if (participant.audioContext && participant.audioContext.state === 'running') {
        this.closeAudioContextForParticipant(participant);
      }

      if (!participant.stream) {
        participant.stream = stream;
      }

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
      const gainNode = audioContext.createGain();

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(gainNode);
      microphone.connect(analyser);

      analyser.connect(scriptProcessor);

      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = () => {
        const array = new Uint8Array(analyser.frequencyBinCount);

        analyser.getByteFrequencyData(array);

        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;

        if (average !== participant.frequencyAverage) {
          this.dispatchEvent(new FrequencyChangedEvent(uid, average));

          participant.frequencyAverage = average;
        }
      };

      participant.gainNode = gainNode;
      participant.audioContext = audioContext;

      this.console.log(`Initialized stream with id "${uid}"`, audioContext);
    } catch (e) {
      // Could not set up audio context, perhaps missing permissions
      throw new Error(`Could not set up audio context for socket with id "${uid}": ${e.message}`);
    }
  }

  createParticipant(uid, throwErrorIfAlreadyExists = true) {
    if (this.participants.hasOwnProperty(uid)) {
      if (!throwErrorIfAlreadyExists) {
        return this.participants[uid];
      }

      throw new Error(`Participant with uid ${uid} already existed`);
    }

    const participant = new VoiceParticipant(uid, {
      stream: null,
      videoTag: null,
      volume: 100,
    });

    this.dispatchEvent(new ParticipantAddedEvent(participant));

    this.participants[uid] = participant;

    return participant;
  }

  getParticipantByUid(uid, createIfMissing) {
    console.log('getParticipantByUid', uid, createIfMissing);

    if (!this.participants.hasOwnProperty(uid)) {
      if (!createIfMissing) {
        throw new Error(`Could not find room participant by id ${uid} and createIfMissing was false`);
      }

      this.createParticipant(uid);
    }

    return this.participants[uid];
  }

  setParticipantVolume(uid, volume) {
    const participant = this.getParticipantByUid(uid);

    if (!participant) {
      this.console.error(`Could not find participant by uid ${uid}`, this.participants);

      return;
    }

    this.console.info(`Set volume of ${uid} to ${volume}`);
    participant.videoTag.volume = volume;
  }

  setParticipantMicrophoneGain(uid, gain) {
    const participant = this.getParticipantByUid(uid);

    if (!participant) {
      this.console.error(`Could not find participant by uid ${uid}`, this.participants);

      return;
    }

    this.console.info(`Set gain of ${uid} to ${gain}`);
    participant.gainNode.gain.value = gain;
  }

  reset() {
    for (const roomUid in this.participants) {
      this.removeParticipantByUid(roomUid);
    }

    this.localStream = undefined;
    this.localStreamDeviceId = undefined;
    this.localVideo = undefined;
    this.participants = {};
  }

  removeParticipantByUid(uid) {
    const participant = this.getParticipantByUid(uid, false);

    if (!participant) {
      return;
    }

    this.closeAudioContextForParticipant(participant);

    if (participant.videoTag) {
      participant.videoTag.remove();
    }

    this.dispatchEvent(new ParticipantRemovedEvent(participant));

    delete this.participants[uid];

    if (uid === 'self') {
      // We left, let's remove EVERYTHING and reset
      this.reset();
    }
  }

  setVideoTagForParticipant(uid, videoStream) {
    const participant = this.getParticipantByUid(uid, true);

    let videoTag = participant.videoTag;

    if (!videoStream) {
      this.console.error(`Video stream for uid ${uid} was empty`);

      if (videoTag) {
        this.closeAudioContextForParticipant(participant);

        participant.videoTag.remove();
        participant.videoTag = null;
      }

      return;
    }

    if (!videoTag) {
      videoTag = document.createElement('video');

      videoTag.autoplay = true;
      videoTag.playsInline = true;
      videoTag.muted = uid === 'self';

      const videoContainer = document.createElement('div');

      videoContainer.appendChild(videoTag);

      this.element.appendChild(videoContainer);

      participant.videoTag = videoTag;
    }

    this.console.log(`Set video tag for ${uid} to stream`, videoStream, videoTag);

    videoTag.srcObject = videoStream;

    this.initializeAudioContextForParticipant(uid, videoStream);

    return videoTag;
  }

  getDeviceLabelById(deviceId) {
    if (this.inputDevices !== undefined && this.inputDevices !== null && this.inputDevices.length > 0) {
      for (let i = 0, len = this.inputDevices.length; i < len; i++) {
        const inputDevice = this.inputDevices[i];

        if (inputDevice.deviceId === deviceId) {
          return inputDevice.label;
        }
      }
    }

    return deviceId;
  }

  enumerateInputDevices() {
    this.console.log(`Enumerate input devices promise..`);
    return new Promise((resolve, reject) => {
      this.console.log(`Enumerate input devices..`);
      navigator.mediaDevices.enumerateDevices()
        .then((enumeratedDevices) => {
          this.console.log(`Enumerate input devices result`, enumeratedDevices);
          const inputDevices = enumeratedDevices.filter((device) => {
            return device.kind === 'audioinput' && device.label && device.deviceId;
          });

          if (!inputDevices.length) {
            inputDevices.push({
              id: 'default', // Firefox doesn't always return all items since you can give permission to a single microphone
              label: this.getDeviceLabelById('default'),
            });
          }

          this.inputDevices = inputDevices;

          this.dispatchEvent(new InputDevicesUpdatedEvent(this.inputDevices));

          resolve(inputDevices);
        })
        .catch((err) => {
          reject(new Error(`Could not enumerate devices: ${err.message}`));
        });
    });
  }

  requestLocalStreamDefault() {
    return this.requestLocalStream('default');
  }

  requestLocalStreamLatest() {
    if (this.localStreamDeviceId) {
      return this.requestLocalStream(this.localStreamDeviceId);
    }

    return this.requestLocalStreamDefault();
  }

  setLocalStream(stream, deviceId) {
    this.localStream = stream;
    this.localStreamDeviceId = deviceId;

    this.console.log('VoiceController.LocalStreamChangedEvent (emitting)', stream, deviceId);

    this.dispatchEvent(new LocalStreamChangedEvent(stream, deviceId));

    //if (this.webRtc) {
    //  this.webRtc.setLocalStream(stream);
    //}
  }

  setLocalStreamMuted(localStreamMuted) {
    this.localStreamMuted = localStreamMuted;

    this.dispatchEvent(new LocalStreamMutedChangedEvent(this.localStream, this.localStreamMuted));

    const audioTracks = this.localStream.getAudioTracks();

    for (const audioTrack of audioTracks) {
      if (localStreamMuted) {
        audioTrack.enabled = false;
      } else {
        audioTrack.enabled = true;
      }
    }
  }

  isLocalStreamMuted() {
    return this.localStreamMuted;
  }

  stopLocalStream() {
    this.localStream = null;
    this.localStreamDeviceId = null;

    this.localVideo = this.setVideoTagForParticipant('self', null);

    this.dispatchEvent(new LocalStreamChangedEvent(this.localStream, this.localStreamDeviceId));
  }

  requestLocalStream(deviceId) {
    this.console.log(`requestLocalStream(${deviceId})`);

    return new Promise(async (resolve, reject) => {
      if (this.localStream && this.localStreamDeviceId === deviceId) {
        this.console.log(`We requested local stream, but the local stream device id ${this.localStreamDeviceId} is already the same as the device id ${deviceId}`);

        // It's already the correct Id and stream ID
        resolve(this.localStream);
      } else {
        const options = {
          // This might be required but sounds weird with music
          echoCancellation: true,
          autoGainControl: true,
          noiseSupression: true,
          highPassfilter: true,
        };

        const userMediaConstraints = {
          audio: {
            echoCancellation: { exact: options.echoCancellation },
            googEchoCancellation: { exact: options.echoCancellation },

            autoGainControl: { exact: options.autoGainControl },
            googAutoGainControl: { exact: options.autoGainControl },

            noiseSupression: { exact: options.noiseSupression },
            googNoiseSuppression: { exact: options.noiseSupression },

            googHighpassFilter: { exact: options.highPassfilter },

            sampleRate: 48000,
            sampleSize: 8,

            //sampleRate: 48000,
            //sampleSize: 8,
            channelCount: 1,
          },
          video: false,
        };

        if (deviceId !== 'default') {
          userMediaConstraints.audio.deviceId = {
            exact: deviceId
          };
        } else {
          userMediaConstraints.audio.deviceId = {
            exact: deviceId
          };
        }

        this.console.log(`Changing voice stream to device "${this.getDeviceLabelById(deviceId)}"`, userMediaConstraints);

        const mediaDevices = await navigator.mediaDevices;

        if (!navigator.mediaDevices) {
          const error = new UserMediaError('Could not find navigator.mediaDevices (perhaps no permissions or no HTTPS)');

          this.dispatchEvent(error);

          reject(error);

          return;
        }

        const stream = await navigator.mediaDevices
          .getUserMedia(userMediaConstraints)
          .catch(() => {
            const error = new UserMediaError('Could not fetch local stream (perhaps no permissions)');

            this.dispatchEvent(error);

            reject(error);
          });

        this.console.log('New stream, right?', stream);

        if (stream) {
          this.console.log('Found a stream so we are setting it');

          this.setLocalStream(stream, deviceId);

          this.localVideo = this.setVideoTagForParticipant('self', stream);

          resolve(stream);
        } else {
          this.console.log('No stream, so not setting local stream');
        }
      }
    });
  }

  onLeftRoom(uid) {
    if (uid === 'self') {
      for (const uid in this.participants) {
        if (uid !== 'self') {
          this.removeParticipantByUid(uid);
        }
      }
    } else {
      this.removeParticipantByUid(uid);
    }
  }
}
