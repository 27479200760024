<template>
  <div class="row mt-2">
    <end-game-popup-decision-not-unanimous
      :end-game-decision-attempts="endGameDecisionAttempts"
      :player-votes-all="playerVotesAll"
      @click:accept="onClickAccept"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">End Game Decision Attempts</label>
      <select v-model.number="endGameDecisionAttempts" class="form-select">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'
import EndGamePopupDecisionNotUnanimous from '@/components/EndGamePopup/EndGamePopupDecisionNotUnanimous/index.vue'

export default {
  name: 'EndGamePopupDecisionNotUnanimousDev',
  components: {
    EndGamePopupDecisionNotUnanimous,
    HazardPopupFlagManagement,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DevSettingsPanel,
    BootstrapSelect,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],
  data () {
    return {
      events: ['click:accept'],
      endGameDecisionAttempts: 1,
      playerVotesAll: [
        {
          characterIndex: 2,
        },
        {
          characterIndex: 3,
        },
      ]
    }
  },
  methods: {
    onClickAccept() {
      this.triggerEvent('click:accept');
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
