<template>
  <end-game-popup-feedback
    :player-votes="playerVotes"
    :player-decision="playerDecision"
    :game-decision="resultDecision"
    @click:continue="onContinue"
  />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import EndGamePopupFeedback from '@/components/EndGamePopup/EndGamePopupFeedback/index.vue'
import { DECISION_CANCEL } from '@/data/decision-data'
import { mapState } from 'vuex'

export default {
  name: 'EndGamePopupFeedbackLive',
  components: {
    EndGamePopupFeedback,
  },
  computed: {
    ...mapState(['room']),
    playerVotes() {
      const playerVotes = [];

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (player.activeEndGameVote !== this.playerDecision) {
          continue;
        }

        playerVotes.push(player);
      }

      return playerVotes;
    },
    playerDecision() {
      return this.room.endGamePlayerDecision || null;
    },
    resultDecision() {
      return this.room.endGameResultDecision || null;
    }
  },
  methods: {
    onContinue() {
      this.$emit('click:continue');
    }
  }
}
</script>
