<template>
  <div class="row">
    <button class="btn btn-success" @click="randomize">Randomize</button>
  </div>

  <div class="team-balance-dev">
    <team-balance :aoe-values="aoeValues" />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import TeamBalance from './TeamBalance'

export default {
  name: 'TeamBalanceDev',
  components: {
    TeamBalance,
    ButtonContainer
  },
  extends: DevComponentContainer,
  data() {
    return {
      aoes: [
          "animalDomesticAndWild",
        "communityEngagement",
        "defence",
        "development",
        "education",
        "emergencyManagement",
        "energy",
        "environment",
        "EOC",
        "finance",
        "food",
        "health",
        "humanRights",
        "IHR",
        "innovation",
        "internationalAndNationalCooperation",
        "laboratory",
        "legislation",
        "PoE",
        "research",
        "routineAndEventBasedSurveillance",
        "security",
        "sportsAndMGEvents",
        "tourism",
        "transportationAndRoads",
        "water",
        "fundingAndPartnerCoop",
      ],
      aoeValues: {}
    }
  },
  mounted() {
    this.randomize();
  },
  methods: {
    randomize() {
      let aoeValues = {};

      for (const aoe of this.aoes) {
        aoeValues[aoe] = Math.floor(Math.random() * 5);
      }

      this.aoeValues = aoeValues;
    }
  }
}
</script>

<style lang="scss" scoped>
.lobby-popup-dev {
  border: 1px solid black;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
}
</style>
