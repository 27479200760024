<template>
  <div class="tutorial-box-content d-flex gap-2">
    <div class="tutorial-box-info flex-fill bg-darkish p-3">
    <p>{{ $t('starMinigameTutorialSeverity.introText') }}</p>

    <ul>
      <li>{{ $t('starMinigameTutorialSeverity.list1point1') }}</li>
      <li>{{ $t('starMinigameTutorialSeverity.list1point2') }}</li>
      <li>{{ $t('starMinigameTutorialSeverity.list1point3') }}</li>
    </ul>

    <p>{{ $t('starMinigameTutorialSeverity.paragraph2') }}</p>

    <ul>
      <li>{{ $t('starMinigameTutorialSeverity.list2point1') }}</li>
      <li>{{ $t('starMinigameTutorialSeverity.list2point2') }}</li>
    </ul>
    </div>
    <div class="tutorial-box-severities bg-darkish p-3">
      <strong>{{ $t('starMinigameTutorialSeverity.tableHeader') }}</strong>
      <div class="tutorial-box-severity bg-level bg-level-1"><span>{{ $t('starMinigameTutorialSeverity.tableVeryLowSeverity') }}</span></div>
      <div class="tutorial-box-severity bg-level bg-level-2"><span>{{ $t('starMinigameTutorialSeverity.tableLowSeverity') }}</span></div>
      <div class="tutorial-box-severity bg-level bg-level-3"><span>{{ $t('starMinigameTutorialSeverity.tableModerateSeverity') }}</span></div>
      <div class="tutorial-box-severity bg-level bg-level-4"><span>{{ $t('starMinigameTutorialSeverity.tableHighSeverity') }}</span></div>
      <div class="tutorial-box-severity bg-level bg-level-5"><span>{{ $t('starMinigameTutorialSeverity.tableVeryHighSeverity') }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialSeverity',
}
</script>

<style lang="scss" scoped>
.tutorial-box-severities {
  width: 800px;
  flex-basis: 800px;
  padding: 10px;
  height: fit-content;

  .tutorial-box-severity {
    height: 75px;
    position: relative;
    margin-top: 10px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  strong {
    font-size: 25pt;
    text-align: center;
    display: block;
  }
}
</style>
