<template>
  <div class="strategic-meeting-invested-action" :class="computedClass">
    <div class="strategic-meeting-invested-action-inner">
      <div class="strategic-meeting-invested-action-description">
        {{ description }}
      </div>
      <div class="strategic-meeting-invested-action-cost">
        Cost: {{ cost }}
      </div>
    </div>
  </div>
</template>
<script>

import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'StrategicMeetingInvestedAction',
  props: {
    description: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { AppButton },
  computed: {
    computedClass() {
      const classes = [];

      if (this.disabled) {
        classes.push(`strategic-meeting-invested-action-disabled`);
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
.strategic-meeting-invested-action {
  border-radius: 10px;
  color: white;
  border: 3px solid rgba(#ffffff, .6);
  overflow: hidden;

  .strategic-meeting-invested-action-inner {
    background: rgba(0, 27, 68, 0.6);
  }

  &.strategic-meeting-invested-action-disabled {
    color: black;

    .strategic-meeting-invested-action-inner {
      background: rgba(#000000, .6);

      .strategic-meeting-invested-action-description {
        //box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
      }

      .strategic-meeting-invested-action-cost {
        background: rgba(#000000, .3);
      }
    }
  }

  .strategic-meeting-invested-action-description {
    padding: 20px;
    border-bottom: 3px solid rgba(#ffffff, .6);
    text-align: left;
  }

  .strategic-meeting-invested-action-cost {
    text-align: left;
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), rgba(#000E21, .6);
    border-radius: 10px 0px 0px 10px;

    padding: 5px 20px;
  }
}
</style>
