<template>
  <div class="star-minigame-overview d-flex">
    <div class="star-minigame-table-container epic-scrollbar-both" ref="table-container">
      <div class="star-minigame-column-highlighter" ref="column-highlighter" v-show="highlightColumn" />
      <table class="table table-striped table-bordered star-minigame-table" ref="main-table" :class="tableClass">
        <thead ref="table-head">
        <tr class="bg-dark">
          <th colspan="3" class="text-center">{{ $t('starMinigame.row1riskAssessment') }}</th>
          <th colspan="23" class="text-start">{{ $t('starMinigame.row1globalLand') }}</th>
        </tr>
        <tr class="bg-dark">
          <th class="th-subgroupOfHazard"><!-- Subgroup of hazard --></th>
          <th class="th-mainTypeOfHazard"><!-- Main type of hazard --></th>
          <th class="th-specificHazard"><!-- Specific hazard --></th>
          <th colspan="3" class="th-healthConsequences th-scale th-exposure text-center">{{ $t('starMinigame.row2hazardsAndExposures') }}</th>
          <th class="th-frequency"><!-- Frequency --></th>
          <th colspan="13" class="th-seasonality text-center">{{ $t('starMinigame.row2likelihood') }}<br/><span
            class="sub-title">{{ $t('starMinigame.row2likelihoodSeasonality') }}</span></th>
          <th class="th-severity th-vulnerability th-copingCapacity text-center" colspan="3">{{ $t('starMinigame.row2severityVulnerabilityCopingCapacity') }}</th>
          <th class="th-impact">{{ $t('starMinigame.row2impact') }}</th>
          <th class="th-confidenceLevel">{{ $t('starMinigame.row2confidenceLevel') }}</th>
          <th class="th-riskLevel">{{ $t('starMinigame.row2riskLevel') }}</th>
        </tr>
        <tr>
          <th class="th-subgroupOfHazard">{{ $t('starMinigame.row3subgroupOfHazard') }}</th>
          <th class="th-mainTypeOfHazard">{{ $t('starMinigame.row3mainTypeOfHazard') }}</th>
          <th class="th-specificHazard">{{ $t('starMinigame.row3specificHazard') }}</th>
          <th class="th-healthConsequences">{{ $t('starMinigame.row3healthConsequences') }}</th>
          <th class="th-scale">{{ $t('starMinigame.row3scale') }}</th>
          <th class="th-exposure">{{ $t('starMinigame.row3exposure') }}</th>
          <th class="highlight-frequency th-frequency">{{ $t('starMinigame.row3frequency') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_january') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_february') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_march') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_april') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_may') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_june') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_july') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_august') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_september') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_october') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_november') }}</th>
          <th class="highlight-seasonality th-seasonality">{{ $t('starMinigame.row3frequency_december') }}</th>
          <th class="highlight-likelihood th-likelihood">{{ $t('starMinigame.row3likelihood') }}</th>
          <th class="highlight-severity th-severity">{{ $t('starMinigame.row3severity') }}</th>
          <th class="highlight-vulnerability th-vulnerability">{{ $t('starMinigame.row3vulnerability') }}</th>
          <th class="highlight-copingCapacity th-copingCapacity">{{ $t('starMinigame.row3copingCapacity') }}</th>
          <th class="th-impact"><!-- Impact --></th>
          <th class="th-confidenceLevel"><!-- Confidence level --></th>
          <th class="th-riskLevel"><!-- Risk level --></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, n) in dataRows" ref="origin-table-row" :class="'tr-editable-' + (row.editable ? 'true' : 'false')">
          <td class="td-subgroupOfHazard">{{ row.subGroup }}</td>
          <td class="td-mainTypeOfHazard">{{ row.mainType }}</td>
          <td class="td-specificHazard">{{ row.hazardName }}</td>
          <td class="td-healthConsequences">{{ row.healthConsequences.join(', ') }}</td>
          <td class="td-scale">{{ row.scale }}</td>
          <td class="td-exposure">{{ row.exposure }}</td>
          <td class="td-frequency" :class="'td-frequency-' + row.frequency">{{ getFrequencyLabel(row.frequency) }}</td>
          <td v-for="seasonValue of row.seasonality" class="td-seasonality bg-level" :class="'bg-level-' + seasonValue" :title="seasonValue">
            <!--{{ seasonValue }}-->
          </td>
          <td class="td-likelihood bg-level" :class="'bg-level-' + getLikelihoodLevel(row.likelihood)">
            {{ getLikelihoodLabel(row.likelihood) }}
          </td>
          <td class="td-severity bg-level" :class="'bg-level-' + getSeverityLevel(row.severity)">{{
              getSeverityLabel(row.severity)
            }}
          </td>
          <td class="td-vulnerability bg-level" :class="'bg-level-' + getVulnerabilityLevel(row.vulnerability)">
            {{ getVulnerabilityLabel(row.vulnerability) }}
          </td>
          <td class="td-copingCapacity bg-level" :class="'bg-level-' + getCopingCapacityLevel(row.copingCapacity)">
            {{ getCopingCapacityLabel(row.copingCapacity) }}
          </td>
          <td class="td-impact bg-level" :class="'bg-level-' + getImpactLevel(row.computedImpact)">{{
              getImpactLabel(row.computedImpact)
            }}
          </td>
          <td class="td-confidenceLevel">{{ getConfidenceLevelLabel(row.confidenceLevel) }}</td>
          <td class="td-riskLevel bg-level" :class="'bg-level-' + getRiskLevelLevel(row.computedRiskLevel)">
            {{ getRiskLevelLabel(row.computedRiskLevel) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="star-minigame-table-syncer">
      <!--{{ scrollLeft }}-->
      <div class="star-minigame-table-syncer-buttons" ref="button-syncer-container">
        <div class="star-minigame-table-syncer-row" v-for="(row, n) in dataRows" ref="button-syncer-row">
          <app-button v-if="buttonState[n] === 'completed'" color="green" :disabled="true">{{ $t('starMinigame.btnCompleted') }}</app-button>
          <app-button v-else-if="buttonState[n] === 'active'" color="green" :disabled="true">{{ $t('starMinigame.btnActive') }}</app-button>
          <app-button v-else-if="buttonState[n] === 'available'" color="green" :state="currentVoteIndex === n ? 'active' : ''" @click="changeAvailableVoteIndex(n)" :disabled="isHazardButtonsDisabled">
            {{ $t('starMinigame.btnAvailable') }}<br />{{ (buttonVoteCounts[n] || 0) }} / {{ playerCount }}</app-button>
          <app-button v-else color="green" :disabled="true">{{ $t('starMinigame.btnUnavailable') }}</app-button>
        </div>
      </div>
    </div>

    <tutorial-box v-if="currentTutorialInfo" :anchor-point="currentTutorialInfo.anchorPoint" :left="tutorialLeft" :top="currentTutorialInfo.top" :debug-position="false">
      <template v-slot:header>
        {{ getTutorialHeaderWithOrdinal(currentTutorialInfo) }}
      </template>
      <template v-slot:content>
        <component :is="currentTutorialInfo.component" />
      </template>
    </tutorial-box>
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue';
import {mapState} from 'vuex';
import TutorialBox from '@/components/TutorialBox/index.vue'
import TutorialFrequency from '@/components/StarMinigame/tutorial/TutorialFrequency.vue'
import {
  getTutorialHeaderWithOrdinal,
  TutorialIdentifiers,
  TutorialParts
} from '@/components/StarMinigame/tutorial/tutorial-parts'
import TutorialSeverity from '@/components/StarMinigame/tutorial/TutorialSeverity.vue'
import TutorialCopingCapacity from '@/components/StarMinigame/tutorial/TutorialCopingCapacity.vue'
import TutorialVulnerability from '@/components/StarMinigame/tutorial/TutorialVulnerability.vue'
import TutorialLikelihood from '@/components/StarMinigame/tutorial/TutorialLikelihood.vue'
import TutorialSeasonality from '@/components/StarMinigame/tutorial/TutorialSeasonality.vue'
import { AnchorPoint } from '@/components/TutorialBox/anchorPoint'
import TableMixins from '@/components/StarMinigame/TableMixins.vue'
import {
  STAR_MINIGAME_TUTORIAL_STATE_FREQUENCY,
  STAR_MINIGAME_TUTORIAL_STATE_SEASONALITY,
  STAR_MINIGAME_TUTORIAL_STATE_LIKELIHOOD,
  STAR_MINIGAME_TUTORIAL_STATE_SEVERITY,
  STAR_MINIGAME_TUTORIAL_STATE_VULNERABILITY,
  STAR_MINIGAME_TUTORIAL_STATE_COPING_CAPACITY, STAR_MINIGAME_TUTORIAL_STATE_NONE,
} from '@/enum/StarMinigameTutorialState'
import { getStarData } from '@/components/StarMinigame/star-utils'
import {TutorialState} from '@/components/StarMinigame/constants';
import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  name: 'StarMinigameOverview',
  mixins: [TableMixins],
  components: {
    TutorialBox,
    AppButton},
  props: {
    currentHazardIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    currentVoteIndex: {
      type: Number,
      default: -1,
      required: false,
    },
    currentTutorialState: {
      type: Number,
      default: -1,
      required: false,
    },
    hazardResults: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
    players: {
      type: Object,
      default() {
        return {};
      },
      required: false,
    },
    canInteract: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ['current-vote-index-changed'],
  data() {
    const StarData = getStarData();

    return {
      buttonVoteCounts: [],
      buttonAvailable: [],

      buttonState: [],

      tutorialLeft: 800,

      dataRows: StarData,

      scrollLeft: 0,
    };
  },
  watch: {
    highlightColumn() {
      this.recomputeHighlightGlow();
    },
    playerVoteCounts: {
      deep: true,
      handler() {
        this.recomputeButtonVoteCounts();
      },
    },
    hazardResults: {
      deep: true,
      handler() {
        this.recomputeButtonStates();
      }
    },
    currentState() {
      this.recomputeButtonStates();
    },
    currentVoteIndex() {
      this.recomputeButtonStates();
    },
    currentTutorialState() {
      this.recomputeButtonStates();
    },
  },
  computed: {
    ...mapState(['aspectRatioScale']),
    playerVoteCounts() {
      const playerVoteCounts = [];

      for (const playerId in this.players) {
        const player = this.players[playerId];

        playerVoteCounts.push(player.starMinigameHazardVoteIndex);
      }

      return playerVoteCounts;
    },
    playerCount() {
      let playerCount = 0;

      for (const playerCode in this.players) {
        const player = this.players[playerCode];

        if (player.playerType === PLAYER_TYPE_FACILITATOR) {
          continue;
        }

        if (!player.sessionId) {
          continue;
        }

        playerCount++;
      }

      return playerCount;
    },
    buttonsDisabled() {
      return !!this.currentTutorialInfo;
    },
    tableClass() {
      if (this.highlightColumn) {
        return [
            `star-minigame-table-highlight`,
            `star-minigame-table-highlight-col-${this.highlightColumn}`,
        ];
      }
    },
    highlightColumn() {
      if (!this.currentTutorialInfo) {
        return null;
      }

      return this.currentTutorialInfo.highlightColumn;
    },
    currentTutorialIdentifier() {
      if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_FREQUENCY) {
        return TutorialIdentifiers.FREQUENCY;
      } else if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_SEASONALITY) {
        return TutorialIdentifiers.SEASONALITY;
      } else if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_LIKELIHOOD) {
        return TutorialIdentifiers.LIKELIHOOD;
      } else if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_SEVERITY) {
        return TutorialIdentifiers.SEVERITY;
      } else if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_VULNERABILITY) {
        return TutorialIdentifiers.VULNERABILITY;
      } else if (this.currentTutorialState === STAR_MINIGAME_TUTORIAL_STATE_COPING_CAPACITY) {
        return TutorialIdentifiers.COPING_CAPACITY;
      }

      return null;
    },
    currentTutorialPart() {
      const currentTutorialIdentifier = this.currentTutorialIdentifier;

      if (!currentTutorialIdentifier) {
        return null;
      }

      return TutorialParts[currentTutorialIdentifier];
    },
    currentTutorialInfo() {
      if (!this.currentTutorialPart) {
        return null;
      }

      /** TutorialPart **/
      const tutorialPart = this.currentTutorialPart;

      return {
        identifier: tutorialPart.identifier,
        anchorPoint: AnchorPoint.Right,
        header: tutorialPart.header,
        ordinal: tutorialPart.ordinal,
        top: 420,
        component: tutorialPart.component,
        highlightColumn: tutorialPart.highlightColumn || null,
      };
    },
    isHazardButtonsDisabled() {
      if (!this.canInteract) {
        return true;
      }

      return this.currentTutorialState !== TutorialState.NONE;
    },
  },
  methods: {
    recomputeButtonVoteCounts() {
      const buttonVoteCounts = [];

      for (let i = 0, len = this.dataRows.length; i < len; i++) {
        buttonVoteCounts[i] = 0;
      }

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.starMinigameHazardVoteIndex >= 0) {
          buttonVoteCounts[player.starMinigameHazardVoteIndex] += 1;
        }
      }

      this.buttonVoteCounts = buttonVoteCounts;
    },
    getTutorialHeaderWithOrdinal(tutorialPart) {
      return getTutorialHeaderWithOrdinal(tutorialPart.identifier);
    },
    changeAvailableVoteIndex(n) {
      if (this.buttonsDisabled) {
        return;
      }

      this.$emit('current-vote-index-changed', n);
    },

    recomputeHighlightGlow() {
      if (!this.highlightColumn) {
        return;
      }

      this.$nextTick(() => {
        requestAnimationFrame(() => {
          this.recomputeHighlightGlowInner();
        });
      });
    },
    recomputeHighlightGlowInner() {
      if (!this.highlightColumn) {
        return;
      }

      const selector = '.highlight-' + this.highlightColumn;
      const tableContainer = this.$refs['table-container'];
      const elements = tableContainer.querySelectorAll(selector);

      const tableContainerRect = tableContainer.getBoundingClientRect();
      const tableRect = this.$refs['main-table'].getBoundingClientRect();

      const columnHighlighter = this.$refs['column-highlighter'];

      const firstElement = elements[0];
      const lastElement = elements[elements.length - 1];

      const firstElementRect = firstElement.getBoundingClientRect();
      const lastElementRect = lastElement.getBoundingClientRect();

      const computedRect = {
        top: 0,
        left: (firstElementRect.left - tableRect.left),
        height: tableRect.height,
        width: ((lastElementRect.left + lastElementRect.width) - firstElementRect.left)
      };

      /*console.log({
        computedRect,
        firstElementRect,
        lastElementRect,
        aspectRatioScale: this.aspectRatioScale,
        tableContainerRect,
      });*/

      computedRect.top /= this.aspectRatioScale;
      computedRect.left /= this.aspectRatioScale;
      computedRect.height /= this.aspectRatioScale;
      computedRect.width /= this.aspectRatioScale;

      columnHighlighter.style.width = computedRect.width + 'px';
      columnHighlighter.style.height = computedRect.height + 'px';
      columnHighlighter.style.top = computedRect.top + 'px';
      columnHighlighter.style.left = computedRect.left + 'px';

      let push = (this.highlightColumn === 'copingCapacity' ? 0 : 0);

      let scrollLeft = Math.max(0, (computedRect.left - computedRect.width) - tableContainerRect.width + push);

      if (this.highlightColumn === 'frequency') {
        scrollLeft = 309;
      } else if (this.highlightColumn === 'seasonality') {
        scrollLeft = 658;
      } else if (this.highlightColumn === 'likelihood') {
        scrollLeft = 764;
      } else if (this.highlightColumn === 'severity') {
        scrollLeft = 851;
      } else if (this.highlightColumn === 'vulnerability') {
        scrollLeft = 985;
      } else if (this.highlightColumn === 'copingCapacity') {
        scrollLeft = 1073;
      }

      tableContainer.scrollLeft = scrollLeft;

      this.tutorialLeft = computedRect.left - tableContainer.scrollLeft;
    },

    updateButtonRows() {
      const tableRows = this.$refs['origin-table-row'];
      const buttonSyncerRows = this.$refs['button-syncer-row'];
      const tableContainer = this.$refs['table-container'];
      const buttonSyncerContainer = this.$refs['button-syncer-container'];
      const tableHead = this.$refs['table-head'];
      const tableHeadRect = tableHead.getBoundingClientRect();

      const tableRowPositions = [];

      for (let i = 0, len = tableRows.length; i < len; i++) {
        tableRowPositions.push(tableRows[i].getBoundingClientRect());
      }

      buttonSyncerContainer.scrollTop = tableContainer.scrollTop;
      buttonSyncerContainer.style.paddingTop = ((tableHeadRect.height + 1) / this.aspectRatioScale) + 'px';

      for (let i = 0, len = tableRowPositions.length; i < len; i++) {
        const tableRow = tableRows[i];
        const buttonSyncerRow = buttonSyncerRows[i];

        const tableRowPosition = tableRow.getBoundingClientRect();

        //buttonSyncerRow.style.background = this.getColorByIndex(i);
        buttonSyncerRow.style.height = (tableRowPosition.height / this.aspectRatioScale) + 'px';

        //console.log(tableRow, buttonSyncerRow, tableRowPosition);
      }
    },

    getColorByIndex(index) {
      // The golden ratio conjugate. The golden ratio is approximately 1.618033988749895.
      // When you multiply a number by the golden ratio, you get a number that seems random,
      // but it's deterministically based on the multiplication.
      const goldenRatioConjugate = 0.618033988749895;

      // We multiply the index by the golden ratio conjugate and then take the modulo with 1
      // to get a floating-point number between 0 and 1. Multiplying by 360 converts this
      // ratio into degrees which is useful for hue as it ranges from 0 to 360.
      const hue = ((index * goldenRatioConjugate) % 1) * 360;

      // We're using the HSL color model. Hue is already calculated above.
      // We set saturation and lightness to 60% to ensure colors are vibrant
      // and neither too dark nor too light. These numbers can be adjusted as per preferences.
      return `hsl(${hue}, 60%, 60%)`;
    },

    onScroll() {
      this.updateButtonRows();
    },
    onResize() {
      this.updateButtonRows();
    },

    recomputeButtonStates() {
      for (let i = 0, len = this.buttonState.length; i < len; i++) {
        this.buttonState[i] = this.getButtonState(i);
      }
    },

    getButtonState(i) {
      if (this.hazardResults) {
        for (const hazardResult of this.hazardResults) {
          if (hazardResult.hazardIndex === i && typeof(hazardResult.voteImpact) !== 'undefined' && typeof(hazardResult.voteLikelihood) !== 'undefined') {
            return 'completed'; // Yes, this one is disabled since we found a result for it, so we've already been through it
          }
        }
      }

      if (this.currentHazardIndex === i && this.currentTutorialState !== TutorialState.NONE) {
        return 'active';
      }

      if (this.dataRows[i].editable) {
        return 'available';
      }

      return 'locked';
    }
  },
  mounted() {
    this.$refs['table-container'].addEventListener('scroll', this.onScroll);

    window.addEventListener('resize', this.onResize);

    for (let i = 0, len = this.dataRows.length; i < len; i++) {
      const dataRow = this.dataRows[i];

      this.buttonState.push('locked');
      this.buttonVoteCounts[i] = 0;
    }

    this.recomputeButtonStates();
    this.updateButtonRows();
    this.recomputeHighlightGlow();

    /*setInterval(() => {
      if (!this.$refs['table-container']) {
        this.scrollLeft =0;
      }

      this.scrollLeft = this.$refs['table-container'].scrollLeft;
    }, 50);*/
  },
  unmounted() {
    if (this.$refs['table-container']) {
      this.$refs['table-container'].removeEventListener('scroll', this.onScroll);
    }

    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/background-colors.scss";

$buttonContainerWidth: 150px;

.star-minigame-overview {
  width: 100%;
  height: 100%;
}

.star-minigame-table-container {
  width: calc(100% - $buttonContainerWidth);
  height: 100%;
  position: relative;
}

.star-minigame-table {
  font-size: 14pt;
  margin-right: 500px;

  th, td {
    color: white;
    text-align: left;
  }

  .descriptor-column, .id-column {
    font-weight: bold;
  }

  tr.bg-dark {
    td, tr {
      background: $bgDark !important;
    }
  }

  tr.bg-light {
    td, tr {
      background: $bgLight !important;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: $bgDark;
    }

    tr:nth-child(even) {
      background: $bgLight;
    }
  }

  td {
    color: white;
    font-size: 10pt;
  }
}

th {
  font-weight: bold;
  text-align: center;

  &.th-mainTypeOfHazard {
    min-width: 250px;
  }

  &.th-healthConsequences {
    min-width: 350px;
  }

  &.th-scale {
    min-width: 350px;
  }

  &.th-exposure {
    min-width: 350px;

  }
}

tr {
  &.tr-editable-false {
    td {
      filter: blur(5px);

      &.td-specificHazard {
        filter: none !important;
      }
    }
  }
}

.star-minigame-table-syncer-buttons {
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 100px;

  .star-minigame-table-syncer-row {
    position: relative;
    width: $buttonContainerWidth;

    .app-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    //background: yellow;
  }
}

.star-minigame-table {
  &.star-minigame-table-highlight {
    td, th {
      opacity: .3;
    }

    &.star-minigame-table-highlight-col-frequency {
      .td-frequency, .th-frequency {
        opacity: 1 !important;
      }
    }

    &.star-minigame-table-highlight-col-seasonality {
      .td-seasonality, .th-seasonality {
        opacity: 1 !important;
      }
    }

    &.star-minigame-table-highlight-col-likelihood {
      .td-likelihood, .th-likelihood {
        opacity: 1 !important;
      }
    }

    &.star-minigame-table-highlight-col-severity {
      .td-severity, .th-severity {
        opacity: 1 !important;
      }
    }

    &.star-minigame-table-highlight-col-vulnerability {
      .td-vulnerability, .th-vulnerability {
        opacity: 1 !important;
      }
    }

    &.star-minigame-table-highlight-col-copingCapacity {

      .td-copingCapacity, .th-copingCapacity {
        opacity: 1 !important;
      }
    }
  }
}

.star-minigame-column-highlighter {
  border: 3px solid white;

  box-shadow: 0 0 20px rgba(#ffffff, 0.8);
  position: absolute;
}
</style>
