<template>
  <div class="toolbox-popup row">
    <close-button @click="onClickClose" class="btn-close-popup" />

    <div class="toolbox-popup-inner d-flex align-items-stretch flex-nowrap epic-scrollbar">
      <div class="toolbox-popup-categories">
        <ul>
          <li v-for="(documentCategory, n) of documentCategories">
            <long-button
              color="orange"
              class="btn-toolbox-category mb-4"
              :state="n === activeDocumentCategoryIndex ? 'active' : ''"
              @click="setActiveDocumentCategoryIndex(n)"
            >
              {{ documentCategory.label }}
            </long-button>
          </li>
        </ul>
      </div>
      <div class="toolbox-popup-vertical-separator-container">
        <div class="toolbox-popup-vertical-separator"/>
      </div>
      <div class="toolbox-popup-content flex-fill">
        <div v-if="activeDocument">
          <long-button class="btn-back" size="icon" color="orange" @click="setActiveDocumentIndex(null)">&laquo;</long-button>

          <header>{{ activeDocument ? activeDocument.label : '' }}</header>
          <div class="toolbox-popup-document">
            <iframe v-if="activeDocument.type === 'iframe' || activeDocument.type === 'pdf'" :src="activeDocument.content" />
            <media-container v-else-if="activeDocument.type === 'video'" :source="activeDocument.content" />
            <p v-else-if="activeDocument.type === 'text'">{{ activeDocument.content }}</p>
            <div v-else-if="activeDocument.type === 'image'">
              <no-drag-image :src="activeDocument.content" />
            </div>
          </div>
        </div>
        <div v-else>
          <header>{{ activeDocumentCategory ? activeDocumentCategory.label : '' }}</header>
          <div class="toolbox-popup-documents">
            <div class="d-flex justify-content-around align-items-stretch g-4 flex-wrap" v-if="activeDocumentCategory">
              <div class="btn-toolbox-document-container"
                   v-for="(document, n) of activeDocumentCategory.documents">

                <long-button
                  color="orange"
                  class="btn-toolbox-document"
                  @click="setActiveDocumentIndex(n, document)"
                >
                  <div class="btn-toolbox-document-name">{{ document.label }}</div>
                  <div class="btn-toolbox-document-icon-container">
                    <no-drag-image v-if="document.icon === 'folder'"
                                   src="@/assets/toolbox/icons/toolbox-document-icon-folder.png"/>
                    <no-drag-image v-else-if="document.icon === 'justice'"
                                   src="@/assets/toolbox/icons/toolbox-document-icon-justice.png"/>
                    <no-drag-image v-else-if="document.icon === 'news'"
                                   src="@/assets/toolbox/icons/toolbox-document-icon-news.png"/>
                    <no-drag-image v-else-if="document.icon === 'video'"
                                   src="@/assets/toolbox/icons/toolbox-document-icon-video.png"/>
                    <no-drag-image v-else-if="document.icon === 'link'"
                                   src="@/assets/toolbox/icons/toolbox-document-icon-link.png"/>
                  </div>
                </long-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton';
import { mapState } from 'vuex'
import MediaContainer from '@/components/common/media/MediaContainer.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'

export default {
  name: 'ToolboxPopup',
  components: {
    CloseButton,
    MediaContainer,
    NoDragImage, LongButton},
  props: {},
  emits: ['close'],
  mounted() {
    this.loadData();
  },
  data() {
    return {
      loading: true,

      documentCategories: [],
      activeDocumentCategoryIndex: 0,
      activeDocumentIndex: null,
    }
  },
  watch: {
    activeDocumentCategoryIndex() {
      this.activeDocumentIndex = null;
    },
  },
  computed: {
    ...mapState(['toolboxCategories']),
    activeDocumentCategory() {
      if (this.activeDocumentCategoryIndex === null) {
        return null;
      }

      return this.documentCategories[this.activeDocumentCategoryIndex];
    },
    activeDocument() {
      const activeDocumentCategory = this.activeDocumentCategory;

      if (!activeDocumentCategory) {
        return null;
      }

      if (this.activeDocumentIndex === null) {
        return null;
      }

      return activeDocumentCategory.documents[this.activeDocumentIndex];
    },
  },
  methods: {
    loadData() {
      if (this.toolboxCategories && this.toolboxCategories.length) {
        this.documentCategories = this.toolboxCategories;
      } else {
        return this.$apiClient.toolbox.getToolboxData().then((toolboxCategories) => {
          this.documentCategories = toolboxCategories;

          this.$store.commit('toolboxCategories', toolboxCategories);
        });
      }
    },
    setActiveDocumentCategoryIndex(activeDocumentCategoryIndex) {
      this.activeDocumentCategoryIndex = activeDocumentCategoryIndex;
    },
    setActiveDocumentIndex(activeDocumentIndex, document) {
      if (activeDocumentIndex !== null && document && document.type === 'externalLink') {
        window.open(document.content, '_blank');

        return;
      }

      this.activeDocumentIndex = activeDocumentIndex;
    },
    onClickClose() {
      this.$emit('close');
    },
  },
}
</script>

<style lang="scss" scoped>
.toolbox-popup {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  //background: url("@/assets/textures/grunge-seamless.png"), #184E5A;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  height: 100%;
  padding: 20px 5px;
  width: 100%;
  margin-top: 30px;
  position: relative;

  z-index: 100000;

  > div {
    height: 100%;
  }

  .toolbox-popup-categories {
    width: 260px;
    min-width: 260px;
    text-align: left;

    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 0;
      height: 100%;
      overflow-y: auto;
    }
  }


  .toolbox-popup-vertical-separator-container {
    width: 10px;
    min-width: 10px;
  }

  .toolbox-popup-vertical-separator {
    background: rgba(24, 78, 90, 0.49);
    box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.4), inset -4px -4px 3px rgba(255, 255, 255, 0.4);
    border-radius: 22px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 100%;
    height: 100%;
  }

  .toolbox-popup-content {
    max-width: calc(100% - 280px);
    padding: 20px;
    position: relative;

    header {
      color: #FFFFFF;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      margin-bottom: 60px;
    }
  }

  .btn-toolbox-document {
    color: #ffffff;

    width: 200px;
    height: 180px;

    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 32px;

    .btn-toolbox-document-name {

    }

    .btn-toolbox-document-icon-container {
      background: #5B4210;
      padding: 10px 20px;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .toolbox-popup-documents {
    height: calc(100% - 85px);
    overflow-y: auto;
  }
}

.btn-toolbox-document-container {
  padding: 10px;
}

.toolbox-popup-content > div {
  width: 100%;
  height: calc(100% - 80px);
}

.toolbox-popup-document {
  &, iframe {
    width: 100%;
    height: 100%;
  }

  color: white;
  font-size: 16pt;
}

.btn-back {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 20px;
}

.toolbox-popup {
  position: relative;
}

.btn-close-popup {
  opacity: 1;
  position: absolute;
  top: -20px;
  z-index: 100000;
  right: -20px;
  width: 50px;
  height: 80px;
}
</style>
