

import IntroImg1 from '@/assets/image-library/intro-img-1.png';
import IntroImg2 from '@/assets/image-library/intro-img-2.png';
import IntroImg3 from '@/assets/image-library/intro-img-3.png';
import IntroLogo from '@/assets/image-library/intro-logo.png';

import PlaceholderImage1 from '@/assets/image-library/placeholder1.png';
import PlaceholderImage2 from '@/assets/image-library/placeholder2.png';
import PlaceholderImage3 from '@/assets/image-library/placeholder3.png';
import PlaceholderImage4 from '@/assets/image-library/placeholder4.png';
import PlaceholderImage5 from '@/assets/image-library/placeholder5.png';


import H1_1 from '@/assets/image-library/H1-1.jpeg';
import H1_2 from '@/assets/image-library/H1-2.jpeg';
import H2_1 from '@/assets/image-library/H2-1.jpeg';
import H2_2 from '@/assets/image-library/H2-2.jpeg';
import H2_3 from '@/assets/image-library/H2-3.jpeg';
import H3_2 from '@/assets/image-library/H3-2.jpeg';
import H3_3 from '@/assets/image-library/H3-3.jpeg';
import H4_1 from '@/assets/image-library/H4-1.jpeg';
import H4_2 from '@/assets/image-library/H4-2.jpeg';
import H6_1 from '@/assets/image-library/H6-1.jpeg';
import H6_2 from '@/assets/image-library/H6-2.jpeg';
import H6_3 from '@/assets/image-library/H6-3.jpeg';
import N1 from '@/assets/image-library/N1.jpeg';
import N10 from '@/assets/image-library/N10.jpeg';
import N11 from '@/assets/image-library/N11.jpeg';
import N12 from '@/assets/image-library/N12.jpeg';
import N13 from '@/assets/image-library/N13.jpeg';
import N15 from '@/assets/image-library/N15.jpeg';
import N16 from '@/assets/image-library/N16.jpeg';
import N17 from '@/assets/image-library/N17.jpeg';
import N18 from '@/assets/image-library/N18.jpeg';
import N19 from '@/assets/image-library/N19.jpeg';
import N2 from '@/assets/image-library/N2.jpeg';
import N20 from '@/assets/image-library/N20.jpeg';
import N21 from '@/assets/image-library/N21.jpeg';
import N22 from '@/assets/image-library/N22.jpeg';
import N23 from '@/assets/image-library/N23.jpeg';
import N24 from '@/assets/image-library/N24.jpeg';
import N3 from '@/assets/image-library/N3.jpeg';
import N4 from '@/assets/image-library/N4.jpeg';
import N5 from '@/assets/image-library/N5.jpeg';
import N6 from '@/assets/image-library/N6.jpeg';
import N7 from '@/assets/image-library/N7.jpeg';
import N8 from '@/assets/image-library/N8.jpeg';
import N9 from '@/assets/image-library/N9.jpeg';

import Intro1 from '@/assets/image-library/Intro1.jpeg';
import Intro2 from '@/assets/image-library/Intro2.jpeg';
import Intro3 from '@/assets/image-library/Intro3.jpeg';
import Intro4 from '@/assets/image-library/Intro4.jpeg';
import Intro5 from '@/assets/image-library/Intro5.jpeg';

const ImageLibrary = {
  'intro-img-1.png': IntroImg1,
  'intro-img-2.png': IntroImg2,
  'intro-img-3.png': IntroImg3,
  'intro-logo.png': IntroLogo,
  'placeholder1.png': PlaceholderImage1,
  'placeholder2.png': PlaceholderImage2,
  'placeholder3.png': PlaceholderImage3,
  'placeholder4.png': PlaceholderImage4,
  'placeholder5.png': PlaceholderImage5,

  'H1-1.jpeg': H1_1,
  'H1-2.jpeg': H1_2,
  'H2-1.jpeg': H2_1,
  'H2-2.jpeg': H2_2,
  'H2-3.jpeg': H2_3,
  'H3-2.jpeg': H3_2,
  'H3-3.jpeg': H3_3,
  'H4-1.jpeg': H4_1,
  'H4-2.jpeg': H4_2,
  'H6-1.jpeg': H6_1,
  'H6-2.jpeg': H6_2,
  'H6-3.jpeg': H6_3,
  'N1.jpeg': N1,
  'N10.jpeg': N10,
  'N11.jpeg': N11,
  'N12.jpeg': N12,
  'N13.jpeg': N13,
  'N15.jpeg': N15,
  'N16.jpeg': N16,
  'N17.jpeg': N17,
  'N18.jpeg': N18,
  'N19.jpeg': N19,
  'N2.jpeg': N2,
  'N20.jpeg': N20,
  'N21.jpeg': N21,
  'N22.jpeg': N22,
  'N23.jpeg': N23,
  'N24.jpeg': N24,
  'N3.jpeg': N3,
  'N4.jpeg': N4,
  'N5.jpeg': N5,
  'N6.jpeg': N6,
  'N7.jpeg': N7,
  'N8.jpeg': N8,
  'N9.jpeg': N9,
  'Intro1.jpeg': Intro1,
  'Intro2.jpeg': Intro2,
  'Intro3.jpeg': Intro3,
  'Intro4.jpeg': Intro4,
  'Intro5.jpeg': Intro5,
}

export {
  IntroImg1,
  IntroImg2,
  IntroImg3,
  IntroLogo,
  PlaceholderImage1,
  PlaceholderImage2,
  PlaceholderImage3,
  PlaceholderImage4,
  PlaceholderImage5,

  H1_1,
  H1_2,
  H2_1,
  H2_2,
  H2_3,
  H3_2,
  H3_3,
  H4_1,
  H4_2,
  H6_1,
  H6_2,
  H6_3,
  N1,
  N10,
  N11,
  N12,
  N13,
  N15,
  N16,
  N17,
  N18,
  N19,
  N2,
  N20,
  N21,
  N22,
  N23,
  N24,
  N3,
  N4,
  N5,
  N6,
  N7,
  N8,
  N9,

  Intro1,
  Intro2,
  Intro3,
  Intro4,
  Intro5,
};

export default ImageLibrary;
