import AlertPop from './AlertPop.vue'
import { AlertPopController } from '@/plugins/AlertPop/AlertPopController'

export function getAlertPop() {
  return {
    install(app) {
      app.component('alert-pop', AlertPop);

      app.config.globalProperties.$alertPop = new AlertPopController();
    }
  }
}
