<template>
  <div class="hazard-action-investment d-flex justify-content-between align-items-stretch">
    <div class="button-container">
      <long-button color="dark-blue" @click="deduct" :disabled="!canDeduct" size="narrow">-</long-button>
    </div>
    <div class="investment-container flex-grow-1">
      <div class="investment-bar-container" :class="'investment-bar-container-side-' + playerInvestmentSide">
        <div class="investment-bar" :style="{ width: barWidthPercent + '%' }">

        </div>
        <div class="investment-bar-player" :style="{ left: playerLeftPx + 'px' }">
          <div class="investment-bar-player-current-investment">
            {{ playerInvestment }}
          </div>
        </div>
        <div class="investment-bar-label">
          {{ totalInvestment }}/{{ investmentCost }}
        </div>
      </div>
    </div>
    <div class="button-container">
      <long-button color="dark-blue" @click="add" :disabled="!canAdd" size="narrow">+</long-button>
    </div>
  </div>
</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import TinyButton from '@/components/common/TinyButton.vue'
import { mapState } from 'vuex'
import LongButton from '@/components/common/LongButton.vue';

export default {
  name: 'HazardActionInvestment',
  components: {
    LongButton,
    TinyButton,
    CharacterThumbnail,
  },
  props: {
    playerInvestment: {
      type: Number,
      required: true,
    },
    totalInvestment: {
      type: Number,
      required: true,
    },
    investmentCost: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:playerInvestment'],
  methods: {
    add () {
      if (!this.canAdd) {
        return
      }

      this.modifyValue(+1)
    },
    deduct () {
      if (!this.canDeduct) {
        return
      }

      this.modifyValue(-1)
    },
    modifyValue (modification) {
      let playerInvestment = this.playerInvestment

      playerInvestment += modification

      playerInvestment = Math.max(0, Math.min(this.investmentCost, playerInvestment))

      if (playerInvestment !== this.playerInvestment) {
        this.$emit('update:playerInvestment', playerInvestment)
      }
    },
  },
  computed: {
    ...mapState(['room', 'investmentCartCostRp', 'investmentCartCostAp', 'actionPoints', 'actionPoints']),
    playerYou() {
      if (!this.room.players) {
        return null;
      }

      for (const playerId in this.room.players) {
        const player = this.room.players[playerId];

        if (player.isSelf) {
          return player;
        }
      }

      return null;
    },
    otherPlayersInvestment () {
      return this.totalInvestment - this.playerInvestment
    },
    playerMaxInvestment () {
      return Math.max(0, Math.min(this.investmentCost, this.investmentCost - this.otherPlayersInvestment))
    },
    playerInvestmentSide () {
      if (this.playerMaxInvestment <= 0) {
        return 'left'
      }

      return this.playerInvestmentPercentile <= 0.5 ? 'left' : 'right'
    },
    playerInvestmentPercentile () {
      return Math.max(0, Math.min(1, (this.playerInvestment / this.playerMaxInvestment)))
    },
    playerLeftPx () {
      const barWidthFullPx = 85
      const barWidthMinusPlayerInvestmentWidth = barWidthFullPx + 30
      const barWidthPercentile = this.playerInvestmentPercentile

      /*console.log('barWidthPercentile', {
        barWidthFullPx,
        barWidthMinusPlayerInvestmentWidth,
        barWidthPercentile,
        playerInvestment: this.playerInvestment,
        playerMaxInvestment: this.playerMaxInvestment,
      })*/

      return barWidthPercentile * barWidthMinusPlayerInvestmentWidth
    },
    barWidthPercent () {
      return Math.max(0, Math.min(100, (this.totalInvestment / this.investmentCost) * 100))
    },
    playerPoints () {
      if (!this.playerYou) {
        return 0;
      }

      return this.playerYou.rolePoints - this.investmentCartCostRp;
    },
    canAdd () {
      /*const apDiff = this.actionPoints - this.investmentCartCostAp;

      console.log({
        actionPoints: this.actionPoints,
        investmentCartCostAp: this.investmentCartCostAp,
        investmentCartCostRp: this.investmentCartCostRp,
        apDiff: apDiff,
        totalInvestment: this.totalInvestment,
        investmentCost: this.investmentCost,
        playerPoints: this.playerPoints,
      });

      if (apDiff < -5) {
        return false;
      }*/

      if (this.totalInvestment >= this.investmentCost) {
        return false;
      }

      if (this.playerPoints <= 0) {
        return false;
      }

      return true;
    },
    canDeduct () {
      return this.playerInvestment > 0
    },
  }
}
</script>

<style lang="scss" scoped>

.hazard-action-investment {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 0 20px;
}
.investment-container {
  color: white;
}

.investment-bar-container {
  background: linear-gradient(0deg, #1C2B3F, #1C2B3F),  url("@/assets/textures/grunge-seamless-20-percent.png"),  url("@/assets/textures/grunge-seamless-20-percent.png");
  //background: linear-gradient(270deg, #15D2C9 -1.67%, #136D6C 153.05%);
  height: 18px;
  position: relative;
  top: 19px;

  &.investment-bar-container-side-left {
    .investment-bar-label {
      right: 0%;
      transform: translateX(0%);
      //right: 10px;
      //left: auto;
    }

  }

  &.investment-bar-container-side-right {
    .investment-bar-label {
      right: 100%;
      transform: translateX(100%);
      //right: 110px;
      //right: auto;
      //left: 10px;
    }
  }

  //background: url("@/assets/hazard-popup/hazard-investment-bg.png") transparent no-repeat;
  //background-size: 100%;
  //margin-left: -40px;
  //margin-right: -40px;

  .investment-bar {
    background: linear-gradient(185.09deg, rgba(161, 255, 77, 0.7) 40%, rgba(11, 34, 3, 0.7) 127.09%);
    height: 100%;

    transition: 200ms width ease-in-out;
  }

  .investment-bar-player {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), linear-gradient(180deg, #002336 0%, #051224 84.07%);
    mix-blend-mode: normal;
    box-shadow: inset 0px 1.17111px 1.17111px rgba(255, 255, 255, 0.5), inset 0px -3.51334px 2.34223px rgba(0, 0, 0, 0.51);
    border-radius: 1.17111px;

    color: white;
    position: absolute;
    height: 25px;
    width: 22px;
    top: -4px;
    transition: 200ms left ease-in-out;
    z-index: 1000;

    .investment-bar-player-current-investment {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.investment-bar-label {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12pt;
  font-weight: 700;
  transition: 200ms all ease-in-out;
  padding: 0 10px;
}

.button-container {
  padding: 12px 0;
}
</style>
