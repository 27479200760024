export function ensureArray(mixed) {
  if (mixed instanceof Array) {
    return mixed;
  }

  return objectToArray(mixed);
}

export function pushCloned(arr, ...pushValues) {
  // Use the spread operator to clone the array
  const newArr = [...arr];

  for (const pushValue of pushValues) {
    // Push the item to the new array
    newArr.push(pushValue);
  }

  // Return the new array, leaving the original array unchanged
  return newArr;
}

export function objectToArray(obj, sortKeys = false) {
  let objectKeys = Object.keys(obj);

  if (sortKeys) {
    objectKeys = objectKeys.sort();
  }

  const array = [];

  for (const objectKey of objectKeys) {
    array.push(obj[objectKey]);
  }

  return array;
}

export function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

/**
 * Removes a specific item from an array by its value.
 *
 * @param array - The array to remove the item from.
 * @param item - The item to remove.
 *
 * @returns The modified array.
 */
export function removeArrayItemByValue(array, item) {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}

export function removeArrayItemByCallback(array, cb) {
  for (let i = array.length - 1; i => 0; i--) {
    const item = array[i];

    if (cb(item)) {
      array.splice(i, 1);
    }
  }

  return array;
}
