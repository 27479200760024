<template>
  <div class="action-nav-button" :class="computedClasses">
    <div class="content-container">
      <label>{{ label }}</label>
      <img class="icon" :src="iconSrc"/>
      <div class="amount-container" v-if="amount && amount > 0">
        {{ amount }}
      </div>
    </div>
  </div>
</template>

<script>
import CostBox from '@/components/common/CostBox.vue'

export default {
  name: 'ActionNavButton',
  components: { CostBox },
  props: {
    iconSrc: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hide: {
      type: Boolean,
      required: false,
      default: false,
    },
    amount: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    computedClasses () {
      return [
          `action-nav-button-${this.color}`,
          `action-nav-button-${this.isActive ? 'active' : 'inactive'}`,
          `action-nav-button-${this.hide ? 'hide' : 'show'}`,
      ];
    }
  }
}
</script>

<style scoped lang="scss">

.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

label {
  position: absolute;
  width: 180px;
  text-align: center;
  font-size: 15pt;
  color: white;
  text-shadow: 0px 3.88235px 3.88235px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  top: 52%;
  transform: translateY(-50%);
  left: 0px;
  user-select: none;
  pointer-events: none;
}

.icon {
  position: absolute;
  top: 55%;
  right: -10px;
  transform: translate(-50%, -50%);
  max-width: 70px;
  max-height: 70px;
  user-select: none;
  pointer-events: none;
}

.action-nav-button {
  position: relative;
  margin-bottom: 10px;
  width: 257px;
  height: 100px;
  display: flex;
  cursor: pointer;
  left: -150px;
  transition: 150ms ease-out;
  background-position-x: right;
  background-color: transparent;
  background-repeat: no-repeat;

  &.action-nav-button-orange {
    background-image: url("@/assets/ui/left-side-btn-orange.png");
  }

  &.action-nav-button-blue {
    background-image: url("@/assets/ui/left-side-btn-blue.png");
  }

  &.action-nav-button-green {
    background-image: url("@/assets/ui/left-side-btn-green.png");
  }

  &.action-nav-button-red {
    background-image: url("@/assets/ui/left-side-btn-red.png");
  }

  &:hover, &.action-nav-button-active {
    left: -30px;
  }

  &:active {
    left: -10px;
  }

  &.action-nav-button-hide {
    left: -250px !important;
  }

  .amount-container {
    position: absolute;
    top: 28px;
    right: 36px;
    color: #38708F;
    width: 50px;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
  }
}

</style>
