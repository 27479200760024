<template>
  <div class="user-container">
    <div class="title-line">
      <h2>Users</h2>
      <button class="btn btn-success" @click="onClickAddUser">Add New User +</button>
    </div>

    <div v-if="loadingUsers">
      Loading users..
    </div>
    <div v-else-if="loadingUsersError">
      <strong>Error: </strong> {{ loadingUsersError }}
    </div>
    <div v-if="users.length">
      <table class="table table-striped table-bordered room-table">
        <thead>
        <tr>
          <th>User ID</th>
          <th>Username</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <admin-user-row
          v-for="user of users"
          :key="user.id"
          :user="user"
          @userDeleted="onUserDeleted(user.id)"
        />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AdminUserRow from '@/components/Admin/AdminUserRow.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleSlider from '@/components/common/ToggleSlider.vue'
import { mapState } from 'vuex'
import Sidebar from '@/components/Admin/Sidebar.vue'
import { removeArrayItemByCallback, removeArrayItemByValue } from '@/utils/arrayUtil'

export default {
  name: 'UserIndex',
  components: {
    Sidebar,
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    Lightbox,
    AdminUserRow,
  },
  computed: {
    ...mapState(['signedInUser']),
  },
  data () {
    return {
      // Users
      currentUser: null,
      loadingUsers: true,
      loadingUsersError: null,
      users: [],

      loadingUsersTimeout: null,
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    loadUsers () {
      if (this.loadingUsersTimeout) {
        clearTimeout(this.loadingUsersTimeout)

        this.loadingUsersTimeout = null
      }

      this.loadingUsers = true
      this.loadingUsersError = null

      this.$apiClient.backend.getUsers().then((users) => {
        this.users = users

        this.loadingUsers = false
      }).catch((e) => {
        this.loadingUsersError = e
        this.loadingUsers = false
      })
    },
    onClickAddUser () {
      this.$router.push({ name: 'UserCreate' })
    },
    onUserDeleted (userId) {
      removeArrayItemByCallback(this.users, (user) => {
        return user.id === userId;
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.title-line {
  display: flex;

  .btn {
    margin-left: 1em;
    height: 2.2rem;
    padding: .4em 1em;
  }

}

.user-table {
  .td-player-list {
    ul {
      text-align: left;
      margin: 0;
    }
  }
}

h1, h2, h3, h4, h5, p, div {
  text-align: left;
}
</style>
