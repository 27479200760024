import localMessages from '@/lib/i18n/index'
import { createI18n } from 'vue-i18n'

const debugLocalization = false;

const localizationOptions = debugLocalization
  ? {
    locale: 'system',
    fallbackLocale: 'system',
    messages: []
  } : {
    locale: 'en',
    fallbackLocale: 'en',
    messages: localMessages,
  };

export const i18n = createI18n(localizationOptions);
