<template>
  <div>
    <top-bar :action-points="3" :collaboration="2" :game-time="1" :health-security="2" :mg-event-success="4" :trust="5" />

    <left-action-bar>
      <action-nav-button
        color="orange"
        :icon-src="iconMagnifier"
        label="Dashboard"
        class="action-bar-dashboard"
      />

      <action-nav-button
        color="blue"
        :icon-src="iconWrench"
        label="Toolbox"
        class="action-bar-toolbox"
      />

      <action-nav-button
        color="green"
        :icon-src="iconFlag"
        label="Priorities"
        class="action-bar-flag-management"
      />
    </left-action-bar>

    <div class="avatar-root">
      <player-character-plate
        :player-data="firstPlayer"
        :waiting-for="waitingForPlayerCode"
      />
    </div>

    <right-player-list-bar class="right-player-list-bar" :players="players" :waiting-for-player-code="waitingForPlayerCode"/>
  </div>
</template>
<script>
import TopBar from "@/components/player-ui/TopBar/index.vue";
import ActionNavButton from "@/components/player-ui/ActionNavButton.vue";
import LeftActionBar from "@/components/player-ui/LeftActionBar.vue";
import PlayerAvatarCircle from "@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircle.vue";
import RightPlayerListBar from "@/components/player-ui/RightPlayerListBar/RightPlayerListBar.vue";
import IconWrench from '@/assets/icons/icon-wrench.png'
import IconMagnifier from '@/assets/icons/icon-magnifier.png'
import IconDev from '@/assets/icons/icon-dev.png'
import IconBug from '@/assets/icons/icon-bug.png'
import IconFlag from '@/assets/icons/icon-flag.png'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import { generatePlayersData } from '@/utils/playerDataGenerator'

export default {
  name: 'GameUiDev',
  components: {
    PlayerCharacterPlate,
    PlayerAvatarCircle,
    LeftActionBar,
    TopBar,
    ActionNavButton,
    RightPlayerListBar,
  },
  data() {
    return {
      waitingForPlayerCode: '',
      iconMagnifier: IconMagnifier,
      iconWrench: IconWrench,
      iconFlag: IconFlag,
      players: generatePlayersData(5),
    };
  },
  computed: {
    firstPlayer() {
      return Object.values(this.players)[0];
    },
  },
}
</script>

<style lang="scss" scoped>
.right-player-list-bar {
  position: fixed;
  top: 80px;
  right: 15px;
}

.avatar-root {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
