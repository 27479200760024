export function dotTrim(string, maxLength) {
  if (string.length < maxLength) {
    return string;
  }

  string = string.substr(0, maxLength);

  return `${string}..`;
}

export function repeat(string, repeatCount) {
  if (repeatCount < 0) {
    return '';
  }

  let repeatedString = '';

  for (let i = 0; i < repeatCount; i += 1) {
    repeatedString += string;
  }

  return repeatedString;
}

export function padLeft(string, length, padCharacter = ' ') {
  const repeatCount = length - string.length;

  if (repeatCount <= 0) {
    return string;
  }

  return repeat(padCharacter, repeatCount) + string;
}

export function padRight(string, length, padCharacter = ' ') {
  const repeatCount = length - string.length;

  if (repeatCount <= 0) {
    return string;
  }

  return string + repeat(padCharacter, repeatCount);
}

export function convertToPascalCase(text) {
  // If all characters are uppercase, treat them as lowercase
  if (text === text.toUpperCase()) {
    text = text.toLowerCase();
  }

  // Replace non-alphanumeric characters with spaces
  const cleanedText = text.replace(/[^a-zA-Z0-9]/g, ' ');

  // Split the text by spaces
  const words = cleanedText.split(' ');

  // Capitalize the first letter of each word and keep the rest as-is
  const pascalCaseText = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

  return pascalCaseText;
}

export function convertToCamelCase(text) {
  const pascalCase = convertToPascalCase(text);

  return pascalCase.substr(0, 1).toLowerCase() + pascalCase.substr(1);
}

export function encodeHtml(text) {
  if (!text) {
    return text;
  }

  if (typeof(text) !== 'string') {
    return text;
  }

  // https://stackoverflow.com/a/18750001/247893
  return text.replace(/[\u00A0-\u9999<>\&]/g, (i) => {
    return '&#' + i.charCodeAt(0) + ';';
  });
}

export function getCurrentBaseUrl(withEndSlash = false) {
  let url = location.protocol + '://' + location.host;

  // Add port if not standard
  if (location.port) {
    if (location.port !== '80' && (location.protocol === 'http' || location.protocol === 'ws')) {
      url += ':' + location.port;
    }

    if (location.port !== '443' && (location.protocol === 'https' || location.protocol === 'wss')) {
      url += ':' + location.port;
    }
  }

  if (withEndSlash) {
    url += '/';
  }

  return url;
}
