<template>
  <div class="login-full">
  </div>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton.vue'

import IntroPageData from '@/lib/intro-pages'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import MediaContainer from '@/components/common/media/MediaContainer.vue'

import IntroImg1 from '@/assets/image-library/intro-img-1.png';
import IntroImg2 from '@/assets/image-library/intro-img-2.png';
import IntroImg3 from '@/assets/image-library/intro-img-3.png';
import IntroLogo from '@/assets/image-library/intro-logo.png';
import { mapState } from 'vuex'

export default {
  name: 'LoginView',
  components: {
    MarkdownText,
    LongButton,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    MediaContainer,
  },
  mounted() {
    this.$router.push('/lobby');

    return;

    // They haven't seen the intro yet - be gone!
    if (!this.seenIntro) {
      this.$router.push('/');

      return;
    }

    this.$store.commit('backgroundBlingTop', false);
    this.$store.commit('backgroundBlingBottom', false);
    this.$store.commit('background', null);
  },
  computed: {
    ...mapState(['seenIntro']),
  },
  methods: {
    onClickFakeLogin() {
      this.$router.push('/lobby');
    }
  }
}
</script>

<style lang="scss" scoped>
.center-page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  p {
    font-size: 16pt;
  }
}
</style>
