// Must Match TimerIdentifier.ts in server
export const TIMER_IDENTIFIER_HAZARD_MEETING = 'HazardMeeting';
export const TIMER_IDENTIFIER_HAZARD_MEETING_STARTING = 'HazardMeetingStarting';
export const TIMER_IDENTIFIER_HAZARD_MEETING_PRIORITIZATION = 'HazardMeetingPrioritization';
export const TIMER_IDENTIFIER_HAZARD_MEETING_ENDING = 'HazardMeetingEnding';
export const TIMER_IDENTIFIER_STRATEGIC_MEETING = 'StrategicMeeting';
export const TIMER_IDENTIFIER_STRATEGIC_MEETING_RESULTS = 'StrategicMeetingResults';
export const TIMER_IDENTIFIER_EPI_REPORT = 'EpiReport';
export const TIMER_IDENTIFIER_END_GAME_DECISION = 'EndGameDecision';
export const TIMER_IDENTIFIER_END_GAME_DECISION_AGAIN = 'EndGameDecisionAgain';
export const TIMER_IDENTIFIER_ROLE_SELECTION_START_GAME = 'RoleSelectionStartGame';
export const TIMER_IDENTIFIER_ANNEX_2 = 'Annex2';
export const TIMER_IDENTIFIER_STAR_MINIGAME_INTRO = 'StarMinigameIntro';
export const TIMER_IDENTIFIER_STAR_MINIGAME_FORCED_DOCUMENT = 'StarMinigameForcedDocument';
export const TIMER_IDENTIFIER_STAR_MINIGAME_OVERVIEW = 'StarMinigameOverview';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_FREQUENCY = 'StarMinigameTutorialFrequency';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_SEASONALITY = 'StarMinigameTutorialSeasonality';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_LIKELIHOOD = 'StarMinigameTutorialLikelihood';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_SEVERITY = 'StarMinigameTutorialSeverity';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_VULNERABILITY = 'StarMinigameTutorialVulnerability';
export const TIMER_IDENTIFIER_STAR_MINIGAME_TUTORIAL_COPING_CAPACITY = 'StarMinigameTutorialCopingCapacity';
export const TIMER_IDENTIFIER_STAR_MINIGAME_VOTE_LIKELIHOOD = 'StarMinigameVoteLikelihood';
export const TIMER_IDENTIFIER_STAR_MINIGAME_VOTE_IMPACT = 'StarMinigameVoteImpact';
export const TIMER_IDENTIFIER_STAR_MINIGAME_RISK_MATRIX = 'StarMinigameRiskMatrix';
export const TIMER_IDENTIFIER_HAZARD_MEETING_NO_PRIORITIZATIONS = 'HazardMeetingNoPrioritizations';
