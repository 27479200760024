<template>
  <app-button class="button-close" @click.prevent.stop="onClickX" color="red" :width="width" :height="height" :text-size="textSize">X</app-button>
</template>
<script>
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'CloseButton',
  components: {
    AppButton,
  },
  props: {
    textSize: {
      type: String,
      default: 'xxxl',
      required: false,
    },
    width: {
      type: String,
      default: 'fit-content',
      required: false,
    },
    height: {
      type: String,
      default: 'fit-content',
      required: false,
    },
  },
  emits: ['click'],
  methods: {
    onClickX() {
      this.$emit('click');
    }
  }
}
</script>
