<template>
  <div class="capacity-level" :class="['capacity-level-' + capacityLevel, 'capacity-level-size-' + size]">
    <div
      class="capacity-level-box"
      v-for="n in 5"
      :class="['capacity-level-box-' + n, n === capacityLevel ? 'capacity-level-box-active' : '']"
      v-text="n"
    />
    <div class="capacity-level-current">
      <no-drag-image src="@/assets/icons/triangle-down-shadow.png" />
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton';
import DashboardIndicatorCategory from '@/components/dashboard/DashboardIndicatorCategory.vue'

export default {
  name: 'CapacityLevel',
  components: {
    NoDragImage,
  },
  props: {
    capacityLevel: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    }
  },
}
</script>

<style lang="scss" scoped>
.capacity-level-box {
  display: inline-block;
  width: 60px;
  height: 70px;
  top: 0;

  border: 3px solid #ffffff;
  border-collapse: collapse;
  box-shadow: inset 5px 10px 6px rgba(0, 0, 0, 0.25);

  opacity: .47;
  position: relative;

  transition: 200ms cubic-bezier(0.175, 0.985, 0.32, 2.175);

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 70px;

  align-items: center;
  text-align: center;

  color: #FFFFFF;

  margin-left: -2px;
  margin-right: -2px;

  &.capacity-level-box-active {
    opacity: 1;
    box-shadow: none;
    transform: scale(110%);
    z-index: 10;

    top: -10px;
  }

  &.capacity-level-box-1 {
    background: #BB114C;
  }

  &.capacity-level-box-2,
  &.capacity-level-box-3 {
    background: #F2B91A;
  }

  &.capacity-level-box-4,
  &.capacity-level-box-5 {
    background: #00A895;
  }
}

.capacity-level {
  position: relative;
  width: 290px;
  margin: 0 auto;

  &.capacity-level-size-sm {
    width: 290px * 0.9;

    .capacity-level-box {
      width: 60px * 0.9;
      height: 70px * 0.9;
      line-height: 70px * 0.9;
    }

    img {
      position: relative;
      left: -7px;
      top: -5px;
    }
  }

  .capacity-level-current {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 15;
    transition-property: left;
    transition: 200ms ease-out;
  }

  &.capacity-level-0 {
    display: none;
  }

  &.capacity-level-1 .capacity-level-current {
    left: 6%;
  }

  &.capacity-level-2 .capacity-level-current {
    left: 25.5%;
  }

  &.capacity-level-3 .capacity-level-current {
    left: 44.5%;
  }

  &.capacity-level-4 .capacity-level-current {
    left: 64.5%;
  }

  &.capacity-level-5 .capacity-level-current {
    left: 83.5%;
  }

}
</style>
