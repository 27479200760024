import {getConsole, getMockConsole} from '@/utils/getConsole'
import {Client} from 'colyseus.js'
//import {ColyseusVuexSyncer, VUEX_MUTATION_ADD, VUEX_MUTATION_SET} from './utils/ColyseusVuexSyncer'
import {EventEmitter} from 'eventemitter3'
import {GameClientEvent} from '@/plugins/GameClient/lib/GameClientEvent'
import {WebsocketCloseCodeUtil} from '@/plugins/GameClient/lib/WebsocketCloseCodeUtil'
import { ColyseusVuexSyncerNew, VUEX_MUTATION_ADD, VUEX_MUTATION_SET } from '@/plugins/GameClient/utils/ColyseusVuexSyncerNew'
//import { VoiceController } from '@/plugins/GameClient/voice/VoiceController'
export default class {
  errorHandler

  console

  client
  room

  sessionId

  vuexSyncer

  eventEmitter

  voiceController

  constructor({voiceEnabled}) {
    this.console = getMockConsole()

    this.eventEmitter = new EventEmitter();

    const mixins = this.mixins()

    /*if (voiceEnabled) {
      this.voiceController = new VoiceController({
        useDebugConsole: getConsole('VoiceController'),
      });
    }*/

    if (Object.keys(mixins).length) {
      for (const mixinGroup in mixins) {
        if (Object.prototype.hasOwnProperty.call(mixins, mixinGroup)) {
          const mixin = mixins[mixinGroup]

          if (typeof (mixin) === 'function') {
            // It's a standalone call without a group
            this[mixinGroup] = mixin.bind(this)
          } else {
            // mixin is actually a group of mixins, let's merge the entire group¨¨
            const groupMixins = {...this[mixinGroup], ...mixin}

            this[mixinGroup] = {}

            for (const name in groupMixins) {
              if (Object.prototype.hasOwnProperty.call(groupMixins, name)) {
                this[mixinGroup][name] = groupMixins[name].bind(this)
              }
            }
          }
        }
      }
    }
  }

  getSessionId() {
    return this.sessionId;
  }

  setUseDebugConsole(useDebugConsole, introMessage = null, textColor = 'white', backgroundColor = '#0000aa') {
    if (useDebugConsole) {
      this.console = getConsole('GameClient', textColor, backgroundColor)

      if (introMessage) {
        this.console.log(introMessage, this)
      }
    } else {
      this.console = getMockConsole()
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return []
  }

  setErrorHandler(errorHandler) {
    this.errorHandler = errorHandler
  }

  setVuex(vuex, vuexGetter, vuexSetter) {
    if (!vuexGetter) {
      throw new Error('vuexGetter cannot be empty')
    }

    if (!vuexSetter) {
      throw new Error('vuexSetter cannot be empty')
    }

    this.vuexSyncer = new ColyseusVuexSyncerNew(this, vuex, vuexGetter, vuexSetter, false);
  }

  mutatePlayerWithLocalData(player, playerId) {
    const type = typeof (player.isSelf);

    if (type !== 'undefined') {
      return;
    }

    this.vuexSyncer.vuex.commit('roomSetPlayerLocalData', {
      playerUid: playerId,
      data: {
        isSelf: playerId === this.room.sessionId
      },
    });
  }

  onRoomJoined(room) {
    this.console.log(`Joined room with name ${room.name} (session id: ${room.sessionId})`)

    this.sessionId = room.sessionId;
    this.room = room

    if (this.vuexSyncer) {
      this.console.log(`Getting ready to bind the store state to the websocket state..`)

      room.onStateChange.once(state => {

        this.console.log(`Bound the store state to the websocket state`)

        this.vuexSyncer.bindColyseusRoot(state);

        this.vuexSyncer.addMutationListener(VUEX_MUTATION_ADD, (data) => {
          //console.log('VUEX_MUTATION_ADD', data);
          if (data.keyParts[1] === 'players' && data.keyParts.length === 3) {
            // data.keyParts will be ['room', 'players', 'PLAYERID']
            this.mutatePlayerWithLocalData(data.value, data.keyParts[data.keyParts.length - 1]);
          }
        })

        this.vuexSyncer.addMutationListener(VUEX_MUTATION_SET, (data) => {
          //console.log('VUEX_MUTATION_SET', data);
          if (data.keyParts[1] === 'players') {
            const keyPartLength = data.keyParts.length;

            if (keyPartLength === 2) {
              // data.keyParts will be ['room', 'players']

              // Setting all players in one go
              for (let playerId in data.value) {
                const player = data.value[playerId];

                this.mutatePlayerWithLocalData(player, playerId);
              }
            } else if (keyPartLength === 3) {
              // data.keyParts will be ['room', 'players', 'PLAYERID']

              // Setting a single, static, player
              this.mutatePlayerWithLocalData(data.value, data.keyParts[data.keyParts.length - 1]);
            }
          }
        })

        // Add initial players
        if (state.players.size > 0) {
          state.players.forEach((player, playerId) => {
            //console.log('VUEX_MUTATION SYNC PLaYER 333', player);
            this.mutatePlayerWithLocalData(player, playerId);
          });
        }

        //console.log('state', state);

        //bindColyseusStateToVuex(this.vuex, this.vuexStateKey, state)

        this.eventEmitter.emit(GameClientEvent.ROOM_STATE_READY, {room});
      })
    }

    this.addRoomCallbacks(room);

    room.onLeave((code) => {
      const data = {code, codeString: WebsocketCloseCodeUtil.getErrorCodeString(code)};

      this.console.log('room.onLeave', data);

      this.room = null;

      this.eventEmitter.emit(GameClientEvent.ROOM_LEAVE, data);
    });

    this.eventEmitter.emit(GameClientEvent.ROOM_JOIN, {room});

    return room
  }

  addRoomCallbacks(room) {
    room.onError((code, message) => {
      this.console.log('room.onError', {code, message});

      this.eventEmitter.emit(GameClientEvent.ROOM_ERROR, {code, message});

      this.room = null;
      this.sessionId = null;
    });

    room.onMessage('accessorSuccess', (message) => {
      this.eventEmitter.emit(GameClientEvent.ACCESSOR_SUCCESS, message);
    });

    room.onMessage('accessorError', (message) => {
      this.eventEmitter.emit(GameClientEvent.ACCESSOR_ERROR, message);
    });
  }

  onRoomJoinError(e) {
    this.console.log(`Join error`, e)

    this.eventEmitter.emit(GameClientEvent.ROOM_ERROR, {code: 0, message: 'Could not join room', event: e});

    this.room = null;
    this.sessionId = null;
  }

  leaveRoom() {
    let onLeave = () => {
      this.room = null;
      this.sessionId = null;

      this.vuexSyncer.reset();
    };

    if (!this.hasJoinedRoom()) {
      return new Promise((resolve) => {
        onLeave();

        resolve();
      }); // Currently not in a room
    }

    return this.room.leave(true).then(onLeave)
  }

  reset() {
    return this.leaveRoom();
  }

  connectToServer(address) {
    this.client = new Client(address)
  }

  joinOrCreate(roomName, options = null) {
    this.console.log(`joinOrCreate()`, roomName, options)

    return this.client.joinOrCreate(roomName, options)
      .then((room) => {
        return this.onRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  onWaitRoomJoined(room) {
    return new Promise((resolve, reject) => {
      let onFinally;

      let onStateReady = ({room}) => {
        onFinally();

        this.console.log(`onStateReady()`, room)
        resolve(room);
      };

      let onError = ({code, message}) => {
        onFinally();

        this.console.log(`onError()`, code, message)
        reject(message);
      };

      onFinally = () => {
        this.eventEmitter.off(GameClientEvent.ROOM_STATE_READY, onStateReady);
        this.eventEmitter.off(GameClientEvent.ROOM_ERROR, onError);
      };

      this.eventEmitter.on(GameClientEvent.ROOM_STATE_READY, onStateReady);
      this.eventEmitter.on(GameClientEvent.ROOM_ERROR, onError);

      this.onRoomJoined(room);
    });
  }

  create(roomName, options = null) {
    this.console.log(`create()`, roomName, options)

    return this.client.create(roomName, options)
      .then((room) => {
        return this.onWaitRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  join(roomName, options = null) {
    this.console.log(`join()`, roomName, options)

    return this.client.join(roomName, options)
      .then((room) => {
        //console.log('room', room);
        return this.onWaitRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  isConnected() {
    return !!this.getSessionId();
  }

  joinById(roomId, options = null) {
    this.console.log(`joinById()`, roomId, options)

    return this.client.joinById(roomId, options)
      .then((room) => {
        return this.onWaitRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  reconnect(roomId, sessionId) {
    this.console.log(`reconnect()`, roomId, sessionId)

    return this.client.reconnect(roomId, sessionId)
      .then((room) => {
        return this.onRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  getAvailableRooms(roomName) {
    return this.client.getAvailableRooms(roomName).then((rooms) => {
      this.console.log('getAvailableRooms', roomName)

      return rooms
    })
  }

  consumeSeatReservation(reservation) {
    this.console.log(`consumeSeatReservation()`, reservation)

    return this.client.consumeSeatReservation(reservation)
      .then((room) => {
        return this.onRoomJoined(room)
      })
      .catch((e) => {
        this.onRoomJoinError(e)

        throw e
      })
  }

  async pauseRoom(roomId, facilitatorCode, pause) {
    console.log(`pauseRoom - joining room by id ${roomId}..`);
    const room = await this.client.joinById(roomId, {
      playerType: 'facilitator',
      playerCode: facilitatorCode,
      muted: true,
    });

    this.room = room;
    this.sessionId = room.sessionId;

    console.log(`pauseRoom - Adding room callbacks..`, room);
    this.addRoomCallbacks(room);

    let callResult = null;

    if (pause) {
      console.log(`pauseRoom - pauseMode:enable..`, room);
      callResult = await this.roomCallMethod("pauseMode:enable")
    } else {
      console.log(`pauseRoom - pauseMode:disable..`, room);
      callResult = await this.roomCallMethod("pauseMode:disable")
    }

    console.log(`pauseRoom - leaving room..`, room);
    await this.leaveRoom();

    return callResult;
  }

  async joinByCustomId(roomName, customId, createIfNotExist = false, playerOptions = {}) {
    const joinOptions = {'_customId': customId};

    for (const key in playerOptions) {
      joinOptions[key] = playerOptions[key];
    }

    console.log('joinByCustomId', {
      roomName,
      customId,
      createIfNotExist,
      playerOptions
    });

    if (customId) {
      const roomId = await this.client.getAvailableRooms(roomName).then(rooms => {
        let roomId = null;

        rooms.forEach((room) => {
          if (!room || !room.metadata || !room.metadata.customId) {
            return;
          }

          if (room.metadata.customId === customId) {
            roomId = room.roomId;
          }
        });

        return roomId || null;
      });


      if (roomId) {
        console.log('joinByCustomId found an existing room using the customId meta data:', roomId);

        return this.joinById(roomId, joinOptions);
      } else if (createIfNotExist) {
        console.log('joinByCustomId did not find an existing room using the customId meta data - creating one');

        return this.create(roomName, joinOptions)
      }
    }

    /*
    You cannot get customId from rooms here !!!

    return this.getAvailableRooms(roomName).then((rooms) => {
      let matchingRoom

      console.log('rooms', rooms)

      if (!matchingRoom) {
        this.console.log(`Could not find matching room by room name ${roomName} and custom ID ${customId}`)

        if (createIfNotExist) {
          return this.create(roomName, {
            customId: customId,
          })
        } else {
          throw new Error(`Could not find matching room by room name ${roomName} and custom ID ${customId}`)
        }
      }
    })*/
  }

  hasJoinedRoom() {
    if (!this.room) {
      return false;
    }

    return this.room.hasJoined;
  }

  send(data) {
    if (!this.room) {
      console.warn(`Can't send data since there is currently no room`, data, this.room);
    }

    if (data.hasOwnProperty('voteSkipTimer')) {
      this.console.log('Sending voteSkipTimer', data);
    }

    this.console.log(`Sending data`, data);

    this.room.send('raw', data);
  }

  stripUnderscore(key) {
    if (key[0] !== '_') {
      return key;
    } else {
      return key.substring(1);
    }
  }

  ensureUnderscore(key) {
    if (key[0] !== '_') {
      return '_' + key;
    } else {
      return key;
    }
  }

  sendAndReturnAccessorPromise(method, data) {
    const isRoomAccessor = method[0] === '_';
    const normalizedMethod = isRoomAccessor ? method.substr(1) : method;

    let normalizedAccessor = isRoomAccessor ? 'room' : 'player';

    data['~sendAccessorUpdate'] = true;

    return new Promise((resolve, reject) => {
      let removeAccessorCallbacks;

      const onAccessorSuccess = (message) => {
        if (message.dataKey !== normalizedMethod || message.accessor !== normalizedAccessor) {
          this.console.log('Irrelevant success data key', message, normalizedMethod, normalizedAccessor);

          return; // Irrelevant
        }

        this.console.log('onAccessorSuccess', message);

        removeAccessorCallbacks();

        resolve(message.response);
      };

      const onAccessorError = (message) => {
        if (message.dataKey !== normalizedMethod || message.accessor !== normalizedAccessor) {
          this.console.log('Irrelevant error data key', message, normalizedMethod, normalizedAccessor);

          return; // Irrelevant
        }

        this.console.error('onAccessorError', message);

        removeAccessorCallbacks();

        reject(message.error);
      };

      removeAccessorCallbacks = () => {
        this.eventEmitter.off(GameClientEvent.ACCESSOR_SUCCESS, onAccessorSuccess);
        this.eventEmitter.off(GameClientEvent.ACCESSOR_ERROR, onAccessorError);
      };

      this.eventEmitter.on(GameClientEvent.ACCESSOR_SUCCESS, onAccessorSuccess);
      this.eventEmitter.on(GameClientEvent.ACCESSOR_ERROR, onAccessorError);

      this.send(data);
    });
  }

  playerSet(data) {
    const nonUnderscoreData = {};

    for (let key in data) {
      // Player data does not use underscores
      key = this.stripUnderscore(key);

      nonUnderscoreData[key] = data[key];
    }

    return this.send(data);
  }

  playerUpdate(key, value) {
    return this.playerCallMethod(key, value);
  }

  playerCallMethod(method, data = null) {
    const nonUnderscoreData = {};

    // Player data does not use underscores
    method = this.stripUnderscore(method);

    nonUnderscoreData[method] = data;

    return this.sendAndReturnAccessorPromise(method, nonUnderscoreData);
  }

  roomSet(data) {
    const underscoreData = {};

    for (let key in data) {
      // Room data MUST use underscores
      key = this.ensureUnderscore(key);

      underscoreData[key] = data[key];
    }

    return this.send(data);
  }

  roomUpdate(key, value) {
    return this.roomCallMethod(key, value);
  }

  roomCallMethod(method, data = null) {
    const underscoreData = {};

    // Room data MUST use underscores
    method = this.ensureUnderscore(method);

    underscoreData[method] = data;

    return this.sendAndReturnAccessorPromise(method, underscoreData);
  }
}
