<template>
  <div class="faux-ui">
    <img src="@/assets/dev/faux-players.png" class="faux-players"/>
    <img src="@/assets/dev/faux-self.png" class="faux-self"/>
    <img src="@/assets/dev/faux-timer.png" class="faux-timer"/>
  </div>

  <star-minigame
      :player-vote-hazard-index="playerVoteHazardIndex"
      :player-vote-likelihood-index="playerVoteLikelihoodIndex"
      :player-vote-impact-index="playerVoteImpactIndex"
      :current-state="currentState"
      :current-tutorial-state="currentTutorialState"
      :current-vote-state="currentVoteState"
      :current-hazard-index="currentHazardIndex"
      :hazard-results="hazardResults"
      :players="players"

      @player-vote-hazard-index-changed="onPlayerVoteHazardIndexChanged"
      @player-vote-likelihood-index-changed="onPlayerVoteLikelihoodIndexChanged"
      @player-vote-impact-index-changed="onPlayerVoteImpactIndexChanged"
      @continue="onContinue"
  />

  <dev-settings-panel>
    <div class="row">
      <label>State</label>
      <bootstrap-select v-model.number="currentState" :options="stateOptions"/>
    </div>
    <div class="row">
      <label>Tutorial state</label>
      <bootstrap-select v-model.number="currentTutorialState" :options="tutorialStateOptions"/>
    </div>
    <div class="row">
      <label>Vote state</label>
      <bootstrap-select v-model.number="currentVoteState" :options="voteStateOptions"/>
    </div>

    <div class="row">
      <label>Hazard index</label>
      <bootstrap-select v-model.number="currentHazardIndex" :options="hazardIndexOptions"/>
    </div>
    <div class="row">
      <label>Hazard result #1</label>
      <div class="d-flex">
        <bootstrap-select v-model.number="currentHazardResult1LikelihoodIndex" :options="likelihoodIndexOptions"/>
        <bootstrap-select v-model.number="currentHazardResult1ImpactIndex" :options="impactIndexOptions"/>
      </div>
    </div>
    <div class="row">
      <label>Hazard result #2</label>
      <div class="d-flex">
        <bootstrap-select v-model.number="currentHazardResult2LikelihoodIndex" :options="likelihoodIndexOptions"/>
        <bootstrap-select v-model.number="currentHazardResult2ImpactIndex" :options="impactIndexOptions"/>
      </div>
    </div>

    <div class="row">
      <label>Player vote hazard index</label>
      <bootstrap-select v-model.number="playerVoteHazardIndex" :options="hazardIndexOptions"/>
    </div>
    <div class="row">
      <label>Player vote likelihood index</label>
      <bootstrap-select v-model.number="playerVoteLikelihoodIndex" :options="likelihoodIndexOptions"/>
    </div>
    <div class="row">
      <label>Player vote impact index</label>
      <bootstrap-select v-model.number="playerVoteImpactIndex" :options="impactIndexOptions"/>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import StarMinigame from '@/components/StarMinigame/index.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import { State, TutorialState, VoteState } from './constants';
import { convertToPascalCase } from '@/utils/stringUtil'
import { generatePlayersData } from '@/utils/playerDataGenerator'
import { objectToArray } from '@/utils/arrayUtil'
import { getStarData } from '@/components/StarMinigame/star-utils'

export default {
  name: 'StarMinigameDev',
  components: {
    BootstrapSelect,
    StarMinigame,
    DevSettingsPanel,
    MeetingPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const StarData = getStarData();

    const stateOptions = [];
    const tutorialStateOptions = [];
    const voteStateOptions = [];

    for (const key in State) {
      const value = State[key];

      stateOptions.push({
        label: convertToPascalCase(key),
        value: value,
      });
    }

    for (const key in TutorialState) {
      const value = TutorialState[key];

      tutorialStateOptions.push({
        label: convertToPascalCase(key),
        value: value,
      });
    }

    for (const key in VoteState) {
      const value = VoteState[key];

      voteStateOptions.push({
        label: convertToPascalCase(key),
        value: value,
      });
    }

    const hazardIndexOptions = [
      {
        label: 'None',
        value: -1,
      }
    ];

    for (let i = 0, len = StarData.length; i < len; i++) {
      const starDataRow = StarData[i];

      if (starDataRow.editable) {
        hazardIndexOptions.push({
          label: `${starDataRow.hazardName} (#${i})`,
          value: i,
        })
      }
    }

    const likelihoodIndexOptions = [
      {
        label: 'None',
        value: -1,
      }
    ];

    for (let i = 0; i < 4; i++) {
      likelihoodIndexOptions.push({
        label: 'Likelihood #' + i,
        value: i,
      });
    }

    const impactIndexOptions = [
      {
        label: 'None',
        value: -1,
      }
    ];

    for (let i = 0; i < 4; i++) {
      impactIndexOptions.push({
        label: 'Impact #' + i,
        value: i,
      });
    }

    const hazardResultOptions = [];
    let hazardResultOptionIndex = 0;

    const hazardResultOptionValues = [];

    for (const likelihoodIndexOption of likelihoodIndexOptions) {
      if (likelihoodIndexOption.value < 0) {
        continue;
      }

      for (const impactIndexOption of impactIndexOptions) {
        if (impactIndexOption.value < 0) {
          continue;
        }

        hazardResultOptions.push({
          label: `Likelihood: ${likelihoodIndexOption.value} / Impact: ${impactIndexOption.value}`,
          value: hazardResultOptionIndex,
        });

        hazardResultOptionValues.push({
          voteLikelihood: likelihoodIndexOption.value,
          voteImpact: impactIndexOption.value,
        });

        hazardResultOptionIndex++;
      }
    }

    return {
      StarData,
      State: State,
      TutorialState: TutorialState,
      VoteState: VoteState,

      events: ['continue'],

      state: 'overview',

      players: generatePlayersData(5),

      stateOptions: stateOptions,
      tutorialStateOptions: tutorialStateOptions,
      voteStateOptions: voteStateOptions,
      hazardIndexOptions: hazardIndexOptions,
      likelihoodIndexOptions: likelihoodIndexOptions,
      impactIndexOptions: impactIndexOptions,
      hazardResultOptions: hazardResultOptions,

      currentState: stateOptions[0].value,
      currentTutorialState: tutorialStateOptions[0].value,
      currentVoteState: voteStateOptions[0].value,

      currentHazardIndex: -1,
      currentLikelihoodIndex: -1,
      currentImpactIndex: -1,

      playerVoteHazardIndex: -1,
      playerVoteLikelihoodIndex: -1,
      playerVoteImpactIndex: -1,

      currentHazardResultIndex1: 0,
      currentHazardResultIndex2: 0,

      currentHazardResult1LikelihoodIndex: 0,
      currentHazardResult1ImpactIndex: 0,
      currentHazardResult2LikelihoodIndex: 0,
      currentHazardResult2ImpactIndex: 0,
    }
  },

  computed: {
    hazardResults() {
      return [
        {
          hazardIndex: this.hazardIndexOptions[1].value,
          voteLikelihood: this.currentHazardResult1LikelihoodIndex,
          voteImpact: this.currentHazardResult1ImpactIndex,
        },
        {
          hazardIndex: this.hazardIndexOptions[2].value,
          voteLikelihood: this.currentHazardResult2LikelihoodIndex,
          voteImpact: this.currentHazardResult2ImpactIndex,
        }
      ]
    },
  },
  mounted () {
    let urlState = this.$router.currentRoute?.value?.query?.state

    if (urlState) {
      this.currentState = urlState
    }
  },
  methods: {
    onChangeState (currentState) {
      this.currentState = currentState

      this.$router.push({
        path: '/dev/StarMinigameDev',
        query: { state: currentState }
      })
    },
    onPlayerVoteHazardIndexChanged(hazardIndex) {
      this.playerVoteHazardIndex = hazardIndex;
    },
    onPlayerVoteLikelihoodIndexChanged(likelihoodIndex) {
      this.playerVoteLikelihoodIndex = likelihoodIndex;
    },
    onPlayerVoteImpactIndexChanged(impactIndex) {
      this.playerVoteImpactIndex = impactIndex;
    },
    onContinue() {
      this.triggerEvent('continue');

      if (this.currentState === State.NOT_BEGUN) {
        this.currentState = State.OVERVIEW;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.faux-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  pointer-events: none !important;

  z-index: 9999999999;
  opacity: .25;

  .faux-players, .faux-self, .faux-timer {
    position: fixed;
  }

  .faux-players {
    bottom: 0;
    right: 0;
  }

  .faux-timer {
    top: 100px;
    right: 0;
  }

  .faux-self {
    bottom: 0;
    left: 0;
  }
}

.btn-state {
  width: fit-content;
  margin-right: 5px;
}
</style>
