<template>
  <lightbox background="#000000" :background-opacity=".5">
    <hazard-popup-container size="sm">
      <template v-slot:header>
        {{ $t('meetingPopup.hazardNoPrioritizationsHeader') }}
      </template>
      <template v-slot:content>
        <p class="no-prioritizations-description">{{ $t('meetingPopup.hazardNoPrioritizationsDescription') }}</p>
        <p v-if="canGoBack" class="go-back-button">
          <app-button color="green" @click="onClickGoBack" width="fit-content" height="lg">Go back and prioritize</app-button>
        </p>
      </template>
    </hazard-popup-container>
  </lightbox>
</template>
<script>
import Lightbox from '@/components/common/Lightbox.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue';
import CloseButton from '@/components/common/buttons/CloseButton.vue';
import AppButton from '@/components/common/buttons/AppButton.vue';

export default {
  name: 'HazardPopupFlagManagementEmpty',
  components: {
    AppButton,
    CloseButton, HazardPopupContainer, Lightbox },
  props: {
    canGoBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['cancel', 'close', 'go-back'],
  methods: {
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickClose() {
      this.$emit('close');
    },
    onClickGoBack() {
      this.$emit('go-back');
    }
  },
}
</script>


<style lang="scss" scoped>
.no-prioritizations-description {
  font-size: 16pt;
  color: #ffffff;
  font-weight: bold;
  width: 90%;
  margin-bottom: 5px;

  background: rgba(#194040, .8);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 100px;
}

.button-container {
  margin-top: 30px;
}

.button-close {
  position: absolute;
  top: -0px;
  right: -0px;
}

.go-back-button {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
