<template>
  <lightbox :open="isOpen" background="#000000" :background-opacity=".8">
    <lobby-popup-template :show-footer="false" :style="{ width: '800px' }" :content-class="computedClass">
      <template v-slot:header>
        {{ title }}

        <close-button @click="onClickClose" class="btn-close-alert-pop" text-size="xl" />
      </template>
      <template v-slot:content>
        <div class="alert-pop-popup-body">
          <markdown-text>{{ body }}</markdown-text>
        </div>
        <div class="alert-pop-popup-buttons">
          <app-button color="red" width="lg" height="lg" class="btn-cancel" @click="onClickNo">{{ noButtonText }}</app-button>
          <app-button color="green" width="fit-content" height="lg" class="btn-confirm ms-4" @click="onClickYes">{{ yesButtonText }}</app-button>
        </div>
      </template>
    </lobby-popup-template>
  </lightbox>
</template>

<script>
import { timeoutPromise } from '@/utils/promiseUtil'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import {
  DEFAULT_TEXT_CONFIRM,
  DEFAULT_TEXT_ERROR,
  DEFAULT_TEXT_INFO,
  DEFAULT_TEXT_OK_BUTTON,
  DEFAULT_TEXT_WARNING
} from '@/plugins/AlertPop/enums';
import CloseButton from '@/components/common/buttons/CloseButton.vue';
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';

export default {
  name: 'Alert',
  components: {
    LobbyPopupTemplate,
    CloseButton,
    AppButton,
    MarkdownText,
    Lightbox,
    HazardPopupContainer
  },
  mounted() {
    this.$alertPop.addEventListener('pop', (e) => {
      let type = e.type || 'pop';

      // Allow pop, info, warning, error
      this[type](e.title, e.body, e.popData || {});
    });

    this.$alertPop.addEventListener('close', (e) => {
      if (this.isOpen) {
        this.close();
      }
    });
  },
  data() {
    return {
      title: '',
      body: '',
      type: '',
      popData: {},

      noButtonText: this.$t('various.confirmationPopupAnswerNo'),
      yesButtonText: this.$t('various.confirmationPopupAnswerYes'),

      confirmResolve: null,

      isOpen: false,
    }
  },
  methods: {
    close(closeResult = undefined) {
      if (!this.isOpen) {
        return; // Already closed - not sure what's going on here
      }

      if (this.confirmResolve) {
        this.confirmResolve(closeResult);
      }

      this.isOpen = false;

      this.reset();

      this.$alertPop.close(closeResult);
    },
    reset() {
      this.title = '';
      this.body = '';
      this.popData = {};
      this.type = '';
      this.confirmResolve = null;
    },
    async pop(title, body, popData = {}, type = null) {
      this.title = title;
      this.body = body;
      this.popData = popData || {};
      this.type = type;

      this.isOpen = true;

      return new Promise((resolve, reject) => {
        this.confirmResolve = resolve;
      });
    },
    onClickClose() {
      this.close(undefined);
    },
    onClickNo() {
      this.close(false);
    },
    onClickYes() {
      this.close(true);
    },
    async info(body, title = DEFAULT_TEXT_INFO, popData = {}) {
      return this.pop(title || DEFAULT_TEXT_INFO, body, popData, 'info');
    },
    async warning(body, title = DEFAULT_TEXT_WARNING, popData = {}) {
      return this.pop(title || DEFAULT_TEXT_WARNING, body, popData, 'warning');
    },
    async error(body, title = DEFAULT_TEXT_ERROR, popData = {}) {
      return this.pop(title || DEFAULT_TEXT_ERROR, body, popData, 'error');
    },
    async confirm(body, title = DEFAULT_TEXT_ERROR, popData = {}) {
      return this.pop(title || DEFAULT_TEXT_CONFIRM, body, popData, 'confirm');
    },
  },
  computed: {
    computedClass() {
      const classes = [
        `alert-pop-popup`,
        `small-padding`,
      ];

      if (this.type) {
        classes.push(`alert-pop-type-${this.type}`);
      }

      return classes;
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-pop {
  /*z-index: 99999999999999;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  opacity: 0;
  color: white;*/
  //pointer-events: none;

  h1 {
    font-size: 50pt;
    font-weight: bold;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.alertpop-show {
    opacity: 1;
  }

  &.alert-pop-mouse-catch {
    pointer-events: all;
    cursor: default;
  }
}

.btn-close-alert-pop {
  position: absolute;
  top: 10px;
  right: 10px;
}
.alert-pop-popup-body {
  color: #ffffff;
  padding: 10px;
  font-size: 16pt;
}

.alert-pop-popup-buttons {
  margin-bottom: 10px;
}
</style>
