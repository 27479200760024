<template>
  <div class="meeting-results-popup-container">
    <meeting-results-popup
      :players="players"
      :meeting-type="meetingType"
      :action-categories="actionCategories"
      :investments="investments"
      :score-impacts="totalScoreImpact"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <button class="btn" :class="meetingType === 'hazard' ? 'btn-success' : 'btn-dark'" @click="meetingType = 'hazard'">Hazard</button>
      <button class="btn" :class="meetingType === 'strategic' ? 'btn-success' : 'btn-dark'" @click="meetingType = 'strategic'">Strategic</button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue'
import { generatePlayerData, generatePlayersData } from '@/utils/playerDataGenerator'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import TopBar from '@/components/player-ui/TopBar/index.vue'

export default {
  name: 'MeetingResultsPopupDev',
  components: {
    TopBar,
    DevSettingsPanel,
    MeetingResultsPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['continue'],
      actionCategories: [],
      meetingType: 'hazard',
      investments: [],
      players: {},

      totalScoreImpact: {
        mgEventSuccess: 0,
        collaboration: 0,
        healthSecurity: 0,
        trust: 0,
      }
    }
  },
  computed: {
    ...mapState(['background']),
  },
  mounted() {
    this.players = generatePlayersData();

    this.$store.commit('background', 'roundtable');

    this.$apiClient.get('/mission/strategic').then((data) => {
      this.actionCategories = data.categories;

      this.generateRandomInvestments();
    });
  },
  methods: {
    generateRandomInvestments() {
      let totalScoreImpact = {
        mgEventSuccess: 0,
        healthSecurity: 0,
        collaboration: 0,
        trust: 0,
      };

      for (const category of this.actionCategories) {
        for (const tab of category.tabs) {
          for (const action of tab.actions) {
            for (const playerCode in this.players) {
              if (Math.random() < .3) {
                const investment = {
                  actionIdentifier: action.identifier,
                  playerCode: playerCode,
                  investment: this.meetingType === 'hazard' ? Math.floor((Math.random() * 1) + 1) : action.cost,
                  scoreImpact: {
                    mgEventSuccess: action.scoreImpactMgEventSuccess || action.impactMgEventSuccess || 0,
                    healthSecurity: action.scoreImpactHealthSecurity || action.impactHealthSecurity || 0,
                    collaboration: action.scoreImpactCollaboration || action.impactCollaboration || 0,
                    trust: action.scoreImpactTrust || action.impactTrust || 0,
                  }
                };

                this.investments.push(investment)

                for (const key in investment.scoreImpact) {
                  const impact = investment.scoreImpact[key] || 0;

                  if (impact > 0) {
                    totalScoreImpact[key] = (totalScoreImpact[key] || 0) + impact;
                  }
                }
              }
            }
          }
        }
      }

      this.totalScoreImpact = totalScoreImpact;
    },
  },
  unmounted () {
    if (this.background === 'roundtable') {
      this.$store.commit('background', '');
    }
  },
}
</script>

<style lang="scss" scoped>
.meeting-results-popup-container {
  .lobby-popup {
    margin: auto;
  }
}
</style>
