<template>
  <div class="indicator-category" :class="open ? 'indicator-category-open' : 'indicator-category-closed'">
    <div class="indicator-category-row d-flex justify-content-between" :style="style" @click="toggleOpen">
      <div class="name flex-fill text-start">{{ data.name }}</div>
      <div class="count">{{ jeeCapacityAverage }}</div>
      <div class="triangle"><img src="@/assets/icons/triangle-up.png" class="accordion-triangle" /></div>
    </div>
    <accordion :open="open">
      <div class="indicator-groups">
        <dashboard-indicator-group
          v-for="group of data.groups"
          :data="group"
          :identifier="data.identifier"
          :jee-scores="jeeScores"
        />
      </div>
    </accordion>
  </div>
</template>

<script>
import { getDashboardCategoryColor, getDashboardGroupColor } from '@/components/dashboard/CategoryColorUtils'
import Accordion from '@/components/common/Accordion.vue'
import DashboardIndicatorGroup from '@/components/dashboard/DashboardIndicatorGroup.vue'
import { getCapacityLevelFromJeeScore } from '@/components/dashboard/CapacityLevelUtil'

export default {
  name: 'DashboardIndicatorCategory',
  components: { Accordion, DashboardIndicatorGroup },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jeeScores: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  data() {
    return {
      open: true,
      jeeCapacityAverage: 0,
    }
  },
  watch: {
    jeeScores() {
      this.recalculateJeeScores();
    }
  },
  mounted() {
    this.recalculateJeeScores();
  },
  methods: {
    recalculateJeeScores() {
      let jeeCapacityTotal = 0;
      let jeeCapacityCount = 0;

      for (const jeeGroup of this.data.groups) {
        for (const jee of jeeGroup.jees) {
          jeeCapacityTotal += getCapacityLevelFromJeeScore(this.jeeScores[jee.identifier] || 0);
          jeeCapacityCount += 1;
        }
      }

      if (jeeCapacityCount > 0) {
        this.jeeCapacityAverage = Math.floor(jeeCapacityTotal / jeeCapacityCount);
      } else {
        this.jeeCapacityAverage = 0;
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
  },
  computed: {
    style() {
      return {
        'background': `linear-gradient(180deg, rgba(255, 255, 255, .6) -75%, ${getDashboardCategoryColor(this.data.identifier, .6)} 84.07%)`,
      }
    },
    count() {
      return 0;
    },
  },
}
</script>
<style lang="scss">
.indicator-category {
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
  color: #FFFFFF;

  .name, .description, .count {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
  }

  .count {
    min-width: 60px;
    width: 60px;
    text-align: left;
  }

  .indicator-category-row {
    user-select: none;
    cursor: pointer;
    padding: 10px;

    .triangle {
      min-width: 26px;
      width: 26px;
      height: 26px;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
    }
  }

  &.indicator-category-open {
    .indicator-category-row {
      .triangle {
        transform: rotate(180deg);
      }
    }
  }
}

.indicator-groups {
  padding: 0 20px;

  .indicator-group {
    margin-top: 7px;
  }
}

</style>
