<template>
  <hazard-popup
    class="hazard-popup"
    :icon="icon"
    :title="title"
    :tab-data="tabData"
    :player-data="playerData"
  />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopup from './index.vue'
import HazardIconData from '@/data/hazard-icon-data';
import { randomBetween } from '@/utils/randomUtil'
import { generatePlayerData } from '@/utils/playerDataGenerator'

export default {
  name: 'HazardPopupDev',
  components: {
    HazardPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const hazardData = HazardIconData.chemical;

    const tabData = [];

    const tabNames = [
      'IHR compliance',
      'Surveillance and Risk Assessment',
      'Risk Communication and Community Engagement',
    ];

    let actionId = 0;
    const actionIds = [];

    for (let i = 0; i < 30; i++) {
      const actions = [];

      for (let j = 0; j < 3; j++) {
        actionId++;

        actionIds.push(actionId);

        let text = '';

        for (let k = 0, len = i + j + 1; k < len; k++) {
          if (k % 2 === 0) {
            text += 'Report to national level'
          } else {
            text += ' and notify WHO through IHR NFP';
          }
        }

        const action = {
          id: actionId,
          name: `Action ${actionId}`,
          text: text,
          cost: Math.round(randomBetween(3, j * 4)),
        };

        actions.push(action);
      }

      tabData.push({
        label: tabNames[i % tabNames.length],
        actions: actions,
      })
    }

    const playerData = [];

    playerData.push(generatePlayerData({
      name: 'Player 1',
      characterIndex: 1,
      isSelf: true,
      playerIndex: 0,
    }, actionIds));

    for (let i = 2; i <= 5; i++) {
      playerData.push(generatePlayerData({
        name: 'Player ' + i,
        characterIndex: i,
        isSelf: false,
        playerIndex: i - 1,
      }, actionIds));
    }

    return {
      events: ['toggleEndMeeting'],

      countdownTimer: null,
      timeLeftSeconds: 60 * 5,

      toggleEndMeeting: false,

      icon: hazardData.identifier,
      title: 'Measles',

      tabData: tabData,

      playerData: playerData,
    }
  },
  mounted () {
    this.countdownTick()
  },
  methods: {
    onToggleEndMeetingUpdated(toggleEndMeeting) {
      this.toggleEndMeeting = toggleEndMeeting;

      this.triggerEvent('toggleEndMeeting')
    },
    countdownTick () {
      if (this.countdownTimer) {
        clearTimeout(this.countdownTimer)
      }

      this.countdownTimer = setTimeout(() => {
        this.timeLeftSeconds -= 1

        if (this.timeLeftSeconds === 0) {
          clearTimeout(this.countdownTimer)

          this.countdownTimer = null
        } else {
          this.countdownTick()
        }
      }, 1000)
    },
  },
  computed: {
    endMeetingVotesCurrent () {
      return (this.toggleEndMeeting ? 1 : 0)
    },
    endMeetingVotesNeeded () {
      return 6
    },
  }
}
</script>

<style lang="scss" scoped>
.hazard-popup {
  margin: 0 auto;
}
</style>
