export const EVENT_FREQUENCY_CHANGED = 'frequencyChanged';

export class FrequencyChangedEvent extends Event {
  uid;

  constructor(uid, frequency) {
    super(EVENT_FREQUENCY_CHANGED);

    this.uid = uid;
    this.frequency = frequency;
  }
}
