<template>
  <lobby-popup-double-template :style="{ width: '1300px', margin: '0 auto' }" content-class="equal-padding">
    <template v-slot:leftHeader>
      {{ $t('end.decisionFeedbackHeaderLeft') }}
    </template>
    <template v-slot:leftContent>
      <end-game-popup-decision-card
        :value="playerDecision"
        :player-votes="playerVotes"
        :show-button="false"
      />
    </template>
    <template v-slot:rightHeader>
      {{ $t('end.decisionFeedbackHeaderRight') }}
    </template>
    <template v-slot:rightContent>
      <div class="feedback-box">
        <div class="feedback-box-header">{{ decisionResult.title }}</div>
        <div class="feedback-box-content">{{ decisionResult.text }}</div>
      </div>
    </template>
    <template v-slot:footer>
      <app-button color="green" @click="onClickContinue" width="lg" height="lg">Continue</app-button>
    </template>
  </lobby-popup-double-template>
</template>

<script>
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import EndGamePopupDecisionCard from '@/components/EndGamePopup/EndGamePopupDecision/EndGamePopupDecisionCard.vue'
import { objectToArray } from '@/utils/arrayUtil'
import LobbyPopupDoubleTemplate from '@/components/Lobby/LobbyPopupDoubleTemplate.vue'
import { DecisionData } from '@/data/decision-data'

export default {
  name: 'EndGamePopupFeedback',
  components: {
    LobbyPopupDoubleTemplate,
    LobbyPopupTemplate,
    AppButton,
    HazardPopupContainer,
    EndGamePopupDecisionCard,
  },
  props: {
    playerDecision: {
      type: String,
      default: null,
      required: false,
    },
    gameDecision: {
      type: String,
      default: null,
      required: false,
    },
    playerVotes: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
  },
  emits: ['click:continue'],
  data () {
    console.log('DecisionData', DecisionData);

    return {
    }
  },
  computed: {
    decisionData() {
      return DecisionData[this.playerDecision];
    },
    winningConditions() {
      return this.decisionData.winningConditions;
    },
    result() {
      return this.winningConditions.gameDecisionResults[this.gameDecision];
    },
    decisionResult() {
      return this.winningConditions[this.result];
    },
  },
  methods: {
    onClickContinue() {
      this.$emit('click:continue');
    },
  },
}
</script>

<style lang="scss" scoped>
.text-content {
  padding: 20px 0;

  h2 {
    font-size: 40pt;
  }

  p {
    font-size: 18pt;
  }
}

.feedback-box {
  margin: 0 50px;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.794153213500977px 8.794153213500977px 8.794153213500977px 8.794153213500977px rgba(0, 0, 0, 0.60), -4.397076606750488px -4.397076606750488px 3.5176613330841064px 0px rgba(0, 0, 0, 0.40) inset, 3.5176613330841064px 3.5176613330841064px 2.6382460594177246px 0px rgba(255, 255, 255, 0.40) inset;
  color: white;
  height: calc(100% - 50px);
  width: calc(100% - 50px);

  .feedback-box-content {
    padding: 30px;
    text-align: left;
    font-size: 18px;
    line-height: 140%;
  }

  .feedback-box-header {
    font-weight: bold;
    padding: 10px;
    background: rgba(#01A44F, .4);
    font-size: 30pt;
  }
}
</style>

<style lang="scss">
.end-game-popup-intro-popup {
  .hazard-popup-body-inner {
    height: fit-content;
    margin-top: 150px;
    margin-bottom: 70px;
  }
}

.end-game-popup-decision-footer {
  color: white;
  text-align: left;
  padding: 0 100px;

  strong {
    font-size: 16pt;
  }

  p {
    font-size: 14pt;
  }
}
</style>
