import Character1 from '@/assets/character/character-images/Character1.png';
import Character2 from '@/assets/character/character-images/Character2.png';
import Character3 from '@/assets/character/character-images/Character3.png';
import Character4 from '@/assets/character/character-images/Character4.png';
import Character5 from '@/assets/character/character-images/Character5.png';
import Character6 from '@/assets/character/character-images/Character6.png';
import Character7 from '@/assets/character/character-images/Character7.png';
import Character8 from '@/assets/character/character-images/Character8.png';
import Character9 from '@/assets/character/character-images/Character9.png';
import Character10 from '@/assets/character/character-images/Character10.png';
import Character11 from '@/assets/character/character-images/Character11.png';
import Character12 from '@/assets/character/character-images/Character12.png';
import Character13 from '@/assets/character/character-images/Character13.png';
import Character14 from '@/assets/character/character-images/Character14.png';
import Character15 from '@/assets/character/character-images/Character15.png';
import Character16 from '@/assets/character/character-images/Character16.png';
import Character17 from '@/assets/character/character-images/Character17.png';
import Character18 from '@/assets/character/character-images/Character18.png';
import Character19 from '@/assets/character/character-images/Character19.png';
import Character20 from '@/assets/character/character-images/Character20.png';

let characterImages = [
  Character1,
  Character2,
  Character3,
  Character4,
  Character5,
  Character6,
  Character7,
  Character8,
  Character9,
  Character10,
  Character11,
  Character12,
  Character13,
  Character14,
  Character15,
  Character16,
  Character17,
  Character18,
  Character19,
  Character20,
];

const characters = [];

for (let i = 0, len = characterImages.length; i < len; i++) {
  characters.push({
    identifier: 'character' + (i + 1),
    image: characterImages[i],
    //role: 'finance',
  });
}

export default characters;
