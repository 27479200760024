<template>
  <app-button class="button-back" @click.prevent.stop="onClick" color="red" :width="width" :height="height" height="fit-content">
    <img src="@/assets/icons/icon-arrow-backward.png" />
  </app-button>
</template>
<script>
import AppButton from '@/components/common/buttons/AppButton.vue'

export default {
  name: 'BackButton',
  components: {
    AppButton,
  },
  props: {
    width: {
      type: String,
      required: false,
      default: 'fit-content',
    },
    height: {
      type: String,
      required: false,
      default: 'lg',
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
.button-back {
  img {
    max-height: 26px;
  }
}
</style>
