<template>
  <lobby-popup-template v-if="hazardMeetingActionCategories.length === 0" :show-header="true"
                        :show-footer="false"
                        :style="{ width: '1300px', height: '900px' }"
                        :content-class="'equal-padding'"
                        :use-overflow="false"
                        :with-background="true"
                        :center-position="true">
    <template v-slot:header>
      No actions
    </template>
    <template v-slot:content>
      This hazard has no actions
    </template>
  </lobby-popup-template>

  <hazard-popup
    v-else
    className="hazard-popup"

    :action-categories="hazardMeetingActionCategories"
    :players="players"
    :investments="investments"
    :tab-lookers="tabLookers"

    @player-investment-change="onPlayerInvestmentChange"
    @looking-at-category-change="onLookingAtCategoryChange"
    @looking-at-tab-change="onLookingAtTabChange"

    :icon="activeHazardMeeting ? activeHazardMeeting.icon : ''"
    :title="activeHazardMeeting ? activeHazardMeeting.title : ''"
    :can-interact="canInteract"
  />
</template>
<script>
import HazardPopup from './index.vue'
import { mapGetters, mapState } from 'vuex'
import {HAZARD_MEETING_STATE_STARTING} from '@/enum/HazardMeetingState'
import {
  ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_HOST,
  ACTIVE_HAZARD_MEETING_PLAYER_STATE_JOINED_PARTICIPANT
} from '@/enum/ActiveHazardMeetingPlayerState'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import {objectToArray} from '@/utils/arrayUtil'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue';

export default {
  name: 'HazardPopupLive',
  components: {
    HazardPopupContainer,
    LobbyPopupTemplate,
    MeetingPopup,
    HazardPopup,
  },

  data() {
    return {
      toggleEndMeeting: false,
      toggleStartMeeting: false,
    }
  },
  emits: ['endMeeting', 'cancelMeeting'],
  methods: {
    onLookingAtCategoryChange(lookingCategory) {
      this.$gameClient.playerUpdate('meetingLookingAtCategoryIdentifier', lookingCategory.identifier);
    },
    onLookingAtTabChange(lookingTab) {
      this.$gameClient.playerUpdate('meetingLookingAtTabIdentifier', lookingTab.identifier);
    },
    onPlayerInvestmentChange({action, newInvestment}) {
      this.$gameClient.playerCallMethod('setActionInvestment', {
        actionId: action.id,
        investment: newInvestment,
      }).catch((error) => {
        this.$store.commit('errorMessage', this.$t('error.couldNotInvest').replace('[ERROR]', error));
      });
    },

    onCancelMeeting() {
      this.$emit('cancelMeeting');
    },
    onEndMeeting() {
      this.$emit('endMeeting');
    },
    onPlayerInvestmentUpdated() {
      this.$gameClient.playerCallMethod('setActionInvestment', {
        actionId: action.id,
        investment,
      }).catch((error) => {
        this.$store.commit('errorMessage', this.$t('error.couldNotInvest').replace('[ERROR]', error));
      });
    },
    onCurrentTabIndexUpdated(currentTabIndex) {
      this.$gameClient.playerSet({
        activeMeetingTabIndex: currentTabIndex,
      });
    },
  },
  mounted() {
    console.log('hazardMeetingActionCategories', this.hazardMeetingActionCategories);
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerIsFacilitator']),
    canInteract() {
      return !this.playerIsFacilitator;
    },
    players() {
      if (!this.room || !this.room.players) {
        return {};
      }

      return this.room.players;
    },
    tabLookers() {
      if (!this.players) {
        return {};
      }

      const tabLookers = {};
      const tabLookerIds = [];

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.meetingLookingAtTabIdentifier) {
          const tabIdentifier = player.meetingLookingAtTabIdentifier;

          if (!tabLookerIds.includes(tabIdentifier)) {
            tabLookerIds.push(tabIdentifier);
            tabLookers[tabIdentifier] = [];
          }

          tabLookers[tabIdentifier].push(player);
        }
      }

      return tabLookers;
    },
    investments() {
      const actionIdentifierAssoc = {};

      for (const i in this.activeHazardMeeting.categories) {
        const category = this.activeHazardMeeting.categories[i];

        for (const j in category.tabs) {
          const tab = category.tabs[j];

          for (const k in tab.actions) {
            const action = tab.actions[k];

            actionIdentifierAssoc[action.id.toString()] = action.identifier;
          }
        }
      }

      console.log('actionIdentifierAssoc', actionIdentifierAssoc);

      const parsedInvestments = [];

      for (const playerId in this.players) {
        const player = this.players[playerId];

        const activeMeetingInvestments = objectToArray(player.activeMeetingInvestments);

        for (const investment of activeMeetingInvestments) {
          const actionId = investment.actionId.toString();
          const actionIdentifier = actionIdentifierAssoc[actionId] || actionId;

          parsedInvestments.push({
            actionIdentifier: actionIdentifier,
            playerCode: player.playerCode,
            investment: investment.investment,
          });
        }
      }

      return parsedInvestments;
    },
    hazardMeetingActionCategories() {
      const activeHazardMeeting = this.activeHazardMeeting;

      if (!activeHazardMeeting) {
        return [];
      }

      if (!activeHazardMeeting.categories) {
        return [];
      }

      return objectToArray(activeHazardMeeting.categories);
    },

    activeHazardMeeting() {
      if (!this.room) {
        return null;
      }

      if (!this.room.activeHazardMeeting) {
        return null;
      }

      return this.room.activeHazardMeeting.prioritizedWorldHazards[this.room.activeHazardMeeting.currentHazardIndex.toString()];
    },
  }
}
</script>

<style lang="scss" scoped>
.hazard-popup {
  margin: 120px auto 0 auto;
  transform: scale(100%);
}
</style>
<script setup>
</script>
