<template>
  <div class="dashboard-popup row">
    <close-button @click="onClickClose" class="btn-close-popup" />

    <div class="dashboard-popup-inner d-flex align-items-stretch flex-nowrap">
      <div class="dashboard-popup-categories">
        <ul>
          <li>
            <long-button
              color="blue"
              id="btn-dashboard"
              class="btn-dashboard mb-4"
              :state="currentSection === 'indicators' ? 'active' : ''"
              @click="currentSection = 'indicators'"
            >{{ $t('dashboard.indicators') }}
            </long-button>
          </li>
          <li>
            <long-button
              color="blue"
              id="btn-used-actions"
              class="btn-dashboard mb-4"
              :state="currentSection === 'usedActions' ? 'active' : ''"
              @click="currentSection = 'usedActions'"
            >{{ $t('dashboard.usedActions') }}
            </long-button>
          </li>
        </ul>
      </div>
      <div class="dashboard-popup-vertical-separator-container">
        <div class="dashboard-popup-vertical-separator"/>
      </div>
      <div class="dashboard-popup-content p-0 flex-fill">
        <header class="primary-header p-2">
          <span>{{ headlineText }}</span>
        </header>
        <header class="secondary-header p-2">
          <div class="row" v-if="currentSection === 'indicators'">
            <div class="col text-start">{{ $t('dashboard.indicatorName') }}:</div>
            <div class="col text-end">{{ $t('dashboard.indicatorAverageCapacityLevel') }}:</div>
          </div>
        </header>
        <div class="dashup-popup-content-inner epic-scrollbar">
          <div v-if="currentSection === 'indicators'">
            <dashboard-indicator-category
              v-for="category of categories"
              :data="category"
              :jee-scores="jeeScores"
              :class="category.identifier === 'poe' ? 'mt-4' : ''"
            />
          </div>
          <div v-else-if="currentSection === 'usedActions'">
            <meeting-analytics
              type="room"
              :all-actions="implementedActions"
              :implemented-actions="implementedActions"
              :months-available="currentMonth"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton'
import DashboardIndicatorCategory from '@/components/dashboard/DashboardIndicatorCategory.vue'
import DashboardAction from '@/components/dashboard/DashboardAction.vue'
import { mapState } from 'vuex'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import {TutorialGroupIdentifier, TutorialUtil} from '@/utils/tutorialUtil';
import MeetingAnalytics from '@/components/MeetingAnalytics/index.vue';

export default {
  name: 'DashboardPopup',
  components: {
    MeetingAnalytics,
    CloseButton,
    DashboardAction,
    NoDragImage,
    LongButton,
    DashboardIndicatorCategory
  },
  props: {
    usedActionsStrategic: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    usedActionsResponse: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    jeeScores: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    currentMonth: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  emits: ['close'],
  mounted () {
    this.loadData()
  },
  data () {
    return {
      currentSection: 'indicators',
      currentActionSection: 'strategicActions',
      categories: [],

      loading: true,
    }
  },
  watch: {
    currentSection () {
      //this.currentActionSection = 'strategicActions'

      if (this.currentSection === 'usedActions') {
        TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.DashboardActions);
      }
    },
  },
  computed: {
    ...mapState(['jeeCategories']),
    implementedActions() {
      return [
        ...this.usedActionsStrategic,
        ...this.usedActionsResponse
      ];
    },
    headlineText () {
      if (this.currentSection === 'indicators') {
        return 'Indicators'
      } else if (this.currentSection === 'usedActions') {
        return 'Used Actions'
      }
    },
    currentActions() {
      if (this.currentActionSection === 'strategicActions') {
        return this.usedActionsStrategic;
      }

      if (this.currentActionSection === 'responseActions') {
        return this.usedActionsResponse;
      }

      return [];
    }
  },
  methods: {
    loadData () {
      if (this.jeeCategories && this.jeeCategories.length) {
        this.categories = this.jeeCategories

        this.onLoaded();
      } else {
        this.$apiClient.jee.getJeeData().then((jeeCategories) => {
          this.categories = jeeCategories

          this.$store.commit('jeeCategories', jeeCategories);

          this.onLoaded();
        })
      }
    },
    onLoaded() {
      TutorialUtil.launchTutorialGroupByIdentifier(TutorialGroupIdentifier.Dashboard);
    },
    onClickClose () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-popup {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), linear-gradient(0deg, rgba(172, 103, 0, 0.7) 23.32%, rgba(255, 153, 0, 0.7) 103.81%), #8D8D8D;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  //background: url("@/assets/textures/grunge-seamless.png"), #184E5A;
  height: 100%;
  padding: 0;
  width: 100%;
  margin-top: 30px;
  position: relative;

  .dashboard-popup-categories,
  .dashboard-popup-vertical-separator-container {
    padding: 50px 0;
  }

  z-index: 100000;

  > div {
    height: 100%;
  }

  .dashboard-popup-categories {
    width: 260px;
    min-width: 260px;
    text-align: left;
    overflow: hidden;


    ul {
      list-style: none;
      padding-left: 0;
      margin-left: 0;
      height: 100%;
      overflow-y: auto;
    }
  }

  .dashboard-popup-content {
    padding: 20px;
    position: relative;

    .primary-header {
      text-transform: uppercase;
      text-align: center;
      width: 100%;

      color: #FFFFFF;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      margin-bottom: 10px;
    }

    .secondary-header {
      text-transform: uppercase;
      width: 100%;
      height: 41px;

      color: #FFFFFF;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      margin-bottom: 10px;

      .text-start {
        padding-left: 80px;
      }

      .text-end {
        padding-right: 80px;
      }
    }
  }
}


.dashboard-popup-vertical-separator-container {
  width: 10px;
  min-width: 10px;
}

.dashboard-popup-vertical-separator {
  background: rgba(137, 83, 33, 0.49);
  box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.4), inset -4px -4px 3px rgba(255, 255, 255, 0.4);
  border-radius: 22px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 100%;
  height: 100%;
}

.dashup-popup-content-inner {
  width: calc(100% - 0px);
  margin: 0 auto;
  height: calc(100% - 120px);
  padding: 0 10px;
  overflow-y: auto;
}

.btn-back {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 20px;
}

.btn-close-popup {
  opacity: 1;
  position: absolute;
  top: -20px;
  z-index: 100000;
  right: -20px;
  width: 50px;
  height: 80px;
}
</style>
