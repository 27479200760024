<template>
  <div class="row">
    <label class="form-label">Player data</label>
    <bootstrap-select :options="playerDataOptions" v-model="playerDataValue" />
  </div>
  <div class="row">
    <button class="btn btn-success" @click="regeneratePlayers">Generate</button>
  </div>

  <div class="right-player-list-bar-dev-container">
    <right-player-list-bar :players="players" />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import RightPlayerListBar from '@/components/player-ui/RightPlayerListBar/RightPlayerListBar.vue'
import CharacterData from '@/data/character-data'
import fakePlayerData from '@/data/fake-player-data'
import { mapState } from 'vuex'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'RightPlayerListBarDev',
  components: {
    RightPlayerListBar,
    BootstrapSelect,
  },
  extends: DevComponentContainer,

  data () {
    return {
      playerDataOptions: [
        {
          value: '1',
          label: 'All players joined',
        },
        {
          value: '2',
          label: 'No other players joined',
        },
        {
          value: '3',
          label: '1 players joined',
        },
        {
          value: '4',
          label: '2 players joined',
        },
        {
          value: '5',
          label: '3 players joined',
        },
      ],
      playerDataValue: '1',

      players: {},
    }
  },
  mounted() {
    if (!this.roles || !this.roles.length) {
      this.$apiClient.role.getRoleData().then((roles) => {
        this.$store.commit('roles', roles);

        this.onRoles();
      });
    } else {
      this.onRoles();
    }
  },
  watch: {
    playerDataValue() {
      if (this.roles && this.roles.length > 0) {
        this.regeneratePlayers();
      }
    }
  },
  computed: {
    ...mapState(['roles']),
  },
  methods: {
    onRoles() {
      this.regeneratePlayers();
    },
    generatePlayer1() {
      return this.generatePlayer({
        playerCode: '111111',
        name: 'Player 1',
        isSelf: true,
        sessionId: 'yes',
      });
    },
    generateOtherPlayer(playerCode, name, hasJoined) {
      return this.generatePlayer({
        playerCode: playerCode,
        name: name,
        isSelf: false,
        sessionId: hasJoined ? playerCode : null,
        muted: hasJoined && Math.random() < 0.15,
      });
    },
    generatePlayer2(hasJoined = true) {
      return this.generateOtherPlayer('222222', 'Player 2', hasJoined);
    },
    generatePlayer3(hasJoined = true) {
      return this.generateOtherPlayer('333333', 'Player 3', hasJoined);
    },
    generatePlayer4(hasJoined = true) {
      return this.generateOtherPlayer('444444', 'Player 4', hasJoined);
    },
    generatePlayer5(hasJoined = true) {
      return this.generateOtherPlayer('555555', 'Player 5', hasJoined);
    },
    regeneratePlayers() {
      const players = [];

      if (this.playerDataValue === '1') {
        // All players
        players.push(this.generatePlayer2());
        players.push(this.generatePlayer3());
        players.push(this.generatePlayer4());
        players.push(this.generatePlayer5());
      } else if (this.playerDataValue === '2') {
        // Only us
      } else if (this.playerDataValue === '3') {
        // 1 other player joined

        players.push(this.generatePlayer2());
      } else if (this.playerDataValue === '4') {
        // 2 other players joined

        players.push(this.generatePlayer3());
        players.push(this.generatePlayer5());
      } else if (this.playerDataValue === '5') {
        // 3 other players joined

        players.push(this.generatePlayer2());
        players.push(this.generatePlayer4());
        players.push(this.generatePlayer5());
      }

      const returnPlayers = {
        '111111': this.generatePlayer1(),
        '222222': this.generatePlayer2(false),
        '333333': this.generatePlayer3(false),
        '444444': this.generatePlayer4(false),
        '555555': this.generatePlayer5(false),
      };

      for (const player of players) {
        returnPlayers[player.playerCode] = player;
      }

      this.players = returnPlayers;
    },
    generatePlayer(playerOverride) {
      const player = {...{}, ...fakePlayerData};

      player.characterIndex = Math.floor(randomBetween(0, CharacterData.length - 1));

      console.log('this.roles', this.roles);
      player.roleIndex = Math.floor(randomBetween(0, this.roles.length - 1));
      console.log('player.roleIndex', player.roleIndex);

      const finalPlayer = {...player, ...playerOverride};

      console.log('generatePlayer', {
        player,
        fakePlayerData,
        finalPlayer,
      });

      return finalPlayer;
    },
  }
}
</script>

<style lang="scss" scoped>
.right-player-list-bar-dev-container {
  display: inline-block;
  width: fit-content;
  //background: rgba(255, 0, 0, 25%);
}
</style>
