<template>
  <ui-timer-toggle
    v-if="showTimer"
    :button-html="buttonHtmlComputed"
    :seconds-left="secondsLeft"
    :button-color="buttonColor"
    v-model="currentValue"
    :disabled="isPaused"
    :player-count="playerCount"
    :skip-count="skipCount"
  >
    <slot />
  </ui-timer-toggle>
</template>

<script>
import LongButton from '@/components/common/LongButton.vue'
import UiTimer from '@/components/player-ui/UiTimer/UiTimer.vue'
import { mapGetters, mapState } from 'vuex'
import UiTimerToggle from '@/components/player-ui/UiTimer/UiTimerToggle.vue'
import { encodeHtml } from '@/utils/stringUtil'
import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  name: 'UiTimerReadyLive',
  components: { LongButton, UiTimerToggle },
  props: {
    timerIdentifier: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'green',
    },
    buttonText: {
      type: String,
      required: true,
    },
    attribute: {
      type: String,
      required: false,
      default: 'isReady',
    },
    hidden: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      currentValue: false,
    };
  },

  mounted() {
    const playerSelf = this.playerSelf;

    if (playerSelf) {
      this.currentValue = playerSelf[this.attribute];
    }
  },

  watch: {
    currentValue() {
      let currentValue;

      const playerSelf = this.playerSelf;

      if (playerSelf) {
        currentValue = this.playerSelf[this.attribute];
      }

      if (this.currentValue !== currentValue) {
        this.$gameClient.playerUpdate(this.attribute, this.currentValue);
      }
    },
  },

  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf', 'players']),
    playerCount() {
      let playerCount = 0;

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.sessionId) {
          playerCount += 1;
        }
      }

      return playerCount;
    },
    skipCount() {
      let skipCount = 0;

      for (const playerId in this.players) {
        const player = this.players[playerId];

        if (player.isReady || player.voteSkipTimer || player.voteSkipMeeting) {
          skipCount++;
        }
      }

      return skipCount;
    },
    isPaused() {
      if (!this.room) {
        return false;
      }

      return this.room.isPaused;
    },
    timer() {
      if (!this.room) {
        return null;
      }

      if (!this.room.timers) {
        return null;
      }

      const timerIdentifiers = Object.keys(this.room.timers);

      if (!timerIdentifiers.includes(this.timerIdentifier)) {
        return null;
      }

      return this.room.timers[this.timerIdentifier];
    },
    secondsLeft() {
      const timer = this.timer;

      if (!timer) {
        return null;
      }

      return Math.max(0, Math.round(timer.msLeft / 1000));
    },
    readyCount() {
      const players = this.players;

      let readyCount = 0;

      for (const playerId in players) {
        const player = players[playerId];

        if (player.isSelf) {
          this.currentValue = player[this.attribute];
        }

        if (player[this.attribute]) {
          readyCount++;
        }
      }

      return readyCount;
    },
    totalCount() {
      const players = this.players;

      let totalCount = 0;

      for (const playerId in players) {
        const player = players[playerId];

        if (player.sessionId && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          totalCount++;
        }
      }

      return totalCount;
    },

    buttonHtmlComputed() {
      const buttonHtml = encodeHtml(this.buttonText);

      const readyCount = this.readyCount;
      const totalCount = this.totalCount;

      return `<span class="avoid-whitespace">${buttonHtml}</span> <span class="avoid-whitespace">(${readyCount} / ${totalCount})</span>`;
    },

    showTimer() {
      if (this.hidden) {
        //return false;
      }

      const timer = this.timer;

      if (!timer) {
        return false;
      }

      return timer.isRunning;
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-timer {

  header {
    width: 100%;
    height: 75px;
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
    box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
    padding: 10px 0;

    .timer {
      padding-left: 70px;
      background: url("@/assets/icons/clock-icon.png") no-repeat center left;
      background-size: contain;
      width: 180px;
      margin: 0 auto;

      font-weight: 700;
      font-size: 30pt;
      color: #ffffff;
      text-shadow: 0px 3.84724px 3.84724px rgba(0, 0, 0, 0.25);
    }
  }

  footer {
    width: 100%;
    height: fit-content;
    background: linear-gradient(90deg, rgba(#0F3D3D, 80%) -31.57%, rgba(#112B53, 80%) 101.27%);
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 15pt;

    p {
      margin-bottom: 0;
    }
  }

}
</style>
