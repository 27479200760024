<template>
  <strategic-meeting-action :description="description" :cost="cost" @add="onAdd" />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import StrategicMeetingAction from '@/components/StrategicMeeting/StrategicMeetingAction/index.vue'

export default {
  name: 'StrategicMeetingActionDev',
  components: {
    StrategicMeetingAction,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['add'],
      description: 'This is a description of some action that I am not quiet sure what does',
      cost: 10,
    }
  },
  methods: {
    onAdd() {
      this.triggerEvent('add')
    }
  },
}
</script>
