<template>
  <hazard-popup-prioritization
    :hazard-datas="hazardDatas"
    :player-prioritizations="playerPrioritizations"

    @click:continue="onContinue"
  />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupPrioritization from '@/components/HazardMeeting/HazardPopupPrioritization/index.vue'
import { generateFakeHazard1, generateFakeHazard2 } from '@/utils/generator'
import { generatePlayerData } from '@/utils/playerDataGenerator'

export default {
  name: 'HazardPopupPrioritizationDev',
  components: {
    HazardPopupPrioritization,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const hazardDatas = [];
    const playerPrioritizations = [];

    for (let i = 0; i < 10; i++) {
      const hazardData = i % 2 === 0 ? generateFakeHazard1() : generateFakeHazard2();

      console.log('hazardData', hazardData);

      hazardDatas.push(hazardData);
    }

    for (let i = 0; i < 5; i++) {
      const hazardPrioritizationCount = {};

      for (const hazardData of hazardDatas) {
        if (Math.random() > 1) {
          continue;
        }

        hazardPrioritizationCount[hazardData.id.toString()] = Math.floor(Math.random() * 4) + 1;
      }

      playerPrioritizations.push({
        player: generatePlayerData({ characterIndex: i, playerIndex: i, playerCode: i.toString() }),
        hazardPrioritizationCount: hazardPrioritizationCount
      });
    }

    return {
      events: ['continue'],

      hazardDatas: hazardDatas,

      playerPrioritizations: playerPrioritizations,
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    },
  },
}
</script>

<style lang="scss" scoped>
.hazard-popup-prioritization {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
