<template>
  <div class="end-game-popup-decision-card" :class="computedClasses">
    <div class="card-voters d-flex gap-2">
      <div class="card-voter-container d-flex gap-2">
        <div class="card-voter" v-for="player in playerVotesAll">
          <character-thumbnail
            v-if="player"
            :character-index="player.characterIndex"
            size="tiny"
          />
        </div>
      </div>
    </div>
    <div class="card-icon-container">
      <no-drag-image :src="iconSource" />
    </div>
    <div class="card-description-container">
      <div class="card-description-container-inner">
        <div class="card-description-container-text">
          {{ description }}
        </div>
      </div>
    </div>
    <div class="card-button-container" v-if="buttonLabel">
      <app-button
        color="orange"
        width="full"
        height="lg"
        text-size="lg"
        @click="onClickChoose"
        :state="selected ? 'active' : ''"
      >
        {{ buttonLabel }}
      </app-button>
    </div>
  </div>
</template>

<script>
import NoDragImage from '@/components/common/NoDragImage.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import IconRestrict from '@/assets/icons/calendar-icons/calendar-icon-restrict.png'
import IconHold from '@/assets/icons/calendar-icons/calendar-icon-hold.png'
import IconDelay from '@/assets/icons/calendar-icons/calendar-icon-delay.png'
import IconCancel from '@/assets/icons/calendar-icons/calendar-icon-cancel.png'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import { DecisionData } from '@/data/decision-data'

export default {
  name: 'EndGamePopupDecisionCard',
  components: { CharacterThumbnail, PlayerCharacterPlate, AppButton, NoDragImage },
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: String,
      required: true,
    },
    playerVotes: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  emits: ['choose'],
  methods: {
    onClickChoose() {
      this.$emit('choose', this.value);
    }
  },
  data() {
    return {
      DecisionData: DecisionData,
    };
  },
  computed: {
    decisionData() {
      return this.DecisionData[this.value];
    },
    icon() {
      return this.decisionData.icon;
    },
    buttonLabel() {
      if (!this.showButton) {
        return null;
      }

      const translationIdentifier = this.decisionData.translationIdentifier;

      return this.$t(`end.decision${translationIdentifier}Button`);
    },
    description() {
      const translationIdentifier = this.decisionData.translationIdentifier;

      return this.$t(`end.decision${translationIdentifier}Body`);

      //return this.decisionData.description;
    },
    computedClasses() {
      return [
        `end-game-popup-decision-card-label-${this.buttonLabel ? 'on' : 'off'}`
      ];
    },
    iconSource() {
      if (this.icon === 'restrict') {
        return IconRestrict;
      } else if (this.icon === 'hold') {
        return IconHold;
      } else if (this.icon === 'delay') {
        return IconDelay;
      } else if (this.icon === 'cancel') {
        return IconCancel;
      }
    },
    playerVotesAll() {
      const playerVotes = this.playerVotes;

      for (let i = playerVotes.length, len = 5; i < len; i++) {
        playerVotes.push(null);
      }

      return playerVotes;
    },
    votesUnanimous() {

      let votedOptions = 0;
      for (let votes of this.playerVotes) {
        const nonNullVotes = votes.filter(vote => vote !== null);
        if (nonNullVotes.length > 0) {
          votedOptions += 1
        }
      }

      return votedOptions === 1;

    },
  },
}
</script>

<style lang="scss" scoped>
.end-game-popup-decision-card {
  background: url("@/assets/textures/wall-seamless-20-percent.png"), #131D2B;
  mix-blend-mode: normal;
  border: 1.20961px solid #051224;
  box-shadow: inset 0px 0px 2.41923px 1.20961px #071C27, inset 4.83846px 0px 3.62884px rgba(239, 239, 239, 0.25);
  border-radius: 6.04807px;

  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 16pt;

  width: 290px;
  height: 550px;

  position: relative;

  .card-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
  }

  &.end-game-popup-decision-card-label-off {
    height: 450px;
  }
}

.card-description-container {
  padding: 10px;
  height: 200px;

  .card-description-container-inner {
    background: rgba(#000000, .34);
    padding: 10px;
    height: 100%;
    position: relative;
    margin-top: 15px;

    .card-description-container-text {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.card-icon-container {
  padding-top: 25px;
  margin-bottom: -20px;
}

.card-voter {
  width: 47px;
  height: 50px;
  background: rgba(#000000, .34);
  border-radius: 4px;

  .character-thumbnail {
    transform: scale(90%);
    transform-origin: 3px bottom;
  }
}

.card-voters {
  padding: 10px;
}
</style>
