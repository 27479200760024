import { pickRandom, randomBetween } from '@/utils/randomUtil'
import { getTotalMonths } from '@/utils/meetingUtil'

const names = [
  "Tom", "Lily", "Sam", "Rose", "Max", "Eva", "Noah", "Ella", "Leo", "Ivy",
  "Finn", "Ada", "Ryan", "Amy", "Kai", "Zoe", "Eli", "Ava", "Zane", "Eve",
  "Ian", "May", "Lee", "Bee", "Jay", "Kia", "Ray", "Dee", "Moe", "Nia"
];

export function generatePlayerName() {
  const nameIndex = Math.floor(Math.random() * names.length);
  return names[nameIndex];
}

export function generatePlayersData(playerCount = 5, selfPlayer = 1) {
  const players = {};

  for (let i = 0; i < playerCount; i++) {
    const playerNumber = i + 1;
    const playerNumberString = playerNumber.toString();

    let playerCode = '';

    for (let j = 0; j < 6; j++) {
      playerCode += playerNumberString;
    }

    players[playerCode] = generatePlayerData({
      characterIndex: i,
      playerCode: playerCode,
      isSelf: i === (selfPlayer - 1),
    });
  }

  return players;
}
export function generatePlayerData(overwriteFields, actionIds = []) {
  const activeMeetingInvestments = [];

  /*if (overwriteFields.isSelf) {
    if (actionIds.length) {
      for (let actionId of actionIds) {
        activeMeetingInvestments.push({
          actionId: actionId,
          investment: 0,
        });
      }
    }
  } else {*/
    if (actionIds.length) {
      const investmentCount = Math.round(randomBetween(1, 10));

      for (let i = 0; i < investmentCount; i++) {
        activeMeetingInvestments.push({
          actionId: pickRandom(actionIds),
          investment: Math.round(randomBetween(1, 5)),
        });
      }
    }
  //}

  const activeMeetingTabIndex = Math.round(randomBetween(0, getTotalMonths()));

  const playerData = {
    "sessionId": "tX57sRmZ9",
    "joined": false,
    "playerCode": "111111",
    "voiceSocketId": "6caLHfn14bgoazzSAAAB",
    "muted": false,
    "playerType": 1,
    "isReady": false,
    "isSelf": false,
    "forceAiVote": false,
    "characterIndex": 3,
    "roleIndex": -1,
    "rolePoints": 15,
    "rolePointsTotalInvestment": 0,
    "activeMeetingInvestments": activeMeetingInvestments,
    "activeMeetingTabIndex": activeMeetingTabIndex,
    "voteSkipMeeting": false,
    "voteStartMeeting": false,
    "activeHazardMeetingState": 1,
    "playerIndex": 0,
    "name": generatePlayerName(),
    "starMinigameLikelihoodVoteIndex": pickRandom([-1, 1, 8]),
    "starMinigameImpactVoteIndex": pickRandom([-1, 0, 1, 2, 3]),
    "starMinigameHazardVoteIndex": pickRandom([-1, 0, 1, 2, 3]),
  };

  for (let key in overwriteFields) {
    playerData[key] = overwriteFields[key];
  }

  return playerData;
}
