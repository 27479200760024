<template>
  <div class="analytics-container" :class="'analytics-container-' + type">
    <div class="d-flex justify-content-center my-2" v-if="type === 'event'">
      <div class="info-box">
        EVENT: <span>{{ eventData.label }}</span>
      </div>
      <div class="info-box">
        TIME: <span>{{ time }}</span>
      </div>
      <div class="info-box">
        DATE: <span>{{ date }}</span>
      </div>
      <div class="info-box">
        ROOMS: <span>{{ eventData?.rooms?.length || 0 }}</span>
      </div>
    </div>
    <!--<div class="d-flex justify-content-center my-2" v-else-if="type === 'room'">
      <div class="info-box">
        ROOM
      </div>
      <div class="info-box">
        TIME: <span>{{ time }}</span>
      </div>
      <div class="info-box">
        DATE: <span>{{ date }}</span>
      </div>
      <div class="info-box">
        PLAYER COUNT: <span>{{ roomData?.players?.length || 0 }}</span>
      </div>
    </div>-->

    <div class="month-box">
      MONTH
      <div class="d-flex justify-content-center">

        <app-button class="me-4" color="blue" width="md" height="sm" @click="selectMonth(selectedMonth - 1)">
          ◄ PREVIOUS
        </app-button>

        <div>
          <button
            v-for="number in months"
            :key="number"
            class="btn-month"
            @click="selectMonth(number)"
            :disabled="selectedMonth === number"
            :class="{ 'btn-month-available': number <= monthsAvailable }"
          >
            {{ number }}
          </button>
        </div>

        <app-button class="ms-4" color="blue" width="md" height="sm" @click="selectMonth(selectedMonth + 1)">
          NEXT ►
        </app-button>

      </div>
    </div>
    <div class="action-box">
      <div class="my-3 d-flex justify-content-start">
        <app-button class="ms-4" color="blue" width="lg" height="md" @click="selectActionType('strategic')"
                    :disabled="selectedActionType === 'strategic'">
          Strategic actions
        </app-button>
        <app-button class="ms-4" color="blue" width="lg" height="md" @click="selectActionType('hazard')"
                    :disabled="selectedActionType === 'hazard'">
          Hazard Response
        </app-button>
      </div>
      <div class="actions d-flex justify-content-start">
        <div class="action-column" v-if="filteredActionsAssoc && filteredActionsAssoc.length">
          <div v-for="category in filteredActionsAssoc">

            <div class="action-header">
              {{ category.category || 'Investments made in meeting ' }}
            </div>

            <div class="action" v-for="currentAction in category.actions">
              <div class="inner d-flex align-items-center ps-2 justify-content-between">
                <app-button class="action-inspect" width="sm" color="blue" @click="toggleInspectAction(currentAction)" :state="inspectedAction && inspectedAction.id === currentAction.id ? 'active' : ''">
                  <span class="action-inspect-icon">👁</span>
                </app-button>

                <div class="action-description">
                  {{ currentAction.text }}
                </div>

                <stat-bar :min="0" :max="100" :current="selectionPercentage(currentAction.selectedCount)"
                          :watch-value-changes="true" v-if="type === 'event'"/>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          Nothing invested in meeting {{ selectedMonth }}
        </div>

        <div class="action-inspect-info" v-if="inspectedAction">
          <div class="inner">
            {{ inspectedAction.text }}
            <hr>
            {{ inspectedAction.feedbackNarrative }}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/buttons/AppButton.vue';
import Sidebar from '@/components/Admin/Sidebar.vue';
import Accordion from '@/components/common/Accordion.vue';
import AdminUserRow from '@/components/Admin/AdminUserRow.vue';
import DashboardAction from '@/components/dashboard/DashboardAction.vue';
import StatBar from '@/components/player-ui/StatBar.vue';
import {formatDate, formatTime} from '@/utils/dateUtil';
import {getTotalMonths} from '@/utils/meetingUtil';

export default {
  name: 'MeetingAnalytics',
  props: {
    type: {
      type: String,
    },
    dateTime: {
      type: String,
    },
    allActions: {
      // Required only for "type = event"
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    implementedActions: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    eventData: {
      // Required only for "type = event"
      type: Object,
      required: false,
      default: null,
    },
    roomData: {
      // Required only for "type = room"
      type: Object,
      required: false,
      default: null,
    },
    monthsAvailable: {
      type: Number,
      required: false,
      default: 12,
    }
  },
  components: {
    StatBar,
    DashboardAction,
    AdminUserRow,
    Accordion,
    Sidebar,
    AppButton,
  },
  watch: {
    implementedActions: {
      deep: true,
      handler() {
        this.filterActions();
      },
    },
    selectedActionType: {
      handler() {
        this.inspectedAction = null;

        this.filterActions();
      },
    },
    selectedMonth: {
      handler() {
        this.inspectedAction = null;

        this.filterActions();
      },
    },
  },
  mounted() {
    this.filterActions();
  },
  computed: {
    date() {
      return formatDate(this.dateTime);
    },
    time() {
      return formatTime(this.dateTime);
    },
  },
  data() {
    return {
      // Rooms
      selectedMonth: 1,
      months: Array.from({length: getTotalMonths()}, (_, index) => index + 1),

      filteredActions: [],
      filteredActionsAssoc: [],

      inspectedAction: null,

      selectedActionType: 'strategic',
    };
  },
  methods: {
    getFilteredActions() {
      const actionsAssoc = {};

      for (const action of this.allActions) {
        if (actionsAssoc[action.id]) {
          continue;
        }

        const actionType = action.type || action.actionType;
        const investedAt = action.month || action.investAtMeeting;

        if (actionType !== this.selectedActionType) {
          continue;
        }

        if (this.type === 'event') {
          if (investedAt > this.selectedMonth) {
            continue;
          }
        } else if (this.type === 'room') {
          if (investedAt !== this.selectedMonth) {
            continue;
          }
        }

        actionsAssoc[action.id] = {
          ...action,
          ...{
            selectedCount: 0,
            bestOption: action.identifier.charAt(action.identifier.length - 1) === '1',
          }
        };
      }

      for (const implementedAction of this.implementedActions) {
        if (!actionsAssoc[implementedAction.id]) {
          continue;
        }

        if (implementedAction.investAtMeeting !== this.selectedMonth) {
          continue;
        }

        actionsAssoc[implementedAction.id].selectedCount++;
      }

      return Object.values(actionsAssoc);
    },
    getFilteredActionsAssoc() {
      // Filter actions based on selectedMonth and selectedActionType
      let filteredActions = this.getFilteredActions();

      // Group actions by category
      const groupedActions = filteredActions.reduce((result, action) => {
        const category = action.categoryLabel;

        if (!result[category]) {
          result[category] = [];
        }

        result[category].push(action);

        return result;
      }, {});

      // Convert object to array and sort by category
      const sortedActions = Object.keys(groupedActions).sort().map(category => {
        return {
          category: category,
          actions: groupedActions[category]
        };
      });

      return sortedActions;
    },
    filterActions() {
      this.filteredActions = this.getFilteredActions();

      this.filteredActionsAssoc = this.getFilteredActionsAssoc(this.filteredActions);
    },
    selectMonth(number) {
      const totalMonths = Math.min(this.monthsAvailable, getTotalMonths());

      this.selectedMonth = Math.max(1, Math.min(totalMonths, number));
    },
    selectActionType(selectedActionType) {
      this.selectedActionType = selectedActionType;
    },
    toggleInspectAction(action) {
      if (this.inspectedAction && this.inspectedAction.id === action.id) {
        this.inspectedAction = null;
      } else {
        this.inspectedAction = action;
      }
    },
    selectionPercentage(selections) {
      if (this.type === 'event') {
        const roomCount = this.eventData?.rooms.length || 0;

        return (selections / roomCount) * 100;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-month {
  background: transparent;
  border: none;
  transition: ease-in-out .05s;
  line-height: 2em;
  opacity: .4;
  color: lightgrey;
  cursor: default;

  &.btn-month-available {
    opacity: 1;
    cursor: pointer;
  }

  &:disabled {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.3em;
    line-height: 1em;
  }
}

.month-box {
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.38);
}

.action-box {
  padding-left: 5em;
  padding-right: 5em;
  padding-top: .2em;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #1c4750;
  box-shadow: inset -6.20755px 0px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 0px 3.72453px rgba(255, 255, 255, 0.4);

  .action-column {
    overflow-y: scroll;
  }
  .actions {
    height: 66.6vh;
    display: flex;
    gap: 1em;
  }

  .action-column {
    //width: 70%;
  }

  .action-header {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #000000;
    box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.16);
    text-align: left;
    margin-bottom: .4em;
    margin-top: .5em;
    padding: .5em .5em .5em 1em;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
  }

}

.analytics-container {
  margin: 0;
  color: white;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);

  &.analytics-container-event {
    padding-top: 1em;
    height: 84.45vh;
  }

  &.analytics-container-room {
    max-width: 1000px;
    margin: 0 auto;

    .action .inner {
      .action-description {
        width: auto;
        flex-grow: 1;
        padding-left: 10px;
        text-align: left !important;
      }
    }

    .action-column {
      overflow-y: visible !important;
      height: fit-content !important;
    }
  }
}

.info-box {
  width: 20%;

  span {
    background: rgba(0, 0, 0, 0.38);
    padding: .2rem;
    width: 60%;
    display: inline-block;
  }
}

.action {
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  height: 60px;

  .inner {
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), #000E21;
    border-radius: 5px;
    box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
    color: white;
    height: 100%;

    .app-button {
      font-size: 20px;
    }

  }
}

.action-inspect-info {
  border-radius: 10px;
  padding: 3px;
  background: linear-gradient(90deg, #FFFFFF, #052127);
  overflow: hidden;
  position: relative;
  margin-bottom: 5px;
  height: fit-content;
  max-width: 300px;
  text-align: left;

  .inner {
    padding: 1em;
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), #000E21;
    border-radius: 5px;
    box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
    color: white;
    height: 100%;
  }
}

.stat-bar {
  height: 30px;
  width: 50%;
  margin-right: 10px;
  border-radius: 5px;
}

.action-description {
  width: 40%;
  height: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-inspect-icon {
  margin: 5px 0;
  font-size: 30px;
  display: block;
}
</style>
