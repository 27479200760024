<template>
  <div class="star-point" v-if="currentScoreImpactKey" :style="styles.container" :key="currentScoreImpactKey">
    <div class="main-star" :style="styles.mainStar">
      <img class="main-star-icon" v-if="currentScoreImpactKey === 'collaboration'" src="@/assets/star-points/star-icon-collaboration.png" />
      <img class="main-star-icon" v-else-if="currentScoreImpactKey === 'healthSecurity'" src="@/assets/star-points/star-icon-health.png" />
      <img class="main-star-icon" v-else-if="currentScoreImpactKey === 'mgEventSuccess'" src="@/assets/star-points/star-icon-mg-event.png" />
      <img class="main-star-icon" v-else-if="currentScoreImpactKey === 'trust'" src="@/assets/star-points/star-icon-trust.png" />

      <label>{{ currentScoreImpactValueRounded }}%</label>
    </div>

    <star-point-small v-for="sideStarStyle of styles.sideStars" :style="sideStarStyle"></star-point-small>
  </div>
</template>

<script>
import { runSequentially, timeoutPromise } from '@/utils/promiseUtil'
import StarPointSmall from '@/components/StarPoints/StarPointSmall.vue'

export default {
  name: 'StarPoints',
  components: { StarPointSmall },
  props: {
    scoreImpacts: {
      type: Object,
      required: true,
    }
  },
  watch: {
    scoreImpacts: {
      deep: true,
      handler() {
        this.refresh();
      }
    }
  },
  data() {
    return {
      sideStarCount: 5,

      styles: {
        container: {},
        sideStars: [],
        mainStar: {}
      },

      currentScoreImpactValue: null,
      currentScoreImpactKey: null,
    }
  },
  mounted() {
    this.refresh();
  },
  computed: {
    currentScoreImpactValueRounded() {
      return Math.round(this.currentScoreImpactValue);
      //return this.currentScoreImpactValue.toFixed(1);
    },
  },
  methods: {
    refresh() {
      if (!this.scoreImpacts) {
        return;
      }

      let nonEmptyScoreImpacts = {};

      for (const scoreImpactKey in this.scoreImpacts) {
        const scoreImpactValue = this.scoreImpacts[scoreImpactKey];

        if (scoreImpactValue > 0) {
          nonEmptyScoreImpacts[scoreImpactKey] = scoreImpactValue;
        }
      }

      const scoreImpactKeys = Object.keys(nonEmptyScoreImpacts);

      if (!scoreImpactKeys.length) {
        return;
      }

      const customOrder = {
        trust: 1,
        healthSecurity: 2,
        collaboration: 3,
        mgEventSuccess: 4
      };

      scoreImpactKeys.sort((a, b) => {
        return customOrder[a] - customOrder[b];
      })

      const animationPromises = [];

      for (const impactKey of scoreImpactKeys) {
        const impactValue = nonEmptyScoreImpacts[impactKey];

        animationPromises.push(this.getAnimationPromise(impactKey, impactValue));
      }

      if (!animationPromises.length) {
        return;
      }

      console.log(`Running ${animationPromises.length} star animations..`);

      runSequentially(animationPromises).then(() => {
        console.log('All star animations have been run');
      }, 500)
    },
    getAnimationPromise(impactKey, impactValue) {
      return () => {
        return new Promise((resolve, reject) => {
          this.styles.container.left = '50%';
          this.styles.container.top = '50%';
          this.styles.container.transform = `scale(1)`;

          const starPointDimensions = { width: 176, height: 177 };
          const mainStarPointDimensions = { width: 744, height: 745 };

          const statCardLabelSelector = `.stat-card-${impactKey} .stat-card-content`;
          const statCardLabel = document.querySelector(statCardLabelSelector);
          const statCardLabelBoundingRect = statCardLabel.getBoundingClientRect();

          console.log('stat-bar-label', statCardLabel, statCardLabelSelector);

          const mainStarEndCoordinates = {
            x: statCardLabelBoundingRect.x + (statCardLabelBoundingRect.width / 2),
            y: statCardLabelBoundingRect.y + (statCardLabelBoundingRect.height / 2),
          }

          this.styles.mainStar.opacity = 0;

          console.log('StarAnimation', impactKey, impactValue);

          this.currentScoreImpactKey = impactKey;
          this.currentScoreImpactValue = impactValue;

          const centerCoordinates = { x: starPointDimensions.width * -0.5, y: starPointDimensions.height * -0.5 };

          const randomPointDegrees = [];
          const randomPointDegreesEach = 360 / this.sideStarCount;

          for (let i = 0, len = this.sideStarCount; i < len; i++) {
            randomPointDegrees.push((randomPointDegreesEach * i) + (Math.random() * (randomPointDegreesEach / 2)));
            //randomPointDegrees.push(Math.random() * 360);
          }

          const starPoints = this.distributePoints(this.sideStarCount, 300, 0, centerCoordinates.x, centerCoordinates.y, randomPointDegrees);
          const starPointsExpanded = this.distributePoints(this.sideStarCount, 400, 0, centerCoordinates.x, centerCoordinates.y, randomPointDegrees);
          const starPointsShrunk = this.distributePoints(this.sideStarCount, 50, 180, centerCoordinates.x, centerCoordinates.y, randomPointDegrees);

          // Add the star points
          const sideStarPromises = [];

          for (const starPoint of starPoints) {
            sideStarPromises.push(() => {
              return new Promise(subResolve => {
                this.styles.sideStars.push({
                  left: starPoint.x + 'px',
                  top: starPoint.y + 'px',
                  opacity: 1,
                });

                subResolve();
              })
            })
          }

          return runSequentially(sideStarPromises, 30)
            .then(() => {
              return timeoutPromise(1000);
            })
            .then(() => {
              // Move out a little bit
              for (let i = 0, len = this.styles.sideStars.length; i < len; i++) {
                this.styles.sideStars[i].left = starPointsExpanded[i].x + 'px';
                this.styles.sideStars[i].top = starPointsExpanded[i].y + 'px';
              }

              return timeoutPromise(400);
            })
            .then(() => {
              // Then move to center
              /*for (let i = 0, len = this.styles.sideStars.length; i < len; i++) {
                this.styles.sideStars[i].left = starPointsShrunk[i].x + 'px';
                this.styles.sideStars[i].top = starPointsShrunk[i].y + 'px';
                this.styles.sideStars[i].opacity = 0.5;
              }*/

              this.styles.mainStar.opacity = 1;

              return timeoutPromise(200);
            })
            .then(() => {
              // Then move to center
              for (let i = 0, len = this.styles.sideStars.length; i < len; i++) {
                this.styles.sideStars[i].left = starPointsShrunk[i].x + 'px';
                this.styles.sideStars[i].top = starPointsShrunk[i].y + 'px';
                this.styles.sideStars[i].opacity = 0;
              }

              return timeoutPromise(1000);
            })
            .then(() => {
              for (let i = 0, len = this.styles.sideStars.length; i < len; i++) {
                this.styles.sideStars.pop();
              }

              this.styles.sideStars = [];

              this.styles.container.left = mainStarEndCoordinates.x + 'px';
              this.styles.container.top = mainStarEndCoordinates.y + 'px';
              this.styles.container.transform = `scale(0)`;

              return timeoutPromise(300);
            })
            .then(() => {
              statCardLabel.classList.add('quick-pulse');

              const storeImpactKey = 'topUiExtra' + impactKey[0].toUpperCase() + impactKey.substring(1);

              console.log('storeImpactKey', storeImpactKey, impactValue);

              this.$store.commit(storeImpactKey, impactValue * 10);

              this.$emit('pulse', { statCardLabel });

              return timeoutPromise(500);
            })
            .then(() => {
              console.log('IT DONE!!!');

              statCardLabel.classList.remove('quick-pulse');

              resolve();
            });
        });
      };
    },
    distributePoints(distributionCount, radius, rotationOffset, centerX, centerY, randomPointDegrees) {
      const points = []; // Array to store the point coordinates

      const fullCircle = 360;
      const fullCircleEach = fullCircle / distributionCount;

      for (let i = 0; i < distributionCount; i++) {
        const pointDegrees = randomPointDegrees[i]; // (fullCircleEach * i) + rotationOffset;
        const angle = pointDegrees * (Math.PI / 180);

        const x = centerX + radius * Math.cos(angle); // Calculate x coordinate
        const y = centerY + radius * Math.sin(angle); // Calculate y coordinate

        points.push({x: x, y: y});
      }

      return points;
    },
  }
}
</script>

<style lang="scss">
.star-point {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 500ms ease-in-out;
  z-index: 10000000000000000000000;
}

.main-star {
  background: url("@/assets/star-points/big-star.png");
  width: 744px;
  height: 745px;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 500ms ease-in-out;

  .main-star-icon {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.side-star {
  position: absolute;
  transition: 500ms left, 500ms top, 500ms opacity;
  transition-timing-function: ease-in-out;
}

@keyframes sideStarAppear {
  0% {
    transform: scale(0.7) rotate(-120deg);
    opacity: 0;
  }
  20% {
    transform: scale(1.2) rotate(20deg);
    opacity: 1;
  }
  40% {
    transform: scale(1.1) rotate(90deg);
  }
  60% {
    transform: scale(1.0) rotate(60deg);
  }
  80% {
    transform: scale(1.05) rotate(10deg);
  }
  100% {
    transform: scale(1.0) rotate(0deg);
    opacity: 1;
  }
}

.star-point label {color: #FFF;
  text-shadow: 0px 5.127px 4.229px rgba(3, 33, 65, 0.76);
  font-size: 80pt;
  font-weight: 700;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
