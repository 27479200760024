export default {
  'beforePrioritization': {
    'identifier': 'beforePrioritization',
    'text': 'transitionScreen.beforePrioritization',
    'subText': null,
    'duration': 4000,
  },
  'beforeEpiReport': {
    'identifier': 'beforeEpiReport',
    'text': 'transitionScreen.beforeEpiReport',
    'subText': null,
    'duration': 4000,
  },
  'beforeEndDecision': {
    'identifier': 'beforeEndDecision',
    'text': 'transitionScreen.beforeEndDecision',
    'subText': 'transitionScreen.beforeEndDecision',
    'duration': 8000,
  },
  'beforeEndResult': {
    'identifier': 'beforeEndResult',
    'text': 'transitionScreen.beforeEndResult',
    'subText': 'transitionScreen.beforeEndResult',
    'duration': 4000,
  },
  'beforeMonthStart': {
    'identifier': 'beforeMonthStart',
    'text': 'transitionScreen.beforeMonthStart',
    'subText': null,
    'duration': 4000,
  },
  'beforeStarMinigame': {
    'identifier': 'beforeStarMinigame',
    'text': 'transitionScreen.beforeStarMinigame',
    'subText': null,
    'duration': 4000,
  },
};