<template>
  <div class="team-balance">
    <div class="team-balance-inner">
      <h1>{{ $t('lobby.teamBalanceHeader') }}</h1>
      <div class="container-fluid">
        <div class=" d-flex justify-content-center flex-wrap align-items-stretch gap-3">
          <div class="team-balance-aoe-container" v-for="areaOfExpertise in areasOfExpertise">
            <team-balance-aoe :label="areaOfExpertise.name" :value="getAoeValue(areaOfExpertise.identifier)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TeamBalanceAoe from '@/components/Lobby/TeamBalanceAoe';
import { mapState } from 'vuex'

export default {
  name: 'TeamBalance',
  components: {
    TeamBalanceAoe,
  },
  props: {
    aoeValues: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapState(['areasOfExpertise']),
  },
  methods: {
    getAoeValue(areaOfExpertiseIdentifier) {
      const keys = Object.keys(this.aoeValues);

      if (!keys.includes(areaOfExpertiseIdentifier)) {
        return 0;
      }

      return Math.max(0, Math.min(5, this.aoeValues[areaOfExpertiseIdentifier]));
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 25px 0 25px;
  font-weight: 700;
}

.team-balance {
  width: 100%;
  max-height: 100%;
  height: 650px;
  //min-height: 320px;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  padding: 20px;
}

.team-balance-inner {
  overflow-y: auto;
  height: 100%;
}

.team-balance-aoe-container {
  width: 145px;
}
</style>
