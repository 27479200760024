<template>
  <div class="bling-layer bling-layer-top" v-if="isMounted">
    <div class="bling-layer-component bling-layer-component-clouds" v-if="enableClouds">
      <div class="bling-item bling-item-cloud bling-item-cloud-1"
           :ref="(el) => registerBlingItemRef(el, 'cloud', 'cloud1')" :style="dynamicStyles.cloud1"/>
      <div class="bling-item bling-item-cloud bling-item-cloud-2"
           :ref="(el) => registerBlingItemRef(el, 'cloud', 'cloud2')" :style="dynamicStyles.cloud2"/>
      <div class="bling-item bling-item-cloud bling-item-cloud-3"
           :ref="(el) => registerBlingItemRef(el, 'cloud', 'cloud3')" :style="dynamicStyles.cloud3"/>
      <div class="bling-item bling-item-cloud bling-item-cloud-4"
           :ref="(el) => registerBlingItemRef(el, 'cloud', 'cloud4')" :style="dynamicStyles.cloud4"/>
      <div class="bling-item bling-item-cloud bling-item-cloud-5"
           :ref="(el) => registerBlingItemRef(el, 'cloud', 'cloud5')" :style="dynamicStyles.cloud5"/>
    </div>
    <div class="bling-layer-component bling-layer-component-plane" v-if="enablePlane">
      <div class="bling-item bling-item-plane" :ref="(el) => registerBlingItemRef(el, 'plane', 'plane')"
           :style="dynamicStyles.plane"/>
    </div>
    <!--<div class="bling-layer-component bling-layer-component-dvd" v-if="enableDvd">
      <div class="bling-item bling-item-dvd" :ref="(el) => registerBlingItemRef(el, 'dvd', 'dvd')"
           :style="dynamicStyles.dvd"/>
    </div>-->
  </div>
</template>

<script>
import BlingLayer from '@/components/bling/BlingLayer.vue'
import { removeArrayItemByValue } from '@/utils/arrayUtil'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'BlingLayerTop',
  extends: BlingLayer,
  props: {
    enableClouds: {
      type: Boolean,
      default: true,
      required: false,
    },
    enablePlane: {
      type: Boolean,
      default: true,
      required: false,
    },
    enableDvd: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data () {
    return {
      transitions: {
        cloud1: this.getBlankTransition(),
        cloud2: this.getBlankTransition(),
        cloud3: this.getBlankTransition(),
        cloud4: this.getBlankTransition(),
        cloud5: this.getBlankTransition(),
        plane: this.getBlankTransition(),
        dvd: this.getBlankTransition(),
      },
      dynamicStyles: {
        cloud1: {},
        cloud2: {},
        cloud3: {},
        cloud4: {},
        cloud5: {},
        plane: {},
        dvd: {},
      },
      dynamicRefs: [],
      dvd: {
        x: 0,
        y: 0,
        speedX: 0,
        speedY: 0,
      },

      dvdDimensions: {
        width: 560,
        height: 237,
        width2: 560 / 2,
        height2: 237 / 2,
      },

      containerDimensions: {
        width: 1920,
        height: 1080,
        width2: 1920 / 2,
        height2: 1080 / 2,
      },

      isMounted: false,
    }
  },
  mounted () {
    console.log('this.transitions', this.transitions)

    this.isMounted = true
  },
  methods: {
    registerBlingItemRef (el, group, itemKey) {
      if (!el || el.__vueRegistered) {
        return
      }

      el.__vueRegistered = true

      /*console.log('registerBlingItemRef', {
        el,
        group,
        itemKey
      })*/

      el.addEventListener('transitionend', () => {
        if (!this.animatingItemKeys.includes(itemKey)) {
          return // ?????
        }

        this.transitions[itemKey] = this.getTransitionFromGroup(group)

        //console.log('initTransitions', itemKey, this.transitions[itemKey])

        //console.log('TRANSITION END', group)

        removeArrayItemByValue(this.animatingItemKeys, itemKey)

        this.startItemTransition(itemKey, group)
      })

      this.transitions[itemKey] = this.getTransitionFromGroup(group)

      //console.log('initTransitions', itemKey, this.transitions[itemKey])

      if (itemKey === 'dvd') {
        const destinationX = Math.round(randomBetween(0, this.containerDimensions.width - this.dvdDimensions.width))
        const destinationY = Math.round(randomBetween(0, this.containerDimensions.height - this.dvdDimensions.height))

        this.transitions.dvd.source.left = destinationX + 'px';
        this.transitions.dvd.source.top = destinationY + 'px';
      }

      this.startItemTransition(itemKey, group)
    },
    getTransitionFromGroup (group) {
      if (group === 'cloud') {
        return this.getCloudTransition()
      } else if (group === 'plane') {
        return this.getPlaneTransition()
      } else if (group === 'dvd') {
        return this.getDvdTransition()
      }

      return this.getBlankTransition()
    },
    getBlankTransition () {
      return {
        source: {
          top: '-1000px',
          left: '-1000px',
        },
        destination: {
          top: '-1000px',
          left: '-1000px',
        },
        transition: '0ms linear',
        flipX: false,
      }
    },
    getPlaneTransition () {
      const planeTransition = {
        source: {
          top: '1300px',
          //left: '-200px',
        },
        destination: {
          top: '-200px',
          //left: '1920px',
        },
        transition: this.randomTransitionDuration(12000, 26000),
        flipX: Math.random() < .5
      }

      if (planeTransition.flipX) {
        // From bottom right to top left
        planeTransition.source.left = (1920 + 200) + 'px'
        planeTransition.destination.left = '-200px'
      } else {
        // From bottom left to top right
        planeTransition.source.left = '-200px'
        planeTransition.destination.left = '1920px'
      }

      return planeTransition
    },
    getCloudTransition () {
      let cloudLeftSource, cloudLeftDestination

      const left = Math.round((0 - 500)) + 'px'
      const right = Math.round((1920 + 500)) + 'px'

      const cloudTop = Math.round((Math.random() * (1080 - 300))) + 'px'

      if (Math.random() > .5) {
        // Left to right
        cloudLeftSource = left
        cloudLeftDestination = right
      } else {
        // Right to left
        cloudLeftSource = right
        cloudLeftDestination = left
      }

      return {
        source: {
          top: cloudTop,
          left: cloudLeftSource,
        },
        destination: {
          top: cloudTop,
          left: cloudLeftDestination,
        },
        transition: this.randomTransitionDuration(20000, 60000),
        flipX: Math.random() < .5,
      }
    },
    calculateDistance(x1, y1, x2, y2) {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    },
    getDvdTransition () {
      const sourceX = parseInt(this.transitions.dvd.destination.left, 10);
      const sourceY = parseInt(this.transitions.dvd.destination.top, 10);

      const destinationX = Math.round(randomBetween(0, this.containerDimensions.width - this.dvdDimensions.width))
      const destinationY = Math.round(randomBetween(0, this.containerDimensions.height - this.dvdDimensions.height))

      const durationMs = Math.round(this.calculateDistance(sourceX, sourceY, destinationX, destinationY) * 3);

      return {
        source: {
          left: sourceX + 'px',
          top: sourceY + 'px',
        },
        destination: {
          left: destinationX + 'px',
          top: destinationY + 'px',
        },
        transition: `${durationMs}ms ease-in-out`,
        flipX: false,
        timeoutInitialWait: 0,
        timeoutAfter: 0,
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";

.bling-layer-component {
  &-dvd {
    .bling-item-dvd {
      background-image: url("@/assets/lobby/logo_small.png");
      width: 560px;
      height: 237px;
      opacity: .3;
    }
  }

  &-plane {
    .bling-item-plane {
      background-image: url("@/assets/bling/planeTest1.gif");
      width: 339px;
      height: 237px;
    }
  }

  &-clouds {
    .bling-item-cloud {
      width: 500px;
      height: 300px;

      &-1 {
        background-image: url("@/assets/bling/cloud1.png");
      }

      &-2 {
        background-image: url("@/assets/bling/cloud2.png");
      }

      &-3 {
        background-image: url("@/assets/bling/cloud3.png");
      }

      &-4 {
        background-image: url("@/assets/bling/cloud4.png");
      }

      &-5 {
        background-image: url("@/assets/bling/cloud5.png");
      }
    }
  }
}
</style>
