<template>
  <div>
    <game-ui-dev />
  </div>
  <dev-settings-panel >
    <bootstrap-select :options="tutorialGroupOptions" v-model="currentTutorialGroupIdentifier" placeholder-text="Select a tutorial group" />
    <button class="btn btn-success start" @click="start" v-if="currentTutorialGroupIdentifier">START</button>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import TutorialBox from '@/components/TutorialBox/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import TutorialHighlightOverlay from '@/plugins/TutorialHighlightOverlay/TutorialHighlightOverlay.vue'
import Tutorials, { getSortedTutorialGroupIdentifiers } from '@/lib/tutorials'
import PlayerAvatarCircleLive from '@/components/player-ui/PlayerAvatarCircle/PlayerAvatarCircleLive.vue'
import TopBarLive from '@/components/player-ui/TopBar/TopBarLive.vue'
import TopBar from '@/components/player-ui/TopBar/index.vue'
import RightPlayerListBar from '@/components/player-ui/RightPlayerListBar/RightPlayerListBar.vue'
import { getSortedTutorialsByGroupIdentifier } from '@/lib/tutorials'
import GameUiDev from '@/components/dev/GameUiDev.vue'
import {
  HIGHLIGHT_TYPE_ELEMENT,
  POPUP_ANCHOR_AUTO,
  POPUP_ANCHOR_BOTTOM, POPUP_ANCHOR_LEFT,
  POPUP_ANCHOR_TOP, POPUP_ANCHOR_X_LEFT, POPUP_ANCHOR_X_MIDDLE, POPUP_ANCHOR_Y_TOP
} from '@/plugins/TutorialHighlightOverlay/enums'
import { TutorialUtil } from '@/utils/tutorialUtil'

export default {
  name: 'TutorialBoxDev',
  components: {
    GameUiDev,
    RightPlayerListBar,
    TopBar,
    TopBarLive,
    PlayerAvatarCircleLive,
    TutorialHighlightOverlay,
    BootstrapSelect,
    DevSettingsPanel,
    LobbyPopupTemplate,
    AppButton,
    TutorialBox,
  },
  extends: DevComponentContainer,

  data () {
    const tutorialGroupOptions = [];

    const tutorialGroupIdentifiers = getSortedTutorialGroupIdentifiers();

    for (const tutorialGroupIdentifier of tutorialGroupIdentifiers) {
      tutorialGroupOptions.push({
        label: tutorialGroupIdentifier,
        value: tutorialGroupIdentifier,
      })
    }

    return {
      currentTutorialGroupIdentifier: '',
      tutorialGroupOptions: tutorialGroupOptions,
    }
  },
  methods: {
    async start() {
      await TutorialUtil.launchTutorialGroupByIdentifier(this.currentTutorialGroupIdentifier);
    }
  }
}
</script>

<style lang="scss" scoped>
#circle1 {
  position: absolute;
  top: 20%;
  left: 60%;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #aa0000;
}
</style>
