import { hexToRgba } from '@/utils/colorUtil'

const indicatorColors = {
  prevent: {
    primary: 0x0066FF,
    secondary: 0x004267,
  },
  detect: {
    primary: 0x753552,
    secondary: 0x670000,
  },
  respond: {
    primary: 0x299F55,
    secondary: 0x00671D,
  },
  poe: {
    primary: 0xBA9023,
    secondary: 0xBA9023, // ?
  },
  chemical: {
    primary: 0x000000,
    secondary: 0x000000, // ?
  },
  radiation: {
    primary: 0x004655,
    secondary: 0x004655, // ?
  },
}

const indicatorColorIdentifiers = Object.keys(indicatorColors);

function getIndicatorColors(identifier) {
  if (!indicatorColorIdentifiers.includes(identifier)) {
    throw new Error(`Identifier ${identifier} not found`)
  }

  return indicatorColors[identifier]
}

function getIndicatorPrimaryColor(identifier) {
  return getIndicatorColors(identifier).primary;
}

function getIndicatorSecondaryColor(identifier) {
  return getIndicatorColors(identifier).secondary;
}

export function getDashboardCategoryColor(identifier) {
  return hexToRgba(getIndicatorPrimaryColor(identifier), .6);
}

export function getDashboardGroupColor(identifier) {
  return hexToRgba(getIndicatorPrimaryColor(identifier), .6);
}

export function getDashboardIndicatorColor(identifier) {
  return hexToRgba(getIndicatorSecondaryColor(identifier), .6);
}
