<template>
  <div class="debug-grid" :class="{'with-pointer-events': pointerEvents}">
    <div v-for="x in xLines" :key="'x-' + x" class="grid-line vertical" :style="{ left: `${x}%` }">
      <span class="label-start">{{ x }}%</span>
      <span class="label-end">{{ x }}%</span>
    </div>
    <div v-for="y in yLines" :key="'y-' + y" class="grid-line horizontal" :style="{ top: `${y}%` }">
      <span class="label-start">{{ y }}%</span>
      <span class="label-end">{{ y }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebugGrid',
  props: {
    gap: {
      type: Number,
      default: 10
    },
    pointerEvents: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    start() {
      return this.gap;
    },
    end() {
      return 100 - this.gap;
    },
    xLines() {
      const lines = [];

      for (let i = this.start; i <= this.end; i += this.gap) {
        lines.push(i);
      }

      return lines;
    },
    yLines() {
      const lines = [];

      for (let i = this.start; i <= this.end; i += this.gap) {
        lines.push(i);
      }

      return lines;
    }
  }
}
</script>

<style scoped>
.debug-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000000;

  pointer-events: none;

  &.with-pointer-events {
    pointer-events: auto !important;

    .grid-line {
      &.vertical {
        width: 5px !important;
      }

      &.horizontal {
        height: 5px !important;
      }
    }
  }
}

.grid-line {
  position: absolute;
  background-color: rgba(255, 255, 255, .6);

  &.vertical, &.horizontal {
    /* Won't work if pointer events are disabled */
    &:hover {
      .label-start, .label-end {
        background: cornflowerblue;
        color: white;
      }
    }
  }

  &.vertical {
    width: 2px;
    height: 100%;

    .label-start, .label-end {
      transform: translateX(-50%);
    }

    .label-start {
      top: 0;
    }

    .label-end {
      bottom: 0;
    }
  }

  &.horizontal {
    height: 2px;
    width: 100%;

    .label-start, .label-end {
      transform: translateY(-50%);
    }

    .label-start {
      left: 0;
    }

    .label-end {
      right: 0;
    }
  }

  .label-start, .label-end {
    position: absolute;
    background-color: white;
    padding: 2px 4px;
    font-size: 15px;
    font-weight: bold;
    font-family: Consolas, monaco, monospace;
    border: 1px solid #ccc;
  }
}
</style>
