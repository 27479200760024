export class VoiceParticipant {
  uid; // The UID of the participant

  videoTag; // The video HTML tag

  stream; // The videoTag.src

  audioContext; // The created audio context for the participant

  frequencyAverage;

  volume = 100;

  constructor (uid, { videoTag, stream, audioContext, frequencyAverage, volume = 100 }) {
    if (!uid) {
      throw new Error(`Could not find VoiceParticipant by empty uid`);
    }

    this.uid = uid;

    this.videoTag = videoTag;
    this.stream = stream;
    this.audioContext = audioContext;
    this.frequencyAverage = frequencyAverage;
    this.volume = volume;
  }
}
