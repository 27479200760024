<template>
  <div class="logo-container"
       :class="logoContainerClasses">
    <div class="logo-main-container" :class="instantTransition ? 'transition-instant' : ''">
      <img src="@/assets/teaser/logo.png"/>
    </div>
    <div class="logo-sub-container" :class="instantTransition ? 'transition-instant' : ''">
      {{ subtitle }}
    </div>
    <div class="logo-fate-container" :class="instantTransition ? 'transition-instant' : ''">
      {{ action }}
    </div>

    <div class="begin-button-container" :class="instantTransition ? 'transition-instant' : ''">
      <app-button color="green" width="xl" height="xl" text-size="xl" @click="onClickButton">{{buttonText }}</app-button>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/common/buttons/AppButton.vue";

export default {
  name: 'TeaserLogo',
  components: {
    AppButton,
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },

    instantTransition: {
      type: Boolean,
      default: true,
    },

    logoContainerShow: {
      type: Boolean,
      default: true,
    },
    logoContainerFate: {
      type: Boolean,
      default: true,
    },
    logoContainerButton: {
      type: Boolean,
      default: true,
    },
    fadeToAlmostBlack: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'button-click',
  ],
  methods: {
    onClickButton() {
      this.$emit('button-click');
    },
  },
  computed: {
    logoContainerClasses() {
      return [
        'logo-container-show-' + (this.logoContainerShow ? 'on' : 'off'),
        'logo-container-fate-' + (this.logoContainerFate ? 'on' : 'off'),
        'logo-container-button-' + (this.logoContainerButton ? 'on' : 'off'),
        'logo-container-hide-' + (this.fadeToAlmostBlack ? 'on' : 'off'),
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  transition: all 800ms ease-out;
  opacity: 1;

  color: white;
  font-size: 16pt;
  text-align: left;

  &.logo-container-hide {
    opacity: 0;
    transform: translateY(-50px);
  }

  .logo-main-container, .logo-sub-container, .logo-fate-container, .begin-button-container {
    position: absolute;
    transition: all 800ms ease-out;
  }

  .logo-main-container, .logo-sub-container {
    transform: scale(1.5) translateX(-50%);
  }

  .logo-sub-container, .logo-fate-container {
    text-transform: uppercase;
  }
  .logo-main-container {
    top: -500px;
    left: 50%;
    transform: scale(1.5) translateX(-300px);
  }

  .logo-sub-container {
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 1), 5px 5px 10px rgba(0, 0, 0, 1), 5px 5px 10px rgba(0, 0, 0, 1), 5px 5px 10px rgba(0, 0, 0, 1);
    font-size: 32pt;
    bottom: -400px;
    left: 50%;
    width: 1200px;
    transform: scale(1.5) translateX(-420px);
    text-align: center;
  }

  &.logo-container-show-on {
    .logo-main-container {
      top: 200px;
      transform: scale(1) translateX(-50%);
    }

    .logo-sub-container {
      bottom: 400px;
      transform: scale(1) translateX(-50%);
    }
  }

  &.logo-container-fate-on {
    .logo-fate-container {
      opacity: 1;
    }
  }

  &.logo-container-button-on {
    .begin-button-container {
      opacity: 1;
    }
  }

  .logo-fate-container {
    text-shadow: 5px 5px 15px rgba(0, 0, 0, 1);
    font-size: 40pt;
    background: url("@/assets/teaser/fate-background.png") no-repeat;
    background-size: contain;
    width: 1473px;
    height: 151px;
    line-height: 151px;
    text-align: center;
    font-weight: 700;
    bottom: 250px;
    left: 50%;
    opacity: 0;
    transform: scale(1) translateX(-50%);
  }

  .begin-button-container {
    position: absolute;
    bottom: 170px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>
