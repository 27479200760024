<template>
  <strategic-meeting-intro @continue="onContinue" :can-interact="canInteract" :waiting-on-player-codes="waitingOnPlayerCodes" :players="connectedPlayers" :show-waiting-on-players="showWaitingOnPlayers" />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import { mapGetters } from 'vuex';
import { objectToArray } from '@/utils/arrayUtil';

export default {
  name: 'StrategicMeetingIntroLive',
  components: {
    StrategicMeetingIntro,
  },

  props: {
    canInteract: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['continue'],
  computed: {
    ...mapGetters(['room', 'players']),
    showWaitingOnPlayers() {
      return this.room.meetingNumber === 1;
    },
    waitingOnPlayerCodes() {
      if (!this.showWaitingOnPlayers) {
        return []; // Not waiting on anyone, since we're not even showing the error
      }

      const waitingOnPlayerCodes = [];

      const playerCodes = Object.keys(this.connectedPlayers);

      for (const playerCode of playerCodes) {
        const player = this.connectedPlayers[playerCode];

        const closedTutorialIdentifiers = objectToArray(player.closedTutorialIdentifiers);

        if (!closedTutorialIdentifiers.includes('mainUi.toolbox')) {
          waitingOnPlayerCodes.push(player.playerCode);
        }
      }

      return waitingOnPlayerCodes;
    },
    players() {
      return this.room && this.room.players ? this.room.players : {};
    },
    connectedPlayers() {
      const players = this.players;
      const playerCodes = Object.keys(players);

      const connectedPlayers = {};

      for (const playerCode of playerCodes) {
        const player = players[playerCode];

        if (player.sessionId) {
          connectedPlayers[playerCode] = player;
        }
      }

      return connectedPlayers;
    }
  },
  methods: {
    onContinue() {
      this.$emit('continue')
    }
  },
}
</script>

<style lang="scss" scoped>
.strategic-meeting-popup {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
