<template>
  <hazard-popup-intro
    class="hazard-popup-intro"
    :investigation-cost="investigationCost"
    :title="title"
    :icon="icon"
    :hazard-text="hazardText"
    :speaker="speaker"
    :type="type"

    @open:flagManagement="onOpenFlagManagement"
    @click:cancel="onClickCancel"
    @click:investigate="onClickInvestigate"
  />

  <dev-settings-panel>
    <div class="row">
      <button class="btn" :class="textType === 'short' ? 'btn-success' : 'btn-dark'" @click="textType = 'short'">Short</button>
      <button class="btn" :class="textType === 'medium' ? 'btn-success' : 'btn-dark'" @click="textType = 'medium'">Medium</button>
      <button class="btn" :class="textType === 'long' ? 'btn-success' : 'btn-dark'" @click="textType = 'long'">Long</button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'

export default {
  name: 'HazardPopupIntroDev',
  components: {
    DevSettingsPanel,
    HazardPopupIntro,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['cancel', 'investigate', 'openFlagManagement'],

      investigationCost: 1,

      title: 'Measles',
      icon: 'infectious',
      type: 'hazard',

      textType: 'short',
      speaker: null,
    }
  },
  computed: {
    hazardText() {
      if (this.textType === 'short') {
        return 'Ministry of Health and Sports Spokes Person: COVID-19 cases are still reported in GL.';
      } else if (this.textType === 'medium') {
        return 'Ministry of Health and Sports Spokes Person: COVID-19 cases are still reported in GL, while the Ministry of health and sports is taking actions inline with the WHO latest recomendations.';
      } else if (this.textType === 'long') {
        return 'Ministry of Health and Sports Spokes Person: COVID-19 cases are still reported in GL, while the Ministry of health and sports is taking actions inline with the WHO latest recomendations. There should also be more space for more text here if we truly need it. Some of them have an EXCEPTIONAL long text that needs to be able to be viewed.';
      }

      return `Unknown text type ${this.textType}`;
    },
  },
  methods: {
    onClickCancel() {
      this.triggerEvent('cancel')
    },
    onClickInvestigate() {
      this.triggerEvent('investigate')
    },
    onOpenFlagManagement() {
      this.triggerEvent('openFlagManagement');
    },
  },
}
</script>

<style lang="scss" scoped>
.hazard-popup-intro {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
