<template>
  <div class="dashboard-action">
    <div class="dashboard-action-header row">
      <div class="col text-nowrap">
        <label>{{ actionName }}</label>
      </div>
      <div class="col text-nowrap">
        <label>{{ $t('dashboard.actionCost') }}: {{ cost }}</label>
      </div>
      <div class="col text-nowrap">
        <label>{{ $t('dashboard.actionIndicatorsImpacted') }}: {{ indicatorImpactCount }}</label>
      </div>
      <div class="col text-nowrap">
        <label>{{ $t('dashboard.actionMonth') }}: {{ month }}</label>
      </div>
    </div>
    <div class="dashboard-action-content row pt-4 pb-4">
      <div class="col">
        <div class="description-container">
          <p>
            <strong>{{ $t('dashboard.actionDescription') }}:</strong><br/><br/>
            {{ actionDescription }}
          </p>
          <div class="in-response-to-container" v-if="inResponseTo">
            {{ $t('dashboard.actionInResponseTo') }}: {{ inResponseTo }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="indicator-category-impacted row mb-2" v-for="indicatorCategoryImpacted of indicatorCategoriesImpactedWithJeeInfo">
          <div
            class="indicator-category-name"
            :class="'indicator-category-impacted-' + indicatorCategoryImpacted.category.identifier"
            @click="toggleIndicatorCategory(indicatorCategoryImpacted.category.identifier)"
          >
            {{ indicatorCategoryImpacted.category.name }}

            <div class="chevron-container">
              <chevron :model-value="indicatorCategoryIdentifiersOpen.includes(indicatorCategoryImpacted.category.identifier)" />
            </div>
          </div>

          <accordion :open="indicatorCategoryIdentifiersOpen.includes(indicatorCategoryImpacted.category.identifier)">
            <div class="indicators-impacted-container mt-2">
              <div class="indicator-impacted row mb-2" v-for="indicatorImpacted in indicatorCategoryImpacted.indicatorsImpacted">
                <div class="indicator-description d-flex"
                     :class="['indicator-impacted-' + indicatorImpacted.category.identifier, indicatorImpacted.jee.identifier === currentIndicatorIdentifier ? 'indicator-impacted-active' : '']">
                  <div class="indicator-background flex-fill">
                    <div class="row">
                      <div class="col text-start">
                        {{ indicatorImpacted.jee.name }}
                      </div>
                      <div class="col text-end col-auto">
                        {{ indicatorImpacted.jee.impact > 0 ? '+' + indicatorImpacted.jee.impact : (indicatorImpacted.jee.impact === 0 ? '' : indicatorImpacted.jee.impact) }}
                      </div>
                    </div>
                  </div>
                  <div class="ms-2 indicator-view-background indicator-background">
                    <div
                      class="indicator-view"
                      :class="indicatorImpacted.jee.identifier === currentIndicatorIdentifier ? 'indicator-view-active' : ''"
                      @click="setCurrentIndicator(indicatorImpacted)"
                    >
                      <no-drag-image src="@/assets/icons/eye.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </accordion>
        </div>
      </div>
      <div class="col">
        <div v-if="currentIndicator" class="indicator-box" :class="'indicator-box-' + currentIndicator.category.identifier">
          <div class="header row">
            <div class="col text-start">
              {{ currentIndicator.jee.name }}
            </div>
            <div class="col text-end col-auto">
              {{ currentIndicator.jee.impact > 0 ? '+' + currentIndicator.jee.impact : (currentIndicator.jee.impact === 0 ? '' : currentIndicator.jee.impact) }}
            </div>
          </div>
          <div class="row content">
            <div class="col pt-3">
              {{ currentIndicator.jee.description }}
            </div>
          </div>
          <div class="row capacity-level-container">
            <div class="col">
              <label>{{ $t('dashboard.capacityLevel') }}:</label>
              <capacity-level :capacity-level="currentIndicator.jee.capacityLevel" :title="currentIndicator.jee.currentScore" size="sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboardIndicatorColor } from '@/components/dashboard/CategoryColorUtils'
import NoDragImage from '@/components/common/NoDragImage.vue'
import CapacityLevel from '@/components/dashboard/CapacityLevel.vue'
import { getCapacityLevelFromJeeScore } from '@/components/dashboard/CapacityLevelUtil'
import { mapState } from 'vuex'
import Accordion from '@/components/common/Accordion.vue'
import Chevron from '@/components/common/Chevron.vue'

export default {
  name: 'DashboardAction',
  components: { Chevron, Accordion, CapacityLevel, NoDragImage },
  props: {
    actionName: {
      type: String,
      required: true,
    },
    cost: {
      type: Number,
      required: true,
    },
    indicatorsImpacted: {
      type: Object,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    actionDescription: {
      type: String,
      required: true,
    },
    inResponseTo: {
      type: String,
      required: false,
      default: null,
    },
    jeeCategoriesStatic: {
      type: Array,
      required: false,
      default: null,
    },
    jeeScoresStatic: {
      type: Array,
      required: false,
      default: null,
    }
  },
  data () {
    return {
      currentIndicator: null,
      indicatorCategoryIdentifiersOpen: [],

      indicatorCategoriesImpactedWithJeeInfo: [],
      indicatorImpactCount: 0,
    }
  },
  watch: {
    indicatorsImpacted: {
      handler() {
        this.recalculateIndicatorCategories();
      },
      deep: true,
    },
    jeeCategories: {
      handler() {
        this.recalculateIndicatorCategories();
      },
      deep: true,
    },
    jeeScores: {
      handler() {
        this.recalculateIndicatorCategories();
      },
      deep: true,
    },
    jeeScoresStatic: {
      handler() {
        this.recalculateIndicatorCategories();
      },
      deep: true,
    },
  },
  mounted() {
    this.recalculateIndicatorCategories();
  },
  computed: {
    ...mapState(['jeeCategories', 'room']),
    jeeCategoriesComputed() {
      if (this.jeeCategoriesStatic) {
        return this.jeeCategoriesStatic;
      }

      return this.jeeCategories;
    },
    jeeScoresComputed() {
      if (this.jeeScoresStatic) {
        return this.jeeScoresStatic;
      }

      return this.jeeScores || {};
    },
    currentIndicatorIdentifier() {
      if (!this.currentIndicator) {
        return null;
      }

      return this.currentIndicator.jee.identifier;
    },
  },

  methods: {
    recalculateIndicatorCategories() {
      const indicatorCategoriesImpacted = [];

      const impactKeys = Object.keys(this.indicatorsImpacted);

      let indicatorImpactCount = 0;

      const jeeScoreKeys = this.jeeScoresComputed ? Object.keys(this.jeeScoresComputed) : [];

      for (const jeeCategory of this.jeeCategoriesComputed) {
        const indicatorsImpacted = [];

        for (const jeeGroup of jeeCategory.groups) {
          for (const jee of jeeGroup.jees) {
            if (impactKeys.includes(jee.identifier)) {
              const currentScore = jeeScoreKeys.includes(jee.identifier) ? this.jeeScoresComputed[jee.identifier] : 0;

              indicatorsImpacted.push({
                category: {
                  identifier: jeeCategory.identifier,
                  name: jeeCategory.name,
                },
                group: {
                  identifier: jeeGroup.identifier,
                  name: jeeGroup.name,
                  description: jeeGroup.description,
                },
                jee: {
                  identifier: jee.identifier,
                  name: jee.name,
                  description: jee.description,
                  impact: this.indicatorsImpacted[jee.identifier] || 0,
                  currentScore: currentScore,
                  capacityLevel: Math.max(1, Math.min(5, Math.floor(currentScore / 20) + 1)),
                },
              })

              indicatorImpactCount++;
            }
          }
        }

        if (indicatorsImpacted.length === 0) {
          continue;
        }

        indicatorCategoriesImpacted.push({
          category: {
            identifier: jeeCategory.identifier,
            name: jeeCategory.name,
          },
          indicatorsImpacted: indicatorsImpacted,
        });
      }

      console.log('indicatorCategoriesImpacted', indicatorCategoriesImpacted);

      this.indicatorCategoriesImpactedWithJeeInfo = indicatorCategoriesImpacted;
      this.indicatorImpactCount = indicatorImpactCount;
    },
    toggleIndicatorCategory(indicatorCategoryIdentifier) {
      const searchIndex = this.indicatorCategoryIdentifiersOpen.indexOf(indicatorCategoryIdentifier);

      if (searchIndex >= 0) {
        this.indicatorCategoryIdentifiersOpen.splice(searchIndex, 1);
      } else {
        this.indicatorCategoryIdentifiersOpen.push(indicatorCategoryIdentifier);
      }
    },
    getJeeValue(jeeIdentifier) {
      if (!this.room) {
        return null;
      }

      if (!this.room.jeeScores) {
        return null;
      }

      if (!this.room.jeeScores[jeeIdentifier]) {
        return null;
      }

      return this.room.jeeScores[jeeIdentifier];
    },
    getCapacityLevelFromJeeScore(jeeScore) {
      return getCapacityLevelFromJeeScore(jeeScore);
    },
    setCurrentIndicator (indicator) {
      if (this.currentIndicatorIdentifier !== indicator.jee.identifier) {
        this.currentIndicator = indicator; // Toggle on
      } else {
        this.currentIndicator = null; // Toggle off
      }
    },
  }
}
</script>

<style lang="scss">
.dashboard-action-header {
  line-height: 50px;
  height: 50px;
  color: #ffffff;
  background: rgba(#000000, .6);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  padding: 5px 10px;

  font-weight: 700;
  font-size: 24px;
}

.dashboard-action-content {
  color: white;
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #001727;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
}

.description-container {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: inset 4px 4px 3px rgba(255, 255, 255, 0.4), inset -5px -5px 4px rgba(0, 0, 0, 0.4);
  padding: 15px;
  text-align: left;

  .in-response-to-container {
    text-transform: uppercase;
    font-weight: 700;
  }
}

.indicator-background {
  padding: 5px;
  height: 30px;
}

.indicator-view-background {
  width: 40px;
  display: inline-block;
  padding: 0;
}

.indicator-background {
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.indicator-description {
}

.indicator-view {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 2px 0;
  text-align: center;

  img {
    height: 15px;
    width: 25px;
  }
}

.indicator-impacted-active {
  .indicator-view-background {
    transform: scale(120%);
  }
}

.indicator-category-impacted {
  cursor: pointer;
  text-align: left;
  position: relative;
}

@mixin indicator-background($indicatorCategoryIdentifier, $primaryColor, $secondaryColor) {
  .indicator-category-impacted-#{$indicatorCategoryIdentifier} {
    background: rgba($primaryColor, .6);

    &.indicator-view-background:hover {
      background: lighten(rgba($primaryColor, .6), 25%);
    }
  }

  .indicator-impacted-#{$indicatorCategoryIdentifier} {
    .indicator-background {
      background: rgba($primaryColor, .6);

      &.indicator-view-background:hover {
        background: lighten(rgba($primaryColor, .6), 25%);
      }
    }

    &.indicator-impacted-active {
      .indicator-view-background {
        box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.6);
      }

      .indicator-background {
        background: lighten(rgba($primaryColor, .6), 25%);
      }
    }
  }

  .indicator-box-#{$indicatorCategoryIdentifier} {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), $primaryColor;
  }
}

.indicator-box {
  transition: 200ms cubic-bezier(0.175, 0.985, 0.32, 2.175);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.6), inset -5px -5px 4px rgba(0, 0, 0, 0.4), inset 4px 4px 3px rgba(255, 255, 255, 0.4);
  padding: 20px;

  font-weight: 700;
  font-size: 24px;
  color: white;

  .header {
    padding: 0 5px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .capacity-level-container {
    padding: 15px;
    text-align: left;

    label {
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}

.indicator-category-name {
  position: relative;
}

.chevron-container {
  transform: scale(.6);
  position: absolute;
  right: 5px;
  top: 0;
}

@include indicator-background('prevent', #0066FF, #004267);
@include indicator-background('detect', #753552, #670000);
@include indicator-background('respond', #299F55, #00671D);
@include indicator-background('poe', #BA9023, #BA9023);
@include indicator-background('chemical', #000000, #000000);
@include indicator-background('radiation', #004655, #004655);

</style>
