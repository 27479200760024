<template>
  <div class="tutorial-highlight-overlay-dev-container">
    <div class="row">
      <div id="circle" ref="circle"></div>
      <div id="rounded-square"></div>
      <div id="button-test">
        <button class="btn btn-success btn-lg" @click="onClickNormalButton">This is a normal button<br/>It can hover,
          click and everything
        </button>
      </div>

      <div class="circle-anchors">
        <div @click="openCircleAnchor('tl')" class="circle-anchor circle-anchor-top-left"></div>
        <div @click="openCircleAnchor('tr')" class="circle-anchor circle-anchor-top-right"></div>
        <div @click="openCircleAnchor('bl')"
             class="circle-anchor circle-anchor-bottom-left"></div>
        <div @click="openCircleAnchor('br')"
             class="circle-anchor circle-anchor-bottom-right"></div>
      </div>
      <p id="paragraph">This is a paragraph with a single <strong id="bold">bolded</strong> word!</p>
    </div>

    <dev-settings-panel>
      <div class="row mt-2" id="type-row">
        <div class="col-4">
          <label id="type-label">Quick Highlights</label>
        </div>
        <div class="col-8">
          <button class="btn btn-sm btn-success btn-highlight" v-for="highlight in highlights"
                  @click="setHighlightOptionsFromButton(highlight)">{{ highlight.label }}
          </button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Tutorial text</label>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" v-model="tutorialText"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Highlight</label>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" v-model="highlight"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Highlight Type</label>
        </div>
        <div class="col-8 radio-container">
          <label>{{ Enums.HIGHLIGHT_TYPE_SELECTOR }} <input type="radio" v-model="highlightType"
                                                            :value="Enums.HIGHLIGHT_TYPE_SELECTOR"/></label><br/>
          <label>{{ Enums.HIGHLIGHT_TYPE_ID }} <input type="radio" v-model="highlightType"
                                                      :value="Enums.HIGHLIGHT_TYPE_ID"/></label><br/>
          <label>{{ Enums.HIGHLIGHT_TYPE_ELEMENT }} <input type="radio" v-model="highlightType"
                                                           :value="Enums.HIGHLIGHT_TYPE_ELEMENT"/></label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Shape</label>
        </div>
        <div class="col-8 radio-container">
          <label>{{ Enums.SHAPE_SQUARE }} <input type="radio" v-model="shape" :value="Enums.SHAPE_SQUARE"/></label><br/>
          <label>{{ Enums.SHAPE_SLIGHTLY_ROUNDED }} <input type="radio" v-model="shape"
                                                           :value="Enums.SHAPE_SLIGHTLY_ROUNDED"/></label><br/>
          <label>{{ Enums.SHAPE_ROUNDED }} <input type="radio" v-model="shape"
                                                  :value="Enums.SHAPE_ROUNDED"/></label><br/>
          <label>{{ Enums.SHAPE_CIRCLE }} <input type="radio" v-model="shape" :value="Enums.SHAPE_CIRCLE"/></label>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Popup Anchor</label>
        </div>
        <div class="col-8">
          <div>
            <label>Popup anchor auto <input type="checkbox" v-model="popupAnchorAuto"/></label>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="!popupAnchorAuto">
        <div class="col-4">
          <label>Popup Anchor X</label>
        </div>
        <div class="col-8">
          <div>
            <bootstrap-select v-model="popupAnchorX" :options="popupAnchorOptionsX" placeholder-text="Popup Anchor X"/>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="!popupAnchorAuto">
        <div class="col-4">
          <label>Popup Anchor Y</label>
        </div>
        <div class="col-8">
          <div>
            <bootstrap-select v-model="popupAnchorY" :options="popupAnchorOptionsY" placeholder-text="Popup Anchor Y"/>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <label>Offset</label>
        </div>
        <div class="col-8">
          <label>{{ offset }}</label><br/>
          <input type="range" min="0" max="150" v-model.number="offset"/>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-dark" @click="onClickPop">POP</button>
          <button class="btn btn-dark ms-2" @click="onClickReset">RESET</button>
          <button class="btn btn-dark ms-2" @click="onClickFlow">FLOW</button>
          <button class="btn btn-dark ms-2" @click="onClickClone">CLONE TEST</button>
          <button class="btn btn-dark ms-2" @click="onClickAnchorTest">ANCHOR TEST</button>
        </div>
      </div>
    </dev-settings-panel>
  </div>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'

import * as Enums from '@/plugins/TutorialHighlightOverlay/enums'
import {
  POPUP_ANCHOR_AUTO,
  POPUP_ANCHOR_BOTTOM,
  POPUP_ANCHOR_LEFT,
  POPUP_ANCHOR_RIGHT,
  POPUP_ANCHOR_TOP
} from '@/plugins/TutorialHighlightOverlay/enums'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'

export default {
  name: 'TutorialHighlightOverlayDev ',
  computed: {
    Enums () {
      return Enums
    }
  },
  components: {
    BootstrapSelect,
    DevSettingsPanel

  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      tutorialText: 'This is some example tutorial text',
      offset: 10,
      shape: Enums.SHAPE_SQUARE, // Enums.SHAPE_CIRCLE
      highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
      highlight: null,

      popupAnchorAuto: true,
      popupAnchorX: Enums.POPUP_ANCHOR_X_MIDDLE,
      popupAnchorY: Enums.POPUP_ANCHOR_Y_MIDDLE,

      popupAnchorOptionsX: [
        {
          value: Enums.POPUP_ANCHOR_X_LEFT,
          label: 'POPUP_ANCHOR_X_LEFT'
        },
        {
          value: Enums.POPUP_ANCHOR_X_MIDDLE,
          label: 'POPUP_ANCHOR_X_MIDDLE'
        },
        {
          value: Enums.POPUP_ANCHOR_X_RIGHT,
          label: 'POPUP_ANCHOR_X_RIGHT'
        },
      ],
      popupAnchorOptionsY: [
        {
          value: Enums.POPUP_ANCHOR_Y_TOP,
          label: 'POPUP_ANCHOR_Y_TOP'
        },
        {
          value: Enums.POPUP_ANCHOR_Y_MIDDLE,
          label: 'POPUP_ANCHOR_Y_MIDDLE'
        },
        {
          value: Enums.POPUP_ANCHOR_Y_BOTTOM,
          label: 'POPUP_ANCHOR_Y_BOTTOM'
        },
      ],

      highlights: [
        {
          label: '#circle',
          highlight: '#circle',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: 'circle id',
          highlight: 'circle',
          highlightType: Enums.HIGHLIGHT_TYPE_ID,
        },
        {
          label: 'circle element',
          highlight: this.$refs['circle'],
          highlightType: Enums.HIGHLIGHT_TYPE_ELEMENT,
        },
        {
          label: '#rounded-square',
          highlight: '#rounded-square',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '#paragraph',
          highlight: '#paragraph',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '#bold',
          highlight: '#bold',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '.circle-anchor-tl',
          highlight: '.circle-anchor-top-left',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '.circle-anchor-tr',
          highlight: '.circle-anchor-top-right',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '.circle-anchor-bl',
          highlight: '.circle-anchor-bottom-left',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },
        {
          label: '.circle-anchor-br',
          highlight: '.circle-anchor-bottom-right',
          highlightType: Enums.HIGHLIGHT_TYPE_SELECTOR,
        },

      ]
    }
  },
  methods: {
    openCircleAnchor (anchor) {
      this.shape = Enums.SHAPE_CIRCLE
      this.highlightType = Enums.HIGHLIGHT_TYPE_SELECTOR

      const cornerAnchors = false;

      if (cornerAnchors) {
        this.popupAnchorAuto = false;
      } else {
        this.popupAnchorAuto = true;
        this.popupAnchorX = Enums.POPUP_ANCHOR_AUTO;
        this.popupAnchorY = Enums.POPUP_ANCHOR_AUTO;
      }

      if (anchor === 'tl') {
        this.highlight = '.circle-anchor-top-left';

        if (cornerAnchors) {
          this.popupAnchorX = Enums.POPUP_ANCHOR_X_LEFT;
          this.popupAnchorY = Enums.POPUP_ANCHOR_Y_TOP;
        }
      } else if (anchor === 'tr') {
        this.highlight = '.circle-anchor-top-right';

        if (cornerAnchors) {
          this.popupAnchorX = Enums.POPUP_ANCHOR_X_RIGHT;
          this.popupAnchorY = Enums.POPUP_ANCHOR_Y_TOP;
        }
      } else if (anchor === 'bl') {
        this.highlight = '.circle-anchor-bottom-left';

        if (cornerAnchors) {
          this.popupAnchorX = Enums.POPUP_ANCHOR_X_LEFT;
          this.popupAnchorY = Enums.POPUP_ANCHOR_Y_BOTTOM;
        }
      } else if (anchor === 'br') {
        this.highlight = '.circle-anchor-bottom-right';

        if (cornerAnchors) {
          this.popupAnchorX = Enums.POPUP_ANCHOR_X_RIGHT;
          this.popupAnchorY = Enums.POPUP_ANCHOR_Y_BOTTOM;
        }
      }

      this.onClickPop();
    },
    onClickNormalButton () {
      console.log('the normal button was clicked with its normal listener')
    },
    setHighlightOptionsFromButton (options) {
      if (options.text) {
        this.text = options.text
      }

      if (options.highlight) {
        this.highlight = options.highlight
      }

      if (options.highlightType) {
        this.highlightType = options.highlightType
      }

      if (options.shape) {
        this.shape = options.shape
      }

      if (options.offset) {
        this.offset = options.offset
      }

      this.onClickPop()
    },
    onClickPop () {
      this.$tutorialHighlightOverlay.reset()

      const builder = this.$tutorialHighlightOverlay.build()

      if (this.popupAnchorAuto) {
        builder.popupAnchor(Enums.POPUP_ANCHOR_AUTO)
      } else {
        builder.popupAnchor({
          x: this.popupAnchorX,
          y: this.popupAnchorY,
        })
      }

      builder.text(this.tutorialText)
        .highlight(this.highlight, this.highlightType)
        .shape(this.shape)
        .offset(this.offset)
        .pop()
    },
    onClickReset () {
      this.$tutorialHighlightOverlay.pop()
      this.$tutorialHighlightOverlay.reset()
    },
    onClickFlow () {
      // Instantiate builder
      const builder = this.$tutorialHighlightOverlay
        .build()
        .shape(this.shape)
        .offset(this.offset)

      builder
        .highlight('#paragraph')
        .text('This is a paragraph')
        .pop()
        .then(() => {
          return builder
            .highlight('#bold')
            .text('This is not a paragraph, but simply a tiny part of the paragraph marked in bold')
            .pop()
        })
    },
    onClickClone () {
      // Instantiate builder
      this.$tutorialHighlightOverlay
        .build()
        .highlight('#button-test')
        .clickThrough()
        .shape(this.shape)
        .offset(this.offset)
        .text('Click on the standard button to continue!')
        .pop()
    },
    onClickAnchorTest () {
      const builder = this.$tutorialHighlightOverlay
        .build()
        .shape(this.shape)
        .offset(this.offset)

      const builderAnchor = builder.clone().popupAnchor(POPUP_ANCHOR_AUTO)

      const builders = [
        builder
          .clone()
          .text('POPUP_ANCHOR_LEFT')
          .popupAnchor(POPUP_ANCHOR_LEFT),

        builder
          .clone()
          .text('POPUP_ANCHOR_RIGHT')
          .popupAnchor(POPUP_ANCHOR_RIGHT),

        builder
          .clone()
          .text('POPUP_ANCHOR_TOP')
          .popupAnchor(POPUP_ANCHOR_TOP),

        builder
          .clone()
          .text('POPUP_ANCHOR_BOTTOM')
          .popupAnchor(POPUP_ANCHOR_BOTTOM),

        builderAnchor
          .clone()
          .highlight('.circle-anchor-top-left')
          .text('POPUP_ANCHOR_AUTO.TopLeft'),

        builderAnchor
          .clone()
          .highlight('.circle-anchor-top-right')
          .text('POPUP_ANCHOR_AUTO.TopRight'),

        builderAnchor
          .clone()
          .highlight('.circle-anchor-bottom-left')
          .text('POPUP_ANCHOR_AUTO.BottomLeft'),

        builderAnchor
          .clone()
          .highlight('.circle-anchor-bottom-right')
          .text('POPUP_ANCHOR_AUTO.BottomRight'),
      ]

      this.$tutorialHighlightOverlay.popBuilderArray(builders).then(() => {
        //console.log('DONE!');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#circle {
  position: absolute;
  top: 20%;
  left: 60%;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #aa0000;
}

.circle-anchor {
  position: absolute;
  background: rgba(0, 100, 0, .2);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;

  &.circle-anchor-top-left {
    top: 15%;
    left: 15%;
  }

  &.circle-anchor-top-right {
    top: 15%;
    right: 15%;
  }

  &.circle-anchor-bottom-left {
    bottom: 15%;
    left: 15%;
  }

  &.circle-anchor-bottom-right {
    bottom: 15%;
    right: 15%;
  }
}

#rounded-square {
  position: absolute;
  top: 40%;
  left: 60%;
  height: 150px;
  width: 100px;
  border-radius: 12px;
  background-color: #0000aa;
}

#button-test {
  position: absolute;
  top: 50%;
  left: 40%;
  width: fit-content;
  height: fit-content;
}

.row {
  text-align: left;
}

.btn-highlight {
  margin: 5px;
}
</style>
