export default {
  'hold': {
    'identifier': 'hold',
    'veryPositive': 'hold.veryPositive',
    'positive': 'hold.positive',
    'medium': 'hold.medium',
    'negative': 'hold.negative',
    'gameDecisionResults': {
      'hold': 'playerDecision.hold',
      'delay': 'playerDecision.delay',
      'restrict': 'playerDecision.restrict',
      'cancel': 'playerDecision.cancel',
    },
  },
  'delay': {
    'identifier': 'delay',
    'veryPositive': 'delay.veryPositive',
    'positive': 'delay.positive',
    'medium': 'delay.medium',
    'negative': 'delay.negative',
    'gameDecisionResults': {
      'hold': 'playerDecision.hold',
      'delay': 'playerDecision.delay',
      'restrict': 'playerDecision.restrict',
      'cancel': 'playerDecision.cancel',
    },
  },
  'restrict': {
    'identifier': 'restrict',
    'veryPositive': 'restrict.veryPositive',
    'positive': 'restrict.positive',
    'medium': 'restrict.medium',
    'negative': 'restrict.negative',
    'gameDecisionResults': {
      'hold': 'playerDecision.hold',
      'delay': 'playerDecision.delay',
      'restrict': 'playerDecision.restrict',
      'cancel': 'playerDecision.cancel',
    },
  },
  'cancel': {
    'identifier': 'cancel',
    'veryPositive': 'cancel.veryPositive',
    'positive': 'cancel.positive',
    'medium': 'cancel.medium',
    'negative': 'cancel.negative',
    'gameDecisionResults': {
      'hold': 'playerDecision.hold',
      'delay': 'playerDecision.delay',
      'restrict': 'playerDecision.restrict',
      'cancel': 'playerDecision.cancel',
    },
  },
};