<template>
  <div
    ref="markdown-text-compiler"
    class="markdown-text-compiler"
    :class="[this.class, debug ? 'markdown-text-compiler-debug' : '']"
  >
    <slot />
  </div>
  <div v-if="markdownHtml" class="markdown-text" :class="class" v-html="markdownHtml" ref="markdown-compile-destination"/>
</template>

<script>
import {marked} from 'marked';

export default {
  name: 'MarkdownText',
  props: {
    debug: {
      type: Boolean,
      required: false,
      default: false,
    },
    class: {
      type: String,
      required: false,
      default: '',
    },
    features: {
      type: Array,
      required: false,
      default() {
        return [
          'code',
          'blockquote',
          'html',
          'heading',
          'hr',
          'list',
          'listitem',
          'paragraph',
          'table',
          'tablerow',
          'tablecell',
          'strong',
          'em',
          'codespan',
          'br',
          'del',
          'link',
          'image',
          'text',
        ];
      },
    },
    featuresBlacklist: {
      type: Array,
      required: false,
      default() {
        return [
          'blockquote',
          'image',
          'del',
          'code',
          'codespan',
          'checkbox',
          'hr',
        ];
      },
    },
  },
  data() {
    return {
      observer: null,
      observerMutationCount: 0,

      markdownHtml: '',
      markdownRaw: '',
    };
  },
  emits: ['render'],
  watch: {
    observerMutationCount() {
      this.renderMarkdown();
    },
  },
  mounted() {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    this.observer = new MutationObserver(() => {
      console.log('OBSERVED!!!');
      this.observerMutationCount += 1;
    });

    const htmlObserverOptions = {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    };

    // Setup the observer to observe when slot changes
    //console.log('markdownTextCompiler', this.$refs['markdown-text-compiler']);
    this.observer.observe(this.$refs['markdown-text-compiler'], htmlObserverOptions);

    this.renderMarkdown();
  },
  beforeUnmount() {
    // Clean up, disconnect observer
    this.observer.disconnect();

    this.observer = null;
    this.markdownRaw = null;
    this.markdownHtml = null;
  },
  methods: {
    renderMarkdown() {
      // Fetch from raw HTML element (best way to get slot content)
      let markdownRaw = this.$refs['markdown-text-compiler'].innerText;

      // Trim
      markdownRaw = markdownRaw.replace(/(^\s+|\s+$)/g, ' ');

      this.markdownRaw = markdownRaw;

      const featureBlacklist = this.features.filter((item) => {
        return this.featuresBlacklist.indexOf(item) >= 0;
      });

      const tokenizer = new marked.Tokenizer();

      // Disable blacklisted tokens
      for (const feature of featureBlacklist) {
        tokenizer[feature] = () => {};
      }

      /*const underlineExtension = {
        name: 'underline',
        level: 'inline',
        start(src) {
          console.log('start', src);
          return src.match(/~/)?.index;
        },
        tokenizer(src, tokens) {
          console.log('tokenizer', src, tokens);
          const rule = /~[^~]+~/;
          const match = rule.exec(src);

          if (!match) {
            return null;
          }

          return {
            type: 'underline',
            raw: match[0],
            text: match[1].trim(),
          };
        },
        renderer(token) {
          console.log('renderer', token);
          return `<u>${this.parser.parseInline(token.text)}</u>`;
        },
        childTokens: ['text'],
      };

      const extensions = [underlineExtension];

      const options = { tokenizer, extensions };*/
      const options = { tokenizer };

      //console.log('options', options);

      let markdownHtml = marked.parse(markdownRaw, options);

      // The underline extension doesn't work correct, let's just do it manually in the HTML
      markdownHtml = markdownHtml.replace(/~([^~]+)~/g, '<u>$1</u>');

      this.markdownHtml = markdownHtml;

      requestAnimationFrame(() => {
        this.$emit('render', {
          html: markdownHtml,
          element: this.$refs['markdown-compile-destination'],
        });
      });
    },
  },
};
</script>

<style lang="scss">
.markdown-text-compiler {
  display: none;

  &.markdown-text-compiler-debug {
    display: block !important;
  }
}

.markdown-text {
  // Some of these markdown items are put into a p element, others put into a blockquote element, probably due to newlines
  p:last-child,
  blockquote:last-child {
    margin-bottom: 0;
  }

  del {
    // Change the default behavior of ~text~
    text-decoration-line: underline !important;
  }

  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: circle !important;
  }
}
</style>
