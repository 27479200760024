<template>
  <div class="debriefing-container">
    <debriefing-live />
  </div>
</template>
<script>
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue';
import EndGamePopupDecisionCard from '@/components/EndGamePopup/EndGamePopupDecision/EndGamePopupDecisionCard.vue';
import DebriefingLive from '@/components/Debriefing/DebriefingLive.vue';
import Lightbox from '@/components/common/Lightbox.vue';

export default {
  name: 'EndGamePopupDebriefingLive',
  components: {
    Lightbox,
    DebriefingLive,
    EndGamePopupDecisionCard, LobbyPopupTemplate,
    EndGamePopupDecision,
  },
  watch: {
    currentDecision() {
      this.$gameClient.playerUpdate('activeEndGameVote', this.currentDecision);
    }
  },
}
</script>

<style lang="scss" scoped>
.debriefing-container {
  height: 940px;
  width: 1350px;
  overflow-y: scroll;
}
</style>
