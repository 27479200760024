<template>
  <div class="row mt-2">
    <debriefing
      v-if="ready"
      :trust="trust"
      :health-security="healthSecurity"
      :collaboration="collaboration"
      :mg-success="mgSuccess"
      :jee-scores="jeeScores"
      :strategic-actions-taken="strategicActionsTaken"
      :response-actions-taken="responseActionsTaken"
      :learning-objective-chart-bars="learningObjectiveChartBars"
      :debriefing-text="debriefingText"
    />
  </div>

  <dev-settings-panel>
    <div class="row">
      <label class="form-label">Trust</label>
      <input type="range" min="0" max="100" v-model.number="trust" />
    </div>
    <div class="row">
      <label class="form-label">Health security</label>
      <input type="range" min="0" max="100" v-model.number="healthSecurity" />
    </div>
    <div class="row">
      <label class="form-label">Collaboration</label>
      <input type="range" min="0" max="100" v-model.number="collaboration" />
    </div>
    <div class="row">
      <label class="form-label">Mass gathering success</label>
      <input type="range" min="0" max="100" v-model.number="mgSuccess" />
    </div>
  </dev-settings-panel>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT } from '@/data/decision-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import DebriefingFinalScoreCard from '@/components/Debriefing/DebriefingFinalScoreCard/index.vue'
import Debriefing from '@/components/Debriefing/index.vue'
import {mapState} from 'vuex';
import {randomBetween} from '@/utils/randomUtil';
import {generateUsedActions} from '@/utils/generator';

export default {
  name: 'DebriefingDev',
  components: {
    Debriefing,
    NoDragImage,
    HazardPopupFlagManagement,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DevSettingsPanel,
    DebriefingFinalScoreCard,
  },
  extends: DevComponentContainer,

  computed: {
    ...mapState(['jeeCategories']),
    ready() {
      return Object.keys(this.jeeScores).length > 0;
    },
  },

  data () {
    return {
      finalScore: 204,

      trust: 16,
      healthSecurity: 30,
      collaboration: 80,
      mgSuccess: 90,

      jeeScores: {},

      strategicActionsTaken: [],
      responseActionsTaken: [],

      learningObjectiveChartBars: [],

      debriefingText: 'Debriefing text; to be taken from the game result popup - the matrix',
    }
  },
  watch: {
    jeeCategories: {
      deep: true,
      handler() {
        this.randomizeEverything();
      },
    },
  },
  mounted() {
    this.randomizeEverything();
  },
  methods: {
    randomizeEverything() {
      const fakeJeeScores = {};

      console.log('randomizeJeeScores', this.jeeCategories);

      for (const category of this.jeeCategories) {
        for (const group of category.groups) {
          for (const indicator of group.jees) {
            fakeJeeScores[indicator.identifier] = Math.max(0, Math.min(100, Math.round(randomBetween(0, 100))));
          }
        }
      }

      this.jeeScores = fakeJeeScores;

      this.strategicActionsTaken = generateUsedActions('Strategic meeting', this.jeeCategories, this.jeeScores);
      this.responseActionsTaken = generateUsedActions('Hazard meeting', this.jeeCategories, this.jeeScores);

      this.learningObjectiveChartBars = [
        this.generateBar('LO 1'),
        this.generateBar('LO 2'),
        this.generateBar('LO 3'),
        this.generateBar('LO 4'),
        this.generateBar('LO 5'),
      ];
    },
    generateBar(label) {
      return {
        label: label,

        hazardActionCount: Math.round(randomBetween(0, 5)),
        strategicActionCount: Math.round(randomBetween(0, 5)),
      }
    },
  }
}
</script>

<style lang="scss" scoped>
img {
  transform: scale(0.5);
  box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
}
</style>
