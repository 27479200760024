<template>
  <div class="facilitator-player d-flex">
    <div class="facilitator-player-code">
      {{ player.code }}
    </div>
    <div class="facilitator-player-call-status" v-if="facilitatorCalled" title="The player has called for a facilitator. Click to remove." @click="onClickRemoveFacilitatorCalled">

    </div>
  </div>
</template>

<script>
export default {
  name: 'FacilitatorPlayer',
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  emits: ['removeFacilitatorCalled'],
  computed: {
    facilitatorCalled() {
      return this.player.callFacilitator;
    }
  },
  methods: {
    onClickRemoveFacilitatorCalled() {
      this.$emit('removeFacilitatorCalled', this.player);
    },
  },
}
</script>

<style lang="scss">
.facilitator-player-code {
  font-family: Consolas, monaco, monospace;
  font-size: 16px;
}

.facilitator-player-call-status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: red;
  margin-left: 5px;
  position: relative;
  top: 3px;
  animation: room-player-glow 500ms infinite alternate;
  cursor: pointer;
  transition: 200ms cubic-bezier(0.175, 0.985, 0.32, 2.175);

  &:hover {
    transform: scale(200%);
  }
}

@keyframes room-player-glow {
  from {
    box-shadow: 0 0 5px #ff0000;
    opacity: 0.5;
  }

  to {
    box-shadow: 0 0 10px #ff0000;
    opacity: 1.0;
  }
}
</style>
