<template>
  <div class="top-bar-root">
    <div class="main-content-container d-flex align-items-stretch flex-nowrap">
      <action-points-circle :current="actionPoints" :total="actionPointsTotal" :total-cost="totalActionPointsCost"/>

      <stat-card :icon="icons.loyalty" :label-text="$t('topUi.trust')" class="stat-card-main-score stat-card-trust">
        <stat-bar :min="0" :max="computedMax" :current="trust" :watch-value-changes="true" />
      </stat-card>
      <stat-card :icon="icons.health" :label-text="$t('topUi.healthSecurity')" class="stat-card-main-score stat-card-healthSecurity">
        <stat-bar :min="0" :max="computedMax" :current="healthSecurity" :watch-value-changes="true" />
      </stat-card>
      <stat-card :icon="icons.agreement" :label-text="$t('topUi.collaboration')" class="stat-card-main-score stat-card-collaboration">
        <stat-bar :min="0" :max="computedMax" :current="collaboration" :watch-value-changes="true" />
      </stat-card>
      <stat-card :icon="icons.bullet" :label-text="$t('topUi.mgEventSuccess')" class="stat-card-main-score stat-card-mgEventSuccess">
        <stat-bar :min="0" :max="computedMax" :current="mgEventSuccess" :watch-value-changes="true" />
      </stat-card>
      <stat-card :icon="icons.time" :label-text="timeLeftHover" class="flex-grow-1 stat-card-time">
        <div class="d-flex align-items-stretch flex-nowrap justify-content-between">
          <stat-bar :min="0" :max="gameTimeTotal" :current="gameTimeNormalized" :reverse="false" :show-label="false" :segment-count="gameTimeTotal + 1" />

          <div class="time-left-icon-container" @click="iterateDevCounter">
            <img :src="icons.calendar" />
          </div>
        </div>
      </stat-card>
      <stat-card label-text="Pause">

        <app-button class="pause-button" color="green" @click="pauseGame" :disabled="cooldownActive" v-if="!isPaused && !cooldownActive">
          <no-drag-image :src="IconPause" />
          {{ $t('topUi.buttonPauseGame') }}
        </app-button>

        <app-button class="pause-button" color="green" :disabled="cooldownActive" v-if="!isPaused && cooldownActive">
          <div class="d-flex flex-column">
            <div class="cooldownLabel">Pause Cooldown</div>
            <div class="cooldownTimer fs-6"> {{ formattedTimeLeft }} </div>
          </div>

        </app-button>

        <app-button class="pause-button" color="green" @click="resumeGame" v-if="isPaused"><no-drag-image :src="IconPlay" /> {{ $t('topUi.buttonResumeGame') }}</app-button>

      </stat-card>
    </div>

  </div>
</template>

<script>
import ActionPointsCircle from '@/components/player-ui/ActionPointsCircle'
import StatCard from '@/components/player-ui/StatCard'
import StatBar from '@/components/player-ui/StatBar'
import GameEventTimerCard from '@/components/player-ui/GameEventTimerCard'

import { mapState } from 'vuex'
import IconAgreement from '@/assets/icons/top-bar/icon-agreement.png'
import IconBullet from '@/assets/icons/top-bar/icon-bullet.png'
import IconHealth from '@/assets/icons/top-bar/icon-health.png'
import IconLoyalty from '@/assets/icons/top-bar/icon-loyalty.png'
import IconTime from '@/assets/icons/top-bar/icon-time.png'
import IconCalendar from '@/assets/icons/top-bar/icon-calendar.png'
import IconPause from '@/assets/icons/top-bar/icon-pause.png'
import IconPlay from '@/assets/icons/top-bar/icon-play.png'
import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import UiTimer from "@/components/player-ui/UiTimer/UiTimer.vue";
import {padLeft} from "@/utils/stringUtil";
import { getTotalMonths } from '@/utils/meetingUtil'

export default {
  name: 'TopBar',
  components: {UiTimer, NoDragImage, AppButton, ActionPointsCircle, StatCard, StatBar, GameEventTimerCard },
  props: {
    trust: {
      type: Number,
      required: true,
    },
    healthSecurity: {
      type: Number,
      required: true,
    },
    collaboration: {
      type: Number,
      required: true,
    },
    mgEventSuccess: {
      type: Number,
      required: true,
    },
    gameTime: {
      type: Number,
      required: true,
    },
    actionPoints: {
      type: Number,
      required: true,
    },
    totalActionPointsCost: {
      type: Number,
      required: false,
      default: null,
    },
    isPaused: {
      type: Boolean,
      required: false,
      default: false,
    },
    tutorialActive: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['activate-dev-mode', 'pauseMode:enable', 'pauseMode:disable'],
  methods: {
    /*debugAddHazard() {
      this.$gameClient.roomCallMethod('debugAddHazard');
    },*/
    iterateDevCounter() {
      if (this.devModeActive) {
        return;
      }

      this.devCounter++;

      if (this.devCounter >= 5) {
        this.devModeActive = true;

        this.$emit('activate-dev-mode');
      }
    },
    async pauseGame() {
      if(this.canPause){
        console.log('ASKING QUESTION');

        const confirmation = await this.$alertPop.confirm(this.$t('topUi.pauseConfirmationTitle'), this.$t('topUi.pauseConfirmationBody'));

        if (confirmation === true) {
          this.isPauser = true;
          this.$emit('pauseMode:enable');
          console.info("pauseGame")
        }
      }
    },
    resumeGame() {
      this.$emit('pauseMode:disable');
    },
  },
  data () {
    return {
      icons: {
        agreement: IconAgreement,
        bullet: IconBullet,
        health: IconHealth,
        loyalty: IconLoyalty,
        time: IconTime,
        calendar: IconCalendar,
      },

      IconPause,
      IconPlay,

      canPause: true,
      isPauser: false,

      gameTimeTotal: getTotalMonths(), // 12 meetings
      actionPointsTotal: 100,

      cooldownActive: false,
      cooldownDuration: 600000,
      cooldownTimeLeft: 0,
      cooldownTimer: null,

      devCounter: 0,
    }
  },
  watch: {
    isPaused(){
      if(!this.isPaused){
        this.isPauser = false
        if (!this.cooldownActive && !this.tutorialActive) {
          this.cooldownActive = true;
          this.cooldownTimeLeft = this.cooldownDuration / 1000;

          this.cooldownTimer = setInterval(() => {
            this.cooldownTimeLeft--;
            if (this.cooldownTimeLeft <= 0) {
              clearInterval(this.cooldownTimer);
              this.cooldownTimer = null;
              this.cooldownActive = false;
            }
          }, 1000);

        }
      }
    },
  },
  computed: {
    gameTimeNormalized() {
      return Math.max(1, Math.min(this.gameTimeTotal, this.gameTime));
    },
    computedMax() {
      return 100 / 0.1;
    },
    pauseIcon() {
      if (this.isPaused) {
        return IconPause;
      } else {
        return IconPlay;
      }
    },
    timeLeftHover() {
      return this.$t('topUi.timeLeftHover')
        .replace(/\[CURRENT_MONTH\]/g, this.gameTimeNormalized)
        .replace(/\[TOTAL_MONTHS\]/g, this.gameTimeTotal);
    },
    formattedTimeLeft() {
      let minutesLeft = Math.floor(this.cooldownTimeLeft / 60);
      let secondsLeft = this.cooldownTimeLeft - (minutesLeft * 60);

      return `${minutesLeft.toString()}:${padLeft(secondsLeft.toString(), 2, '0')}`
    }
  }
}
</script>

<style scoped lang = 'scss'>

@import '~@/assets/styles/variables.scss';

.top-bar-root {
  height: 120px;
  width: $appWidth;
  position: relative;
  padding: 10px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), linear-gradient(180deg, #2C7373 0%, #253D62 100%);
    opacity: 0.7;
    box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.8);
    content: "";
    z-index: 0;
  }
}

.main-content-container {
  padding-left: $actionPointCircleDiameter;
  z-index: 100;
}

.action-points-circle {
  position: absolute;
  left: -$actionPointCircleDiameterOffsetX;
  top: -85px;
}

.time-left-icon-container {
  z-index: 1;
  padding: 2px;
}

.pause-button {
  img {
    max-height: 25px;
  }
}

.cooldownLabel {
  font-size: .7em;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 5px;
}

.cooldownTimer {
  margin-bottom: -5px;
}

</style>
