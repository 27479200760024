<template>
  <div class="indicator-group" :class="open ? 'indicator-group-open' : 'indicator-group-closed'">
    <div class="indicator-group-row d-flex" :style="style" @click="toggleOpen" v-if="isRealGroup">
      <div class="name">{{ data.name }}.</div>
      <div class="description flex-fill text-start">{{ data.description }}</div>
      <div class="count">{{ jeeCapacityAverage }}</div>
      <div class="triangle"><img src="@/assets/icons/triangle-up.png" /></div>
    </div>
    <accordion :open="open || !isRealGroup">
      <div class="indicators">
        <dashboard-indicator
          v-for="jee of data.jees"
          :data="jee"
          :identifier="identifier"
          :jee-score="jeeScores[jee.identifier] || 0"
        />
      </div>
    </accordion>
  </div>
</template>

<script>
import { getDashboardCategoryColor, getDashboardGroupColor } from '@/components/dashboard/CategoryColorUtils'
import Accordion from '@/components/common/Accordion.vue'
import DashboardIndicator from '@/components/dashboard/DashboardIndicator.vue'
import { getCapacityLevelFromJeeScore } from '@/components/dashboard/CapacityLevelUtil'

export default {
  name: 'DashboardIndicatorGroup',
  components: { Accordion, DashboardIndicator },
  props: {
    data: {
      type: Object,
      required: true,
    },
    identifier: {
      type: String,
      required: true
    },
    jeeScores: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  data() {
    return {
      open: true,
      jeeCapacityAverage: 0,
    }
  },
  watch: {
    jeeScores() {
      this.recalculateJeeScores();
    }
  },
  mounted() {
    this.recalculateJeeScores();
  },
  methods: {
    recalculateJeeScores() {
      let jeeCapacityTotal = 0;
      let jeeCapacityCount = 0;

      for (const jee of this.data.jees) {
        jeeCapacityTotal += getCapacityLevelFromJeeScore(this.jeeScores[jee.identifier] || 0);
        jeeCapacityCount += 1;
      }

      if (jeeCapacityCount > 0) {
        this.jeeCapacityAverage = Math.floor(jeeCapacityTotal / jeeCapacityCount);
      } else {
        this.jeeCapacityAverage = 0;
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
  },
  computed: {
    isRealGroup() {
      if (!this.data.jees || !this.data.identifier) {
        return false;
      }

      if (this.data.jees.length <= 0) {
        return false;
      }

      if (this.data.jees.length === 1 && this.data.jees[0].identifier === this.data.identifier) {
        return false; // It's the same one
      }

      return true;
    },
    style() {
      return {
        'background': `${getDashboardGroupColor(this.identifier, .6)}`,
      }
    },
  },
}
</script>
<style lang="scss">
.indicator-group {
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
  color: #FFFFFF;
  user-select: none;

  .name, .count {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
  }

  .count {
    min-width: 60px;
    width: 60px;
    text-align: left;
  }

  .indicator-group-row {
    padding: 10px;
    cursor: pointer;
    user-select: none;

    .name {
      min-width: 80px;
      width: 80px;
      text-align: left;
    }

    .triangle {
      min-width: 26px;
      width: 26px;
      height: 26px;
      transition-duration: 200ms;
      transition-timing-function: ease-in-out;
    }
  }

  &.indicator-group-open {
    .indicator-group-row {
      .triangle {
        transform: rotate(180deg);
      }
    }
  }
}

.indicators {
  padding: 0 20px;

  .indicator {
    margin-top: 7px;
  }
}

</style>
