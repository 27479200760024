<template>
  <tr v-if="user !== null" class="tr-user-details">
    <td>{{ user.id }}<span v-if="isYou" class="badge bg-primary ms-2">(you!)</span></td>
    <td>{{ user.username }}</td>
    <td>{{ computedRole }}</td>
    <td>
      <button v-if="canEdit" class="btn btn-warning" @click="onClickEditUser">Edit</button>
      <button v-if="canDelete" class="btn btn-danger" @click="onClickDeleteUser">Delete</button>
    </td>
  </tr>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'AdminUserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['signedInUser']),
    isYou () {
      return this.signedInUser.id === this.user.id
    },
    canEdit () {
      if (this.isYou) {
        return true // You can always edit yourself
      }

      if (this.signedInUser.role === 'rootadmin') {
        // Root admins can change everyone who isn't rootadmin
        return this.user.role !== 'rootadmin'
      } else if (this.signedInUser.role === 'admin') {
        // Admins can change everyone who isn't rootadmin and admin
        return this.user.role !== 'rootadmin' && this.user.role !== 'admin'
      }

      return false
    },
    canDelete () {
      if (this.isYou) {
        return false; // You can never delete yourself
      }

      if (this.signedInUser.role === 'rootadmin') {
        // Root admins can change everyone who isn't rootadmin
        return this.user.role !== 'rootadmin'
      } else if (this.signedInUser.role === 'admin') {
        // Admins can change everyone who isn't rootadmin and admin
        return this.user.role !== 'rootadmin' && this.user.role !== 'admin'
      }

      return false;
    },
    computedRole () {
      const userRole = this.user.role

      switch (userRole) {
        case 'rootadmin':
          return 'Super Admin'
        case 'admin':
          return 'Admin'
        case 'facilitator':
          return 'Facilitator'
        default:
          return `Unknown (${userRole})`
      }
    }
  },
  emits: ['user-deleted'],
  data () {
    return {
      showActions: false,
    }
  },
  methods: {
    onClickEditUser () {
      this.$router.push({
        name: 'UserUpdate',
        params: { id: this.user.id }
      })
    },
    onClickDeleteUser () {
      if (confirm(`Are you sure you want to delete '${this.user.username}'?`)) {
        this.$apiClient.backend.deleteUser(this.user.id).then((data) => {
          this.$emit('user-deleted', this.user.id)
        })
      }
    },
  }
}
</script>

<style lang="scss">
.tr-user-details {
  .btn {
    margin-right: 4px;
  }
}
</style>
