import GameClientCore from './core';

export default class GameClient extends GameClientCore {
  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return {
    };
  }

  mutatePlayerWithLocalData(player, playerId) {
    const type = typeof (player.isSelf);

    if (type !== 'undefined') {
      return;
    }

    this.vuexSyncer.vuex.commit('roomSetPlayerLocalData', {
      playerUid: playerId,
      data: {
        isSelf: player.sessionId === this.room.sessionId
      },
    });
  }
}
