<template>
<div class="left-bar-root">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: 'LeftActionBar',
}
</script>

<style scoped>

.left-bar-root{
  position: absolute;
  top: 320px;
  width: 225px;
  height: 200px;
  display: block;
}

div > *:not(:last-child) {
  display: block;
  margin-bottom: 10px;
}
</style>
