<template>
  <div class="app-button-dev-container">
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th><table-column-descriptor row="state" column="color" /></th>
              <th v-for="color in colors">
                {{ color }}
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="state in states">
            <th>
              {{ state }}
            </th>
            <td v-for="color in colors">
              <app-button :color="color" :state="state" :disabled="state === 'disabled'">Btn text</app-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-begin gap-4">
          <toggle-app-button idle-color="green" active-color="red" v-model="toggleButtonValue1">Toggle button ({{ toggleButtonValue1 ? 'On' : 'Off' }})</toggle-app-button>
          <toggle-image-button idle-color="green" active-color="red" v-model="toggleButtonValue2">
            <img v-if="toggleButtonValue2" src="@/assets/icons/microphone/mic-off.png" />
            <img v-else src="@/assets/icons/microphone/mic-on.png" />
          </toggle-image-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th><table-column-descriptor row="width" column="type" /></th>
            <th v-for="width in widths">
              {{ width }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="type in types">
            <th>
              {{ type }}
            </th>
            <td v-for="width in widths">
              <app-button :type="type" :color="defaultButton.color" :state="defaultButton.state" :disabled="defaultButton.disabled" :width="width">Text</app-button>
              <image-button :type="type" :color="defaultButton.color" :state="defaultButton.state" :disabled="defaultButton.disabled" :size="width"><img src="@/assets/icons/microphone/mic-on.png" /></image-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col" v-for="type in types">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th><table-column-descriptor row="width" column="height" /></th>
            <th v-for="width in widths">
              {{ width }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="height in heights">
            <th>
              {{ height }}
            </th>
            <td v-for="width in widths">
              <app-button :color="defaultButton.color" :state="defaultButton.state" :disabled="defaultButton.disabled" :width="width" :height="height">Btn text</app-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th><table-column-descriptor row="width" column="height" /></th>
            <th v-for="width in widths">
              {{ width }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="height in heights">
            <th>
              {{ height }}
            </th>
            <td v-for="width in widths">
              <app-button :color="defaultButton.color" :state="defaultButton.state" :disabled="defaultButton.disabled" :width="width" :height="height">This is a button with an unusual large amount of text</app-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>

<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import AppButton from '@/components/common/buttons/AppButton.vue'
import ImageButton from '@/components/common/buttons/ImageButton.vue'
import TinyButton from '@/components/common/TinyButton.vue'
import TableColumnDescriptor from '@/components/common/TableColumnDescriptor.vue'
import ToggleImageButton from '@/components/common/buttons/ToggleImageButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'

export default {
  name: 'Voice-Dev',
  components: {
    ToggleAppButton,
    ToggleImageButton,
    TableColumnDescriptor,
    AppButton, ImageButton, EventTicks, ButtonContainer},
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data() {
    return {
      toggleButtonValue1: false,
      toggleButtonValue2: false,

      defaultButton: {
        color: 'blue',
        width: 'md',
        height: 'md',
        state: 'normal',
        type: 'box',
      },
      events: ['clicked'],
      states: [
        'normal',
        'hover',
        'active',
        'disabled',
      ],
      colors: [
        'blue',
        'green',
        'red',
        'orange',
      ],
      widths: [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        'fit-content',
        'full',
      ],
      heights: [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        'fit-content',
        'full',
      ],
      types: [
        'box',
        'circle',
        'box-rounded',
      ]
    }
  },

  methods: {
    onClick(buttonName) {
      console.log('click', buttonName);

      this.triggerEvent('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-button-dev-container {
  color: white !important;
  width: 1200px;

  > .row {
    padding-top: 30px;
  }
}

table {
  background: rgba(0, 0, 0, 60%);
  tr {
    th, td {
      color: white !important;
    }
  }
}

.animated-button-container {
  width: 500px;
  height: 500px;
}
</style>
