<template>
  <div v-if="debugPosition" class="tutorial-box-debug-position" :style="debugPointStyle" />
  <lobby-popup-template ref="tutorial-box" popup-class="tutorial-box" content-class="medium-padding" :with-background="false" :show-footer-line="false" :show-footer="false" :style="popupStyle">
    <template v-slot:header>
      <slot name="header" />
    </template>
    <template v-slot:content>
      <slot name="content" />
    </template>
  </lobby-popup-template>
</template>
<script>
import AppButton from '@/components/common/buttons/AppButton.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import LobbyPopupDoubleTemplate from '@/components/Lobby/LobbyPopupDoubleTemplate.vue'
import { AnchorPoint } from '@/components/TutorialBox/anchorPoint'

export default {
  name: 'TutorialBox',
  components: {
    LobbyPopupDoubleTemplate,
    LobbyPopupTemplate,
    AppButton
  },
  props: {
    top: {
      type: Number,
      required: false,
      default: -1,
    },
    left: {
      type: Number,
      required: false,
      default: -1,
    },
    debugPosition: {
      type: Boolean,
      required: false,
      default: false,
    },
    anchorPoint: {
      type: String,
      required: false,
      default: AnchorPoint.Left
    },
  },
  data() {
    return {
      debugPointStyle: {},
      popupStyle: {},
    };
  },
  watch: {
    top() {
      this.recomputeStyle();
    },
    left() {
      this.recomputeStyle();
    },
    anchorPoint() {
      this.recomputeStyle();
    }
  },
  mounted() {
    this.recomputeStyle();
  },
  methods: {
    recomputeStyle() {
      this.debugPointStyle = this.getDebugPointStyle();
      this.popupStyle = this.getPopupStyle();
    },
    getDebugPointStyle() {
      const style = {};

      if (this.left >= 0 || this.top >= 0) {
        style.position = 'fixed';
      }

      if (this.left >= 0) {
        style.left = this.left + 'px';
      }

      if (this.top >= 0) {
        style.top = this.top + 'px';
      }

      return style;
    },
    getPopupStyle() {
      const style = {};

      if (this.left >= 0 || this.top >= 0) {
        style.position = 'fixed';
      }

      if (this.left >= 0) {
        style.left = this.left + 'px';
      }

      if (this.top >= 0) {
        style.top = this.top + 'px';
      }

      if (this.anchorPoint === AnchorPoint.Bottom) {
        style.transform = `translate(-50%, -100%)`
      } else if (this.anchorPoint === AnchorPoint.Top) {
        style.transform = `translate(-50%, 0%)`
      } else if (this.anchorPoint === AnchorPoint.Left) {
        style.transform = `translate(0%, -50%)`
      } else if (this.anchorPoint === AnchorPoint.Right) {
        style.transform = `translate(-100%, -50%)`
      }

      return style;
    }
  }
}
</script>

<style lang="scss">
.tutorial-box {
  width: fit-content !important;
  height: fit-content !important;

  .lobby-popup-header {
    text-align: left;
  }

  .lobby-popup-content {
    color: white;
    width: fit-content;
    height: fit-content;
  }
}

.tutorial-box-debug-position {
  box-shadow: 2px 2px 2px 2px rgba(#000000, .6);
  background: yellow;
  position: fixed;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  z-index: 99999999999999;
}
</style>

<style lang="scss">
@import "@/assets/styles/background-colors";

.tutorial-box {
  font-size: 14pt;
  text-align: left;

  .tutorial-box-content {
    max-width: 900px;
    width: 900px;
  }

  p, table {
    text-align: left;
  }

  .tutorial-table {
    border-collapse: separate;
    border-spacing: 5px;

    th, td {
      padding: 3px 6px;
      text-align: left;
    }

    thead {
      th, td {
        background: transparent;
      }
    }

    tbody {
      th, td {
        background: $bgDarkish;
      }
    }
  }
}
</style>
