<template>
  <aspect-ratio-container :aspect-width="1920" :aspect-height="1080" ref="aspectRatioContainer">
    <tutorial-highlight-overlay :popup-follow="true" :popup-offset-x="10" :popup-offset-y="10" :show-anchor-circle="false" :show-tutorial-layer="showTutorial">
      <template v-slot:popup="{ text, style, passOptions }">
        <lobby-popup-template popup-class="lobby-popup-tutorial" :with-background="false" :show-footer-line="false"
                              :style="style">
          <template v-slot:header>
          <span v-if="passOptions && passOptions.tutorialGroupIdentifier" :title="passOptions.tutorialGroupIdentifier">{{
              $t('tutorial.title')
            }}</span>
            <span v-else>{{ $t('tutorial.title') }}</span>
          </template>
          <template v-slot:content>
            <div class="tutorial-content">
              <markdown-text>{{ text }}</markdown-text>
            </div>
          </template>
          <template v-slot:footer>
            <app-button color="green" @click="onTutorialContinue">{{ $t('tutorial.understood') }}</app-button>
          </template>
        </lobby-popup-template>
      </template>
    </tutorial-highlight-overlay>

    <div class="background" :class="'background-' + computedBackground">
      <bling-layer-bottom v-if="backgroundBlingBottom"/>
      <bling-layer-top v-if="backgroundBlingTop"/>

      <debug-grid :gap="5" v-if="showDebugGrid"/>

      <overlay-pop/>
      <alert-pop/>

      <router-view/>
    </div>
  </aspect-ratio-container>
</template>

<script>
import { mapState } from 'vuex'
import { GameClientEvent } from '@/plugins/GameClient/lib/GameClientEvent'
import AspectRatioContainer from '@/components/common/AspectRatioContainer.vue'
import OverlayPop from '@/plugins/OverlayPop/OverlayPop.vue'
import AlertPop from '@/plugins/AlertPop/AlertPop.vue'
import TutorialHighlightOverlay from '@/plugins/TutorialHighlightOverlay/TutorialHighlightOverlay.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import BlingLayerBottom from '@/components/bling/BlingLayerBottom.vue'
import BlingLayerTop from '@/components/bling/BlingLayerTop.vue'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import { TutorialUtil } from '@/utils/tutorialUtil'
import DebugGrid from '@/components/dev/DebugGrid.vue'
import { getApiBaseUrl, getWebsocketAddress } from '@/utils/currentHostModifier';

export default {
  name: 'App',
  components: {
    DebugGrid,
    MarkdownText,
    BlingLayerTop,
    BlingLayerBottom,
    FloatingHazardIcon,
    AppButton,
    LobbyPopupTemplate,
    TutorialHighlightOverlay,
    AspectRatioContainer,
    OverlayPop,
    AlertPop
  },
  data () {
    return {
      showDebugGrid: false,
    }
  },
  computed: {
    ...mapState(['background', 'backgroundBlingTop', 'backgroundBlingBottom', 'room', 'showTutorial']),
    computedBackground () {
      return this.background || 'none'
    },
  },
  mounted () {
    TutorialUtil.setTutorialHighlightOverlay(this.$tutorialHighlightOverlay)
    console.log('TutorialUtil', TutorialUtil)

    console.log(`Client version: 0.0.1`)
    if (parseInt(process.env.VUE_APP_DEBUG_GAME_CLIENT, 10) > 0) {
      this.$gameClient.setUseDebugConsole(true, 'Enabled debugging due to VUE_APP_DEBUG_GAME_CLIENT', 'white', '#880088')
    }

    if (parseInt(process.env.VUE_APP_DEBUG_VOICE_CLIENT, 10) > 0) {
      this.$voiceChatClient.setUseDebugConsole(true, 'Enabled debugging due to VUE_APP_DEBUG_VOICE_CLIENT', 'white', '#008800')
    }

    if (parseInt(process.env.VUE_APP_DEBUG_API_CLIENT, 10) > 0) {
      this.$apiClient.setUseDebugConsole(true, 'Enabled debugging due to VUE_APP_DEBUG_API_CLIENT', 'white', '#000088')
    }

    this.$apiClient.setBaseUrl(getApiBaseUrl())

    // This doesn't actually connect to anything, it just sets it up
    const wsAddress = getWebsocketAddress()

    this.$gameClient.setUseDebugConsole(true)
    this.$gameClient.setVuex(this.$store, 'room', {
      // The name of the mutators
      set: 'roomStateSet',
      add: 'roomStateAdd',
      remove: 'roomStateRemove',
      reset: 'roomStateReset',
    })

    this.$gameClient.connectToServer(wsAddress)

    this.$gameClient.eventEmitter.on(GameClientEvent.ROOM_LEAVE, this.onRoomLeave)

    this.$tutorialHighlightOverlay.addEventListener('close', (e) => {
      const tutorialIdentifier = e?.popOpenEvent?.passOptions?.tutorialGroupIdentifier;

      if (tutorialIdentifier) {
        this.$gameClient.playerCallMethod('closedTutorial', { tutorialId: tutorialIdentifier });
      }
    });

    //this.$voiceController.setVoiceChatClient(this.$voiceChatClient);
    this.$voiceChatClient.setVoiceController(this.$voiceController)

    const token = sessionStorage.getItem('jwtToken')
    if (token !== null) {
      this.$apiClient.setDefaultHeader('authorization', token)
    }

  },
  methods: {
    onRoomLeave (data) {
      console.log('ON ROOM LEAVE!!')

      this.$gameClient.reset().then(() => {
        this.$overlayPop.cancel()

        console.log('PUSHING TO FRONTPAGE')
        this.$router.push('/')
      })
    },
    onTutorialContinue () {
      this.$tutorialHighlightOverlay.close()
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/background-colors.scss";

html, body {
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  background: black;

}

#app {
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*aspect-ratio: 16/9;
  max-width: 100%;
  max-height: 100%;*/
  width: 100%;
  height: 100%;
}

.backend-container {
  height: 100%;
  width: 60%;
  overflow-y: scroll;
  margin: 0 auto;
  padding-top: 2%;
  padding-left: 2em;
  padding-right: 2em;
  background-color: white;
  box-shadow: .5em .5em 1em rgba(0, 0, 0, 0.5);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

$scrollbarColorThumb: #FFFFFF;
$scrollbarColorBackground: #B08035;
$scrollbarSize: 30px;

.epic-scrollbar {
  overflow-y: auto;
  overflow-x: clip;
  scrollbar-color: $scrollbarColorThumb $scrollbarColorBackground;

  &::-webkit-scrollbar {
    width: 8px;
    height: $scrollbarSize;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $scrollbarColorBackground;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: $scrollbarSize;
    background-color: $scrollbarColorThumb;
  }
}

.epic-scrollbar-x {
  overflow-x: auto;
  overflow-y: clip;
  scrollbar-color: $scrollbarColorThumb $scrollbarColorBackground;

  &::-webkit-scrollbar {
    height: 8px;
    width: $scrollbarSize;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $scrollbarColorBackground;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    width: $scrollbarSize;
    background-color: $scrollbarColorThumb;
  }
}

.epic-scrollbar-both {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-color: $scrollbarColorThumb $scrollbarColorBackground;

  &::-webkit-scrollbar {
    &:vertical {
      width: $scrollbarSize;
    }

    &:horizontal {
      height: $scrollbarSize;
    }
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $scrollbarColorBackground;
  }

  &::-webkit-scrollbar-thumb {
    &:vertical {
      height: $scrollbarSize;
      background-color: $scrollbarColorThumb;
    }

    &:horizontal {
      width: $scrollbarSize;
      background-color: $scrollbarColorThumb;
    }
  }
}

.background {
  width: 100%;
  height: 100%;

  background-size: cover;
  background-color: #ffffff;

  &.background-game {
    background-image: url('~@/assets/backgrounds/world-map.png');
  }

  &.background-backend {
    background-color: #ffffff50;

    &::after {
      content: '<div>Squirrel</div>';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      filter: blur(2px) brightness(70%) grayscale(100%);
      background-image: url('~@/assets/backgrounds/world-map.png');
    }
  }

  &.background-roundtable {
    background-image: url('~@/assets/backgrounds/roundtable.png');
  }
}

.bg-light {
  background: $bgLight;
}

.bg-dark {
  background: $bgDark;
}

.bg-darkish {
  background: $bgDarkish;
}

.bg-level-default {
  background: $bgLevelDefault;
}

.bg-level {
  color: black;
  font-weight: bold;
  text-align: center;
}

.bg-level-1 {
  background: $bgLevel1 !important;
}

.bg-level-2 {
  background: $bgLevel2 !important;
}

.bg-level-3 {
  background: $bgLevel3 !important;
}

.bg-level-4 {
  background: $bgLevel4 !important;
}

.bg-level-5 {
  background: $bgLevel5 !important;
}

.bg-level-10 {
  /** Fake one for test data **/
  background: $bgLevelDefault !important;
}

.avoid-whitespace {
  white-space: nowrap;
}

@keyframes quick-pulse {
  from {
    transform: scale(1.00);
  }

  50% {
    transform: scale(1.30);
  }

  to {
    transform: scale(1.00);
  }
}

.quick-pulse {
  position: relative;
  animation-name: quick-pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 500ms;
  transform-origin: center center;
  z-index: 5000;
}

.tutorial-content {
  strong {
    color: #E0FF4F;
  }

  font-size: 1.2em;
}

.overlay-pop p.sub-text {
  color: #E0FF4F;
}
</style>
