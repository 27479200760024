<template>
  <div class="strategic-meeting-popup">
    <strategic-meeting-total-cost class="strategic-meeting-cost" :cost="totalCost" />

    <div class="strategic-meeting-header">
      {{ $t('meetingPopup.meetingResultsHeader') }}
    </div>
    <div class="strategic-meeting-investments d-flex align-items-stretch">
      <div class="strategic-meeting-investments-actions-container flex-grow">
        <div class="strategic-meeting-investments-actions-header">
          {{ $t('meetingPopup.meetingResultsActionsTaken') }}: {{ investedActions.length }}
        </div>
        <div class="strategic-meeting-investments-actions-content p-3">
          <div class="strategic-meeting-investments-actions-content-inner">
            <div class="epic-scrollbar h-100">
              <div v-for="(investedAction, i) in investedActions" class="single-invested-action-container" :class="[i === selectedInvestmentIndex ? 'single-invested-action-container-active' : '']">
                <strategic-meeting-invested-action :description="investedAction.text" :cost="investedAction.cost" @click="toggleSelectedInvestmentIndex(i)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="strategic-meeting-investments-feedback-container" v-if="feedback">
        <div class="strategic-meeting-investments-feedback-header">
          {{ $t('meetingPopup.meetingResultsFeedback') }}
        </div>
        <div class="strategic-meeting-investments-feedback-content">
          <div class="strategic-meeting-investments-feedback-content-inner">
            <div class="epic-scrollbar h-100">
              <markdown-text>{{ feedback }}</markdown-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="strategic-meeting-hint d-flex">
      <div class="hint-container">Hint:</div>
      <div class="hint-text-container flex-grow-1">{{ hint }}</div>
      <div class="hint-button-container">
        <app-button color="green" width="fit-content" height="lg" @click="onClickHintContinue">Continue</app-button>
      </div>
    </div>-->
  </div>
</template>
<script>
import LongButton from '@/components/common/LongButton.vue'
import HazardPopupContainer from '@/components/HazardMeeting/HazardPopupContainer/index.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import Lightbox from '@/components/common/Lightbox.vue'
import StrategicMeetingAction from '@/components/StrategicMeeting/StrategicMeetingAction/index.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleAppButton from '@/components/common/buttons/ToggleAppButton.vue'
import StrategicMeetingTotalCost from '@/components/StrategicMeeting/StrategicMeetingTotalCost/index.vue'
import { objectToArray } from '@/utils/arrayUtil'
import { mapState } from 'vuex'
import StrategicMeetingInvestedAction from '@/components/StrategicMeeting/StrategicMeetingInvestedAction/index.vue';
import MarkdownText from '@/components/common/MarkdownText/index.vue';

export default {
  name: 'StrategicMeetingResults',
  components: {
    MarkdownText,
    StrategicMeetingTotalCost,
    ToggleAppButton,
    StrategicMeetingAction,
    Lightbox,
    NoDragImage,
    HazardPopupContainer,
    AppButton,
    StrategicMeetingInvestedAction,
  },
  props: {
    investedActions: {
      type: Array,
    },
    canInteract: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedInvestmentIndex: 0,

      hint: 'The RAV Public school of Retunya informs that the sick student comes from the neighboring country Tremeristan and is currently on exchange.',

      //addedActionIdentifiers: [],
    };
  },
  computed: {
    totalCost() {
      let cost = 0;

      for (const investedAction of this.investedActions) {

        console.log('investment', investedAction);
        cost += investedAction.cost;
      }

      return cost;
    },
    selectedInvestment() {
      if (this.selectedInvestmentIndex === null) {
        return null;
      }

      return this.investedActions[this.selectedInvestmentIndex];
    },
    feedback() {
      if (!this.selectedInvestment) {
        return null;
      }

      if (this.selectedInvestment.feedbackNarrative) {
        return this.selectedInvestment.feedbackNarrative;
      }

      return '_No feedback_';
    }
  },
  emits: ['continue'],
  methods: {
    onClickHintContinue() {
      this.$emit('continue');
    },
    toggleSelectedInvestmentIndex(i) {
      if (this.selectedInvestmentIndex === i) {
        this.selectedInvestmentIndex = null;
      } else {
        this.selectedInvestmentIndex = i;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$totalHeight: 1080px;
$headerHeight: 80px;
$hintHeight: 0; // 100px;
$investmentsHeight: $totalHeight - $headerHeight - $hintHeight;

.strategic-meeting-header {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #051224;
  box-shadow: inset -6.20755px -6.20755px 4.96604px rgba(0, 0, 0, 0.4), inset 4.96604px 4.96604px 3.72453px rgba(255, 255, 255, 0.4);
  height: $headerHeight;
}

.strategic-meeting-investments {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
  box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  height: $investmentsHeight;

  overflow: hidden;
}

.strategic-meeting-hint {
  background: #194040;
  height: $hintHeight;
  padding: 10px 130px;

  color: white;

  .hint-container{
    font-weight: 700;
    font-size: 20pt;
  }

  .hint-text-container {
    font-size: 16pt;
  }
}

.strategic-meeting-investments-actions-container,
.strategic-meeting-investments-feedback-container {
  padding: 5px;
}

.strategic-meeting-investments-actions-container {
  flex-basis: 70%;

  .strategic-meeting-investments-actions-content {
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);
  }
}

.strategic-meeting-investments-feedback-container {
  flex-basis: 30%;

  .strategic-meeting-investments-feedback-content {
    padding: 10px;
    background: url("@/assets/textures/grunge-seamless-20-percent.png"), #184E5A;
    box-shadow: 8.79415px 8.79415px 8.79415px 8.79415px rgba(0, 0, 0, 0.6), inset 3.51766px 3.51766px 2.63825px rgba(255, 255, 255, 0.4), inset -4.39708px -4.39708px 3.51766px rgba(0, 0, 0, 0.4);

    .strategic-meeting-investments-feedback-content-no-feedback {
      font-style: italic;
    }
  }

  .strategic-meeting-investments-feedback-content-inner {
    background: linear-gradient(0deg, rgba(0, 27, 68, 0.4), rgba(0, 27, 68, 0.4)), url("@/assets/textures/grunge-seamless-20-percent.png"), rgba(#000E21, .6);
    mix-blend-mode: normal;
    box-shadow: 0px 4.17299px 6.95499px 1.391px rgba(0, 0, 0, 0.8), 0px -2.78199px 12.519px 1.391px rgba(0, 0, 0, 0.5), inset -8.91px -8.91px 13.91px 1.391px rgba(0, 0, 0, 0.56);
    border-radius: 10px;
    padding: 50px;
    color: white;
    text-align: left;
    line-height: 150%;

    .epic-scrollbar {
      padding: 10px;
    }
  }
}

.strategic-meeting-cost {
  position: absolute;

  // For testing:
  //top: 10px;
  //left: 10px;

  // For real components:
  top: 0px;
  left: -240px;
}

.strategic-meeting-investments-actions-header,
.strategic-meeting-investments-feedback-header,
.strategic-meeting-header {
  font-size: 25pt;
  color: white;
  line-height: $headerHeight;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.strategic-meeting-popup {
  overflow: hidden;
  height: 1060px;
  width: 1300px;
  padding: 0;
}

.strategic-meeting-invested-action {
  cursor: pointer;
}

.single-invested-action-container {
  padding: 5px;

  transition-duration: 200ms;
  transition-property: padding-left;

  &.single-invested-action-container-active {
    padding-left: 50px;
  }
}

.strategic-meeting-investments-actions-content,
.strategic-meeting-investments-feedback-content {
  height: calc(100% - 80px);
}

.strategic-meeting-investments-actions-content-inner,
.strategic-meeting-investments-feedback-content-inner {
  height: 100%;
}

</style>
