import StarData from '@/lib/star-data'
import { i18n } from '@/lib/i18n/i18n'

const translate = i18n.global.t

export const levelTable = {
  likelihood: {
    veryUnlikely: 1,
    unlikely: 2,
    likely: 3,
    veryLikely: 4,
    almostCertain: 5,
  },
  severity: {
    low: 1,
    partial: 2,
    moderate: 3,
    high: 4,
    veryHigh: 5,
  },
  vulnerability: {
    low: 1,
    partial: 2,
    moderate: 3,
    high: 4,
    veryHigh: 5,
  },
  copingCapacity: {
    perennial: 1,
    low: 1,
    partial: 2,
    moderate: 3,
    high: 4,
    veryHigh: 5,
  },
  impact: {
    perennial: 1,
    partial: 2,
    moderate: 3,
    high: 4,
    veryHigh: 5,
  },
  riskLevel: {
    low: 1,
    partial: 2,
    moderate: 3,
    high: 4,
    veryHigh: 5,
  },
}

const levelTableOppositeCached = {};

for (const key in levelTable) {
  const levelTableOppositeSub = {};

  for (const subKey in levelTable[key]) {
    levelTableOppositeSub[levelTable[key][subKey]] = subKey;
  }

  levelTableOppositeCached[key] = levelTableOppositeSub;
}

export const levelTableOpposite = levelTableOppositeCached;

export const labelTable = {
  frequency: {
    perennial: 'starMinigame.frequency_perennial',
    recurrent: 'starMinigame.frequency_recurrent',
    frequent: 'starMinigame.frequency_frequent',
    rare: 'starMinigame.frequency_rare',
    random: 'starMinigame.frequency_random',
  },
  likelihood: {
    veryUnlikely: 'starMinigame.likelihood_veryUnlikely',
    unlikely: 'starMinigame.likelihood_unlikely',
    almostCertain: 'starMinigame.likelihood_almostCertain',
    veryLikely: 'starMinigame.likelihood_veryLikely',
    likely: 'starMinigame.likelihood_likely',
  },
  severity: {
    veryHigh: 'starMinigame.severity_veryHigh',
    high: 'starMinigame.severity_high',
    moderate: 'starMinigame.severity_moderate',
    low: 'starMinigame.severity_low',
    veryLow: 'starMinigame.severity_veryLow',
  },
  vulnerability: {
    veryLow: 'starMinigame.vulnerability_veryLow',
    low: 'starMinigame.vulnerability_low',
    partial: 'starMinigame.vulnerability_partial',
    high: 'starMinigame.vulnerability_high',
    veryHigh: 'starMinigame.vulnerability_veryHigh',
  },
  copingCapacity: {
    perennial: 'starMinigame.copingCapacity_perennial',
    veryLow: 'starMinigame.copingCapacity_veryLow',
    low: 'starMinigame.copingCapacity_low',
    partial: 'starMinigame.copingCapacity_partial',
    high: 'starMinigame.copingCapacity_high',
    veryHigh: 'starMinigame.copingCapacity_veryHigh',
  },
  impact: {
    perennial: 'starMinigame.impact_perennial',
    partial: 'starMinigame.impact_partial',
    moderate: 'starMinigame.impact_moderate',
    high: 'starMinigame.impact_high',
    veryHigh: 'starMinigame.impact_veryHigh',
  },
  confidenceLevel: {
    satisfactory: 'starMinigame.confidenceLevel_satisfactory',
    unsatisfactory: 'starMinigame.confidenceLevel_unsatisfactory',
    good: 'starMinigame.confidenceLevel_good',
  },
  riskLevel: {
    veryLow: 'starMinigame.riskLevel_veryLow',
    low: 'starMinigame.riskLevel_low',
    partial: 'starMinigame.riskLevel_partial',
    high: 'starMinigame.riskLevel_high',
    veryHigh: 'starMinigame.riskLevel_veryHigh',
  }
}

export function getLabel (key, value) {
  if (labelTable[key] && labelTable[key][value]) {
    return translate(labelTable[key][value])
  }

  return value
}

export function getLevel (key, value) {
  return levelTable[key] && levelTable[key][value] ? levelTable[key][value] : value
}

export function getIdentifierFromLevel(key, value) {
  return levelTableOpposite[key] && levelTableOpposite[key][value] ? levelTableOpposite[key][value] : value
}

// LIKELIHOOD
export function getLikelihoodLabel (likelihood) {
  return getLabel('likelihood', likelihood)
}

export function getLikelihoodLevel (likelihood) {
  return getLevel('likelihood', likelihood)
}

export function getLikelihoodIdentifier (likelihoodLevel) {
  return getIdentifierFromLevel('likelihood', likelihoodLevel)
}

// SEVERITY
export function getSeverityLabel (severity) {
  return getLabel('severity', severity)
}

export function getSeverityLevel (severity) {
  return getLevel('severity', severity)
}

// VULNERABILITY
export function getVulnerabilityLabel (vulnerability) {
  return getLabel('vulnerability', vulnerability)
}

export function getVulnerabilityLevel (vulnerability) {
  return getLevel('vulnerability', vulnerability)
}

// COPING CAPACITY
export function getCopingCapacityLabel (copingCapacity) {
  return getLabel('copingCapacity', copingCapacity)
}

export function getCopingCapacityLevel (copingCapacity) {
  return getLevel('copingCapacity', copingCapacity)
}

// IMPACT
export function getImpactLabel (impact) {
  return getLabel('impact', impact)
}

export function getImpactLevel (impact) {
  return getLevel('impact', impact)
}

export function getImpactIdentifier (impactLevel) {
  return getIdentifierFromLevel('impact', impactLevel)
}

// RISK LEVEL
export function getRiskLevelLabel (riskLevel) {
  return getLabel('riskLevel', riskLevel)
}

export function getRiskLevelLevel (riskLevel) {
  return getLevel('riskLevel', riskLevel)
}

export function getRiskLevelIdentifier (riskLevelLevel) {
  return getIdentifierFromLevel('riskLevel', riskLevelLevel)
}

// FREQUENCY
export function getFrequencyLabel (frequency) {
  return getLabel('frequency', frequency)
}

// CONFIDENCE LEVEL
export function getConfidenceLevelLabel (confidenceLevel) {
  return getLabel('confidenceLevel', confidenceLevel)
}

export function normalizeValue (value) {
  return Math.max(1, Math.min(5, Math.round(value)))
}

export function computeImpact (severity, vulnerability, copingCapacity) {
  if (typeof (severity) === 'string') {
    severity = getSeverityLevel(severity)
  }

  if (typeof (vulnerability) === 'string') {
    vulnerability = getVulnerabilityLevel(vulnerability)
  }

  if (typeof (copingCapacity) === 'string') {
    copingCapacity = getCopingCapacityLevel(copingCapacity)
  }

  severity = normalizeValue(severity)
  vulnerability = normalizeValue(vulnerability)
  copingCapacity = normalizeValue(copingCapacity)

  if (Number.isNaN(severity)) {
    console.error(`Severity was NaN`, severity, { severity, vulnerability, copingCapacity });
  }

  if (Number.isNaN(vulnerability)) {
    console.error(`Vulnerability was NaN`, vulnerability, { severity, vulnerability, copingCapacity });
  }

  if (Number.isNaN(copingCapacity)) {
    console.error(`CopingCapacity was NaN`, copingCapacity, { severity, vulnerability, copingCapacity });
  }

  return normalizeValue((severity + vulnerability + copingCapacity) / 3);
}

export function computeLikelihood (frequency, seasonality, likelihood) {
  if (typeof (likelihood) === 'string') {
    likelihood = getLikelihoodLevel(likelihood)
  }

  likelihood = normalizeValue(likelihood)

  return likelihood
}

export function computeRiskLevel (likelihood, impact) {
  if (typeof (likelihood) === 'string') {
    likelihood = getLikelihoodLevel(likelihood)
  }

  if (typeof (impact) === 'string') {
    impact = getImpactLevel(impact)
  }

  likelihood = normalizeValue(likelihood)
  impact = normalizeValue(impact)

  // Determination of Risk Level and Ranking
  const riskNumber = likelihood * impact

  if (riskNumber >= 17) {
    return 5 // Very high - 17 to 25
  } else if (riskNumber >= 12) {
    return 4 // High - 12 to 16
  } else if (riskNumber >= 7) {
    return 3 // Moderate - 7 to 11
  } else if (riskNumber >= 4) {
    return 2 // Low - 4 to 6
  }

  return 1 // Very low - 1 to 3
}

export function computeStarData(starData, clone = true) {
  const computedStarData = clone ? structuredClone(starData) : starData;

  for (const starDataSingle of computedStarData) {
    const likelihood = computeLikelihood(starDataSingle.frequency, starDataSingle.seasonality, starDataSingle.likelihood)
    const impact = computeImpact(starDataSingle.severity, starDataSingle.vulnerability, starDataSingle.copingCapacity)
    const riskLevel = computeRiskLevel(likelihood, impact)

    starDataSingle.computedLikelihoodRaw = likelihood;
    starDataSingle.computedImpactRaw = impact;
    starDataSingle.computedRiskLevelRaw = riskLevel;

    starDataSingle.computedLikelihood = getLikelihoodIdentifier(likelihood);
    starDataSingle.computedImpact = getImpactIdentifier(impact);
    starDataSingle.computedRiskLevel = getRiskLevelIdentifier(riskLevel);
  }

  return computedStarData;
}

export function getStarData () {
  return computeStarData(StarData);
}

export function getStarDataCorrect () {
  const starDataCorrect = structuredClone(StarData);

  for (const starDataSingle of starDataCorrect) {
    if (!starDataSingle.correctData || starDataSingle.correctData.length === 0) {
      continue;
    }

    for (const correctDataKey in starDataSingle.correctData) {
      starDataSingle[correctDataKey] = starDataSingle.correctData[correctDataKey];
    }

    console.log('starDataCorrect', starDataCorrect);
    console.log('starDataSingle.correctData', starDataSingle.correctData);
  }

  return computeStarData(starDataCorrect, false);
}
