<template>
  <div class="cost-ball">
    <div class="cost-ball-inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CostBall',
}
</script>

<style lang="scss" scoped>
$size: 50px;
$padding: 3px;

.cost-ball {
  background: linear-gradient(135deg, #81FF7C 0%, #012501 100%);

  width: $size;
  height: $size;
  border-radius: 50%;
  padding: $padding;
}

.cost-ball-inner {
  background: url("@/assets/textures/grunge-seamless-20-percent.png"), linear-gradient(180deg, #A0D530 0%, #0A443A 100%);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: #FFFFFF;

  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  line-height: $size - ($padding * 2);
  text-align: center;
}
</style>
