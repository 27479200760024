<template>
  <div class="toggle-slider-container d-flex">
    <div class="toggle-slider" @click="toggle" :class="'toggle-slider-' + (modelValue ? 'active' : 'inactiva')">
      <div class="toggle-slider-ball" />
    </div>
    <label v-if="label">{{ label }} ({{ modelValue ? 'ON' : 'OFF' }})</label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSlider',
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    }
  },
  emits: ['update:modelValue'],
  methods: {
    toggle() {
      const newToggleValue = !this.modelValue;

      this.$emit('update:modelValue', newToggleValue);
    }
  }
}
</script>
<style lang="scss" scoped>
$distance: 5px;
$ballSize: 22px;
$transitionDuration: 250ms;
$transitionFunction: cubic-bezier(0.68, -0.6, 0.32, 1.6);

.toggle-slider {
  border-radius: 20px;
  width: 70px;
  height: 30px;

  transition: $transitionDuration $transitionFunction;
  position: relative;
  padding: 3px 10px;
  cursor: pointer;
  border: 1px solid black;

  &.toggle-slider-active {
    background: black;

    .toggle-slider-ball {
      animation: blur-move $transitionDuration;
      left: calc(100% - $distance - $ballSize);
      background: white;
    }
  }

  .toggle-slider-ball {
    transition: $transitionDuration $transitionFunction;
    position: absolute;
    top: 50%;
    left: calc(0% + $distance);
    width: $ballSize;
    height: $ballSize;
    border-radius: 50%;
    background: black;
    transform: translateY(-50%);
  }
}

label {
  margin-top: 0px;
  margin-left: 10px;
  font-size: 16pt;
  font-weight: bold;
}

@keyframes blur-move {
  0% {
    filter: blur(0px);
  }

  50% {
    filter: blur(5px);
  }

  100% {
    filter: blur(0px);
  }
}
</style>
