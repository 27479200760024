import TutorialFrequency from '@/components/StarMinigame/tutorial/TutorialFrequency.vue'
import TutorialSeverity from '@/components/StarMinigame/tutorial/TutorialSeverity.vue'
import TutorialVulnerability from '@/components/StarMinigame/tutorial/TutorialVulnerability.vue'
import TutorialCopingCapacity from '@/components/StarMinigame/tutorial/TutorialCopingCapacity.vue'
import TutorialLikelihood from '@/components/StarMinigame/tutorial/TutorialLikelihood.vue'
import TutorialSeasonality from '@/components/StarMinigame/tutorial/TutorialSeasonality.vue'

import { i18n } from '@/lib/i18n/i18n'

const $t = i18n.global.t

export const TutorialIdentifiers = {
  FREQUENCY: 'FREQUENCY',
  SEASONALITY: 'SEASONALITY',
  LIKELIHOOD: 'LIKELIHOOD',
  SEVERITY: 'SEVERITY',
  VULNERABILITY: 'VULNERABILITY',
  COPING_CAPACITY: 'COPING_CAPACITY',
}

export const TutorialParts = {
  [TutorialIdentifiers.FREQUENCY]: {
    identifier: TutorialIdentifiers.FREQUENCY,
    ordinal: 1,
    header: $t('starMinigame.headerFrequency'),
    component: TutorialFrequency,
    highlightColumn: 'frequency',
  },
  [TutorialIdentifiers.SEASONALITY]: {
    identifier: TutorialIdentifiers.SEASONALITY,
    ordinal: 2,
    header: $t('starMinigame.headerSeasonality'),
    component: TutorialSeasonality,
    highlightColumn: 'seasonality',
  },
  [TutorialIdentifiers.LIKELIHOOD]: {
    identifier: TutorialIdentifiers.LIKELIHOOD,
    ordinal: 3,
    header: $t('starMinigame.headerLikelihood'),
    component: TutorialLikelihood,
    highlightColumn: 'likelihood',
  },
  [TutorialIdentifiers.SEVERITY]: {
    identifier: TutorialIdentifiers.SEVERITY,
    ordinal: 4,
    header: $t('starMinigame.headerSeverity'),
    component: TutorialSeverity,
    highlightColumn: 'severity',
  },
  [TutorialIdentifiers.VULNERABILITY]: {
    identifier: TutorialIdentifiers.VULNERABILITY,
    ordinal: 5,
    header: $t('starMinigame.headerVulnerability'),
    component: TutorialVulnerability,
    highlightColumn: 'vulnerability',
  },
  [TutorialIdentifiers.COPING_CAPACITY]: {
    identifier: TutorialIdentifiers.COPING_CAPACITY,
    ordinal: 6,
    header: $t('starMinigame.headerCopingCapacity'),
    component: TutorialCopingCapacity,
    highlightColumn: 'copingCapacity',
  },
}

export function getTutorialHeaderWithOrdinal (key) {
  const ordinal = TutorialParts[key].ordinal
  const header = TutorialParts[key].header

  return `${ordinal}. ${header}`
}
