<template>
  <div class="avatar-root">
    <player-character-plate
      :player-data="playerSelf"
      :waiting-for="waitingForPlayerCode === playerSelf.playerCode"
    />
  </div>

</template>

<script>
import CharacterThumbnail from '@/components/character/CharacterThumbnail.vue'
import RoleProgressBall from '@/components/character/RoleProgressBall.vue'
import { getRoleFromRoleIndex } from '@/utils/roleConverter'
import { mapGetters, mapState } from 'vuex'
import MicrophoneIcon from '@/components/Icon/MicrophoneIcon';
import CharacterPlate from '@/components/character/CharacterPlate.vue'
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'
import { PLAYER_TYPE_FACILITATOR } from '@/enum/PlayerType'

export default {
  name: 'PlayerAvatarCircle',
  components: { PlayerCharacterPlate, CharacterPlate, RoleProgressBall, CharacterThumbnail, MicrophoneIcon },
  props: {
  },
  data() {
    return {
      waitingForPlayerCode: null,
    };
  },
  computed: {
    ...mapGetters(['playerSelf']),
    ...mapState(['investmentCartCostRp', 'microphoneMuted', 'roles', 'rolePointsTotal', 'room']),
    rolePointsCalc() {
      return this.rolePoints - this.investmentCartCostRp;
    },
    role() {
      return this.roles[this.roleIndex];
    },
    max() {
      return 100;
    },

    playerJoinedCount() {
      let playerJoinedCount = 0;

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (player.sessionId && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          playerJoinedCount++;
        }
      }

      return playerJoinedCount;
    },

    playerVoteSkips() {
      const playerVoteSkips = [];

      if (this.playerSelf && this.playerSelf.sessionId) {
        playerVoteSkips.push(this.playerSelf.voteSkipTimer || this.playerSelf.isReady || this.playerSelf.voteSkipMeeting);
      }

      for (const otherPlayer of this.otherPlayers) {
        playerVoteSkips.push(otherPlayer.voteSkipTimer || otherPlayer.isReady || otherPlayer.voteSkipMeeting);
      }

      return playerVoteSkips;
    },

    otherPlayers() {
      const otherPlayers = [];

      for (const playerCode in this.room.players) {
        const player = this.room.players[playerCode];

        if (!player.isSelf && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          otherPlayers.push(player);
        }
      }

      return otherPlayers;
    },
  },
  watch: {
    playerVoteSkips() {
      this.recomputeWaitingForPlayerIndex();
    },
    playerJoinedCount() {
      this.recomputeWaitingForPlayerIndex();
    },
  },
  mounted() {
    this.recomputeWaitingForPlayerIndex();
  },
  methods: {
    recomputeWaitingForPlayerIndex() {
      let notSkipCount = 0;
      let playerCount = 0;

      let notSkipOtherPlayerCode;

      if (this.playerSelf) {
        if (this.playerSelf.sessionId) {
          playerCount++; // Surely?!

          if (!this.playerSelf.voteSkipTimer && !this.playerSelf.isReady && !this.playerSelf.voteSkipMeeting) {
            notSkipCount++;
            notSkipOtherPlayerCode = this.playerSelf.playerCode;
          }
        }
      }

      for (const otherPlayer of this.otherPlayers) {
        if (!otherPlayer.sessionId) {
          continue;
        }

        playerCount++;

        if (!otherPlayer.voteSkipTimer && !otherPlayer.isReady && !otherPlayer.voteSkipMeeting) {
          notSkipCount++;
          notSkipOtherPlayerCode = otherPlayer.playerCode;
        }
      }

      // One player needs to answer
      if (notSkipCount === 1 && notSkipOtherPlayerCode) {
        this.waitingForPlayerCode = notSkipOtherPlayerCode;
      } else {
        this.waitingForPlayerCode = null;
      }
    },
    onLocalMuteUpdated(e) {
      this.toggleMute();
    },
    toggleMute() {
      const newMuted = !this.microphoneMuted;

      this.$store.commit('microphoneMuted', newMuted);

      this.$voiceController.setLocalStreamMuted(newMuted);

      if (this.$gameClient.hasJoinedRoom()) {
        return this.$gameClient.playerUpdate('muted', newMuted);
      }
    }
  },
}

</script>

<style scoped>

.avatar-root {
  width: 281px;
  min-height: 200px;
}
</style>
