export const TYPE_BOX_ROUNDED = 'box-rounded';
export const TYPE_BOX = 'box';
export const TYPE_CIRCLE = 'circle';

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_FIT_CONTENT = 'fit-content';
export const SIZE_FULL = 'full';
