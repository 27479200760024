<template>
  <debriefing
    v-if="ready"
    :trust="trust"
    :health-security="healthSecurity"
    :collaboration="collaboration"
    :mg-success="mgSuccess"
    :jee-scores="jeeScores"
    :response-actions-taken="responseActionsTaken"
    :strategic-actions-taken="strategicActionsTaken"
    :learning-objective-chart-bars="learningObjectiveChartBars"
    :debriefing-title="decisionResult.title"
    :debriefing-text="decisionResult.text"
  />
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue'
import HazardIconData from '@/data/hazard-icon-data'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import EndGamePopupIntro from '@/components/EndGamePopup/EndGamePopupIntro/index.vue'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'
import EndGamePopupDecision from '@/components/EndGamePopup/EndGamePopupDecision/index.vue'
import HazardPopupFlagManagement from '@/components/HazardMeeting/HazardPopupFlagManagement/index.vue'

import { generatePlayersData } from '@/utils/playerDataGenerator'
import { DECISION_CANCEL, DECISION_DELAY, DECISION_HOLD, DECISION_RESTRICT, DecisionData } from '@/data/decision-data'
import NoDragImage from '@/components/common/NoDragImage.vue'
import DebriefingFinalScoreCard from '@/components/Debriefing/DebriefingFinalScoreCard/index.vue'
import Debriefing from '@/components/Debriefing/index.vue'
import {mapState} from 'vuex';
import {randomBetween} from '@/utils/randomUtil';
import {generateUsedActions} from '@/utils/generator';
import {objectToArray} from '@/utils/arrayUtil';

export default {
  name: 'DebriefingLive',
  components: {
    Debriefing,
    NoDragImage,
    EndGamePopupDecision,
    EndGamePopupIntro,
    DebriefingFinalScoreCard,
  },

  methods: {
    getImplementedActions(filteredActionType) {
      if (!this.room) {
        return [];
      }

      if (!this.room.implementedActions) {
        return [];
      }

      let implementedActions = objectToArray(this.room.implementedActions);

      if (implementedActions) {
        implementedActions = implementedActions.filter((action) => {
          return action.actionType === filteredActionType;
        });
      } else {
        implementedActions = [];
      }

      if (implementedActions && implementedActions.length) {
        for (const implementedAction of implementedActions) {
          const indicatorsImpacted = {};

          const jeeImpacts = objectToArray(implementedAction.jeeImpacts);

          if (jeeImpacts && implementedAction.jeeImpacts.length) {
            for (const jeeImpact of implementedAction.jeeImpacts) {
              indicatorsImpacted[jeeImpact.identifier] = indicatorsImpacted[jeeImpact.impact];
            }
          }

          implementedAction.indicatorsImpacted = indicatorsImpacted;

          if (!implementedAction.description) {
            implementedAction.description = implementedAction.text;
          }
        }
      }

      console.log('implementedAction', implementedActions);

      return implementedActions;
    },
  },
  computed: {
    ...mapState(['jeeCategories', 'room']),

    playerDecision() {
      return this.room.endGamePlayerDecision || null;
    },
    resultDecision() {
      return this.room.endGameResultDecision || null;
    },
    decisionData() {
      return DecisionData[this.playerDecision];
    },
    winningConditions() {
      return this.decisionData.winningConditions;
    },
    result() {
      return this.winningConditions.gameDecisionResults[this.resultDecision];
    },
    decisionResult() {
      return this.winningConditions[this.result];
    },

    ready() {
      return Object.keys(this.jeeScores).length > 0;
    },
    jeeScores() {
      if (!this.room) {
        return {};
      }

      if (!this.room.jeeScores) {
        return {};
      }

      return this.room.jeeScores;
    },
    strategicActionsTaken() {
      return this.getImplementedActions('strategic');
    },
    responseActionsTaken() {
      return this.getImplementedActions('hazard');
    },
    /*learningObjectiveChartBars() {
      if (!this.room) {
        return false;
      }

      return this.room.jeeScores;
    },*/
    trust() {
      if (!this.room) {
        return 0;
      }

      if (!this.room.trust) {
        return 0;
      }

      return this.room.trust;
    },
    collaboration() {
      if (!this.room) {
        return 0;
      }

      if (!this.room.collaboration) {
        return 0;
      }

      return this.room.collaboration;
    },
    healthSecurity() {
      if (!this.room) {
        return 0;
      }

      if (!this.room.healthSecurity) {
        return 0;
      }

      return this.room.healthSecurity;
    },
    mgSuccess() {
      if (!this.room) {
        return 0;
      }

      if (!this.room.mgEventSuccess) {
        return 0;
      }

      return this.room.mgEventSuccess;
    },
    learningObjectiveChartBars() {
      if (!this.room) {
        return [];
      }

      if (!this.room.implementedActions) {
        return [];
      }

      const learningObjectiveLabelsAssoc = this.room.learningObjectiveLabels;

      const learningObjectives = {};

      for (let key in learningObjectiveLabelsAssoc) {
        const label = learningObjectiveLabelsAssoc[key];

        learningObjectives[key] = {
          label: label,

          hazardActionCount: 0,
          strategicActionCount: 0,
        };
      }

      const implementedActions = objectToArray(this.room.implementedActions) || [];

      if (implementedActions && implementedActions.length) {
        for (const implementedAction of implementedActions) {
          let countKey;

          if (implementedAction.actionType === 'strategic') {
            countKey = 'strategicActionCount';
          } else if (implementedAction.actionType === 'hazard') {
            countKey = 'hazardActionCount';
          }

          if (countKey) {
            const loId = implementedAction.learningObjectiveId.toString();

            if (learningObjectives.hasOwnProperty(loId)) {
              learningObjectives[loId][countKey] += 1;
            }
          }
        }
      }

      const learningObjectivesArray = [];

      for (const key in learningObjectives) {
        learningObjectivesArray.push(learningObjectives[key]);
      }

      return learningObjectivesArray;
    },
  },
}
</script>
