<template>
  <volume-slider
    :min="0"
    :max="100"
    v-model.number="setVolume"
    :current-volume="currentVolume"
  />
</template>

<script>
import slider from "vue3-slider"
import UiSlider from '@/components/player-ui/UiSlider.vue'
import VolumeSlider from '@/components/player-ui/VolumeSlider.vue'
import { EVENT_FREQUENCY_CHANGED } from '@/plugins/VoiceController/events/FrequencyChangedEvent'
import { LOCAL_STREAM_MUTED_CHANGED } from '@/plugins/VoiceController/events/LocalStreamMutedChangedEvent'

export default {
  name: 'VolumeSliderOutput',
  components: {
    VolumeSlider,
  },
  data() {
    return {
      setVolume: 100,
      currentVolume: 0,

      updatedVolumeThisTick: false,
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$voiceController.addEventListener(EVENT_FREQUENCY_CHANGED, this.onFrequencyChanged);

    this.updateCurrentVolumeFromParticipants();
    this.updateVolumeFromSetVolume();
  },
  beforeUnmount () {
    this.$voiceController.removeEventListener(EVENT_FREQUENCY_CHANGED, this.onFrequencyChanged);
  },
  watch: {
    setVolume() {
      this.updateVolumeFromSetVolume();
    },
  },
  methods: {
    updateVolumeFromSetVolume() {
      // TODO: Reactivate
      //this.$voiceController.setAllParticipantVolume(this.setVolume);
    },
    updateCurrentVolumeFromParticipants() {
      if (this.updatedVolumeThisTick) {
        return;
      }

      this.updatedVolumeThisTick = true;

      const participantIds = Object.keys(this.$voiceController.participants);

      let maxFrequency = 0;

      for (const participantId of participantIds) {
        if (participantId === 'self') {
          // We cannot hear ourselves
          continue;
        }

        const participant = this.$voiceController.participants[participantId];

        if (participant.volume <= 0) {
          // We obviously can't hear someone who has no volume (aka. muted)
          continue;
        }

        maxFrequency = Math.max(maxFrequency, participant.frequencyAverage);
      }

      this.currentVolume = Math.min(100, Math.max(0, maxFrequency));

      this.$nextTick(() => {
        this.updatedVolumeThisTick = false;
      })
    },
    onFrequencyChanged(e) {
      if (e.uid === 'self') {
        return; // Ignore
      }

      this.updateCurrentVolumeFromParticipants();
    }
  },
}
</script>
<style lang="scss">
.epic-ui-slider {
  &, .track, .track-filled {
    border-radius: 7px !important;
    height: 19px !important;
  }

  .track {
    // Default fill
    background: #D9D9D9 !important;
  }

  .track-filled {
    // Active fill
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%), #D9D9D9 !important;
  }

  .handle {
    background: linear-gradient(180deg, #38708F 0%, #051224 84.07%) !important;
    mix-blend-mode: normal !important;
    box-shadow: 0px 4.2459px 4.2459px rgba(0, 0, 0, 0.52), inset 0px 1.06148px 1.06148px rgba(255, 255, 255, 0.5), inset 0px -3.18443px 2.12295px rgba(0, 0, 0, 0.51) !important;
    border-radius: 2.12295px !important;
    height: 33px !important;
    width: 12px !important;
    top: -7.5px !important;
    transform: translateX(6.5px) !important;
  }
}
</style>
