<template>
  <div class="lobby-popup-dev">
    <lobby-popup
      @close="onClose"
    />
  </div>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import LobbyPopup from './LobbyPopup'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin.vue'
import EventTicks from '@/components/DevComponent/EventTicks.vue'

export default {
  name: 'LobbyPopupDev',
  components: {
    LobbyPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: ['close'],
    }
  },
  methods: {
    onClose() {
      this.triggerEvent('close')
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/variables.scss';

  .lobby-popup-dev {
    border: 1px solid black;
    margin: 0 auto;
    width: 100%;
    height: 900px;
    max-height: $appHeight * 0.80; //80vh;
  }
</style>
