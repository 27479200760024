import {
  TutorialHighlightOverlayCloseEvent,
  TutorialHighlightOverlayPopEvent,
  TutorialHighlightOverlayResetEvent
} from './events'

import {
  SHAPE_SQUARE,
  SHAPE_ROUNDED,
  SHAPE_CIRCLE,
  SHAPE_SLIGHTLY_ROUNDED,
  HIGHLIGHT_TYPE_SELECTOR,
  HIGHLIGHT_TYPE_ID,
  HIGHLIGHT_TYPE_ELEMENT, POPUP_ANCHOR_AUTO,
} from './enums'
import { PopBuilder } from '@/plugins/TutorialHighlightOverlay/PopBuilder'

export class TutorialHighlightOverlayController extends EventTarget {
  lastPopEvent = null;

  constructor () {
    super();

    this.addEventListener('pop', (e) => {
      this.lastPopEvent = e;
    })
  }

  reset(callRejection = false) {
    this.dispatchEvent(new TutorialHighlightOverlayResetEvent(callRejection, this.lastPopEvent));

    this.lastPopEvent = null;
  }

  close() {
    this.dispatchEvent(new TutorialHighlightOverlayCloseEvent(this.lastPopEvent));

    this.lastPopEvent = null;
  }

  pop(
    text,
    highlight,
    highlightType = HIGHLIGHT_TYPE_SELECTOR,
    shape = SHAPE_SQUARE,
    offset = 0,
    popupAnchor = POPUP_ANCHOR_AUTO,
    clickThrough = false,
    animationLength = undefined,
    autoBoundDistance = undefined,
    passOptions = undefined,
  ) {
    return new Promise((resolve, reject) => {
      this.addEventListener('close', () => {
        resolve();
      }, {
        once: true,
      });

      this.dispatchEvent(new TutorialHighlightOverlayPopEvent(
        text,
        highlight,
        highlightType,
        shape,
        offset,
        popupAnchor,
        clickThrough,
        animationLength,
        autoBoundDistance,
        passOptions
      ));
    });
  }

  build(options = {}) {
    return new PopBuilder(this, options);
  }

  async popBuilderArray(builderArray) {
    for (const builderItem of builderArray) {
      await builderItem.pop();
    }
  }
}
