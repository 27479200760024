<template>
  <ui-timer :seconds-left="secondsLeft" :class="disabled ? 'ui-timer-disabled' : ''">
    <button class="btn-ui-timer" :class="'btn-ui-timer-' + (modelValue ? 'active' : 'inactive')" @click="toggle">
      {{ computedButtonText }}
    </button>
    <div class="other-answers d-flex justify-content-between">
      <ui-timer-ready-check v-for="n in 5" :model-value="n <= skipCount" :active="n <= playerCount" />
    </div>
  </ui-timer>
</template>

<script>
import LongButton from '@/components/common/LongButton.vue'
import UiTimer from '@/components/player-ui/UiTimer/UiTimer.vue'
import AppButton from '@/components/common/buttons/AppButton.vue';
import { encodeHtml } from '@/utils/stringUtil'
import {mapGetters} from 'vuex';
import UiTimerReadyCheck from '@/components/player-ui/UiTimer/UiTimerReadyCheck.vue';

export default {
  name: 'UiTimerToggle',
  components: {UiTimerReadyCheck, AppButton, LongButton, UiTimer },
  props: {
    secondsLeft: {
      type: Number,
      required: true,
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'green',
    },
    buttonText: {
      type: String,
      required: false,
    },
    buttonHtml: {
      type: String,
      required: false,
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    playerCount: {
      type: Number,
      required: false,
      default: 1,
    },
    skipCount: {
      type: Number,
      required: false,
      default: 0,
    }
  },

  computed: {
    ...mapGetters(['playerType', 'playerIsFacilitator']),

    computedDisabled() {
      if (this.disabled) {
        return true;
      }

      if (this.playerIsFacilitator) {
        return true;
      }

      return false;
    },
    buttonState() {
      if (!this.modelValue) {
        return null;
      }

      return 'active';
    },
    computedButtonHtml() {
      if (this.buttonHtml) {
        return this.buttonHtml;
      }

      return encodeHtml(this.buttonText);
    },
    computedButtonText() {
      if (this.modelValue) {
        return 'READY';
      } else {
        return 'DONE';
      }
    }
  },

  emits: ['update:modelValue'],

  methods: {
    toggle() {
      if (this.computedDisabled) {
        return;
      }

      this.$emit('update:modelValue', !this.modelValue);
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-ui-timer {
  background: url("@/assets/ui/timer/timer-button-up.png");
  width: 244px;
  height: 95px;
  color: white;
  padding-left: 10px;
  padding-right: 80px;
  text-align: center;
  font-size: 28pt;
  font-weight: 700;
  text-shadow: 0px 1.0712361335754395px 2.142472267150879px rgba(0, 0, 0, 0.40);
  letter-spacing: 2px;
  line-height: 85px;

  &:hover {
    background: url("@/assets/ui/timer/timer-button-hover.png");
  }

  &, &:focus, &:active {
    outline: 0;
    border: 0;
  }



  &.btn-ui-timer-active {
    background: url("@/assets/ui/timer/timer-button-down.png");
    letter-spacing: 1.2px;

  }
}

.other-answers {
  height: 33px;
  padding: 0 20px;
  position: relative;
  top: -3px;
}
</style>
