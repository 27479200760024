<script>
import { randomBetween } from '@/utils/randomUtil'
import { removeArrayItemByValue } from '@/utils/arrayUtil'

export default {
  name: 'BlingLayer',
  data() {
    return {
      transitions: {},
      dynamicStyles: {},

      animatingItemKeys: [],
    };
  },
  methods: {
    randomTransitionDuration(min, max) {
      return `${Math.round(randomBetween(min, max))}ms linear`;
    },
    startItemTransition(itemKey, group) {
      //console.log('itemKey', {  itemKey, group });

      if (!this.transitions[itemKey]) {
        throw new Error(`Could not find item key ${itemKey}`);
      }

      if (this.animatingItemKeys.includes(itemKey)) {
        console.warn(`Item key ${itemKey} was already animating it - not animating it again until we are done`);

        return;
      }

      const transition = this.transitions[itemKey];

      const timeoutInitialWait = transition.timeoutInitialWait || randomBetween(500, 5000);

      this.dynamicStyles[itemKey].transition = '0ms linear';

      const itemScale = 0.7;

      if (transition.flipX) {
        this.dynamicStyles[itemKey].transform = `scale(-${itemScale}, ${itemScale})`;
      } else {
        this.dynamicStyles[itemKey].transform = `scale(${itemScale}, ${itemScale})`;
      }

      this.dynamicStyles[itemKey].top = transition.source.top;
      this.dynamicStyles[itemKey].left = transition.source.left;
      this.dynamicStyles[itemKey].display = 'none';

      requestAnimationFrame(() => {
        setTimeout(() => {
          this.dynamicStyles[itemKey].transition = transition.transition;
          this.dynamicStyles[itemKey].display = 'block';

          setTimeout(() => {
            this.animatingItemKeys.push(itemKey);

            this.dynamicStyles[itemKey].top = transition.destination.top;
            this.dynamicStyles[itemKey].left = transition.destination.left;

          }, timeoutInitialWait);
        }, 1);
      });
    },
  }
}
</script>
