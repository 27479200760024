import { timeoutPromise } from '@/utils/promiseUtil'
import { AlertPopEvent } from '@/plugins/AlertPop/AlertPopEvent'
import { AlertCloseEvent } from '@/plugins/AlertPop/AlertCloseEvent'
import {
  DEFAULT_TEXT_CONFIRM,
  DEFAULT_TEXT_ERROR,
  DEFAULT_TEXT_INFO,
  DEFAULT_TEXT_OK_BUTTON,
  DEFAULT_TEXT_WARNING
} from '@/plugins/AlertPop/enums';
import { AlertResetEvent } from '@/plugins/AlertPop/AlertResetEvent'

export class AlertPopController extends EventTarget {
  reset(callRejection = false) {
    this.dispatchEvent(new AlertResetEvent(callRejection));
  }

  async pop(title, body, popData = {}, type = 'pop') {
    return new Promise((resolve, reject) => {
      this.addEventListener('close', (e) => {
        resolve(e?.closeResult);
      }, {
        once: true,
      });

      this.dispatchEvent(new AlertPopEvent(title, body, popData, type));
    });
  }

  async info(body, title = DEFAULT_TEXT_INFO, popData = {}) {
    return this.pop(title || DEFAULT_TEXT_INFO, body, popData, 'info');
  }

  async warning(body, title = DEFAULT_TEXT_WARNING, popData = {}) {
    return this.pop(title || DEFAULT_TEXT_WARNING, body, popData, 'warning');
  }

  async error(body, title = DEFAULT_TEXT_ERROR, popData = {}) {
    return this.pop(title || DEFAULT_TEXT_ERROR, body, popData, 'error');
  }

  async confirm(body, title = DEFAULT_TEXT_CONFIRM, popData = {}) {
    return this.pop(title || DEFAULT_TEXT_CONFIRM, body, popData, 'confirm');
  }

  close(closeResult = undefined) {
    this.dispatchEvent(new AlertCloseEvent(closeResult));
  }
}
