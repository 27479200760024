export default {
  "sessionId": "tX57sRmZ9",
  "joined": false,
  "playerCode": "111111",
  "voiceSocketId": "6caLHfn14bgoazzSAAAB",
  "muted": false,
  "playerType": 1,
  "isReady": false,
  "forceAiVote": false,
  "characterIndex": 3,
  "roleIndex": -1,
  "rolePoints": 15,
  "rolePointsTotalInvestment": 0,
  "activeMeetingInvestments": [],
  "activeMeetingTabIndex": 0,
  "voteSkipMeeting": false,
  "voteStartMeeting": false,
  "activeHazardMeetingState": 1,
  playerIndex: 0,
}
