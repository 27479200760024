<template>
  <div class="room-form">
    <div class="title-line">
      <h3 v-if="this.room === null">Create Room</h3>
      <h3 v-else>Edit Room</h3>
    </div>

    <div class="input-row">
      <label for="name">Name*</label>
      <input type="text" id="name" v-model="roomName" required>
    </div>

    <div class="input-row">
      <label for="event">Event*</label>
      <bootstrap-select :options="eventOptions" v-model.number="event"/>
    </div>

    <div class="input-row">
      <label for="narrative">Narrative*</label>
      <bootstrap-select :options="narrativeOptions" v-model.number="narrative"/>
    </div>

    <div class="input-row">
      <label for="narrative">Facilitator*</label>
      <bootstrap-select :options="facilitatorOptions" v-model.number="facilitator"/>
    </div>

    <div class="input-row">
      <label><input type="checkbox" v-model="scheduledAccessEnable" /> Enable scheduled access</label>
    </div>

    <div class="input-row" v-if="scheduledAccessEnable">
      <label for="scheduled-access-start">Scheduled access*</label>
      <VueDatePicker
        class="form-control"
        name="scheduled-access-start"
        v-model="scheduledAccessRange"
        range
        :time-picker="false"
        :auto-apply="true"
        :clearable="true"
        :required="true"
        format="yyyy-MM-dd"
      />
    </div>


    <div class="player-box">
      <div class="mb-3">
        <button class="btn btn-success" :disabled="!canAddPlayer" @click="onClickPlayerAdd">Add Player</button>
        <button class="btn btn-success" :disabled="!canRemovePlayer" @click="onClickPlayerRemove">Remove Player
        </button>
      </div>

      <div v-for="(player, index) in players" :key="index" class="mb-3 player-container">
        <label :for="'playerEmail' + index" class="form-label">Player #{{ index + 1 }}</label><br />
        <router-link class='lobby-link' v-if="player.code !==''" :to="'/lobby?u=' + player.code">
          {{ getPlayerUrl(player) }}
        </router-link>
      </div>

      <div>
        <p>When creating players each player will get a unique link that they need to click on.</p>
        <p>The facilitator needs to provide these links to the players manually.</p>
      </div>

    </div>

    <div>
      <button class="btn btn-success" @click="onClickSave" :disabled="!canSave">Save</button>
      <button class="btn btn-danger" @click="onClickCancel">Cancel</button>
    </div>

  </div>
</template>

<script>
import Lightbox from '@/components/common/Lightbox.vue'
import ErrorPopup from '@/components/ErrorPopup/index.vue'
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import ToggleSlider from '@/components/common/ToggleSlider.vue'
import {mapState} from 'vuex'
import Sidebar from '@/components/Admin/Sidebar.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import {getCurrentBaseUrl} from '@/utils/stringUtil';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'RoomForm',
  components: {
    BootstrapSelect,
    Sidebar,
    ToggleSlider,
    AppButton,
    CloseButton,
    LobbyPopupTemplate,
    ErrorPopup,
    Lightbox,
    VueDatePicker,
  },
  data() {
    const minPlayers = 2;
    const players = [];

    for (let i = 0; i < minPlayers; i++) {
      players.push({
        email: '',
        code: '',
      });
    }

    return {
      scheduledAccessEnable: false,
      scheduledAccessRange: null,

      enableScheduledAccess: false,
      narrativeOptions: [],
      facilitatorOptions: [],
      eventOptions: [],
      roomName: '',
      narrative: 0,
      facilitator: 0,
      event: 0,
      room: null,
      minPlayers: minPlayers,
      players: players,
    }
  },
  watch: {
    scheduledAccessEnable() {
      if (this.scheduledAccessEnable) {
        if (!this.scheduledAccessRange) {
          this.scheduledAccessRange = [undefined, undefined];
        }
      } else {
        this.scheduledAccessRange = [undefined, undefined];
      }
    }
  },
  computed: {
    ...mapState(['signedInUser']),
    canSave() {
      if (!this.roomName) {
        return false;
      }

      if (!this.narrative) {
        return false;
      }

      if (!this.event) {
        return false;
      }

      if (!this.facilitator) {
        return false;
      }

      if (this.scheduledAccessEnable) {
        if (!this.scheduledAccessRange || !this.scheduledAccessRange[0] || !this.scheduledAccessRange[1]) {
          return false;
        }
      }

      return true;
    },
    canAddPlayer() {
      return this.players.length < 5
    },
    canRemovePlayer() {
      return this.players.length > this.minPlayers
    },
  },
  async mounted() {
    if (!this.signedInUser) {
      this.$router.push({name: 'BackendIndex'});

      return;
    }

    /*if (this.signedInUser.role === 'facilitator') {
      this.$router.push({name: 'RoomIndex'});

      return
    }*/

    const facilitators = await this.$apiClient.backend.getUsers();
    const events = await this.$apiClient.backend.getEvents();
    const narratives = await this.$apiClient.backend.getNarratives();

    for (const narrative of narratives) {
      this.narrativeOptions.push({
        value: narrative.id,
        label: narrative.name
      });
    }

    for (const event of events) {
      this.eventOptions.push({
        value: event.id,
        label: event.label
      })
    }

    const signedInRole = this.signedInUser.role;

    this.facilitator = this.signedInUser.id;

    if (signedInRole === 'facilitator') {
      for (const facilitator of facilitators) {
        if (this.signedInUser.id !== facilitator.id) {
          continue;
        }

        this.facilitatorOptions.push({
          value: facilitator.id,
          label: `${facilitator.role}: ${facilitator.username}`
        })
      }
    } else {
      for (const facilitator of facilitators) {
        if (signedInRole === 'admin' && facilitator.role === 'rootadmin') {
          continue;
        }

        this.facilitatorOptions.push({
          value: facilitator.id,
          label: `${facilitator.role}: ${facilitator.username}`
        })
      }
    }

    this.narrative = this.narrativeOptions[0].value
    this.event = this.eventOptions[0].value
    this.facilitator = this.facilitatorOptions[0].value

    if (this.$route.params.id) {
      this.room = await this.$apiClient.backend.getRoom(this.$route.params.id);

      if (this.room.players.length > 0) {
        this.players = []

        for (const player of this.room.players) {
          this.players.push({
            email: undefined, // `Player: ${player.code}`,
            code: player.code
          })
        }

        this.minPlayers = this.players.length
      }

      console.log('this.room', this.room);

      this.roomName = this.room.name
      this.narrative = this.room.narrativeId
      this.event = this.room.eventId;
      this.facilitator = this.room.facilitatorId;

      if (this.room.scheduledAccessStart && this.room.scheduledAccessEnd) {
        this.scheduledAccessRange = [
          this.parseDate(this.room.scheduledAccessStart),
          this.parseDate(this.room.scheduledAccessEnd)
        ];

        this.scheduledAccessEnable = true;
      } else {
        this.scheduledAccessRange = [undefined, undefined];

        this.scheduledAccessEnable = false;
      }
    }

    /*if (this.$route.params.id != null) {
      const event = await this.$apiClient.backend.getEvent(this.$route.params.id);

      this.narrative = event.defaultNarrative;
      this.facilitator = event.defaultFacilitator;
      this.event = event.id;
    }*/
  },
  methods: {
    getPlayerUrl(player) {
      return `${getCurrentBaseUrl()}/#/lobby?u=${player.code}`;
    },
    onClickCancel() {
      this.goBack()
    },
    onClickPlayerAdd() {
      this.players.push({
        email: '',
        code: ''
      })
    },
    onClickPlayerRemove() {
      this.players.splice(this.players.length - 1, 1)
    },
    parseDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);

      return new Date(year, month - 1, day);
    },
    formatDate(date) {
      if (!date) {
        return undefined;
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-indexed, so add 1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    async onClickSave() {
      let scheduledAccessRange = null;

      if (this.scheduledAccessEnable) {
        let scheduledAccessStart = undefined;
        let scheduledAccessEnd = undefined;

        if (this.scheduledAccessRange[0]) {
          scheduledAccessStart = this.formatDate(this.scheduledAccessRange[0]);
        }

        if (this.scheduledAccessRange[1]) {
          scheduledAccessEnd = this.formatDate(this.scheduledAccessRange[1]);
        }

        if (scheduledAccessStart && scheduledAccessEnd) {
          scheduledAccessRange = [scheduledAccessStart, scheduledAccessEnd];
        }
      }

      if (this.room) {
        const newPlayers = this.players.filter(pl => pl.code === '');

        await this.$apiClient.backend.updateRoom(this.room.id, this.roomName, this.narrative, this.facilitator, this.event, JSON.stringify(newPlayers), scheduledAccessRange);
      } else {
        await this.$apiClient.backend.createRoom(this.roomName, this.narrative, this.facilitator, this.event, JSON.stringify(this.players), scheduledAccessRange);
      }

      this.goBack()
    },
    goBack() {
      if (this.room && this.room.id) {
        this.$router.push({name: 'EventIndex'});
      } else {
        this.$router.push({name: 'RoomIndex'});
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.backend-container {
  margin: 0 auto;

  .btn {
    margin-left: .5em;
    height: 2.2rem;
    padding: .4em 1em;
  }

  select, input[type="text"] {
    width: 100%;
  }

  .input-row {
    margin-bottom: 2em;
    text-align: left;
  }

  .room-form {
    width: 50%;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5 {
    text-align: center;
  }

  .player-box {
    padding: 2em;
    margin: 1em;
    border: 1px solid lightgrey;
    border-radius: 10px;
  }

  .lobby-link {
    font-size: .8rem;
  }

}

h1, h2, h3, h4, h5 {
  text-align: left;
}

.player-container {
  text-align: left;
  border-bottom: 1px solid #333333;
  padding: 0 10px 10px 10px;
}
</style>
