<template>
  <div class="debriefing-indicator-group">
    <h2>{{ name }}. {{ description }}</h2>
    <div class="debriefing-indicator-group-content">
      <div class="row">
        <div class="col-3">
          <wheel-of-evaluation :value="evaluation"/>
        </div>
        <div class="col-9 indicator-container">
          <div class="d-flex gap-4 justify-content-start flex-wrap">
            <debriefing-indicator
              v-for="indicator of indicators"
              :identifier="indicator.identifier"
              :name="indicator.name"
              :description="indicator.description"
              :jee-scores="jeeScores"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WheelOfEvaluation from '@/components/Debriefing/WheelOfEvaluation/index.vue'
import DebriefingIndicator from '@/components/Debriefing/DebriefingIndicator/DebriefingIndicator.vue';
import {getCapacityLevelFromJeeScore} from '@/components/dashboard/CapacityLevelUtil';

export default {
  name: 'DebriefingIndicatorGroup',
  components: {WheelOfEvaluation, DebriefingIndicator},
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    indicators: {
      type: Array,
      required: true,
    },
    jeeScores: {
      type: Object,
      required: true,
    },
  },
  computed: {
    evaluation() {
      let totalJeeScore = 0;
      let totalJeeCount = 0;

      for (const indicator of this.indicators) {
        const jeeScore = this.jeeScores[indicator.identifier];

        totalJeeScore += jeeScore;
        totalJeeCount += 1;
      }

      return getCapacityLevelFromJeeScore(totalJeeScore / totalJeeCount);
    },
  },
}
</script>

<style lang="scss" scoped>
.debriefing-indicator-group {
  border-radius: 5px;
  background-image: url('@/assets/textures/grunge-seamless-20-percent.png'), linear-gradient(180deg, rgba(#000, .3) 0%, rgba(#FFF, .3) 100%);
  background-size: 30px 30px, auto;
  background-repeat: repeat, no-repeat;

  box-shadow: 4px 0px 3px 0px rgba(239, 239, 239, 0.25) inset, -4px 2px 3px 0px rgba(255, 255, 255, 0.25) inset, 0px -4px 2px 1px #071C27 inset, 0px 1px 5px 2px rgba(0, 0, 0, 0.50);
  width: 100%;
  min-height: 350px;

  h2 {
    text-align: left;
    background: rgba(#00255A, .20);
    padding: 8px 10px 4px 10px;
    text-transform: uppercase;
    font-size: 15pt;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }
}

.debriefing-indicator-group-content {
  padding: 20px;
}

.indicator-container {
  padding-left: 55px;
}
</style>
