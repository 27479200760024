<template>
  <hazard-popup-results
    :invested-actions="investedActions"

    @click:continue="onContinue"
  />

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import HazardPopupResults from '@/components/HazardMeeting/HazardPopupResults/index.vue'
import { randomBetween } from '@/utils/randomUtil'

export default {
  name: 'HazardPopupResultsDev',
  components: {
    HazardPopupResults,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    const investedActions = [];

    /*for (let i = 0; i < 10; i++) {
      const actionId = i + 1;

      let text = '';

      for (let k = 0, len = i + 1; k < len; k++) {
        if (k % 2 === 0) {
          text += 'Report to national level'
        } else {
          text += ' and notify WHO through IHR NFP';
        }
      }

      const action = {
        id: actionId,
        name: `Action ${actionId}`,
        text: text,
        cost: Math.round(randomBetween(3, i * 4)),
      };

      investedActions.push(action);
    }*/

    return {
      events: ['continue'],

      investedActions: investedActions,
    }
  },
  methods: {
    onContinue() {
      this.triggerEvent('continue')
    },
  },
}
</script>

<style lang="scss" scoped>
.hazard-popup-Results {
  margin: 0 auto;
  transform: scale(70%);
}
</style>
