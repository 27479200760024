export const USER_MEDIA_ERROR = 'userMediaError';

export class UserMediaError extends Event {
  error;

  constructor (error) {
    super(USER_MEDIA_ERROR);

    this.error = error;
  }
}
