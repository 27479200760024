import { isPrimitive } from '@/utils/isPrimitive'
import { getConsole, getMockConsole } from '@/utils/getConsole'
import {PLAYER_TYPE_FACILITATOR} from '@/enum/PlayerType';

const useConsole = false
const colyseusSyncerConsole = useConsole ? getConsole('RoomStore', '#55AAAA') : getMockConsole()

function doPrintKeyPartsToConsole(keyParts) {
  if (keyParts.length < 2) {
    return true;
  } else {
    return !(keyParts[0] === 'room' && keyParts[1] === 'timers');
  }
}
function iterateStateKeys (state, keyParts, endOffset = 0, createIfNotExist = true, printConsole = true) {
  endOffset = Math.abs(endOffset)

  const keyPartsLengthMinusEndOffset = keyParts ? keyParts.length - endOffset : 0

  if (printConsole) {
    colyseusSyncerConsole.log('state.pre', {
      state,
      keyParts,
      endOffset
    })
  }

  if (keyPartsLengthMinusEndOffset >= 0) {
    // Iterate through every keyPart except for the last one
    for (let i = 0; i < keyPartsLengthMinusEndOffset; i++) {
      if (printConsole) {
        colyseusSyncerConsole.log('state.pre.' + i, state, keyParts[i])
      }

      const stateKeys = Object.keys(state)

      if (!stateKeys.includes(keyParts[i]) && createIfNotExist) {
        if (printConsole) {
          colyseusSyncerConsole.log('Creating ', keyParts[i], 'as it did not exist')
        }

        state[keyParts[i]] = {}
      }

      state = state[keyParts[i]]

      if (printConsole) {
        colyseusSyncerConsole.log('state.post.' + i, state, keyParts[i])
      }
    }
  }

  if (printConsole) {
    colyseusSyncerConsole.log('state.post', {
      state,
      keyParts,
      endOffset
    })
  }

  return state
}

function setState (state, {
  keyParts,
  value,
  type,
  forcePrimitive,
  printConsole = true,
}) {
  if (printConsole) {
    colyseusSyncerConsole.log('room.setState', {
      state,
      keyParts,
      value,
      type,
      forcePrimitive,
    })
  }

  if (isPrimitive(value) || forcePrimitive) {
    // Primitive value, let's just set this single one
    const newState = iterateStateKeys(state, keyParts, -1, true, printConsole)

    if (!newState) {
      if (printConsole) {
        colyseusSyncerConsole.log('iterateStateKeys', {
          newState,
          state,
          keyParts,
          keyPartsMinusOne: keyParts[keyParts.length - 1],
        })
      }
    } else {
      state = newState
    }

    state[keyParts[keyParts.length - 1]] = value
  } else {
    // Value is an object or an array, iterate through and set sub values

    const newState = iterateStateKeys(state, keyParts, 0, true, printConsole)

    if (!newState) {
      if (printConsole) {
        colyseusSyncerConsole.log('state not found', keyParts)
      }

      state = iterateStateKeys(state, keyParts, -1, true, printConsole)

      if (printConsole) {
        colyseusSyncerConsole.log('creating state ')
      }

      state[keyParts[keyParts.length - 1]] = value
    } else {
      for (const field in value) {
        newState[field] = value[field]

        //console.log('state', field, state, fieldValue, keyParts.concat(field));
      }
    }
  }
}

export default {
  state: {

    room: {
    }
  },
  mutations: {
    roomSetPlayerLocalData (rootState, {
      playerUid,
      data
    }) {
      const player = rootState.room.players[playerUid]

      if (!player) {
        throw new Error(`Could not find player by id "${playerUid}"`)
      }

      /*console.log({
        player,
        playerUid,
        data,
      });*/

      for (const key in data) {
        player[key] = data[key]
      }

      return player
    },
    roomStateSet: (rootState, data) => {
      const {
        keyParts,
        value,
        type
      } = data

      const printConsole = doPrintKeyPartsToConsole(keyParts);

      if (printConsole) {
        colyseusSyncerConsole.log('roomStateSet', {
          data,
          rootState,
          keyParts,
          value,
          type
        })
      }

      setState(rootState, {
        keyParts,
        value,
        type,
        forcePrimitive: false,
        printConsole,
      })
    },
    roomStateAdd: (rootState, data) => {
      const {
        keyParts,
        value,
        type
      } = data

      const printConsole = doPrintKeyPartsToConsole(keyParts);

      if (printConsole) {
        colyseusSyncerConsole.log('roomStateAdd', {
          keyParts,
          value,
          type
        })
      }

      //if (type === MUTATION_VALUE_TYPE_MAP) {
      setState(rootState, {
        keyParts,
        value,
        type,
        forcePrimitive: true,
        printConsole,
      })
      //}
    },

    roomStateReset: (rootState) => {
      console.log('Resetting store');

      rootState.room = {};
    },

    roomStateRemove: (rootState, data) => {
      const {
        keyParts,
        value,
        type
      } = data

      const printConsole = doPrintKeyPartsToConsole(keyParts);

      colyseusSyncerConsole.log('roomStateRemove', {
        keyParts,
        value,
        type,
        forcePrimitive: false,
        printConsole,
      })

      //if (type === MUTATION_VALUE_TYPE_MAP) {
      const state = iterateStateKeys(rootState, keyParts, -1)

      delete state[keyParts[keyParts.length - 1]]
      //}
    },
  },
  getters: {
    room (state, getters) {
      return state.room
    },

    playerJoinedCount (state, getters) {
      let playerJoinedCount = 0;

      for (const playerCode in getters.room.players) {
        const player = getters.room.players[playerCode];

        if (player.sessionId && player.playerType !== PLAYER_TYPE_FACILITATOR) {
          playerJoinedCount++;
        }
      }

      return playerJoinedCount;
    },

    players (state, getters) {
      if (getters.room && getters.room.players) {
        return getters.room.players
      }

      return {}
    },
    playerCodesAll (state, getters) {
      if (getters.players) {
        return Object.keys(getters.players)
      }

      return {}
    },
    playerCodesOther (state, getters) {
      const playerCodesOther = []

      getters.playerCodesAll.forEach((playerCode) => {
        const player = getters.players[playerCode]

        if (!player.isSelf) {
          playerCodesOther.push(playerCode)
        }
      })

      return playerCodesOther
    },

    playerOthers (state, getters) {
      const playerOthers = {}

      if (getters.players) {
        for (const playerId in getters.players) {
          const player = getters.players[playerId]

          if (!player.isSelf) {
            playerOthers[playerId] = player
          }
        }
      }

      return playerOthers
    },

    playerSelf (state, getters) {
      if (getters.players) {
        for (const playerId in getters.players) {
          const player = getters.players[playerId]

          if (player.isSelf) {
            return player
          }
        }
      }

      return null
    },

    playerIsFacilitator (state, getters) {
      const playerSelf = getters.playerSelf;

      if (playerSelf) {
        return playerSelf.playerType === PLAYER_TYPE_FACILITATOR;
      }

      return false;
    },
  },
}
