<template>
  <div class="teaser-full">
    <div class="teaser-skip" @click="skipTeaser" v-if="!logoContainerButton" />

    <div class="hazards">
      <floating-hazard-icon
        v-for="hazardIcon of hazardIcons"

        :identifier="hazardIcon.identifier"
        :showShadow="hazardIcon.showShadow"
        :flagCount="hazardIcon.flagCount"
        :float="hazardIcon.float"
        :clickable="hazardIcon.clickable"
        :severity="hazardIcon.severity"
        :type="hazardIcon.type"
        :age="hazardIcon.age"
        :decayAge="hazardIcon.decayAge"
        :style="hazardIcon.style"
      />
    </div>

    <teaser-logo
      :subtitle="$t('intro.logoEmergencyPreparedness')"
      :action="$t('intro.logoYouDecide')"
      :button-text="'Begin'"
      :instant-transition="instantTransition"

      :logo-container-show="logoContainerShow"
      :logo-container-fate="logoContainerFate"
      :logo-container-button="logoContainerButton"
      :fade-to-almost-black="fadeToAlmostBlack"

      @button-click="begin"
    />

    <div class="fade-to-black" :class="fadeToBlack ? 'fade-to-black-visible' : 'fade-to-black-invisible'"/>
    <div class="fade-to-almost-black" :class="fadeToAlmostBlack ? 'fade-to-almost-black-visible' : 'fade-to-almost-black-invisible'"/>
  </div>
</template>

<script>
import LobbyPopupTemplate from '@/components/Lobby/LobbyPopupTemplate.vue'
import AppButton from '@/components/common/buttons/AppButton.vue'
import NoDragImage from '@/components/common/NoDragImage.vue'
import LongButton from '@/components/common/LongButton.vue'

import IntroPageData from '@/lib/intro-pages'
import MarkdownText from '@/components/common/MarkdownText/index.vue'
import MediaContainer from '@/components/common/media/MediaContainer.vue'
import ImageLibrary from '@/lib/image-library'
import {mapState} from 'vuex'
import VersionContainer from '@/components/common/VersionContainer.vue'
import { clearTimeoutPromise, timeoutPromise } from '@/utils/promiseUtil'
import FloatingHazardIcon from '@/components/FloatingHazardIcon/index.vue';
import TeaserLogo from '@/components/Teaser/TeaserLogo.vue';

export default {
  name: 'TeaserView',
  components: {
    TeaserLogo,
    FloatingHazardIcon,
    MarkdownText,
    LongButton,
    NoDragImage,
    AppButton,
    LobbyPopupTemplate,
    MediaContainer,
    VersionContainer,
  },
  data() {
    return {
      fadeToBlack: true,
      fadeToAlmostBlack: false,

      hazardIcons: [],
      hazardIconTimeouts: [],

      logoContainerShow: false,
      logoContainerFate: false,
      logoContainerButton: false,

      instantTransition: false,
    };
  },
  mounted() {
    this.$store.commit('backgroundBlingTop', true);
    this.$store.commit('backgroundBlingBottom', true);
    this.$store.commit('background', 'game');

    if (parseInt(process.env.VUE_APP_SKIP_TEASER, 10) > 0 || this.seenTeaser) {
      // Either we've already seen the teaser or we want to skip it
      this.jumpNext();

      return;
    }

    requestAnimationFrame(() => {
      this.startAnimation();
    });
  },
  methods: {
    jumpNext() {
      this.skipTeaser();

      this.$store.commit('seenTeaser', true);
      this.$router.push('/intro');
    },
    skipTeaser() {
      for (let i = 0, len = this.hazardIconTimeouts.length; i < len; i++) {
        this.clearHazardIconTimeout(i);
      }

      clearTimeoutPromise();

      this.hazardIcons = [];
      this.hazardIconTimeouts = [];

      this.instantTransition = true;

      requestAnimationFrame(() => {
        setTimeout(() => {
          this.addHazardIcon(250, 320, 'infectious', true);
          this.addHazardIcon(800, 60, 'animal', true);
          this.addHazardIcon(1500, 400, 'chemical', true);

          this.logoContainerShow = true;
          this.logoContainerFate = true;
          this.logoContainerButton = true;
        }, 10);
      });
    },
    begin() {
      this.fadeToAlmostBlack = true;

      setTimeout(() => {
        this.jumpNext();
      }, 1000);
    },
    startAnimation() {
      this.fadeToBlack = false;
      this.fadeToAlmostBlack = false;
      this.logoContainerShow = false;
      this.logoContainerFate = false;
      this.logoContainerButton = false;

      timeoutPromise(2500)
        .then(() => {
          this.addHazardIcon(250, 320, 'infectious');

          return timeoutPromise(1500);
        })
        .then(() => {
          this.addHazardIcon(800, 60, 'animal');

          return timeoutPromise(1500);
        })
        .then(() => {
          this.addHazardIcon(1500, 400, 'chemical');

          return timeoutPromise(1500);
        })
        .then(() => {
          this.logoContainerShow = true;

          return timeoutPromise(1500);
        })
        .then(() => {
          this.logoContainerFate = true;

          return timeoutPromise(1500);
        })
        .then(() => {
          this.logoContainerButton = true;

          return timeoutPromise(1500);
        });
    },
    addHazardIcon(x, y, type, instant = false) {
      const hazardIcon = {
        identifier: type,
        showShadow: true,
        flagCount: 0,
        float: false,
        clickable: false,
        severity: -1,
        type: type,
        age: 0,
        decayAge: 0,
        style: {
          left: x + 'px',
          top: y + 'px',
          transform: 'scale(1)',
          opacity: 0,
          transitionTimingFunction: `cubic-bezier(0.175, 0.985, 0.32, 2.175) !important`,
        },
      };

      if (instant) {
        hazardIcon.style.opacity = 1;
        hazardIcon.style.transitionTimingFunction = `ease-out !important`;
        hazardIcon.style.transform = 'scale(1)';
      }

      this.hazardIconTimeouts.push(null);
      this.hazardIcons.push(hazardIcon);

      if (instant) {
        return;
      }

      let index = this.hazardIcons.length - 1;

      this.setHazardIconTimeout(index, () => {
        this.hazardIcons[index].style.opacity = 1;
        this.hazardIcons[index].style.transform = 'scale(2)';

        this.setHazardIconTimeout(index, () => {
          console.log('hazardIcon', type);
          this.hazardIcons[index].style.transitionTimingFunction = `ease-out !important`;
          this.hazardIcons[index].style.transform = 'scale(1)';
        }, 1000);
      }, 100);
    },
    setHazardIconTimeout(i, callbackFn, timeout) {
      this.clearHazardIconTimeout(i);

      this.hazardIconTimeouts[i] = setTimeout(() => {
        this.clearHazardIconTimeout(i);

        callbackFn();
      }, timeout);
    },
    clearHazardIconTimeout(i) {
      console.log('clearingHazardIconTimeout', i);

      if (!this.hazardIconTimeouts[i]) {
        return;
      }

      clearTimeout(this.hazardIconTimeouts[i]);

      this.hazardIconTimeouts[i] = null;
    }
  },
}
</script>

<style lang="scss" scoped>
.teaser-full {
  width: 100%;
  height: 100%;

  padding: 50px 290px 50px 290px;
  color: white;
  font-size: 16pt;
  text-align: left;
}

.hazards {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .floating-event-icon {
    position: absolute;
    transition-timing-function: cubic-bezier(0.175, 0.985, 0.32, 2.175) !important;
    transition-duration: 500ms !important;
    transition-property: all !important;
  }
}

.fade-to-black {
  background: rgba(#000000, 1);
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  pointer-events: none;

  &.fade-to-black-visible {
    opacity: 1;
  }

  transition: 2500ms ease-out;
}

.fade-to-almost-black {
  background: rgba(#000000, .8);
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  pointer-events: none;

  &.fade-to-almost-black-visible {
    opacity: 1;
  }

  transition: 800ms ease-out;
}

.transition-instant {
  transition: 0ms !important;
}

.teaser-skip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(255, 0, 0, 0);
  z-index: 9999999999;
}
</style>
