<template>
  <div class="star-points-dev-container">
    <top-bar :action-points="10" :game-time="3" :mg-event-success="currentScores.mgEventSuccess" :collaboration="currentScores.collaboration" :health-security="currentScores.healthSecurity" :trust="currentScores.trust" />

    <star-points :score-impacts="scoreImpacts" v-if="scoreImpacts" :key="scoreImpactKey" />
  </div>
  <dev-settings-panel>
    <div class="row">
      <button class="btn btn-success mb-2" @click="addOne">Add one</button>
      <button class="btn btn-success mb-2" @click="addTwo">Add two</button>
      <button class="btn btn-success mb-2" @click="addThree">Add three</button>
      <button class="btn btn-success mb-2" @click="addFour">Add four</button>
    </div>
  </dev-settings-panel>

  <event-ticks :events="events" :events-active="eventsActive" class="mt-4"/>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import EventTicks from '@/components/DevComponent/EventTicks'
import ButtonContainer from '@/components/AnimatingNumber/ButtonContainer'

import HazardPopupIntro from './index.vue'
import StrategicMeetingIntro from '@/components/StrategicMeeting/StrategicMeetingIntro/index.vue'
import StrategicMeetingPopup from '@/components/StrategicMeeting/StrategicMeetingPopup/index.vue'
import { mapState } from 'vuex'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import MeetingResultsPopup from '@/components/MeetingResultsPopup/index.vue'
import { generatePlayerData, generatePlayersData } from '@/utils/playerDataGenerator'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import TopBar from '@/components/player-ui/TopBar/index.vue'
import StarPoints from '@/components/StarPoints/index.vue'

export default {
  name: 'StarPointsDev',
  components: {
    StarPoints,
    TopBar,
    DevSettingsPanel,
    MeetingResultsPopup,
    EventTicks,
    ButtonContainer
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],

  data () {
    return {
      events: [
        'done',
      ],

      scoreImpactKey: 0,

      currentScores: {
        mgEventSuccess: 10,
        collaboration: 20,
        healthSecurity: 30,
        trust: 40,
      },

      scoreImpacts: null,
    }
  },
  methods: {
    setScoreImpacts(scoreImpacts) {
      this.scoreImpacts = scoreImpacts;
      this.scoreImpactKey++;
    },
    addOne() {
      this.setScoreImpacts({
        mgEventSuccess: Math.random() * 10,
      });
    },
    addTwo() {
      this.setScoreImpacts({
        mgEventSuccess: Math.random() * 10,
        collaboration: Math.random() * 10,
      });
    },
    addThree() {
      this.setScoreImpacts({
        mgEventSuccess: Math.random() * 10,
        collaboration: Math.random() * 10,
        healthSecurity: Math.random() * 10,
      });
    },
    addFour() {
      this.setScoreImpacts({
        mgEventSuccess: Math.random() * 10,
        collaboration: Math.random() * 10,
        healthSecurity: Math.random() * 10,
        trust: Math.random() * 10,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.meeting-results-popup-container {
  .lobby-popup {
    margin: auto;
  }
}
</style>
