<template>
  <div class="right-player-list-bar">
    <div v-for="playerCode in playerCodeOthers" :key="playerCode" class="right-player-single">
      <player-character-plate :player-data="players[playerCode]" size="medium-small" :waiting-for="waitingForPlayerCode === players[playerCode].playerCode" />
    </div>
  </div>
</template>

<script>
import PlayerCharacterPlate from '@/components/character/PlayerCharacterPlate.vue'

export default {
  name: 'RightPlayerListBar',
  components: {
    PlayerCharacterPlate,
  },
  props: {
    players: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    waitingForPlayerCode: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    playerCodeOthers() {
      const playerCodeOthers = [];

      for (const playerCode in this.players) {
        const player = this.players[playerCode];

        if (!player.isSelf) {
          playerCodeOthers.push(playerCode);
        }
      }

      return playerCodeOthers;
    }
  }
}
</script>

<style scoped>
.right-player-list-bar {
  width: 110px;
  z-index: 1000000000000;
}

.right-player-single {
  margin-bottom: 25px;
}
</style>
