<template>
  <div class="star-minigame-live-container">
    <star-minigame
        @continue="onContinue"

        :player-vote-hazard-index="playerVoteHazardIndex"
        :player-vote-likelihood-index="playerVoteLikelihoodIndex"
        :player-vote-impact-index="playerVoteImpactIndex"

        @player-vote-hazard-index-changed="onPlayerVoteHazardIndexChanged"
        @player-vote-likelihood-index-changed="onPlayerVoteLikelihoodIndexChanged"
        @player-vote-impact-index-changed="onPlayerVoteImpactIndexChanged"

        :can-interact="canInteract"
        :current-state="currentState"
        :current-tutorial-state="currentTutorialState"
        :current-vote-state="currentVoteState"
        :current-hazard-index="currentHazardIndex"
        :hazard-results="hazardResults"
        :players="players"
    />
  </div>
</template>
<script>
import DevComponentContainer from '@/components/DevComponent/DevComponentContainer'
import EventTicksComponentMixin from '@/components/DevComponent/EventTicksComponentMixin'
import MeetingPopup from '@/components/MeetingPopup/index.vue'
import DevSettingsPanel from '@/components/DevComponent/DevSettingsPanel.vue'
import StarMinigame from '@/components/StarMinigame/index.vue'
import BootstrapSelect from '@/components/bootstrap/BootstrapSelect.vue'
import { mapGetters, mapState } from 'vuex'
import {
  STAR_MINIGAME_STATE_FORCED_DOCUMENT,
  STAR_MINIGAME_STATE_NOT_BEGUN,
  STAR_MINIGAME_STATE_OVERVIEW,
  STAR_MINIGAME_STATE_RISK_MATRIX,
  STAR_MINIGAME_STATE_TUTORIAL,
  STAR_MINIGAME_STATE_VOTE
} from '@/enum/StarMinigameState'
import {
  STAR_MINIGAME_TUTORIAL_STATE_COPING_CAPACITY,
  STAR_MINIGAME_TUTORIAL_STATE_FREQUENCY,
  STAR_MINIGAME_TUTORIAL_STATE_LIKELIHOOD,
  STAR_MINIGAME_TUTORIAL_STATE_SEASONALITY,
  STAR_MINIGAME_TUTORIAL_STATE_SEVERITY,
  STAR_MINIGAME_TUTORIAL_STATE_VULNERABILITY
} from '@/enum/StarMinigameTutorialState'
import {
  STAR_MINIGAME_VOTE_STATE_VOTE_IMPACT,
  STAR_MINIGAME_VOTE_STATE_VOTE_LIKELIHOOD
} from '@/enum/StarMinigameVoteState'
import { objectToArray } from '@/utils/arrayUtil'
import { State, VoteState } from '@/components/StarMinigame/constants'
import { pickRandom } from '@/utils/randomUtil'

export default {
  name: 'StarMinigameLive',
  components: {
    BootstrapSelect,
    StarMinigame,
    DevSettingsPanel,
    MeetingPopup,
  },
  extends: DevComponentContainer,
  mixins: [EventTicksComponentMixin],
  data () {
    return {
      currentAssessmentRowIndex: -1
    }
  },
  watch: {
    currentState(currentState) {
      this.verifyVotes();
    },
  },
  mounted() {
    this.verifyVotes();
  },
  computed: {
    ...mapState(['room']),
    ...mapGetters(['playerSelf', 'playerIsFacilitator']),
    canInteract() {
      return !this.playerIsFacilitator;
    },
    players() {
      if (!this.room) {
        return null;
      }

      return this.room.players;
    },
    starMinigameData () {
      if (!this.room) {
        return null
      }

      if (!this.room.activeStarMinigame) {
        return null
      }

      return this.room.activeStarMinigame
    },
    currentState() {
      if (!this.starMinigameData) {
        return null
      }

      return this.starMinigameData.state;
    },
    currentTutorialState () {
      if (!this.starMinigameData) {
        return null
      }

      return this.starMinigameData.tutorialState
    },
    currentVoteState () {
      if (!this.starMinigameData) {
        return null
      }

      return this.starMinigameData.voteState
    },
    currentHazardIndex() {
      if (!this.starMinigameData) {
        return null
      }

      return this.starMinigameData.currentHazardIndex;
    },
    hazardResults() {
      if (!this.starMinigameData) {
        return null
      }

      return objectToArray(this.starMinigameData.hazardResults) || [];
    },
    playerVoteLikelihoodIndex () {
      if (!this.playerSelf) {
        return
      }

      return this.playerSelf.starMinigameLikelihoodVoteIndex
    },
    playerVoteImpactIndex () {
      if (!this.playerSelf) {
        return
      }

      return this.playerSelf.starMinigameImpactVoteIndex;
    },
    playerVoteHazardIndex () {
      if (!this.playerSelf) {
        return
      }

      return this.playerSelf.starMinigameHazardVoteIndex;
    },
  },
  methods: {
    verifyVotes() {
      if (this.currentState !== State.VOTE) {
        return;
      }

      if (this.currentVoteState !== VoteState.IMPACT) {
        return;
      }

      if (this.playerSelf.starMinigameLikelihoodVoteIndex >= 0) {
        // Already got a vote

        return;
      }

      const voteIndex = pickRandom([0, 1, 2, 3]);

      console.log(`Likelihood vote index missing - answering with a random answer ${voteIndex}`);
      this.$gameClient.playerUpdate('starMinigameLikelihoodVoteIndex', voteIndex);
    },
    voteSkipTimer() {
      this.$nextTick(() => {
        this.$gameClient.playerUpdate('voteSkipTimer', true);
      });
    },
    onContinue (origin) {
      if (origin === 'invitation') {
        this.voteSkipTimer();
      }
    },
    onPlayerVoteHazardIndexChanged(hazardIndex) {
      console.log('hazardIndex', hazardIndex);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('starMinigameHazardVoteIndex', hazardIndex).then(() => {
        console.log('hazardIndex', hazardIndex, true);

        //this.voteSkipTimer();
      });
    },
    onPlayerVoteLikelihoodIndexChanged(likelihoodIndex) {
      console.log('likelihoodIndex', likelihoodIndex);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('starMinigameLikelihoodVoteIndex', likelihoodIndex).then(() => {
        console.log('likelihoodIndex', likelihoodIndex, true);

        //this.voteSkipTimer();
      });
    },
    onPlayerVoteImpactIndexChanged(impactIndex) {
      console.log('impactIndex', impactIndex);

      if (this.playerIsFacilitator) {
        return;
      }

      this.$gameClient.playerUpdate('starMinigameImpactVoteIndex', impactIndex).then(() => {
        console.log('impactIndex', impactIndex, true);

        //this.voteSkipTimer();
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.star-minigame-live-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}
</style>
